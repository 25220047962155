import { ColumnsType } from 'antd/es/table';
import { IGetKom1TypeOfWaste, IKom1TypeOfWasteFilters, IPostKom1TypeOfWaste } from './kom1TypeOfWaste.types';
import { FormInstance } from 'antd';
import { THE_ORDER_TABLE, VERSION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { THE_ORDER_FORM, VERSION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import i18n from 'translations/i18n.config';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const KOM1_TYPE_OF_WASTE_INITIAL_STATE: IPostKom1TypeOfWaste = {
  type_of_waste: '',
  type_of_waste_sr: '',
  version: '',
  the_order: '',
  is_active: true,
};
export const KOM1_TYPE_OF_WASTE_FILTERS_INITIAL_STATE: IKom1TypeOfWasteFilters = {
  type_of_waste__ilike: '',
  type_of_waste_sr__ilike: '',
  version__ilike: '',
  the_order__ilike: '',
  is_active: null,
};
export const KOM1_TYPE_OF_WASTE_TABLE_DATA: ColumnsType<IGetKom1TypeOfWaste> = [
  {
    title: i18n.t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
    width: '200px',
    dataIndex: 'type_of_waste',
    key: 'type_of_waste',
  },

  {
    title: `${i18n.t('OBRASCI.KOM_1.TYPE_OF_WASTE')} ${i18n.t('NA_SRPSKOM')}`,
    width: '200px',
    dataIndex: 'type_of_waste_sr',
    key: 'type_of_waste_sr',
  },
  VERSION_TABLE,

  THE_ORDER_TABLE,
];
export const KOM1_TYPE_OF_WASTE_FORM_OPTIONS = (_: FormInstance<IGetKom1TypeOfWaste>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      name: 'type_of_waste',
      filterName: 'type_of_waste__ilike',
      label: i18n.t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
      inputName: 'type_of_waste',
      rules: [MAX_LENGTH_RULE(200)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'type_of_waste_sr',
      filterName: 'type_of_waste_sr__ilike',
      rules: [MAX_LENGTH_RULE(200)],
      label: `${i18n.t('OBRASCI.KOM_1.TYPE_OF_WASTE')} ${i18n.t('NA_SRPSKOM')}`,
      inputName: 'type_of_waste_sr',
    } as InputSifarniciFormProps,
    { ...VERSION_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
    THE_ORDER_FORM,
  ];
};
