import { IGetFacility, IPostFacilityTipoviObrazaca } from 'modules/facility/facility.types.ts';
import { obrasciRepo } from './obrasci.repo';
import {
  CUSTOM_OBRASCI_KEYS,
  CustomTipoviObrazaca,
  IFiltersSameObrazac,
  IOpsteInitial,
  ISubmitFormObrasca,
  ITipObrasca,
  ITipObrascaConfig,
  ITipObrascaCreate,
  ITipoviObrascaReduceNivoResult,
  OpsteProvera,
  TIPOVI_OBRAZACA,
} from './obrasci.types';
import { arrayToObject } from 'utils/arrayToObject';
import { CUSTOM_OBRASCI_MAKER, TIPOVI_OBRAZACA_FIELDS_MAP } from './tipoviObrasci.service';
import { formStore, modalStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { OBRAZAC_TYPE_TABLE_COLUMNS } from './tableDataObrasci.constants';
import { OBRASCI_FILTERS_DATA } from './filterDataObrasci.constants';
import { ISifarniciFormData } from 'modules/sifarnici/sifarnici.types';
import { obrasciStore } from './obrasci.store';
import { IGetCompany } from 'modules/company/company.types';
import storage, { StorageEnum } from 'store/storage';
import { OBRAZAC_TYPE_WIZARD_URL_MAP, opsteInitial } from './obrasci.constants';
import to from 'await-to-js';
import { facilityService } from 'modules/facility/facility.service';
import { VALIDATION_OBRAZAC } from './validationObrasci.service';
import { Deo6Gio6Enum } from './store/deo6/deo6.types';
import { IExportDeo6Gio6PdfForm } from 'components/Modals/ExportDeo6Gio6PdfModal/ExportDeo6Gio6PdfModal';
import { IZakljucavanjeGodinaIzuzeci, IZakljucavanjeObrazacaFilters } from 'modules/zakljucavanje/zakljucavanje.types';
import { zakljucavanjeRepo } from 'modules/zakljucavanje/zakljucavanje.repo';
import { mergeArraysWithoutDuplicates } from 'utils/mergeArraysWithoutDuplicates';
import { ITablePagination } from 'components/TableData/TableData';
import { ApiResponse } from 'modules/axios';
import { IEmptyResponse, IPostResponse } from 'typescript/NrizTypes';
import Decimal from 'decimal.js';
import {
  IBaseWithGodinaFilterDataObrasci,
  IDeo6Gio6FilterDataObrasci,
  IDkoFilterDataObrasci,
  IFilterDataObrasci,
} from './filterDataObrasci.types';
import dayjs from 'dayjs';
import { PotvrdaPayload } from './store/dko/dko.types';

class ObrasciService {
  fetchTipoviObrasca = () => {
    return obrasciRepo.fetchTipoviObrasca();
  };

  fetchTipoviObrazacaPoPreduzecu = (id: string) => {
    return obrasciRepo.fetchTipoviObrazacaPoPreduzecu(id);
  };

  fetchTipoviObrazacaPoPostrojenju = (id: string) => {
    return obrasciRepo.fetchTipoviObrazacaPoPostrojenju(id);
  };

  postTipoviObrasca = (obrazac: ITipObrascaCreate): ApiResponse<IEmptyResponse> => {
    return obrasciRepo.postTipoviObrasca(obrazac);
  };

  fetchSingleObrazac = <ObrazacResponse = any>(type: string, id: string): ApiResponse<ObrazacResponse> => {
    return obrasciRepo.fetchSingleObrazac<ObrazacResponse>(type, id);
  };

  postSingleObrazac = <ObrazacPayload = any>(type: string, payload: ObrazacPayload): ApiResponse<IPostResponse> => {
    return obrasciRepo.postSingleObrazac<ObrazacPayload>(type, nrizService.setEmptyValuesToNull(payload));
  };

  putSingleObrazac = <ObrazacPayload = any>(
    type: string,
    id: string,
    payload: ObrazacPayload
  ): ApiResponse<IEmptyResponse> => {
    return obrasciRepo.putSingleObrazac<ObrazacPayload>(type, id, nrizService.setEmptyValuesToNull(payload));
  };

  putSingleObrazacWithParams = (type: string, id: string, params: string, payload?: PotvrdaPayload) => {
    return obrasciRepo.putSingleObrazacWithParams(type, id, params, payload);
  };

  deleteSingleObrazac = (type: string, id: string): ApiResponse<IEmptyResponse> => {
    return obrasciRepo.deleteSingleObrazac(type, id);
  };

  fetchListObrazac = <ObrazacPayload = any>(type: string, filters: any) => {
    return obrasciRepo.fetchListObrazac<ObrazacPayload>(type, filters);
  };

  exportObrazacListExcel = <ObrasciFilters = any>(type: TIPOVI_OBRAZACA, filters?: ObrasciFilters) => {
    return obrasciRepo.exportObrazacListExcel(type, filters);
  };

  calculateGodisnjiBilans = <BilansPayload = any>(type: string, payload: any) => {
    return obrasciRepo.calculateGodisnjiBilans<BilansPayload>(type, payload);
  };

  fetchInfoForLastYear = <ObrazacPayload = any>(filters: any) => {
    return obrasciRepo.fetchInforForLastYear<ObrazacPayload>(filters);
  };

  exportPdfSingleObrazac = (type: TIPOVI_OBRAZACA, id: string, filters: any): Promise<Blob> => {
    return obrasciRepo.exportPdfSingleObrazac(type, id, filters);
  };

  exportPdfDeo6Gio6 = (type: Deo6Gio6Enum, filters: IExportDeo6Gio6PdfForm): Promise<Blob> => {
    return obrasciRepo.exportPdfDeo6Gio6(type, filters);
  };

  submitFormObrazac = (props: ISubmitFormObrasca) => {
    if (formStore.getCrudButtonType === 'submit') {
      return props.postForm(props.payload);
    }

    if (formStore.getCrudButtonType === 'change') {
      return props.putForm(props.payload);
    }
    if (formStore.getCrudButtonType === 'delete') {
      return props.deleteForm(props.payload);
    }
  };

  makeObrasciShortNameMap = (obrasci: ITipObrascaConfig[]) => {
    return arrayToObject<TIPOVI_OBRAZACA, ITipObrascaConfig | ITipObrasca>(obrasci, 'skraceni_naziv');
  };

  filterByNivo = (obrasci: ITipObrascaConfig[]): ITipoviObrascaReduceNivoResult => {
    if (!obrasci) return { preduzece: [], postrojenje: [] };

    return obrasci.reduce<ITipoviObrascaReduceNivoResult>(
      (acc, obrazac) => {
        if (obrazac.nivo === 'preduzece') acc.preduzece.push(obrazac);
        if (obrazac.nivo === 'postrojenje') acc.postrojenje.push(obrazac);
        return acc;
      },
      { preduzece: [], postrojenje: [] }
    );
  };

  getObrazacByShortName = (obrasci: ITipObrasca[], shortName: string) => {
    return obrasci.find(obrazac => obrazac.skraceni_naziv === shortName);
  };

  getTipoviNames = (tipoviId: string[], obrasci: ITipObrasca[]): IPostFacilityTipoviObrazaca => {
    return tipoviId.map(id => {
      const obrazacName = obrasci.find(obrazac => obrazac.id === id)?.skraceni_naziv || '';
      return { id, skraceni_naziv: obrazacName };
    });
  };

  buildTipoviConfig = (tipoviObrazaca: ITipObrasca[]) => {
    return tipoviObrazaca.map(tipO => ({
      ...tipO,
      ...TIPOVI_OBRAZACA_FIELDS_MAP[tipO.skraceni_naziv],
    }));
  };
  tableFinder = (type: string) => {
    return OBRAZAC_TYPE_TABLE_COLUMNS[type];
  };

  submitMicroServiceForm = (initialList: any[], payload: any, closeOneModal?: boolean, modalName?: string) => {
    if (closeOneModal && modalName) {
      modalStore.clearModal(modalName);
    } else {
      modalStore.removeAllModals();
    }

    let newList;
    if (formStore.getCrudButtonType === 'submit') {
      newList = nrizService.addItemToList(initialList, payload);
    }

    if (formStore.getCrudButtonType === 'change') {
      newList = nrizService.changeItemInList(initialList, payload);
    }
    if (formStore.getCrudButtonType === 'delete') {
      newList = nrizService.removeFromList(initialList, payload.id);
    }

    return newList;
  };

  setMiddleValue(state: any) {
    let sum = new Decimal(0);
    let count = 0;

    Object.values(state).forEach(value => {
      if (value === null || value === undefined) {
        return;
      }

      const decimalValue = new Decimal(value as number);
      sum = sum.plus(decimalValue);
      count++;
    });

    if (count === 0) {
      return new Decimal(0);
    }

    return sum.dividedBy(count).toNumber().toFixed(4);
  }

  switchObrasciType = (type: TIPOVI_OBRAZACA) => {
    switch (type) {
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1:
        return TIPOVI_OBRAZACA.OBRAZAC_1;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4:
        return TIPOVI_OBRAZACA.OBRAZAC_4;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5:
        return TIPOVI_OBRAZACA.OBRAZAC_5;
      case TIPOVI_OBRAZACA.GIO1:
        return TIPOVI_OBRAZACA.OBRAZAC_5;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return TIPOVI_OBRAZACA.OBRAZAC_5;

      default:
        return type;
    }
  };

  filtersObrasciFinder = (type: string): ISifarniciFormData => {
    return OBRASCI_FILTERS_DATA[type];
  };

  setObrazacTypeWithUrl = (url: string) => {
    for (const [key, val] of Object.entries<string>(OBRAZAC_TYPE_WIZARD_URL_MAP)) {
      if (url.includes(val)) {
        obrasciStore.handleSameObrazac(key as TIPOVI_OBRAZACA);
      }
    }
  };

  handleValidation = (obrazacPayload: any) => {
    const currentValidationRules = VALIDATION_OBRAZAC[obrasciStore.obrazac_type];
    if (currentValidationRules.length === 0) return true;

    const validationResults = currentValidationRules.map(rule => rule.isValid(obrazacPayload));
    const isValid = validationResults.every(result => result);
    if (!isValid) {
      obrasciStore.handleChange('isValidationNotificationOpen', true);
    }
    return isValid;
  };

  nameForFirstExportPdf = (obrazacType: TIPOVI_OBRAZACA) => {
    switch (obrazacType) {
      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2;

      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3;

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5;

      default:
        return obrazacType;
    }
  };

  firstExportPdfFilters = (obrazacType: TIPOVI_OBRAZACA) => {
    switch (obrazacType) {
      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.OBRAZAC_2);

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2);

      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.OBRAZAC_3);

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3);

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5);

      default:
        break;
    }
  };

  nameForSecondExportPdfFile = (obrazacType: TIPOVI_OBRAZACA) => {
    switch (obrazacType) {
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.GVE;

      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.GVE;

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OTV;

      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OTV;

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return TIPOVI_OBRAZACA.GIO1;

      default:
        break;
    }
  };

  secondExportPdfFilters = (obrazacType: TIPOVI_OBRAZACA) => {
    switch (obrazacType) {
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.GVE);

      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.GVE);

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.OTV);

      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.OTV);

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return this.obrasciListFiltersSameObrazac(TIPOVI_OBRAZACA.GIO1);

      default:
        break;
    }
  };

  obrasciListFiltersSameObrazac = (obrazacType: TIPOVI_OBRAZACA) => {
    switch (obrazacType) {
      case TIPOVI_OBRAZACA.OBRAZAC_1:
        return { obrazac_1: true };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1:
        return { lriz_obrazac_1: true };

      case TIPOVI_OBRAZACA.OBRAZAC_2:
        return { obrazac_2: true };

      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return { lriz_obrazac_2: false };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2:
        return { lriz_obrazac_2: true };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return { obrazac_2: false };

      case TIPOVI_OBRAZACA.GVE:
        return { obrazac_gve: true };

      case TIPOVI_OBRAZACA.OBRAZAC_3:
        return { obrazac3: true };

      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return { obrazac3_lriz: false };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3:
        return { obrazac3_lriz: true };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return { obrazac3: false };

      case TIPOVI_OBRAZACA.OTV:
        return { otv: true };

      case TIPOVI_OBRAZACA.OBRAZAC_4:
        return { obrazac_4: true };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4:
        return { lriz_obrazac_4: true };

      case TIPOVI_OBRAZACA.OBRAZAC_5:
        return { obrazac_5: true };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5:
        return { lriz_obrazac_5: true };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return { obrazac_5: false };

      case TIPOVI_OBRAZACA.GIO1:
        return { obrazac_gio1: true };

      default:
        break;
    }
  };

  checkIfCustomTipObrasca = (tip: TIPOVI_OBRAZACA): tip is CustomTipoviObrazaca => {
    return CUSTOM_OBRASCI_KEYS.includes(tip);
  };

  filtersSameObrazac = (obrazacType: TIPOVI_OBRAZACA): IFiltersSameObrazac | undefined => {
    switch (obrasciStore.obrazac_type) {
      case TIPOVI_OBRAZACA.OBRAZAC_1:
        return {
          lriz_obrazac_1: obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1,
          obrazac_1: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_1,
        };

      case TIPOVI_OBRAZACA.OBRAZAC_2:
        return {
          obrazac_gve:
            obrazacType === TIPOVI_OBRAZACA.GVE ||
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE ||
            obrazacType === TIPOVI_OBRAZACA.OBRAZAC_2_GVE,
          lriz_obrazac_2:
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2,
          obrazac_2: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_2_GVE || obrazacType === TIPOVI_OBRAZACA.OBRAZAC_2,
        };
      case TIPOVI_OBRAZACA.OBRAZAC_3:
        return {
          obrazac3_lriz:
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3 || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
          otv:
            obrazacType === TIPOVI_OBRAZACA.OTV ||
            obrazacType === TIPOVI_OBRAZACA.OBRAZAC_3_OTV ||
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
          obrazac3: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_3 || obrazacType === TIPOVI_OBRAZACA.OBRAZAC_3_OTV,
        };
      case TIPOVI_OBRAZACA.OBRAZAC_4:
        return {
          lriz_obrazac_4: obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4,
          obrazac_4: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_4,
        };
      case TIPOVI_OBRAZACA.OBRAZAC_5:
        return {
          obrazac_gio1: obrazacType === TIPOVI_OBRAZACA.GIO1 || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
          lriz_obrazac_5:
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5 || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
          obrazac_5: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_5,
        };
      default:
        break;
    }
  };

  combineTwoSameObrazacTypeIntoOne = (arrayObrazaca: ITipObrasca[]): ITipObrasca[] => {
    const obrazac2Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2
    );
    const lrizObrazac2Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
    );
    const obrazacGveIndex = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GVE
    );

    if (obrazac2Index !== -1 && obrazacGveIndex !== -1) {
      arrayObrazaca[obrazac2Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.OBRAZAC_2_GVE);
      arrayObrazaca.splice(obrazacGveIndex, 1);
    }

    if (lrizObrazac2Index !== -1 && obrazacGveIndex !== -1) {
      arrayObrazaca[lrizObrazac2Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE);
      arrayObrazaca.splice(obrazacGveIndex, 1);
    }

    // OBRAZAC 3 , LRIZ OBRAZAC 3 , OTV

    const obrazac3Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_3
    );
    const lrizObrazac3Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3
    );
    const obrazacOtvIndex = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OTV
    );

    if (obrazac3Index !== -1 && obrazacOtvIndex !== -1) {
      arrayObrazaca[obrazac3Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.OBRAZAC_3_OTV);
      arrayObrazaca.splice(obrazacOtvIndex, 1);
    }

    if (lrizObrazac3Index !== -1 && obrazacOtvIndex !== -1) {
      arrayObrazaca[lrizObrazac3Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV);
      arrayObrazaca.splice(obrazacOtvIndex, 1);
    }

    // LRIZ OBRAZAC 5 , GIO1

    const lrizObrazac5Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5
    );
    const gio1ObrazacIndex = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GIO1
    );

    if (lrizObrazac5Index !== -1 && gio1ObrazacIndex !== -1) {
      arrayObrazaca[lrizObrazac5Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1);
      arrayObrazaca.splice(gio1ObrazacIndex, 1);
    }

    return arrayObrazaca;
  };

  checkPostrojenjeTipObrascaSameObrazac = async (
    id: string
  ): Promise<CustomTipoviObrazaca | TIPOVI_OBRAZACA | undefined> => {
    if (
      obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_2 ||
      obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3 ||
      obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_5
    ) {
      const [err, res] = await to(facilityService.fetchFacilityTipObrasca(id));
      if (err || !res) return Promise.reject();

      let sameObrazac: CustomTipoviObrazaca | null = null;

      if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_2) {
        const obrazac2 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2);
        const lrizObrazac2 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2);
        const gve = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GVE);

        if (obrazac2 && gve) {
          sameObrazac = TIPOVI_OBRAZACA.OBRAZAC_2_GVE;
        }

        if (lrizObrazac2 && gve) {
          sameObrazac = TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE;
        }
      }
      if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3) {
        const obrazac3 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_3);
        const lrizObrazac3 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3);
        const otv = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OTV);

        if (obrazac3 && otv) {
          sameObrazac = TIPOVI_OBRAZACA.OBRAZAC_3_OTV;
        }

        if (lrizObrazac3 && otv) {
          sameObrazac = TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV;
        }
      }

      if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_5) {
        const gio1 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GIO1);
        const lrizObrazac5 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5);
        if (gio1 && lrizObrazac5) {
          sameObrazac = TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1;
        }
      }
      if (sameObrazac) {
        obrasciStore.handleSameObrazac(sameObrazac);
        return sameObrazac;
      }
    }
    return obrasciStore.obrazac_type || undefined;
  };

  obrasciFilterModifyPayload = (payload: IFilterDataObrasci) => {
    if ((payload as IDeo6Gio6FilterDataObrasci).datum)
      (payload as IDeo6Gio6FilterDataObrasci).datum = dayjs((payload as IDeo6Gio6FilterDataObrasci).datum).format(
        'YYYY-MM-DD'
      );

    if ((payload as IDkoFilterDataObrasci).datum_predaje_otpada)
      (payload as IDkoFilterDataObrasci).datum_predaje_otpada = dayjs(
        (payload as IDkoFilterDataObrasci).datum_predaje_otpada
      ).format('YYYY-MM-DD');

    if ((payload as IBaseWithGodinaFilterDataObrasci).godina)
      (payload as IBaseWithGodinaFilterDataObrasci).godina = dayjs(
        (payload as IBaseWithGodinaFilterDataObrasci).godina
      ).format('YYYY');

    return payload;
  };

  changeGodinaAndDatumFilters = (payload: IFilterDataObrasci) => {
    (payload as IDeo6Gio6FilterDataObrasci).datum = (payload as IDeo6Gio6FilterDataObrasci).datum
      ? dayjs((payload as IDeo6Gio6FilterDataObrasci).datum)
      : '';

    (payload as IDkoFilterDataObrasci).datum_predaje_otpada = (payload as IDkoFilterDataObrasci).datum_predaje_otpada
      ? dayjs((payload as IDkoFilterDataObrasci).datum_predaje_otpada)
      : '';

    (payload as IBaseWithGodinaFilterDataObrasci).godina = (payload as IBaseWithGodinaFilterDataObrasci).godina
      ? dayjs((payload as IBaseWithGodinaFilterDataObrasci).godina)
      : '';

    return payload;
  };

  setOpsteObrazacWizardUser = (): IOpsteInitial => {
    let preduzecePostrojenje = opsteInitial;

    const storagePostrojenje = storage.getData(StorageEnum.POSTROJENJE) as IGetFacility;
    if (storagePostrojenje) {
      preduzecePostrojenje = {
        ...preduzecePostrojenje,
        postrojenje: storagePostrojenje,
        postrojenje_id: storagePostrojenje.id,
      };
    }

    const storagePreduzece = storage.getData(StorageEnum.PREDUZECE) as IGetCompany;
    if (storagePreduzece) {
      preduzecePostrojenje = {
        ...preduzecePostrojenje,
        preduzece: storagePreduzece,
        preduzece_id: storagePreduzece.id,
      };
    }
    return preduzecePostrojenje;
  };

  resetObrazacWizardAdmin = (tip: TIPOVI_OBRAZACA | '') => {
    obrasciStore.handleChange('opste', opsteInitial);

    const mainTipObrasca =
      tip === TIPOVI_OBRAZACA.OBRAZAC_2_GVE
        ? TIPOVI_OBRAZACA.OBRAZAC_2
        : tip === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE
        ? TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
        : tip === TIPOVI_OBRAZACA.OBRAZAC_3_OTV
        ? TIPOVI_OBRAZACA.OBRAZAC_3
        : tip === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV
        ? TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3
        : tip === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1
        ? TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5
        : '';

    if (mainTipObrasca) {
      obrasciStore.handleSameObrazac(mainTipObrasca);
    }
  };

  checkGodinaZakljucana = async (
    filters: ITablePagination & Partial<IZakljucavanjeGodinaIzuzeci>
  ): Promise<number[]> => {
    // poziv koji dohvata otkljucane godine po tipu obrasca
    if (!filters.tip_obrasca_id) return [];
    const [err, res] = await to(obrasciRepo.checkGodinaZakljucana(filters.tip_obrasca_id));

    if (err) return [];
    const otkljucaneGodine = res.reduce((listaGodina: number[], godina) => {
      if (!godina.zakljucana_godina) return [...listaGodina, godina.godina];
      return listaGodina;
    }, []);

    // poziv koji dohvata otkljucane godine iz tabele izuzetaka po tipu obrasca i preduzecu
    const [error, response] = await to(zakljucavanjeRepo.fetchIzuzeciPoGodini(filters));

    if (error) return otkljucaneGodine;

    const godineIzIzuzetaka = response.items.map(izuzetak => izuzetak.godina);

    const availableYears = mergeArraysWithoutDuplicates(otkljucaneGodine, godineIzIzuzetaka) as number[];

    return availableYears;
  };

  checkIfObrazacLocked = (id: string) => {
    return obrasciRepo.checkIfObrazacLocked(id);
  };

  checkIfObrazacDeo6Locked = (payload: Partial<IZakljucavanjeObrazacaFilters>) => {
    return obrasciRepo.checkIfObrazacDeo6Locked(payload);
  };

  deleteObrazac = (type: TIPOVI_OBRAZACA, id: string): ApiResponse<IEmptyResponse> => {
    return obrasciRepo.deleteObrazac(type, id);
  };

  separateCustomTipObrasca = (customTip: CustomTipoviObrazaca): [TIPOVI_OBRAZACA, TIPOVI_OBRAZACA] => {
    switch (customTip) {
      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return [TIPOVI_OBRAZACA.OBRAZAC_2, TIPOVI_OBRAZACA.GVE];
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2, TIPOVI_OBRAZACA.GVE];
      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return [TIPOVI_OBRAZACA.OBRAZAC_3, TIPOVI_OBRAZACA.OTV];
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3, TIPOVI_OBRAZACA.OTV];
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1:
        return [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5, TIPOVI_OBRAZACA.GIO1];
    }
  };

  checkIfObrazacExists = (params: OpsteProvera) => {
    const validParams = nrizService.pickFields(params);
    return obrasciRepo.checkIfObrazacExists(validParams);
  };
}

export const obrasciService = new ObrasciService();
