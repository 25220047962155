import { makeAutoObservable } from 'mobx';
import { IBaseRegistriStore, RegistriEnum } from '../registri.types';
import { ILCPRegistar, ILCPRegistarListResponse, LCPOpsteInfo, LCPRequest } from './lcp.types';
import { IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { lcpService } from './lcp.service';
import { nrizService } from 'modules/nriz/nriz.service';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { LCP_OPSTE_INITIAL } from './lcp.constants';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { ITablePagination } from 'components/TableData/TableData';

class LCPStore implements IBaseRegistriStore<RegistriEnum.Lcp> {
  pagination: IPagination = paginationInitialState;
  lcpRegistar: ILCPRegistar | undefined;
  dataSource: ILCPRegistarListResponse[] = [];
  lcpOpste: LCPOpsteInfo = LCP_OPSTE_INITIAL;
  nextInstalacioniDeoId: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setLcpRegistar = (newLcpRegistar: ILCPRegistar | undefined) => {
    this.lcpRegistar = newLcpRegistar;
  };

  setLcpList = (newLcpList: ILCPRegistarListResponse[]) => {
    this.dataSource = newLcpList;
  };

  handleOpsteChange = (opsteKey: keyof LCPOpsteInfo, value: string) => {
    this.lcpOpste = { ...this.lcpOpste, [opsteKey]: value };
  };

  setPagination = (pagination: IPagination) => {
    this.pagination = pagination;
  };

  setNextInstalacioniDeoId = (nextId: string | undefined) => {
    this.nextInstalacioniDeoId = nextId;
  };

  fetchPreduzecaLcp = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCompany>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompany>(SIFARNICI_ROUTES.PREDUZECE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchPostrojenjaLcp = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetFacility>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFacility>(SIFARNICI_ROUTES.VELIKI_ZAGADJIVACI, {
      preduzece_id: this.lcpOpste.preduzece_id,
      page: pagination.page,
      size: pagination.size,
      naziv_postrojenja: search,
    });

    return response;
  };

  getItemList = async (pagination?: ITablePagination) => {
    const data = await lcpService.fetchLcpInstalacijeList({
      ...pagination,
      ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.REGISTRI]),
    });
    this.setLcpList(data.items);
    const { page, size, total, pages } = data;
    this.setPagination({ page, size, total, pages });
    return data;
  };

  getItem = async (id: string) => {
    const lcpRegistar = await lcpService.fetchLcpInstalacija(id);
    if (!lcpRegistar) return;
    const validResponse = lcpService.formatResponseDates(lcpRegistar);
    this.setLcpRegistar(validResponse);

    this.handleOpsteChange('preduzece_id', validResponse.preduzece_id);
    this.handleOpsteChange('postrojenje_id', validResponse.postrojenje_id);
    const nextId = await this.fetchSledeciIdInstalacionogDela();
    // const nextId = lcpService.getNextIdInstalacionogDela(lcpRegistar);

    nextId ? this.setNextInstalacioniDeoId(nextId) : this.setNextInstalacioniDeoId(lcpRegistar?.id_instalacije);

    return lcpRegistar;
  };

  postItem = async (payload: LCPRequest) => {
    const validPayload = lcpService.formatPayloadDates(payload);
    return await lcpService.postLcpInstalacija(validPayload);
  };

  putItem = async (payload: ILCPRegistar) => {
    const validPayload = lcpService.formatPayloadDates(payload);
    return await lcpService.putLcpInstalacija(validPayload);
  };

  deleteItem = async (id: string) => {
    return await lcpService.deleteLcpInstalacija(id);
  };

  resetStates = () => {
    this.setLcpRegistar(undefined);
    this.setNextInstalacioniDeoId(undefined);
    this.handleOpsteChange('preduzece_id', '');
    this.handleOpsteChange('postrojenje_id', '');
  };

  fetchSledeciIdInstalacionogDela = async () => {
    const nextId = await lcpService.fetchSledeciIdInstalacionogDela();
    return nextId;
  };

  fetchSledeciIdInstalacije = async () => {
    const nextId = await lcpService.fetchSledeciIdInstalacije();
    return nextId;
  };
}

export const lcpStore = new LCPStore();
