import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ActivityList1Modal } from 'components/Modals/ActivityModals/ActivityList1Modal/ActivityList1Modal';
import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { useTranslation } from 'react-i18next';
import { authStore, modalStore } from 'store';
import { ActivityList1Table } from './ActivityList1Table/ActivityList1Table';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { activityList2Store } from 'modules/activityList2/activityList2.store';
import { facilityStore } from 'modules/facility/facility.store';
import { IGetActivity1 } from 'modules/activityList1/activityList1.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export const ActivityList1Header: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t(`ACTIVITIES.SUBTITLE1`)}</h3>
      {authStore.isAdmin &&
        activityList2Store.activityList.length === 0 &&
        !activityList1Store.activityList.some((item: IGetActivity1) => item.aktivnost1.code === '0') &&
        facilityStore.getFacility.is_active && (
          <AddNewButton
            label="Dodaj aktivnost 1"
            name={`activity-1`}
            onButtonClick={() => {
              activityList1Store.handleChange('privredna_delatnost_id', '');
              modalStore.changeModalName(`activity-list-modal-1`);
            }}
          />
        )}
    </Row>
  );
});

export const ActivityList1: React.FC = observer(() => {
  useEffect(() => {
    activityList1Store.fetchActivityList(paginationInitialState);
  }, []);

  return (
    <InsideContentWrapper header={<ActivityList1Header />}>
      <ActivityList1Table />
      <ActivityList1Modal />
    </InsideContentWrapper>
  );
});
