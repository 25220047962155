// import { useForm } from 'antd/es/form/Form';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import {
  LATITUDE_RULE,
  LONGITUDE_RULE,
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'store';
import { IGetWater, NacinSnabdevanjaVodom } from 'modules/sourcesOfPollution/water/water.types';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';
import { FormSelect } from 'components/FormSelect/FormSelect';

export const GeneralNewWater: React.FC<ICrudTypeModalProps> = observer(() => {
  const { t } = useTranslation();
  const [lockKoordinates, setLockKoordinates] = useState<boolean>(
    waterStore.water?.zakljucane_koordinate ? waterStore.water?.zakljucane_koordinate : false
  );

  const defaultSliv: ISifarnikInitial | undefined = waterStore.water?.sliv_id
    ? {
        label: (waterStore.getWater as IGetWater).sliv?.basin_name,
        value: waterStore.getWater.sliv_id,
      }
    : undefined;

  const defaultVrstaRecipijenta: ISifarnikInitial | undefined = waterStore.water?.recipient_type_id
    ? {
        label: (waterStore.getWater as IGetWater).recipient_type?.type_sr,
        value: waterStore.getWater.recipient_type_id,
      }
    : undefined;

  const defaultWaterBody: ISifarnikInitial | undefined = waterStore.water.vodno_telo_id
    ? {
        label: (waterStore.getWater as IGetWater).vodno_telo?.water_body_name,
        value: waterStore.getWater.vodno_telo_id,
      }
    : undefined;

  const nacinSnabdevanjaVodomOptions: { label: string; value: NacinSnabdevanjaVodom }[] = [
    {
      label: 'Vodovod',
      value: NacinSnabdevanjaVodom.vodovod,
    },
    {
      label: 'Drugi izvori snabdevanja',
      value: NacinSnabdevanjaVodom.drugiIzvori,
    },
  ];

  // polje: nacin_snabdevanja_vodom
  // enum: vodovod & drugi_izvori_snabdevanja

  return (
    <>
      <FormInput
        label={t('POLLUTION.WATER.SEWER_NUMBER')}
        type="number"
        name="broj_ispusta"
        inputName="broj_ispusta"
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
        disabled={waterStore.water.broj_ispusta !== null && !authStore.isAdmin}
      />
      <FormInput
        label={t('POLLUTION.WATER.SEWER_NAME')}
        name="naziv_ispusta"
        inputName="naziv_ispusta"
        type="text"
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(200)]}
        disabled={waterStore.water.naziv_ispusta !== '' && !authStore.isAdmin}
      />
      <FormCheckbox
        name="zakljucane_koordinate"
        label={t('POLLUTION.AIR.COORDINATE_CONCLUSION')}
        onCheckBoxChange={(e: any) => setLockKoordinates(e.target.checked)}
        disabled={!authStore.isAdmin}
      />
      <FormInput
        type="number"
        disabled={lockKoordinates}
        label={t('COMPANY.LONGITUDE')}
        name="koordinate_long"
        inputName={'koordinate_long'}
        rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
      />
      <FormInput
        type="number"
        disabled={lockKoordinates}
        label={t('COMPANY.LATITUDE')}
        name="koordinate_lat"
        inputName={'koordinate_long'}
        rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
      />

      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>
      <FormInputNumber
        label={t('POLLUTION.WATER.PROJECTED_CAPACITY_OF_SEWER')}
        name="projektovani_kapacitet_ispusta"
        inputName="projektovani_kapacitet_ispusta"
        rules={MORE_THEN_0}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'recipient_type_id'}
        fetchOptinsList={waterStore.fetchTypeofRecipient}
        labelOptionAccessor="type_sr"
        valueOptionAccessor="id"
        label={t('POLLUTION.WATER.TYPE_OF_RECIPIENT')}
        readOnly={false}
        inputName={'recipient_type_id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultVrstaRecipijenta}
        placeholder={t('POLLUTION.WATER.TYPE_OF_RECIPIENT')}
      />

      <FormInput
        label={t('POLLUTION.WATER.NAME_RECIPIENT')}
        name="naziv_recipijenta"
        inputName="naziv_recipijenta"
        type="text"
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'sliv_id'}
        fetchOptinsList={waterStore.fetchSliv}
        labelOptionAccessor="basin_name"
        valueOptionAccessor="id"
        label={t('POLLUTION.WATER.BASIN')}
        readOnly={false}
        inputName={'sliv_id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultSliv}
        placeholder={t('POLLUTION.WATER.BASIN')}
      />
      <FormSelect
        options={nacinSnabdevanjaVodomOptions}
        name={'nacin_snabdevanja_vodom'}
        label="Način snabdevanja vodom"
        allowClear
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      {authStore.isAdmin && (
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'vodno_telo_id'}
          fetchOptinsList={waterStore.fetchWaterBodies}
          labelOptionAccessor="water_body_name"
          valueOptionAccessor="id"
          label={t('POLLUTION.WATER.WATER_BODY')}
          readOnly={false}
          inputName={'vodno_telo_id'}
          defaultOption={defaultWaterBody}
          placeholder={t('POLLUTION.WATER.WATER_BODY')}
        />
      )}

      <FormTextArea
        name="napomena"
        labelCol={10}
        wrapperCol={14}
        rows={3}
        label={t('COMPANY.NOTE')}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
    </>
  );
});
