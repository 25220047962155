import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { IPtp2Korekcije } from './ptp2.types';

const submitDozvoljeneKorekcije = (payload: IPtp2Korekcije, id: string): ApiResponse<string> => {
  return axios.put(`${ROUTES.OBRAZAC}/ptp2/${id}/dozvoljene-korekcije`, payload);
};

export const ptp2Repo = {
  submitDozvoljeneKorekcije,
};
