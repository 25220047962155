import { Col, Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { CRUDModal, ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE, MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { DEO_6_PREDAT_OTPAD_INITIAL_STATE, NACIN_POSTUPANJA_OPTIONS } from 'modules/obrasci/store/deo6/deo6.constants';
import { formCleanPredat } from 'modules/obrasci/store/deo6/deo6.service';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import {
  IGetNoveVrsteOtpada,
  IGetPredatOtpad,
  IGetRazrediEeOtpadaDeo6,
  NacinPostupanjaEnum,
} from 'modules/obrasci/store/deo6/deo6.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IGetFacility } from 'modules/facility/facility.types';
import { drListOptions } from 'utils/optionMakers';
import { resetFormFields } from 'utils/resetFormFieldValues';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { cloneDeep } from 'lodash';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { Select } from 'components/Select/Select';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import Decimal from 'decimal.js';
import { formatNumberWithDecimals } from 'utils/formatNumberWithDecimals';

const EditDeo6NoveVrsteOtpadaButton: React.FC<EditButtonProps<IGetNoveVrsteOtpada>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="deo6-nove-vrste-otpad"
      modalName="deo6-nove-vrste-otpad-modal"
      onClick={() => {
        deo6ObrazacStore.handleChange('index_nove_vrste_id', record.indeksni_broj_otpada_id);
        if (record.indeksni_broj_otpada.weee && Number(deo6ObrazacStore.godina) >= 2020) {
          deo6ObrazacStore.handleChange('weee_categories_nove_vrste', true);
        }
        deo6ObrazacStore.handleChange('nove_vrste_form', record);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

const TableNoveVrsteOtpada = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetNoveVrsteOtpada> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: '500px',
      key: 'waste',
      render: (_: any, record: IGetNoveVrsteOtpada) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_OTPADA')}`,
      width: '150px',

      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetNoveVrsteOtpada) => <EditDeo6NoveVrsteOtpadaButton record={record} />,
    },
  ];

  return (
    <TableData
      name="nova-vrsta-otpada-deo6"
      columns={baseColumns}
      dataSource={[...(deo6ObrazacStore.getPredatForm as IGetPredatOtpad).nove_vrste_otpada_lista]}
    />
  );
});

const EditDeo6PredatOtpadRazredButton: React.FC<EditButtonProps<IGetRazrediEeOtpadaDeo6>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="deo6-nove-vrste-otpad"
      modalName="deo6-razred-predat-otpad-modal"
      onClick={() => deo6ObrazacStore.handleChange('razredi_ee_form', record)}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

const TablePredatRazred: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaDeo6> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaDeo6) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: 100,
      render(_, record) {
        if (!record.kolicina) return <></>;
        return <span>{formatNumberWithDecimals(record.kolicina, 4)}</span>;
      },
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEeOtpadaDeo6) => <EditDeo6PredatOtpadRazredButton record={record} />,
    },
  ];

  return (
    <TableData
      name="predat-otpad-razredi-deo6"
      columns={baseColumns}
      dataSource={[...(deo6ObrazacStore.predat_form as IGetPredatOtpad).razred]}
    />
  );
});

const NewPredatOtpadDeo6Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const preduzeceOptionMaker = (options: IGetApr[]) => {
    return options.map(company => ({
      label: company.full_company_name,
      value: company.id,
    }));
  };

  const postrojenjeOptionMaker = (options: IGetFacility[]) => {
    return options.map(facility => ({
      label: facility.naziv_postrojenja,
      value: (facility as IGetFacility).id,
      disabled: !facility.is_active,
    }));
  };

  const defaultZemljaIzvozaOption: ISifarnikInitial | undefined = deo6ObrazacStore.predat_form.zemlja_izvoza_id
    ? {
        label: (deo6ObrazacStore.getPredatForm as IGetPredatOtpad).zemlja_izvoza.name,
        value: deo6ObrazacStore.getPredatForm.zemlja_izvoza_id,
      }
    : undefined;

  const defaultDOption: ISifarnikInitial | undefined = deo6ObrazacStore.getPredatForm.d_oznaka_id
    ? {
        value: deo6ObrazacStore.getPredatForm.d_oznaka_id,
        label: `${(deo6ObrazacStore.getPredatForm as IGetPredatOtpad).d_oznaka.name} ${
          (deo6ObrazacStore.getPredatForm as IGetPredatOtpad).d_oznaka.description_sr
        }`,
      }
    : undefined;

  const defaultAcitivitiesOfWaste: ISifarnikInitial | undefined = deo6ObrazacStore.getPredatForm.operater_je_id
    ? {
        value: deo6ObrazacStore.getPredatForm.operater_je_id,
        label: `${(deo6ObrazacStore.getPredatForm as IGetPredatOtpad).operater_je.activities_operator_sr}`,
      }
    : undefined;

  const defaultROption: ISifarnikInitial | undefined = deo6ObrazacStore.getPredatForm.r_oznaka_id
    ? {
        value: deo6ObrazacStore.getPredatForm.r_oznaka_id,
        label: `${(deo6ObrazacStore.getPredatForm as IGetPredatOtpad).r_oznaka.name} ${
          (deo6ObrazacStore.getPredatForm as IGetPredatOtpad).r_oznaka.description_sr
        }`,
      }
    : undefined;

  const defaultPreduzeceOption: ISifarnikInitial | undefined = deo6ObrazacStore.getPredatForm.preduzece_id
    ? {
        label: (deo6ObrazacStore.getPredatForm as IGetPredatOtpad).preduzece.full_company_name,
        value: deo6ObrazacStore.getPredatForm.preduzece_id,
      }
    : undefined;

  const defaultPostrojenjeOption: ISifarnikInitial | undefined = deo6ObrazacStore.getPredatForm.postrojenje_id
    ? {
        label: (deo6ObrazacStore.getPredatForm as IGetPredatOtpad).postrojenje.naziv_postrojenja,
        value: deo6ObrazacStore.getPredatForm.postrojenje_id,
      }
    : undefined;

  const defaultTypeOfWaste: ISifarnikInitial | undefined = deo6ObrazacStore.getPredatForm.indeksni_broj_otpada_id
    ? {
        value: deo6ObrazacStore.getPredatForm.indeksni_broj_otpada_id,
        label: `${(deo6ObrazacStore.getPredatForm as IGetPredatOtpad).indeksni_broj_otpada.index_number} ${(
          deo6ObrazacStore.getPredatForm as IGetPredatOtpad
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
      }
    : undefined;

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  useEffect(() => {
    if (Number(deo6ObrazacStore.godina) <= 2019) {
      props.form.setFieldValue('nacin_postupanja', NacinPostupanjaEnum.PREDATO_DRUGOM_OPERATERU);
    }
    if (deo6ObrazacStore.weee_categories) {
      props.form.validateFields(['kolicina']);
    }
    if ((deo6ObrazacStore.predat_form as IGetPredatOtpad).nove_vrste_otpada_lista.length !== 0) {
      props.form.validateFields(['kolicina']);
    }
  }, [deo6ObrazacStore.razredi_ee_form, deo6ObrazacStore.nove_vrste_form]);

  useEffect(() => {
    let arrayToClean: string[] = [];
    arrayToClean = formCleanPredat(props.form.getFieldValue('nacin_postupanja'));
    if (props.form.getFieldValue('nove_vrste_otpada')) {
      arrayToClean = [...arrayToClean, ...['r_oznaka_id', 'broj_dozvole_ponovno_iskoriscenje']];
    } else {
      props.form.setFieldValue('razlog_postojanja_razlike_zbira_kolicina_novih_vrsti_i_kolicine_predatog_otpada', null);
      deo6ObrazacStore.handlePredatForm('nove_vrste_otpada_lista', []);
    }
    resetFormFields(props.form, arrayToClean);
  }, [
    Form.useWatch('nove_vrste_otpada'),
    Form.useWatch('nacin_postupanja'),
    Form.useWatch('preduzece_id'),
    Form.useWatch('naziv_preduzeca'),
  ]);

  const [showReason, setShowReason] = useState(false);

  useEffect(() => {
    let sum = new Decimal(0);
    (deo6ObrazacStore.predat_form as IGetPredatOtpad).nove_vrste_otpada_lista.map((item: IGetNoveVrsteOtpada) => {
      sum = sum.plus(item.kolicina ?? 0);
    });
    if (props.form.getFieldValue('kolicina') > sum.toNumber()) {
      setShowReason(true);
    } else {
      setShowReason(false);
      props.form.setFieldValue('razlog_postojanja_razlike_zbira_kolicina_novih_vrsti_i_kolicine_predatog_otpada', null);
    }
  }, [Form.useWatch('kolicina'), cloneDeep((deo6ObrazacStore.predat_form as IGetPredatOtpad).nove_vrste_otpada_lista)]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'indeksni_broj_otpada_id'}
        disabledAccessor="is_active"
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        disabled={deo6ObrazacStore.index_id !== ''}
        onChange={(e: any) => {
          deo6ObrazacStore.handleChange('index_id', e);
          const obj = deo6ObrazacStore.indexMap(e);
          if (!obj.weee && deo6ObrazacStore.weee_categories) {
            props.form.validateFields(['kolicina']);
          }
          deo6ObrazacStore.checkWeeeCategories(e, 'handlePredatForm');
        }}
        optionsMaker={wasteCatalogueOptions}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina"
        precision={4}
        label={t('OBRASCI.DEO_6.KOLICINA_PREDATOG_OTPADA')}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              if (deo6ObrazacStore.weee_categories) {
                let sum = new Decimal(0);
                (deo6ObrazacStore.predat_form as IGetPredatOtpad).razred.map(
                  (item: IGetRazrediEeOtpadaDeo6) => (sum = sum.plus(item.kolicina ?? 0))
                );
                if (sum.toNumber() !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED')));
                }
              }
              return Promise.resolve();
            },
          },
          {
            validator(_, value) {
              if (props.form.getFieldValue('nove_vrste_otpada')) {
                if ((deo6ObrazacStore.predat_form as IGetPredatOtpad).nove_vrste_otpada_lista.length < 1) {
                  return Promise.reject(new Error(t('Unesite novu vrstu otpada u listu')));
                }
                let sum = new Decimal(0);
                (deo6ObrazacStore.predat_form as IGetPredatOtpad).nove_vrste_otpada_lista.map(
                  (item: IGetNoveVrsteOtpada) => (sum = sum.plus(item.kolicina ?? 0))
                );
                if (sum.toNumber() > value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.VECA_ILI_JEDNAKA_NOVE_VRSTE')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        placeholder={''}
        inputName={'kolicina'}
        colon={true}
        readOnly={readOnly}
      />

      <Col span={24}>
        <Form.Item
          labelCol={{ span: 12 }}
          label={t('OBRASCI.DEO_6.NACIN_POSTUPANJA_OTPADOM')}
          name={'nacin_postupanja'}
          wrapperCol={{ span: 24 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select
            name="nacin_postupanja"
            style={{ width: 100 }}
            // onChange={value => setNacinPostupanja(value)}
            options={NACIN_POSTUPANJA_OPTIONS}
            disabled={!!(Number(deo6ObrazacStore.godina) <= 2019)}
            readOnly={readOnly}
          />
        </Form.Item>
      </Col>

      {props.form.getFieldValue('nacin_postupanja') === NacinPostupanjaEnum.PREDATO_DRUGOM_OPERATERU && (
        <>
          {!props.form.getFieldValue('naziv_preduzeca') && (
            <FormSearchInfiniteScroll
              showSearch={true}
              label={t('COMPANY.FULL_NAME')}
              placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
              formName={'preduzece_id'}
              inputName={'preduzece_id'}
              fetchOptinsList={deo6ObrazacStore.fetchAprPredatOtpad}
              disabledAccessor=""
              labelOptionAccessor="full_company_name"
              valueOptionAccessor="id"
              colon={true}
              defaultOption={defaultPreduzeceOption}
              rules={[REQUIRED_FIELD_RULE(true)]}
              optionsMaker={preduzeceOptionMaker}
              readOnly={readOnly}
            />
          )}

          {Number(deo6ObrazacStore.godina) <= 2019 && !props.form.getFieldValue('preduzece_id') && (
            <FormInput
              name="naziv_preduzeca"
              type="text"
              label={'Slobodan unos preduzeća'}
              rules={[REQUIRED_FIELD_RULE(true)]}
              placeholder={''}
              inputName={'naziv_preduzeca'}
              colon={true}
              readOnly={readOnly}
              disabled={true}
            />
          )}

          <FormSearchInfiniteScroll
            formName={'operater_je_id'}
            fetchOptinsList={deo6ObrazacStore.fetchActivitiesOfWasteOperators}
            labelOptionAccessor="activities_operator_sr"
            valueOptionAccessor="id"
            rules={[REQUIRED_FIELD_RULE(true)]}
            inputName={'operater_je_id'}
            label={t('OBRASCI.DEO_6.OTPAD_JE_PREDAT_OPERATERU')}
            colon={true}
            placeholder={''}
            defaultOption={defaultAcitivitiesOfWaste}
            readOnly={readOnly}
          />
          <FormInput
            name="broj_dozvole"
            type="text"
            label={t('OBRASCI.DEO_6.BROJ_DOZVOLE_UPRAVLJANJE_OTPADOM')}
            rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)]}
            placeholder={''}
            inputName={'broj_dozvole'}
            colon={true}
            readOnly={readOnly}
          />
        </>
      )}

      {props.form.getFieldValue('nacin_postupanja') === NacinPostupanjaEnum.IZVEZENO && (
        <>
          <FormSearchInfiniteScroll
            formName={'zemlja_izvoza_id'}
            fetchOptinsList={deo6ObrazacStore.fetchCountryCode}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[REQUIRED_FIELD_RULE(true)]}
            inputName={'zemlja_izvoza_id'}
            label={t('OBRASCI.GIO5.EXPORT_COUNTRY')}
            colon={true}
            showSearch
            placeholder={''}
            defaultOption={defaultZemljaIzvozaOption}
            // onChange={gio4ObrazacStore.fetchGranica}
            readOnly={readOnly}
          />
          <FormInput
            name="broj_dozvole_za_izvoz"
            type="text"
            label={t('OBRASCI.GIO4.EXPORT_LICENSE')}
            rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)]}
            placeholder={''}
            inputName={'broj_dozvole_za_izvoz'}
            colon={true}
            readOnly={readOnly}
          />
        </>
      )}

      {props.form.getFieldValue('nacin_postupanja') === NacinPostupanjaEnum.SOPSTVENI_LOKACIJA && (
        <>
          <FormSearchInfiniteScroll
            formName={'d_oznaka_id'}
            fetchOptinsList={deo6ObrazacStore.fetchDList}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'d_oznaka_id'}
            label={t('OBRASCI.OBRAZAC5.D_OZNAKA')}
            colon={true}
            showSearch
            placeholder={''}
            defaultOption={defaultDOption}
            rules={[REQUIRED_FIELD_RULE(true)]}
            optionsMaker={drListOptions}
            readOnly={readOnly}
          />
          <FormInput
            name="broj_dozvole_za_odlaganje"
            type="text"
            label={t('OBRASCI.DEO_6.BROJ_DOZVOLE_ZA_ODLAGANJE')}
            rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)]}
            placeholder={''}
            inputName={'broj_dozvole_za_odlaganje'}
            colon={true}
            readOnly={readOnly}
          />
        </>
      )}
      {props.form.getFieldValue('nacin_postupanja') === NacinPostupanjaEnum.SOPSTVENI_PROCES_PONOVNOG_ISKORISCENJA && (
        <>
          <FormCheckbox name="nove_vrste_otpada" label={t('OBRASCI.DEO_6.PREDTRETMAN')} />
          {!props.form.getFieldValue('nove_vrste_otpada') && (
            <>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'r_oznaka_id'}
                fetchOptinsList={deo6ObrazacStore.fetchRList}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'r_oznaka_id'}
                label={t('OBRASCI.GIO4.R_LIST')}
                colon={true}
                placeholder={''}
                defaultOption={defaultROption}
                rules={[REQUIRED_FIELD_RULE(true)]}
                optionsMaker={drListOptions}
                readOnly={readOnly}
              />
              <FormInput
                name="broj_dozvole_ponovno_iskoriscenje"
                type="text"
                label={t('OBRASCI.DEO_6.BROJ_DOZVOLE_ZA_PONOVNO_ISKORISCENJE')}
                rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)]}
                placeholder={''}
                inputName={'broj_dozvole_ponovno_iskoriscenje'}
                colon={true}
                readOnly={readOnly}
              />
            </>
          )}
          {props.form.getFieldValue('nove_vrste_otpada') &&
            deo6ObrazacStore.getPredatForm.nove_vrste_otpada_lista.length >= 1 &&
            showReason && (
              <FormTextArea
                name="razlog_postojanja_razlike_zbira_kolicina_novih_vrsti_i_kolicine_predatog_otpada"
                label={'Razlog postojanja razlike zbira količina novih vrsta i količina predatog otpada'}
                rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(500)]}
                placeholder={''}
                rows={4}
                labelCol={12}
                wrapperCol={12}
                readOnly={readOnly}
              />
            )}
        </>
      )}

      {props.form.getFieldValue('nacin_postupanja') === NacinPostupanjaEnum.POSTROJENJE && (
        <>
          <FormSearchInfiniteScroll
            label={t('FACILITY.TITLE')}
            placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
            formName={'postrojenje_id'}
            fetchOptinsList={deo6ObrazacStore.fetchPostrojenje}
            labelOptionAccessor="naziv_postrojenja"
            valueOptionAccessor="id"
            showSearch
            defaultOption={defaultPostrojenjeOption}
            rules={[REQUIRED_FIELD_RULE(true)]}
            optionsMaker={postrojenjeOptionMaker}
            readOnly={readOnly}
          />
        </>
      )}

      {props.form.getFieldValue('nacin_postupanja') === NacinPostupanjaEnum.SOPSTVENI_PROCES_PONOVNOG_ISKORISCENJA &&
        props.form.getFieldValue('nove_vrste_otpada') && (
          <InsideContentWrapper
            header={
              <WizardObrazacListHeader
                title={t('OBRASCI.DEO_6.NOVE_VRSTE_OTPADA')}
                name={'deo6-nove-vrste-otpad-form'}
                modalName="deo6-nove-vrste-otpad-modal"
              />
            }
          >
            <TableNoveVrsteOtpada />
          </InsideContentWrapper>
        )}

      {deo6ObrazacStore.weee_categories && (
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              title={t('OBRASCI.GIO_3.TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE')}
              name={'deo6-razred-predat-otpad-form'}
              modalName="deo6-razred-predat-otpad-modal"
            />
          }
        >
          <TablePredatRazred />
        </InsideContentWrapper>
      )}
    </>
  );
});

export const Deo6PredatOtpadModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="80%"
      name="deo6-predat-otpad-modal"
      title={t('WIZARD_TABS.PREDAT_OTPAD')}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.getPredatForm}
      children={({ form }) => <NewPredatOtpadDeo6Form form={form} />}
      submitForm={deo6ObrazacStore.submitPredatForm}
      initialState={DEO_6_PREDAT_OTPAD_INITIAL_STATE}
      dataToChange={'predat_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={deo6ObrazacStore.resetWeeeCategories}
    />
  );
});
