import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { FACILITY_FILTERS_INITIAL_STATE, FACILITY_FILTERS_OPTIONS } from 'modules/facility/facility.constants';
import { facilityStore } from 'modules/facility/facility.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { useEffect } from 'react';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

import { IFilterTableProps } from 'typescript/NrizTypes';

export const FacilityFiltersTable: React.FC = observer(() => {
  const fetchFacilityCompanyList = () => {
    return facilityStore.fetchFacilityCompanyList(paginationInitialState);
  };

  useEffect(() => {
    return () => {
      filtersStore.setFilters(FiltersNameEnum.POSTROJENJA, FACILITY_FILTERS_INITIAL_STATE);
    };
  }, []);

  const [facilityForm] = useForm();

  const data: IFilterTableProps = {
    form: facilityForm,
    initialState: filtersStore.getFilters[FiltersNameEnum.POSTROJENJA],
    filterStoreName: FiltersNameEnum.POSTROJENJA,
    filterOptions: FACILITY_FILTERS_OPTIONS,
    fetchTableList: fetchFacilityCompanyList,
  };

  return <FilterTableForm data={data} />;
});
