import { ReactNode } from 'react';
import loadable from '@loadable/component';
import { AdminRoles, CompanyRoles, UserRoles } from 'modules/user/user.types';
import { Company, Facility, Sifarnici, User, UsersList } from 'pages';
import { Obrasci } from 'pages/Obrasci/Obrasci';
import { OBRASCI_ROUTES } from 'modules/obrasci/obrasci.constants';
import { ObrazacWizard } from 'pages/ObrazacWizard/ObrazacWizard';
import { REGISTRI_CONFIG } from 'modules/registri/registri.constants';
import { Registri } from 'pages/Registri/Registri';
import i18n from 'translations/i18n.config';
import {
  AuditOutlined,
  BookOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  HistoryOutlined,
  HomeOutlined,
  LockOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ObrazacIzvestaji } from 'pages/ObrazacIzvestaji/ObrazacIzvestaji';
import { ZakljucavanjePoGodini } from 'pages/ZakljucavanjeObrazaca/ZakljucavanjePoGodini/ZakljucavanjePoGodini';
import { ZakljucavanjePoTipu } from 'pages/ZakljucavanjeObrazaca/ZakljucavanjePoTipu/ZakljucavanjePoTipu';
import { Obrazac1IzvestajiEnum, ObrazacIzvestajTypeEnum } from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import { IstorijaPromena } from 'pages/IstorijaPromena/IstorijaPromena';
import { ProfileUser } from 'pages/ProfileUser/ProfileUser';

export interface IRouteConfig {
  path: string;
  element: ReactNode;
  key: string;
  children?: IRouteConfig[];
  menuProps?: MenuProps;
  allowedRoles: UserRoles[];
  isSubMenuSelected?: (path: string) => boolean;
}

interface MenuProps {
  menuLink?: string;
  subpath: string[];
  label: string;
  icon: ReactNode;
  roles: UserRoles[];
}

// *** DYNAMIC IMPORTS ***
const UserRegistration = loadable(() => import('pages/UserRegistration/UserRegistration'));
const UserStartPage = loadable(() => import('pages/UserStartPage/UserStartPage'));
const CompaniesList = loadable(() => import('pages/CompaniesList/CompaniesList'));

const OBRASCI_ROUTES_MAP: IRouteConfig[] = OBRASCI_ROUTES.map(route => {
  const routes = [
    {
      path: `${route.path}:obrazac_id/`,
      key: route.path,
      element: <ObrazacWizard wizardConfig={route.wizardConfig} store={route.store} modals={route.modals} />,
      allowedRoles: [...CompanyRoles, ...AdminRoles],
    },
    {
      path: `${route.path}:obrazac_id/:id`,
      key: `${route.path}_id`,
      element: <ObrazacWizard wizardConfig={route.wizardConfig} store={route.store} modals={route.modals} />,
      allowedRoles: [...CompanyRoles, ...AdminRoles],
    },
  ];
  return routes;
}).flat();

const REGISTRI_ROUTES_MAP: IRouteConfig[] = Object.values(REGISTRI_CONFIG).map(registar => {
  const { urlRoute, component } = REGISTRI_CONFIG[registar.key].routes;

  return {
    path: `${urlRoute}/:id`,
    key: urlRoute,
    element: component,
    allowedRoles: AdminRoles,
  };
});

export enum RoutePathEnum {
  REGISTRACIJA = '/registracija-korisnika',
  POCETNA_STRANA_KORISNIK = '/pocetna-strana-korisnika',
  PROFIL = '/profil',
  PREDUZECE = '/preduzece',
  LISTA_PREDUZECA = '/lista-preduzeca',
  POSTROJENJE = '/postrojenje',
  LISTA_KORISNIKA = '/lista-korisnika',
  KORISNIK = '/korisnik',
  LISTA_OBRAZACA = '/lista-obrazaca',
  OBRAZAC = '/obrazac',
  LISTA_PRESEKA = '/spisak-preseka',
  PRESEK = '/presek',
  REGISTRI = '/registri',
  SIFARNICI = '/sifarnici',
  OBRAZAC_IZVESTAJI = '/izvestaji',
  ZAKLJUCAVANJE = '/zakljucavanje',
  ISTORIJA_PROMENA = '/istorija-promena',
  ZAKLJUCAVANJE_KOREKCIJA = 'zakljucavanje/korekcija',
  ZAKLJUCAVANJE_KREIRANJE = 'zakljucavanje/kreiranje',
}

export const routes: IRouteConfig[] = [
  {
    path: RoutePathEnum.REGISTRACIJA,
    element: <UserRegistration />,
    key: RoutePathEnum.REGISTRACIJA,
    allowedRoles: [],
  },
  {
    path: RoutePathEnum.POCETNA_STRANA_KORISNIK,
    element: <UserStartPage />,
    key: RoutePathEnum.POCETNA_STRANA_KORISNIK,
    allowedRoles: CompanyRoles,
  },
  {
    path: `${RoutePathEnum.PROFIL}/:id`,
    element: <ProfileUser />,
    key: RoutePathEnum.PROFIL,
    allowedRoles: [...CompanyRoles, ...AdminRoles],
    menuProps: {
      subpath: [RoutePathEnum.PROFIL],
      label: 'Profil',
      icon: <UserOutlined />,
      roles: [...CompanyRoles, ...AdminRoles],
    },
  },

  {
    path: RoutePathEnum.LISTA_PREDUZECA,
    element: <CompaniesList />,
    key: RoutePathEnum.LISTA_PREDUZECA,
    allowedRoles: AdminRoles,
    menuProps: {
      subpath: [RoutePathEnum.LISTA_PREDUZECA, RoutePathEnum.PREDUZECE, RoutePathEnum.POSTROJENJE],
      label: i18n.t('COMPANY.COMPANIES'),
      icon: <HomeOutlined />,
      roles: AdminRoles,
    },
  },
  {
    path: `${RoutePathEnum.PREDUZECE}/:id`,
    element: <Company />,
    key: RoutePathEnum.PREDUZECE,
    allowedRoles: [...CompanyRoles, ...AdminRoles],
    menuProps: {
      subpath: [RoutePathEnum.PREDUZECE, RoutePathEnum.POSTROJENJE],
      label: 'Preduzeće',
      icon: <HomeOutlined />,
      roles: CompanyRoles,
    },
  },
  {
    path: RoutePathEnum.LISTA_KORISNIKA,
    element: <UsersList />,
    key: RoutePathEnum.LISTA_KORISNIKA,
    allowedRoles: [...AdminRoles, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    menuProps: {
      subpath: [RoutePathEnum.LISTA_KORISNIKA, RoutePathEnum.KORISNIK],
      label: i18n.t('USER.USERS'),
      icon: <TeamOutlined />,
      roles: [...AdminRoles, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    },
  },
  {
    path: `${RoutePathEnum.KORISNIK}/:id`,
    element: <User />,
    key: RoutePathEnum.KORISNIK,
    allowedRoles: [...CompanyRoles, ...AdminRoles],
  },
  {
    path: `${RoutePathEnum.POSTROJENJE}/:id`,
    element: <Facility />,
    key: RoutePathEnum.POSTROJENJE,
    allowedRoles: [...AdminRoles, UserRoles.CompanyOwner, UserRoles.FacilityUser],
  },
  {
    path: RoutePathEnum.LISTA_OBRAZACA,
    element: <Obrasci />,
    key: RoutePathEnum.LISTA_OBRAZACA,
    allowedRoles: [...CompanyRoles, ...AdminRoles],
    menuProps: {
      subpath: [RoutePathEnum.LISTA_OBRAZACA, RoutePathEnum.OBRAZAC],
      label: i18n.t('OBRASCI.OBRASCI'),
      icon: <AuditOutlined />,
      roles: [...CompanyRoles, ...AdminRoles],
    },
  },
  ...OBRASCI_ROUTES_MAP,
  {
    path: `${RoutePathEnum.REGISTRI}/:registar`,
    element: <Registri />,
    key: RoutePathEnum.REGISTRI,
    allowedRoles: AdminRoles,
    menuProps: {
      subpath: [RoutePathEnum.REGISTRI],
      label: i18n.t('REGISTRI.TITLE'),
      icon: <BookOutlined />,
      roles: AdminRoles,
    },
  },
  ...REGISTRI_ROUTES_MAP,
  // {
  //   path: `${RoutePathEnum.LISTA_PRESEKA}`,
  //   element: <PreseciList />,
  //   key: RoutePathEnum.LISTA_PRESEKA,
  //   allowedRoles: AdminRoles,
  //   menuProps: {
  //     subpath: [RoutePathEnum.LISTA_PRESEKA, RoutePathEnum.PRESEK],
  //     label: 'Preseci',
  //     icon: <FundOutlined />,
  //     roles: AdminRoles,
  //   },
  // },
  // {
  //   path: `${RoutePathEnum.PRESEK}`,
  //   element: <SinglePresek />,
  //   key: RoutePathEnum.PRESEK,
  //   allowedRoles: AdminRoles,
  // },

  {
    path: RoutePathEnum.SIFARNICI,
    element: <Sifarnici />,
    key: RoutePathEnum.SIFARNICI,
    allowedRoles: AdminRoles,
    menuProps: {
      subpath: ['sifarnici'],
      label: i18n.t('Šifarnici'),
      icon: <DatabaseOutlined />,
      roles: AdminRoles,
    },
  },
  {
    path: RoutePathEnum.OBRAZAC_IZVESTAJI,
    element: <ObrazacIzvestaji />,
    key: RoutePathEnum.OBRAZAC_IZVESTAJI,
    allowedRoles: AdminRoles,
    menuProps: {
      menuLink: `${RoutePathEnum.OBRAZAC_IZVESTAJI}?type=${ObrazacIzvestajTypeEnum.OBRAZAC_1}&subtype=${Obrazac1IzvestajiEnum.GORIVA}`,
      subpath: [RoutePathEnum.OBRAZAC_IZVESTAJI],
      label: i18n.t('OBRAZAC_IZVESTAJI.OBRAZAC_IZVESTAJI'),
      icon: <FolderOpenOutlined />,
      roles: AdminRoles,
    },
  },
  {
    path: RoutePathEnum.ZAKLJUCAVANJE,
    element: <></>,
    key: RoutePathEnum.ZAKLJUCAVANJE,
    allowedRoles: AdminRoles,
    menuProps: {
      subpath: [],
      label: i18n.t('ZAKLJUCAVANJE_OBRAZACA.TITLE'),
      icon: <LockOutlined />,
      roles: AdminRoles,
    },
    children: [
      {
        path: RoutePathEnum.ZAKLJUCAVANJE_KREIRANJE,
        element: <ZakljucavanjePoGodini />,
        key: RoutePathEnum.ZAKLJUCAVANJE_KREIRANJE,
        allowedRoles: AdminRoles,
        menuProps: {
          subpath: [],
          label: i18n.t('ZAKLJUCAVANJE_OBRAZACA.UNOS_NOVIH'),
          icon: <></>,
          roles: AdminRoles,
        },
      },
      {
        path: RoutePathEnum.ZAKLJUCAVANJE_KOREKCIJA,
        element: <ZakljucavanjePoTipu />,
        key: RoutePathEnum.ZAKLJUCAVANJE_KOREKCIJA,
        allowedRoles: AdminRoles,
        menuProps: {
          subpath: [],
          label: i18n.t('ZAKLJUCAVANJE_OBRAZACA.KOREKCIJA'),
          icon: <></>,
          roles: AdminRoles,
        },
      },
    ],
  },
  {
    path: RoutePathEnum.ISTORIJA_PROMENA,
    element: <IstorijaPromena />,
    key: RoutePathEnum.ISTORIJA_PROMENA,
    allowedRoles: AdminRoles,
    menuProps: {
      menuLink: RoutePathEnum.ISTORIJA_PROMENA,
      subpath: [],
      label: i18n.t('ISTORIJA_PROMENA.TITLE'),
      icon: <HistoryOutlined />,
      roles: AdminRoles,
    },
  },
];
