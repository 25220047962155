import { IGetWaterBodies } from 'modules/sifarnici/store/waterBodies/waterBodies.types';
import { IPagination } from '../../../typescript/NrizTypes';
import { IGetFacility } from 'modules/facility/facility.types';

export interface IGetWater extends IPostWater {
  sliv: ISliv;
  id: string;
  vodno_telo: IGetWaterBodies;
  recipient_type: ITypesOfRecipient;
  postrojenje: IGetFacility;
}

export interface ResponseWater extends IPagination {
  items: IGetWater[];
}

export interface IPostWater {
  broj_ispusta: number | null;
  naziv_ispusta: string;
  zakljucane_koordinate: boolean;
  nacin_snabdevanja_vodom: NacinSnabdevanjaVodom | undefined;
  aktivan: boolean;
  koordinate_long: number | null;
  koordinate_lat: number | null;
  projektovani_kapacitet_ispusta: number | null;
  naziv_recipijenta: string;
  recipient_type_id: string | undefined;
  sliv_id: string | undefined;
  vodno_telo_id: string;
  sanitarna: boolean;
  tehnoloska: boolean;
  rashladna: boolean;
  atmosferska: boolean;
  postojanje_postrojenja_za_preciscavanje: boolean;
  resetka: boolean;
  sito_mehanicki_filter: boolean;
  peskolov: boolean;
  aerisani_peskolov: boolean;
  taloznik_uzduzni: boolean;
  taloznik_laminarni: boolean;
  taloznik_radijalni: boolean;
  separator_masti_i_ulja: boolean;
  flotator: boolean;
  pescani_filter: boolean;
  uredjaj_za_neutralizaciju: boolean;
  uredjaj_za_detoksikaciju: boolean;
  jonska_izmena: boolean;
  hlorisanje: boolean;
  ozonizacija: boolean;
  laguna: boolean;
  aeracioni_bazen: boolean;
  bio_filter: boolean;
  bio_disk: boolean;
  nitrifikacija: boolean;
  denitrifikacija: boolean;
  fermentacija_mulja: boolean;
  prirodna_izmena_toplote: boolean;
  rashladni_toranj_prirodna_cirkulacija_vazduha: boolean;
  rashladni_toranj_prisilna_cirkulacija_vazduha: boolean;
  zatvoreni_rashladni_uredjaji: boolean;
  napomena?: string;
  postrojenje_id?: string;
}

export interface ITypesOfRecipient {
  type?: string;
  type_sr: string;
  id: string;
}

export interface ISliv {
  basin_name: string;
  id: string;
}

export interface ResponseTypesOfRecipientList extends IPagination {
  items: ITypesOfRecipient;
}

export interface ResponseSlivList extends IPagination {
  items: ISliv[];
}

export interface RequestwaterList extends Partial<IPagination> {
  postrojenje_id: string;
}

export enum NacinSnabdevanjaVodom {
  vodovod = 'vodovod',
  drugiIzvori = 'drugi_izvori_snabdevanja',
}
