import { Form } from 'antd';
import { Switch } from 'components/Switch/Switch';
import React from 'react';
import { FormSwitchProps } from 'typescript/NrizTypes';

export const FormSwitch: React.FC<FormSwitchProps> = props => {
  return (
    <Form.Item
      label={props.label}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      name={props.name}
      valuePropName="checked"
      rules={props.rules}
      style={props.style}
      className={props.className}
      colon={props.colon}
      hidden={props.hidden}
      tooltip={props.tooltip}
    >
      <Switch
        disabled={props.disabled}
        name={'props.name'}
        onChange={e => props.onSwitchChange && props.onSwitchChange(e)}
      />
    </Form.Item>
  );
};
