import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styles from './FilterTableForm.module.scss';
import {
  FormInputProps,
  IFormScrollWithObjProps,
  FormSelectProps,
  IFilterTableProps,
  IRangePickerProps,
} from 'typescript/NrizTypes';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { filtersStore } from 'store/filteri.store';
import { nrizService } from 'modules/nriz/nriz.service';
import { SortByButton } from 'components/Buttons/SortByButton/SortByButton';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

interface IFiltersTableProps {
  data: IFilterTableProps;
}

export const FilterTableForm: React.FC<IFiltersTableProps> = observer(props => {
  useEffect(() => {
    props.data.form.resetFields();
    props.data.form.setFieldsValue(props.data.initialState);
  }, [props.data.resetForm]);

  return (
    <Form
      initialValues={nrizService.setBoolToNull(props.data.initialState)}
      onFinish={() => {
        const allValues = props.data.form.getFieldsValue(true);

        filtersStore.setFilters(
          props.data.filterStoreName,
          nrizService.pickFields(props.data.modifyPayload ? props.data.modifyPayload(allValues) : allValues)
        );
        props.data.fetchTableList();
      }}
      layout="vertical"
      className={styles.form}
      form={props.data.form}
    >
      <div className={styles.filterTitle}>Filteri</div>
      <Row gutter={10}>
        {props.data.filterOptions.map((item: ISifarniciTypeOfForm, index: number) => {
          if (item.type_of_form === 'title' || item.type_of_form === 'area') return;
          if ((item.type_of_form === 'input' || item.type_of_form === 'input_number') && !item.hideInput) {
            return (
              <Col span={4} className={styles.input_wrapper} key={index}>
                <Col span={item.sortName ? 22 : 24} key={index}>
                  <FormInput
                    name={item.filterName || (item as FormInputProps).name}
                    inputName={(item as FormInputProps).inputName}
                    onInputChange={(item as FormInputProps).onInputChange}
                    label={(item as FormInputProps).label}
                    placeholder={(item as FormInputProps).placeholder}
                    rules={(item as FormInputProps).rules}
                  />
                </Col>
                <Col span={2}>{item.sortName && <SortByButton name={item.sortName} form={props.data.form} />}</Col>
              </Col>
            );
          }

          if (item.type_of_form === 'switch' && !item.hideInput) {
            return (
              <Col span={4} key={index}>
                <FormSelect
                  name={item.filterName || (item as FormSelectProps).name}
                  label={(item as FormSelectProps).label}
                  rules={(item as FormSelectProps).rules}
                  options={[
                    { label: 'Da', value: true },
                    { label: 'Ne', value: false },
                  ]}
                  onChange={(item as FormSelectProps)?.onChange}
                />
              </Col>
            );
          }

          if (item.type_of_form === 'select' && !item.hideInput) {
            return (
              <Col span={4} key={index}>
                <FormSelect
                  mode={(item as FormSelectProps).mode}
                  name={item.filterName || (item as FormSelectProps).name}
                  label={(item as FormSelectProps).label}
                  options={(item as FormSelectProps).options}
                  rules={(item as FormSelectProps).rules}
                  placeholder={(item as FormSelectProps).placeholder}
                />
              </Col>
            );
          }

          if (item.type_of_form === 'date' && !item.hideInput) {
            return (
              <Col span={4} className={styles.input_wrapper} key={index}>
                <Col span={item.sortName ? 22 : 24}>
                  <FormDatePicker
                    label={(item as IRangePickerProps).label}
                    placeholder={(item as IRangePickerProps).placeholder}
                    name={item.filterName || (item as IRangePickerProps).name}
                    format={(item as IRangePickerProps).format}
                    picker={(item as IRangePickerProps).picker}
                    onChange={(item as IRangePickerProps).onChange}
                    disabledDate={(item as IRangePickerProps).disabledDate}
                    disabled={(item as IRangePickerProps).disabled}
                    rules={(item as IRangePickerProps).rules}
                    showTime={(item as IRangePickerProps).showTime}
                  />
                </Col>
                <Col span={2}>{item.sortName && <SortByButton name={item.sortName} form={props.data.form} />}</Col>
              </Col>
            );
          }
          if (item.type_of_form === 'searchScroll' && !item.hideInput) {
            return (
              <Col span={4} className={styles.input_wrapper} key={index}>
                <Col span={item.sortName ? 22 : 24}>
                  <FormScrollWithObjInside
                    showSearch={true}
                    formName={item.filterName || (item as IFormScrollWithObjProps).formName}
                    label={(item as IFormScrollWithObjProps).label}
                    options={(item as IFormScrollWithObjProps).options}
                    labelOptionAccessor={(item as IFormScrollWithObjProps).labelOptionAccessor}
                    valueOptionAccessor={(item as IFormScrollWithObjProps).valueOptionAccessor}
                    disabledAccessor={(item as IFormScrollWithObjProps).disabledAccessor}
                    fetchOptinsList={(item as IFormScrollWithObjProps).fetchOptinsList}
                    onChange={(item as IFormScrollWithObjProps).onChange}
                    placeholder={(item as IFormScrollWithObjProps).placeholder}
                    resetOption={(item as IFormScrollWithObjProps).resetOption}
                    filtersForFetch={(item as IFormScrollWithObjProps).filtersForFetch}
                    rules={(item as IFormScrollWithObjProps).rules}
                    optionsMaker={(item as IFormScrollWithObjProps).optionsMaker}
                    customParamsForSearch={(item as IFormScrollWithObjProps).customParamsForSearch}
                  />
                </Col>
                <Col span={2}>{item.sortName && <SortByButton name={item.sortName} form={props.data.form} />}</Col>
              </Col>
            );
          }
        })}
        <Col className={styles.search}>
          <Form.Item>
            <div className={styles.filterButtons}>
              <Button htmlType="submit">
                Pretraži <SearchOutlined />
              </Button>
              {props.data.clearFilters && (
                <Button
                  onClick={() => {
                    props.data.form.resetFields();
                    filtersStore.setFilters(props.data.filterStoreName, {});
                    props.data.clearFilters && props.data.clearFilters();
                  }}
                >
                  Obriši filtere
                </Button>
              )}
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
