import { FormInstance } from 'antd';
import { IGetProductsNr, IGetProductsType } from './productsNr.types';
import i18n from '../../../../translations/i18n.config';
import dayjs from 'dayjs';

class ProductNrService {
  makeVrsteProizvodaInput = (form: FormInstance<IGetProductsNr>) => {
    const datumOd = form.getFieldValue('period_from_date');
    const datumDo = form.getFieldValue('period_to_date');
    const vrstaProzivoda = form.getFieldValue('products_category');

    const period =
      datumDo && datumOd
        ? `za period ${i18n.t('SIFARNICI.OD').toLowerCase()}: ${dayjs(datumOd)?.format('DD. MM. YYYY.')} ${i18n
            .t('SIFARNICI.DO')
            .toLowerCase()}: ${dayjs(datumDo)?.format('DD. MM. YYYY.')}`
        : '';
    form.setFieldValue('display_product_category', `${vrstaProzivoda} ${period}`);
  };

  fetchProductsNr = (item: IGetProductsNr) => {
    const datumOd = item.period_from_date ? dayjs(item.period_from_date) : '';
    const datumDo = item.period_to_date ? dayjs(item.period_to_date) : '';
    return { ...item, period_from_date: datumOd, period_to_date: datumDo };
  };

  makeProizvodiInput = (form: FormInstance<IGetProductsType>) => {
    const name = form.getFieldValue('name');
    const valuta = form.getFieldValue('currencyor');
    const jedinica = form.getFieldValue('unit');
    const cena = form.getFieldValue('priceor_percentageof_pdv');

    const bracket = `(${cena || ''} ${valuta || ''} ${`/ ${jedinica || ''}`})`;

    const input_value = `${name || ''} ${((valuta || jedinica || cena) && bracket) || ''}`;

    form.setFieldValue('display_product', input_value);
  };
}

export const productNrService = new ProductNrService();
