import i18n from 'translations/i18n.config';
import {
  IGetDeo6Obrazac,
  IPostDeo6Obrazac,
  IPostPonovnaUpotrebaDeo6,
  IPostPredatOtpad,
  IPostPreuzetOtpad,
  IPostRazrediEEPreuzet,
  IPostRazrediEeOtpadaDeo6,
  IPostSkladistaDeo6,
  NacinPostupanjaEnum,
  PreuzetOdEnum,
} from './deo6.types';

export const DEO_6_INITIAL_STATE: IPostDeo6Obrazac = {
  datum: '',
  preduzece_id: '',
  postrojenje_id: '',
  napomena: '',
  skladiste: [],
  preuzet_otpad: [],
  predat_otpad: [],
  ponovna_upotreba_otpada: [],
};

export const DEO_6_SKLADISTE_INITIAL_STATE: IPostSkladistaDeo6 = {
  indeksni_broj_otpada_id: '',
  kolicina_prvi_januar: null,
  razred: [],
};

export const DEO_6_PREDAT_OTPAD_INITIAL_STATE: IPostPredatOtpad = {
  indeksni_broj_otpada_id: '',
  kolicina: null,
  razred: [],
  naziv_preduzeca: '',
  nove_vrste_otpada_lista: [],
  nacin_postupanja: '',
  preduzece_id: '',
  operater_je_id: '',
  broj_dozvole: '',
  r_oznaka_id: '',
  broj_dozvole_ponovno_iskoriscenje: '',
  d_oznaka_id: '',
  broj_dozvole_za_odlaganje: '',
  postrojenje_id: '',
  zemlja_izvoza_id: '',
  broj_dozvole_za_izvoz: '',
  razlog_postojanja_razlike_zbira_kolicina_novih_vrsti_i_kolicine_predatog_otpada: '',
  nove_vrste_otpada: false,
};

export const DEO_6_PREUZET_OTPAD_INITIAL_STATE: IPostPreuzetOtpad = {
  razred: [],
  indeksni_broj_otpada_id: '',
  kolicina: null,
  preuzet_od: '',
  postrojenje_id: '',
  naziv_preduzeca: '',
  zemlja_izvoza_id: '',
  preduzece_id: '',
  proizvodjac_otpada: false,
  sakupljac: false,
  operater_na_deponiji: false,
  operater_tretmana_otpada: false,
  skladistar: false,
  uvoznik_otpada: false,
  drugi_vlasnik: false,
};

export const DEO_6_PONOVNA_UPOTREBA_INITIAL_STATE: IPostPonovnaUpotrebaDeo6 = {
  indeksni_broj_otpada_id: '',
  kolicina: null,
  razred: [],
};
export const DEO_6_NOVE_VRSTE_OTPADA_INITIAL_STATE: IPostPonovnaUpotrebaDeo6 = {
  indeksni_broj_otpada_id: '',
  kolicina: null,
  razred: [],
};

export const DEO_6_RAZREDI_EE_OTPADA_INITIAL_STATE: IPostRazrediEeOtpadaDeo6 = {
  razred_ee_otpada_id: '',
  kolicina: null,
};

export const DEO_6_RAZREDI_PREUZET_INITIAL_STATE: IPostRazrediEEPreuzet = {
  razred_ee_otpada_id: '',
  kolicina_domacinstva: null,
  kolicina_drugi_izvor: null,
  ukupna_kolicina: null,
};

export const PREUZET_CHECKBOX: { label: string; name: string }[] = [
  {
    label: i18n.t('OBRASCI.DEO_6.PROIZVODJAC_OTPADA'),
    name: 'proizvodjac_otpada',
  },
  {
    label: i18n.t('OBRASCI.DEO_6.SAKUPLJAC'),
    name: 'sakupljac',
  },
  {
    label: i18n.t('OBRASCI.DEO_6.OPERATER_NA_DEPONIJI'),
    name: 'operater_na_deponiji',
  },
  {
    label: i18n.t('OBRASCI.DEO_6.OPERATER_TRETMANA_OTPADA'),
    name: 'operater_tretmana_otpada',
  },
  {
    label: i18n.t('OBRASCI.DEO_6.SKLADISTAR'),
    name: 'skladistar',
  },
  {
    label: i18n.t('OBRASCI.DEO_6.UVOZNIK_OTPADA'),
    name: 'uvoznik_otpada',
  },
  {
    label: i18n.t('OBRASCI.DEO_6.DRUGI_VLASNIK_OTPADA'),
    name: 'drugi_vlasnik',
  },
];

export const PREUZET_OD_OPTIONS = [
  { value: PreuzetOdEnum.FIZICKO_LICE, label: i18n.t('OBRASCI.DEO_6.FIZICKO_LICE') },
  {
    value: PreuzetOdEnum.PRAVNO_LICE,
    label: i18n.t('OBRASCI.DEO_6.PRAVNO_LICE'),
  },
];

export const PREUZET_OD_2020_OPTIONS = [
  { value: PreuzetOdEnum.FIZICKO_LICE, label: i18n.t('OBRASCI.DEO_6.FIZICKO_LICE') },
  {
    value: PreuzetOdEnum.PRAVNO_LICE,
    label: i18n.t('OBRASCI.DEO_6.PRAVNO_LICE'),
  },
  { value: PreuzetOdEnum.SOPSTVENO_POSTROJENJE, label: 'Proizvedene količine otpada sopstvenog postrojenja' },
  {
    value: PreuzetOdEnum.SOPSTVENI_UVOZ,
    label: i18n.t('OBRASCI.DEO_6.SOPSTVENI_UVOZ'),
  },
  {
    value: PreuzetOdEnum.POSTROJENJE,
    label: i18n.t('OBRASCI.DEO_6.DRUGOG_POSTROJENJA'),
  },
];

export const NACIN_POSTUPANJA_OPTIONS = [
  {
    value: NacinPostupanjaEnum.PREDATO_DRUGOM_OPERATERU,
    label: i18n.t('OBRASCI.DEO_6.PREDAT_DRUGOM_OPERATERU'),
  },
  {
    value: NacinPostupanjaEnum.SOPSTVENI_PROCES_PONOVNOG_ISKORISCENJA,
    label: i18n.t('OBRASCI.DEO_6.UKLJUCEN_U_SOPSTVENI_PROCES'),
  },

  { value: NacinPostupanjaEnum.SOPSTVENI_LOKACIJA, label: i18n.t('OBRASCI.DEO_6.ODLOZENO_NA_SOPSTVENOJ_LOKACIJI') },
  {
    value: NacinPostupanjaEnum.POSTROJENJE,
    label: i18n.t('OBRASCI.DEO_6.PREDATO_DRUGOM_POSTROJENJU'),
  },
  { value: NacinPostupanjaEnum.IZVEZENO, label: i18n.t('OBRASCI.DEO_6.IZVEZENO') },
];

export const FILTER_PREUZET_OTPAD =
  '9f3d3e64-784a-4fdE-a67d-ae372b3f0c90,7bdcca66-7668-4b89-a279-84212056ab6c,8a3a7889-8c70-4342-bc5A-fff7587c6b0c,40fb460c-2610-4c07-841f-1940865b8A0b,fa9518f0-5271-4039-adef-c588a922b5f8,ef65a737-8c62-4216-a35c-159e04e4f78d';

export const FILTER_PREDAT_OTPAD =
  '9f3d3e64-784a-4fde-a67d-ae372b3f0c90,fa9518f0-5271-4039-adef-c588a922b5f8,40fb460c-2610-4c07-841f-1940865b8a0b,8a3a7889-8c70-4342-bc5a-fff7587c6b0c';

export const otpadListsKeys: { key: keyof IGetDeo6Obrazac; operation: '+' | '-' }[] = [
  { key: 'preuzet_otpad', operation: '+' },
  {
    key: 'predat_otpad',
    operation: '-',
  },
  {
    key: 'ponovna_upotreba_otpada',
    operation: '-',
  },
  {
    key: 'skladiste',
    operation: '+',
  },
];

export const deo6KalkulacijaMapConfig = {
  otpad: {
    idAccessor: 'indeksni_broj_otpada_id',
    objectAccessor: 'indeksni_broj_otpada',
    kolicinaAccessorArray: ['kolicina', 'kolicina_prvi_januar'],
    innerOtpadAcessor: 'nove_vrste_otpada_lista',
  },
  razred: {
    idAccessor: 'razred_ee_otpada_id',
    objectAccessor: 'razred_ee_otpada',
    kolicinaAccessorArray: ['kolicina', 'ukupna_kolicina'],
  },
};
