import to from 'await-to-js';
import { isEmpty } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { nrizService } from 'modules/nriz/nriz.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { facilityStore } from 'modules/facility/facility.store';
import { formStore } from 'store/FormStore';
import { modalStore } from 'store/ModalStore';
import { IPagination } from 'typescript';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetWater, IPostWater } from 'modules/sourcesOfPollution/water/water.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { waterInitialState } from './water.constants';
import { waterService } from './water.service';
import { IGetRecipientType } from 'modules/sifarnici/store/recipientType/recipientType.types';
import { IGetWaterBodies } from 'modules/sifarnici/store/waterBodies/waterBodies.types';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { ITablePagination } from 'components/TableData/TableData';
import { IGetSliv } from 'modules/sifarnici/store/sliv/sliv.types';

class WaterStore {
  constructor() {
    makeAutoObservable(this);
  }
  water: IPostWater | IGetWater = waterInitialState;
  waterList: IGetWater[] = [];
  waterListPagination: IPagination = paginationInitialState;

  waterFormData = false;
  waterShowDevices = false;

  waterDeviceTreatmentList: Partial<IPostWater> = {};
  waterShowDevicesTreatmentError = false;
  waterValidationDevicesTreatment = true;

  waterTypeOfDischargedList: string[] = [];
  waterShowTypeOfDischargedError = false;

  get getWaterList(): IGetWater[] {
    return this.waterList;
  }

  get getWater(): IGetWater {
    return this.water as IGetWater;
  }

  get getWaterShowDevicesTreatmentError() {
    return this.waterShowDevicesTreatmentError;
  }

  get getWaterShowTypeOfDischargedError() {
    return this.waterShowTypeOfDischargedError;
  }

  get getShowWaterDevices() {
    return this.waterShowDevices;
  }
  get getWaterDeviceTreatmentList() {
    return this.waterDeviceTreatmentList;
  }

  handleChange = (type: keyof WaterStore, value: any) => {
    (this as any)[type] = value;
  };

  changeWaterDeviceTreatmentList = (type: string, value: string) => {
    this.waterDeviceTreatmentList = { ...this.waterDeviceTreatmentList, [type]: value };
    let numberOfTrueDeviceTreatment = 0;

    Object.values(this.waterDeviceTreatmentList).map(value => {
      if (value === true) {
        numberOfTrueDeviceTreatment += 1;
      }
    });

    if (numberOfTrueDeviceTreatment > 0) {
      runInAction(() => {
        this.waterShowDevicesTreatmentError = false;
        this.waterValidationDevicesTreatment = true;
      });
    } else {
      runInAction(() => {
        this.waterValidationDevicesTreatment = false;
      });
    }
  };

  changeWaterTypeOfDichargedList = (type: string, value: boolean) => {
    const indexOfType = this.waterTypeOfDischargedList.indexOf(type);

    if (value === true && indexOfType === -1) {
      this.waterTypeOfDischargedList.push(type);
    } else if (value === false && indexOfType !== -1) {
      this.waterTypeOfDischargedList.splice(indexOfType, 1);
    }

    if (this.waterTypeOfDischargedList.length > 0) {
      runInAction(() => {
        this.waterShowTypeOfDischargedError = false;
      });
    } else {
      runInAction(() => {
        this.waterShowTypeOfDischargedError = true;
      });
    }
  };

  checkIfWaterTypeOfDischargedTrue = (water: IGetWater) => {
    for (const key in water) {
      if (Object.prototype.hasOwnProperty.call(water, key) && water[key as keyof IGetWater] === true) {
        this.waterTypeOfDischargedList.push(key);
      }
    }
  };

  changeShowWaterDevices = (checked: any) => {
    if (!checked) {
      Object.keys(this.waterDeviceTreatmentList).forEach(key => {
        runInAction(() => (this.waterDeviceTreatmentList = { ...this.waterDeviceTreatmentList, [key]: false }));
      });
      runInAction(() => {
        // this.setWater(this.waterDeviceTreatmentList);
        this.waterShowDevicesTreatmentError = false;
        this.waterValidationDevicesTreatment = true;
      });
      runInAction(() => (this.waterShowDevices = false));
    } else {
      runInAction(() => (this.waterValidationDevicesTreatment = false));
      runInAction(() => (this.waterShowDevices = true));
    }
  };

  onWaterModalCancel = () => {
    runInAction(() => {
      this.waterShowDevices = false;
      this.waterDeviceTreatmentList = {};
      this.waterShowDevicesTreatmentError = false;
      this.waterValidationDevicesTreatment = true;

      this.waterTypeOfDischargedList = [];
      this.waterShowTypeOfDischargedError = false;
    });
  };

  checkWaterValidation = () => {
    if (isEmpty(this.waterTypeOfDischargedList) || !this.waterTypeOfDischargedList) {
      runInAction(() => (this.waterShowTypeOfDischargedError = true));
    }

    if (this.waterShowDevices && !this.waterValidationDevicesTreatment) {
      runInAction(() => (this.waterShowDevicesTreatmentError = true));
    }
  };

  fetchWaterList = async (pagination?: ITablePagination): Promise<IPaginatedResponse<IGetWater>> => {
    const [err, res] = await to<IPaginatedResponse<IGetWater>>(
      waterService.fetchWaterList({
        page: pagination?.page || this.waterListPagination.page,
        size: pagination?.size || this.waterListPagination.size,
        postrojenje_id: (facilityStore.facility as IGetFacility).id,
      })
    );
    if (err || !res) return Promise.reject();
    const { items, total, page, size, pages } = res;
    this.setWaterList(items);
    this.handleChange('waterListPagination', { total, page, size, pages });
    return res;
  };

  fetchTypeofRecipient = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetRecipientType>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetRecipientType>(SIFARNICI_ROUTES.TYPE_OF_RECIPIENT, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      /// nema order name_sr
    });
    return response;
  };

  fetchSliv = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetSliv>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetSliv>(SIFARNICI_ROUTES.SLIV, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      /// nema order basin_name
    });
    return response;
  };

  fetchWaterBodies = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetWaterBodies>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWaterBodies>(SIFARNICI_ROUTES.WATER_BODIES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+water_body_name',
    });
    return response;
  };

  postWater = async (values: IPostWater) => {
    const newWater = {
      ...values,
      postrojenje_id: (facilityStore.getFacility as IGetFacility).id,
    };
    const [err, res] = await to<IPostResponse>(waterService.postWater(nrizService.setEmptyValuesToNull(newWater)));
    if (err || !res) return Promise.reject();
    const { id } = res;
    modalStore.removeAllModals();

    let sifarnikSliv = {};
    let sifarnikRecipijent = {};
    let sifarnikVodnoTelo = {};

    if (newWater.sliv_id) {
      sifarnikSliv = nrizService.addSifarnikInObject({
        list: sifarniciStore.list[SIFARNICI_ROUTES.SLIV],
        name: 'sliv',
        id: newWater.sliv_id,
      });
    }

    if (newWater.recipient_type_id) {
      sifarnikRecipijent = nrizService.addSifarnikInObject({
        list: sifarniciStore.list[SIFARNICI_ROUTES.TYPE_OF_RECIPIENT],
        name: 'recipient_type',
        id: newWater.recipient_type_id,
      });
    }
    if (newWater.vodno_telo_id) {
      sifarnikVodnoTelo = nrizService.addSifarnikInObject({
        list: sifarniciStore.list[SIFARNICI_ROUTES.WATER_BODIES],
        name: 'vodno_telo',
        id: newWater.vodno_telo_id,
      });
    }

    const newObjWithSifarnik = {
      ...newWater,
      id,
      ...sifarnikSliv,
      ...sifarnikRecipijent,
      ...sifarnikVodnoTelo,
      aktivan: true,
    } as IGetWater;

    this.setWaterList([newObjWithSifarnik, ...this.waterList]);
    this.handleChange('waterListPagination', nrizService.increaseTotalPagination(this.waterListPagination));
    this.setWater(waterInitialState);
    handleSuccessNotify();
  };

  putWater = async (water: IGetWater) => {
    const [err, res] = await to<IEmptyResponse>(
      waterService.putWater(nrizService.setEmptyValuesToNull({ ...water, aktivan: this.water.aktivan }))
    );
    if (err || !res) return Promise.reject();
    handleSuccessNotify();
    let sifarnikSliv = {};
    let sifarnikRecipijent = {};
    let sifarnikVodnoTelo = {};

    if (water.sliv_id) {
      sifarnikSliv = nrizService.addSifarnikInObject({
        exId: this.water.sliv_id,
        exObj: (this.water as IGetWater).sliv,
        list: sifarniciStore.list[SIFARNICI_ROUTES.SLIV],
        name: 'sliv',
        id: water.sliv_id,
      });
    }

    if (water.recipient_type_id) {
      sifarnikRecipijent = nrizService.addSifarnikInObject({
        exId: this.water.recipient_type_id,
        exObj: (this.water as IGetWater).recipient_type,
        list: sifarniciStore.list[SIFARNICI_ROUTES.TYPE_OF_RECIPIENT],
        name: 'recipient_type',
        id: water.recipient_type_id,
      });
    }

    if (water.vodno_telo_id) {
      sifarnikVodnoTelo = nrizService.addSifarnikInObject({
        exId: this.water.vodno_telo_id,
        exObj: (this.water as IGetWater).vodno_telo,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WATER_BODIES],
        name: 'vodno_telo',
        id: water.vodno_telo_id,
      });
    }

    const waterWithSifarnici = {
      ...water,
      ...sifarnikSliv,
      ...sifarnikRecipijent,
      ...sifarnikVodnoTelo,
      aktivan: this.water.aktivan,
    };
    this.setWaterList(nrizService.changeItemInList(this.waterList, waterWithSifarnici));
    this.setWater(waterInitialState);
    modalStore.removeAllModals();
  };

  setWater(water: IPostWater) {
    if (water.postojanje_postrojenja_za_preciscavanje) {
      runInAction(() => (this.waterShowDevices = true));
    }

    this.water = water;
  }

  setWaterList = (values: IGetWater[]) => {
    this.waterList = [...values];
  };

  submitWater = (water: IPostWater) => {
    this.waterTypeOfDischargedList = [];

    if (formStore.getCrudButtonType === 'submit') {
      return this.postWater(water);
    }
    const newObject = { id: (this.water as IGetWater).id, ...water } as IGetWater;
    if (formStore.getCrudButtonType === 'change') {
      return this.putWater(newObject);
    }
  };

  toggleActivateWater = async (isActive: boolean) => {
    const [err, res] = await to<IEmptyResponse>(
      waterService.toggleActivateWater((this.water as IGetWater).id, isActive)
    );
    if (err || !res) return Promise.reject();
    const newObj = { ...this.water, aktivan: isActive };
    this.setWater(newObj);
    nrizService.changeItemInList(this.waterList, newObj);
    return handleSuccessNotify();
  };
}

export const waterStore = new WaterStore();
