import { EditOutlined } from '@ant-design/icons';
import { Button, Row, Select, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { Content } from 'components/Content/Content';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { nrizService } from 'modules/nriz/nriz.service';
import {
  SIFARNIK_MODAL_DATA,
  SIFARNIK_SELECT_OPTIONS,
  readOnlySifarnikTypes,
} from 'modules/sifarnici/sifarnici.constants.tsx';
import { sifarniciService } from 'modules/sifarnici/sifarnici.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { modalStore } from 'store';
import { SifarniciFilters } from './components/SifarniciFilters/SifarniciFilters';
import { IS_ACTIVE_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { RoutePathEnum } from 'router/router.constants';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

const SifarniciHeaderButtons = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'end'}>
      <Space>
        <Select
          showSearch
          style={{ minWidth: '450px' }}
          placeholder={t('OBRASCI.PLACEHOLDER.CHOOSE_TIP_OBRASCA')}
          optionFilterProp="children"
          onChange={e => {
            filtersStore.setFilters(FiltersNameEnum.SIFARNICI, {});
            sifarniciStore.handleChange('sifarnik_type', e);
          }}
          value={sifarniciStore.sifarnik_type}
          filterOption={nrizService.handleSearchOptions}
          options={SIFARNIK_SELECT_OPTIONS}
        />

        <Link
          to={{
            pathname: RoutePathEnum.SIFARNICI,
            search: `?sifarnik_type=${sifarniciStore?.sifarnik_type}`,
          }}
        >
          <AddNewButton
            name="add-new-sifarnik"
            onButtonClick={() => modalStore.changeModalName('sifarnici-crud-modal')}
            disabled={!sifarniciStore.sifarnik_type}
            label={`Dodaj ${SIFARNIK_MODAL_DATA[sifarniciStore.sifarnik_type].modal.title}`}
          />
        </Link>
      </Space>
    </Row>
  );
});
// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////

const SifarniciHeader = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} id="sifarniciHeader">
      <h1>{t('Šifarnici')}</h1>
      <SifarniciHeaderButtons />
    </Row>
  );
});

// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////

export const Sifarnici: React.FC = observer(() => {
  const baseSifarniciColumns = (): ColumnsType<any> => {
    let columns = sifarniciService.tableColumnsSifarniciFinder(sifarniciStore?.sifarnik_type);

    if (!readOnlySifarnikTypes.includes(sifarniciStore.sifarnik_type)) {
      columns = [IS_ACTIVE_TABLE, ...columns];
    }
    return [
      ...columns,
      {
        title: ``,
        key: 'operation',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (_, record) => {
          return (
            <Link
              to={{
                pathname: RoutePathEnum.SIFARNICI,
                search: `?sifarnik_type=${sifarniciStore?.sifarnik_type}&sifarnik_id=${record?.id}`,
              }}
            >
              <Button
                icon={<EditOutlined />}
                name={`edit-sifarnik-${sifarniciStore?.sifarnik_type}`}
                onClick={() => {
                  modalStore.changeModalName('sifarnici-crud-modal');
                }}
              />
            </Link>
          );
        },
      },
    ];
  };

  const [columnsSifarnici, setColumnsSifarnici] = useState<ColumnsType<any>>(baseSifarniciColumns());

  const sifarniciInit = async () => {
    sifarniciStore.handleChange('sifarniciTablePagination', paginationInitialState);
    await sifarniciStore.fetchSifarnikListTable();

    setColumnsSifarnici(baseSifarniciColumns());
  };

  useEffect(() => {
    sifarniciInit();
  }, [sifarniciStore.sifarnik_type]);

  useEffect(() => {
    return () => sifarniciStore.handleChange('sifarniciTablePagination', paginationInitialState);
  }, []);

  return (
    <Content header={<SifarniciHeader />}>
      <div id="sifarniciFilters">
        <SifarniciFilters />
      </div>
      <TableData
        name="sifarnici"
        scrollConfig={['#sifarniciHeader', '#sifarniciFilters']}
        columns={[...columnsSifarnici]}
        storePagination={sifarniciStore.sifarniciTablePagination}
        dataSource={[...sifarniciStore.sifarniciListTable]}
        refetchData={sifarniciStore.fetchSifarnikListTable}
      />
    </Content>
  );
});
