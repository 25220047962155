import { FormInstance, useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { istorijaPromenaStore } from 'modules/istorijaPromena/istorijaPromena.store';
import { ISTORIJA_PROMENA_FILTERS_DATA } from 'modules/istorijaPromena/istroijaPromena.constants';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

import React from 'react';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const IstorijaPromenaFilters: React.FC = observer(() => {
  const fetchIstorijaPromena = () => {
    return istorijaPromenaStore.fetchIstorijuPromena(paginationInitialState);
  };

  const [istorijaPromenaForm] = useForm();
  const data: IFilterTableProps = {
    form: istorijaPromenaForm as FormInstance<any>,
    initialState: filtersStore.getFilters[FiltersNameEnum.ISTORIJA_PROMENA],
    filterOptions: ISTORIJA_PROMENA_FILTERS_DATA,
    filterStoreName: FiltersNameEnum.ISTORIJA_PROMENA,
    fetchTableList: fetchIstorijaPromena,
  };

  return (
    <div id="istorijaPromenaFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
