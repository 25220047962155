import { Alert, Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NewActivity1Form.module.scss';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { formStore } from 'store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IFormProps } from 'typescript/NrizTypes';
import { IGetActivity1 } from 'modules/activityList1/activityList1.types';
import { IGetPrivrednaDelatnost1 } from 'modules/sifarnici/store/privrednaDelatnost1/privrednaDelatnost1.types';
import { IGetAktivnost1 } from 'modules/sifarnici/store/aktivnost1/aktivnost1.types';
import { activityInitialState } from 'modules/activityList1/activityList1.constants';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { facilityStore } from 'modules/facility/facility.store';

export const NewActivity1Form: React.FC<IFormProps> = observer(() => {
  const [form] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return activityList1Store.submitActivity(newValues as any);
  };

  useEffect(() => {
    form.setFieldsValue({ ...activityList1Store.getActivity });
  }, [activityList1Store.getActivity]);

  const defaultPrivrednaDelatnostOption: ISifarnikInitial | undefined = activityList1Store.activity
    .privredna_delatnost_id
    ? {
        label: `${(activityList1Store.activity as IGetActivity1).privredna_delatnost1?.code} ${
          (activityList1Store.activity as IGetActivity1).privredna_delatnost1.name_sr
        }`,
        value: activityList1Store.activity.privredna_delatnost_id,
      }
    : undefined;

  const defaultAktivnostOption: ISifarnikInitial | undefined = useMemo(() => {
    return activityList1Store.activity.aktivnost_id
      ? {
          label: `${(activityList1Store.activity as IGetActivity1).aktivnost1?.code} ${
            (activityList1Store.activity as IGetActivity1).aktivnost1.name_sr
          }`,
          value: activityList1Store.activity.aktivnost_id,
        }
      : undefined;
  }, [activityList1Store.activity]);

  const handlePrivrednaDelatnostChange = (value: string) => {
    form.setFieldValue('aktivnost_id', '');
    activityList1Store.handleChange('privredna_delatnost_id', value);
  };

  const economicActivityOptionMaker = (options: IGetPrivrednaDelatnost1[]) => {
    return options.map(activity => ({
      label: `${activity?.code} ${activity.name_sr}`,
      value: activity.id,
    }));
  };

  const activityOptionMaker = (options: IGetAktivnost1[]) => {
    return options.map(activity => ({
      label: `${activity?.code} ${activity.name_sr}`,
      value: activity.id,
    }));
  };

  return (
    <>
      <h3 className={styles.activityModalInfo}>{t('ACTIVITIES.MODAL_INFO')}</h3>
      <Form
        form={form}
        className={styles.activityForm}
        initialValues={activityInitialState}
        labelAlign="left"
        name={'aktivnost-1'}
        onFinish={activityList1Store.submitActivity}
        onFinishFailed={() => {
          if (formStore.crudButtonType === 'delete') {
            onFinishFailed();
          }
        }}
      >
        <FormSearchInfiniteScroll
          showSearch
          label={t('ACTIVITIES.ECONOMIC_ACTIVITY')}
          labelCol={{ span: 8 }}
          formName={'privredna_delatnost_id'}
          inputName={'privredna_delatnost_id'}
          labelOptionAccessor={'name_sr'}
          valueOptionAccessor={'id'}
          defaultOption={defaultPrivrednaDelatnostOption}
          fetchOptinsList={activityList1Store.fetchPrivrednaDelatnost1}
          rules={[
            REQUIRED_FIELD_RULE(true),
            {
              validator(_, value) {
                const privredna_delatnost = sifarniciStore.list.PRIVREDNA_DELATNOST_1.find(delatnost => {
                  return delatnost.id === value;
                });

                if (Number(privredna_delatnost?.code) === 0 && value)
                  if (
                    (activityList1Store.getActivity as IGetActivity1).privredna_delatnost1 &&
                    activityList1Store.getActivityList.length <= 1
                  ) {
                    return Promise.resolve();
                  } else if (!activityList1Store.getActivityList.length) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error('Aktivnost pod šifrom 0 može se uneti samo ako nema ni jedne aktivnosti u listi')
                    );
                  }
                return Promise.resolve();
              },
            },
          ]}
          onChange={handlePrivrednaDelatnostChange}
          optionsMaker={economicActivityOptionMaker}
          placeholder={''}
        />
        <FormSearchInfiniteScroll
          showSearch
          label={t('ACTIVITIES.ACTIVITY')}
          labelCol={{ span: 8 }}
          formName={'aktivnost_id'}
          inputName={'aktivnost_id'}
          labelOptionAccessor={'name_sr'}
          valueOptionAccessor={'id'}
          defaultOption={defaultAktivnostOption}
          fetchOptinsList={activityList1Store.fetchAktivnost1}
          resetOption={activityList1Store.privredna_delatnost_id}
          optionsMaker={activityOptionMaker}
          rules={[REQUIRED_FIELD_RULE(true)]}
          disabled={!activityList1Store.privredna_delatnost_id}
          placeholder={''}
        />
        <FormCheckbox
          name="glavna_aktivnost"
          label={t('ACTIVITIES.MAIN')}
          labelCol={{ span: 16 }}
          disabled={activityList1Store.activity.glavna_aktivnost}
          rules={[
            {
              validator(_, value) {
                const privrednaDelatnostId = form.getFieldValue('privredna_delatnost_id');
                if (!privrednaDelatnostId) return Promise.resolve();
                const privrednaDelatnost = sifarniciStore.list.PRIVREDNA_DELATNOST_1.find(
                  delatnost => delatnost.id === privrednaDelatnostId
                );

                if (privrednaDelatnost?.code === 0 && value)
                  return Promise.reject(new Error('Aktivnost pod šifrom 0 ne može biti glavna.'));
                return Promise.resolve();
              },
            },
          ]}
        />
        <Row justify={'space-between'}>
          <Col span={14}>
            <Alert
              style={{ display: 'flex' }}
              message="Nakon menjanja liste aktivnosti, proverite dodeljene obrasce na postrojenju"
              type="info"
              banner
            />
          </Col>
          {(facilityStore.getFacility.is_active && (activityList1Store.getActivity as IGetActivity1).id) ||
          !(activityList1Store.getActivity as IGetActivity1).id ? (
            <CRUDButton
              form={form}
              showButton={!!(activityList1Store.getActivity as IGetActivity1).id}
              withOutDelete={activityList1Store.activity.glavna_aktivnost && activityList1Store.activityList.length > 1}
            />
          ) : undefined}
        </Row>
      </Form>
    </>
  );
});
