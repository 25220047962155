import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CODE_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { CODE_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostWasteCatalogGroup, IGetWasteCatalogGroup, IWasteCatalogGroupFilters } from './wasteCatalogGroup.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const WASTE_CATALOG_GROUP_INITIAL_STATE: IPostWasteCatalogGroup = {
  code: '',
  description: '',
  description_sr: '',
  is_active: true,
};

export const WASTE_CATALOG_GROUP_FILTERS_INITIAL_STATE: IWasteCatalogGroupFilters = {
  code__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
  is_active: null,
};

export const WASTE_CATALOG_GROUP_TABLE_DATA: ColumnsType<IGetWasteCatalogGroup> = [
  { ...CODE_TABLE, width: '100px' },
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
];
export const WASTE_CATALOG_GROUP_FORM_OPTIONS = (_: FormInstance<IGetWasteCatalogGroup>): ISifarniciTypeOfForm[] => {
  return [CODE_FORM, DESCRIPTION_FORM(1000), DESCRIPTION_SR_FORM(1000)];
};
