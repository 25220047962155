import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { nrizService } from 'modules/nriz/nriz.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { zakljucavanjeFields } from 'modules/zakljucavanje/zakljucavanje.constants';
import { zakljucavanjeService } from 'modules/zakljucavanje/zakljucavanje.service';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { IObrazacZaZakljucavanje } from 'modules/zakljucavanje/zakljucavanje.types';

interface ZakljucavanjeTableProps {
  selectedTipObrascaColumns: ColumnType<any>[];
}

export const ZakljucavanjePoTipuTable: React.FC<ZakljucavanjeTableProps> = observer(({ selectedTipObrascaColumns }) => {
  const form = useFormInstance();

  const refetchData = (pagination: ITablePagination) => {
    if (!form.getFieldValue('godina')) return Promise.reject();
    const params = nrizService.pickFields(form.getFieldsValue(zakljucavanjeFields));
    return zakljucavanjeStore.fetchZakljucavanjeObrazacaList({ ...pagination, ...params });
  };

  const handleLockUnlockObrazac = async (record: IObrazacZaZakljucavanje) => {
    const { obrazac_id, tip_obrasca_id, tip_obrasca, godina, postrojenje_id, preduzece_id } = record;
    const skraceniNaziv = tip_obrasca.skraceni_naziv;

    const payload =
      // za tip obrasca deo6, ne postoji obrazac_id, on se zakljucava tako sto se salju svi ostali filteri.
      skraceniNaziv === TIPOVI_OBRAZACA.DEO6_GIO6
        ? { tip_obrasca_id, godina, postrojenje_id, preduzece_id }
        : { obrazac_id, godina };

    const response = await zakljucavanjeStore.zakljucajObrasce(payload);
    const params = form.getFieldsValue(zakljucavanjeFields);
    if (response) zakljucavanjeStore.fetchZakljucavanjeObrazacaList(params);
  };

  const columns: ColumnType<IObrazacZaZakljucavanje>[] = [
    {
      width: 70,
      title: 'Godina',
      dataIndex: 'godina',
      key: 'godina',
    },
    {
      width: 70,
      title: 'Preduzeće',
      dataIndex: ['preduzece', 'naziv'],
      key: 'preduzece',
    },
    {
      width: 70,
      title: 'Obrazac',
      dataIndex: ['tip_obrasca', 'skraceni_naziv'],
      key: 'tip_obrasca',
    },
    ...selectedTipObrascaColumns,
    {
      width: 35,
      title: zakljucavanjeService.returnIsLocked(zakljucavanjeStore.showOtkljucano)
        ? 'Otključaj obrazac'
        : 'Zaključaj obrazac',
      align: 'center',
      render(_, record) {
        return (
          <Button name="lock-unlock-obrazac-btn" onClick={() => handleLockUnlockObrazac(record)}>
            {zakljucavanjeService.returnIsLocked(zakljucavanjeStore.showOtkljucano) ? (
              <UnlockOutlined />
            ) : (
              <LockOutlined />
            )}
          </Button>
        );
      },
    },
  ];

  return (
    <TableData
      columns={columns}
      name="zakljucavanje-tip-obrasca-table"
      dataSource={zakljucavanjeStore.obrasciZaZakljucavanje}
      refetchData={refetchData}
      storePagination={zakljucavanjeStore.pagination}
    />
  );
});
