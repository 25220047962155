import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  EMAIL_FIELD_RULE,
  IDENTIFICATION_NUMBER_FIELD_RULE,
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  PIB_FIELD_RULE,
  POST_NUMBER_FIELD_RULE,
  REQUIRED_FIELD_RULE,
  checkIfItemExistsInList,
  invalidCharactersForInputNumber,
} from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import to from 'await-to-js';
import { AAO2_PRAVNO_LICE_FIELDS } from 'modules/obrasci/store/aao2/aao2.constants';
import { IGetCompany } from 'modules/company/company.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { FormInstance } from 'antd/lib';
import { RuleConfig } from 'typescript';

export const NewAao2PreduzetnikPravnoLiceForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultCompanyOption: ISifarnikInitial | undefined = aao2Store.pravnoLiceIliPreduzetnik.naziv
    ? {
        label: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).naziv,
        value: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).naziv,
      }
    : undefined;

  const defaultMestoOption: ISifarnikInitial | undefined = aao2Store.pravnoLiceIliPreduzetnik.mesto_id
    ? {
        label: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).mesto?.display_name,
        value: aao2Store.pravnoLiceIliPreduzetnik.mesto_id,
      }
    : undefined;

  const defaultPreteznaDelatnostOption: ISifarnikInitial | undefined = aao2Store.pravnoLiceIliPreduzetnik
    .sifarnik_pretezne_delatnosti_id
    ? {
        label: (aao2Store.pravnoLiceIliPreduzetnik as IGetCompany).sifarnik_pretezne_delatnosti?.name_sr,
        value: aao2Store.pravnoLiceIliPreduzetnik.sifarnik_pretezne_delatnosti_id,
      }
    : undefined;

  // treba prepraviti da se ne salje search nego odvojeno pib, maticni, naziv_preduzeca...

  const handleCompanyFields = async (search: string, type: string) => {
    const [err, res] = await to(aao2Store.fetchApr(search, type));
    if (err || !res) return;
    AAO2_PRAVNO_LICE_FIELDS.forEach((fieldName: string) => {
      if (res[fieldName]) (form as FormInstance).setFieldValue(`${fieldName}`, res[fieldName]);
    });
  };

  // ovo se radi jer se preduzeca biraju iz tabele preduzeca sa aplikacije a uporedjuju se sa preduzecima iz apra
  // samim tim je validacija po nazivu pogresna, pa se validira po pibu i maticnom broju sa same opcije selekt komponente

  const [invalidCompanyName, setInvalidCompanyName] = useState<boolean>(false);

  const companyNameRule = (): RuleConfig => {
    return {
      validator(_: any, __: any) {
        return invalidCompanyName ? Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS'))) : Promise.resolve();
      },
    };
  };

  const handleCompanyNameChange = async (option: IGetCompany | undefined) => {
    if (!option) return setInvalidCompanyName(false);
    const { pib, maticni_broj } = option;
    const itemExists = aao2Store.initialState.pravno_lice_ili_preduzetnik.some(item =>
      pib ? item.pib === pib : item.maticni_broj === maticni_broj
    );
    if (itemExists) return setInvalidCompanyName(true);
    setInvalidCompanyName(false);
    pib ? handleCompanyFields(pib, 'pib') : handleCompanyFields(maticni_broj, 'maticni_broj');
  };

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <FormInput
        inputName={'pib'}
        name={'pib'}
        label={t('COMPANY.PIB')}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          PIB_FIELD_RULE,
          checkIfItemExistsInList({
            previousValue: aao2Store.pravnoLiceIliPreduzetnik.pib,
            listItemAccessor: 'pib',
            list: aao2Store.initialState.pravno_lice_ili_preduzetnik,
          }),
        ]}
        labelCol={{ span: 9 }}
        disabled={aao2Store.pravnoLiceIliPreduzetnik.pib !== ''}
        onInputChange={async e => {
          try {
            await (form as FormInstance).validateFields(['pib'], { validateOnly: true });
            const stringInput = e.target.value.toString();
            if (stringInput.length === 9) {
              handleCompanyFields(stringInput, 'pib');
            }
          } catch {
            return;
          }
        }}
        invalidCharacters={invalidCharactersForInputNumber}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'maticni_broj'}
        name={'maticni_broj'}
        label={t('COMPANY.ENTERPRISE_ID_NUMBER')}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          IDENTIFICATION_NUMBER_FIELD_RULE,
          checkIfItemExistsInList({
            previousValue: aao2Store.pravnoLiceIliPreduzetnik.maticni_broj,
            listItemAccessor: 'maticni_broj',
            list: aao2Store.initialState.pravno_lice_ili_preduzetnik,
          }),
        ]}
        labelCol={{ span: 9 }}
        disabled={aao2Store.pravnoLiceIliPreduzetnik.maticni_broj !== ''}
        onInputChange={async e => {
          try {
            await (form as FormInstance).validateFields(['maticni_broj'], { validateOnly: true });
            const stringInput = e.target.value.toString();
            if (stringInput.length === 8) {
              handleCompanyFields(stringInput, 'maticni_broj');
            }
          } catch {
            return;
          }
        }}
        invalidCharacters={invalidCharactersForInputNumber}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv'}
        label={t('COMPANY.FULL_NAME')}
        labelCol={{ span: 9 }}
        labelOptionAccessor={'naziv'}
        valueOptionAccessor={'naziv'}
        fetchOptinsList={aao2Store.fetchCompaniesListWithSearch}
        placeholder={''}
        rules={[REQUIRED_FIELD_RULE(true), companyNameRule()]}
        onChange={(_: any, option: { item: IGetCompany }) => handleCompanyNameChange(option?.item)}
        defaultOption={defaultCompanyOption}
        disabled={aao2Store.pravnoLiceIliPreduzetnik.naziv !== ''}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'mesto_id'}
        label={t('COMPANY.LOCATION')}
        labelCol={{ span: 9 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        fetchOptinsList={aao2Store.fetchMestoOptions}
        placeholder={''}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultMestoOption}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'postanski_broj'}
        name={'postanski_broj'}
        label={t('COMPANY.POST_NUMBER')}
        colon={true}
        type="number"
        rules={[POST_NUMBER_FIELD_RULE, ...MORE_THEN_0]}
        labelCol={{ span: 9 }}
        invalidCharacters={invalidCharactersForInputNumber}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'ulica'}
        name={'ulica'}
        label={t('COMPANY.ADDRESS')}
        colon={true}
        rules={[MAX_LENGTH_RULE(50)]}
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'kucni_broj'}
        name={'kucni_broj'}
        label={t('COMPANY.ADDRESS_NUMBER')}
        colon={true}
        rules={[MAX_LENGTH_RULE(20)]}
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'telefon'}
        name={'telefon'}
        label={t('COMPANY.PHONE')}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
        type="text"
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormInput
        inputName={'email'}
        name={'email'}
        label={t('COMPANY.EMAIL')}
        colon={true}
        rules={[EMAIL_FIELD_RULE, MAX_LENGTH_RULE(100)]}
        labelCol={{ span: 9 }}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'sifarnik_pretezne_delatnosti_id'}
        label={t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE')}
        labelCol={{ span: 9 }}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultPreteznaDelatnostOption}
        fetchOptinsList={aao2Store.fetchPretezneDelatnosti}
        placeholder={''}
        readOnly={readOnly}
      />
    </Col>
  );
});
