import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  IPostRiverBasinDistrictCode,
  IGetRiverBasinDistrictCode,
  IRiverBasinDistrictCodeFilters,
} from './riverBasinDistrictCode.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const RIVER_BASIN_DISTRICT_CODE_INITIAL_STATE: IPostRiverBasinDistrictCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  is_active: true,
};
export const RIVER_BASIN_DISTRICT_CODE_FILTERS_INITIAL_STATE: IRiverBasinDistrictCodeFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};
export const RIVER_BASIN_DISTRICT_CODE_TABLE_DATA: ColumnsType<IGetRiverBasinDistrictCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
];
export const RIVER_BASIN_DISTRICT_CODE_FORM_OPTIONS = (
  form: FormInstance<IGetRiverBasinDistrictCode>
): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(200),
    NAME_SR_FORM(200),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM(200),
    DESCRIPTION_FORM(1000),
    { ...VERSION_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
  ];
};
