import { Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NewIzvozObrazac5Form } from './components/NewIzvozObrazac5';
import { NewSkladistenjeObrazac5 } from './components/NewSkladistenjeObrazac5Form';
import { NewOdlaganjeObrazac5 } from './components/NewOdlaganjeObrazac5Form';
import { NewPonovnoObrazac5 } from './components/NewPonovnoObrazac5Form';
import { FormInput } from 'components/FormInput/FormInput';

import styles from './NewUpravljanjeObrazac5.module.scss';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { NacinUpravljanjaEnum } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import { useEffect, useState } from 'react';
import { Select } from 'components/Select/Select';

export const NewUpravljanjeObrazac5Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const [nacinUpravljanja, setNacinUpravljanja] = useState('');

  const handleNacinUpravljanjaClearInput = () => {
    const form = props.form;
    const excludedFields: string[] = [
      'nacin_upravljanja',
      'naziv_prevoznika_otpada',
      'broj_dozvole_za_prevoz_opasnih_materija',
      'tip_vozila',
    ];

    const fields: { [key: string]: any } = form.getFieldsValue();
    const updatedFields: { [key: string]: any } = Object.keys(fields).reduce((acc, fieldName) => {
      if (!excludedFields.includes(fieldName)) {
        acc[fieldName] = '';
      }
      return acc;
    }, {} as { [key: string]: any });

    form.setFieldsValue(updatedFields);
  };

  useEffect(() => {
    setNacinUpravljanja(props.form.getFieldValue('nacin_upravljanja'));
  }, [Form.useWatch('nacin_upravljanja', props.form)]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      {Number(obrasciStore.opste.godina) < 2019 && (
        <>
          <h4 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.TRANSPORT_OTPADA')}</h4>
          <FormInput
            name="naziv_prevoznika_otpada"
            label={t('OBRASCI.OBRAZAC5.NAZIV_PREVOZNIKA_OTPADA')}
            type="text"
            placeholder={t('OBRASCI.OBRAZAC5.NAZIV_PREVOZNIKA_OTPADA')}
            inputName={'naziv_prevoznika_otpada'}
            colon={true}
            readOnly={readOnly}
            rules={[MAX_LENGTH_RULE(200)]}
          />
          <FormInput
            name="broj_dozvole_za_prevoz_opasnih_materija"
            label={t('OBRASCI.OBRAZAC5.BROJ_DOZVOLE_ZA_PREVOZ_OPASNIH_MATERIJA')}
            type="text"
            placeholder={t('OBRASCI.OBRAZAC5.BROJ_DOZVOLE_ZA_PREVOZ_OPASNIH_MATERIJA')}
            inputName={'broj_dozvole_za_prevoz_opasnih_materija'}
            colon={true}
            readOnly={readOnly}
            rules={[MAX_LENGTH_RULE(100)]}
          />
          <FormInput
            name="tip_vozila"
            label={t('OBRASCI.OBRAZAC5.TIP_VOZILA')}
            type="text"
            placeholder={t('OBRASCI.OBRAZAC5.TIP_VOZILA')}
            inputName={'tip_vozila'}
            colon={true}
            readOnly={readOnly}
            rules={[MAX_LENGTH_RULE(100)]}
          />
        </>
      )}
      <Row>
        <Col span={14}>
          <h3>{t('OBRASCI.OBRAZAC5.IZABERI_NACIN_UPRAVLJANJA')}</h3>
        </Col>
        <Col span={10}>
          <Form.Item name={'nacin_upravljanja'} wrapperCol={{ span: 24 }} rules={[REQUIRED_FIELD_RULE(true)]}>
            <Select
              name="nacin_upravljanja"
              readOnly={readOnly}
              style={{ width: 100 }}
              placeholder={t('OBRASCI.OBRAZAC5.IZABERI_NACIN_UPRAVLJANJA')}
              onChange={handleNacinUpravljanjaClearInput}
              options={[
                { value: NacinUpravljanjaEnum.SKLADISTENJE, label: t('OBRASCI.OBRAZAC5.SKLADISTE_OTPADA') },
                { value: NacinUpravljanjaEnum.ODLAGANJE, label: t('OBRASCI.OBRAZAC5.ODLAGANJE_OTPADA') },
                { value: NacinUpravljanjaEnum.PONOVNO, label: t('OBRASCI.OBRAZAC5.PONOVNO_ISKORISCENJE_OTPADA') },
                { value: NacinUpravljanjaEnum.IZVOZ, label: t('OBRASCI.OBRAZAC5.IZVOZ_OTPADA') },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      {nacinUpravljanja === NacinUpravljanjaEnum.SKLADISTENJE && <NewSkladistenjeObrazac5 form={props.form} />}
      {nacinUpravljanja === NacinUpravljanjaEnum.ODLAGANJE && <NewOdlaganjeObrazac5 form={props.form} />}
      {nacinUpravljanja === NacinUpravljanjaEnum.PONOVNO && <NewPonovnoObrazac5 form={props.form} />}
      {nacinUpravljanja === NacinUpravljanjaEnum.IZVOZ && <NewIzvozObrazac5Form form={props.form} />}
    </Col>
  );
});
