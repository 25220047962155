import { Col, Form, Row, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Content } from 'components/Content/Content';
import styles from './LCPRegistar.module.scss';
import { Button } from 'components/Button/Button';
import { REGISTRI_CONFIG } from 'modules/registri/registri.constants';

import { RegistriEnum } from 'modules/registri/registri.types';
import { lcpStore } from 'modules/registri/lcp/lcp.store';
import { ILCPRegistar } from 'modules/registri/lcp/lcp.types';
import { LCP_INITIAL_STATE } from 'modules/registri/lcp/lcp.constants';
import { LCPRegistarForm } from './LCPRegistarForm/LCPRegistarForm';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';

export const LCPRegistar: React.FC = observer(() => {
  const { id } = useParams();

  const [form] = Form.useForm();

  const { t } = useTranslation();
  //todo: prevod
  t;

  const navigate = useNavigate();

  useEffect(() => {
    if (!id || id === 'novi') return;
    lcpStore.getItem(id);
  }, []);

  useEffect(() => {
    return () => lcpStore.resetStates();
  }, []);

  const LcpHeader = () => {
    const handleDelete = async (id: string) => {
      const response = await lcpStore.deleteItem(id);
      if (response) {
        navigate(REGISTRI_CONFIG[RegistriEnum.Lcp].routes.urlRoute);
        form.resetFields();
      }
    };

    return (
      <Row className={styles.lcpHeader}>
        <Col span={8}>
          <h1>LCP Registar</h1>
        </Col>
        <Col className={styles.lcpSubmitButtons}>
          <Button
            name="lcp-back"
            className="ant-btn-dangerous"
            disabled={false}
            onClick={() => {
              navigate(REGISTRI_CONFIG[RegistriEnum.Lcp].routes.urlRoute);
            }}
          >
            Nazad
          </Button>
          {id && id !== 'novi' && (
            <Popconfirm
              name="lcp-delete"
              title={'Da li ste sigurni da želite da obrišete LCP registar?'}
              okText={t('Da')}
              okButtonProps={{ disabled: false }}
              cancelButtonProps={{ disabled: false }}
              cancelText={t('Odbaci')}
              onConfirm={() => handleDelete(id)}
              placement="bottom"
            >
              <Button name="lcp-delete" className="ant-btn-dangerous" disabled={false}>
                {t('DELETE')}
              </Button>
            </Popconfirm>
          )}
          <Button name="lcp-submit" htmlType="submit" disabled={false}>
            {t('SUBMIT')}
          </Button>
        </Col>
      </Row>
    );
  };

  const handleFormSubmit = async (payload: ILCPRegistar) => {
    if (!id) return;
    try {
      if (id === 'novi') {
        await lcpStore.postItem(payload);
        message.success('Uspešno dodat LCP!');
        navigate(REGISTRI_CONFIG[RegistriEnum.Lcp].routes.urlRoute);
      } else {
        await lcpStore.putItem({ ...payload, id });
        message.success('Uspešno izmenjen LCP!');
        navigate(REGISTRI_CONFIG[RegistriEnum.Lcp].routes.urlRoute);
      }
      form.resetFields();
    } catch (error) {
      message.error('Došlo je do greške!');
    }
  };

  const getLcpRegistarWithValidInstalacijaId = async () => {
    if (id !== 'novi') {
      if (lcpStore.lcpRegistar && !lcpStore.lcpRegistar?.postrojenje.is_active) {
        message.warning('Ovo postrojenje je deaktivirano! Proverite matične podatke.');
      }

      return form.setFieldsValue({ ...lcpStore.lcpRegistar });
    }
    const id_instalacije = await lcpStore.fetchSledeciIdInstalacije();
    const nextInstalacioniDeoId = (await lcpStore.fetchSledeciIdInstalacionogDela()) ?? id_instalacije;
    lcpStore.setNextInstalacioniDeoId(nextInstalacioniDeoId);
    return form.setFieldsValue({ ...lcpStore.lcpRegistar, id_instalacije });
  };

  // probaj da izbegnes ovaj useeffect tako sto ces setovati initialValues forme

  useEffect(() => {
    getLcpRegistarWithValidInstalacijaId();
  }, [lcpStore.lcpRegistar]);

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      disabled={!lcpStore.lcpOpste.postrojenje_id}
      initialValues={lcpStore.lcpRegistar ?? LCP_INITIAL_STATE}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
    >
      <Content header={<LcpHeader />} className={styles.lcpFormWrapper}>
        <LCPRegistarForm />
      </Content>
    </Form>
  );
});
