import { ColumnsType } from 'antd/es/table';
import { IGetRecipientType, IPostRecipientType, IRecipientTypeFilters } from './recipientType.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const RECIPIENT_TYPE_INITIAL_STATE: IPostRecipientType = {
  type: '',
  type_sr: '',
  is_active: true,
};
export const RECIPIENT_TYPE_FILTERS_INITIAL_STATE: IRecipientTypeFilters = {
  type__ilike: '',
  type_sr__ilike: '',
  is_active: null,
};

export const RECIPIENT_TYPE_TABLE_DATA: ColumnsType<IGetRecipientType> = [
  {
    title: 'Tip primaoca',
    width: '300px',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Tip primaoca (srp.)',
    width: '300px',
    dataIndex: 'type_sr',
    key: 'type_sr',
  },
];

export const RECIPIENT_TYPE_FORM_OPTIONS = (_: FormInstance<IGetRecipientType>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      name: 'type',
      filterName: 'type__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Tip primaoca',
      inputName: 'type',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'type_sr',
      filterName: 'type_sr__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Tip primaoca (srp.)',
      inputName: 'type_sr',
    } as InputSifarniciFormProps,
  ];
};
