import {
  Aao1IzvestajiEnum,
  Aao2IzvestajiEnum,
  Deo6IzvestajiEnum,
  Dep1IzvestajiEnum,
  Dep2IzvestajiEnum,
  Gio2IzvestajiEnum,
  Gio3IzvestajiEnum,
  Gio4IzvestajiEnum,
  Gio5IzvestajiEnum,
  IzvestajiSubtypes,
  Kom1IzvestajiEnum,
  Obrazac1IzvestajiEnum,
  Obrazac2IzvestajiEnum,
  Obrazac3IzvestajiEnum,
  Obrazac5IzvestajiEnum,
  ObrazacIzvestajTypeEnum,
  Ptp2IzvestajiEnum,
  PzvIzvestajiEnum,
} from './obrazacIzvestaji.types';

// import { FilteriStore } from 'store/filteri.store';

//#region izvestaji options

export const OBRAZAC_IZVESTAJI_TYPE_OPTIONS = [
  { label: 'OBRAZAC 1', value: ObrazacIzvestajTypeEnum.OBRAZAC_1 },
  { label: 'OBRAZAC 2', value: ObrazacIzvestajTypeEnum.OBRAZAC_2 },
  { label: 'OBRAZAC 3', value: ObrazacIzvestajTypeEnum.OBRAZAC_3 },
  { label: 'OBRAZAC 5', value: ObrazacIzvestajTypeEnum.OBRAZAC_5 },
  { label: 'GIO 2', value: ObrazacIzvestajTypeEnum.GIO_2 },
  { label: 'GIO 3', value: ObrazacIzvestajTypeEnum.GIO_3 },
  { label: 'GIO 4', value: ObrazacIzvestajTypeEnum.GIO_4 },
  { label: 'GIO 5', value: ObrazacIzvestajTypeEnum.GIO_5 },
  { label: 'DEO 6', value: ObrazacIzvestajTypeEnum.DEO_6 },
  { label: 'KOM 1', value: ObrazacIzvestajTypeEnum.KOM_1 },
  { label: 'PTP2', value: ObrazacIzvestajTypeEnum.PTP2 },
  { label: 'AAO1', value: ObrazacIzvestajTypeEnum.AAO1 },
  { label: 'AAO2', value: ObrazacIzvestajTypeEnum.AAO2 },
  { label: 'DEP1', value: ObrazacIzvestajTypeEnum.DEP1 },
  { label: 'DEP2', value: ObrazacIzvestajTypeEnum.DEP2 },
  { label: 'PZV', value: ObrazacIzvestajTypeEnum.PZV },
];

export const OBRAZAC_1_IZVESTAJ_OPTIONS = [
  { label: 'GORIVA', value: Obrazac1IzvestajiEnum.GORIVA },
  { label: 'PROIZVODI', value: Obrazac1IzvestajiEnum.PROIZVODI },
  { label: 'SIROVINE', value: Obrazac1IzvestajiEnum.SIROVINE },
  { label: 'OPŠTE', value: Obrazac1IzvestajiEnum.OPSTE },
];

export const OBRAZAC_2_IZVESTAJ_OPTIONS = [
  { label: 'ZAGAĐUJUĆE MATERIJE', value: Obrazac2IzvestajiEnum.ZAGADLJIVE_MATERIJE },
  { label: 'GORIVO', value: Obrazac2IzvestajiEnum.GORIVO },
  { label: 'OPŠTE', value: Obrazac2IzvestajiEnum.OPSTE },
  { label: 'PREGLED PO GODINAMA', value: Obrazac2IzvestajiEnum.PREGLED_PO_GODINAMA },
  { label: 'IZVORI PO GODINAMA', value: Obrazac2IzvestajiEnum.IZVORI_PO_GODINAMA },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'PRTR KATEGORIJE', value: Obrazac2IzvestajiEnum.PRTR_KATEGORIJE },
  { label: 'KONTROLA UNOSA', value: Obrazac2IzvestajiEnum.KONTROLA_UNOSA },
  { label: 'PRTR PREGLED PO GODINAMA', value: Obrazac2IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA },
];

export const OBRAZAC_3_IZVESTAJ_OPTIONS = [
  { label: 'ZAGAĐUJUĆE MATERIJE', value: Obrazac3IzvestajiEnum.ZAGADLJIVE_MATERIJE },
  { label: 'PREGLED PO GODINAMA', value: Obrazac3IzvestajiEnum.PREGLED_PO_GODINAMA },
  { label: 'ISPUST PO GODINAMA', value: Obrazac3IzvestajiEnum.ISPUST_PO_GODINAMA },
  { label: 'OPŠTE', value: Obrazac3IzvestajiEnum.OPSTE },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'KONTROLA UNOSA', value: Obrazac3IzvestajiEnum.KONTROLA_UNOSA },
  { label: 'PRTR PREGLED PO GODINAMA', value: Obrazac3IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA },
];

export const OBRAZAC_5_IZVESTAJ_OPTIONS = [
  { label: 'POSTROJENJA', value: Obrazac5IzvestajiEnum.POSTROJENJA },
  { label: 'POSTROJENJA INDEKSNI BROJ', value: Obrazac5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ },
  { label: 'GRUPA PODGRUPA INDEKSNI BROJ', value: Obrazac5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ },
  { label: 'OTPAD (NE)OPASAN', value: Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN },
  {
    label: 'GENERISANJE OTPADA PREGLED PO GODINAMA',
    value: Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_PREGLED_PO_GODINAMA,
  },
  { label: 'OTPAD - POSTROJENJA ZA PLAĆANJE', value: Obrazac5IzvestajiEnum.OTPAD_POSTROJENJA_ZA_PLACANJE },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'KONTROLA UNOSA', value: Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_KONTROLA_UNOSA },
  { label: 'PRTR PREGLED PO GODINAMA', value: Obrazac5IzvestajiEnum.PRTR_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA },
];

export const GIO_2_IZVESTAJ_OPTIONS = [
  { label: 'POSTROJENJA INDEKSNI BROJ', value: Gio2IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ },
  { label: 'GRUPA PODGRUPA INDEKSNI BROJ', value: Gio2IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ },
  { label: 'ODLAGANJE OTPADA PREGLED PO GODINI', value: Gio2IzvestajiEnum.ODLAGANJE_OTPADA_PREGLED_PO_GODINI },
  { label: 'ELEKTRIČNI I ELEKTRONSKI OTPAD', value: Gio2IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD },
  /// TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'ODLAGANJE OTPADA KONTROLA UNOSA', value: Gio2IzvestajiEnum.ODLAGANJE_OTPADA_KONTROLA_UNOSA },
];

export const GIO_3_IZVESTAJ_OPTIONS = [
  { label: 'POSTROJENJA INDEKSNI BROJ', value: Gio3IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ },
  { label: 'GRUPA PODGRUPA INDEKSNI BROJ', value: Gio3IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ },
  { label: 'TRETMAN OTPADA PREGLED PO GODINAMA', value: Gio3IzvestajiEnum.TRETMAN_OTPADA_PREGLED_PO_GODINI },
  { label: 'ELEKTRIČNI I ELEKTRONSKI OTPAD', value: Gio3IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'TRETMAN OTPADA KONTROLA UNOSA', value: Gio3IzvestajiEnum.TRETMAN_OTPADA_KONTROLA_UNOSA },
];

export const GIO_4_IZVESTAJ_OPTIONS = [
  { label: 'POSTROJENJA INDEKSNI BROJ', value: Gio4IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ },
  { label: 'GRUPA PODGRUPA INDEKSNI BROJ', value: Gio4IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ },
  { label: 'IZVOZ OTPADA PREGLED PO GODINI', value: Gio4IzvestajiEnum.IZVOZ_OTPADA_PREGLED_PO_GODINI },
  { label: 'ELEKTRIČNI I ELEKTRONSKI OTPAD', value: Gio4IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD },
  { label: 'KOLIČINA IZVEZENOG OTPADA PO VRSTI', value: Gio4IzvestajiEnum.KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'IZVOZ OTPADA KONTROLA UNOSA', value: Gio4IzvestajiEnum.IZVOZ_OTPADA_KONTROLA_UNOSA },
];

export const GIO_5_IZVESTAJ_OPTIONS = [
  { label: 'POSTROJENJA INDEKSNI BROJ', value: Gio5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ },
  { label: 'GRUPA PODGRUPA INDEKSNI BROJ', value: Gio5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ },
  { label: 'UVOZ OTPADA PREGLED PO GODINI', value: Gio5IzvestajiEnum.UVOZ_OTPADA_PREGLED_PO_GODINI },
  { label: 'KOLIČINA UVEZENOG OTPADA PO VRSTI', value: Gio5IzvestajiEnum.KOLICINA_UVEZENOG_OTPADA_PO_VRSTI },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'UVOZ OTPADA KONTROLA UNOSA', value: Gio5IzvestajiEnum.UVOZ_OTPADA_KONTROLA_UNOSA },
];

export const DEO_6_IZVESTAJ_OPTIONS = [
  { label: 'UKUPNO PREUZET OTPAD', value: Deo6IzvestajiEnum.UKUPNO_PREUZET_OTPAD },
  {
    label: 'PREUZET OTPAD POSTROJENJA INDEKSNI BROJ',
    value: Deo6IzvestajiEnum.PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ,
  },
  { label: 'UKUPNO PREDAT OTPAD', value: Deo6IzvestajiEnum.UKUPNO_PREDAT_OTPAD },
  { label: 'PREDAT OTPAD POSTROJENJA INDEKSNI BROJ', value: Deo6IzvestajiEnum.PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ },
  { label: 'REKAPITULACIJA', value: Deo6IzvestajiEnum.REKAPITULACIJA },
  { label: 'REKALKULACIJA', value: Deo6IzvestajiEnum.REKALKULACIJA },
  { label: 'PRIMOPREDAJA OTPADA IZMEĐU PREDUZEĆA', value: Deo6IzvestajiEnum.PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'GIO 3 - DEO 6 PO PREDUZEĆU', value: Deo6IzvestajiEnum.GIO_3_DEO6_PO_PREDUZECU },
];

export const KOM_1_IZVESTAJ_OPTIONS = [
  { label: 'SASTAV KOMUNALNOG OTPADA PO GODINAMA', value: Kom1IzvestajiEnum.SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA },
  { label: 'KOLIČINA KOMUNALNOG OTPADA PO GODINAMA', value: Kom1IzvestajiEnum.KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA },
  {
    label: 'OBUHVAT PRIKUPLJENOG OTPADA PO GODINAMA',
    value: Kom1IzvestajiEnum.OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA,
  },
  { label: 'UPRAVLJANJE KOMUNALNIM OTPADOM', value: Kom1IzvestajiEnum.UPRAVLJANJE_KOMUNALNIM_OTPADOM },
];

export const PTP_2_IZVESTAJ_OPTIONS = [
  { label: 'OBRAČUN ZA NAPLATU', value: Ptp2IzvestajiEnum.OBRACUN_ZA_NAPLATU },
  { label: 'VOZILA UPOREĐIVANJE', value: Ptp2IzvestajiEnum.VOZILA_UPOREDJIVANJE },
  { label: 'NEDOSTAVLJENI PODACI O VOZILIMA', value: Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_VOZILIMA },
  { label: 'UVOZ GUMA UPOREĐIVANJE', value: Ptp2IzvestajiEnum.UVOZ_GUMA_UPOREDJIVANJE },
  { label: 'NEDOSTAVLJENI PODACI O GUMAMA', value: Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_GUMAMA },
  // TODO Yellow reports were commented based on ticket NRZI-65. Remove comment when they are ready for production stage.
  { label: 'KONTROLA UNOSA', value: Ptp2IzvestajiEnum.KONTROLA_UNOSA },
];

export const AAO_1_IZVESTAJ_OPTIONS = [
  { label: 'PREGLED PO GODINAMA', value: Aao1IzvestajiEnum.PREGLED_PO_GODINAMA },
  { label: 'PRIKAZ 1', value: Aao1IzvestajiEnum.PRIKAZ_1 },
  { label: 'SVI PODACI', value: Aao1IzvestajiEnum.SVI_PODACI },
  { label: 'NEDOSTAVLJENI OBRASCI', value: Aao1IzvestajiEnum.NEDOSTAVLJENI_OBRASCI },
];

export const AAO_2_IZVESTAJ_OPTIONS = [{ label: 'IZVEŠTAJ FOR BOOK', value: Aao2IzvestajiEnum.IZVESTAJ_FOR_BOOK }];

export const PZV_IZVESTAJ_OPTIONS = [
  { label: 'KOLIČINE PROIZVODA PO PREDUZEĆU', value: PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_PREDUZECU },
  { label: 'KOLIČINE PO GRUPI PROIZVODA I PREDUZEĆU', value: PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU },
];

export const DEP_1_IZVESTAJ_OPTIONS = [{ label: 'SVI PODACI', value: Dep1IzvestajiEnum.SVI_PODACI }];

export const DEP_2_IZVESTAJ_OPTIONS = [{ label: 'SVI PODACI', value: Dep2IzvestajiEnum.SVI_PODACI }];

export const OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP: {
  [key in ObrazacIzvestajTypeEnum]: { label: string; value: IzvestajiSubtypes }[];
} = {
  [ObrazacIzvestajTypeEnum.OBRAZAC_1]: OBRAZAC_1_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.OBRAZAC_2]: OBRAZAC_2_IZVESTAJ_OPTIONS, // GVE
  [ObrazacIzvestajTypeEnum.OBRAZAC_3]: OBRAZAC_3_IZVESTAJ_OPTIONS, // OTV
  [ObrazacIzvestajTypeEnum.OBRAZAC_5]: OBRAZAC_5_IZVESTAJ_OPTIONS, // GIO 1
  [ObrazacIzvestajTypeEnum.GIO_2]: GIO_2_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.GIO_3]: GIO_3_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.GIO_4]: GIO_4_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.GIO_5]: GIO_5_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.DEO_6]: DEO_6_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.KOM_1]: KOM_1_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.PTP2]: PTP_2_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.AAO1]: AAO_1_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.AAO2]: AAO_2_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.DEP1]: DEP_1_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.DEP2]: DEP_2_IZVESTAJ_OPTIONS,
  [ObrazacIzvestajTypeEnum.PZV]: PZV_IZVESTAJ_OPTIONS,
};

//#endregion
