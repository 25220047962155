import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import { ICompaniesListFilters, IGetCompany, IPib, IPostCompany } from './company.types';
import i18n from 'translations/i18n.config';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const companyInitialState: IPostCompany = {
  pib: '',
  maticni_broj: '',
  naziv: '',
  kraci_naziv: '',
  postanski_broj: '',
  mesto_id: '',
  ulica: '',
  kucni_broj: '',
  ime_odgovorne_osobe: '',
  prezime_odgovorne_osobe: '',
  telefon: '',
  email: '',
  sifarnik_pretezne_delatnosti_id: '',
  popunjen_upitnik: false,
  verifikacioni_kod: '',
  status: '',
  is_active: false,
  napomena: '',
};

export const pibInitialState: IPib = {
  pib: '',
  maticni_broj: '',
};

export const companyRequiredFields: (keyof Partial<IGetCompany>)[] = [
  'naziv',
  'kraci_naziv',
  // 'pib',
  // 'maticni_broj',
  'telefon',
  'email',
  // 'sifarnik_pretezne_delatnosti_id',
  'mesto_id',
  'postanski_broj',
  'ulica',
  'kucni_broj',
  // 'ime_odgovorne_osobe',
  // 'prezime_odgovorne_osobe',
];

export enum CompanyStatus {
  AKTIVAN = 'aktivan',
  U_PRIPREMI = 'u_pripremi',
}

export enum KoraciRegistracijeKorisnika {
  WARNING = 'warningInfo',
  PIB = 'pib',
  INFO = 'info',
  SURVEY = 'survey',
  HOME = 'home',
}

export const COMPANY_LIST_FILTERS: ICompaniesListFilters = {
  naziv_preduzeca: '',
  pib: '',
  maticni_broj: '',
  order_by: '',
  u_pripremi: null,
  is_active: null,
};

export const companiesFilterOptions: ISifarniciTypeOfForm[] = [
  {
    label: i18n.t('COMPANY.NAME'),
    name: 'naziv',
    type_of_form: 'input',
    inputName: 'naziv',
    sortName: 'naziv',
  } as InputSifarniciFormProps,
  {
    label: i18n.t('COMPANY.PIB'),
    name: 'pib',
    type_of_form: 'input',
    inputName: 'pib',
    sortName: 'pib',
  } as InputSifarniciFormProps,
  {
    label: i18n.t('COMPANY.ENTERPRISE_ID_NUMBER'),
    name: 'maticni_broj',
    type_of_form: 'input',
    inputName: 'maticni_broj',
    sortName: 'maticni_broj',
  } as InputSifarniciFormProps,
  {
    type_of_form: 'switch',
    label: 'U pripremi',
    name: 'u_pripremi',
  },
  IS_ACTIVE_SWITCH,
];
