import {
  IPostDozvoleGio3,
  IPostGio3Obrazac,
  IPostKolicineGio3,
  IPostPostrojenjaGio3,
  IPostRazrediEeOtpadaGio3,
} from './gio3.types';
export const GIO_3_INITIAL_STATE: IPostGio3Obrazac = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  napomena: '',
  dozvole: [],
  kolicine: [],
  postrojenja: [],
};

export const KOLICINE_GIO_3_INITIAL_STATE: IPostKolicineGio3 = {
  indeksni_broj_otpada_id: '',
  kolicina_preuzetog_otpada: null,
  ukupna_kolicina_ove_vrste_otpada: null,
  stanje_privremenog_skladista_na_dan_1_januar: null,
  stanje_privremenog_skladista_na_dan_31_decembar: null,
  kolicina_spaljenog_otpada_uz_proizvodnju_energije: null,
  kolicina_kompostiranog_otpada: null,
  postupak_ponovnog_iskoriscenja_kolicina: null,
  postupak_ponovnog_iskoriscenja_r_oznaka_id: '',
  drugi_postupak_ponovnog_iskoriscenja_kolicina: null,
  drugi_postupak_ponovnog_iskoriscenja_r_oznaka_id: '',
  razredi_ee_otpada: [],
  kolicina_otpada_podvrgnuta_predtretmanu: null,
};

export const RAZREDI_EE_OTPADA_INITIAL_STATE: IPostRazrediEeOtpadaGio3 = {
  kolicina: null,
  razred_ee_otpada_id: '',
};

export const POSTROJENJA_GIO_3_INITIAL_STATE: IPostPostrojenjaGio3 = {
  adresa_postrojenja: '',
  mesto_postrojenja_id: '',
  geografska_sirina: '',
  geografska_duzina: '',
};

export const GIO_3_DOZVOLE_INITIAL_STATE: IPostDozvoleGio3 = {
  broj_dozvole: '',
  datum_izdavanja: '',
  datum_isteka: '',
  sakupljanje: false,
  transport: false,
  skladistenje: false,
  tretman: false,
  odlaganje: false,
};
