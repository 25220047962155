import { ColumnsType } from 'antd/es/table';
import { IGetWeeeCategories, IPostWeeeCategories, IWeeeCategoriesFilters } from './weeeCategories.types';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const WEEE_CATEGORIES_INITIAL_STATE: IPostWeeeCategories = {
  category_name: '',
  category_name_sr: '',
  the_order: '',
  is_active: true,
};
export const WEEE_CATEGORIES_FILTERS_INITIAL_STATE: IWeeeCategoriesFilters = {
  category_name__ilike: '',
  category_name_sr__ilike: '',
  the_order__ilike: '',
  is_active: null,
};
export const WEEE_CATEGORIES_TABLE_DATA: ColumnsType<IGetWeeeCategories> = [
  { title: 'Ime kategorije', width: 200, dataIndex: 'category_name', key: 'category_name' },
  { title: 'Ime kategorije (srp.)', width: 200, dataIndex: 'category_name_sr', key: 'category_name_sr' },
  THE_ORDER_TABLE,
];
export const WEEE_CATEGORIES_FORM_OPTIONS = (_: FormInstance<IGetWeeeCategories>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      name: 'category_name',
      filterName: 'category_name__ilike',
      rules: [MAX_LENGTH_RULE(300)],
      // todo: prevod
      label: 'Ime kategorije',
      inputName: 'category_name',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'category_name_sr',
      filterName: 'category_name_sr__ilike',
      rules: [MAX_LENGTH_RULE(300)],
      // todo: prevod
      label: 'Ime kategorije (srp.)',
      inputName: 'category_name_sr',
    } as InputSifarniciFormProps,
    THE_ORDER_FORM,
  ];
};
