import i18n from 'translations/i18n.config';
import { toast } from 'react-toastify';
export const handleSuccessNotify = () => {
  toast.success(`${i18n.t('SUCCESS')}`, {
    position: 'bottom-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};
