import { Col, Form, Row } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3IzmereneVrednostiRecipijenta } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { EqualsOrLessThan } from 'pages/ObrazacWizard/components/components/EqualsOrLessThan/EqualsOrLessThan';
import { useTranslation } from 'react-i18next';
import styles from './NewObrazac3AnalizaRecipijenataIzmereneVrednostiForm.module.scss';
import { useEffect, useState } from 'react';
import { IGetUnitCode } from 'modules/sifarnici/store/unitCode/unitCode.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { Select } from 'components/Select/Select';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';

export const NewObrazac3AnalizaRecipijenataIzmereneVrednostiForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const [option, setOption] = useState({ label: '', value: '' });

  const form = Form.useFormInstance();

  useEffect(() => {
    if (obrazac3Store.izmerene_vrednosti_recipijenta_form.jedinica_mere_id) {
      setOption({
        label: (obrazac3Store.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .jedinica_mere.code,
        value: obrazac3Store.izmerene_vrednosti_recipijenta_form.jedinica_mere_id,
      });
      form.setFieldValue('jedinica_mere_id', obrazac3Store.izmerene_vrednosti_recipijenta_form.jedinica_mere_id);
      obrazac3Store.handleChange(
        'jedinica_mere_option',
        (obrazac3Store.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta).jedinica_mere
      );
    }
  }, []);

  const handleZagadjujucaMaterijaChange = async (id: string) => {
    if (!id) {
      form.setFieldValue('jedinica_mere_id', '');
      obrazac3Store.handleChange('jedinica_mere_option', {});
      return;
    }
    let unitCode: IGetUnitCode = {} as IGetUnitCode;
    await obrazac3Store.fetchUnitCodeByPollutant(id).then(value => (unitCode = value));
    if (unitCode) {
      setOption({
        label: unitCode.code,
        value: unitCode.id,
      });
      form.setFieldValue('jedinica_mere_id', unitCode.id);
      obrazac3Store.handleChange('jedinica_mere_option', unitCode);
    } else {
      form.setFieldValue('jedinica_mere_id', '');
      obrazac3Store.handleChange('jedinica_mere_option', {});
    }
  };

  const defaultPollutantCodeOption: ISifarnikInitial | undefined = obrazac3Store.izmerene_vrednosti_recipijenta_form
    .naziv_zagadjujuce_materije_id
    ? {
        label: (obrazac3Store.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .naziv_zagadjujuce_materije.name_sr,
        value: obrazac3Store.izmerene_vrednosti_recipijenta_form.naziv_zagadjujuce_materije_id,
      }
    : undefined;

  const defaultMethodTypesOption: ISifarnikInitial | undefined = obrazac3Store.izmerene_vrednosti_recipijenta_form
    .metod_odredjivanja_id
    ? {
        label: (obrazac3Store.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .metod_odredjivanja.name_sr,
        value: obrazac3Store.izmerene_vrednosti_recipijenta_form.metod_odredjivanja_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24} className={styles.wrapper}>
      <h4>Zagađujuća materija</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_zagadjujuce_materije_id'}
        fetchOptinsList={obrazac3Store.fetchPollutantCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'naziv_zagadjujuce_materije_id'}
        label={t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                obrazac3Store.izmerene_vrednosti_recipijenta_form.naziv_zagadjujuce_materije_id !== value &&
                obrazac3Store.recipijent_merenje_form.izmerene_vrednosti_recipijenta.some(item => {
                  return value === item.naziv_zagadjujuce_materije_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.GVE.ISTA_ZAGADJUJUCA_UPOZORENJE')));
              }
              return Promise.resolve();
            },
          },
        ]}
        onChange={handleZagadjujucaMaterijaChange}
        defaultOption={defaultPollutantCodeOption}
        placeholder={''}
        readOnly={readOnly}
      />
      <Form.Item
        name={'jedinica_mere_id'}
        label={t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Select
          name="jedinica_mere_id"
          options={[option, { label: '', value: '' }]}
          disabled
          readOnly={readOnly}
        ></Select>
      </Form.Item>
      <Row>
        <Col span={15}>
          <EqualsOrLessThan
            name={'predznak_izmerene_vrednosti_pre_ispusta'}
            label={t('OBRASCI.OBRAZAC3.PRE_ISPUSTA')}
            labelCol={{ span: 19 }}
            wrapperCol={{ span: 3 }}
            readOnly={readOnly}
          />
        </Col>
        <Col span={9}>
          <FormInputNumber
            name="izmerene_vrednosti_pre_ispusta"
            placeholder={''}
            inputName={'izmerene_vrednosti_pre_ispusta'}
            colon={true}
            rules={[
              REQUIRED_FIELD_RULE(true),
              {
                validator(_, value) {
                  if (value < 0) return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.LESS_THAN_0')));
                  return Promise.resolve();
                },
              },
            ]}
            wrapperCol={{ span: 24 }}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <EqualsOrLessThan
            name={'predznak_izmerene_vrednosti_posle_ispusta'}
            label={t('OBRASCI.OBRAZAC3.POSLE_ISPUSTA')}
            labelCol={{ span: 19 }}
            wrapperCol={{ span: 3 }}
            readOnly={readOnly}
          />
        </Col>
        <Col span={9}>
          <FormInputNumber
            name="izmerene_vrednosti_posle_ispusta"
            placeholder={''}
            inputName={'izmerene_vrednosti_posle_ispusta'}
            colon={true}
            rules={[
              REQUIRED_FIELD_RULE(true),
              {
                validator(_, value) {
                  if (value < 0) return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.LESS_THAN_0')));
                  return Promise.resolve();
                },
              },
            ]}
            wrapperCol={{ span: 24 }}
            readOnly={readOnly}
          />
        </Col>
      </Row>

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'metod_odredjivanja_id'}
        fetchOptinsList={obrazac3Store.fetchMethodTypesMerenja}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'metod_odredjivanja_id'}
        label={t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultMethodTypesOption}
        placeholder={''}
        readOnly={readOnly}
      />
    </Col>
  );
});
