import { ColumnsType } from 'antd/es/table';
import { IGetWaterBodyCategory, IPostWaterBodyCategory, IWaterBodyCategoryFilters } from './waterBodyCategory.types';
import { FormInstance } from 'antd';
import i18n from 'translations/i18n.config';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const WATER_BODY_CATEGORY_INITIAL_STATE: IPostWaterBodyCategory = {
  category: '',
  is_active: true,
};
export const WATER_BODY_CATEGORY_FILTERS_INITIAL_STATE: IWaterBodyCategoryFilters = {
  category__ilike: '',
  is_active: null,
};

export const WATER_BODY_CATEGORY_TABLE_DATA: ColumnsType<IGetWaterBodyCategory> = [
  {
    title: i18n.t('SIFARNICI.NAZIV'),
    // align: 'center',
    width: '700px',
    dataIndex: 'category',
    key: 'category',
  },
];
export const WATER_BODY_CATEGORY_FORM_OPTIONS = (_: FormInstance<IGetWaterBodyCategory>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      label: i18n.t('SIFARNICI.NAZIV'),
      inputName: 'category',
      name: 'category',
      filterName: 'category__ilike',
    } as InputSifarniciFormProps,
  ];
};
