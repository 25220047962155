import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  DESCRIPTION_FORM,
  CODE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
  CODE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostPollutantGroup, IGetPollutantGroup, IPollutantGroupFilters } from './pollutantGroup.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const POLLUTANT_GROUP_INITIAL_STATE: IPostPollutantGroup = {
  validity_from_date: '',
  validity_to_date: '',
  description: '',
  code: '',
  name: '',
  name_sr: '',
  is_active: true,
};
export const POLLUTANT_GROUP_FILTERS_INITIAL_STATE: IPollutantGroupFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  description__ilike: '',
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};

export const POLLUTANT_GROUP_TABLE_DATA: ColumnsType<IGetPollutantGroup> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
  CODE_TABLE,
];

export const POLLUTANT_GROUP_FORM_OPTIONS = (form: FormInstance<IGetPollutantGroup>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(300),
    NAME_SR_FORM(300),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    DESCRIPTION_FORM(500),
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(100)] },
  ];
};
