import { ColumnsType } from 'antd/es/table';
import { IGetNuts1Code, INuts1CodeFilters, IPostNuts1Code } from './nuts1Code.types';
import { CODE_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { CODE_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const NUTS1_CODE_INITIAL_STATE: IPostNuts1Code = {
  code: '',
  name: '',
  is_active: true,
};
export const NUTS1_CODE_FILTERS_INITIAL_STATE: INuts1CodeFilters = {
  code__ilike: '',
  name__ilike: '',
  is_active: null,
};
export const NUTS1_CODE_TABLE_DATA: ColumnsType<IGetNuts1Code> = [CODE_TABLE, { ...NAME_TABLE, width: '600px' }];
export const NUTS1_CODE_FORM_OPTIONS = (_: FormInstance<IGetNuts1Code>): ISifarniciTypeOfForm[] => {
  return [{ ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(50)] }, NAME_FORM(100)];
};
