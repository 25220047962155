import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { LATITUDE_RULE, LONGITUDE_RULE, MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetPostrojenjeGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewPostrojenjaGio3Form: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaMestoOption: ISifarnikInitial | undefined = gio3ObrazacStore.postrojenja_form.mesto_postrojenja_id
    ? {
        label: (gio3ObrazacStore.postrojenja_form as IGetPostrojenjeGio3).mesto_postrojenja.display_name,
        value: gio3ObrazacStore.postrojenja_form.mesto_postrojenja_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  const stariSistem = Number(obrasciStore.opste.godina) < 2013;

  return (
    <>
      <h3>{t('OBRASCI.GIO_3.DATA_ON_THE_WASTE_RECYCLING_FACILITY_OWNED_BY_COMPANY')}</h3>
      <FormInput
        name="adresa_postrojenja"
        type="text"
        label={t('ADDRESS')}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(200)]}
        placeholder={''}
        inputName={'adresa_postrojenja'}
        colon={true}
        readOnly={readOnly}
      />
      {stariSistem ? (
        <FormInput
          inputName="stari_sistem_lokacija_postrojenja"
          name="stari_sistem_lokacija_postrojenja"
          type="text"
          label={t('COMPANY.LOCATION')}
          placeholder=""
          colon
          rules={[MAX_LENGTH_RULE(200)]}
          readOnly={readOnly}
        />
      ) : (
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'mesto_postrojenja_id'}
          fetchOptinsList={gio3ObrazacStore.fetchMesto}
          labelOptionAccessor="display_name"
          rules={[REQUIRED_FIELD_RULE(true)]}
          valueOptionAccessor="id"
          label={t('COMPANY.LOCATION')}
          inputName={'mesto_postrojenja_id'}
          defaultOption={defaMestoOption}
          placeholder={t('COMPANY.LOCATION')}
          readOnly={readOnly}
        />
      )}
      <FormInput
        type="number"
        name="geografska_sirina"
        label={t('COMPANY.LATITUDE')}
        inputName={'geografska_sirina'}
        colon={true}
        readOnly={readOnly}
        rules={LATITUDE_RULE()}
      />
      <FormInput
        type="number"
        name="geografska_duzina"
        label={t('COMPANY.LONGITUDE')}
        placeholder={''}
        inputName={'geografska_duzina'}
        colon={true}
        readOnly={readOnly}
        rules={LONGITUDE_RULE()}
      />

      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>
    </>
  );
});
