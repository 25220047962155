import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { nrizService } from 'modules/nriz/nriz.service';
import { IPagination } from 'typescript';
import { formStore } from '../../store/FormStore';
import { modalStore } from '../../store/ModalStore';
import { handleSuccessNotify } from 'utils/succesHandling';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { facilityStore } from 'modules/facility/facility.store';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetActivity1, IPostActivity1 } from './activityList1.types';
import { activityInitialState } from './activityList1.constants';
import { activity1Service } from './activityList1.service';
import { activityList2Store } from 'modules/activityList2/activityList2.store';
import {
  deleteGio1OnAddingActivity1,
  deleteTipoveObrazacaIfActiveListIsEmpty,
} from 'modules/obrasci/tipoviObrasci.service';
import { IGetPrivrednaDelatnost1 } from 'modules/sifarnici/store/privrednaDelatnost1/privrednaDelatnost1.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { ITablePagination } from 'components/TableData/TableData';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { IGetAktivnost1 } from 'modules/sifarnici/store/aktivnost1/aktivnost1.types';

export class ActivityList1Store {
  constructor() {
    makeAutoObservable(this);
  }

  activityList: IGetActivity1[] = [];
  activity: IPostActivity1 | IGetActivity1 = activityInitialState;
  privredna_delatnost_id = '';
  activityList1Pagination: IPagination = paginationInitialState;

  get getActivityList() {
    return this.activityList;
  }

  get getActivity() {
    return this.activity;
  }

  handleChange(key: keyof ActivityList1Store, value: any) {
    (this as any)[key] = value;
  }

  setActivityList(activityList: IGetActivity1[]) {
    this.activityList = activityList;
  }

  setActivity(activity: IGetActivity1 | IPostActivity1) {
    this.activity = activity;
  }

  fetchAktivnost1 = async (pagination: IPagination): Promise<IPaginatedResponse<IGetAktivnost1>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetAktivnost1>(SIFARNICI_ROUTES.AKTIVNOST_1, {
      page: pagination.page,
      size: pagination.size,
      privredna_delatnost_id: this.privredna_delatnost_id,
      order_by: '+code',
    });
    return response;
  };

  fetchPrivrednaDelatnost1 = async (pagination: IPagination): Promise<IPaginatedResponse<IGetPrivrednaDelatnost1>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetPrivrednaDelatnost1>(
      SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1,
      {
        page: pagination.page,
        size: pagination.size,
        order_by: '+code',
      }
    );
    return response;
  };

  fetchActivityList = async (pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetActivity1>>(
      activity1Service.fetchActivityList1({
        postrojenje_id: (facilityStore.facility as IGetFacility).id,
        page: pagination?.page || this.activityList1Pagination.page,
        size: pagination?.size || this.activityList1Pagination.size,
      })
    );
    if (err || !res) return Promise.reject();
    const { items, page, size, total, pages } = res;
    this.setActivityList(items);
    this.handleChange('activityList1Pagination', { page, size, total, pages });
    return res;
  };

  postActivity = async (payload: IPostActivity1) => {
    const validPayload = nrizService.setEmptyValuesToNull({
      ...payload,
      postrojenje_id: (facilityStore.facility as IGetFacility).id,
    });

    const [err, res] = await to<IPostResponse>(activity1Service.postActivity1(validPayload as IGetActivity1));
    if (err || !res) return;
    const { id } = res;
    if (
      (
        sifarniciStore.getSifarniciIdMap(SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1)[
          payload.privredna_delatnost_id
        ] as IGetPrivrednaDelatnost1
      ).code === '0'
    ) {
      deleteTipoveObrazacaIfActiveListIsEmpty();
    }

    if (this.activityList1Pagination.total === 0) {
      deleteGio1OnAddingActivity1();
    }

    let aktivnostSifarnik = {};
    let privrednaDelatnostSifarnik = {};

    aktivnostSifarnik = nrizService.addSifarnikInObject({
      list: sifarniciStore.list[SIFARNICI_ROUTES.AKTIVNOST_1],
      name: 'aktivnost1',
      id: payload.aktivnost_id,
    });

    privrednaDelatnostSifarnik = nrizService.addSifarnikInObject({
      list: sifarniciStore.list[SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1],
      name: 'privredna_delatnost1',
      id: payload.privredna_delatnost_id,
    });

    const newPayload = {
      id,
      ...payload,
      ...aktivnostSifarnik,
      ...privrednaDelatnostSifarnik,
    } as IGetActivity1;

    const activityListToChange = payload.glavna_aktivnost
      ? this.activityList.map(activity => ({ ...activity, glavna_aktivnost: false }))
      : this.activityList;

    const newActivityList = [newPayload, ...activityListToChange];
    this.handleChange('activityList1Pagination', nrizService.increaseTotalPagination(this.activityList1Pagination));
    this.setActivityList(newActivityList);
    this.handleChange('privredna_delatnost_id', '');
    this.handleChange('activity', activityInitialState);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  putActivity = async (payload: IGetActivity1) => {
    if (!(facilityStore.facility as IGetFacility).id) return;
    const validPayload = { ...payload, postrojenje_id: (facilityStore.facility as IGetFacility).id };

    const [err, res] = await to<IEmptyResponse>(activity1Service.putActivity1(validPayload));
    if (err || !res) return;
    const indexCode =
      this.activity.privredna_delatnost_id === payload.privredna_delatnost_id
        ? payload.privredna_delatnost1.code
        : (
            sifarniciStore.getSifarniciIdMap(SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1)[
              payload.privredna_delatnost_id
            ] as IGetPrivrednaDelatnost1
          ).code;

    if (indexCode === '0') {
      deleteTipoveObrazacaIfActiveListIsEmpty();
    }

    let aktivnostSifarnik = {};
    let privrednaDelatnostSifarnik = {};

    aktivnostSifarnik = nrizService.addSifarnikInObject({
      exId: this.activity.aktivnost_id,
      exObj: (this.activity as IGetActivity1).aktivnost1,
      list: sifarniciStore.list[SIFARNICI_ROUTES.AKTIVNOST_1],
      name: 'aktivnost1',
      id: payload.aktivnost_id,
    });

    privrednaDelatnostSifarnik = nrizService.addSifarnikInObject({
      exId: this.activity.privredna_delatnost_id,
      exObj: (this.activity as IGetActivity1).privredna_delatnost1,
      list: sifarniciStore.list[SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1],
      name: 'privredna_delatnost1',
      id: payload.privredna_delatnost_id,
    });

    const newPayload = { ...validPayload, ...aktivnostSifarnik, ...privrednaDelatnostSifarnik };
    const activityListToChange = payload.glavna_aktivnost
      ? this.activityList.map(activity => ({ ...activity, glavna_aktivnost: false }))
      : this.activityList;
    this.setActivityList([...nrizService.changeItemInList(activityListToChange, newPayload)]);
    this.handleChange('privredna_delatnost_id', '');
    this.handleChange('activity', activityInitialState);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  deleteActivity = async (id: string) => {
    const [err, res] = await to<IEmptyResponse>(activity1Service.deleteActivity1(id));
    if (err || !res) return;
    this.handleChange('activityList1Pagination', nrizService.decreaseTotalPagination(this.activityList1Pagination));
    const newActivityList = this.activityList.filter(activity => activity.id !== id);
    this.setActivityList(newActivityList);
    if (this.activityList1Pagination.total === 0 && activityList2Store.activityList2Pagination.total === 0) {
      deleteTipoveObrazacaIfActiveListIsEmpty();
    }
    this.handleChange('activity', activityInitialState);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  submitActivity = (values: IPostActivity1) => {
    if (formStore.getCrudButtonType === 'submit') {
      return this.postActivity(values);
    }
    const newObject = { ...this.activity, ...values } as IGetActivity1;
    if (formStore.getCrudButtonType === 'change') {
      return this.putActivity(newObject);
    }
    if (formStore.getCrudButtonType === 'delete') {
      if (newObject.id) {
        return this.deleteActivity(newObject.id);
      }
    }
  };
}

export const activityList1Store = new ActivityList1Store();
