import { Col, Row } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './NewKlasifikacijaForm.module.scss';
import { LATITUDE_RULE, LONGITUDE_RULE, MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { nListOptions, qListOptions, yListOptions } from 'utils/optionMakers';
import { IGetWasteCharacter } from 'modules/sifarnici/store/wasteCharacter/wasteCharacter.types';

export const NewKlasifikacijeObrazac5Form: React.FC<ICrudTypeModalProps> = observer(_form => {
  const { t } = useTranslation();

  // const [yListRequired, setYListRequired] = useState<boolean>(true);:
  // const [isNListRequired, setNListRequired] = useState<boolean>(true);

  // const handleYListRequired = () => {
  //   const fields = form.getFieldsValue([
  //     'oznaka_otpada_y_lista_id',
  //     'oznaka_otpada_y_lista2_id',
  //     'oznaka_otpada_y_lista3_id',
  //   ]);
  //   if (Object.values(fields).every(value => value === '' || value === undefined)) {
  //     setYListRequired(true);
  //   } else {
  //     setYListRequired(false);
  //   }
  // };

  // const handleNListRequired = () => {
  //   const fields = form.getFieldsValue([
  //     'oznaka_otpada_n_lista_id',
  //     'oznaka_otpada_n_lista2_id',
  //     'oznaka_otpada_n_lista3_id',
  //   ]);
  //   if (Object.values(fields).every(value => value === '' || value === undefined)) {
  //     setNListRequired(true);
  //   } else {
  //     setNListRequired(false);
  //   }
  // };

  // todo prepakovati dependency

  // useEffect(() => {
  //   handleYListRequired();
  // }, [
  //   Form.useWatch('oznaka_otpada_y_lista_id'),
  //   Form.useWatch('oznaka_otpada_y_lista2_id'),
  //   Form.useWatch('oznaka_otpada_y_lista3_id'),
  // ]);

  // useEffect(() => {
  //   handleNListRequired();
  // }, [
  //   Form.useWatch('oznaka_otpada_n_lista_id'),
  //   Form.useWatch('oznaka_otpada_n_lista2_id'),
  //   Form.useWatch('oznaka_otpada_n_lista3_id'),
  // ]);

  const defaultVrstaOtpada: ISifarnikInitial | undefined = obrazac5Store.initialState.vrsta_otpada_id
    ? {
        label: (obrazac5Store.getInitialState as IGetObrazac5).vrsta_otpada.name_sr,
        value: (obrazac5Store.getInitialState as IGetObrazac5).vrsta_otpada_id,
      }
    : undefined;

  const defaultKarakterOtpada: ISifarnikInitial | undefined = obrazac5Store.initialState.karakter_otpada_id
    ? {
        label: (obrazac5Store.getInitialState as IGetObrazac5).karakter_otpada.name_sr,
        value: (obrazac5Store.getInitialState as IGetObrazac5).karakter_otpada_id,
      }
    : undefined;

  const defaultQlistOption: ISifarnikInitial | undefined = obrazac5Store.getInitialState.karakter_otpada_q_lista_id
    ? {
        value: obrazac5Store.getInitialState.karakter_otpada_q_lista_id,
        label: `${(obrazac5Store.getInitialState as IGetObrazac5).karakter_otpada_q_lista.name} ${
          (obrazac5Store.getInitialState as IGetObrazac5).karakter_otpada_q_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlistOption: ISifarnikInitial | undefined = obrazac5Store.getInitialState.oznaka_otpada_n_lista_id
    ? {
        value: obrazac5Store.getInitialState.oznaka_otpada_n_lista_id,
        label: `${(obrazac5Store.getInitialState as IGetObrazac5).oznaka_otpada_n_lista.name} ${
          (obrazac5Store.getInitialState as IGetObrazac5).oznaka_otpada_n_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlist2Option: ISifarnikInitial | undefined = obrazac5Store.getInitialState.oznaka_otpada_n_lista2_id
    ? {
        value: obrazac5Store.getInitialState.oznaka_otpada_n_lista2_id,
        label: `${(obrazac5Store.getInitialState as IGetObrazac5).oznaka_otpada_n_lista2.name} ${
          (obrazac5Store.getInitialState as IGetObrazac5).oznaka_otpada_n_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultNlist3Option: ISifarnikInitial | undefined = obrazac5Store.getInitialState.oznaka_otpada_n_lista3_id
    ? {
        value: obrazac5Store.getInitialState.oznaka_otpada_n_lista3_id,
        label: `${(obrazac5Store.getInitialState as IGetObrazac5).oznaka_otpada_n_lista3.name} ${
          (obrazac5Store.getInitialState as IGetObrazac5).oznaka_otpada_n_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultYlistOption: ISifarnikInitial | undefined = obrazac5Store.initialState.oznaka_otpada_y_lista_id
    ? {
        value: obrazac5Store.initialState.oznaka_otpada_y_lista_id,
        label: `${(obrazac5Store.initialState as IGetObrazac5).oznaka_otpada_y_lista.name} ${
          (obrazac5Store.initialState as IGetObrazac5).oznaka_otpada_y_lista.description_sr
        }`,
      }
    : undefined;

  const defaultYlist2Option: ISifarnikInitial | undefined = obrazac5Store.initialState.oznaka_otpada_y_lista2_id
    ? {
        value: obrazac5Store.initialState.oznaka_otpada_y_lista2_id,
        label: `${(obrazac5Store.initialState as IGetObrazac5).oznaka_otpada_y_lista2.name} ${
          (obrazac5Store.initialState as IGetObrazac5).oznaka_otpada_y_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultYlist3Option: ISifarnikInitial | undefined = obrazac5Store.initialState.oznaka_otpada_y_lista3_id
    ? {
        value: obrazac5Store.initialState.oznaka_otpada_y_lista3_id,
        label: `${(obrazac5Store.initialState as IGetObrazac5).oznaka_otpada_y_lista3.name} ${
          (obrazac5Store.initialState as IGetObrazac5).oznaka_otpada_y_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultFizickoStanjeOtpada: ISifarnikInitial | undefined = obrazac5Store.initialState.fizicko_stanje_otpada_id
    ? {
        label: (obrazac5Store.getInitialState as IGetObrazac5).fizicko_stanje_otpada.name_sr,
        value: (obrazac5Store.getInitialState as IGetObrazac5).fizicko_stanje_otpada_id,
      }
    : undefined;

  const characterListMaker = (options: IGetWasteCharacter[]) => {
    if (obrazac5Store.getDangerousWaste) {
      const hazardousOptions = options.filter(entity => entity.name_sr === 'Opasan');
      return hazardousOptions.map(entity => ({
        label: entity.name_sr,
        value: entity.id,
      }));
    }
    const otherOptions = options.filter(entity => entity.name_sr !== 'Opasan');
    return otherOptions.map(entity => ({
      label: `${entity.name_sr}`,
      value: entity.id,
    }));
  };

  return (
    <Col span={24} className={styles.wrapper}>
      <h3 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.KLASIFIKACIJA_OTPADA')}</h3>
      <FormInput
        name="mesto_nastanka_otpada"
        label={t('OBRASCI.OBRAZAC5.MESTO_NASTANKA_OTPADA')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.MESTO_NASTANKA_OTPADA')}
        inputName={'mesto_nastanka_otpada'}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
        colon={true}
      />
      <FormInput
        type="number"
        name="koordinate_lat"
        label={t('COMPANY.LATITUDE')}
        placeholder={t('COMPANY.LATITUDE')}
        inputName={'koordinate_lat'}
        colon={true}
        rules={LATITUDE_RULE()}
      />
      <FormInput
        type="number"
        name="koordinate_long"
        label={t('COMPANY.LONGITUDE')}
        placeholder={t('COMPANY.LONGITUDE')}
        inputName={'koordinate_long'}
        colon={true}
        rules={LONGITUDE_RULE()}
      />
      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'vrsta_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchTypeOfWaste}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'vrsta_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.VRSTA_OTPADA')}
        colon={true}
        defaultOption={defaultVrstaOtpada}
        placeholder={t('OBRASCI.OBRAZAC5.VRSTA_OTPADA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormTextArea
        name={'opis_otpada'}
        rows={2}
        labelCol={9}
        wrapperCol={15}
        label={t('OBRASCI.OBRAZAC5.OPIS_OTPADA')}
        placeholder={t('OBRASCI.OBRAZAC5.OPIS_OTPADA')}
        rules={[MAX_LENGTH_RULE(255)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'karakter_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchWasteCharacter}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'karakter_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.KARAKTER_OTPADA')}
        colon={true}
        defaultOption={defaultKarakterOtpada}
        placeholder={t('OBRASCI.OBRAZAC5.KARAKTER_OTPADA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        optionsMaker={characterListMaker}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'karakter_otpada_q_lista_id'}
        fetchOptinsList={obrazac5Store.fetchQlist}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        inputName={'karakter_otpada_q_lista_id'}
        label={t('OBRASCI.OBRAZAC5.KATEGORIJA_OTPADA_Q_LISTA')}
        colon={true}
        defaultOption={defaultQlistOption}
        placeholder={t('OBRASCI.OBRAZAC5.KATEGORIJA_OTPADA_Q_LISTA')}
        optionsMaker={qListOptions}
      />

      <h3 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.IZVESTAJ_O_ISPITIVANJU')}</h3>

      <FormInput
        name="broj_izvestaja"
        label={t('OBRASCI.OBRAZAC5.BROJ_IZVESTAJA')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.BROJ_IZVESTAJA')}
        inputName={'broj_izvestaja'}
        colon={true}
        rules={[MAX_LENGTH_RULE(30)]}
      />
      <FormDatePicker
        name="datum_izdavanja"
        label={t('OBRASCI.OBRAZAC5.DATUM_IZDAVANJA')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
        placeholder={t('OBRASCI.OBRAZAC5.DATUM_IZDAVANJA')}
      />

      {obrazac5Store.getDangerousWaste && (
        <>
          <h3 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.OPASAN_OTPAD_PORUKA')}</h3>
          <Row>
            <Col span={9}>
              <h4>{t('OBRASCI.OBRAZAC5.OZNAKA_OPASNE_KARAKTERISTIKE')}</h4>
            </Col>
            <Col span={5}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'oznaka_otpada_n_lista_id'}
                fetchOptinsList={obrazac5Store.fetchNListOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'oznaka_otpada_n_lista_id'}
                label={t('H')}
                colon={true}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                defaultOption={defaultNlistOption}
                placeholder={t('H')}
                // onChange={handleNListRequired}
                // rules={[REQUIRED_FIELD_RULE(isNListRequired)]}
                optionsMaker={nListOptions}
              />
            </Col>
            <Col span={5}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'oznaka_otpada_n_lista2_id'}
                fetchOptinsList={obrazac5Store.fetchNListOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'oznaka_otpada_n_lista2_id'}
                colon={true}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                label={t('/ H')}
                defaultOption={defaultNlist2Option}
                placeholder={t('/ H')}
                // onChange={handleNListRequired}
                // rules={[REQUIRED_FIELD_RULE(isNListRequired)]}
                optionsMaker={nListOptions}
              />
            </Col>
            <Col span={5}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'oznaka_otpada_n_lista3_id'}
                fetchOptinsList={obrazac5Store.fetchNListOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'oznaka_otpada_n_lista3_id'}
                colon={true}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                label={t('/ H')}
                defaultOption={defaultNlist3Option}
                placeholder={t('/ H')}
                // onChange={handleNListRequired}
                // rules={[REQUIRED_FIELD_RULE(isNListRequired)]}
                optionsMaker={nListOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              <h4>{t('OBRASCI.OBRAZAC5.KATEGORIJA_PO_POREKLU')}</h4>
            </Col>
            <Col span={5}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'oznaka_otpada_y_lista_id'}
                fetchOptinsList={obrazac5Store.fetchYListOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'oznaka_otpada_y_lista_id'}
                label={t('Y')}
                colon={true}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                defaultOption={defaultYlistOption}
                placeholder={t('Y')}
                // onChange={handleYListRequired}
                // rules={[REQUIRED_FIELD_RULE(yListRequired)]}
                optionsMaker={yListOptions}
              />
            </Col>
            <Col span={5}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'oznaka_otpada_y_lista2_id'}
                fetchOptinsList={obrazac5Store.fetchYListOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'oznaka_otpada_y_lista2_id'}
                colon={true}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                label={t('/ Y')}
                defaultOption={defaultYlist2Option}
                placeholder={t('/ Y')}
                // onChange={handleYListRequired}
                // rules={[REQUIRED_FIELD_RULE(yListRequired)]}
                optionsMaker={yListOptions}
              />
            </Col>
            <Col span={5}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'oznaka_otpada_y_lista3_id'}
                fetchOptinsList={obrazac5Store.fetchYListOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                inputName={'oznaka_otpada_y_lista3_id'}
                colon={true}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                label={t('/ Y')}
                defaultOption={defaultYlist3Option}
                placeholder={t('/ Y')}
                // onChange={handleYListRequired}
                // rules={[REQUIRED_FIELD_RULE(yListRequired)]}
                optionsMaker={yListOptions}
              />
            </Col>
          </Row>
        </>
      )}
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'fizicko_stanje_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchWasteStateCategory}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'fizicko_stanje_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.FIZICKO_STANJE_OTPADA')}
        colon={true}
        defaultOption={defaultFizickoStanjeOtpada}
        placeholder={t('OBRASCI.OBRAZAC5.FIZICKO_STANJE_OTPADA')}
      />
    </Col>
  );
});
