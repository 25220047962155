import { ColumnsType } from 'antd/es/table';
import {
  IGetPrivrednaDelatnost1,
  IPostPrivrednaDelatnost1,
  IPrivrednaDelatnost1Filters,
} from './privrednaDelatnost1.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import {
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  NAME_TABLE,
  NAME_SR_TABLE,
  CODE_2_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  CODE_2_FORM,
  VALIDITY_TO_DATE_FORM,
  VALIDITY_FROM_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const PRIVREDNA_DELATNOST_1_FILTERS_INITIAL_STATE: IPrivrednaDelatnost1Filters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  minimumthresholdofcapacity__ilike: '',
  minimumthresholdofcapacity_sr__ilike: '',
  ippccode__ilike: '',
  pd_level__ilike: '',
  code2__ilike: '',
  is_active: null,
};
export const PRIVREDNA_DELATNOST_1_INITIAL_STATE: IPostPrivrednaDelatnost1 = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  minimumthresholdofcapacity: '',
  minimumthresholdofcapacity_sr: '',
  ippccode: '',
  pd_level: '',
  code2: '',
  is_active: true,
};

export const PRIVREDNA_DELATNOST_1_TABLE_DATA: ColumnsType<IGetPrivrednaDelatnost1> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  // todo: prevod
  {
    title: 'Minimalna granična vrednost kapaciteta ',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity',
    key: 'minimumthresholdofcapacity',
  },
  {
    title: 'Minimalna granična vrednost kapaciteta (srp.)',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity_sr',
    key: 'minimumthresholdofcapacity_sr',
  },
  {
    title: 'IPPC kod',
    width: '100px',
    dataIndex: 'ippccode',
    key: 'ippccode',
  },
  {
    title: 'Nivo pd',
    width: '100px',
    dataIndex: 'pd_level',
    key: 'pd_level',
  },
  CODE_2_TABLE,
];

export const PRIVREDNA_DELATNOST_1_FORM_OPTIONS = (
  form: FormInstance<IGetPrivrednaDelatnost1>
): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(1000),
    NAME_SR_FORM(1000),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM(500),
    DESCRIPTION_FORM(1000),
    { ...VERSION_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(5)] },
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity',
      filterName: 'minimumthresholdofcapacity__ilike',
      // todo: prevod
      rules: [MAX_LENGTH_RULE(1000)],
      label: 'Minimalna granična vrednost kapaciteta ',
      inputName: 'minimumthresholdofcapacity',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity_sr',
      filterName: 'minimumthresholdofcapacity_sr__ilike',
      rules: [MAX_LENGTH_RULE(1000)],
      // todo: prevod
      label: 'Minimalna granična vrednost kapaciteta (srp.)',
      inputName: 'minimumthresholdofcapacity_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      rules: [MAX_LENGTH_RULE(15)],
      name: 'ippccode',
      filterName: 'ippccode__ilike',
      // todo: prevod
      label: 'IPPC kod',
      inputName: 'ippccode',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'pd_level',
      filterName: 'pd_level__ilike',
      precision: 0,
      // todo: prevod
      label: 'Nivo pd',
      inputName: 'pd_level',
    } as InputSifarniciFormProps,
    { ...CODE_2_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
  ];
};
