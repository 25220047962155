import { ColumnsType } from 'antd/es/table';
import {
  IGetWasteCatalogSubgroup,
  IPostWasteCatalogSubgroup,
  IWasteCatalogSubgroupFilters,
} from './wasteCatalogSubgroup.types';
import { CODE_TABLE, DESCRIPTION_SR_TABLE, DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { CODE_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const WASTE_CATALOG_SUBGROUP_INITIAL_STATE: IPostWasteCatalogSubgroup = {
  code: '',
  description: '',
  description_sr: '',
  is_active: true,
};
export const WASTE_CATALOG_SUBGROUP_FILTERS_INITIAL_STATE: IWasteCatalogSubgroupFilters = {
  code__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
  is_active: null,
};
export const WASTE_CATALOG_SUBGROUP_TABLE_DATA: ColumnsType<IGetWasteCatalogSubgroup> = [
  CODE_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
];
export const WASTE_CATALOG_SUBGROUP_FORM_OPTIONS = (
  _: FormInstance<IGetWasteCatalogSubgroup>
): ISifarniciTypeOfForm[] => {
  return [
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
    DESCRIPTION_FORM(1000),
    DESCRIPTION_SR_FORM(1000),
  ];
};
