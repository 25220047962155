import { observer } from 'mobx-react-lite';
import { Content } from 'components/Content/Content';
import { CompanyInfo } from './components/CompanyInfo/CompanyInfo';
import { CompanyUsersList } from './components/CompanyUsersList/CompanyUsersList';
import { CompanyFacilityList } from './components/CompanyFacilityList/CompanyFacilityList';
import { useParams } from 'react-router-dom';
import { authStore } from 'store';
import { useEffect } from 'react';
import { CompanyUnsanitaryLandfillList } from './components/CompanyUnsanitaryLandfillList/CompanyUnsanitaryLandfillList';
import { CompanyTipoviObrazaca } from './components/CompanyTipoviObrazaca/CompanyTipoviObrazaca';
import { IGetCompany } from 'modules/company/company.types';
import { CompanyStatus, companyInitialState } from 'modules/company/company.constants';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import { userStore } from 'modules/user/user.store';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoutePathEnum } from 'router/router.constants';

export const CompanyHeader: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h1>{`${t('COMPANY.TITLE')} /`}</h1>
        <h2 style={{ color: 'gray' }}>{`${companyStore.company.kraci_naziv ?? ''}`}</h2>
      </div>
    </Row>
  );
});

const Company: React.FC = observer(() => {
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      companyStore.fetchCompany(id);
      if (!authStore.isOnlyFacilityUser) companyStore.fetchCompanyTipoviObrasca(id);
    }

    return () => {
      if (authStore.isAdmin) {
        companyStore.setCompany(companyInitialState);
        companyStore.setCompanyTipoviObrazaca([]);
        userStore.setUserList([]);
        facilityStore.setFacilityList([]);
        unsanitaryLandfillStore.setUnsanitaryLandfillList([]);
        facilityStore.handleChange('readOnlyTipoviObrazacaForm', true);
      }
    };
  }, []);

  return (
    <Content
      header={<CompanyHeader />}
      backBtn={
        authStore.isAdmin
          ? {
              name: 'preduzece',
              route: RoutePathEnum.LISTA_PREDUZECA,
              label: t('COMPANY.LIST'),
            }
          : undefined
      }
    >
      <CompanyInfo />
      {(companyStore.company as IGetCompany).id && (
        <>
          {authStore.isAdmin && <CompanyUsersList />}
          {companyStore.company.status !== CompanyStatus.U_PRIPREMI && (
            <>
              {!authStore.isOnlyFacilityUser && <CompanyTipoviObrazaca />}
              <CompanyFacilityList />
              {!authStore.isOnlyFacilityUser && <CompanyUnsanitaryLandfillList />}
            </>
          )}
        </>
      )}
    </Content>
  );
});

export default Company;
