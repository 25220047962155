import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IPagination } from 'typescript';
import { registriService } from './registri.service';

class RegistriStore {
  registriList: { name: string }[] = [];
  registriPagination: IPagination = paginationInitialState;
  constructor() {
    makeAutoObservable(this);
  }

  handleChange<K extends keyof RegistriStore>(key: K, value: this[K]): void {
    this[key] = value;
  }
  // todo: obrisati eslint-disabled
  // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
  fetchRegistriList = async () => {};

  resetRegistri = () => {
    this.handleChange('registriList', []);
    this.handleChange('registriPagination', paginationInitialState);
  };

  checkIfRegistarExists = async (postrojenje_id: string, izvor_zagadjenja_vode_id?: string) => {
    const [err, res] = await to(registriService.checkIfRegistarExists(postrojenje_id, izvor_zagadjenja_vode_id));
    if (err || !res) return;

    return res.registar_postoji;
  };
}
export const registriStore = new RegistriStore();
