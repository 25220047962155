import { Col, Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import {
  MAX_AND_MIN_NUMBER,
  MORE_OR_EQUILE_0_IS_REQUIRED,
  MORE_THEN_0,
  MonthsOptions,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import {
  brojRadnihDanaNedeljnoOptions,
  brojSmenaDnevnoOptions,
  zaposleniUPostrojenju,
} from 'modules/obrasci/store/obrazac1/obrazac1.constants';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Obrazac1DodatniPodaciWizardTab.module.scss';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetObrazac1 } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';

export const Obrazac1DodatniPodaciWizardTab: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!obrazac1Store.showIfSezonski) {
      props.form.setFieldValue('pocetak_sezone_mesec', '');
      props.form.setFieldValue('kraj_sezone_mesec', '');
    }
  }, [obrazac1Store.showIfSezonski]);

  const defaultRezimOptions: ISifarnikInitial | undefined = obrazac1Store.initialState.radni_rezim_id
    ? {
        label: (obrazac1Store.initialState as IGetObrazac1).radni_rezim.name_sr,
        value: obrazac1Store.initialState.radni_rezim_id,
      }
    : undefined;

  return (
    <Col span={14} className={styles.wrapper}>
      <h3>{t('OBRASCI.OBRAZAC_1.DATA_ON_THE_RESUME_OF_WORK_IN_FACILITY')}</h3>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'radni_rezim_id'}
        fetchOptinsList={obrazac1Store.fetchWorkingRegime}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.OBRAZAC_1.WORK_SCHEDULE')}
        inputName={'radni_rezim_id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={true}
        defaultOption={defaultRezimOptions}
        placeholder={''}
        onChange={e => obrazac1Store.changeShowIfSezonski(e)}
      />

      {obrazac1Store.showIfSezonski && (
        <>
          <Form.Item
            name={'pocetak_sezone_mesec'}
            label={t('OBRASCI.OBRAZAC_1.START_OF_SEASON')}
            rules={[REQUIRED_FIELD_RULE(true)]}
          >
            <Select
              style={{ width: 120 }}
              options={MonthsOptions}
              defaultValue={obrazac1Store.initialState.pocetak_sezone_mesec}
            />
          </Form.Item>
          <Form.Item
            name={'kraj_sezone_mesec'}
            label={t('OBRASCI.OBRAZAC_1.END_OF_SEASON')}
            rules={[REQUIRED_FIELD_RULE(true)]}
          >
            <Select
              style={{ width: 120 }}
              options={MonthsOptions}
              defaultValue={obrazac1Store.initialState.kraj_sezone_mesec}
            />
          </Form.Item>
        </>
      )}

      <Form.Item name={'broj_smena_dnevno'} label={t('OBRASCI.OBRAZAC_1.NUMBERS_OF_SHIFTS_PER_DAY')}>
        <Select style={{ width: 120 }} options={brojSmenaDnevnoOptions} />
      </Form.Item>
      <Form.Item name={'broj_radnih_dana_nedeljno'} label={t('OBRASCI.OBRAZAC_1.NUMBER_OF_WORKING_DAYS_PER_WEEK')}>
        <Select style={{ width: 120 }} options={brojRadnihDanaNedeljnoOptions} />
      </Form.Item>

      <FormInputNumber
        inputName={'broj_radnih_dana_godisnje'}
        label={t('OBRASCI.OBRAZAC_1.NUMBER_OF_WORKING_DAYS_PER_YEAR')}
        name={'broj_radnih_dana_godisnje'}
        rules={MAX_AND_MIN_NUMBER(0, 366)}
      />

      <FormInputNumber
        inputName={'broj_radnih_dana_sezonski'}
        label={t('OBRASCI.OBRAZAC_1.NUMBER_OF_WORKING_DAYS_PER_SEASON')}
        name={'broj_radnih_dana_sezonski'}
        rules={MAX_AND_MIN_NUMBER(0, 366)}
      />
      <FormInputNumber
        rules={MAX_AND_MIN_NUMBER(0, 999999, '=')}
        inputName={'broj_operativnih_sati'}
        label={t('OBRASCI.OBRAZAC_1.NUMBER_OF_OPERATING_H0URS')}
        name={'broj_operativnih_sati'}
      />

      <>
        <h3>{t('OBRASCI.OBRAZAC_1.DATA_ON_EMPLOYEES_IN_THE_FACILITY')}</h3>
        {zaposleniUPostrojenju.map((item, index) => {
          return (
            <div key={index}>
              <FormInputNumber inputName={item.name} label={item.label} rules={MORE_THEN_0} name={item.name} />
            </div>
          );
        })}
      </>

      <h3>{t('OBRASCI.OBRAZAC_1.RECAPITULATION_OF_WASTE_PRODUCED_IN_FACILITY')}</h3>
      <FormInputNumber
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
        inputName={'ukupan_broj_vrsta_otpada'}
        label={t('OBRASCI.OBRAZAC_1.TOTAL_NUMBER_OF_TYPES_OF_WASTE')}
        name={'ukupan_broj_vrsta_otpada'}
        precision={0}
      />
    </Col>
  );
});
