import i18n from 'translations/i18n.config';
import {
  ICompanyWithSelfManagmentFilters,
  IGetCompanyWithSelfManagment,
  IPostCompanyWithSelfManagment,
} from './companyWithSelfManagment.types';
import {
  ISifarniciTypeOfForm,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { FormInstance } from 'antd';

export const COMPANY_WITH_SELF_MANAGEMENT_FILTERS_INITIAL_STATE: ICompanyWithSelfManagmentFilters = {
  is_active: null,
  company_id: '',
};
export const COMPANY_WITH_SELF_MANAGEMENT_INITIAL_STATE: IPostCompanyWithSelfManagment = {
  company_id: '',
  is_active: true,
};

export const COMPANY_WITH_SELF_MANAGEMENT_TABLE_DATA = [
  {
    title: `${i18n.t('COMPANY.TITLE')}`,
    width: '700px',
    dataIndex: ['preduzece', 'naziv'],
    key: 'naziv',
  },
];
export const COMPANY_WITH_SELF_MANAGEMENT_FORM_OPTIONS = (
  _: FormInstance<IGetCompanyWithSelfManagment>
): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'searchScroll',
      label: i18n.t('COMPANY.TITLE'),
      formName: 'company_id',
      // placeholder={i18n.t('COMPANY.TITLE')}
      fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
      filtersForFetch: {},
      labelOptionAccessor: ['naziv'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      objName: 'preduzece',
    } as SearchScrollSifarniciFormProps,
  ];
};
