import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import { RegistriLists } from 'modules/registri/registri.types';
import { SifarniciLists } from 'modules/sifarnici/sifarnici.types';
import { LCPRegistarInstalacioniDeoForm } from 'pages/Registri/components/LCPRegistar/LCPRegistarForm/components/LCPRegistarInstalacioniDeoForm/LCPRegistarInstalacioniDeoForm';
import { LCPRegistarIzvorZagadjenjaForm } from 'pages/Registri/components/LCPRegistar/LCPRegistarForm/components/LCPRegistarIzvorZagadjenjaForm/LCPRegistarIzvorZagadjenjaForm';
import { OTVRegistarZagadjenjaForm } from 'pages/Registri/components/OTVRegistar/OTVRegistarForm/OTVRegistarZagadjenjaForm/OTVRegistarZagadjenjaForm';
import { AprAddressDataFormModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciTableFormModal/AprAddressDataFormModal/AprAddressDataFormModal';
import { AprAssigneeFounderFormModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciTableFormModal/AprAssigneeFounderFormModal/AprAssigneeFounderFormModal';
import { ProductCategoriesFormModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciTableFormModal/ProductCategoriesFormModal/ProductCategoriesFormModal';
import { ProductsTypeFormModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciTableFormModal/ProductsTypeFormModal/ProductsTypeFormModal';
import { PzvProductsFormModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciTableFormModal/PzvProductsFormModal/PzvProductsFormModal';
import { WeeeProductsCategoyFormModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciTableFormModal/WeeeProductsCategoyFormModal/WeeeProductsCategoyFormModal.1';
import { useMemo } from 'react';
import { FormListConfig, FormListType } from 'typescript/NrizTypes';

interface ModalFormComponentProps<T> {
  configPath: FormListConfig;
  parentForm: FormInstance<T>;
  formListName: string;
  selectedIndex: number;
}

export const ModalFormComponent = observer(
  // eslint-disable-next-line
  <T extends object>({ configPath, parentForm, formListName, selectedIndex }: ModalFormComponentProps<T>) => {
    const { formListType, formListSubType } = configPath;

    if (!formListType || !formListSubType) return null;

    const FORM_TYPE = {
      [FormListType.Registri]: {
        [RegistriLists.OTV]: (
          <OTVRegistarZagadjenjaForm<T>
            parentForm={parentForm}
            formListName={formListName}
            selectedIndex={selectedIndex}
          />
        ),
        [RegistriLists.LCP]: (
          <LCPRegistarInstalacioniDeoForm<T>
            parentForm={parentForm}
            formListName={formListName}
            selectedIndex={selectedIndex}
          />
        ),
        [RegistriLists.LCP_INSTALACIONI_DEO]: (
          <LCPRegistarIzvorZagadjenjaForm<T>
            parentForm={parentForm}
            formListName={formListName}
            selectedIndex={selectedIndex}
          />
        ),
      },
      [FormListType.Sifarnici]: {
        [SifarniciLists.PRODUCTS_NR]: <ProductsTypeFormModal parentForm={parentForm} formListName={formListName} />,
        [SifarniciLists.PRODUCT_CATEGORIES]: (
          <ProductCategoriesFormModal parentForm={parentForm} formListName={formListName} />
        ),
        [SifarniciLists.WEEE_PRODUCTS_CATEGORY]: (
          <WeeeProductsCategoyFormModal
            parentForm={parentForm}
            formListName={formListName}
            selectedIndex={selectedIndex}
          />
        ),
        [SifarniciLists.PZV_PRODUCTS]: (
          <PzvProductsFormModal parentForm={parentForm} formListName={formListName} selectedIndex={selectedIndex} />
        ),
        [SifarniciLists.APR_ADDRESS_DATA]: (
          <AprAddressDataFormModal parentForm={parentForm} formListName={formListName} />
        ),
        [SifarniciLists.APR_ASSIGNEE_FOUNDE]: (
          <AprAssigneeFounderFormModal parentForm={parentForm} formListName={formListName} />
        ),
      },
    } as any;

    const component = useMemo(() => {
      return FORM_TYPE[formListType][formListSubType];
    }, [configPath]);

    return component ? <div>{component}</div> : null;
  }
);
