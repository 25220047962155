import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import i18n from 'translations/i18n.config';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { IPostWaterCourse, IGetWaterCourse, IWaterCourseFilters } from './waterCourse.types';

export const WATER_COURSE_INITIAL_STATE: IPostWaterCourse = {
  is_active: true,
  water_course_name: '',
};
export const WATER_COURSE_FILTERS_INITIAL_STATE: IWaterCourseFilters = {
  water_course_name__ilike: '',
  is_active: null,
};
export const WATER_COURSE_TABLE_DATA: ColumnsType<IGetWaterCourse> = [
  {
    title: i18n.t('SIFARNICI.NAZIV'),
    // align: 'center',
    width: '700px',
    dataIndex: 'water_course_name',
    key: 'water_course_name',
  },
];
export const WATER_COURSE_FORM_OPTIONS = (_: FormInstance<IGetWaterCourse>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      label: i18n.t('SIFARNICI.NAZIV'),
      inputName: 'water_course_name',
      name: 'water_course_name',
      filterName: 'water_course_name__ilike',
    } as InputSifarniciFormProps,
  ];
};
