import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './NewKomponenteObrazac5.module.scss';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';

export const NewKomponenteObrazac5Form: React.FC = observer(() => {
  const { t } = useTranslation();

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <h4 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.KOMPONENTE_KOJE_OTPAD_CINE_OPASNIM')}</h4>

      <FormInput
        name="cas_broj"
        label={t('OBRASCI.OBRAZAC5.CAS_BROJ')}
        placeholder={t('OBRASCI.OBRAZAC5.CAS_BROJ')}
        inputName={'cas_broj'}
        colon={true}
        type="number"
        rules={[MAX_LENGTH_RULE(30)]}
        readOnly={readOnly}
      />
      <FormInput
        name="hemijski_naziv"
        label={t('OBRASCI.OBRAZAC5.HEMIJSKI_NAZIV')}
        type="string"
        placeholder={t('OBRASCI.OBRAZAC5.HEMIJSKI_NAZIV')}
        inputName={'hemijski_naziv'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50)]}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="sadrzaj_opasne_materije"
        label={t('OBRASCI.OBRAZAC5.SADRZAJ_OPASNE_MATERIJE')}
        placeholder={t('OBRASCI.OBRAZAC5.SADRZAJ_OPASNE_MATERIJE')}
        inputName={'sadrzaj_opasne_materije'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />
    </Col>
  );
});
