import { Modal } from 'components/Modal/Modal';
import { NewUserCompanyResponsibilityForm } from 'components/NewForms/NewUserCompanyResponsibilityForm/NewUserCompanyResponsibilityForm';
import { observer } from 'mobx-react-lite';
import { userInitialState } from 'modules/user/user.constants.ts';

import { userStore } from 'modules/user/user.store';

import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const UserCompanyResponsibilityModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="user-responsibility-modal"
      open={modalStore.getCurrentOpenedModal.includes('user-responsibility-modal')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        modalStore.removeAllModals();
        userStore.setUser(userInitialState);
      }}
      title={`${t('USER.TITLE')}: ${userStore.getUser.ime} ${userStore.getUser.prezime}`}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
    >
      <NewUserCompanyResponsibilityForm readOnly={false} showButton={true} showAlert={true} />
    </Modal>
  );
});
