import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FacilityListTable } from './FacilityListTable/FacilityListTable';
import { FacilityFiltersTable } from './FacilityFiltersTable/FacilityFiltersTable';
import { authStore, modalStore } from 'store';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export const CompanyFacilityListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('FACILITY.LIST')}</h3>
      {(authStore.isAdmin || authStore.isCompanyOwner) && (
        <ExcelAndAddButton
          addButtonLabel="Dodaj postrojenje"
          excelFunc={undefined}
          excelName={'postrojenje'}
          addButtonOnChange={
            companyStore.getCompany.is_active && authStore.isAdmin
              ? () => {
                  modalStore.changeModalName('facility-modal');
                }
              : undefined
          }
        />
      )}
    </Row>
  );
});

export const CompanyFacilityList: React.FC = observer(() => {
  const handleFacilitiesEffect = async () => {
    const companyFacilities = await facilityStore.fetchFacilityCompanyList(paginationInitialState);
    if ((authStore.isAdmin || authStore.isCompanyOwner) && companyFacilities && companyFacilities.items.length) {
      companyStore.checkIfFacilitiesHaveResponsiblePersons();
    }
  };

  useEffect(() => {
    handleFacilitiesEffect();
  }, []);

  return (
    <InsideContentWrapper header={<CompanyFacilityListHeader />}>
      {!authStore.isOnlyCompanyUser && <FacilityFiltersTable />}
      <FacilityListTable />
    </InsideContentWrapper>
  );
});
