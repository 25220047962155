import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormInput } from 'components/FormInput/FormInput';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import { LATITUDE_RULE, LONGITUDE_RULE, MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore, formStore } from 'store';
import { IFormProps } from 'typescript/NrizTypes';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { soilStore } from 'modules/sourcesOfPollution/soil/soil.store';

export const NewSoilPollution: React.FC<IFormProps> = observer(props => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [lockKoordinates, setLockKoordinates] = useState<boolean>(
    soilStore.soil?.zakljucane_koordinate ? soilStore.soil?.zakljucane_koordinate : false
  );

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return soilStore.submitSoil(newValues as any);
  };

  useEffect(() => {
    return form.setFieldsValue({ ...soilStore.getSoil });
  }, [soilStore.getSoil]);

  return (
    <Form
      form={form}
      initialValues={soilStore.soil}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      labelAlign="left"
      name={props.formName}
      onFinish={values => {
        soilStore.submitSoil(values);
      }}
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          onFinishFailed();
        }
      }}
    >
      <FormInput
        label={t('POLLUTION.SOIL.LOCATION_NUMBER')}
        name="broj_lokacije"
        inputName={'broj_lokacije'}
        type={'number'}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
        disabled={soilStore.getSoil.broj_lokacije !== '' && !authStore.isAdmin}
      />
      <FormInput
        label={t('POLLUTION.SOIL.LOCATION_NAME')}
        disabled={soilStore.getSoil.naziv_lokacije !== '' && !authStore.isAdmin}
        name="naziv_lokacije"
        inputName={'naziv_lokacije'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
      />
      <FormCheckbox
        disabled={!authStore.isAdmin}
        name="zakljucane_koordinate"
        label={t('POLLUTION.AIR.COORDINATE_CONCLUSION')}
        onCheckBoxChange={(e: any) => setLockKoordinates(e.target.checked)}
      />
      <FormInput
        type="number"
        disabled={lockKoordinates}
        label={t('COMPANY.LONGITUDE')}
        name="koordinate_long"
        inputName={'koordinate_long'}
        rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
      />
      <FormInput
        type="number"
        disabled={lockKoordinates}
        label={t('COMPANY.LATITUDE')}
        name="koordinate_lat"
        inputName={'koordinate_lat'}
        rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
      />
      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>

      <FormTextArea
        name={'napomena'}
        labelCol={10}
        wrapperCol={14}
        rows={2}
        label={t('COMPANY.NOTE')}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
      {(soilStore.getSoil.aktivan && (soilStore.getSoil as IGetSoil).id) || !(soilStore.getSoil as IGetSoil).id ? (
        <CRUDButton showButton={!!(soilStore.soil as IGetSoil).id} form={form} withOutDelete={true} />
      ) : undefined}
    </Form>
  );
});
