import i18n from 'translations/i18n.config';
import { ITableColumns, TIPOVI_OBRAZACA } from './obrasci.types';
import { ColumnsType } from 'antd/es/table';
import { IGetDkoObrazac } from './store/dko/dko.types';
import { Checkbox, Tag } from 'antd';
import dayjs from 'dayjs';
import { IGetGve } from './store/gve/gve.types';
import { IGetObrazac3 } from './store/obrazac3/obrazac3.types';
import { IGetObrazac5 } from './store/obrazac5/obrazac5.types';

const BASE_TABLE_OBRAZAC = [
  {
    title: `${i18n.t('COMPANY.TITLE')}`,
    width: '200px',
    dataIndex: ['preduzece', 'naziv'],
    key: 'naziv',
  },
  {
    title: `${i18n.t('COMPANY.PIB')}`,
    width: '150px',
    dataIndex: ['preduzece', 'pib'],
    key: 'pib',
  },
];

const MATICNI_BROJ_TABLE_OBRAZAC = {
  title: `${i18n.t('COMPANY.ENTERPRISE_ID_NUMBER')}`,
  width: '150px',
  dataIndex: ['preduzece', 'maticni_broj'],
  key: 'maticni_broj',
};

const DATUM_PREDAJE_OTPADA_TABLE_OBRAZAC = {
  title: `${i18n.t('OBRASCI.DKO.DATUM_PREDAJE_OTPADA')}`,
  width: '150px',
  dataIndex: 'datum_predaje_otpada',
  render: (_: any, record: any) => {
    const datum = dayjs(record.datum_predaje_otpada).format('DD-MM-YYYY');
    return <span>{datum}</span>;
  },
  key: 'datum_predaje_otpada',
};

const POSTROJENJE_TABLE_OBRAZAC = [
  {
    title: `${i18n.t('FACILITY.TITLE')}`,
    width: '200px',
    dataIndex: ['postrojenje', 'naziv_postrojenja'],
    key: 'naziv_postrojenja',
  },
  {
    title: `${i18n.t('FACILITY.NACIONAL_ID')}`,
    width: '150px',
    dataIndex: ['postrojenje', 'nacionalni_id'],
    key: 'nacionalni_id',
  },
];

const ISTORIJSKI_ID_TABLE_OBRAZAC = {
  title: `${i18n.t('FACILITY.HISTORICAL_ID')}`,
  width: '150px',
  dataIndex: ['postrojenje', 'istorijski_id'],
  key: 'istorijski_id',
};

const GODINA_TABLE_OBRAZAC = {
  title: `${i18n.t('YEAR')}`,
  width: '100px',
  dataIndex: 'godina',
  key: 'godina',
};

const DATUM_TABLE_OBRAZAC = {
  title: `${i18n.t('OBRASCI.DEO_6.DATUM_IZVESTAJA')}`,
  width: '150px',
  // hotfix
  render: (_: any, record: any) => {
    const datum = dayjs(record.datum).format('DD-MM-YYYY');
    return <span>{datum}</span>;
  },
  key: 'datum',
};

const IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC = [
  {
    title: `${i18n.t('POLLUTION.WATER.SEWER_NAME')}`,
    width: '200px',
    dataIndex: ['izvor_zagadjenja_vode', 'naziv_ispusta'],
    key: 'naziv_ispusta',
  },
  {
    title: `${i18n.t('POLLUTION.WATER.SEWER_NUMBER')}`,
    width: '100px',
    dataIndex: ['izvor_zagadjenja_vode', 'broj_ispusta'],
    key: 'broj_ispusta',
  },
];
const IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC = [
  {
    title: `${i18n.t('POLLUTION.AIR.SOURCE_NAME')}`,
    width: '200px',
    dataIndex: ['izvor_zagadjenja_vazduha', 'naziv_izvora'],
    key: 'naziv_izvora',
  },
  {
    title: `${i18n.t('POLLUTION.AIR.SOURCE_NUMBER')}`,
    width: '100px',
    dataIndex: ['izvor_zagadjenja_vazduha', 'broj_izvora'],
    key: 'broj_izvora',
  },
];

const IZVOR_ZAGADJIVANJA_TLO_TABLE_OBRAZAC = [
  {
    title: `${i18n.t('POLLUTION.SOIL.LOCATION_NAME')}`,
    width: '200px',
    dataIndex: ['izvor_zagadjenja_tla', 'naziv_lokacije'],
    key: 'naziv_lokacije',
  },
  {
    title: `${i18n.t('POLLUTION.SOIL.LOCATION_NUMBER')}`,
    width: '100px',
    dataIndex: ['izvor_zagadjenja_tla', 'broj_lokacije'],
    key: 'broj_lokacije',
  },
];

const INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC = {
  title: `${i18n.t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
  width: '300px',
  key: 'index_number',
  render: (_: any, record: any) => (
    <>{`${record?.indeksni_broj_otpada?.index_number} - ${record?.indeksni_broj_otpada?.waste_sr?.toUpperCase()}`}</>
  ),
};

const NESANITARNA_DEPONIJA_TABLE_OBRAZAC_TABLE_OBRAZAC = {
  title: `${i18n.t('UNSANITARY_LANDFILL.NAME')} nesanitarne deponije`,
  dataIndex: ['nesanitarna_deponija', 'naziv'],
  key: 'naziv',
  width: '200px',
};

const BROJ_DOKUMENTA_TABLE_OBRAZAC = {
  title: i18n.t('BROJ_DOKUMENTA'),
  width: '150px',
  dataIndex: 'broj_dokumenta',
  key: 'broj_dokumenta',
};
// todo: prevod
const DKO_STATUS = {
  title: i18n.t('Status dokumenta'),
  width: '200px',
  dataIndex: 'status',
  key: 'status',
  align: 'center',
  render: (_: any, record: IGetDkoObrazac) => {
    if (record.potvrda_dokumenta) return <Tag color="green">Potvrđen</Tag>;
    if (record.odjava_kretanja) return <Tag color="red">Odjava / korisnik</Tag>;
    if (record.admin_odjava_dokumenta) return <Tag color="gold">Odjava / administrator</Tag>;
    return <Tag color="cyan">Najavljen</Tag>;
  },
};

const GVE_TABLE_OBRAZAC = {
  title: TIPOVI_OBRAZACA.GVE,
  width: '150px',
  dataIndex: 'gve',
  key: 'gve',
  render: (_: any, record: IGetGve) => <Checkbox checked={record.obrazac_gve} disabled={true} />,
  align: 'center',
};
const OBRAZAC_2_TABLE_OBRAZAC = {
  title: `Obrazac 2`,
  width: '150px',
  dataIndex: 'obrazac_2',
  key: 'obrazac_2',
  render: (_: any, record: IGetGve) => <Checkbox checked={record.obrazac_2} disabled={true} />,
  align: 'center',
};
const LRIZ_OBRAZAC_2_TABLE_OBRAZAC = {
  title: `Lriz obrazac 2`,
  width: '150px',
  dataIndex: 'lriz_obrazac_2',
  key: 'lriz_obrazac_2',
  render: (_: any, record: IGetGve) => <Checkbox checked={record.lriz_obrazac_2} disabled={true} />,
  align: 'center',
};

const OTV_TABLE_OBRAZAC = {
  title: TIPOVI_OBRAZACA.OTV,
  width: '150px',
  dataIndex: 'otv',
  key: 'otv',
  render: (_: any, record: IGetObrazac3) => <Checkbox checked={record.otv} disabled={true} />,
  align: 'center',
};
const OBRAZAC_3_TABLE_OBRAZAC = {
  title: `Obrazac 3`,
  width: '150px',
  dataIndex: 'obrazac3',
  key: 'obrazac3',
  render: (_: any, record: IGetObrazac3) => <Checkbox checked={record.obrazac3} disabled={true} />,
  align: 'center',
};
const LRIZ_OBRAZAC_3_TABLE_OBRAZAC = {
  title: `Lriz obrazac 3`,
  width: '150px',
  dataIndex: 'lriz_obrazac3',
  key: 'lriz_obrazac3',
  render: (_: any, record: IGetObrazac3) => <Checkbox checked={record.obrazac3_lriz} disabled={true} />,
  align: 'center',
};
const LRIZ_OBRAZAC_5_TABLE_OBRAZAC = {
  title: `Lriz 5`,
  width: '150px',
  dataIndex: 'lriz_obrazac_5',
  key: 'lriz_obrazac_5',
  render: (_: any, record: IGetObrazac5) => <Checkbox checked={record.lriz_obrazac_5} disabled={true} />,
  align: 'center',
};
const GIO_1_TABLE_OBRAZAC = {
  title: `Gio 1`,
  width: '150px',
  dataIndex: 'obrazac_gio1',
  key: 'obrazac_gio1',
  render: (_: any, record: IGetObrazac5) => <Checkbox checked={record.obrazac_gio1} disabled={true} />,
  align: 'center',
};

export const KREIRAO_IZMENIO_TABLE_OBRAZAC: ColumnsType<any> = [
  {
    title: 'Kreirao',
    width: '150px',
    dataIndex: 'kreirao',
    key: 'kreirao',
    render: (_: any, record: any) => {
      return (
        <>
          {record?.kreirao?.ime} {record?.kreirao?.prezime}
        </>
      );
    },
  },
  {
    title: 'Datum kreiranja',
    width: '150px',
    dataIndex: 'datum_kreiranja',
    key: 'datum_kreiranja',
    render: (_: any, record: any) => {
      if (!record.datum_kreiranja || record.datum_kreiranja === 'Invalid date') return '';
      return <>{dayjs(record.datum_kreiranja).format('DD.MM.YYYY. HH:mm:ss')}</>;
    },
  },
  {
    title: 'Izmenio',
    width: '150px',
    dataIndex: 'izmenio',
    key: 'izmenio',
    render: (_: any, record: any) => {
      return (
        <>
          {record?.izmenio?.ime} {record?.izmenio?.prezime}
        </>
      );
    },
  },
  {
    title: 'Datum izmene',
    width: '150px',
    dataIndex: 'datum_izmene',
    key: 'datum_izmene',
    render: (_: any, record: any) => {
      if (!record.datum_izmene || record.datum_izmene === 'Invalid date') return '';
      return <>{dayjs(record.datum_izmene).format('DD.MM.YYYY. HH:mm:ss')}</>;
    },
  },
];

export const OBRAZAC_TYPE_TABLE_COLUMNS: { [key: string]: ITableColumns[] } = {
  [TIPOVI_OBRAZACA.AAO1]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, MATICNI_BROJ_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.AAO2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, MATICNI_BROJ_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.PZV1]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, MATICNI_BROJ_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.PTP2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, MATICNI_BROJ_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.DEP1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    NESANITARNA_DEPONIJA_TABLE_OBRAZAC_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.DEP2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, MATICNI_BROJ_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.KOM1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.GIO2]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.GIO3]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.GIO4]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.GIO5]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_2]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC,
    GVE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC,
    GVE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.GVE]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC,
    OBRAZAC_2_TABLE_OBRAZAC,
    LRIZ_OBRAZAC_2_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_2_GVE]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC,
    OBRAZAC_2_TABLE_OBRAZAC,
    GVE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC,
    LRIZ_OBRAZAC_2_TABLE_OBRAZAC,
    GVE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_3]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC,
    OTV_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_3_OTV]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC,
    OBRAZAC_3_TABLE_OBRAZAC,
    OTV_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OTV]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC,
    OBRAZAC_3_TABLE_OBRAZAC,
    LRIZ_OBRAZAC_3_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC,
    OTV_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC,
    LRIZ_OBRAZAC_3_TABLE_OBRAZAC,
    OTV_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_4]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_TLO_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ...IZVOR_ZAGADJIVANJA_TLO_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_5]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    ISTORIJSKI_ID_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
    GIO_1_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
    LRIZ_OBRAZAC_5_TABLE_OBRAZAC,
    GIO_1_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.GIO1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
    LRIZ_OBRAZAC_5_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.DEO6_GIO6]: [
    DATUM_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.DKO]: [
    BROJ_DOKUMENTA_TABLE_OBRAZAC,
    DATUM_PREDAJE_OTPADA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    MATICNI_BROJ_TABLE_OBRAZAC,
    ...POSTROJENJE_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
    DKO_STATUS,
  ],
};
