import { ColumnsType } from 'antd/es/table';
import { IGetSourceType, IPostSourceType, ISourceTypeFilters } from './sourceType.types';
import { NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const SOURCE_TYPE_INITIAL_STATE: IPostSourceType = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const SOURCE_TYPE_FILTERS_INITIAL_STATE: ISourceTypeFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};
export const SOURCE_TYPE_TABLE_DATA: ColumnsType<IGetSourceType> = [NAME_TABLE, NAME_SR_TABLE];
export const SOURCE_TYPE_FORM_OPTIONS = (_: FormInstance<IGetSourceType>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(100), NAME_SR_FORM(100)];
};
