import { ColumnsType } from 'antd/es/table';
import {
  IGetTypeMeansTransport,
  IPostTypeMeansTransport,
  ITypeMeansTransportFilters,
} from './typeMeansTransport.types';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const TYPE_MEANS_TRANSPORT_INITIAL_STATE: IPostTypeMeansTransport = {
  the_order: '',
  type_of_means_of_transport: '',
  type_of_means_of_transport_sr: '',
  is_active: true,
};
export const TYPE_MEANS_TRANSPORT_FILTERS_INITIAL_STATE: ITypeMeansTransportFilters = {
  the_order__ilike: '',
  type_of_means_of_transport__ilike: '',
  type_of_means_of_transport_sr__ilike: '',
  is_active: null,
};

export const TYPE_MEANS_TRANSPORT_TABLE_DATA: ColumnsType<IGetTypeMeansTransport> = [
  THE_ORDER_TABLE,
  {
    title: 'Vrsta prevoznog sredstva',
    width: 100,
    dataIndex: 'type_of_means_of_transport',
    key: 'type_of_means_of_transport',
  },
  {
    title: 'Vrsta prevoznog sredstva (srp.)',
    width: 100,
    dataIndex: 'type_of_means_of_transport_sr',
    key: 'type_of_means_of_transport_sr',
  },
];

export const TYPE_MEANS_TRANSPORT_FORM_OPTIONS = (_: FormInstance<IGetTypeMeansTransport>): ISifarniciTypeOfForm[] => {
  return [
    THE_ORDER_FORM,
    {
      type_of_form: 'input',
      name: 'type_of_means_of_transport',
      filterName: 'type_of_means_of_transport__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      label: 'Vrsta prevoznog sredstva',
      inputName: 'type_of_means_of_transport',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      rules: [MAX_LENGTH_RULE(100)],
      name: 'type_of_means_of_transport_sr',
      filterName: 'type_of_means_of_transport_sr__ilike',
      label: 'Vrsta prevoznog sredstva (srp.)',
      inputName: 'type_of_means_of_transport_sr',
    } as InputSifarniciFormProps,
  ];
};
