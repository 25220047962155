import i18n from 'translations/i18n.config';
import { IAirFilters, IPostAir } from './air.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const airInitialState: IPostAir = {
  broj_izvora: '',
  naziv_izvora: '',
  aktivan: false,
  source_type_id: '',
  koordinate_long: null,
  koordinate_lat: null,
  nadmorska_visina: null,
  instalisana_toplotna_snaga_na_ulazu: null,
  visina_izvora: null,
  unutrasnji_precnik_izvora_na_vrhu: null,
  napomena: '',
  postrojenje_id: '',
  zakljucane_koordinate: false,
  obaveza_merenja: 'da',
};

export const AIR_FILTERS_INITIAL_STATE: IAirFilters = {
  obaveza_merenja: '',
};
export const AIR_FILTERS_OPTIONS: ISifarniciTypeOfForm[] = [
  {
    type_of_form: 'select',
    name: 'obaveza_merenja',
    label: i18n.t('POLLUTION.AIR.OBLIGATION_TO_MEASURE'),
    options: [
      { label: i18n.t('YES'), value: 'da' },
      { label: i18n.t('NO'), value: 'ne' },
    ],
  },
];
