import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { nrizService } from 'modules/nriz/nriz.service';
import { facilityStore } from 'modules/facility/facility.store';
import { formStore } from 'store/FormStore';
import { modalStore } from 'store/ModalStore';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetSoil, IPostSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { soilInitialState } from './soil.constants';
import { soilService } from './soil.service';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { ITablePagination } from 'components/TableData/TableData';
import { IEmptyResponse, IPaginatedResponse, IPagination, IPostResponse } from 'typescript/NrizTypes';

class SoilStore {
  constructor() {
    makeAutoObservable(this);
  }
  soil: IPostSoil | IGetSoil = soilInitialState;
  soilList: IGetSoil[] = [];
  soilListPagination: IPagination = paginationInitialState;

  get getSoil(): IGetSoil {
    return this.soil as IGetSoil;
  }

  get getSoilList(): IGetSoil[] {
    return this.soilList;
  }

  setSoil(soil: IPostSoil | IGetSoil) {
    this.soil = soil;
  }

  handleChange = (type: keyof SoilStore, value: any) => {
    (this as any)[type] = value;
  };

  setSoilList = (soil: IGetSoil[]) => {
    this.soilList = [...soil];
  };

  fetchSoilList = async (pagination?: ITablePagination): Promise<IPaginatedResponse<IGetSoil>> => {
    const [err, res] = await to<IPaginatedResponse<IGetSoil>>(
      soilService.fetchSoilList({
        page: pagination?.page || this.soilListPagination.page,
        size: pagination?.size || this.soilListPagination.size,
        postrojenje_id: (facilityStore.facility as IGetFacility).id,
      })
    );

    if (err || !res) return Promise.reject();
    const { items, page, size, total, pages } = res;
    this.setSoilList(items);
    this.handleChange('soilListPagination', { page, size, total, pages });
    return res;
  };

  postSoil = async (soil: IPostSoil) => {
    const newSoil = {
      ...soil,
      aktivan: this.soil.aktivan,
      postrojenje_id: (facilityStore.getFacility as IGetFacility).id,
    };
    const [err, res] = await to<IPostResponse>(soilService.postSoil(nrizService.setEmptyValuesToNull(newSoil)));
    if (err || !res) return Promise.reject();
    const { id } = res;
    const newList = [{ ...newSoil, id, aktivan: true }, ...this.soilList] as IGetSoil[];
    this.setSoilList(newList);
    this.handleChange('soilListPagination', nrizService.increaseTotalPagination(this.soilListPagination));
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  putSoil = async (soil: IGetSoil) => {
    const newSoil = { ...soil, aktivan: this.soil.aktivan };
    const [err, res] = await to<IEmptyResponse>(soilService.putSoil(nrizService.setEmptyValuesToNull(newSoil)));
    if (err || !res) return Promise.reject();
    this.setSoil(soilInitialState);
    handleSuccessNotify();
    this.setSoilList(nrizService.changeItemInList(this.soilList, newSoil));
    modalStore.removeAllModals();
  };

  submitSoil = (soil: IPostSoil) => {
    if (formStore.getCrudButtonType === 'submit') {
      return this.postSoil(soil);
    }
    const newObject = { ...this.soil, ...soil } as IGetSoil;
    if (formStore.getCrudButtonType === 'change') {
      return this.putSoil(newObject);
    }
  };

  toggleActivateSoil = async (isActive: boolean) => {
    const [err, res] = await to<IEmptyResponse>(soilService.toggleActivateSoil((this.soil as IGetSoil).id, isActive));
    if (err || !res) return Promise.reject();
    const newObj = { ...this.soil, aktivan: isActive };
    this.setSoil(newObj);
    nrizService.changeItemInList(this.soilList, newObj);
    return handleSuccessNotify();
  };
}

export const soilStore = new SoilStore();
