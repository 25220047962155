import { ApiResponse, axios } from 'modules/axios';
import { REGISTRI_CONFIG } from '../registri.constants';
import { RegistriEnum } from '../registri.types';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { ILCPRegistar, ILCPRegistarListResponse, LCPRequest } from './lcp.types';

const fetchLcpInstalacijeList = (filters: any): IPaginatedApiResponse<ILCPRegistarListResponse> => {
  const query = queryString.stringify(filters);
  return axios.get(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}?${query}`);
};

const fetchLcpInstalacija = (id: string): ApiResponse<ILCPRegistar> => {
  return axios.get(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}${id}`);
};

const postLcpInstalacija = (payload: LCPRequest): ApiResponse<string> => {
  return axios.post(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}`, payload);
};

const putLcpInstalacija = (payload: ILCPRegistar, id: string): ApiResponse<string> => {
  return axios.put(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}${id}`, payload);
};

const deleteLcpInstalacija = (id: string): ApiResponse<string> => {
  return axios.delete(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}${id}`);
};

const fetchSledeciIdInstalacije = (): ApiResponse<string> => {
  return axios.get(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}sledeci_id_instalacije`);
};

const fetchSledeciIdInstalacionogDela = (): ApiResponse<string> => {
  return axios.get(`${REGISTRI_CONFIG[RegistriEnum.Lcp].routes.apiRoute}sledeci_id_instalacionog_dela`);
};

export const lcpRepo = {
  fetchLcpInstalacijeList,
  fetchLcpInstalacija,
  postLcpInstalacija,
  putLcpInstalacija,
  deleteLcpInstalacija,
  fetchSledeciIdInstalacije,
  fetchSledeciIdInstalacionogDela,
};
