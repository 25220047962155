import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { IGetGoriva } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewGorivaForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultJedinicaMereOption: ISifarnikInitial | undefined = obrazac1Store.getGorivoForm.naziv_goriva_id
    ? {
        label: `${(obrazac1Store.getGorivoForm as IGetGoriva).naziv_goriva.name_sr} (${
          (obrazac1Store.getGorivoForm as IGetGoriva).naziv_goriva.unit.code
        })`,
        value: obrazac1Store.getGorivoForm.naziv_goriva_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_1.FUEL_NAME')}`}
        labelCol={{ span: 10 }}
        formName={'naziv_goriva_id'}
        inputName={'naziv_goriva_id'}
        labelOptionAccessor={'display_fuel_sr'}
        valueOptionAccessor={'id'}
        fetchOptinsList={obrazac1Store.fetchFuelType}
        placeholder={''}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                obrazac1Store.goriva_form.naziv_goriva_id !== value &&
                obrazac1Store.initialState.goriva.some(item => {
                  return value === item.naziv_goriva_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.OBRAZAC_1.GORIVO_VEC_POSTOJI')));
              }
              return Promise.resolve();
            },
          },
        ]}
        defaultOption={defaultJedinicaMereOption}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.CONSUMPTION_PER_DAY')}`}
        labelCol={{ span: 10 }}
        name={'potrosnja_na_dan'}
        inputName={'potrosnja_na_dan'}
        placeholder=""
        rules={MORE_THEN_0_IS_REQUIRED}
        readOnly={readOnly}
      />
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.METHOD_OF_STORAGE')}`}
        labelCol={{ span: 10 }}
        name={'nacin_lagerovanja'}
        inputName={'nacin_lagerovanja'}
        placeholder=""
        readOnly={readOnly}
        rules={[MAX_LENGTH_RULE(320)]}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.MAXIMUM_STORAGE_CAPACITY')}`}
        labelCol={{ span: 10 }}
        name={'maksimalni_kapacitet_lagera'}
        inputName={'maksimalni_kapacitet_lagera'}
        placeholder=""
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.AVERAGE_AMOUNT_OF_STORAGE')}`}
        labelCol={{ span: 10 }}
        name={'prosecna_kolicina_na_lageru'}
        inputName={'prosecna_kolicina_na_lageru'}
        placeholder=""
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
    </>
  );
});
