import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UserInfo } from './components/UserInfo/UserInfo';
import { Content } from 'components/Content/Content';
import { Col, Row } from 'antd';
import { userInitialState } from 'modules/user/user.constants.ts';
import { userStore } from 'modules/user/user.store';
import { useTranslation } from 'react-i18next';
import { UserCompanyResponsibility } from './components/UserCompanyResponsibility/UserCompanyResponsibility';
import { UserCompanyInfo } from './components/UserCompanyInfo/UserCompanyInfo';
import { companyStore } from 'modules/company/company.store';
import { IGetCompany } from 'modules/company/company.types';
import { RoutePathEnum } from 'router/router.constants';

export const UserHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'}>
      <h1>{t('USER.TITLE')}</h1>
    </Row>
  );
});

// ///////////////////////

const User: React.FC = observer(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (id) userStore.fetchUser(id);

    return () => {
      userStore.setUser(userInitialState);
    };
  }, []);

  return (
    <Content
      header={<UserHeader />}
      backBtn={
        pathname.includes(RoutePathEnum.KORISNIK)
          ? { name: 'korisnik', label: t('USER.LIST'), route: RoutePathEnum.LISTA_KORISNIKA }
          : undefined
      }
    >
      <Row>
        <Col span={12}>
          <UserInfo />
        </Col>
        {!userStore.isUserAdmin && (companyStore.getCompany as IGetCompany)?.id && (
          <Col span={12}>
            <UserCompanyInfo />
            <UserCompanyResponsibility />
          </Col>
        )}
      </Row>
    </Content>
  );
});

export default User;
