import { ColumnsType } from 'antd/es/table';
import { IGetUnitCode, IPostUnitCode, IUnitCodeFilters } from './unitCode.types';
import {
  CODE_TABLE,
  DESCRIPTION_TABLE,
  NAME_SR_TABLE,
  NAME_TABLE,
  TITLE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';

import { FormInstance } from 'antd';

import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const UNIT_CODE_INITIAL_STATE: IPostUnitCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  prtr_code: '',
  is_active: true,
};
export const UNIT_CODE_FILTERS_INITIAL_STATE: IUnitCodeFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  prtr_code__ilike: '',
  is_active: null,
};
export const UNIT_CODE_TABLE_DATA: ColumnsType<IGetUnitCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  {
    title: 'PRTR kod',
    width: 100,
    dataIndex: 'prtr_code',
    key: 'prtr_code',
  },
];
export const UNIT_CODE_FORM_OPTIONS = (form: FormInstance<IGetUnitCode>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(200),
    NAME_SR_FORM(200),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM(100),
    DESCRIPTION_FORM(1000),
    { ...VERSION_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(30)] },
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(100)] },
    {
      type_of_form: 'input',
      name: 'prtr_code',
      filterName: 'prtr_code__ilike',
      rules: [MAX_LENGTH_RULE(20)],
      // todo: prevod
      label: 'PRTR kod',
      inputName: 'prtr_code',
    } as InputSifarniciFormProps,
  ];
};
