import { ISifarniciConfig, ISifarnikInitial, SIFARNICI_ROUTES } from './sifarnici.types';
import { ITableColumns } from 'modules/obrasci/obrasci.types';
import {
  APR_FILTERS_INITIAL_STATE,
  APR_FORM_OPTIONS,
  APR_INITIAL_STATE,
  APR_TABLE_DATA,
} from './store/apr/apr.constants';

import {
  LABORATORIES_AIR_WATER_FILTERS_INITIAL_STATE,
  LABORATORIES_AIR_WATER_FORM_OPTIONS,
  LABORATORIES_AIR_WATER_INITIAL_STATE,
  LABORATORIES_AIR_WATER_TABLE_DATA,
} from './store/laboratoriesAirWater/laboratoriesAirWater.constants';
import {
  PTP2_REPORT_CUSTOMS_FILTERS_INITIAL_STATE,
  PTP2_REPORT_CUSTOMS_FORM_OPTIONS,
  PTP2_REPORT_CUSTOMS_INITIAL_STATE,
  PTP2_REPORT_CUSTOMS_TABLE_DATA,
} from './store/ptp2ReportCustoms/ptp2ReportCustoms.constants.tsx';
import {
  PZV_CUSOTMS_AND_PRODUCERS_FILTERS_INITIAL_STATE,
  PZV_CUSOTMS_AND_PRODUCERS_INITIAL_STATE,
  PZV_CUSTOMS_AND_PRODUCERS_FORM_OPTIONS,
  PZV_CUSTOMS_AND_PRODUCERS_TABLE_DATA,
} from './store/pzvCustomsAndProducers/pzvCustomsAndProducers.constants.tsx';
import i18n from 'translations/i18n.config';

import {
  PRODUCTS_PZV_FILTERS_INITIAL_STATE,
  PRODUCTS_PZV_FORM_OPTIONS,
  PRODUCTS_PZV_INITIAL_STATE,
  PRODUCTS_PZV_TABLE_DATA,
} from './store/productsPzv/productsPzv.constants.tsx';
import {
  PRODUCTS_NR_FILTERS_INITIAL_STATE,
  PRODUCTS_NR_FORM_OPTIONS,
  PRODUCTS_NR_INITIAL_STATE,
  PRODUCTS_NR_TABLE_DATA,
} from './store/productsNr/productsNr.constants.tsx';
import {
  OPERATORS_PACKAGE_WASTE_MANAGE_FILTERS_INITIAL_STATE,
  OPERATORS_PACKAGE_WASTE_MANAGE_FORM_OPTIONS,
  OPERATORS_PACKAGE_WASTE_MANAGE_INITIAL_STATE,
  OPERATORS_PACKAGE_WASTE_MANAGE_TABLE_DATA,
} from './store/operatorsPackageWasteManage/operatorsPackageWasteManage.constants.tsx';
import {
  COMPANY_WITH_SELF_MANAGEMENT_FILTERS_INITIAL_STATE,
  COMPANY_WITH_SELF_MANAGEMENT_FORM_OPTIONS,
  COMPANY_WITH_SELF_MANAGEMENT_INITIAL_STATE,
  COMPANY_WITH_SELF_MANAGEMENT_TABLE_DATA,
} from './store/companyWithSelfManagment/companyWithSelfManagment.constants.tsx';
import {
  AKTIVNOST_1_FILTERS_INITIAL_STATE,
  AKTIVNOST_1_FORM_OPTIONS,
  AKTIVNOST_1_INITIAL_STATE,
  AKTIVNOST_1_TABLE_DATA,
} from './store/aktivnost1/aktivnost1.constants.tsx';
import {
  METHOD_BASIS_CODE_FILTERS_INITIAL_STATE,
  METHOD_BASIS_CODE_FORM_OPTIONS,
  METHOD_BASIS_CODE_INITIAL_STATE,
  METHOD_BASIS_CODE_TABLE_DATA,
} from './store/methodBasisCode/methodBasisCode.constants.tsx';
import {
  PRIVREDNA_DELATNOST_1_FILTERS_INITIAL_STATE,
  PRIVREDNA_DELATNOST_1_FORM_OPTIONS,
  PRIVREDNA_DELATNOST_1_INITIAL_STATE,
  PRIVREDNA_DELATNOST_1_TABLE_DATA,
} from './store/privrednaDelatnost1/privrednaDelatnost1.constants.tsx';
import {
  WATER_BODIES_FILTERS_INITIAL_STATE,
  WATER_BODIES_FORM_OPTIONS,
  WATER_BODIES_INITIAL_STATE,
  WATER_BODIES_TABLE_DATA,
} from './store/waterBodies/waterBodies.constants.tsx';
import {
  AKTIVNOST_2_FILTERS_INITIAL_STATE,
  AKTIVNOST_2_FORM_OPTIONS,
  AKTIVNOST_2_INITIAL_STATE,
  AKTIVNOST_2_TABLE_DATA,
} from './store/aktivnost2/aktivnost2.constants.ts';

import {
  COUNTRY_CODE_FILTERS_INITIL_STATE,
  COUNTRY_CODE_FORM_OPTIONS,
  COUNTRY_CODE_INITIL_STATE,
  COUNTRY_CODE_TABLE_DATA,
} from './store/countryCode/countryCode.constants.tsx';
import {
  MEDIUM_CODE_FILTERS_INITIAL_STATE,
  MEDIUM_CODE_FORM_OPTIONS,
  MEDIUM_CODE_INITIAL_STATE,
  MEDIUM_CODE_TABLE_DATA,
} from './store/mediumCode/mediumCode.constants.tsx';
import {
  METHOD_TYPE_CODE_FILTERS_INITIAL_STATE,
  METHOD_TYPE_CODE_FORM_OPTIONS,
  METHOD_TYPE_CODE_INITIAL_STATE,
  METHOD_TYPE_CODE_TABLE_DATA,
} from './store/methodTypeCode/methodTypeCode.constants.tsx';
import {
  METHOD_TYPES_FILTERS_INITIAL_STATE,
  METHOD_TYPES_FORM_OPTIONS,
  METHOD_TYPES_INITIAL_STATE,
  METHOD_TYPES_TABLE_DATA,
} from './store/methodTypes/methodTypes.constants.tsx';
import {
  METHOD_DESIGNATION_INITIAL_STATE,
  METHOD_DESIGNATION_FORM_OPTIONS,
  METHOD_DESIGNATION_TABLE_DATA,
  METHOD_DESIGNATION_FILTERS_INITIAL_STATE,
} from './store/methodDesignation/methodDesignation.constants.tsx';
import {
  NACE_ACTIVITY_CODE_INITIAL_STATE,
  NACE_ACTIVITY_CODE_FORM_OPTIONS,
  NACE_ACTIVITY_CODE_TABLE_DATA,
  NACE_ACTIVITY_CODE_FILTERS_INITIAL_STATE,
} from './store/naceActivityCode/naceActivityCode.constants.tsx';
import {
  NUTS_REGION_CODE_INITIAL_STATE,
  NUTS_REGION_CODE_FORM_OPTIONS,
  NUTS_REGION_CODE_TABLE_DATA,
  NUTS_REGION_CODE_FILTERS_INITIAL_STATE,
} from './store/nutsRegionCode/nutsRegionCode.constants.tsx';
import {
  RIVER_BASIN_DISTRICT_CODE_INITIAL_STATE,
  RIVER_BASIN_DISTRICT_CODE_FORM_OPTIONS,
  RIVER_BASIN_DISTRICT_CODE_TABLE_DATA,
  RIVER_BASIN_DISTRICT_CODE_FILTERS_INITIAL_STATE,
} from './store/riverBasinDistrictCode/riverBasinDistrictCode.constants.tsx';
import {
  WASTE_TREATMENT_CODE_FILTERS_INITIAL_STATE,
  WASTE_TREATMENT_CODE_FORM_OPTIONS,
  WASTE_TREATMENT_CODE_INITIAL_STATE,
  WASTE_TREATMENT_CODE_TABLE_DATA,
} from './store/wasteTreatmentCode/wasteTreatmentCode.constants.tsx';
import {
  WASTE_TYPE_CODE_FILTERS_INITIAL_STATE,
  WASTE_TYPE_CODE_FORM_OPTIONS,
  WASTE_TYPE_CODE_INITIAL_STATE,
  WASTE_TYPE_CODE_TABLE_DATA,
} from './store/wasteTypeCode/wasteTypeCode.constants.tsx';
import {
  UNIT_CODE_INITIAL_STATE,
  UNIT_CODE_FORM_OPTIONS,
  UNIT_CODE_TABLE_DATA,
  UNIT_CODE_FILTERS_INITIAL_STATE,
} from './store/unitCode/unitCode.constants.tsx';
import {
  POLLUTANT_CODE_INITIAL_STATE,
  POLLUTANT_CODE_FORM_OPTIONS,
  POLLUTANT_CODE_TABLE_DATA,
  POLLUTANT_CODE_FILTERS_INITIAL_STATE,
} from './store/pollutantCode/pollutantCode.constants.tsx';
import {
  POLLUTANT_GROUP_FILTERS_INITIAL_STATE,
  POLLUTANT_GROUP_FORM_OPTIONS,
  POLLUTANT_GROUP_INITIAL_STATE,
  POLLUTANT_GROUP_TABLE_DATA,
} from './store/pollutantGroup/pollutantGroup.constants.tsx';
import {
  POLLUTANT_THRESHOLD_FILTERS_INITIAL_STATE,
  POLLUTANT_THRESHOLD_FORM_OPTIONS,
  POLLUTANT_THRESHOLD_INITIAL_STATE,
  POLLUTANT_THRESHOLD_TABLE_DATA,
} from './store/pollutantThreshold/pollutantThreshold.constants.tsx';
import {
  COORDINATE_SYSTEM_INITIAL_STATE,
  COORDINATE_SYSTEM_FORM_OPTIONS,
  COORDINATE_SYSTEM_TABLE_DATA,
  COORDINATE_SYSTEM_FILTERS_INITIAL_STATE,
} from './store/coordinateSystem/coordinateSystem.constants.tsx';
import {
  COMPETENT_AUTHORITY_PARTY_INITIAL_STATE,
  COMPETENT_AUTHORITY_PARTY_FORM_OPTIONS,
  COMPETENT_AUTHORITY_PARTY_TABLE_DATA,
  COMPETENT_AUTHORITY_PARTY_FILTERS_INITIAL_STATE,
} from './store/competentAuthorityParty/competentAuthorityParty.constants.tsx';
import {
  CONFIDENTIALITY_INITIAL_STATE,
  CONFIDENTIALITY_FORM_OPTIONS,
  CONFIDENTIALITY_TABLE_DATA,
  CONFIDENTIALITY_FILTERS_INITIAL_STATE,
} from './store/confidentiality/confidentiality.constants.tsx';
import {
  SOURCE_TYPE_INITIAL_STATE,
  SOURCE_TYPE_FORM_OPTIONS,
  SOURCE_TYPE_TABLE_DATA,
  SOURCE_TYPE_FILTERS_INITIAL_STATE,
} from './store/sourceType/sourceType.constants.tsx';
import {
  WORKING_REGIME_FILTERS_INITIAL_STATE,
  WORKING_REGIME_FORM_OPTIONS,
  WORKING_REGIME_INITIAL_STATE,
  WORKING_REGIME_TABLE_DATA,
} from './store/workingRegime/workingRegime.constants.tsx';
import {
  FUEL_TYPE_INITIAL_STATE,
  FUEL_TYPE_FORM_OPTIONS,
  FUEL_TYPE_TABLE_DATA,
  FUEL_TYPE_FILTERS_INITIAL_STATE,
} from './store/fuelType/fuelType.constants.tsx';
import {
  WASTE_WATER_TREATMENT_METHOD_FILTERS_INITIAL_STATE,
  WASTE_WATER_TREATMENT_METHOD_FORM_OPTIONS,
  WASTE_WATER_TREATMENT_METHOD_INITIAL_STATE,
  WASTE_WATER_TREATMENT_METHOD_TABLE_DATA,
} from './store/wasteWaterTreatmentMethod/wasteWaterTreatmentMethod.constants.tsx';
import {
  WASTE_WATER_TREATMENT_TYPE_INITIAL_STATE,
  WASTE_WATER_TREATMENT_TYPE_FORM_OPTIONS,
  WASTE_WATER_TREATMENT_TYPE_TABLE_DATA,
  WASTE_WATER_TREATMENT_TYPE_FILTERS_INITIAL_STATE,
} from './store/wasteWaterTreatmentType/wasteWaterTreatmentType.constants.tsx';
import {
  RECIPIENT_TYPE_INITIAL_STATE,
  RECIPIENT_TYPE_FORM_OPTIONS,
  RECIPIENT_TYPE_TABLE_DATA,
  RECIPIENT_TYPE_FILTERS_INITIAL_STATE,
} from './store/recipientType/recipientType.constants.tsx';
import {
  SLIV_FILTERS_INITIAL_STATE,
  SLIV_FORM_OPTIONS,
  SLIV_INITIAL_STATE,
  SLIV_TABLE_DATA,
} from './store/sliv/sliv.constants.tsx';
import {
  WATER_COURSE_INITIAL_STATE,
  WATER_COURSE_FORM_OPTIONS,
  WATER_COURSE_TABLE_DATA,
  WATER_COURSE_FILTERS_INITIAL_STATE,
} from './store/waterCourse/waterCourse.constants.tsx';
import {
  WATER_BODY_CATEGORY_INITIAL_STATE,
  WATER_BODY_CATEGORY_FORM_OPTIONS,
  WATER_BODY_CATEGORY_TABLE_DATA,
  WATER_BODY_CATEGORY_FILTERS_INITIAL_STATE,
} from './store/waterBodyCategory/waterBodyCategory.constants.tsx';
import {
  WATER_AREA_INITIAL_STATE,
  WATER_AREA_FORM_OPTIONS,
  WATER_AREA_TABLE_DATA,
  WATER_AREA_FILTERS_INITIAL_STATE,
} from './store/waterArea/waterArea.constants.tsx';

import {
  WASTE_CATALOG_GROUP_INITIAL_STATE,
  WASTE_CATALOG_GROUP_FORM_OPTIONS,
  WASTE_CATALOG_GROUP_TABLE_DATA,
  WASTE_CATALOG_GROUP_FILTERS_INITIAL_STATE,
} from './store/wasteCatalogGroup/wasteCatalogGroup.constants.tsx';
import {
  WASTE_CATALOG_SUBGROUP_TABLE_DATA,
  WASTE_CATALOG_SUBGROUP_FORM_OPTIONS,
  WASTE_CATALOG_SUBGROUP_INITIAL_STATE,
  WASTE_CATALOG_SUBGROUP_FILTERS_INITIAL_STATE,
} from './store/wasteCatalogSubgroup/wasteCatalogSubgroup.constants.tsx';
import {
  Y_LIST_FILTERS_INITIAL_STATE,
  Y_LIST_FORM_OPTIONS,
  Y_LIST_INITIAL_STATE,
  Y_LIST_TABLE_DATA,
} from './store/yList/yList.constants.tsx';
import {
  Q_LIST_INITIAL_STATE,
  Q_LIST_FORM_OPTIONS,
  Q_LIST_TABLE_DATA,
  Q_LIST_FILTERS_INITIAL_STATE,
} from './store/qList/qList.constants.tsx';
import {
  N_LIST_FILTERS_INITIAL_STATE,
  N_LIST_FORM_OPTIONS,
  N_LIST_INITIAL_STATE,
  N_LIST_TABLE_DATA,
} from './store/nList/nList.constants.tsx';
import {
  C_LIST_FILTERS_INITIAL_STATE,
  C_LIST_FORM_OPTIONS,
  C_LIST_INITIAL_STATE,
  C_LIST_TABLE_DATA,
} from './store/cList/cList.constants.tsx';
import {
  DR_LIST_INITIAL_STATE,
  DR_LIST_FORM_OPTIONS,
  DR_LIST_TABLE_DATA,
  DR_LIST_FILTERS_INITIAL_STATE,
} from './store/dRList/dRList.constants.tsx';
import {
  TYPE_OF_WASTE_INITIAL_STATE,
  TYPE_OF_WASTE_FORM_OPTIONS,
  TYPE_OF_WASTE_TABLE_DATA,
  TYPE_OF_WASTE_FILTERS_INITIAL_STATE,
} from './store/typeOfWaste/typeOfWaste.constants.tsx';
import {
  WASTE_STATE_CATEGORY_INITIAL_STATE,
  WASTE_STATE_CATEGORY_FORM_OPTIONS,
  WASTE_STATE_CATEGORY_TABLE_DATA,
  WASTE_STATE_CATEGORY_FILTERS_INITIAL_STATE,
} from './store/wasteStateCategory/wasteStateCategory.constants.tsx';
import {
  WASTE_CHARACTER_FILTERS_INITIAL_STATE,
  WASTE_CHARACTER_FORM_OPTIONS,
  WASTE_CHARACTER_INITIAL_STATE,
  WASTE_CHARACTER_TABLE_DATA,
} from './store/wasteCharacter/wasteCharacter.constants.tsx';
import {
  LANDFILL_TYPE_FILTERS_INITIAL_STATE,
  LANDFILL_TYPE_FORM_OPTIONS,
  LANDFILL_TYPE_INITIAL_STATE,
  LANDFILL_TYPE_TABLE_DATA,
} from './store/landfillType/landfillType.constants.tsx';
import {
  IA_LIST_INITIAL_STATE,
  IA_LIST_FORM_OPTIONS,
  IA_LIST_TABLE_DATA,
  IA_LIST_FILTERS_INITIAL_STATE,
} from './store/iaList/iaList.constants.tsx';
import {
  IIB_LIST_INITIAL_STATE,
  IIB_LIST_FORM_OPTIONS,
  IIB_LIST_TABLE_DATA,
  IIB_LIST_FILTERS_INITIAL_STATE,
} from './store/iibList/iibList.constants.tsx';
import {
  KOM1_TYPE_OF_WASTE_INITIAL_STATE,
  KOM1_TYPE_OF_WASTE_FORM_OPTIONS,
  KOM1_TYPE_OF_WASTE_TABLE_DATA,
  KOM1_TYPE_OF_WASTE_FILTERS_INITIAL_STATE,
} from './store/kom1TypeOfWaste/kom1TypeOfWaste.constants.tsx';
import {
  COMPOSITION_COMMUNAL_WASTE_FILTERS_INITIAL_STATE,
  COMPOSITION_COMMUNAL_WASTE_FORM_OPTIONS,
  COMPOSITION_COMMUNAL_WASTE_INITIAL_STATE,
  COMPOSITION_COMMUNAL_WASTE_TABLE_DATA,
} from './store/compositionCommunalWaste/compositionCommunalWaste.constants.tsx';
import {
  WAY_OF_HAND_OVER_INITIAL_STATE,
  WAY_OF_HAND_OVER_FORM_OPTIONS,
  WAY_OF_HAND_OVER_TABLE_DATA,
  WAY_OF_HAND_OVER_FILTERS_INITIAL_STATE,
} from './store/wayOfHandOver/wayOfHandOver.constants.tsx';
import {
  ACTIVITIES_OF_WASTE_OPERATORS_FILTERS_INITIAL_STATE,
  ACTIVITIES_OF_WASTE_OPERATORS_FORM_OPTIONS,
  ACTIVITIES_OF_WASTE_OPERATORS_INITIAL_STATE,
  ACTIVITIES_OF_WASTE_OPERATORS_TABLE_DATA,
} from './store/activitiesOfWasteOperators/activitiesOfWasteOperators.constants.tsx';
import {
  TYPE_MEANS_TRANSPORT_INITIAL_STATE,
  TYPE_MEANS_TRANSPORT_FORM_OPTIONS,
  TYPE_MEANS_TRANSPORT_TABLE_DATA,
  TYPE_MEANS_TRANSPORT_FILTERS_INITIAL_STATE,
} from './store/typeMeansTransport/typeMeansTransport.constants.tsx';
import {
  METHOD_OF_PACKAGING_WASTE_INITIAL_STATE,
  METHOD_OF_PACKAGING_WASTE_FORM_OPTIONS,
  METHOD_OF_PACKAGING_WASTE_TABLE_DATA,
  METHOD_OF_PACKAGING_WASTE_FILTERS_INITIAL_STATE,
} from './store/methodOfPackaging/methodOfPackaging.constants.tsx';
import {
  DEP_FORMS_ANSWERS_FILTERS_INITIAL_STATE,
  DEP_FORMS_ANSWERS_FORM_OPTIONS,
  DEP_FORMS_ANSWERS_INITIAL_STATE,
  DEP_FORMS_ANSWERS_TABLE_DATA,
} from './store/depFormsAnswers/depFormsAnswers.constants.tsx';
import {
  WEEE_CATEGORIES_FILTERS_INITIAL_STATE,
  WEEE_CATEGORIES_FORM_OPTIONS,
  WEEE_CATEGORIES_INITIAL_STATE,
  WEEE_CATEGORIES_TABLE_DATA,
} from './store/weeeCategories/weeeCategories.constants.tsx';
import {
  TYPE_OF_PACKING_INITIAL_STATE,
  TYPE_OF_PACKING_FORM_OPTIONS,
  TYPE_OF_PACKING_TABLE_DATA,
  TYPE_OF_PACKING_FILTERS_INITIAL_STATE,
} from './store/typeOfPacking/typeOfPacking.constants.tsx';
import {
  PZV_TYPE_OF_PRODUCTS_INITIAL_STATE,
  PZV_TYPE_OF_PRODUCTS_FORM_OPTIONS,
  PZV_TYPE_OF_PRODUCTS_TABLE_DATA,
  PZV_TYPE_OF_PRODUCTS_FILTERS_INITIAL_STATE,
} from './store/pzvTypeOfProducts/pzvTypeOfProducts.constants.tsx';
import {
  MUNICIPALITY_CODE_FILTERS_INITIAL_STATE,
  MUNICIPALITY_CODE_FORM_OPTIONS,
  MUNICIPALITY_CODE_INITIAL_STATE,
  MUNICIPALITY_CODE_TABLE_DATA,
} from './store/municipalityCode/municipalityCode.constants.tsx';
import {
  NUTS1_CODE_INITIAL_STATE,
  NUTS1_CODE_FORM_OPTIONS,
  NUTS1_CODE_TABLE_DATA,
  NUTS1_CODE_FILTERS_INITIAL_STATE,
} from './store/nuts1Code/nuts1Code.constants.ts';
import {
  NUTS2_REGION_CODE_INITIAL_STATE,
  NUTS2_REGION_CODE_FORM_OPTIONS,
  NUTS2_REGION_CODE_TABLE_DATA,
  NUTS2_REGION_CODE_FILTERS_INITIAL_STATE,
} from './store/nuts2RegionCode/nuts2RegionCode.constants.tsx';
import {
  NUTS3_AREA_CODE_INITIAL_STATE,
  NUTS3_AREA_CODE_FORM_OPTIONS,
  NUTS3_AREA_CODE_TABLE_DATA,
  NUTS3_AREA_CODE_FILTERS_INITIAL_STATE,
} from './store/nuts3AreaCode/nuts3AreaCode.constants.tsx';
import {
  MESTO_INITIAL_STATE,
  MESTO_FORM_OPTIONS,
  MESTO_TABLE_DATA,
  MESTO_FILTERS_INITIAL_STATE,
} from './store/mesto/mesto.constants.tsx';
import {
  PRIVREDNA_DELATNOST_2_INITIAL_STATE,
  PRIVREDNA_DELATNOST_2_FORM_OPTIONS,
  PRIVREDNA_DELATNOST_2_TABLE_DATA,
  PRIVREDNA_DELATNOST_2_FILTERS_INITIAL_STATE,
} from './store/privrednaDelatnost2/privrednaDelatnost2.constants.ts';
import {
  WASTE_CATALOGUE_FILTERS_INITIAL_STATE,
  WASTE_CATALOGUE_FORM_OPTIONS,
  WASTE_CATALOGUE_INITIAL_STATE,
  WASTE_CATALOGUE_TABLE_DATA,
} from './store/wasteCatalogue/wasteCatalogue.constants.tsx';

// todo: za sve pozive sifarnika u obrascima dodati filter order_by: '-is_active'
// ukoliko za neki poziv vec postoji filter order_by, proslediti vise opcija kao array stringova

// RUTE SIFARNIK
export const SIFARNIK_TYPE_API_URL_MAP: { [key: string]: string } = {
  [SIFARNICI_ROUTES.DEP_FORMS_ANSWERS]: 'dep-forms-answers',
  [SIFARNICI_ROUTES.MESTO]: 'mesto',
  [SIFARNICI_ROUTES.PREDUZECE]: 'preduzece',
  [SIFARNICI_ROUTES.PREDUZECE_APR]: 'preduzece/apr',
  [SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE]:
    'preduzece-postrojenje-tip-obrasca-upitnik/postrojenja',
  [SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE]:
    'preduzece-postrojenje-tip-obrasca-upitnik/preduzeca',
  [SIFARNICI_ROUTES.POSTROJENJE]: 'postrojenje',
  [SIFARNICI_ROUTES.KORISNIK]: 'korisnik',
  [SIFARNICI_ROUTES.NESANITARNA_DEPONIJA]: 'nesanitarna-deponija',
  [SIFARNICI_ROUTES.PRODUCTS_PZV]: 'products-pzv',
  [SIFARNICI_ROUTES.KOM1_TYPE_OF_WASTE]: 'kom1-type-of-waste',
  [SIFARNICI_ROUTES.WAY_OF_HAND_OVER]: 'way-of-hand-over',
  [SIFARNICI_ROUTES.MUNICIPALITY_CODE]: 'municipality-code',
  [SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_TLA]: 'izvor-zagadjenja-tla',
  [SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE]: 'izvor-zagadjenja-vode',
  [SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI]: 'sifarnik-pretezne-delatnosti',
  [SIFARNICI_ROUTES.COMPOSITION_COMMUNAL_WASTE]: 'composition-communal-waste',
  [SIFARNICI_ROUTES.PRODUCTS_NR]: 'products-nr',
  [SIFARNICI_ROUTES.PRODUCTS_TYPE]: 'products-type',
  [SIFARNICI_ROUTES.TYPE_OF_WASTE]: 'type-of-waste',
  [SIFARNICI_ROUTES.DR_LIST]: 'dr-list',
  [SIFARNICI_ROUTES.WASTE_CATALOGUE]: 'waste-catalogue',
  [SIFARNICI_ROUTES.POLLUTANT_CODE]: 'pollutant-code',
  [SIFARNICI_ROUTES.METHOD_TYPES]: 'method-types',
  [SIFARNICI_ROUTES.METHOD_BASIS_CODE]: 'method-basis-code',
  [SIFARNICI_ROUTES.UNIT_CODE]: 'unit-code',
  [SIFARNICI_ROUTES.UNIT_CODE_BY_POLLUTANT]: 'unit-code/obrazac/',
  [SIFARNICI_ROUTES.FUEL_TYPE]: 'fuel-type',
  [SIFARNICI_ROUTES.WORKING_REGIME]: 'working-regime',
  [SIFARNICI_ROUTES.TYPE_OF_PACKING]: 'type-of-packing',
  [SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE]: 'operators-package-waste-manage',
  [SIFARNICI_ROUTES.COMPANY_WITH_SELF_MANAGEMENT]: 'company-with-self-management',
  [SIFARNICI_ROUTES.WEEE_PRODUCTS_CATEGORY]: 'weee-products-category',
  [SIFARNICI_ROUTES.WEEE_CATEGORIES]: 'weee-categories',
  [SIFARNICI_ROUTES.PREDUZECE_PIB_MATICNI]: 'preduzece/apr',
  [SIFARNICI_ROUTES.LANDFILL_TYPE]: 'landfill-type',
  [SIFARNICI_ROUTES.Y_LIST]: 'y-list',
  [SIFARNICI_ROUTES.N_LIST]: 'nlist',
  [SIFARNICI_ROUTES.C_LIST]: 'clist',
  [SIFARNICI_ROUTES.IA_LIST]: 'ia-list',
  [SIFARNICI_ROUTES.IIB_LIST]: 'iib-list',
  [SIFARNICI_ROUTES.COUNTRY_CODE]: 'country-code',
  [SIFARNICI_ROUTES.Q_LIST]: 'q-list',
  [SIFARNICI_ROUTES.WASTE_STATE_CATEGORY]: 'waste-state-category',
  [SIFARNICI_ROUTES.TYPE_MEANS_TRANSPORT]: 'type-means-transport',
  [SIFARNICI_ROUTES.METHOD_OF_PACKAGING_WASTE]: 'method-of-packaging-waste',
  [SIFARNICI_ROUTES.LABORATORIES_AIR_WATER]: 'laboratories-air-water',
  [SIFARNICI_ROUTES.APR]: 'apr',
  [SIFARNICI_ROUTES.APR_ASSIGNEE_FOUNDER]: 'apr-assignee-founder',
  [SIFARNICI_ROUTES.APR_ADDRESS_DATA]: 'apr-address-data',
  [SIFARNICI_ROUTES.PRODUCT_CATEGORIES]: 'product-categories',
  [SIFARNICI_ROUTES.PTP2_REPORT_CUSTOMS]: 'ptp2-report-customs',
  [SIFARNICI_ROUTES.PZV_CUSTOMS_AND_PRODUCERS]: 'pzv-customs-and-producers',
  [SIFARNICI_ROUTES.PZV_PRODUCTS]: 'pzv-products',
  [SIFARNICI_ROUTES.APR_COMPANY_STATUS]: 'apr-company-status',
  [SIFARNICI_ROUTES.APR_DATA_SOURCE]: 'apr-data-source',
  [SIFARNICI_ROUTES.APR_LEGAL_FORM]: 'apr-legal-form',
  [SIFARNICI_ROUTES.APR_TYPE_OF_SHAPE_OF_ORGANIZING]: 'apr-type-of-shape-of-organizing',
  [SIFARNICI_ROUTES.APR_SIZE_OF_BUSINESS_ENTITY]: 'apr-size-of-business-entity',
  [SIFARNICI_ROUTES.APR_TYPE_ADDRESS]: 'apr-type-address',
  [SIFARNICI_ROUTES.APR_TYPE_PERSONALITY]: 'apr-type-personality',
  [SIFARNICI_ROUTES.APR_TYPE_PERSONALITY_OF_LEGAL_ENTITY]: 'apr-type-personality',
  [SIFARNICI_ROUTES.APR_TYPE_PERSONALITY_REPRESENTATIV]: 'apr-type-personality',
  [SIFARNICI_ROUTES.APR_TYPE_ASSIGNEE]: 'apr-type-assignee',
  [SIFARNICI_ROUTES.ASSIGNEE_FUNCTION]: 'apr-assignee-function',
  [SIFARNICI_ROUTES.AKTIVNOST_1]: 'aktivnost1',
  [SIFARNICI_ROUTES.AKTIVNOST_2]: 'aktivnost2',
  [SIFARNICI_ROUTES.AKTIVNOST_1_AKTIVNOST_2]: 'aktivnost1-aktivnost2',
  [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1]: 'privredna-delatnost1',
  [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2]: 'privredna-delatnost2',
  [SIFARNICI_ROUTES.SOURCE_TYPE]: 'source-type',
  [SIFARNICI_ROUTES.TYPE_OF_RECIPIENT]: 'recipient-type',
  [SIFARNICI_ROUTES.SLIV]: 'sliv',
  [SIFARNICI_ROUTES.ACTIVITIES_OF_WASTE_OPERATORS]: 'activities-of-waste-operators',
  [SIFARNICI_ROUTES.WASTE_CHARACTER]: 'waste-character',
  [SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA]: 'izvor-zagadjenja-vazduha',
  [SIFARNICI_ROUTES.PZV_TYPE_OF_PRODUCTS]: 'pzv-type-of-products',
  [SIFARNICI_ROUTES.WATER_BODIES]: 'water-bodies',
  [SIFARNICI_ROUTES.WATER_COURSE]: 'water-course',
  [SIFARNICI_ROUTES.WATER_BODY_CATEGORY]: 'water-body-category',
  [SIFARNICI_ROUTES.WATER_AREA]: 'water-area',
  [SIFARNICI_ROUTES.ANNEX_ACTIVITY_CODE]: 'annex-activity-code',
  [SIFARNICI_ROUTES.MEDIUM_CODE]: 'medium-code',
  [SIFARNICI_ROUTES.METHOD_TYPE_CODE]: 'method-type-code',
  [SIFARNICI_ROUTES.METHOD_DESIGNATION]: 'method-designation',
  [SIFARNICI_ROUTES.NACE_ACTIVITY_CODE]: 'nace-activity-code',
  [SIFARNICI_ROUTES.NUTS_REGION_CODE]: 'nuts-region-code',
  [SIFARNICI_ROUTES.RIVER_BASIN_DISTRICT_CODE]: 'river-basin-district-code',
  [SIFARNICI_ROUTES.WASTE_TREATMENT_CODE]: 'waste-treatment-code',
  [SIFARNICI_ROUTES.WASTE_TYPE_CODE]: 'waste-type-code',
  [SIFARNICI_ROUTES.POLLUTANT_GROUP]: 'pollutant-group',
  [SIFARNICI_ROUTES.POLLUTANT_THRESHOLD]: 'pollutant-threshold',
  [SIFARNICI_ROUTES.COORDINATE_SYSTEM]: 'coordinate-system',
  [SIFARNICI_ROUTES.COMPETENT_AUTHORITY_PARTY]: 'competent-authority-party',
  [SIFARNICI_ROUTES.CONFIDENTIALITY]: 'confidentiality',
  [SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_METHOD]: 'waste-water-treatment-method',
  [SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_TYPE]: 'waste-water-treatment-type',
  [SIFARNICI_ROUTES.RECIPIENT_TYPE]: 'recipient-type',
  [SIFARNICI_ROUTES.WASTE_CATALOG_GROUP]: 'waste-catalog-group',
  [SIFARNICI_ROUTES.WASTE_CATALOG_SUBGROUP]: 'waste-catalog-subgroup',
  [SIFARNICI_ROUTES.NUTS1_CODE]: 'nuts1-code',
  [SIFARNICI_ROUTES.NUTS2_REGION_CODE]: 'nuts2-region-code',
  [SIFARNICI_ROUTES.NUTS3_AREA_CODE]: 'nuts3-area-code',
  [SIFARNICI_ROUTES.VELIKI_ZAGADJIVACI]: 'postrojenje/veliki-zagadjivaci',
};

// CRUD MODAL SIFARNIK
export const SIFARNIK_MODAL_DATA: { [key: string]: ISifarniciConfig } = {
  [SIFARNICI_ROUTES.AKTIVNOST_1]: {
    modal: {
      title: `${i18n.t('ACTIVITIES.ACTIVITY')} 1`,
      width: '60%',
    },
    formData: {
      components: AKTIVNOST_1_FORM_OPTIONS,
      initialState: AKTIVNOST_1_INITIAL_STATE,
      filtersInitialState: AKTIVNOST_1_FILTERS_INITIAL_STATE,
    },
  },
  [SIFARNICI_ROUTES.AKTIVNOST_2]: {
    modal: {
      title: `${i18n.t('ACTIVITIES.ACTIVITY')} 2`,
      width: '60%',
    },
    formData: {
      components: AKTIVNOST_2_FORM_OPTIONS,
      initialState: AKTIVNOST_2_INITIAL_STATE,
      filtersInitialState: AKTIVNOST_2_FILTERS_INITIAL_STATE,
    },
  },
  // [SIFARNICI_ROUTES.ANNEX_ACTIVITY_CODE]: {
  //   modal: {
  //     title: `Aneks šifra delatnosti`,
  //     width: '65%',
  //   },
  //   formData: {
  //     components: ANNEX_ACTIVITY_CODE_FORM_OPTIONS,
  //     initialState: ANNEX_ACTIVITY_CODE_INITIAL_STATE,
  //   },
  // },

  [SIFARNICI_ROUTES.APR]: {
    modal: { title: i18n.t('SIFARNICI.APR'), width: '95%' },
    formData: {
      initialState: APR_INITIAL_STATE,
      components: APR_FORM_OPTIONS,
      filtersInitialState: APR_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.C_LIST]: {
    modal: { title: 'C lista', width: '50%' },
    formData: {
      initialState: C_LIST_INITIAL_STATE,
      components: C_LIST_FORM_OPTIONS,
      filtersInitialState: C_LIST_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.ACTIVITIES_OF_WASTE_OPERATORS]: {
    modal: { title: 'Delatnosti operatera otpada', width: '50%' },
    formData: {
      initialState: ACTIVITIES_OF_WASTE_OPERATORS_INITIAL_STATE,
      components: ACTIVITIES_OF_WASTE_OPERATORS_FORM_OPTIONS,
      filtersInitialState: ACTIVITIES_OF_WASTE_OPERATORS_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.DEP_FORMS_ANSWERS]: {
    modal: { title: 'DEP | Odgovori za obrasce', width: '50%' },
    formData: {
      initialState: DEP_FORMS_ANSWERS_INITIAL_STATE,
      components: DEP_FORMS_ANSWERS_FORM_OPTIONS,
      filtersInitialState: DEP_FORMS_ANSWERS_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.DR_LIST]: {
    modal: { title: 'DR lista', width: '50%' },
    formData: {
      initialState: DR_LIST_INITIAL_STATE,
      components: DR_LIST_FORM_OPTIONS,
      filtersInitialState: DR_LIST_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_STATE_CATEGORY]: {
    modal: { title: 'Fizičko stanje otpada', width: '50%' },
    formData: {
      initialState: WASTE_STATE_CATEGORY_INITIAL_STATE,
      components: WASTE_STATE_CATEGORY_FORM_OPTIONS,
      filtersInitialState: WASTE_STATE_CATEGORY_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.POLLUTANT_THRESHOLD]: {
    modal: { title: 'Granične vrednosti zag. materija', width: '50%' },
    formData: {
      initialState: POLLUTANT_THRESHOLD_INITIAL_STATE,
      components: POLLUTANT_THRESHOLD_FORM_OPTIONS,
      filtersInitialState: POLLUTANT_THRESHOLD_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_CATALOG_GROUP]: {
    modal: { title: 'Grupe u katalogu otpada', width: '50%' },
    formData: {
      initialState: WASTE_CATALOG_GROUP_INITIAL_STATE,
      components: WASTE_CATALOG_GROUP_FORM_OPTIONS,
      filtersInitialState: WASTE_CATALOG_GROUP_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.POLLUTANT_GROUP]: {
    modal: { title: 'Grupe zagađujućih materija', width: '50%' },
    formData: {
      initialState: POLLUTANT_GROUP_INITIAL_STATE,
      components: POLLUTANT_GROUP_FORM_OPTIONS,
      filtersInitialState: POLLUTANT_GROUP_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.IA_LIST]: {
    modal: { title: 'IA lista', width: '50%' },
    formData: {
      initialState: IA_LIST_INITIAL_STATE,
      components: IA_LIST_FORM_OPTIONS,
      filtersInitialState: IA_LIST_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.IIB_LIST]: {
    modal: { title: 'IIB lista', width: '50%' },
    formData: {
      initialState: IIB_LIST_INITIAL_STATE,
      components: IIB_LIST_FORM_OPTIONS,
      filtersInitialState: IIB_LIST_FILTERS_INITIAL_STATE,
    },
  },
  [SIFARNICI_ROUTES.WASTE_CATALOGUE]: {
    modal: { title: 'Katalog otpada', width: '50%' },
    formData: {
      initialState: WASTE_CATALOGUE_INITIAL_STATE,
      components: WASTE_CATALOGUE_FORM_OPTIONS,
      filtersInitialState: WASTE_CATALOGUE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.UNIT_CODE]: {
    modal: { title: 'Jedinice', width: '50%' },
    formData: {
      initialState: UNIT_CODE_INITIAL_STATE,
      components: UNIT_CODE_FORM_OPTIONS,
      filtersInitialState: UNIT_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_CHARACTER]: {
    modal: { title: 'Karakter otpada', width: '50%' },
    formData: {
      initialState: WASTE_CHARACTER_INITIAL_STATE,
      components: WASTE_CHARACTER_FORM_OPTIONS,
      filtersInitialState: WASTE_CHARACTER_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WEEE_CATEGORIES]: {
    modal: { title: 'Kategorije EE otpada', width: '50%' },
    formData: {
      initialState: WEEE_CATEGORIES_INITIAL_STATE,
      components: WEEE_CATEGORIES_FORM_OPTIONS,
      filtersInitialState: WEEE_CATEGORIES_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WATER_BODY_CATEGORY]: {
    modal: { title: 'Kategorija vodnog tela', width: '50%' },
    formData: {
      initialState: WATER_BODY_CATEGORY_INITIAL_STATE,
      components: WATER_BODY_CATEGORY_FORM_OPTIONS,
      filtersInitialState: WATER_BODY_CATEGORY_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WAY_OF_HAND_OVER]: {
    modal: { title: 'KOM 1 | Način predaje', width: '50%' },
    formData: {
      initialState: WAY_OF_HAND_OVER_INITIAL_STATE,
      components: WAY_OF_HAND_OVER_FORM_OPTIONS,
      filtersInitialState: WAY_OF_HAND_OVER_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.KOM1_TYPE_OF_WASTE]: {
    modal: { title: 'KOM 1 | Vrste otpada', width: '50%' },
    formData: {
      initialState: KOM1_TYPE_OF_WASTE_INITIAL_STATE,
      components: KOM1_TYPE_OF_WASTE_FORM_OPTIONS,
      filtersInitialState: KOM1_TYPE_OF_WASTE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.COORDINATE_SYSTEM]: {
    modal: { title: 'Koordinatni sistem', width: '50%' },
    formData: {
      initialState: COORDINATE_SYSTEM_INITIAL_STATE,
      components: COORDINATE_SYSTEM_FORM_OPTIONS,
      filtersInitialState: COORDINATE_SYSTEM_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.LABORATORIES_AIR_WATER]: {
    modal: {
      title: i18n.t('SIFARNICI.LABORATORJE_VAZDUH_VODA'),
      width: '50%',
    },
    formData: {
      components: LABORATORIES_AIR_WATER_FORM_OPTIONS,
      initialState: LABORATORIES_AIR_WATER_INITIAL_STATE,
      filtersInitialState: LABORATORIES_AIR_WATER_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.COUNTRY_CODE]: {
    modal: { title: 'Lista država', width: '50%' },
    formData: {
      initialState: COUNTRY_CODE_INITIL_STATE,
      components: COUNTRY_CODE_FORM_OPTIONS,
      filtersInitialState: COUNTRY_CODE_FILTERS_INITIL_STATE,
    },
  },

  [SIFARNICI_ROUTES.SLIV]: {
    modal: { title: 'Lista slivova', width: '50%' },
    formData: {
      initialState: SLIV_INITIAL_STATE,
      components: SLIV_FORM_OPTIONS,
      filtersInitialState: SLIV_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.MEDIUM_CODE]: {
    modal: { title: 'Medijumi', width: '50%' },
    formData: {
      initialState: MEDIUM_CODE_INITIAL_STATE,
      components: MEDIUM_CODE_FORM_OPTIONS,
      filtersInitialState: MEDIUM_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.MESTO]: {
    modal: { title: i18n.t('COMPANY.LOCATION'), width: '50%' },
    formData: {
      initialState: MESTO_INITIAL_STATE,
      components: MESTO_FORM_OPTIONS,
      filtersInitialState: MESTO_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.METHOD_TYPE_CODE]: {
    modal: { title: 'Metode određivanja', width: '50%' },
    formData: {
      initialState: METHOD_TYPE_CODE_INITIAL_STATE,
      components: METHOD_TYPE_CODE_FORM_OPTIONS,
      filtersInitialState: METHOD_TYPE_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.METHOD_TYPES]: {
    modal: { title: 'Metode određivanja po medijumima', width: '50%' },
    formData: {
      initialState: METHOD_TYPES_INITIAL_STATE,
      components: METHOD_TYPES_FORM_OPTIONS,
      filtersInitialState: METHOD_TYPES_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.N_LIST]: {
    modal: { title: 'N lista', width: '50%' },
    formData: {
      initialState: N_LIST_INITIAL_STATE,
      components: N_LIST_FORM_OPTIONS,
      filtersInitialState: N_LIST_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.METHOD_OF_PACKAGING_WASTE]: {
    modal: { title: 'Način pakovanja otpada', width: '50%' },
    formData: {
      initialState: METHOD_OF_PACKAGING_WASTE_INITIAL_STATE,
      components: METHOD_OF_PACKAGING_WASTE_FORM_OPTIONS,
      filtersInitialState: METHOD_OF_PACKAGING_WASTE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.METHOD_BASIS_CODE]: {
    modal: { title: 'Načini određivanja', width: '50%' },
    formData: {
      initialState: METHOD_BASIS_CODE_INITIAL_STATE,
      components: METHOD_BASIS_CODE_FORM_OPTIONS,
      filtersInitialState: METHOD_BASIS_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.NUTS1_CODE]: {
    modal: { title: 'NUTS 1 Šifarnik', width: '50%' },
    formData: {
      initialState: NUTS1_CODE_INITIAL_STATE,
      components: NUTS1_CODE_FORM_OPTIONS,
      filtersInitialState: NUTS1_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.NUTS2_REGION_CODE]: {
    modal: { title: 'NUTS 2 Šifarnik regiona', width: '50%' },
    formData: {
      initialState: NUTS2_REGION_CODE_INITIAL_STATE,
      components: NUTS2_REGION_CODE_FORM_OPTIONS,
      filtersInitialState: NUTS2_REGION_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.NUTS3_AREA_CODE]: {
    modal: { title: 'NUTS 3 Šifarnik oblasti', width: '50%' },
    formData: {
      initialState: NUTS3_AREA_CODE_INITIAL_STATE,
      components: NUTS3_AREA_CODE_FORM_OPTIONS,
      filtersInitialState: NUTS3_AREA_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.NUTS_REGION_CODE]: {
    modal: { title: 'NUTS Šifarnik regiona', width: '50%' },
    formData: {
      initialState: NUTS_REGION_CODE_INITIAL_STATE,
      components: NUTS_REGION_CODE_FORM_OPTIONS,
      filtersInitialState: NUTS_REGION_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE]: {
    modal: { title: i18n.t('SIFARNICI.OPERATERI_ZA_AMBALAZNI_OTPAD'), width: '50%' },
    formData: {
      initialState: OPERATORS_PACKAGE_WASTE_MANAGE_INITIAL_STATE,
      components: OPERATORS_PACKAGE_WASTE_MANAGE_FORM_OPTIONS,
      filtersInitialState: OPERATORS_PACKAGE_WASTE_MANAGE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.METHOD_DESIGNATION]: {
    modal: { title: 'Oznake metode određivanja', width: '50%' },
    formData: {
      initialState: METHOD_DESIGNATION_INITIAL_STATE,
      components: METHOD_DESIGNATION_FORM_OPTIONS,
      filtersInitialState: METHOD_DESIGNATION_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.RIVER_BASIN_DISTRICT_CODE]: {
    modal: { title: 'Oznake slivova', width: '50%' },
    formData: {
      initialState: RIVER_BASIN_DISTRICT_CODE_INITIAL_STATE,
      components: RIVER_BASIN_DISTRICT_CODE_FORM_OPTIONS,
      filtersInitialState: RIVER_BASIN_DISTRICT_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_TREATMENT_CODE]: {
    modal: { title: 'Oznake tretmana otpada', width: '50%' },
    formData: {
      initialState: WASTE_TREATMENT_CODE_INITIAL_STATE,
      components: WASTE_TREATMENT_CODE_FORM_OPTIONS,
      filtersInitialState: WASTE_TREATMENT_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_TYPE_CODE]: {
    modal: { title: 'Oznake vrsta otpada', width: '50%' },
    formData: {
      initialState: WASTE_TYPE_CODE_INITIAL_STATE,
      components: WASTE_TYPE_CODE_FORM_OPTIONS,
      filtersInitialState: WASTE_TYPE_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.COMPETENT_AUTHORITY_PARTY]: {
    modal: { title: 'Podaci o nadležnom organu', width: '50%' },
    formData: {
      initialState: COMPETENT_AUTHORITY_PARTY_INITIAL_STATE,
      components: COMPETENT_AUTHORITY_PARTY_FORM_OPTIONS,
      filtersInitialState: COMPETENT_AUTHORITY_PARTY_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_CATALOG_SUBGROUP]: {
    modal: { title: 'Podgrupe u katalogu otpada', width: '50%' },
    formData: {
      initialState: WASTE_CATALOG_SUBGROUP_INITIAL_STATE,
      components: WASTE_CATALOG_SUBGROUP_FORM_OPTIONS,
      filtersInitialState: WASTE_CATALOG_SUBGROUP_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.CONFIDENTIALITY]: {
    modal: { title: 'Poverljivost', width: '50%' },
    formData: {
      initialState: CONFIDENTIALITY_INITIAL_STATE,
      components: CONFIDENTIALITY_FORM_OPTIONS,
      filtersInitialState: CONFIDENTIALITY_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_METHOD]: {
    modal: { title: 'PPOV - metod', width: '50%' },
    formData: {
      initialState: WASTE_WATER_TREATMENT_METHOD_INITIAL_STATE,
      components: WASTE_WATER_TREATMENT_METHOD_FORM_OPTIONS,
      filtersInitialState: WASTE_WATER_TREATMENT_METHOD_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_TYPE]: {
    modal: { title: 'PPOV - vrste uređaja', width: '50%' },
    formData: {
      initialState: WASTE_WATER_TREATMENT_TYPE_INITIAL_STATE,
      components: WASTE_WATER_TREATMENT_TYPE_FORM_OPTIONS,
      filtersInitialState: WASTE_WATER_TREATMENT_TYPE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1]: {
    modal: { title: 'Privredna delatnost 1', width: '50%' },
    formData: {
      initialState: PRIVREDNA_DELATNOST_1_INITIAL_STATE,
      components: PRIVREDNA_DELATNOST_1_FORM_OPTIONS,
      filtersInitialState: PRIVREDNA_DELATNOST_1_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2]: {
    modal: { title: 'Privredna delatnost 2', width: '50%' },
    formData: {
      initialState: PRIVREDNA_DELATNOST_2_INITIAL_STATE,
      components: PRIVREDNA_DELATNOST_2_FORM_OPTIONS,
      filtersInitialState: PRIVREDNA_DELATNOST_2_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PTP2_REPORT_CUSTOMS]: {
    modal: { title: i18n.t('SIFARNICI.IZVESTAJ_CARINE'), width: '90%' },
    formData: {
      initialState: PTP2_REPORT_CUSTOMS_INITIAL_STATE,
      components: PTP2_REPORT_CUSTOMS_FORM_OPTIONS,
      filtersInitialState: PTP2_REPORT_CUSTOMS_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PRODUCTS_NR]: {
    modal: {
      title: i18n.t('SIFARNICI.LISTA_PROIZVODA_PTP2_VRSTA'),
      width: '90%',
    },
    formData: {
      components: PRODUCTS_NR_FORM_OPTIONS,
      initialState: PRODUCTS_NR_INITIAL_STATE,
      filtersInitialState: PRODUCTS_NR_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PZV_CUSTOMS_AND_PRODUCERS]: {
    modal: { title: i18n.t('SIFARNICI.CARINA_I_PROIZVODJAC'), width: '90%' },
    formData: {
      components: PZV_CUSTOMS_AND_PRODUCERS_FORM_OPTIONS,
      initialState: PZV_CUSOTMS_AND_PRODUCERS_INITIAL_STATE,
      filtersInitialState: PZV_CUSOTMS_AND_PRODUCERS_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PRODUCTS_PZV]: {
    modal: { title: i18n.t('SIFARNICI.PZV_PROIZVODI'), width: '60%' },

    formData: {
      components: PRODUCTS_PZV_FORM_OPTIONS,
      initialState: PRODUCTS_PZV_INITIAL_STATE,
      filtersInitialState: PRODUCTS_PZV_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.PZV_TYPE_OF_PRODUCTS]: {
    modal: { title: 'PZV | Vrste proizvoda', width: '50%' },
    formData: {
      initialState: PZV_TYPE_OF_PRODUCTS_INITIAL_STATE,
      components: PZV_TYPE_OF_PRODUCTS_FORM_OPTIONS,
      filtersInitialState: PZV_TYPE_OF_PRODUCTS_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.Q_LIST]: {
    modal: { title: 'Q lista', width: '50%' },
    formData: {
      initialState: Q_LIST_INITIAL_STATE,
      components: Q_LIST_FORM_OPTIONS,
      filtersInitialState: Q_LIST_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WORKING_REGIME]: {
    modal: { title: 'Režimi rada', width: '50%' },
    formData: {
      initialState: WORKING_REGIME_INITIAL_STATE,
      components: WORKING_REGIME_FORM_OPTIONS,
      filtersInitialState: WORKING_REGIME_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.COMPANY_WITH_SELF_MANAGEMENT]: {
    modal: { title: i18n.t('SIFARNICI.SAMOSTALNO_UPRAVLJANJE_OTPADOM'), width: '50%' },
    formData: {
      initialState: COMPANY_WITH_SELF_MANAGEMENT_INITIAL_STATE,
      components: COMPANY_WITH_SELF_MANAGEMENT_FORM_OPTIONS,
      filtersInitialState: COMPANY_WITH_SELF_MANAGEMENT_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.COMPOSITION_COMMUNAL_WASTE]: {
    modal: { title: 'Sastav komunalnog otpada', width: '50%' },
    formData: {
      initialState: COMPOSITION_COMMUNAL_WASTE_INITIAL_STATE,
      components: COMPOSITION_COMMUNAL_WASTE_FORM_OPTIONS,
      filtersInitialState: COMPOSITION_COMMUNAL_WASTE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.FUEL_TYPE]: {
    modal: { title: 'Tipovi goriva', width: '50%' },
    formData: {
      initialState: FUEL_TYPE_INITIAL_STATE,
      components: FUEL_TYPE_FORM_OPTIONS,
      filtersInitialState: FUEL_TYPE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WATER_COURSE]: {
    modal: { title: 'Vodotok', width: '50%' },
    formData: {
      initialState: WATER_COURSE_INITIAL_STATE,
      components: WATER_COURSE_FORM_OPTIONS,
      filtersInitialState: WATER_COURSE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WATER_AREA]: {
    modal: { title: 'Vodno područje', width: '50%' },
    formData: {
      initialState: WATER_AREA_INITIAL_STATE,
      components: WATER_AREA_FORM_OPTIONS,
      filtersInitialState: WATER_AREA_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.WATER_BODIES]: {
    modal: {
      title: `Vodno telo`,
      width: '50%',
    },
    formData: {
      components: WATER_BODIES_FORM_OPTIONS,
      initialState: WATER_BODIES_INITIAL_STATE,
      filtersInitialState: WATER_BODIES_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.SOURCE_TYPE]: {
    modal: { title: 'Vrsta izvora', width: '50%' },
    formData: {
      initialState: SOURCE_TYPE_INITIAL_STATE,
      components: SOURCE_TYPE_FORM_OPTIONS,
      filtersInitialState: SOURCE_TYPE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.RECIPIENT_TYPE]: {
    modal: { title: 'Vrsta recipijenta', width: '50%' },
    formData: {
      initialState: RECIPIENT_TYPE_INITIAL_STATE,
      components: RECIPIENT_TYPE_FORM_OPTIONS,
      filtersInitialState: RECIPIENT_TYPE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.TYPE_OF_PACKING]: {
    modal: { title: 'Vrste ambalaže', width: '50%' },
    formData: {
      initialState: TYPE_OF_PACKING_INITIAL_STATE,
      components: TYPE_OF_PACKING_FORM_OPTIONS,
      filtersInitialState: TYPE_OF_PACKING_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.LANDFILL_TYPE]: {
    modal: { title: 'Vrste deponija', width: '50%' },
    formData: {
      initialState: LANDFILL_TYPE_INITIAL_STATE,
      components: LANDFILL_TYPE_FORM_OPTIONS,
      filtersInitialState: LANDFILL_TYPE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.TYPE_OF_WASTE]: {
    modal: { title: 'Vrste otpada', width: '50%' },
    formData: {
      initialState: TYPE_OF_WASTE_INITIAL_STATE,
      components: TYPE_OF_WASTE_FORM_OPTIONS,
      filtersInitialState: TYPE_OF_WASTE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.TYPE_MEANS_TRANSPORT]: {
    modal: { title: 'Vrste prevoznog sredstva', width: '50%' },
    formData: {
      initialState: TYPE_MEANS_TRANSPORT_INITIAL_STATE,
      components: TYPE_MEANS_TRANSPORT_FORM_OPTIONS,
      filtersInitialState: TYPE_MEANS_TRANSPORT_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.POLLUTANT_CODE]: {
    modal: { title: 'Zagađujuće materije', width: '50%' },
    formData: {
      initialState: POLLUTANT_CODE_INITIAL_STATE,
      components: POLLUTANT_CODE_FORM_OPTIONS,
      filtersInitialState: POLLUTANT_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.MUNICIPALITY_CODE]: {
    modal: { title: 'Šifarnik opština', width: '50%' },
    formData: {
      initialState: MUNICIPALITY_CODE_INITIAL_STATE,
      components: MUNICIPALITY_CODE_FORM_OPTIONS,
      filtersInitialState: MUNICIPALITY_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.NACE_ACTIVITY_CODE]: {
    modal: { title: 'Šifarnik pretežne delatnosti (NACE)', width: '50%' },
    formData: {
      initialState: NACE_ACTIVITY_CODE_INITIAL_STATE,
      components: NACE_ACTIVITY_CODE_FORM_OPTIONS,
      filtersInitialState: NACE_ACTIVITY_CODE_FILTERS_INITIAL_STATE,
    },
  },

  [SIFARNICI_ROUTES.Y_LIST]: {
    modal: { title: 'Y lista', width: '50%' },
    formData: {
      initialState: Y_LIST_INITIAL_STATE,
      components: Y_LIST_FORM_OPTIONS,
      filtersInitialState: Y_LIST_FILTERS_INITIAL_STATE,
    },
  },
};

export const sifarniciMakeOptions = (): ISifarnikInitial[] => {
  return Object.keys(SIFARNIK_MODAL_DATA).map(key => ({
    label: SIFARNIK_MODAL_DATA[key].modal.title,
    value: key,
  }));
};

export const SIFARNIK_SELECT_OPTIONS: ISifarnikInitial[] = sifarniciMakeOptions();

export const INITIAL_RESPONSE_SIFARNIK = {
  items: [],
  total: 0,
  size: 0,
  page: 0,
  pages: 0,
};

// TABLE DATA SIFARNIK
export const SIFARNICI_TYPE_TABLE_COLUMNS: ITableColumns = {
  [SIFARNICI_ROUTES.WATER_COURSE]: WATER_COURSE_TABLE_DATA,
  [SIFARNICI_ROUTES.WATER_BODIES]: WATER_BODIES_TABLE_DATA,
  [SIFARNICI_ROUTES.AKTIVNOST_1]: AKTIVNOST_1_TABLE_DATA,
  [SIFARNICI_ROUTES.AKTIVNOST_2]: AKTIVNOST_2_TABLE_DATA,
  [SIFARNICI_ROUTES.APR]: APR_TABLE_DATA,
  [SIFARNICI_ROUTES.PRODUCTS_NR]: PRODUCTS_NR_TABLE_DATA,
  [SIFARNICI_ROUTES.PRODUCTS_PZV]: PRODUCTS_PZV_TABLE_DATA,
  [SIFARNICI_ROUTES.LABORATORIES_AIR_WATER]: LABORATORIES_AIR_WATER_TABLE_DATA,
  [SIFARNICI_ROUTES.PTP2_REPORT_CUSTOMS]: PTP2_REPORT_CUSTOMS_TABLE_DATA,
  [SIFARNICI_ROUTES.PZV_CUSTOMS_AND_PRODUCERS]: PZV_CUSTOMS_AND_PRODUCERS_TABLE_DATA,
  [SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE]: OPERATORS_PACKAGE_WASTE_MANAGE_TABLE_DATA,
  [SIFARNICI_ROUTES.COMPANY_WITH_SELF_MANAGEMENT]: COMPANY_WITH_SELF_MANAGEMENT_TABLE_DATA,
  [SIFARNICI_ROUTES.METHOD_BASIS_CODE]: METHOD_BASIS_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1]: PRIVREDNA_DELATNOST_1_TABLE_DATA,
  [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2]: PRIVREDNA_DELATNOST_2_TABLE_DATA,
  [SIFARNICI_ROUTES.COUNTRY_CODE]: COUNTRY_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.MEDIUM_CODE]: MEDIUM_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.METHOD_TYPE_CODE]: METHOD_TYPE_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.METHOD_TYPES]: METHOD_TYPES_TABLE_DATA,
  [SIFARNICI_ROUTES.METHOD_DESIGNATION]: METHOD_DESIGNATION_TABLE_DATA,
  [SIFARNICI_ROUTES.NACE_ACTIVITY_CODE]: NACE_ACTIVITY_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.NUTS_REGION_CODE]: NUTS_REGION_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.RIVER_BASIN_DISTRICT_CODE]: RIVER_BASIN_DISTRICT_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_TREATMENT_CODE]: WASTE_TREATMENT_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_TYPE_CODE]: WASTE_TYPE_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.UNIT_CODE]: UNIT_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.POLLUTANT_CODE]: POLLUTANT_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.POLLUTANT_GROUP]: POLLUTANT_GROUP_TABLE_DATA,
  [SIFARNICI_ROUTES.POLLUTANT_THRESHOLD]: POLLUTANT_THRESHOLD_TABLE_DATA,
  [SIFARNICI_ROUTES.COORDINATE_SYSTEM]: COORDINATE_SYSTEM_TABLE_DATA,
  [SIFARNICI_ROUTES.COMPETENT_AUTHORITY_PARTY]: COMPETENT_AUTHORITY_PARTY_TABLE_DATA,
  [SIFARNICI_ROUTES.CONFIDENTIALITY]: CONFIDENTIALITY_TABLE_DATA,
  [SIFARNICI_ROUTES.SOURCE_TYPE]: SOURCE_TYPE_TABLE_DATA,
  [SIFARNICI_ROUTES.WORKING_REGIME]: WORKING_REGIME_TABLE_DATA,
  [SIFARNICI_ROUTES.FUEL_TYPE]: FUEL_TYPE_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_METHOD]: WASTE_WATER_TREATMENT_METHOD_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_TYPE]: WASTE_WATER_TREATMENT_TYPE_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_CATALOGUE]: WASTE_CATALOGUE_TABLE_DATA,
  [SIFARNICI_ROUTES.RECIPIENT_TYPE]: RECIPIENT_TYPE_TABLE_DATA,
  [SIFARNICI_ROUTES.SLIV]: SLIV_TABLE_DATA,
  [SIFARNICI_ROUTES.WATER_BODY_CATEGORY]: WATER_BODY_CATEGORY_TABLE_DATA,
  [SIFARNICI_ROUTES.WATER_AREA]: WATER_AREA_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_CATALOG_GROUP]: WASTE_CATALOG_GROUP_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_CATALOG_SUBGROUP]: WASTE_CATALOG_SUBGROUP_TABLE_DATA,
  [SIFARNICI_ROUTES.Y_LIST]: Y_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.Q_LIST]: Q_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.N_LIST]: N_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.C_LIST]: C_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.DR_LIST]: DR_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.TYPE_OF_WASTE]: TYPE_OF_WASTE_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_STATE_CATEGORY]: WASTE_STATE_CATEGORY_TABLE_DATA,
  [SIFARNICI_ROUTES.WASTE_CHARACTER]: WASTE_CHARACTER_TABLE_DATA,
  [SIFARNICI_ROUTES.LANDFILL_TYPE]: LANDFILL_TYPE_TABLE_DATA,
  [SIFARNICI_ROUTES.IA_LIST]: IA_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.IIB_LIST]: IIB_LIST_TABLE_DATA,
  [SIFARNICI_ROUTES.KOM1_TYPE_OF_WASTE]: KOM1_TYPE_OF_WASTE_TABLE_DATA,
  [SIFARNICI_ROUTES.COMPOSITION_COMMUNAL_WASTE]: COMPOSITION_COMMUNAL_WASTE_TABLE_DATA,
  [SIFARNICI_ROUTES.WAY_OF_HAND_OVER]: WAY_OF_HAND_OVER_TABLE_DATA,
  [SIFARNICI_ROUTES.ACTIVITIES_OF_WASTE_OPERATORS]: ACTIVITIES_OF_WASTE_OPERATORS_TABLE_DATA,
  [SIFARNICI_ROUTES.TYPE_MEANS_TRANSPORT]: TYPE_MEANS_TRANSPORT_TABLE_DATA,
  [SIFARNICI_ROUTES.METHOD_OF_PACKAGING_WASTE]: METHOD_OF_PACKAGING_WASTE_TABLE_DATA,
  [SIFARNICI_ROUTES.DEP_FORMS_ANSWERS]: DEP_FORMS_ANSWERS_TABLE_DATA,
  [SIFARNICI_ROUTES.WEEE_CATEGORIES]: WEEE_CATEGORIES_TABLE_DATA,
  [SIFARNICI_ROUTES.TYPE_OF_PACKING]: TYPE_OF_PACKING_TABLE_DATA,
  [SIFARNICI_ROUTES.PZV_TYPE_OF_PRODUCTS]: PZV_TYPE_OF_PRODUCTS_TABLE_DATA,
  [SIFARNICI_ROUTES.MUNICIPALITY_CODE]: MUNICIPALITY_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.NUTS1_CODE]: NUTS1_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.NUTS2_REGION_CODE]: NUTS2_REGION_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.NUTS3_AREA_CODE]: NUTS3_AREA_CODE_TABLE_DATA,
  [SIFARNICI_ROUTES.MESTO]: MESTO_TABLE_DATA,
};

export const readOnlySifarnikTypes: Partial<SIFARNICI_ROUTES>[] = [
  SIFARNICI_ROUTES.APR,
  SIFARNICI_ROUTES.APR_ADDRESS_DATA,
  SIFARNICI_ROUTES.APR_ASSIGNEE_FOUNDER,
];
