import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  IPostWasteStateCategory,
  IGetWasteStateCategory,
  IWasteStateCategoryFilters,
} from './wasteStateCategory.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const WASTE_STATE_CATEGORY_INITIAL_STATE: IPostWasteStateCategory = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const WASTE_STATE_CATEGORY_FILTERS_INITIAL_STATE: IWasteStateCategoryFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};

export const WASTE_STATE_CATEGORY_TABLE_DATA: ColumnsType<IGetWasteStateCategory> = [NAME_TABLE, NAME_SR_TABLE];

export const WASTE_STATE_CATEGORY_FORM_OPTIONS = (_: FormInstance<IGetWasteStateCategory>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(200), NAME_SR_FORM(200)];
};
