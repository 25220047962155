import { ColumnsType } from 'antd/es/table';
import { ICListFilters, IGetCList, IPostCList } from './cList.types';
import { FormInstance } from 'antd';
import { DESCRIPTION_SR_TABLE, DESCRIPTION_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { DESCRIPTION_FORM, DESCRIPTION_SR_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

// todo: proveriti polje v2

export const C_LIST_INITIAL_STATE: IPostCList = {
  name: '',
  description: '',
  description_sr: '',
  is_active: true,
};
export const C_LIST_FILTERS_INITIAL_STATE: ICListFilters = {
  is_active: null,
  name__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
};

export const C_LIST_TABLE_DATA: ColumnsType<IGetCList> = [NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE];
export const C_LIST_FORM_OPTIONS = (_: FormInstance<IGetCList>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(10), DESCRIPTION_FORM(300), DESCRIPTION_SR_FORM(300)];
};
