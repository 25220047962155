import { ColumnsType } from 'antd/es/table';
import { IGetLandfillType, ILandfillTypeFilters, IPostLandfillType } from './landfillType.types';
import { NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const LANDFILL_TYPE_INITIAL_STATE: IPostLandfillType = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const LANDFILL_TYPE_FILTERS_INITIAL_STATE: ILandfillTypeFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};
export const LANDFILL_TYPE_TABLE_DATA: ColumnsType<IGetLandfillType> = [NAME_TABLE, NAME_SR_TABLE];
export const LANDFILL_TYPE_FORM_OPTIONS = (_: FormInstance<IGetLandfillType>): ISifarniciTypeOfForm[] => [
  NAME_FORM(150),
  NAME_SR_FORM(150),
];
