import { Row } from 'antd';
import { Switch } from 'components/Switch/Switch';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { authStore } from 'store';

interface IIsActiveProps {
  isActive: boolean;
  toggleActivate: (checked: boolean) => Promise<unknown>;
  disabled?: boolean;
  roleCondition?: boolean;
}

export const IsActiveSwitch: React.FC<IIsActiveProps> = observer(
  ({ isActive, toggleActivate, disabled, roleCondition }) => {
    const onSwitchChange = async (checked: boolean) => {
      try {
        await toggleActivate(checked);
      } catch (error) {
        error;
      }
    };

    return (
      <>
        {roleCondition ||
          (authStore.isAdmin && (
            <Row align={'middle'}>
              {isActive ? 'Aktivan' : 'Neaktivan'}
              <Switch
                disabled={disabled}
                name={'is_active'}
                checked={isActive}
                onChange={(checked: boolean) => onSwitchChange(checked)}
                style={{ margin: '0px 10px' }}
              />
            </Row>
          ))}
      </>
    );
  }
);
