import { Col, Form, FormInstance, Select } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { EMAIL_FIELD_RULE, MAX_LENGTH_RULE, PHONE_FIELD_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { IGetDkoObrazac } from 'modules/obrasci/store/dko/dko.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect } from 'react';
import styles from './DkoDeoDWizardTab.module.scss';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import dayjs from 'dayjs';
import { PrimalacOtpadaOptions } from 'modules/obrasci/store/dko/dko.constants';
import { useParams } from 'react-router-dom';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { useTranslation } from 'react-i18next';

type FormProps = {
  form: FormInstance;
};

export const DkoDeoDWizardTab: React.FC<FormProps> = observer(({ form }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const defaultPrimalacOtpadaOption: ISifarnikInitial | undefined = dkoObrazacStore.initialState.primalac_otpada_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).primalac_otpada.full_company_name,
        value: dkoObrazacStore.initialState.primalac_otpada_id,
      }
    : undefined;

  useEffect(() => {
    if (dkoObrazacStore.cDatumPredaje) {
      form.setFieldValue('datum_preuzimanja_otpada_deo_d', dayjs(dkoObrazacStore.cDatumPredaje));
    } else form.setFieldValue('datum_preuzimanja_otpada_deo_d', '');
  }, [dkoObrazacStore.cDatumPredaje]);

  return (
    <Col span={24} className={styles.wrapper}>
      <h2>{`${t('WIZARD_TABS.DEO_D')} - ${t('OBRASCI.DKO.PODACI_O_PRIMAOCU_OTPADA')}`}</h2>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.DKO.PREDUZECE_PRIMALAC')}
        placeholder={''}
        formName={'primalac_otpada_id'}
        fetchOptinsList={dkoObrazacStore.fetchApr}
        labelOptionAccessor="full_company_name"
        disabledAccessor=""
        valueOptionAccessor="id"
        readOnly={false}
        inputName={'primalac_otpada_id'}
        defaultOption={defaultPrimalacOtpadaOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      />
      <FormInput
        label={t('OBRASCI.DKO.TELEFON_PRIMALAC')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'telefon_primaoca_otpada'}
        type="text"
        inputName={'telefon_primaoca_otpada'}
        placeholder=""
        rules={
          id
            ? [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50)]
            : [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50), PHONE_FIELD_RULE]
        }
      />
      <FormInputNumber
        label={t('OBRASCI.DKO.TELEFAKS_PRIMALAC')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'telefaks_primaoca_otpada'}
        inputName={'telefaks_primaoca_otpada'}
        placeholder=""
      />
      <FormInput
        label={t('OBRASCI.DKO.EMAIL_PRIMALAC')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'email_deo_d'}
        type="email"
        inputName={'email_deo_d'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true), EMAIL_FIELD_RULE, MAX_LENGTH_RULE(100)]}
      />
      {/* treba da bude neka dd lista ili sifarnik, proveriti windows */}
      <Form.Item
        rules={[REQUIRED_FIELD_RULE(true)]}
        name={'primalac_otpada_je'}
        label={t('OBRASCI.DKO.PRIMALAC_OTPADA_JE')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Select options={PrimalacOtpadaOptions}></Select>
      </Form.Item>
      <h3>{t('OBRASCI.DKO.DOZVOLA_ZA_UPRAVLJANJE')}</h3>
      <FormInput
        label={t('OBRASCI.DKO.BROJ_DOZVOLE')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'broj_dozvole_deo_d'}
        type="text"
        inputName={'broj_dozvole_deo_d'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)]}
      />
      <FormDatePicker
        name="datum_izdavanja_dozvole_deo_d"
        label={t('OBRASCI.GIO2.DATUM_IZDAVANJA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormDatePicker
        name="datum_preuzimanja_otpada_deo_d"
        label={t('OBRASCI.DKO.DATUM_PREUZIMANJA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabled
      />
      <h3>{t('OBRASCI.DKO.ODGOVORNO_LICE_PRIMALAC')}</h3>
      <FormInput
        label={t('OBRASCI.DKO.IME_PREZIME')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'ime_prezime_primaoca_otpada'}
        inputName={'ime_prezime_primaoca_otpada'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(200)]}
      />
      <FormInput
        label={t('OBRASCI.DKO.BROJ_MOBILNOG_TELEFONA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        rules={
          id
            ? [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50)]
            : [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50), PHONE_FIELD_RULE]
        }
        name={'broj_mobilnog_telefona_primaoca_otpada'}
        type="text"
        inputName={'broj_mobilnog_telefona_primaoca_otpada'}
        placeholder=""
      />
    </Col>
  );
});
