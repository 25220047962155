import {
  IBaseObrazacStore,
  IEmptyResponse,
  IPaginatedResponse,
  IPagination,
  IPostResponse,
} from 'typescript/NrizTypes';
import {
  IGetKomponenteObrazac5,
  IGetObrazac5,
  IGetUpravljanjeObrazac5,
  IPostKomponenteObrazac5,
  IPostObrazac5,
  IPostUpravljanjeObrazac5,
} from './obrazac5.types';
import {
  OBRAZAC_5_INITIAL_STATE,
  OBRAZAC_5_KOMPONENTE_INITIAL,
  OBRAZAC_5_UPRAVLJANJE_INITIAL,
} from './obrazac5.constants';
import { makeAutoObservable } from 'mobx';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import to from 'await-to-js';
import dayjs from 'dayjs';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { IGetCountryCode } from 'modules/sifarnici/store/countryCode/countryCode.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetNList } from 'modules/sifarnici/store/nList/nList.types';
import { IGetWasteCatalogue, IPostWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWasteCharacter } from 'modules/sifarnici/store/wasteCharacter/wasteCharacter.types';
import { IGetTypeOfWaste } from 'modules/sifarnici/store/typeOfWaste/typeOfWaste.types';
import { IGetWasteStateCategory } from 'modules/sifarnici/store/wasteStateCategory/wasteStateCategory.types';
import { IGetYList } from 'modules/sifarnici/store/yList/yList.types';
import { IGetQList } from 'modules/sifarnici/store/qList/qList.types';
import { WASTE_CATALOGUE_INITIAL_STATE } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.constants';

class Obrazac5Store implements IBaseObrazacStore<IPostObrazac5, IGetObrazac5> {
  initialState: IPostObrazac5 | IGetObrazac5 = OBRAZAC_5_INITIAL_STATE;
  komponente_form: IPostKomponenteObrazac5 | IGetKomponenteObrazac5 = OBRAZAC_5_KOMPONENTE_INITIAL;
  upravljanje_form: IPostUpravljanjeObrazac5 | IGetUpravljanjeObrazac5 = OBRAZAC_5_UPRAVLJANJE_INITIAL;
  waste_catalogue: IPostWasteCatalogue = WASTE_CATALOGUE_INITIAL_STATE;

  constructor() {
    makeAutoObservable(this);
  }

  get getKomponenteForm() {
    return this.komponente_form;
  }

  get getUpravljanjeForm() {
    return this.upravljanje_form;
  }

  get getInitialState() {
    return this.initialState;
  }

  handleChange(key: keyof Obrazac5Store, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  resetStates = () => {
    this.handleChange('initialState', OBRAZAC_5_INITIAL_STATE);
    this.handleChange('waste_catalogue', WASTE_CATALOGUE_INITIAL_STATE);
  };

  get getDangerousWaste() {
    return this.waste_catalogue.index_number.toString().includes('*');
  }

  /// KOMPONENTE

  submitKomponenteForm = (payload: IPostKomponenteObrazac5) => {
    let newPayload = payload;
    newPayload = { ...newPayload, id: (this.komponente_form as IGetKomponenteObrazac5).id } as IGetKomponenteObrazac5;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.komponente, newPayload);
    this.handleInitialStateChange('komponente', newList);
    this.handleChange('komponente_form', OBRAZAC_5_KOMPONENTE_INITIAL);
  };

  /// UPRAVLJANJE

  submitUpravljanjeForm = (payload: IPostUpravljanjeObrazac5) => {
    let newPayload = payload;
    let sifarnikNazivZaSkladistenje = {};
    let sifarnikOznakaDIliR = {};
    let sifarnikNazivZaOdlaganje = {};
    let sifarnikNazivZaPonovnoIskoriscenje = {};
    let sifarnikZemljaIzvoza = {};
    let sifarnikDOznaka = {};
    let sifarnikROznaka = {};
    let sifarnikOznakaDIliR2 = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.naziv_postrojenja_za_skladiste_otpada_id) {
        sifarnikNazivZaSkladistenje = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).naziv_postrojenja_za_skladiste_otpada_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).naziv_postrojenja_za_skladiste_otpada,
          list: sifarniciStore.list[SIFARNICI_ROUTES.APR],
          name: 'naziv_postrojenja_za_skladiste_otpada',
          id: payload.naziv_postrojenja_za_skladiste_otpada_id,
        });
      }

      if (newPayload.d_ili_r_oznaka_za_skladiste_otpada_id) {
        sifarnikOznakaDIliR = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_skladiste_otpada_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_skladiste_otpada,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
          name: 'd_ili_r_oznaka_za_skladiste_otpada',
          id: payload.d_ili_r_oznaka_za_skladiste_otpada_id,
        });
      }

      if (newPayload.naziv_postrojenja_za_tretman_otpada_odlaganje_id) {
        sifarnikNazivZaOdlaganje = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).naziv_postrojenja_za_tretman_otpada_odlaganje_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).naziv_postrojenja_za_tretman_otpada_odlaganje,
          list: sifarniciStore.list[SIFARNICI_ROUTES.APR],
          name: 'naziv_postrojenja_za_tretman_otpada_odlaganje',
          id: payload.naziv_postrojenja_za_tretman_otpada_odlaganje_id,
        });
      }

      if (newPayload.naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id) {
        sifarnikNazivZaPonovnoIskoriscenje = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5)
            .naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5)
            .naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje,
          list: sifarniciStore.list[SIFARNICI_ROUTES.APR],
          name: 'naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje',
          id: payload.naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id,
        });
      }
      if (newPayload.zemlja_izvoza_id) {
        sifarnikZemljaIzvoza = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).zemlja_izvoza_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).zemlja_izvoza,
          list: sifarniciStore.list[SIFARNICI_ROUTES.COUNTRY_CODE],
          name: 'zemlja_izvoza',
          id: payload.zemlja_izvoza_id,
        });
      }
      if (newPayload.d_oznaka_za_odlaganje_id) {
        sifarnikDOznaka = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).d_oznaka_za_odlaganje_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).d_oznaka_za_odlaganje,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
          name: 'd_oznaka_za_odlaganje',
          id: payload.d_oznaka_za_odlaganje_id,
        });
      }
      if (newPayload.r_oznaka_za_ponovno_iskoriscenje_id) {
        sifarnikROznaka = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).r_oznaka_za_ponovno_iskoriscenje_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).r_oznaka_za_ponovno_iskoriscenje,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
          name: 'r_oznaka_za_ponovno_iskoriscenje',
          id: payload.r_oznaka_za_ponovno_iskoriscenje_id,
        });
      }
      if (newPayload.d_ili_r_oznaka_za_izvoz_otpada_id) {
        sifarnikOznakaDIliR2 = nrizService.addSifarnikInObject({
          exId: (this.upravljanje_form as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_izvoz_otpada_id,
          exObj: (this.upravljanje_form as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_izvoz_otpada,
          list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
          name: 'd_ili_r_oznaka_za_izvoz_otpada',
          id: payload.d_ili_r_oznaka_za_izvoz_otpada_id,
        });
      }
    }

    newPayload = {
      id: (this.upravljanje_form as IGetUpravljanjeObrazac5).id,
      ...sifarnikNazivZaSkladistenje,
      ...sifarnikOznakaDIliR,
      ...sifarnikNazivZaOdlaganje,
      ...sifarnikNazivZaPonovnoIskoriscenje,
      ...sifarnikZemljaIzvoza,
      ...sifarnikDOznaka,
      ...sifarnikROznaka,
      ...sifarnikOznakaDIliR2,
      ...payload,
    } as IGetUpravljanjeObrazac5;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.upravljanje_otpadom, newPayload);
    this.handleInitialStateChange('upravljanje_otpadom', newList);
    this.handleChange('upravljanje_form', OBRAZAC_5_UPRAVLJANJE_INITIAL);
  };

  //// DOHVATANJE SIFARNIKA

  fetchWasteCatalougeList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
    });
    return response;
  };

  fetchTypeOfWaste = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetTypeOfWaste>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetTypeOfWaste>(SIFARNICI_ROUTES.TYPE_OF_WASTE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });
    return response;
  };

  fetchMethodBasisCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodBasisCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodBasisCode>(
      SIFARNICI_ROUTES.METHOD_BASIS_CODE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
      }
    );
    return response;
  };

  fetchDROptionsSkladiste = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__in: 'D14,D15,R12,R13',
      order_by: '+name',
    });

    return response;
  };

  fetchDROptionsOdlaganje = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'D',
    });

    return response;
  };

  fetchDROptionsPonovnoIskoriscenje = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetDRList>> => {
    let RNames = 'R1,R2,R3,R4,R5,R6,R7,R8,R9,R10,R11,R12';

    if (
      this.waste_catalogue.index_number &&
      this.waste_catalogue.index_number.startsWith('18') &&
      Number(obrasciStore.opste.godina) >= 2019
    ) {
      RNames += ',D9';
    }

    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(
      SIFARNICI_ROUTES.DR_LIST,
      nrizService.pickFields({
        search: search,
        name__in: RNames,
      })
    );

    return response;
  };

  fetchDROptionsIzvoz = async (_pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
    });

    return response;
  };

  fetchYListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetYList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetYList>(SIFARNICI_ROUTES.Y_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      v2: true,
      order_by: '+name',
    });
    return response;
  };

  fetchNListOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetNList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetNList>(SIFARNICI_ROUTES.N_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchWasteCharacter = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCharacter>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCharacter>(SIFARNICI_ROUTES.WASTE_CHARACTER, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });
    return response;
  };

  fetchApr = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetApr>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetApr>(SIFARNICI_ROUTES.APR, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+full_company_name',
    });
    return response;
  };

  fetchCountryCode = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetCountryCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCountryCode>(SIFARNICI_ROUTES.COUNTRY_CODE, {
      page: pagination.page,
      size: pagination.size,
      code__neq: 'RS',
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchWasteStateCategory = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteStateCategory>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteStateCategory>(
      SIFARNICI_ROUTES.WASTE_STATE_CATEGORY,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
      }
    );
    return response;
  };

  fetchQlist = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetQList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetQList>(SIFARNICI_ROUTES.Q_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  ////// SUBMIT OBRASCA

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetObrazac5>(TIPOVI_OBRAZACA.OBRAZAC_5, id));
    if (err) return;
    if (res.obrazac_gio1 && res.lriz_obrazac_5) {
      obrasciStore.handleChange('real_obrazac_type', TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1);
    }
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newDatumIzdavanja = res.datum_izdavanja ? dayjs(res.datum_izdavanja) : '';
    this.handleChange('initialState', { ...res, godina: newGodina, datum_izdavanja: newDatumIzdavanja });
    return res;
  };

  async postData(payload: IPostObrazac5) {
    const validObrazac = obrasciService.filtersSameObrazac(obrasciStore.real_obrazac_type as TIPOVI_OBRAZACA);
    const validPayload = { ...payload, ...validObrazac };
    const [err, res] = await to<IPostResponse>(
      obrasciService.postSingleObrazac<IPostObrazac5>(
        TIPOVI_OBRAZACA.OBRAZAC_5,
        nrizService.setEmptyValuesToNull(validPayload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: any) => {
    const [err, res] = await to<IEmptyResponse>(
      obrasciService.putSingleObrazac<IGetObrazac5>(
        TIPOVI_OBRAZACA.OBRAZAC_5,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;

    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.OBRAZAC_5, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostObrazac5) => {
    const newInitialState = {
      ...OBRAZAC_5_INITIAL_STATE,
      ...payload,
      godina: obrasciStore.opste.godina,
      datum_izdavanja: payload.datum_izdavanja ? dayjs(payload.datum_izdavanja).format('YYYY-MM-DD') : '',
      id: (this.initialState as IGetObrazac5).id,
      komponente: this.initialState.komponente,
      upravljanje_otpadom: this.initialState.upravljanje_otpadom,
    } as IGetObrazac5;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  //// PRETHODNA GODINA

  fetchInfoLastYear = async (
    godina: string,
    preduzece_id: string,
    postrojenje_id: string,
    indeksni_broj_otpada: string
  ) => {
    const [err, res] = await to(
      obrasciService.fetchInfoForLastYear({
        godina: godina,
        preduzece_id: preduzece_id,
        postrojenje_id: postrojenje_id,
        indeksni_broj_otpada_id: indeksni_broj_otpada,
      })
    );
    if (err || !res) return;

    if (Object.keys(res).length === 0) return;

    const newGodina = dayjs().year((obrasciStore.opste.godina as any) * 1);
    const updatedInitialState = {
      ...this.initialState,
      ...res,
      godina: newGodina,
      godisnja_kolicina_proizvedenog_otpada: '',
      stanje_skladista_1_januar: res.stanje_skladista_31_decembar,
      stanje_skladista_31_decembar: '',
    };

    this.handleChange('initialState', updatedInitialState);
    return res;
  };
}

export const obrazac5Store = new Obrazac5Store();
