import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { RequestUnsanitaryLandfill, IGetUnsanitaryLandfill, IPostUnsanitaryLandfill } from './unsanitarylandfill.types';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

const fetchUnsanitaryLandfillList = (
  payload: RequestUnsanitaryLandfill
): IPaginatedApiResponse<IGetUnsanitaryLandfill> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.UNSANITARY_LANDFILL}/?${query}`);
};

const postUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill): ApiResponse<IPostResponse> => {
  return axios.post(`/${ROUTES.UNSANITARY_LANDFILL}/`, unsanitaryLandfill);
};
const putUnsanitaryLandfill = (unsanitaryLandfill: IGetUnsanitaryLandfill): ApiResponse<IEmptyResponse> => {
  return axios.put(`/${ROUTES.UNSANITARY_LANDFILL}/${unsanitaryLandfill.id}`, unsanitaryLandfill);
};

const toggleActivateUnsanitaryLandfill = (id: string, isActive: boolean): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.UNSANITARY_LANDFILL}/${id}/${isActive ? 'aktiviraj' : 'deaktiviraj'}`);
};

export const unsanitaryLandfillRepo = {
  fetchUnsanitaryLandfillList,
  postUnsanitaryLandfill,
  putUnsanitaryLandfill,
  toggleActivateUnsanitaryLandfill,
};
