import { ColumnsType } from 'antd/es/table';
import {
  IActivitiesOfWasteOperatorsFilters,
  IGetActivitiesOfWasteOperators,
  IPostActivitiesOfWasteOperators,
} from './activitiesOfWasteOperators.types';
import { FormInstance } from 'antd';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const ACTIVITIES_OF_WASTE_OPERATORS_INITIAL_STATE: IPostActivitiesOfWasteOperators = {
  the_order: '',
  activities_operator: '',
  activities_operator_sr: '',
  is_active: true,
};

export const ACTIVITIES_OF_WASTE_OPERATORS_FILTERS_INITIAL_STATE: IActivitiesOfWasteOperatorsFilters = {
  the_order__ilike: '',
  activities_operator__ilike: '',
  activities_operator_sr__ilike: '',
  is_active: null,
};

export const ACTIVITIES_OF_WASTE_OPERATORS_TABLE_DATA: ColumnsType<IGetActivitiesOfWasteOperators> = [
  THE_ORDER_TABLE,
  {
    title: 'Aktivnost operatera',
    width: '300px',
    dataIndex: 'activities_operator',
    key: 'activities_operator',
  },
  {
    title: 'Aktivnost operatera (srp.)',
    width: '300px',
    dataIndex: 'activities_operator_sr',
    key: 'activities_operator_sr',
  },
];

export const ACTIVITIES_OF_WASTE_OPERATORS_FORM_OPTIONS = (
  _: FormInstance<IGetActivitiesOfWasteOperators>
): ISifarniciTypeOfForm[] => {
  return [
    THE_ORDER_FORM,
    {
      type_of_form: 'input',
      name: 'activities_operator',
      filterName: 'activities_operator__ilike',
      // todo: prevod
      label: 'Aktivnost operatera',
      inputName: 'activities_operator',
      rules: [MAX_LENGTH_RULE(100)],
    },
    {
      type_of_form: 'input',
      rules: [MAX_LENGTH_RULE(100)],
      name: 'activities_operator_sr',
      filterName: 'activities_operator_sr__ilike',
      // todo: prevod
      label: 'Aktivnost operatera (srp.)',
      inputName: 'activities_operator_sr',
    },
  ];
};
