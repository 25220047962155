import { ColumnsType } from 'antd/es/table';
import {
  IGetWasteCatalogue,
  IGetWeeeProductsCategory,
  IPostWasteCatalogue,
  IPostWeeeProductsCategory,
  IWasteCatalogueFilters,
  IWeeeProductsCategoryProps,
} from './wasteCatalogue.types';
import { Checkbox, FormInstance } from 'antd';
import { INDEX_NUMBER_TABLE, IS_ACTIVE_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { INDEX_NUMBER_FORM, IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SifarniciLists,
  SwitchSifarniciFormProps,
  TableSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import i18n from 'translations/i18n.config';
import { FormListType } from 'typescript/NrizTypes';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE, checkIfItemExistsInList } from 'modules/nriz/nriz.constants';

export const WEEE_PRODUCTS_CATEGORY_INITIAL_STATE: IPostWeeeProductsCategory = {
  is_active: true,
  category_id: '',
};
export const WEEE_PRODUCTS_CATEGORY_TABLE_DATA: ColumnsType<IGetWeeeProductsCategory> = [
  IS_ACTIVE_TABLE as any,
  {
    title: 'Male kategorije',
    width: 200,
    dataIndex: ['category', 'category_name_sr'],
    key: 'products_nr_id',
  },
];

export const WEEE_PRODUCTS_CATEGORY_FORM_OPTIONS = ({
  parentForm,
  formListName,
  selectedIndex,
}: IWeeeProductsCategoryProps): ISifarniciTypeOfForm[] => {
  const listItemAccessor = 'category_id';
  const list = parentForm.getFieldValue(formListName);
  const previousValue = list[selectedIndex] ? list[selectedIndex][listItemAccessor] : undefined;
  return [
    { ...IS_ACTIVE_SWITCH, labelCol: { span: 8 } },
    {
      type_of_form: 'searchScroll',
      label: 'Male kategorije',
      formName: 'category_id',
      fetchOptinsList: SIFARNICI_ROUTES.WEEE_CATEGORIES,
      filtersForFetch: {},
      labelOptionAccessor: ['category_name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      labelCol: { span: 8 },
      rules: [REQUIRED_FIELD_RULE(true), checkIfItemExistsInList({ previousValue, listItemAccessor, list })],
    } as SearchScrollSifarniciFormProps,
  ];
};

export const WASTE_CATALOGUE_INITIAL_STATE: IPostWasteCatalogue = {
  index_number: '',
  waste: '',
  waste_sr: '',
  level: null,
  group_code_id: '',
  subgroup_code_id: '',
  weee: false,
  weee_products_category: [],
};
export const WASTE_CATALOGUE_FILTERS_INITIAL_STATE: IWasteCatalogueFilters = {
  index_number__ilike: '',
  waste__ilike: '',
  waste_sr__ilike: '',
  level__ilike: null,
  group_code_id: '',
  subgroup_code_id: '',
  weee: null,
  is_active: null,
};
export const WASTE_CATALOGUE_TABLE_DATA: ColumnsType<IGetWasteCatalogue> = [
  INDEX_NUMBER_TABLE,
  {
    title: `Katalog otpada`,
    width: 200,
    dataIndex: 'waste',
    key: 'waste',
  },
  {
    title: `Katalog otpada ${i18n.t('NA_SRPSKOM')}`,
    width: 200,
    dataIndex: 'waste_sr',
    key: 'waste_sr',
  },
  {
    title: `Nivo`,
    width: 100,
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: `Šifra grupe`,
    width: 200,
    dataIndex: ['group_code', 'description_sr'],
    key: 'group_code',
  },
  {
    title: `Šifra podgrupe`,
    width: 200,
    dataIndex: ['subgroup_code', 'description_sr'],
    key: 'subgroup_code',
  },
  {
    title: `Male kategorije`,
    width: 100,
    dataIndex: 'wee',
    key: 'wee',
    render: (_: any, record: IGetWasteCatalogue) => <Checkbox checked={!!record.weee} disabled={true} />,
  },
];
export const WASTE_CATALOGUE_FORM_OPTIONS = (form: FormInstance<IGetWasteCatalogue>): ISifarniciTypeOfForm[] => {
  return [
    INDEX_NUMBER_FORM(20),
    {
      type_of_form: 'input',
      name: 'waste',
      filterName: 'waste__ilike',
      rules: [MAX_LENGTH_RULE(500)],
      label: 'Katalog otpada',
      inputName: 'waste',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'waste_sr',
      filterName: 'waste_sr__ilike',
      rules: [MAX_LENGTH_RULE(500)],
      label: 'Katalog otpada (srp.)',
      inputName: 'waste_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      label: 'Nivo',
      name: 'level',
      filterName: 'level__ilike',
      precision: 0,
      inputName: 'level',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'Šifra grupe',
      formName: 'group_code_id',
      sortName: 'group_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOG_GROUP,
      filtersForFetch: {},
      labelOptionAccessor: ['description_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'Šifra podgrupe',
      formName: 'subgroup_code_id',
      sortName: 'subgroup_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOG_SUBGROUP,
      filtersForFetch: {},
      labelOptionAccessor: ['description_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'weee',
      onSwitchChange: e => {
        if (!e) {
          form.setFieldValue('weee_products_category', []);
        }
      },
      // sortName: 'air',
      label: 'Male kategorije',
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'table',
      tableColumns: WEEE_PRODUCTS_CATEGORY_TABLE_DATA,
      listName: 'weee_products_category',
      configPath: { formListType: FormListType.Sifarnici, formListSubType: SifarniciLists.WEEE_PRODUCTS_CATEGORY },
      title: <div style={{ textDecoration: 'underline' }}>Kategorije</div>,
      hideInput: !form.getFieldValue('weee'),
      formModalInitialValues: WEEE_PRODUCTS_CATEGORY_INITIAL_STATE,
    } as TableSifarniciFormProps,
  ];
};
