import { FormInstance } from 'antd';
import { IGetAprAssigneeFounder, IPostAprAssigneeFounder } from './aprAssigneeFounder.types';
import i18n from 'translations/i18n.config';
import {
  ISifarniciObjectProps,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { JMBG_FIELD_RULE, MAX_LENGTH_RULE, PASSPORT_NUMBER_FIELD_RULE } from 'modules/nriz/nriz.constants';

export const APR_ASSIGNEE_FOUNDER_INITIAL_STATE: IPostAprAssigneeFounder = {
  apr_id: '',
  type_assignee_id: '',
  type_personality_id: '',
  first_name: '',
  surname: '',
  jmbg: '',
  numberof_passport: '',
  country: '',
  master_numberof_assignee: '',
  name_of_assignee: '',
  type_personality_representativ_id: '',
  representative_name: '',
  representative_surname: '',
  representative_jmbg: '',
  representative_passport: '',
  representative_country: '',
  assignee_function_id: '',
  independently_represents: false,
  restrictions_countrysignature: false,
  assignee_function_other: '',
  unigue_identifier: '',
  type_personalityof_legalentity_id: '',
};

export const APR_ASSIGNEE_FOUNDER_TABLE_DATA = [
  {
    title: `${i18n.t('USER.TYPE_PERSON')}`,
    // align: 'center',
    width: 150,
    dataIndex: ['type_personality', 'type_sr'],
    key: 'typeofaddress',
  },
  {
    title: `${i18n.t('USER.NAME')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: `${i18n.t('USER.SURNAME')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'surname',
    key: 'surname',
  },
  {
    title: `${i18n.t('USER.JMBG')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'jmbg',
    key: 'jmbg',
  },
  // {
  //   title: `${i18n.t('USER.NUMBER_OF_PASSPORT')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: 'numberof_passport',
  //   key: 'numberof_passport',
  // },
  {
    title: `${i18n.t('USER.COUNTRY')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: `${i18n.t('USER.TYPE_ASSIGNEE')}`,
    // align: 'center',
    width: 150,
    dataIndex: ['type_assignee', 'type_sr'],
    key: 'type_assignee',
  },
  // {
  //   title: `${i18n.t('USER.TYPE_LEGAL_ENTITY')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: ['type_personalityof_legalentity', 'type_sr'],
  //   key: 'type_personalityof_legalentity',
  // },
  {
    title: `${i18n.t('USER.MASTER_NUMBER_ASSIGNEE')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'master_numberof_assignee',
    key: 'master_numberof_assignee',
  },
  {
    title: `${i18n.t('USER.ASSIGNEE_NAME')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'name_of_assignee',
    key: 'name_of_assignee',
  },
  // {
  //   title: `${i18n.t('USER.TYPE_REPRESENTATIVE')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: ['type_personality_representativ', 'type_sr'],
  //   key: 'type_personality_representativ',
  // },
  // {
  //   title: `${i18n.t('USER.REPRESENTATIVE_NAME')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: 'representative_name',
  //   key: 'representative_name',
  // },
  // {
  //   title: `${i18n.t('USER.REPRESENTATIVE_SURNAME')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: 'representative_surname',
  //   key: 'representative_surname',
  // },
  // {
  //   title: `${i18n.t('USER.REPRESENTATIVE_JMBG')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: 'representative_jmbg',
  //   key: 'representative_jmbg',
  // },
  // {
  //   title: `${i18n.t('USER.REPRESENTATIVE_PASSPORT')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: 'representative_passport',
  //   key: 'representative_passport',
  // },
  // {
  //   title: `${i18n.t('USER.REPRESENTATIVE_COUNTRY')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: 'representative_country',
  //   key: 'representative_country',
  // },
  // {
  //   title: `${i18n.t('USER.ASSIGNEE_FUNCTION')}`,
  //   // align: 'center',
  //   width: 150,
  //   dataIndex: ['assignee_function', 'assignee_function_sr'],
  //   key: 'assignee_function',
  // },
  {
    title: `${i18n.t('USER.ASSIGNEE_FUNCTION_OTHER')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'assignee_function_other',
    key: 'assignee_function_other',
  },
  {
    title: `${i18n.t('USER.NUMBER_OF_PASSPORT')}`,
    // align: 'center',
    width: 150,
    dataIndex: 'unigue_identifier',
    key: 'unigue_identifier',
  },
];

export const APR_ASSIGNEE_FOUNDER_FORM_OPTIONS = (_: FormInstance<IGetAprAssigneeFounder>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'title',
      labelCol: { span: 10 },
      title: i18n.t('COMPANY.APR_TITLES.REPRESENTATIVE_FOUNDER'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'searchScroll',
      labelCol: { span: 10 },
      label: i18n.t('USER.TYPE_PERSON'),
      formName: 'type_personality_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_TYPE_PERSONALITY,
      filtersForFetch: {},
      labelOptionAccessor: ['type_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.NAME'),
      name: 'first_name',
      inputName: 'first_name',
      rules: [MAX_LENGTH_RULE(50)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      rules: [MAX_LENGTH_RULE(100)],
      label: i18n.t('USER.SURNAME'),
      name: 'surname',
      inputName: 'surname',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.JMBG'),
      name: 'jmbg',
      inputName: 'jmbg',
      type: 'number',
      rules: [JMBG_FIELD_RULE],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.NUMBER_OF_PASSPORT'),
      name: 'numberof_passport',
      inputName: 'numberof_passport',
      type: 'number',
      rules: [PASSPORT_NUMBER_FIELD_RULE],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.COUNTRY'),
      name: 'country',
      inputName: 'country',
      rules: [MAX_LENGTH_RULE(50)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'title',
      labelCol: { span: 10 },
      title: i18n.t('COMPANY.APR_TITLES.REPRESENTATIVE_LEGAL_ENTITY'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'searchScroll',
      labelCol: { span: 10 },
      label: i18n.t('USER.TYPE_ASSIGNEE'),
      formName: 'type_assignee_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_TYPE_ASSIGNEE,
      filtersForFetch: {},
      labelOptionAccessor: ['type_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      labelCol: { span: 10 },
      label: i18n.t('USER.TYPE_LEGAL_ENTITY'),
      formName: 'type_personalityof_legalentity_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_TYPE_PERSONALITY_OF_LEGAL_ENTITY,
      filtersForFetch: {},
      labelOptionAccessor: ['type_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.MASTER_NUMBER_ASSIGNEE'),
      name: 'master_numberof_assignee',
      inputName: 'master_numberof_assignee',
      type: 'number',
      rules: [MAX_LENGTH_RULE(20)],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'area',
      label: i18n.t('USER.ASSIGNEE_NAME'),
      name: 'name_of_assignee',
      rules: [MAX_LENGTH_RULE(500)],
      rows: 2,
      labelCol: 10,
      wrapperCol: 14,
    } as TextAreaSifarniciFormProps,
    {
      type_of_form: 'title',
      labelCol: { span: 10 },
      title: i18n.t('COMPANY.APR_TITLES.REPRESENTATIVE_ASSIGNEE'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'searchScroll',
      labelCol: { span: 10 },
      label: i18n.t('USER.TYPE_REPRESENTATIVE'),
      formName: 'type_personality_representativ_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_TYPE_PERSONALITY_REPRESENTATIV,
      filtersForFetch: {},
      labelOptionAccessor: ['type_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.REPRESENTATIVE_NAME'),
      name: 'representative_name',
      inputName: 'representative_name',
      rules: [MAX_LENGTH_RULE(100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.REPRESENTATIVE_SURNAME'),
      name: 'representative_surname',
      rules: [MAX_LENGTH_RULE(100)],
      inputName: 'representative_surname',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.REPRESENTATIVE_JMBG'),
      name: 'representative_jmbg',
      inputName: 'representative_jmbg',
      type: 'number',
      rules: [JMBG_FIELD_RULE],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.REPRESENTATIVE_PASSPORT'),
      name: 'representative_passport',
      inputName: 'representative_passport',
      type: 'number',
      rules: [PASSPORT_NUMBER_FIELD_RULE],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.REPRESENTATIVE_COUNTRY'),
      name: 'representative_country',
      inputName: 'representative_country',
      rules: [MAX_LENGTH_RULE(30)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'title',
      labelCol: { span: 10 },
      title: i18n.t('COMPANY.APR_TITLES.FUNCTION'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'searchScroll',
      labelCol: { span: 10 },
      label: i18n.t('USER.ASSIGNEE_FUNCTION'),
      formName: 'assignee_function_id',
      fetchOptinsList: SIFARNICI_ROUTES.ASSIGNEE_FUNCTION,
      filtersForFetch: {},
      labelOptionAccessor: ['assignee_function_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.ASSIGNEE_FUNCTION_OTHER'),
      name: 'assignee_function_other',
      rules: [MAX_LENGTH_RULE(30)],
      inputName: 'assignee_function_other',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'title',
      labelCol: { span: 10 },
      title: i18n.t('COMPANY.APR_TITLES.OTHER_INFO'),
    } as ISifarniciObjectProps,
    {
      type_of_form: 'input',
      labelCol: { span: 10 },
      label: i18n.t('USER.UNIQUE_IDENTIFIER'),
      name: 'unigue_identifier',
      inputName: 'unigue_identifier',
      rules: [MAX_LENGTH_RULE(30)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'switch',
      labelCol: { span: 10 },
      name: 'independently_represents',
      label: i18n.t('USER.REPRESENTS_INDEPENDANTLY'),
      hideInput: true,
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      labelCol: { span: 10 },
      name: 'restrictions_countrysignature',
      label: i18n.t('USER.HAS_SIGNING_RESTRICTIONS'),
      hideInput: true,
    } as SwitchSifarniciFormProps,
  ] as ISifarniciTypeOfForm[];
};
