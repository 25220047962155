import { Row, Col } from 'antd';

import { observer } from 'mobx-react-lite';

import React from 'react';

import { EditButton } from '../EditButton/EditButton';
import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

interface IPropsEditChangeDiscardButton {
  name: string;
  onDiscardClick: () => any;
  onChangeClick: () => any;
  editButtonVisible: boolean;
  discardChangeButtonVisible: boolean;
  onEditClick: () => any;
}

export const EditChangeDiscardButton: React.FC<IPropsEditChangeDiscardButton> = observer(props => {
  const { t } = useTranslation();
  return (
    <Col>
      {props.editButtonVisible && <EditButton name={props.name} label="" onButtonClick={props.onEditClick} />}
      {props.discardChangeButtonVisible && (
        <Row justify="end" gutter={12}>
          <Col>
            <Button name={`${props.name}_discard`} onClick={props.onDiscardClick} danger>
              {t('DISCARD')}
            </Button>
          </Col>
          <Col>
            <Button onClick={props.onChangeClick} name={`${props.name}_change`}>
              {t('SUBMIT')}
            </Button>
          </Col>
        </Row>
      )}
    </Col>
  );
});
