import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import { unsanitaryLandfillRepo } from './unsanitarylandfill.repo';
import { IGetUnsanitaryLandfill, IPostUnsanitaryLandfill, RequestUnsanitaryLandfill } from './unsanitarylandfill.types';
import { ApiResponse } from 'modules/axios';

class UnsanitaryLandfillService {
  fetchUnsanitaryLandfillList = (payload: RequestUnsanitaryLandfill): IPaginatedApiResponse<IGetUnsanitaryLandfill> => {
    return unsanitaryLandfillRepo.fetchUnsanitaryLandfillList(payload);
  };

  postUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill): Promise<IPostResponse> => {
    return unsanitaryLandfillRepo.postUnsanitaryLandfill(unsanitaryLandfill);
  };
  putUnsanitaryLandfill = (unsanitaryLandfill: IGetUnsanitaryLandfill): Promise<IEmptyResponse> => {
    return unsanitaryLandfillRepo.putUnsanitaryLandfill(unsanitaryLandfill);
  };

  toggleActiveUnsanitaryLandfill = (id: string, isActive: boolean): ApiResponse<IEmptyResponse> => {
    return unsanitaryLandfillRepo.toggleActivateUnsanitaryLandfill(id, isActive);
  };
}

export const unsanitaryLandfillService = new UnsanitaryLandfillService();
