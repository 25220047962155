import { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
  CODE_TABLE,
  TITLE_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { IGetNaceActivityCode, INaceActivityCodeFilters, IPostNaceActivityCode } from './naceActivityCode.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const NACE_ACTIVITY_CODE_FILTERS_INITIAL_STATE: INaceActivityCodeFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  code_prtr__ilike: null,
  is_active: null,
};
export const NACE_ACTIVITY_CODE_INITIAL_STATE: IPostNaceActivityCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  code_prtr: null,
  is_active: true,
};

export const NACE_ACTIVITY_CODE_TABLE_DATA: ColumnsType<IGetNaceActivityCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  {
    title: 'Šifra prtr',
    width: '100px',
    dataIndex: 'code_prtr',
    key: 'code_prtr',
  },
];
export const NACE_ACTIVITY_CODE_FORM_OPTIONS = (form: FormInstance<IGetNaceActivityCode>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(200),
    NAME_SR_FORM(200),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM(200),
    DESCRIPTION_FORM(300),
    VERSION_FORM,
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(50)] },
    {
      type_of_form: 'input_number',
      name: 'code_prtr',
      filterName: 'code_prtr__ilike',
      // todo: prevod
      label: 'Šifra prtr',
      inputName: 'code_prtr',
    } as InputSifarniciFormProps,
  ];
};
