import { ColumnsType } from 'antd/es/table';
import { EditButton } from 'components/Buttons/EditButton/EditButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authStore } from 'store';
import { IGetFacility } from 'modules/facility/facility.types';
import { facilityStore } from 'modules/facility/facility.store';
import { facilityFormInitialState } from 'modules/facility/facility.constants';
import { IS_ACTIVE_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { RoutePathEnum } from 'router/router.constants';

export const FacilityListTable: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const baseColumns: ColumnsType<IGetFacility> = [
    { ...IS_ACTIVE_TABLE, fixed: 'left' } as any,
    {
      title: `${t('FACILITY.NAME')}`,
      dataIndex: 'naziv_postrojenja',
      width: '200px',
      key: 'naziv_postrojenja',
      fixed: 'left',
    },
    {
      title: `${t('FACILITY.NACIONAL_ID')}`,
      dataIndex: 'nacionalni_id',
      width: '150px',
      key: 'nacionalni_id',
    },
    {
      title: `${t('COMPANY.LOCATION')}`,
      dataIndex: ['mesto', 'display_name'],
      width: '200px',
      key: 'mesto',
    },
    {
      title: `${t('COMPANY.ADDRESS')}`,
      dataIndex: 'ulica',
      key: 'ulica',
      width: '200px',
    },
    {
      title: `${t('COMPANY.ADDRESS_NUMBER')}`,
      dataIndex: 'kucni_broj',
      key: 'kucni_broj',
      width: '100px',
    },
    {
      title: `${t('COMPANY.PHONE')}`,
      dataIndex: 'telefon',
      key: 'telefon',
      width: '150px',
    },
  ];

  const editColumns = [
    ...baseColumns,
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_: any, record: IGetFacility) => {
        return (
          <EditButton
            key={record.id}
            onButtonClick={() => {
              facilityStore.setFacility(facilityFormInitialState);
              navigate(`${RoutePathEnum.POSTROJENJE}/${record.id}`);
            }}
            name={'go-to-facility'}
          />
        );
      },
    },
  ];

  const columns = authStore.isOnlyCompanyUser ? baseColumns : editColumns;

  return (
    <TableData
      name="facility"
      columns={columns}
      dataSource={facilityStore.getFacilityList}
      refetchData={
        authStore.isCompanyUser ? facilityStore.fetchFacilityListByUser : facilityStore.fetchFacilityCompanyList
      }
      storePagination={facilityStore.facilityPagination}
    />
  );
});
