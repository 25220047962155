import dayjs from 'dayjs';

import { ITablePagination } from 'components/TableData/TableData';
import { ArrayHelper } from '../../utils/helpers/array.helper';
import { MAX_PAGINATION_SIZE } from '../nriz/nriz.constants';
import { obrazacIzvestajiRepo } from './obrazacIzvestaji.repo';
import {
  Aao1PregledPoGodinamaIzvestajFilters,
  Aao1SviPodaciOperaterInfo,
  Deo6RekapitulacijaIzvestajFilters,
  IIzvestajGodinePoKategorijiKategorijaInfo,
  IIzvestajOperaterInfo,
  IIzvestajPoGodinama,
  IIzvestajPoGodinamaTotal,
  IIzvestajUporedjivanjeGodina,
  IObrazacIzvestajFilters,
  IzvestajiSubtypes,
  Obrazac1GorivoIzvestajFilters,
  Obrazac5IzvestajiEnum,
  Obrazac5KontrolaUnosaIzvestajFilters,
  Obrazac5NeOpasan,
  ObrazacIzvestajiAllFilters,
  ObrazacIzvestajiAllReports,
  RekalkulacijaIzvestajTableData,
} from './obrazacIzvestaji.types';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import {
  IIzvestajiColumnGroupProps,
  IIzvestajiColumnOrGroupProps,
  IIzvestajiColumnProps,
} from './obrazacIzvestajiColumns.constants';
import { nrizService } from 'modules/nriz/nriz.service';

class ObrazacIzvestajiService {
  getObrazacByType = (
    payload: IObrazacIzvestajFilters,
    pagination: ITablePagination
  ): IPaginatedApiResponse<ObrazacIzvestajiAllReports | Array<RekalkulacijaIzvestajTableData>> => {
    return obrazacIzvestajiRepo.getObrazacByType(payload, this.changeSizePagination(payload.type, pagination));
  };

  exportToExcel = (payload: IObrazacIzvestajFilters) => {
    return obrazacIzvestajiRepo.exportToExcel(payload);
  };

  exportToPDF = (payload: IObrazacIzvestajFilters) => {
    return obrazacIzvestajiRepo.exportToPDF(payload);
  };

  formatGodina = (godina?: string) => {
    return godina ? dayjs(godina).format('YYYY') : godina;
  };

  formatDatum = (date?: string) => {
    return date ? dayjs(date).format('YYYY-MM-DD') : date;
  };

  isFilterByGodina = (object: any): object is Obrazac1GorivoIzvestajFilters => {
    return 'godina' in object;
  };

  isFilterByGodinaOd = (object: any): object is Aao1PregledPoGodinamaIzvestajFilters => {
    return 'godina_od' in object;
  };

  isFilterByGodinaDo = (object: any): object is Aao1PregledPoGodinamaIzvestajFilters => {
    return 'godina_do' in object;
  };

  isFilterByDatum = (object: any): object is Obrazac5KontrolaUnosaIzvestajFilters => {
    return 'datum' in object;
  };

  isFilterByDatumOd = (object: any): object is Deo6RekapitulacijaIzvestajFilters => {
    return 'datum_od' in object;
  };

  isFilterByDatumDo = (object: any): object is Deo6RekapitulacijaIzvestajFilters => {
    return 'datum_do' in object;
  };

  isFilterByTransakcije = (object: any): object is Deo6RekapitulacijaIzvestajFilters => {
    return 'transakcije' in object;
  };

  parseFilters = (filter_params: ObrazacIzvestajiAllFilters) => {
    if (this.isFilterByGodina(filter_params)) {
      filter_params.godina = this.formatGodina(filter_params.godina);
    }
    if (this.isFilterByGodinaOd(filter_params)) {
      filter_params.godina_od = this.formatGodina(filter_params.godina_od);
    }
    if (this.isFilterByGodinaDo(filter_params)) {
      filter_params.godina_do = this.formatGodina(filter_params.godina_do);
    }
    if (this.isFilterByDatum(filter_params)) {
      filter_params.datum = this.formatDatum(filter_params.datum);
    }
    if (this.isFilterByDatumOd(filter_params)) {
      filter_params.datum_od = this.formatDatum(filter_params.datum_od);
    }
    if (this.isFilterByDatumDo(filter_params)) {
      filter_params.datum_do = this.formatDatum(filter_params.datum_do);
    }
    if (this.isFilterByTransakcije(filter_params)) {
      const transakcijeObject: Record<string, boolean> = {};
      filter_params?.transakcije.forEach((transakcija: string) => {
        transakcijeObject[transakcija] = true;
      });
      filter_params = {
        ...filter_params,
        ...transakcijeObject,
      };
    }
    return filter_params;
  };

  parseIzvestajiFilters = (payload: ObrazacIzvestajiAllFilters) => {
    return nrizService.pickFields(nrizService.removeObjFromPayload(this.parseFilters(payload)));
  };

  getYearlyColumns<T extends IIzvestajPoGodinama>(data: T[]): IIzvestajiColumnProps<T>[] {
    const yearsObject = data?.[0]?.godine ?? {};
    const allYears = Object.keys(yearsObject);
    allYears.sort((a, b) => a.localeCompare(b));

    return allYears.map(year => {
      return {
        width: 120,
        render: (record: IIzvestajPoGodinama) => record.godine[year as keyof IIzvestajPoGodinama['godine']] || '',
        title: year,
      };
    });
  }

  createYearlyTotalRow<T extends IIzvestajPoGodinama>(data: T[]) {
    const yearsObject = data?.[0]?.godine ?? {};
    const allYears = Object.keys(yearsObject);
    allYears.sort((a, b) => a.localeCompare(b));

    const result: IIzvestajPoGodinama = { godine: {} };

    data.forEach(record => {
      allYears.forEach(year => {
        const yearCast = year as keyof IIzvestajPoGodinama['godine'];
        const newSum = Number(result.godine[yearCast] || 0) + Number(record.godine[yearCast]);
        result.godine[yearCast] = newSum ? newSum.toFixed(2) : '';
      });
    });

    return result;
  }

  renderYearlyColumnsWithTotal<T extends IIzvestajPoGodinama | IIzvestajPoGodinamaTotal>(
    dataColumns: IIzvestajiColumnOrGroupProps[],
    data: T[],
    totalText = 'Grand Total '
  ): IIzvestajiColumnOrGroupProps<T>[] {
    const yearsObject = data?.[0]?.godine ?? {};
    const allYears = Object.keys(yearsObject);
    allYears.sort((a, b) => a.localeCompare(b));

    return [
      ...dataColumns.map((col, colIndex) => {
        const prevRender = col.render || ((value, record: T) => record[col.dataIndex as keyof T]);
        return {
          ...col,
          render: (value, record: IIzvestajPoGodinamaTotal, index) => {
            if (record.is_total_record) {
              return {
                children: totalText,
                props: {
                  colSpan: colIndex === 0 ? dataColumns.length : 0,
                },
              };
            }
            return {
              children: record && !record.is_total_record && prevRender(value, record as T, index),
              props: {
                colSpan: record.is_total_record ? 0 : 1,
              },
            };
          },
        } as IIzvestajiColumnOrGroupProps<T>;
      }),
      ...this.getYearlyColumns(data),
    ];
  }

  getComparisonByYearGroupColumns<T extends IIzvestajUporedjivanjeGodina<TColumnGroup>, TColumnGroup>(
    data: T[],
    leftSideHeaderCols: IIzvestajiColumnProps<T>[],
    yearlyColumnGroups: { valueAccessor: string; title: string }[]
  ): IIzvestajiColumnGroupProps<T>[] {
    const yearsObject = data?.[0]?.godine ?? {};
    const allYears = Object.keys(yearsObject);
    allYears.sort((a, b) => a.localeCompare(b));

    return [
      {
        title: '',
        children: [...leftSideHeaderCols],
      },
      ...allYears.map(year => {
        return {
          title: year,
          className: 'border-left',
          children: [
            ...yearlyColumnGroups.map((col, index) => {
              return {
                width: 200,
                className: index === 0 ? 'border-left' : '',
                title: col.title,
                render: (record: T) =>
                  record.godine?.[year as keyof IIzvestajUporedjivanjeGodina<unknown>['godine']]?.[
                    col.valueAccessor as keyof TColumnGroup
                  ] || '',
              };
            }),
          ],
        };
      }),
    ];
  }

  getYearsGroupedByCategoryColumns<T>(
    leftSideHeaderCols: IIzvestajiColumnProps<T>[],
    allCategories: IIzvestajGodinePoKategorijiKategorijaInfo[],
    categoriesAccessor: keyof T
  ): IIzvestajiColumnGroupProps<T>[] {
    return [
      {
        title: '',
        children: [
          {
            title: '',
            children: [...leftSideHeaderCols],
          },
        ],
      },
      ...allCategories.map((category, categoryIndex) => {
        return {
          title: `${categoryIndex + 1 < 10 ? '0' : ''}${categoryIndex + 1}`,
          className: 'border-left',
          children: [
            {
              title: category.naziv,
              className: 'border-left',
              children: category.godine.map((yearObj, yearIndex) => {
                return {
                  width: 150,
                  title: yearObj.godina,
                  className: yearIndex === 0 ? 'border-left' : '',
                  render: (record: T) =>
                    (record[categoriesAccessor] as IIzvestajGodinePoKategorijiKategorijaInfo[])?.[categoryIndex]
                      ?.godine?.[yearIndex]?.vrednost || '',
                };
              }),
            },
          ],
        };
      }),
    ];
  }

  changeSizePagination(izvestajType: IzvestajiSubtypes, pagination: ITablePagination): ITablePagination {
    return {
      ...pagination,
      size: izvestajType !== Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN ? pagination.size : MAX_PAGINATION_SIZE,
    };
  }

  createSummaryRow(items: Array<Obrazac5NeOpasan>): Obrazac5NeOpasan {
    return {
      grupa: 'UKUPNO:',
      kolicina_neopasan_t: ArrayHelper.sumValuesFromObjectKeysInArray<Obrazac5NeOpasan>(items, 'kolicina_neopasan_t'),
      kolicina_opasan_t: ArrayHelper.sumValuesFromObjectKeysInArray<Obrazac5NeOpasan>(items, 'kolicina_opasan_t'),
    };
  }

  getOperaterInfoColumns<T extends IIzvestajOperaterInfo>(data: T[]): IIzvestajiColumnProps<T>[] {
    const operateri: Aao1SviPodaciOperaterInfo[] = data?.[0]?.operateri ?? [];
    const operaterInfoColumns: IIzvestajiColumnProps<T>[] = [];
    operateri.forEach((operater: Aao1SviPodaciOperaterInfo, index: number) => {
      operaterInfoColumns.push({
        width: 300,
        render: (record: IIzvestajOperaterInfo) => record.operateri[index].naziv_operatera,
        title: `Naziv operatera ${index + 1}`,
      });
      operaterInfoColumns.push({
        width: 300,
        render: (record: IIzvestajOperaterInfo) => record.operateri[index].broj_dozvole_operatera,
        title: `Broj dozvole operatera ${index + 1}`,
      });
      operaterInfoColumns.push({
        width: 400,
        render: (record: IIzvestajOperaterInfo) => record.operateri[index].procenat_ambalaze_prenet_na_operatera,
        title: `Procenat ambalaže prenet na operatera ${index + 1}`,
      });
    });
    return operaterInfoColumns;
  }
}

export const obrazacIzvestajiService = new ObrazacIzvestajiService();
