import { ColumnsType, ColumnType } from 'antd/es/table';
import { IPagination } from 'typescript';
import { IOTVRegistar, OTVRequest } from './otv/otv.types';
import { ReactNode } from 'react';
import { FormInstance } from 'antd';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { ILCPRegistar, ILCPRegistarListResponse, LCPRequest } from './lcp/lcp.types';
import { IPaginatedResponse } from 'typescript/NrizTypes';
import { ITablePagination } from 'components/TableData/TableData';

export enum RegistriEnum {
  Otv = 'otv',
  // Dozvole = 'dozvole',
  Lcp = 'lcp',
}

export type RegistriOptionType = {
  value: RegistriEnum;
  label: string;
};

export type RegistriColumns = {
  [key in RegistriEnum]: ColumnType<any>[];
};

export type RegistarKeyProps = {
  registar: RegistriEnum;
};

export type Registar = IOTVRegistar | ILCPRegistar;

export type RegistarResponse = ILCPRegistarListResponse | IOTVRegistar;
export interface RegistriConfig<T extends RegistriEnum> {
  label: string;
  key: T;
  routes: RegistriRoutes;
  store: IBaseRegistriStore<T>;
  tableData: RegistriTableData<T>;
}

export interface RegistriRoutes {
  urlRoute: string;
  component: ReactNode;
  apiRoute: string;
}

export interface RegistriTableData<T extends RegistriEnum> {
  columns: ColumnsType<T>;
  filters: {
    components: (form: FormInstance<FormState[T]>) => ISifarniciTypeOfForm[];
    initialState: Partial<FormState[T]>;
  };
}

export enum RegistriLists {
  OTV = 1,
  LCP,
  LCP_INSTALACIONI_DEO,
}
interface RegistriTypeMappings {
  [RegistriEnum.Lcp]: {
    request: LCPRequest;
    response: ILCPRegistar;
    tableData: ILCPRegistarListResponse;
  };
  [RegistriEnum.Otv]: {
    request: OTVRequest;
    response: IOTVRegistar;
    tableData: IOTVRegistar;
  };
}

export interface IBaseRegistriStore<T extends RegistriEnum> {
  postItem: (item: RegistriTypeMappings[T]['request']) => Promise<string | undefined>;
  putItem: (item: RegistriTypeMappings[T]['response']) => Promise<any>;
  deleteItem?: (id: string) => Promise<any>;
  getItem: (id: string) => Promise<RegistriTypeMappings[T]['response'] | undefined>;
  getItemList: (pagination?: ITablePagination) => Promise<IPaginatedResponse<RegistriTypeMappings[T]['tableData']>>;
  initialState?: RegistriTypeMappings[T]['request'];
  pagination: IPagination;
  dataSource: RegistriTypeMappings[T]['tableData'][];
  resetStates: () => void;
}

export type FormState = {
  [RegistriEnum.Otv]: OTVRequest & { id?: string };
  [RegistriEnum.Lcp]: LCPRequest & { id?: string };
};
