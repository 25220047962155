import { ColumnsType } from 'antd/es/table';
import { IGetMethodTypeCode, IMethodTypeCodeFilters, IPostMethodTypeCode } from './methodTypeCode.types';
import {
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { Checkbox, FormInstance } from 'antd';
import {
  CODE_FORM,
  DESCRIPTION_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  TITLE_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  VERSION_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

import { ISifarniciTypeOfForm, SwitchSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const METHOD_TYPE_CODE_INITIAL_STATE: IPostMethodTypeCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  designation_required: false,
  name_sr: '',
  is_active: true,
};
export const METHOD_TYPE_CODE_FILTERS_INITIAL_STATE: IMethodTypeCodeFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  designation_required: null,
  is_active: null,
};
export const METHOD_TYPE_CODE_TABLE_DATA: ColumnsType<IGetMethodTypeCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  {
    title: 'Potrebna oznaka',
    width: '150px',
    align: 'center',
    dataIndex: 'designation_required',
    key: 'designation_required',
    render: (_: any, record: IGetMethodTypeCode) => (
      <Checkbox checked={!!record.designation_required} disabled={true} />
    ),
  },
];
export const METHOD_TYPE_CODE_FORM_OPTIONS = (form: FormInstance<IGetMethodTypeCode>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(500),
    NAME_SR_FORM(500),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM(150),
    DESCRIPTION_FORM(500),
    VERSION_FORM,
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(30)] },
    {
      type_of_form: 'switch',
      name: 'designation_required',
      label: 'Potrebna oznaka',
    } as SwitchSifarniciFormProps,
  ];
};
