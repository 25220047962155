import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IGetLandfillType } from '../landfillType/landfillType.types';
import { IPostWorkingRegime, IWorkingRegimeFilters } from './workingRegime.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const WORKING_REGIME_FILTERS_INITIAL_STATE: IWorkingRegimeFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};
export const WORKING_REGIME_INITIAL_STATE: IPostWorkingRegime = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const WORKING_REGIME_TABLE_DATA: ColumnsType<IGetLandfillType> = [NAME_TABLE, NAME_SR_TABLE];
export const WORKING_REGIME_FORM_OPTIONS = (_: FormInstance<IGetLandfillType>): ISifarniciTypeOfForm[] => [
  NAME_FORM(200),
  NAME_SR_FORM(200),
];
