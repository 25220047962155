import { ColProps, FormInstance } from 'antd';
import { FormTableProps } from 'components/FormTable/FormTable';
import React from 'react';
import { FormCheckboxProps, FormInputProps, FormRadioProps, IFormScrollWithObjProps, IPagination } from 'typescript';
import {
  FormNumberInputProps,
  FormSelectProps,
  FormSwitchProps,
  FormTextAreaProps,
  IRangePickerProps,
} from 'typescript/NrizTypes';
import { IWasteStateCategoryFilters } from './store/wasteStateCategory/wasteStateCategory.types';
import { IAktivnost1Filters } from './store/aktivnost1/aktivnost1.types';
import { IAktivnost2Filters } from './store/aktivnost2/aktivnost2.types';
import { ICListFilters } from './store/cList/cList.types';
import { IActivitiesOfWasteOperatorsFilters } from './store/activitiesOfWasteOperators/activitiesOfWasteOperators.types';
import { IDepFormAnswersFilters } from './store/depFormsAnswers/depFormsAnswers.types';
import { IDRListFilters } from './store/dRList/dRList.types';
import { IPollutantThresholdFilters } from './store/pollutantThreshold/pollutantThreshold.types';
import { IWasteCatalogGroupFilters } from './store/wasteCatalogGroup/wasteCatalogGroup.types';
import { IPollutantGroupFilters } from './store/pollutantGroup/pollutantGroup.types';
import { IIaListFilters } from './store/iaList/iaList.types';
import { IIibListFilters } from './store/iibList/iibList.types';
import { IWasteCatalogueFilters } from './store/wasteCatalogue/wasteCatalogue.types';
import { IUnitCodeFilters } from './store/unitCode/unitCode.types';
import { IWasteCharacterFilters } from './store/wasteCharacter/wasteCharacter.types';
import { IWeeeCategoriesFilters } from './store/weeeCategories/weeeCategories.types';
import { IWaterBodyCategoryFilters } from './store/waterBodyCategory/waterBodyCategory.types';
import { IWayOfHandOverFilters } from './store/wayOfHandOver/wayOfHandOver.types';
import { IKom1TypeOfWasteFilters } from './store/kom1TypeOfWaste/kom1TypeOfWaste.types';
import { ICoordinateSystemFilters } from './store/coordinateSystem/coordinateSystem.types';
import { ILaboratoriesAirWaterFilters } from './store/laboratoriesAirWater/laboratoriesAirWater.types';
import { ICountryCodeFilters } from './store/countryCode/countryCode.types';
import { ISlivFilters } from './store/sliv/sliv.types';
import { IMediumCodeFilters } from './store/mediumCode/mediumCode.types';
import { IMestoFilters } from './store/mesto/mesto.types';
import { IMethodTypeCodeFilters } from './store/methodTypeCode/methodTypeCode.types';
import { IMethodTypesFilters } from './store/methodTypes/methodTypes.types';
import { INListFilters } from './store/nList/nList.types';
import { IMethodOfPackagingWasteFilters } from './store/methodOfPackaging/methodOfPackaging.types';
import { IMethodBasisCodeFilters } from './store/methodBasisCode/methodBasisCode.types';
import { INuts1CodeFilters } from './store/nuts1Code/nuts1Code.types';
import { INuts2RegionFilters } from './store/nuts2RegionCode/nuts2RegionCode.types';
import { INuts3AreaCodeFilters } from './store/nuts3AreaCode/nuts3AreaCode.types';
import { INutsRegionCodeFilters } from './store/nutsRegionCode/nutsRegionCode.types';
import { IOperatorsPackageWasteManageFilters } from './store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';
import { IMethodDesignationFilters } from './store/methodDesignation/methodDesignation.types';
import { IRiverBasinDistrictCodeFilters } from './store/riverBasinDistrictCode/riverBasinDistrictCode.types';
import { IWasteTreatmentCodeFilters } from './store/wasteTreatmentCode/wasteTreatmentCode.types';
import { IWasteTypeCodeFilters } from './store/wasteTypeCode/wasteTypeCode.types';
import { ICompetentAuthorityPartyFilters } from './store/competentAuthorityParty/competentAuthorityParty.types';
import { IWasteCatalogSubgroupFilters } from './store/wasteCatalogSubgroup/wasteCatalogSubgroup.types';
import { IConfidentialityFilters } from './store/confidentiality/confidentiality.types';
import { IFuelTypeFilters } from './store/fuelType/fuelType.types';
import { IWaterCourseFilters } from './store/waterCourse/waterCourse.types';
import { IWaterAreaFilters } from './store/waterArea/waterArea.types';
import { IWaterBodiesFilters } from './store/waterBodies/waterBodies.types';
import { ISourceTypeFilters } from './store/sourceType/sourceType.types';
import { IRecipientTypeFilters } from './store/recipientType/recipientType.types';
import { ITypeOfPackingFilters } from './store/typeOfPacking/typeOfPacking.types';
import { ILandfillTypeFilters } from './store/landfillType/landfillType.types';
import { ITypeOfWasteFilters } from './store/typeOfWaste/typeOfWaste.types';
import { ITypeMeansTransportFilters } from './store/typeMeansTransport/typeMeansTransport.types';
import { IPollutantCodeFilters } from './store/pollutantCode/pollutantCode.types';
import { IMunicipalityCodeFilters } from './store/municipalityCode/municipalityCode.types';
import { INaceActivityCodeFilters } from './store/naceActivityCode/naceActivityCode.types';
import { IYListFilters } from './store/yList/yList.types';
import { IAprFilters } from './store/apr/apr.types';
import { IPrivrednaDelatnost2Filters } from './store/privrednaDelatnost2/privrednaDelatnost2.types';
import { IWasteWaterTreatmentMethodFilters } from './store/wasteWaterTreatmentMethod/wasteWaterTreatmentMethod.types';
import { IPrivrednaDelatnost1Filters } from './store/privrednaDelatnost1/privrednaDelatnost1.types';
import { IWasteWaterTreatmentTypeFilters } from './store/wasteWaterTreatmentType/wasteWaterTreatmentType.types';
import { IPtp2ReportCustomsFilters } from './store/ptp2ReportCustoms/ptp2ReportCustoms.types';
import { IProductsNrFilters } from './store/productsNr/productsNr.types';
import { IPzvCustomsAndProducersFilters } from './store/pzvCustomsAndProducers/pzvCustomsAndProducers.types';
import { IProductsPzvFilters } from './store/productsPzv/productsPzv.types';
import { IPzvTypeOfProductsFilters } from './store/pzvTypeOfProducts/pzvTypeOfProducts.types';
import { IQListFilters } from './store/qList/qList.types';
import { IWorkingRegimeFilters } from './store/workingRegime/workingRegime.types';
import { ICompanyWithSelfManagmentFilters } from './store/companyWithSelfManagment/companyWithSelfManagment.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SIFARNICI_ROUTES {
  MESTO = 'MESTO',
  PREDUZECE = 'PREDUZECE',
  POSTROJENJE = 'POSTROJENJE',
  KORISNIK_POSTROJENJE = 'KORISNIK_POSTROJENJE',
  KORISNIK = 'KORISNIK',
  PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE = 'PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE',
  PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE = 'PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE',
  PREDUZECE_APR = 'PREDUZECE_APR',
  NESANITARNA_DEPONIJA = 'NESANITARNA_DEPONIJA',
  IZVOR_ZAGADJENJA_TLA = 'IZVOR_ZAGADJENJA_TLA',
  IZVOR_ZAGADJENJA_VODE = 'IZVOR_ZAGADJENJA_VODE',
  SIFARNIK_PRETEZNE_DELATNOSTI = 'SIFARNIK_PRETEZNE_DELATNOSTI',
  KORISNICI_PREDUZECA = 'KORISNICI_PREDUZECA',
  AKTIVNOST_1 = 'AKTIVNOST_1',
  PRIVREDNA_DELATNOST_1 = 'PRIVREDNA_DELATNOST_1',
  AKTIVNOST_2 = 'AKTIVNOST_2',
  PRIVREDNA_DELATNOST_2 = 'PRIVREDNA_DELATNOST_2',
  AKTIVNOST_1_AKTIVNOST_2 = 'AKTIVNOST_1_AKTIVNOST_2',
  SOURCE_TYPE = 'SOURCE_TYPE',
  TYPE_OF_RECIPIENT = 'TYPE_OF_RECIPIENT',
  RECIPIENT_TYPE = 'RECIPIENT_TYPE',
  SLIV = 'SLIV',
  WATER_BODIES = 'WATER_BODIES',
  VELIKI_ZAGADJIVACI = 'VELIKI_ZAGADJIVACI',

  // DEP 2 // DEP 1
  DEP_FORMS_ANSWERS = 'DEP_FORMS_ANSWERS',

  // PZV 1
  PRODUCTS_PZV = 'PRODUCTS_PZV',

  // KOM 1
  MUNICIPALITY_CODE = 'MUNICIPALITY_CODE',
  KOM1_TYPE_OF_WASTE = 'KOM1_TYPE_OF_WASTE',
  WAY_OF_HAND_OVER = 'WAY_OF_HAND_OVER',
  COMPOSITION_COMMUNAL_WASTE = 'COMPOSITION_COMMUNAL_WASTE',

  // PTP 2
  PRODUCTS_NR = 'PRODUCTS_NR',
  PRODUCTS_TYPE = 'PRODUCTS_TYPE',

  // OBRAZAC 4
  TYPE_OF_WASTE = 'TYPE_OF_WASTE',
  DR_LIST = 'DR_LIST',
  WASTE_CATALOGUE = 'WASTE_CATALOGUE',

  POLLUTANT_CODE = 'POLLUTANT_CODE',
  POLLUTANT_GROUP = 'POLLUTANT_GROUP',
  POLLUTANT_THRESHOLD = 'POLLUTANT_THRESHOLD',

  METHOD_TYPES = 'METHOD_TYPES',
  METHOD_BASIS_CODE = 'METHOD_BASIS_CODE',

  // OBRAZAC 1
  UNIT_CODE = 'UNIT_CODE',
  FUEL_TYPE = 'FUEL_TYPE',
  WORKING_REGIME = 'WORKING_REGIME',

  // AAO1
  TYPE_OF_PACKING = 'TYPE_OF_PACKING',
  OPERATORS_PACKAGE_WASTE_MANAGE = 'OPERATORS_PACKAGE_WASTE_MANAGE',
  COMPANY_WITH_SELF_MANAGEMENT = 'COMPANY_WITH_SELF_MANAGEMENT',
  POLLUTANTS_PER_ACTIVITY = 'POLLUTANTS_PER_ACTIVITY',
  // GIO3

  WEEE_CATEGORIES = 'WEEE-CATEGORIES',

  // OBRAZAC 3

  UNIT_CODE_BY_POLLUTANT = 'UNIT_CODE_BY_POLLUTANT',

  //AAO2

  PRAVNO_LICE_ILI_PREDUZETNIK = 'PRAVNO_LICE_ILI_PREDUZETNIK',
  PREDUZECE_PIB_MATICNI = 'PREDUZECE_PIB_MATICNI',

  // GIO2

  LANDFILL_TYPE = 'LANDFILL_TYPE',

  WEEE_PRODUCTS_CATEGORY = 'WEEE_PRODUCTS_CATEGORY',
  // GIO4
  Y_LIST = 'Y_LIST',
  Y_LIST2 = 'Y_LIST2',
  Y_LIST3 = 'Y_LIST3',
  N_LIST = 'N_LIST',
  N_LIST2 = 'N_LIST2',
  N_LIST3 = 'N_LIST3',
  C_LIST = 'C_LIST',
  IA_LIST = 'IA_LIST',
  IIB_LIST = 'IIB_LIST',
  COUNTRY_CODE = 'COUNTRY_CODE',

  // OBRAZAC 5
  WASTE_CHARACTER = 'WASTE_CHARACTER',
  // DKO
  Q_LIST = 'Q_LIST',
  METHOD_OF_PACKAGING_WASTE = 'METHOD_OF_PACKAGING_WASTE',
  WASTE_STATE_CATEGORY = 'WASTE_STATE_CATEGORY',
  TYPE_MEANS_TRANSPORT = 'TYPE_MEANS_TRANSPORT',

  // GVE OBRAZAC 2
  IZVOR_ZAGADJENJA_VAZDUHA = 'IZVOR ZAGADJENJA VAZDUHA',

  LABORATORIES_AIR_WATER = 'LABORATORIES_AIR_WATER',
  APR = 'APR',
  APR_ASSIGNEE_FOUNDER = 'APR_ASSIGNEE_FOUNDER',
  APR_ADDRESS_DATA = 'APR_ADDRESS_DATA',
  PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES',
  PTP2_REPORT_CUSTOMS = 'PTP2_REPORT_CUSTOMS',
  PZV_CUSTOMS_AND_PRODUCERS = 'PZV_CUSTOMS_AND_PRODUCERS',
  PZV_PRODUCTS = 'PZV_PRODUCTS',

  // APR
  APR_COMPANY_STATUS = 'APR_COMPANY_STATUS',
  APR_LEGAL_FORM = 'APR_LEGAL_FORM',
  APR_TYPE_OF_SHAPE_OF_ORGANIZING = 'APR_TYPE_OF_SHAPE_OF_ORGANIZING',
  APR_SIZE_OF_BUSINESS_ENTITY = 'APR_SIZE_OF_BUSINESS_ENTITY',
  APR_DATA_SOURCE = 'APR_DATA_SOURCE',
  APR_TYPE_ADDRESS = 'APR_TYPE_ADDRESS',
  APR_TYPE_ASSIGNEE = 'APR_TYPE_ASSIGNEE',
  APR_TYPE_PERSONALITY = 'APR_TYPE_PERSONALITY',
  APR_TYPE_PERSONALITY_REPRESENTATIV = 'APR_TYPE_PERSONALITY_REPRESENTATIV',
  APR_TYPE_PERSONALITY_OF_LEGAL_ENTITY = 'APR_TYPE_PERSONALITY_OF_LEGAL_ENTITY',
  ASSIGNEE_FUNCTION = 'ASSIGNEE_FUNCTION',
  ACTIVITIES_OF_WASTE_OPERATORS = 'ACTIVITIES_OF_WASTE_OPERATORS',
  PZV_TYPE_OF_PRODUCTS = 'PZV_TYPE_OF_PRODUCTS',
  WASTE_TREATMENT_CODE = 'WASTE_TREATMENT_CODE',
  MEDIUM_PER_ACTIVITY = 'MEDIUM_PER_ACTIVITY',
  MEDIUM_CODE = 'MEDIUM_CODE',
  SECTOR_SPECIFIC_POLLUTANTS = 'SECTOR_SPECIFIC_POLLUTANTS',
  NUTS1_CODE = 'NUTS1_CODE',
  NUTS2_REGION_CODE = 'NUTS2_REGION_CODE',
  NUTS3_AREA_CODE = 'NUTS3_AREA_CODE',
  NUTS_REGION_CODE = 'NUTS_REGION_CODE',

  METHOD_DESIGNATION = 'METHOD_DESIGNATION',
  METHOD_TYPE_CODE = 'METHOD_TYPE_CODE',
  NACE_ACTIVITY_CODE = 'NACE_ACTIVITY_CODE',

  RIVER_BASIN_DISTRICT_CODE = 'RIVER_BASIN_DISTRICT_CODE',
  WASTE_CATALOG_GROUP = 'WASTE_CATALOGUE_GROUP',
  WASTE_CATALOG_SUBGROUP = 'WASTE_CATALOGUE_SUBGROUP',
  WASTE_TYPE_CODE = 'WASTE_TYPE_CODE',
  WASTE_WATER_TREATMENT_METHOD = 'WASTE_WATER_TREATMENT_METHOD',
  WASTE_WATER_TREATMENT_TYPE = 'WASTE_WATER_TREATMENT_TYPE',
  WATER_AREA = 'WATER_AREA',
  WATER_BODY_CATEGORY = 'WATER_BODY_CATEGORY',
  WATER_COURSE = 'WATER_COURSE',
  ANNEX_ACTIVITY_CODE = 'ANNEX_ACTIVITY_CODE',
  COORDINATE_SYSTEM = 'COORDINATE_SYSTEM',
  COMPETENT_AUTHORITY_PARTY = 'COMPETENT_AUTHORITY_PARTY',
  CONFIDENTIALITY = 'CONFIDENTIALITY',
}

export interface ISubmitFormSifarnik {
  state: any;
  postForm: (obj: any) => void;
  putForm: (obj: any) => void;
  deleteForm: (id: string) => void;
}

export interface IPzvTypeOfProducts {
  id: string;
  name: string;
}

export interface ResponsePzvTypeOfProducts extends IPagination {
  items: IPzvTypeOfProducts[];
}

export type ISifarniciComponentHandler = (form: FormInstance) => ISifarniciTypeOfForm[];

export type ISifarniciModalData = {
  title: string;
  width: string;
};

export type ISifarniciFormData = {
  components: ISifarniciComponentHandler;
  initialState: any;
  filtersInitialState?: any;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
};

export interface ISifarniciConfig {
  modal: ISifarniciModalData;
  formData: ISifarniciFormData;
}

export interface ISifarniciObjectProps {
  type_of_form:
    | 'input'
    | 'input_number'
    | 'switch'
    | 'select'
    | 'date'
    | 'area'
    | 'searchScroll'
    | 'year'
    | 'title'
    | 'table';
  hideInput?: boolean;
  filterName?: string;
  title?: string | React.ReactNode;
  sortName?: string;
  inputValue?: any;
  mode?: 'tags' | 'multiple';
}

export interface InputSifarniciFormProps extends FormInputProps, ISifarniciObjectProps {}
export interface SearchScrollSifarniciFormProps extends IFormScrollWithObjProps, ISifarniciObjectProps {}
export interface SelectSifarniciFormProps extends FormSelectProps, ISifarniciObjectProps {}
export interface SwitchSifarniciFormProps extends FormSwitchProps, ISifarniciObjectProps {}
export interface CheckboxSifarniciFormProps extends FormCheckboxProps, ISifarniciObjectProps {}
export interface RadioSifarniciFormProps extends FormRadioProps, ISifarniciObjectProps {}
export interface RangeSifarniciFormProps extends IRangePickerProps, ISifarniciObjectProps {}
export interface DateSifarniciFormProps extends IRangePickerProps, ISifarniciObjectProps {}
export interface TextAreaSifarniciFormProps extends FormTextAreaProps, ISifarniciObjectProps {}
export interface InputNumberSifarniciFormProps extends FormNumberInputProps, ISifarniciObjectProps {}
export interface TableSifarniciFormProps extends FormTableProps<any>, ISifarniciObjectProps {}

export type ISifarniciTypeOfForm =
  | TextAreaSifarniciFormProps
  | InputSifarniciFormProps
  | SelectSifarniciFormProps
  | SearchScrollSifarniciFormProps
  | SwitchSifarniciFormProps
  | CheckboxSifarniciFormProps
  | RadioSifarniciFormProps
  | DateSifarniciFormProps
  | InputNumberSifarniciFormProps
  | RangeSifarniciFormProps
  | TableSifarniciFormProps
  | ISifarniciObjectProps;

export interface IDateArrayPropsData {
  name: string;
  type: string;
}

export interface IFiltersSifarniciPropsData {
  initialState: any;
  components: any;
}
export interface ISifarnikInitial {
  label: string | undefined;
  value: string | undefined;
}

export enum SifarniciLists {
  PRODUCTS_NR = 1,
  PRODUCT_CATEGORIES = 2,
  WEEE_PRODUCTS_CATEGORY = 3,
  PZV_PRODUCTS = 4,
  APR_ASSIGNEE_FOUNDE = 5,
  APR_ADDRESS_DATA = 6,
}

export type ISifarniciFilters =
  | IAktivnost1Filters
  | IAktivnost2Filters
  | ICListFilters
  | IActivitiesOfWasteOperatorsFilters
  | IDepFormAnswersFilters
  | IDRListFilters
  | IWasteStateCategoryFilters
  | IPollutantThresholdFilters
  | IWasteCatalogGroupFilters
  | IPollutantGroupFilters
  | IIaListFilters
  | IIibListFilters
  | IWasteCatalogueFilters
  | IUnitCodeFilters
  | IWasteCharacterFilters
  | IWeeeCategoriesFilters
  | IWaterBodyCategoryFilters
  | IWayOfHandOverFilters
  | IKom1TypeOfWasteFilters
  | ICoordinateSystemFilters
  | ILaboratoriesAirWaterFilters
  | ICountryCodeFilters
  | ISlivFilters
  | IMediumCodeFilters
  | IMestoFilters
  | IMethodTypeCodeFilters
  | IMethodTypesFilters
  | INListFilters
  | IMethodOfPackagingWasteFilters
  | IMethodBasisCodeFilters
  | INuts1CodeFilters
  | INuts2RegionFilters
  | INuts3AreaCodeFilters
  | INutsRegionCodeFilters
  | IOperatorsPackageWasteManageFilters
  | IMethodDesignationFilters
  | IRiverBasinDistrictCodeFilters
  | IWasteTreatmentCodeFilters
  | IWasteTypeCodeFilters
  | ICompetentAuthorityPartyFilters
  | IWasteCatalogSubgroupFilters
  | IConfidentialityFilters
  | IFuelTypeFilters
  | IWaterCourseFilters
  | IWaterAreaFilters
  | IWaterBodiesFilters
  | ISourceTypeFilters
  | IRecipientTypeFilters
  | ITypeOfPackingFilters
  | ILandfillTypeFilters
  | ITypeOfWasteFilters
  | ITypeMeansTransportFilters
  | IPollutantCodeFilters
  | IMunicipalityCodeFilters
  | INaceActivityCodeFilters
  | IYListFilters
  | IAprFilters
  | IPrivrednaDelatnost1Filters
  | IPrivrednaDelatnost2Filters
  | IWasteWaterTreatmentMethodFilters
  | IWasteWaterTreatmentTypeFilters
  | IPtp2ReportCustomsFilters
  | IProductsNrFilters
  | IPzvCustomsAndProducersFilters
  | IProductsPzvFilters
  | IPzvTypeOfProductsFilters
  | IQListFilters
  | IWorkingRegimeFilters
  | ICompanyWithSelfManagmentFilters;
