import { ColProps, Form, Modal, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { modalStore } from 'store';
import { ModalFormComponent } from '../ModalFormComponent/ModalFormComponent';
import { StoreValue } from 'antd/es/form/interface';
import { Button } from 'components/Button/Button';
import styles from './FormTableModal.module.scss';
import { FormListConfig, FormListType } from 'typescript/NrizTypes';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { nrizService } from 'modules/nriz/nriz.service';

interface FormTableModalProps<T> {
  name: string;
  addRow: (defaultValue?: StoreValue, insertIndex?: number) => void;
  removeRow: (index: number | number[]) => void;
  editRow?: (values: T) => void;
  selectedIndex: number;
  configPath: FormListConfig;
  modalWidth: number | string;
  somethingThatWillUseThisTypeSomeday?: T;
  title?: string | React.ReactNode;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  initialValues?: any;
}

export const FormTableModal = observer(
  // eslint-disable-next-line
  <T extends object>({
    removeRow,
    configPath,
    name,
    selectedIndex,
    addRow,
    editRow,
    modalWidth,
    title,
    labelCol,
    wrapperCol,
    initialValues,
  }: FormTableModalProps<T>) => {
    const { t } = useTranslation();
    const form = Form.useFormInstance();

    const [itemForm] = Form.useForm();

    const formList = form.getFieldValue([name]) ?? [];

    const isCreateMode = selectedIndex === formList.length;

    useEffect(() => {
      if (selectedIndex < formList.length) {
        itemForm.setFieldsValue(formList[selectedIndex]);
      } else {
        itemForm.resetFields();
      }
    }, [selectedIndex, formList, itemForm]);

    const handleEdit = (values: T) => {
      editRow?.(values);
      form.setFieldValue([name, selectedIndex], values);
    };

    const handleFormItemSubmit = () => {
      const values = { ...nrizService.setEmptyValuesToNull(itemForm.getFieldsValue(true)), key: selectedIndex };
      isCreateMode ? addRow(values) : handleEdit(values);
      modalStore.clearModal(`${name}-modal`);
    };

    const handleDelete = () => {
      removeRow(selectedIndex);
      modalStore.clearModal(`${name}-modal`);
    };

    return (
      <Modal
        open={modalStore.getCurrentOpenedModal.includes(`${name}-modal`)}
        destroyOnClose
        onCancel={() => modalStore.clearModal(`${name}-modal`)}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={modalWidth}
        className={styles.formModal}
        title={title}
        modalRender={formChildren => {
          //ERROR:  FormTableModal.tsx:46 Warning: Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop?
          return (
            <Form
              form={itemForm}
              name={`${name}-form-in-modal`}
              onFinish={handleFormItemSubmit}
              // preserve={false}
              initialValues={initialValues}
              labelAlign="left"
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              {formChildren}
            </Form>
          );
        }}
      >
        <ModalFormComponent<T>
          configPath={configPath}
          formListName={name}
          parentForm={form}
          selectedIndex={selectedIndex}
        />
        <Row className={styles.formTableButtons}>
          {!isCreateMode && configPath.formListType !== FormListType.Sifarnici && (
            <Popconfirm
              title={'Da li ste sigurni da želite da obrišete ?'}
              okText={t('Da')}
              cancelText={t('Odbaci')}
              onConfirm={handleDelete}
              placement="top"
              name={'delete-button'}
            >
              <Button name={`${name}-modal-delete-button`} className="ant-btn-dangerous">
                {t('DELETE')}
              </Button>
            </Popconfirm>
          )}
          <Button name={`${name}-modal-submit-button`} htmlType="submit">
            {isCreateMode ? 'Sačuvaj' : 'Izmeni'}
          </Button>
        </Row>
      </Modal>
    );
  }
);
