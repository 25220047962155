import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  NAME_FORM,
  DESCRIPTION_FORM,
  DESCRIPTION_SR_FORM,
  APPROVED_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
  APPROVED_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostIibList, IGetIibList, IIibListFilters } from './iibList.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const IIB_LIST_INITIAL_STATE: IPostIibList = {
  name: '',
  description: '',
  description_sr: '',
  approved: false,
  is_active: true,
};
export const IIB_LIST_FILTERS_INITIAL_STATE: IIibListFilters = {
  name__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
  approved: null,
  is_active: null,
};
export const IIB_LIST_TABLE_DATA: ColumnsType<IGetIibList> = [
  NAME_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
  APPROVED_TABLE,
];
export const IIB_LIST_FORM_OPTIONS = (_: FormInstance<IGetIibList>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(300), DESCRIPTION_FORM(600), DESCRIPTION_SR_FORM(600), APPROVED_FORM];
};
