import { ApiResponse, axios } from 'modules/axios';
import { IPravnoLiceIliPreduzetnik } from './aao2.types';
import queryString from 'query-string';

const fetchOldPravnaLicaList = (payload: {
  godina: number;
  preduzece_id: string;
}): ApiResponse<IPravnoLiceIliPreduzetnik[]> => {
  const query = queryString.stringify(payload);
  return axios.get(`obrazac/aao2/pravno-lice-ili-preduzetnik/?${query}`);
};

export const aao2Repo = {
  fetchOldPravnaLicaList,
};
