import i18n from 'translations/i18n.config';
import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import { FormInputProps } from 'typescript';
import { IFilterObjectProps } from 'typescript/NrizTypes';
import { IPostUser, IPostUserResponsibility, IUserFilters } from './user.types';

export const userInitialState: IPostUser = {
  username: '',
  ime: '',
  prezime: '',
  email: '',
  broj_telefon: '',
  jezik: '',
  function_of_person: '',
  alternativni_telefon: '',
  company_user: false,
  facility_user: false,
  postrojenje_id_list: [],
};

export const USER_COMPANY_RESPONSIBILITY_INITIAL_STATE: IPostUserResponsibility = {
  odgovorna_osoba_preduzeca: false,
  odgovorna_osoba_za_izvestavanje: false,
  odgovorna_osoba_za_saradnju_sa_agencijom: false,
};

export const USER_FILTERS_INITIAL_STATE: IUserFilters = {
  sistem_administrator: null,
  ime: '',
  prezime: '',
  username: '',
  ime__ilike: '',
  prezime__ilike: '',
  username__ilike: '',
  preduzece__pib__ilike: '',
  preduzece__maticni_broj__ilike: '',
  preduzece__naziv__ilike: '',
  order_by: '',
  is_active: null,
};

export const USER_FILTERS_OPTIONS = (hideInput: boolean) => [
  {
    type_of_form: 'input',
    inputName: !hideInput ? 'username__ilike' : 'username',
    name: !hideInput ? 'username__ilike' : 'username',
    label: 'Korisničko ime',
    sortName: 'username',
  },
  {
    type_of_form: 'input',
    inputName: !hideInput ? 'ime__ilike' : 'ime',
    name: !hideInput ? 'ime__ilike' : 'ime',
    label: 'Ime korisnika',
    sortName: 'ime',
  },
  {
    type_of_form: 'input',
    inputName: !hideInput ? 'prezime__ilike' : 'prezime',
    name: !hideInput ? 'prezime__ilike' : 'prezime',
    label: 'Prezime korisnika',
    sortName: 'prezime',
  },

  {
    label: i18n.t('COMPANY.NAME'),
    name: 'preduzece__naziv__ilike',
    type_of_form: 'input',
    inputName: 'preduzece__naziv__ilike',
    hideInput: !hideInput,
  } as FormInputProps & IFilterObjectProps,
  {
    label: `${i18n.t('COMPANY.PIB')} preduzeća`,
    name: 'preduzece__pib__ilike',
    type_of_form: 'input',
    inputName: 'preduzece__pib__ilike',
    hideInput: !hideInput,
  } as FormInputProps & IFilterObjectProps,
  {
    label: i18n.t('COMPANY.ENTERPRISE_ID_NUMBER'),
    name: 'preduzece__maticni_broj__ilike',
    type_of_form: 'input',
    inputName: 'preduzece__maticni_broj__ilike',
    hideInput: !hideInput,
  } as FormInputProps & IFilterObjectProps,
  {
    type_of_form: 'switch',
    label: 'Lista administratora',
    name: 'sistem_administrator',
    hideInput: !hideInput,
  },
  // {
  //   type_of_form: 'switch',
  //   label: 'Korisnik preduzeća',
  //   name: 'company_user',
  // },
  // {
  //   type_of_form: 'switch',
  //   label: 'Korisnik postrojenja',
  //   name: 'facility_user',
  // },
  IS_ACTIVE_SWITCH,
];
