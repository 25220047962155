import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { modalStore } from 'store';
import styles from './FacilityUsersModalTable.module.scss';
import { nrizService } from 'modules/nriz/nriz.service';
import { CheckOutlined } from '@ant-design/icons';
import { IGetUser } from 'modules/user/user.types';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { useForm } from 'antd/es/form/Form';
import { IFilterTableProps } from 'typescript/NrizTypes';
import { userStore } from 'modules/user/user.store';
import { useEffect } from 'react';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { USER_FILTERS_INITIAL_STATE } from 'modules/user/user.constants';
import { useTranslation } from 'react-i18next';

export const FacilityUsersModalTable: React.FC = observer(() => {
  const { t } = useTranslation();
  const columns: ColumnsType<IGetUser> = [
    {
      title: `${t('USER.USERNAME')}`,
      width: '200px',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: `${t('USER.NAME')}`,
      width: '150px',
      dataIndex: 'ime',
      key: 'ime',
    },
    {
      title: `${t('USER.SURNAME')}`,
      width: '150px',
      dataIndex: 'prezime',
      key: 'prezime',
    },
    {
      title: `${t('USER.ADD')}`,
      key: 'id',
      width: '100px',
      fixed: 'right',
      align: 'center',
      render: (_: any, record: IGetUser) => {
        const isFacilityUser = nrizService.isItemInList(userStore.getFacilityUserList, record.id);
        return isFacilityUser ? (
          <CheckOutlined />
        ) : (
          <Button
            name="add-user-to-facility"
            onClick={() => {
              userStore.postUserFacility(record);
              modalStore.removeAllModals();
            }}
          >
            {t('USER.ADD_BUTTON')}
          </Button>
        );
      },
    },
  ];

  const [userForm] = useForm();
  const fetchUserList = () => {
    return userStore.fetchUserCompanyList(paginationInitialState);
  };

  const data = {
    form: userForm,
    initialState: filtersStore.getFilters[FiltersNameEnum.KORISNIK],
    filterStoreName: FiltersNameEnum.KORISNIK,
    filterOptions: [
      {
        type_of_form: 'input',
        inputName: 'username__ilike',
        name: 'username__ilike',
        label: 'Korisničko ime',
        sortName: 'username',
      },
      {
        type_of_form: 'input',
        inputName: 'ime__ilike',
        name: 'ime__ilike',
        label: 'Ime korisnika',
        sortName: 'ime',
      },
      {
        type_of_form: 'input',
        inputName: 'prezime__ilike',
        name: 'prezime__ilike',
        label: 'Prezime korisnika',
        sortName: 'prezime',
      },
    ],
    fetchTableList: fetchUserList,
  } as IFilterTableProps;

  useEffect(() => {
    userStore.fetchUserCompanyList();
  }, []);

  useEffect(() => {
    return () => filtersStore.setFilters(FiltersNameEnum.KORISNIK, USER_FILTERS_INITIAL_STATE);
  }, []);

  return (
    <div className={styles.modalDiv}>
      <FilterTableForm data={data} />
      <TableData
        name="facitiy-user"
        columns={columns}
        dataSource={[...userStore.getUserList]}
        refetchData={userStore.fetchUserCompanyList}
        storePagination={userStore.userListPagination}
      />
    </div>
  );
});
