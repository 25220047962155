import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3MerenjeOtpadneVode } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditObrazac3AnalizaOtpadneVodeMerenjeButton: React.FC<EditButtonProps<IGetObrazac3MerenjeOtpadneVode>> = observer(
  ({ record }) => {
    return (
      <RowEditPreviewButton
        name="obrazac3-analiza-otpadne-vode-merenje"
        modalName="obrazac3-analiza-otpadne-vode-merenje-modal"
        onClick={() => {
          obrazac3Store.handleChange('otpadne_vode_merenje_form', {
            ...record,
            datum_merenja: dayjs(record.datum_merenja),
          });
        }}
        preview={obrasciStore.zakljucanObrazac}
      />
    );
  }
);

export const Obrazac3AnalizaOtpadneVodeMerenjeTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3MerenjeOtpadneVode> = [
    {
      title: `${t('OBRASCI.GVE.REDNI_BROJ_MERENJA')}`,
      width: 100,
      render: (_, _record, i) => i + 1,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.DATUM_MERENJA')}`,
      width: 200,
      // dataIndex: 'datum_merenja',
      render: (_: any, record: IGetObrazac3MerenjeOtpadneVode) => {
        const datum = dayjs(record.datum_merenja).format('DD-MM-YYYY');
        return <span>{datum}</span>;
      },
      key: 'datum_merenja',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.IDENTIFIKACIONI_BROJ_IZVESTAJA')}`,
      width: 200,
      dataIndex: 'indetifikacioni_broj_izvestaja',
      key: 'indetifikacioni_broj_izvestaja',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_LABORATORIJE')}`,
      dataIndex: ['naziv_strucne_laboratorije', 'name_of_laboratory'],
      key: 'naziv_strucne_laboratorije_id',
      width: 200,
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetObrazac3MerenjeOtpadneVode) => (
        <EditObrazac3AnalizaOtpadneVodeMerenjeButton record={record} />
      ),
    },
  ];
  return (
    <TableData
      name="analiza-otpadne-vode-merenje-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.initialState.merenja_otpadne_vode].sort(
        (a, b) => (new Date(a.datum_merenja) as any) - (new Date(b.datum_merenja) as any)
      )}
    />
  );
  // resi store
});
