import { useEffect, useState } from 'react';
import { Col, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormInput } from 'components/FormInput/FormInput';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import {
  LATITUDE_RULE,
  LONGITUDE_RULE,
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { authStore, formStore } from 'store';
import { IFormProps } from 'typescript/NrizTypes';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { airInitialState } from 'modules/sourcesOfPollution/air/air.constants';
import { airStore } from 'modules/sourcesOfPollution/air/air.store';

export const NewAirPollutionForm: React.FC<IFormProps> = observer(props => {
  const [lockKoordinates, setLockKoordinates] = useState<boolean>(airStore.air.zakljucane_koordinate);

  const [instalisana, setInstalisana] = useState((airStore.air as IGetAir).source_type?.name_sr);

  const [form] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return airStore.submitAir(newValues as any);
  };

  useEffect(() => {
    return () => setLockKoordinates(false);
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...airStore.getAir });
  }, [airStore.getAir]);

  // useEffect(() => {}, [Form.useWatch('')]);

  const defaultVrstaIzvora: ISifarnikInitial | undefined = airStore.air?.source_type_id
    ? {
        label: (airStore.getAir as IGetAir)?.source_type?.name_sr,
        value: (airStore.getAir as IGetAir)?.source_type_id,
      }
    : undefined;

  return (
    <Form
      form={form}
      initialValues={airInitialState}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 13 }}
      labelAlign="left"
      name={props.formName}
      onFinish={values => airStore.submitAir(values)}
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          onFinishFailed();
        }
      }}
    >
      <FormInput
        label={t('POLLUTION.AIR.SOURCE_NUMBER')}
        name="broj_izvora"
        // type="number"
        inputName={'broj_izvora'}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50)]}
        disabled={!!(airStore.air.broj_izvora !== '' && !authStore.isAdmin)}
        precision={0}
      />
      <FormInput
        label={t('POLLUTION.AIR.SOURCE_NAME')}
        name="naziv_izvora"
        disabled={!!(airStore.air.naziv_izvora !== '' && !authStore.isAdmin)}
        inputName={'naziv_izvora'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(300)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'source_type_id'}
        fetchOptinsList={airStore.fetchTypeOfSource}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('POLLUTION.AIR.SOURCE_TYPE')}
        readOnly={false}
        inputName={'source_type_id'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        defaultOption={defaultVrstaIzvora}
        placeholder={t('POLLUTION.AIR.SOURCE_TYPE')}
        onChange={(_, option) => {
          if (option) setInstalisana(option.item.name_sr);
          else setInstalisana('');
        }}
      />

      <FormCheckbox
        name="zakljucane_koordinate"
        label={t('POLLUTION.AIR.COORDINATE_CONCLUSION')}
        onCheckBoxChange={(e: any) => setLockKoordinates(e.target.checked)}
        disabled={!authStore.isAdmin}
      />

      <FormInput
        type="number"
        disabled={lockKoordinates}
        label={t('COMPANY.LONGITUDE')}
        name="koordinate_long"
        inputName={'koordinate_long'}
        rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
      />
      <FormInput
        type="number"
        disabled={lockKoordinates}
        label={t('COMPANY.LATITUDE')}
        name="koordinate_lat"
        inputName={'koordinate_lat'}
        rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
      />
      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>

      <Col span={24} hidden={!authStore.isAdmin}>
        <Form.Item name="obaveza_merenja" label={t('POLLUTION.AIR.OBLIGATION_TO_MEASURE')}>
          <Select>
            <Select.Option value="da">{t('YES')}</Select.Option>
            <Select.Option value="ne">{t('NO')}</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <FormInputNumber label={t('POLLUTION.AIR.ELEVATION')} name="nadmorska_visina" inputName={'nadmorska_visina'} />
      <FormInputNumber
        label={t('POLLUTION.AIR.INTALLED_HEAT_POWER_AT_ENTRANCE')}
        name="instalisana_toplotna_snaga_na_ulazu"
        inputName={'instalisana_toplotna_snaga_na_ulazu'}
        rules={[REQUIRED_FIELD_RULE(instalisana === 'Energetski')]}
      />
      <FormInputNumber
        label={t('POLLUTION.AIR.SOURCE_HEIGHT')}
        name="visina_izvora"
        inputName={'visina_izvora'}
        rules={MORE_THEN_0}
      />
      <FormInputNumber
        label={t('POLLUTION.AIR.INNER_DIAMETER_OF_SOURCE_ON_TOP')}
        rules={MORE_THEN_0}
        name="unutrasnji_precnik_izvora_na_vrhu"
        inputName={'unutrasnji_precnik_izvora_na_vrhu'}
      />

      <FormTextArea
        name={'napomena'}
        rows={2}
        labelCol={11}
        wrapperCol={13}
        label={t('COMPANY.NOTE')}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
      {(airStore.getAir.aktivan && (airStore.getAir as IGetAir).id) || !(airStore.getAir as IGetAir).id ? (
        <CRUDButton showButton={!!(airStore.air as IGetAir).id} form={form} withOutDelete={true} />
      ) : undefined}
    </Form>
  );
});
