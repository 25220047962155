import i18n from 'translations/i18n.config';
import { obrasciStore } from './obrasci.store';
import { FormInstance } from 'antd';
import {
  DateSifarniciFormProps,
  ISifarniciFormData,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { TIPOVI_OBRAZACA } from './obrasci.types';
import { authStore } from 'store';
import {
  IBaseFilterDataObrasci,
  IBaseWithGodinaFilterDataObrasci,
  IDeo6Gio6FilterDataObrasci,
  IDep1FilterDataObrasci,
  IDkoFilterDataObrasci,
  IGio1FilterDataObrasci,
  IGveFilterDataObrasci,
  ILriz2GveFilterDataObrasci,
  ILriz3OtvFilterDataObrasci,
  ILriz5FilterDataObrasci,
  ILriz5Gio1FilterDataObrasci,
  IObrazac1FilterDataObrasci,
  IObrazac2FilterDataObrasci,
  IObrazac2GveFilterDataObrasci,
  IObrazac3FilterDataObrasci,
  IObrazac3OtvFilterDataObrasci,
  IObrazac4FilterDataObrasci,
  IObrazac5FilterDataObrasci,
  IOtvFilterDataObrasci,
} from './filterDataObrasci.types';

const formFieldNamesConnectedByPreduzece = [
  'postrojenje_id',
  'nesanitarna_deponija_id',
  'izvor_zagadjenja_vazduha_id',
  'izvor_zagadjenja_vode_id',
  'izvor_zagadjenja_tla_id',
  'indeksni_broj_otpada_id',
];

const PREDUZECE_FILTER_OBRAZAC = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    label: i18n.t('COMPANY.TITLE'),
    formName: 'preduzece_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    filtersForFetch: {
      tip_obrasca_id: obrasciStore.real_tip_obrasca_id,
    },
    readOnly: false,
    resetOption: obrasciStore.real_obrazac_type,
    sortName: 'naziv_preduzeca',
    onChange: value => {
      formFieldNamesConnectedByPreduzece.forEach(field => form.setFieldValue(field, ''));
      obrasciStore.handleOpste('preduzece_id', value);
      form.resetFields(['postrojenje_id', 'nesanitarna_deponija_id']);
    },
    hideInput: !authStore.isAdmin,
  };
};

const POSTROJENJE_FILTER_OBRAZAC = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      tip_obrasca_id: obrasciStore.real_tip_obrasca_id,
      preduzece_id: authStore.isAdmin ? form.getFieldValue('preduzece_id') : obrasciStore.opste.preduzece_id,
    },
    onChange: () => {
      form.resetFields(['izvor_zagadjenja_vazduha_id', 'izvor_zagadjenja_vode_id', 'izvor_zagadjenja_tla_id']);
    },
    formName: 'postrojenje_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE,
    labelOptionAccessor: ['naziv_postrojenja'],
    valueOptionAccessor: 'id',
    resetOption: obrasciStore.opste.preduzece_id,
    label: i18n.t('FACILITY.NAME'),
    sortName: 'naziv_postrojenja',
    hideInput: !(
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type) &&
      authStore.isAdmin
    ),
  };
};

const NACIONALNI_ID_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: `${i18n.t('FACILITY.NACIONAL_ID')} postrojenja`,
    name: 'postrojenje__nacionalni_id__ilike',
    inputName: 'postrojenje__nacionalni_id__ilike',
    sortName: 'nacionalni_id',
    hideInput: !(
      authStore.isAdmin &&
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type)
    ),
  };
};

const ISTORIJSKI_ID_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: `${i18n.t('FACILITY.HISTORICAL_ID')} postrojenja`,
    name: 'postrojenje__istorijski_id__ilike',
    inputName: 'postrojenje__istorijski_id__ilike',
    sortName: 'istorijski_id',
    hideInput: !(
      authStore.isAdmin &&
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type)
    ),
  };
};

const NESANITARNA_DEPONIJA_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      preduzece_id: authStore.isAdmin ? form.getFieldValue('preduzece_id') : obrasciStore.opste.preduzece_id,
    },
    formName: 'nesanitarna_deponija_id',
    fetchOptinsList: SIFARNICI_ROUTES.NESANITARNA_DEPONIJA,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    resetOption: obrasciStore.opste.preduzece_id,
    label: `${i18n.t('UNSANITARY_LANDFILL.NAME')} nesanitarne deponije`,
    sortName: 'naziv_nesanitarne_deponije',
  };
};

const IZVOR_VAZDUH_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: authStore.isAdmin ? form.getFieldValue('postrojenje_id') : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_vazduha_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA,
    labelOptionAccessor: ['naziv_izvora'],
    valueOptionAccessor: 'id',
    resetOption: obrasciStore.opste.postrojenje_id || obrasciStore.opste.preduzece_id,
    label: i18n.t('OBRASCI.GVE.IZVOR_ZAGADJIVANJA'),
    sortName: 'izvor_zagadjenja_vazduha',
  };
};

const BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: i18n.t('POLLUTION.AIR.SOURCE_NUMBER'),
  name: 'izvor_zagadjenja_vazduha__broj_izvora__ilike',
  inputName: 'izvor_zagadjenja_vazduha__broj_izvora__ilike',
  sortName: 'izvor_zagadjenja_vazduha__broj_izvora',
};

const IZVOR_VODA_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: authStore.isAdmin ? form.getFieldValue('postrojenje_id') : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_vode_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
    labelOptionAccessor: ['naziv_ispusta'],
    valueOptionAccessor: 'id',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: i18n.t('POLLUTION.WATER.TITLE'),
    sortName: 'izvor_zagadjenja_vode',
  };
};

const BROJ_ISPUST_VODA_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: i18n.t('POLLUTION.WATER.SEWER_NUMBER'),
  name: 'izvor_zagadjenja_vode__broj_ispusta__ilike',
  inputName: 'izvor_zagadjenja_vode__broj_ispusta__ilike',
  sortName: 'izvor_zagadjenja_vode__broj_ispusta__ilike',
};

const IZVOR_TLO_FILTER_DATA = (form: FormInstance<any>): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: authStore.isAdmin ? form.getFieldValue('postrojenje_id') : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_tla_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_TLA,
    labelOptionAccessor: ['naziv_lokacije'],
    valueOptionAccessor: 'id',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: i18n.t('POLLUTION.SOIL.TITLE'),
    sortName: 'izvor_zagadjenja_tla',
  };
};

const BROJ_LOKACIJE_TLO_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: i18n.t('POLLUTION.SOIL.LOCATION_NUMBER'),
  name: 'izvor_zagadjenja_tla__broj_lokacije__ilike',
  inputName: 'izvor_zagadjenja_tla__broj_lokacije__ilike',
  sortName: 'izvor_zagadjenja_tla__broj_lokacije__ilike',
};

const BROJ_DOKUMENTA_FILTER_OBRAZAC: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Broj dokumenta',
  name: 'broj_dokumenta__ilike',
  inputName: 'broj_dokumenta__ilike',
  sortName: 'broj_dokumenta__ilike',
};

const INDEX_NUMBER_FILTER_DATA: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: i18n.t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
  sortName: 'indeksni_broj_otpada',
  placeholder: '',
};

const DATUM_FILTER_DATA: DateSifarniciFormProps = {
  type_of_form: 'date',
  label: 'Datum',
  name: 'datum',
  format: 'DD-MM-YYYY',
  picker: 'date',
  sortName: 'datum',
};

const DATUM_PREDAJE_OTPADA_FILTER_DATA: DateSifarniciFormProps = {
  type_of_form: 'date',
  label: 'Datum',
  name: 'datum_predaje_otpada',
  format: 'DD-MM-YYYY',
  picker: 'date',
  sortName: 'datum_predaje_otpada',
};

const GODINA_FILTER_DATA: DateSifarniciFormProps = {
  type_of_form: 'date',
  label: 'Godina',
  name: 'godina',
  format: 'YYYY',
  picker: 'year',
  sortName: 'godina',
};

const PIB_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: i18n.t('COMPANY.PIB'),
    name: 'preduzece__pib__ilike',
    inputName: 'preduzece__pib__ilike',
    sortName: 'pib',
    hideInput: !authStore.isAdmin,
  };
};

const MATICNI_BROJ_FILTER_DATA = (): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: i18n.t('COMPANY.ENTERPRISE_ID_NUMBER'),
    name: 'preduzece__maticni_broj__ilike',
    inputName: 'preduzece__maticni_broj__ilike',
    sortName: 'maticni_broj',
    hideInput: !authStore.isAdmin,
  };
};

const BASE_FILTER_DATA = (form: FormInstance<any>): ISifarniciTypeOfForm[] => {
  const newArray: ISifarniciTypeOfForm[] = [];

  if (obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3) {
    newArray.push(
      PREDUZECE_FILTER_OBRAZAC(form),
      PIB_FILTER_DATA(),
      POSTROJENJE_FILTER_OBRAZAC(form),
      NACIONALNI_ID_FILTER_DATA()
    );
  } else {
    newArray.push(
      PREDUZECE_FILTER_OBRAZAC(form),
      PIB_FILTER_DATA(),
      MATICNI_BROJ_FILTER_DATA(),
      POSTROJENJE_FILTER_OBRAZAC(form),
      NACIONALNI_ID_FILTER_DATA()
    );
  }

  return newArray;
};

const BASE_WITH_GODINA_FILTER_DATA = (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => [
  GODINA_FILTER_DATA,
  ...BASE_FILTER_DATA(form),
];

const GVE_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: TIPOVI_OBRAZACA.GVE,
  name: 'obrazac_gve',
};

const OBRAZAC_2_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: i18n.t('Obrazac 2'),
  name: 'obrazac_2',
};

const LRIZ_OBRAZAC_2_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: i18n.t('Lriz obrazac 2'),
  name: 'lriz_obrazac_2',
};

const OTV_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: TIPOVI_OBRAZACA.OTV,
  name: 'otv',
};

const OBRAZAC_3_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: i18n.t('Obrazac 3'),
  name: 'obrazac3',
};

const LRIZ_OBRAZAC_3_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: i18n.t('Lriz obrazac 3'),
  name: 'obrazac3_lriz',
};

const LRIZ_OBRAZAC_5_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: i18n.t('Lriz obrazac 5'),
  name: 'lriz_obrazac_5',
};

const GIO_1_FILTER_DATA: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  label: i18n.t('Gio 1'),
  name: 'obrazac_gio1',
};

//#region BASE
const BASE_INITIAL_STATE: IBaseFilterDataObrasci = {
  preduzece_id: '',
  postrojenje_id: '',
  preduzece__pib__ilike: '',
  preduzece: null,
  postrojenje: null,
  preduzece__maticni_broj__ilike: '',
  postrojenje__nacionalni_id__ilike: '',
};

const BASE_WITH_GODINA_INITIAL_STATE: IBaseWithGodinaFilterDataObrasci = {
  ...BASE_INITIAL_STATE,
  godina: '',
};
//#endregion BASE

//#region DKO
const DKO_FILTER_DATA_INITIAL_STATE: IDkoFilterDataObrasci = {
  ...BASE_INITIAL_STATE,
  datum_predaje_otpada: '',
  indeksni_broj_otpada_id: '',
  broj_dokumenta__ilike: '',
};

const DKO_FILTER_OPTIONS = (form: FormInstance<IDkoFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  BROJ_DOKUMENTA_FILTER_OBRAZAC,
  DATUM_PREDAJE_OTPADA_FILTER_DATA,
  ...BASE_FILTER_DATA(form),
  INDEX_NUMBER_FILTER_DATA,
];
//#endregion DKO

//#region DEO6/GIO6
const DEO6_GIO6_FILTER_DATA_INITIAL_STATE: IDeo6Gio6FilterDataObrasci = {
  ...BASE_INITIAL_STATE,
  datum: '',
};

const DEO6_GIO6_FILTER_OPTIONS = (form: FormInstance<IDeo6Gio6FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  DATUM_FILTER_DATA,
  ...BASE_FILTER_DATA(form),
];
//#endregion DEO6/GIO6

//#region OBRAZAC 1
const OBRAZAC_1_FILTER_DATA_INITIAL_STATE: IObrazac1FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  postrojenje__istorijski_id__ilike: '',
};

const OBRAZAC_1_FILTER_OPTIONS = (form: FormInstance<IObrazac1FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
];
//#endregion OBRAZAC 1

//#region OBRAZAC 2
const OBRAZAC_2_FILTER_DATA_INITIAL_STATE: IObrazac2FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  postrojenje__istorijski_id__ilike: '',
  izvor_zagadjenja_vazduha_id: '',
  izvor_zagadjenja_vazduha__broj_izvora__ilike: '',
  obrazac_gve: '',
};

const OBRAZAC_2_FILTER_OPTIONS = (form: FormInstance<IObrazac2FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  GODINA_FILTER_DATA,
  ...BASE_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
  IZVOR_VAZDUH_FILTER_DATA(form),
  BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
  GVE_FILTER_DATA,
];
//#endregion OBRAZAC 2

//#region OBRAZAC 2 GVE
const OBRAZAC_2_GVE_FILTER_DATA_INITIAL_STATE: IObrazac2GveFilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vazduha_id: '',
  izvor_zagadjenja_vazduha__broj_izvora__ilike: '',
  obrazac_gve: '',
  obrazac_2: '',
};

const OBRAZAC_2_GVE_FILTER_OPTIONS = (form: FormInstance<IObrazac2GveFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  IZVOR_VAZDUH_FILTER_DATA(form),
  BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
  GVE_FILTER_DATA,
  OBRAZAC_2_FILTER_DATA,
];
//#endregion OBRAZAC 2 GVE

//#region LRIZ 2 GVE
const LRIZ_OBRAZAC_2_GVE_FILTER_DATA_INITIAL_STATE: ILriz2GveFilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vazduha_id: '',
  izvor_zagadjenja_vazduha__broj_izvora__ilike: '',
  obrazac_gve: '',
  lriz_obrazac_2: '',
};

const LRIZ_OBRAZAC_2_GVE_FILTER_OPTIONS = (form: FormInstance<ILriz2GveFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  IZVOR_VAZDUH_FILTER_DATA(form),
  BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
  GVE_FILTER_DATA,
  LRIZ_OBRAZAC_2_FILTER_DATA,
];
//#endregion LRIZ 2 GVE

//#region GVE

const GVE_FILTER_DATA_INITIAL_STATE: IGveFilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vazduha_id: '',
  izvor_zagadjenja_vazduha__broj_izvora__ilike: '',
  lriz_obrazac_2: '',
  obrazac_2: '',
};

const GVE_FILTER_OPTIONS = (form: FormInstance<IGveFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  IZVOR_VAZDUH_FILTER_DATA(form),
  BROJ_IZVOR_VAZDUH_FILTER_OBRAZAC,
  OBRAZAC_2_FILTER_DATA,
  LRIZ_OBRAZAC_2_FILTER_DATA,
];
//#endregion GVE
//#region OBRAZAC 3

const OBRAZAC_3_FILTER_DATA_INITIAL_STATE: IObrazac3FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vode_id: '',
  izvor_zagadjenja_vode__broj_ispusta__ilike: '',
  otv: '',
  postrojenje__istorijski_id__ilike: '',
};

const OBRAZAC_3_FILTER_OPTIONS = (form: FormInstance<IObrazac3FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  GODINA_FILTER_DATA,
  ...BASE_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
  IZVOR_VODA_FILTER_DATA(form),
  BROJ_ISPUST_VODA_FILTER_OBRAZAC,
  OTV_FILTER_DATA,
];
//#endregion OBRAZAC 3
//#region OBRAZAC 3 OTV
const OBRAZAC_3_OTV_FILTER_DATA_INITIAL_STATE: IObrazac3OtvFilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vode_id: '',
  izvor_zagadjenja_vode__broj_ispusta__ilike: '',
  otv: '',
  obrazac3: '',
};

const OBRAZAC_3_OTV_FILTER_OPTIONS = (form: FormInstance<IObrazac3OtvFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  IZVOR_VODA_FILTER_DATA(form),
  BROJ_ISPUST_VODA_FILTER_OBRAZAC,
  OTV_FILTER_DATA,
  OBRAZAC_3_FILTER_DATA,
];
//#endregion OBRAZAC 3 OTV
//#region LRIZ 3 OTV
const LRIZ_OBRAZAC_3_OTV_FILTER_DATA_INITIAL_STATE: ILriz3OtvFilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vode_id: '',
  izvor_zagadjenja_vode__broj_ispusta__ilike: '',
  otv: '',
  obrazac3_lriz: '',
};

const LRIZ_OBRAZAC_3_OTV_FILTER_OPTIONS = (form: FormInstance<ILriz3OtvFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  IZVOR_VODA_FILTER_DATA(form),
  BROJ_ISPUST_VODA_FILTER_OBRAZAC,
  OTV_FILTER_DATA,
  LRIZ_OBRAZAC_3_FILTER_DATA,
];
//#endregion LRIZ 3 OTV

//#region OTV
const OTV_FILTER_DATA_INITIAL_STATE: IOtvFilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_vode_id: '',
  izvor_zagadjenja_vode__broj_ispusta__ilike: '',
  obrazac3_lriz: '',
  obrazac3: '',
};

const OTV_FILTER_OPTIONS = (form: FormInstance<IOtvFilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  IZVOR_VODA_FILTER_DATA(form),
  BROJ_ISPUST_VODA_FILTER_OBRAZAC,
  OBRAZAC_3_FILTER_DATA,
  LRIZ_OBRAZAC_3_FILTER_DATA,
];
//#endregion OTV

//#region OBRAZAC 4
const OBRAZAC_4_FILTER_DATA_INITIAL_STATE: IObrazac4FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  izvor_zagadjenja_tla_id: '',
  izvor_zagadjenja_tla__broj_lokacije__ilike: '',
  postrojenje__istorijski_id__ilike: '',
};

const OBRAZAC_4_FILTER_OPTIONS = (form: FormInstance<IObrazac4FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
  IZVOR_TLO_FILTER_DATA(form),
  BROJ_LOKACIJE_TLO_FILTER_OBRAZAC,
];
//#endregion OBRAZAC 4
//#region OBRAZAC 5
const OBRAZAC_5_FILTER_DATA_INITIAL_STATE: IObrazac5FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  postrojenje__istorijski_id__ilike: '',
  indeksni_broj_otpada_id: '',
};

const OBRAZAC_5_FILTER_OPTIONS = (form: FormInstance<IObrazac5FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
  INDEX_NUMBER_FILTER_DATA,
];
//#endregion OBRAZAC 5
//#region LRIZ 5
const LRIZ_OBRAZAC_5_FILTER_DATA_INITIAL_STATE: ILriz5FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  postrojenje__istorijski_id__ilike: '',
  indeksni_broj_otpada_id: '',
  obrazac_gio1: '',
};

const LRIZ_OBRAZAC_5_FILTER_OPTIONS = (form: FormInstance<ILriz5FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
  INDEX_NUMBER_FILTER_DATA,
  GIO_1_FILTER_DATA,
];
//#endregion LRIZ 5
//#region LRIZ 5 GIO 1
const LRIZ_OBRAZAC_5_GIO_1_FILTER_DATA_INITIAL_STATE: ILriz5Gio1FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  indeksni_broj_otpada_id: '',
  obrazac_gio1: '',
  lriz_obrazac_5: '',
};

const LRIZ_OBRAZAC_5_GIO_1_FILTER_OPTIONS = (
  form: FormInstance<ILriz5Gio1FilterDataObrasci>
): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  INDEX_NUMBER_FILTER_DATA,
  LRIZ_OBRAZAC_5_FILTER_DATA,
  GIO_1_FILTER_DATA,
];
//#endregion LRIZ 5
//#region GIO 1
const GIO_1_FILTER_DATA_INITIAL_STATE: IGio1FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  postrojenje__istorijski_id__ilike: '',
  indeksni_broj_otpada_id: '',
  lriz_obrazac_5: '',
};

const GIO_1_FILTER_OPTIONS = (form: FormInstance<IGio1FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  ISTORIJSKI_ID_FILTER_DATA(),
  INDEX_NUMBER_FILTER_DATA,
  LRIZ_OBRAZAC_5_FILTER_DATA,
];
//#endregion GIO 1
//#region DEP 1
const DEP_1_FILTER_DATA_INITIAL_STATE: IDep1FilterDataObrasci = {
  ...BASE_WITH_GODINA_INITIAL_STATE,
  nesanitarna_deponija_id: '',
};

const DEP_1_FILTER_OPTIONS = (form: FormInstance<IDep1FilterDataObrasci>): ISifarniciTypeOfForm[] => [
  ...BASE_WITH_GODINA_FILTER_DATA(form),
  NESANITARNA_DEPONIJA_FILTER_DATA(form),
];
//#endregion DEP 1

//#region OBRASCI FILTERS
export const OBRASCI_FILTERS_DATA: { [key: string]: ISifarniciFormData } = {
  [TIPOVI_OBRAZACA.DKO]: {
    initialState: DKO_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IDkoFilterDataObrasci>) => DKO_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.DEO6_GIO6]: {
    initialState: DEO6_GIO6_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IDeo6Gio6FilterDataObrasci>) => DEO6_GIO6_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.AAO1]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.AAO2]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_1]: {
    initialState: OBRAZAC_1_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac1FilterDataObrasci>) => OBRAZAC_1_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1]: {
    initialState: OBRAZAC_1_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac1FilterDataObrasci>) => OBRAZAC_1_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_2]: {
    initialState: OBRAZAC_2_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac2FilterDataObrasci>) => OBRAZAC_2_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_2_GVE]: {
    initialState: OBRAZAC_2_GVE_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac2GveFilterDataObrasci>) => OBRAZAC_2_GVE_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2]: {
    initialState: OBRAZAC_2_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac2FilterDataObrasci>) => OBRAZAC_2_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE]: {
    initialState: LRIZ_OBRAZAC_2_GVE_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<ILriz2GveFilterDataObrasci>) => LRIZ_OBRAZAC_2_GVE_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.GVE]: {
    initialState: GVE_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IGveFilterDataObrasci>) => GVE_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_3]: {
    initialState: OBRAZAC_3_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac3FilterDataObrasci>) => OBRAZAC_3_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_3_OTV]: {
    initialState: OBRAZAC_3_OTV_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac3OtvFilterDataObrasci>) => OBRAZAC_3_OTV_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3]: {
    initialState: OBRAZAC_3_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac3FilterDataObrasci>) => OBRAZAC_3_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV]: {
    initialState: LRIZ_OBRAZAC_3_OTV_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<ILriz3OtvFilterDataObrasci>) => LRIZ_OBRAZAC_3_OTV_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OTV]: {
    initialState: OTV_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IOtvFilterDataObrasci>) => OTV_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_4]: {
    initialState: OBRAZAC_4_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac4FilterDataObrasci>) => OBRAZAC_4_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4]: {
    initialState: OBRAZAC_4_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac4FilterDataObrasci>) => OBRAZAC_4_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.OBRAZAC_5]: {
    initialState: OBRAZAC_5_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IObrazac5FilterDataObrasci>) => OBRAZAC_5_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5]: {
    initialState: LRIZ_OBRAZAC_5_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<ILriz5FilterDataObrasci>) => LRIZ_OBRAZAC_5_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.GIO1]: {
    initialState: GIO_1_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IGio1FilterDataObrasci>) => GIO_1_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1]: {
    initialState: LRIZ_OBRAZAC_5_GIO_1_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<ILriz5Gio1FilterDataObrasci>) => LRIZ_OBRAZAC_5_GIO_1_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.DEP1]: {
    initialState: DEP_1_FILTER_DATA_INITIAL_STATE,
    components: (form: FormInstance<IDep1FilterDataObrasci>) => DEP_1_FILTER_OPTIONS(form),
  },

  [TIPOVI_OBRAZACA.DEP2]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.GIO2]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.GIO3]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.GIO4]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.GIO5]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.PTP2]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.PZV1]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },

  [TIPOVI_OBRAZACA.KOM1]: {
    initialState: BASE_WITH_GODINA_INITIAL_STATE,
    components: (form: FormInstance<IBaseWithGodinaFilterDataObrasci>) => BASE_WITH_GODINA_FILTER_DATA(form),
  },
};
