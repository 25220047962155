import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostQList, IGetQList, IQListFilters } from './qList.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const Q_LIST_FILTERS_INITIAL_STATE: IQListFilters = {
  name__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
  is_active: null,
};
export const Q_LIST_INITIAL_STATE: IPostQList = {
  name: '',
  description: '',
  description_sr: '',
  is_active: true,
};
export const Q_LIST_TABLE_DATA: ColumnsType<IGetQList> = [NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE];
export const Q_LIST_FORM_OPTIONS = (_: FormInstance<IGetQList>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(20), DESCRIPTION_FORM(1000), DESCRIPTION_SR_FORM(1000)];
};
