import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IGetWater, IPostWater, RequestwaterList } from './water.types';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

const fetchWaterList = (payload: RequestwaterList): IPaginatedApiResponse<IGetWater> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.WATER}?${query}`);
};

const postWater = (payload: IPostWater): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.WATER}`, payload);
};

const putWater = (payload: IGetWater): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.WATER}${payload.id}`, payload);
};

const toggleActivateWater = (id: string, isActive: boolean): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.WATER}${id}/${isActive ? 'aktiviraj' : 'deaktiviraj'}`);
};

export const waterRepo = {
  fetchWaterList,
  postWater,
  putWater,
  toggleActivateWater,
};
