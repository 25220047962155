import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { UsersListTable } from 'components/Tables';
import { observer } from 'mobx-react-lite';
import { authStore, modalStore } from 'store';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { companyStore } from 'modules/company/company.store';
import { userStore } from 'modules/user/user.store';
import { useEffect } from 'react';
import { UserFormType } from 'modules/user/user.types';
import { CompanyStatus } from 'modules/company/company.constants';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

const CompanyUserListHeader: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('USER.LIST')}</h3>
      <ExcelAndAddButton
        excelFunc={undefined}
        excelName={'user'}
        addButtonLabel="Dodaj korisnika na preduzeće"
        addButtonOnChange={
          (authStore.isAdmin || authStore.isCompanyOwner) &&
          companyStore.getCompany.is_active &&
          companyStore.getCompany.status !== CompanyStatus.U_PRIPREMI
            ? () => {
                userStore.setUserTypeForm(UserFormType.UserCompany);
                modalStore.changeModalName('user-modal');
              }
            : undefined
        }
      />
    </Row>
  );
});

export const CompanyUsersList: React.FC = observer(() => {
  useEffect(() => {
    userStore.fetchUserCompanyList(paginationInitialState);
  }, []);

  return (
    <InsideContentWrapper header={<CompanyUserListHeader />}>
      <UsersListTable
        isFacility={false}
        fetchListTable={userStore.fetchUserCompanyList}
        storePagination={userStore.userListPagination}
      />
    </InsideContentWrapper>
  );
});
