import { Checkbox, FormInstance } from 'antd';
import {
  IPostPzvCustomsAndProducers,
  IGetPzvCustomsAndProducers,
  IGetPzvProducts,
  IPostPzvProducts,
  IPzvCustomsAndProducersFilters,
} from './pzvCustomsAndProducers.types';
import i18n from 'translations/i18n.config';
import {
  MAX_LENGTH_RULE,
  PIB_FIELD_RULE,
  REQUIRED_FIELD_RULE,
  checkIfItemExistsInList,
} from 'modules/nriz/nriz.constants';
import {
  DateSifarniciFormProps,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SifarniciLists,
  SwitchSifarniciFormProps,
  TableSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import dayjs from 'dayjs';
import { DESCRIPTION_FORM, IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import { DESCRIPTION_TABLE, IS_ACTIVE_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormListType } from 'typescript/NrizTypes';

export const PZV_PRODUCTS_INITIAL_STATE: IPostPzvProducts = {
  product_id: '',
  net_mass: null,
  description: '',
  exempted: false,
  remarks: '',
  is_active: true,
};

export const PZV_PRODUCTS_TABLE_DATA = [
  IS_ACTIVE_TABLE,
  {
    title: i18n.t('SIFARNICI.PROIZVOD'),
    width: '200px',
    dataIndex: ['product_type', 'name'],
    key: 'product_type',
  },
  {
    title: `${i18n.t('SIFARNICI.NETO_MASA')}`,
    width: '150px',
    dataIndex: 'net_mass',
    key: 'net_mass',
  },
  DESCRIPTION_TABLE,
  {
    title: `${i18n.t('SIFARNICI.OSLOBODJENI')}`,
    width: '150px',
    dataIndex: 'exempted',
    align: 'center',
    render: (_: any, record: IGetPzvProducts) => <Checkbox checked={record.exempted} disabled={true} />,
    key: 'exempted',
  },
  {
    title: `${i18n.t('WIZARD_TABS.NAPOMENA')}`,
    width: '200px',
    dataIndex: 'remarks',
    key: 'remarks',
  },
];

interface IPzvProductProps {
  parentForm: FormInstance<IGetPzvCustomsAndProducers>;
  formListName: string;
  selectedIndex: number;
}

export const PZV_PRODUCTS_FORM_OPTIONS = ({
  parentForm,
  formListName,
  selectedIndex,
}: IPzvProductProps): ISifarniciTypeOfForm[] => {
  const accessor = 'product_id';
  const formList = parentForm.getFieldValue(formListName);
  const previousValue = formList[selectedIndex] ? formList[selectedIndex][accessor] : undefined;

  return [
    { ...IS_ACTIVE_SWITCH, labelCol: { span: 7 } },
    {
      type_of_form: 'searchScroll',
      label: i18n.t('SIFARNICI.PROIZVOD'),
      formName: 'product_id',
      fetchOptinsList: SIFARNICI_ROUTES.PZV_TYPE_OF_PRODUCTS,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      objName: 'product_type',
      disabledAccessor: 'is_active',
      labelCol: { span: 7 },
      rules: [
        REQUIRED_FIELD_RULE(true),
        checkIfItemExistsInList({ previousValue, listItemAccessor: accessor, list: formList }),
      ],
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input_number',
      label: i18n.t('SIFARNICI.NETO_MASA'),
      name: 'net_mass',
      filterName: 'net_mass__ilike',
      inputName: 'net_mass',
      labelCol: { span: 7 },
    } as InputSifarniciFormProps,

    { ...DESCRIPTION_FORM(100), labelCol: 7, wrapperCol: 17 },

    {
      type_of_form: 'switch',
      name: 'exempted',
      label: i18n.t('SIFARNICI.OSLOBODJENI'),
      labelCol: { span: 7 },
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'area',
      label: i18n.t('WIZARD_TABS.NAPOMENA'),
      name: 'remarks',
      filterName: 'remarks__ilike',
      rules: [MAX_LENGTH_RULE(500)],
      rows: 4,
      labelCol: 7,
      wrapperCol: 17,
    } as TextAreaSifarniciFormProps,
  ];
};

export const PZV_CUSOTMS_AND_PRODUCERS_FILTERS_INITIAL_STATE: IPzvCustomsAndProducersFilters = {
  pro_id__ilike: '',
  period_year__ilike: '',
  pib__ilike: '',
  company_name__ilike: '',
  address__ilike: '',
  location__ilike: '',
  is_active: null,
};
export const PZV_CUSOTMS_AND_PRODUCERS_INITIAL_STATE: IPostPzvCustomsAndProducers = {
  pro_id: '',
  period_year: '',
  pib: '',
  company_name: '',
  address: '',
  location: '',
  is_active: true,
  pzv_products: [],
};

export const PZV_CUSTOMS_AND_PRODUCERS_TABLE_DATA = [
  {
    title: 'Pro id',
    width: '200px',
    dataIndex: 'pro_id',
    key: 'pro_id',
  },
  {
    title: `${i18n.t('YEAR')}`,
    width: '100px',
    dataIndex: 'period_year',
    key: 'period_year',
  },
  {
    title: `${i18n.t('COMPANY.PIB')}`,
    width: '150px',
    dataIndex: 'pib',
    key: 'pib',
  },
  {
    title: `${i18n.t('COMPANY.NAME')}`,
    width: '250px',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: `${i18n.t('ADDRESS')}`,
    width: '200px',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: `${i18n.t('SIFARNICI.LOCATION')}`,
    width: '150px',
    dataIndex: 'location',
    key: 'location',
  },
];

export const PZV_CUSTOMS_AND_PRODUCERS_FORM_OPTIONS = (
  _: FormInstance<IGetPzvCustomsAndProducers>
): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      label: 'Pro id',
      name: 'pro_id',
      filterName: 'pro_id__ilike',
      inputName: 'pro_id',
      rules: [MAX_LENGTH_RULE(15)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'period_year',
      filterName: 'period_year__ilike',
      label: i18n.t('YEAR'),
      format: 'YYYY',
      picker: 'year',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('COMPANY.PIB'),
      name: 'pib',
      filterName: 'pib__ilike',
      inputName: 'pib',
      rules: [PIB_FIELD_RULE],
      precision: 0,
      type: 'number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('COMPANY.NAME'),
      name: 'company_name',
      filterName: 'company_name__ilike',
      inputName: 'company_name',
      rules: [MAX_LENGTH_RULE(150)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('ADDRESS'),
      name: 'address',
      filterName: 'address__ilike',
      type: 'text',
      rules: [MAX_LENGTH_RULE(150)],
      inputName: 'address',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('SIFARNICI.LOCATION'),
      type: 'text',
      name: 'location',
      filterName: 'location__ilike',
      inputName: 'location',
      rules: [MAX_LENGTH_RULE(100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'table',
      tableColumns: PZV_PRODUCTS_TABLE_DATA,
      listName: 'pzv_products',
      configPath: { formListType: FormListType.Sifarnici, formListSubType: SifarniciLists.PZV_PRODUCTS },
      title: <div style={{ textDecoration: 'underline' }}>{i18n.t('SIFARNICI.CARINA_I_PROIZVODJAC_PROIZVODI')}</div>,
      formModalInitialValues: PZV_PRODUCTS_INITIAL_STATE,
    } as TableSifarniciFormProps,
  ];
};
