import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { UsersListTable } from 'components/Tables';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FacilityUserModal } from 'components/Modals/FacilityUserModal/FacilityUserModal';
import { authStore, modalStore } from 'store';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { userStore } from 'modules/user/user.store';
import { facilityStore } from 'modules/facility/facility.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export const FacilityUsersListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('USER.LIST')}</h3>
      <ExcelAndAddButton
        excelFunc={undefined}
        excelName={'user'}
        addButtonLabel="Dodaj korisnika na postrojenje"
        addButtonOnChange={
          (authStore.isAdmin || authStore.isCompanyOwner) && facilityStore.getFacility.is_active
            ? () => {
                modalStore.changeModalName('facility-user-modal');
              }
            : undefined
        }
      />
    </Row>
  );
});

export const FacilityUsersList: React.FC = observer(() => {
  useEffect(() => {
    userStore.fetchUserFacilityList(paginationInitialState);
  }, []);

  return (
    <InsideContentWrapper header={<FacilityUsersListHeader />}>
      <UsersListTable
        fetchListTable={userStore.fetchUserFacilityList}
        isFacility={true}
        storePagination={userStore.facilityUserListPagination}
      />
      <FacilityUserModal />
    </InsideContentWrapper>
  );
});
