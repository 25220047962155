import { ApiResponse, axios } from 'modules/axios';
import queryString from 'query-string';

// todo generalizuj payload za sve registre

const checkIfRegistarExists = (payload: {
  postrojenje_id: string;
  izvor_zagadjenja_vode_id?: string;
}): ApiResponse<{ registar_postoji: boolean }> => {
  const query = queryString.stringify(payload);
  return axios.get(`/registar_provera/?${query}`);
};
export const registriRepo = {
  checkIfRegistarExists,
};
