import to from 'await-to-js';
import { lcpRepo } from './lcp.repo';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { ILCPRegistar, LCPRequest } from './lcp.types';
// import { isNaN } from 'lodash';
import dayjs from 'dayjs';

class LCPService {
  async fetchLcpInstalacijeList(filters: any) {
    const [err, res] = await to(lcpRepo.fetchLcpInstalacijeList(filters));
    if (err) return { items: [], ...paginationInitialState };
    return res;
  }
  async fetchLcpInstalacija(id: string) {
    const [err, res] = await to(lcpRepo.fetchLcpInstalacija(id));
    if (err) return undefined;
    return res;
  }
  async postLcpInstalacija(payload: LCPRequest) {
    const [err, res] = await to(lcpRepo.postLcpInstalacija(payload));
    if (err) return undefined;
    return res;
  }
  async putLcpInstalacija(payload: ILCPRegistar) {
    const [err, res] = await to(lcpRepo.putLcpInstalacija(payload, payload.id));
    if (err) return undefined;
    return res;
  }
  async deleteLcpInstalacija(id: string) {
    const [err, res] = await to(lcpRepo.deleteLcpInstalacija(id));
    if (err) return undefined;
    return res;
  }

  async fetchSledeciIdInstalacije() {
    const [err, res] = await to(lcpRepo.fetchSledeciIdInstalacije());
    if (err) return undefined;
    return res;
  }

  async fetchSledeciIdInstalacionogDela() {
    const [err, res] = await to(lcpRepo.fetchSledeciIdInstalacionogDela());
    if (err) return undefined;
    return res;
  }

  getIdProizvodnogPogona(istorijskiId: string | undefined) {
    if (!istorijskiId) return;

    const MAX_ID_PROIZVODNOG_POGONA_LENGTH = 9;

    const parsedIstorijskiId = istorijskiId.replace(/\D/g, '');

    const idProizvodnogPogona = parsedIstorijskiId.padStart(MAX_ID_PROIZVODNOG_POGONA_LENGTH, '0');

    return idProizvodnogPogona;
  }

  getNextIdInstalacionogDela(lcpRegistar: ILCPRegistar | undefined) {
    if (!lcpRegistar || !lcpRegistar.instalacioni_deo.length) return undefined;

    const nextId = lcpRegistar.instalacioni_deo[lcpRegistar.instalacioni_deo.length - 1].id_instalacionog_dela;

    return this.incrementIdInstalacionogDela(nextId);
  }

  incrementIdInstalacionogDela(instalacioniId: string) {
    const maxIdLength = 9;

    const instalacioniIdIncremented = (Number(instalacioniId) + 1).toString();

    const nubmerOfLeadingZeroes = maxIdLength - instalacioniIdIncremented.length;

    let leadingZeroes = '';

    for (let i = 1; i <= nubmerOfLeadingZeroes; i++) {
      leadingZeroes += '0';
    }

    const finalId = leadingZeroes + instalacioniIdIncremented;

    return finalId;
  }

  formatPayloadDates<T extends LCPRequest | ILCPRegistar>(lcpRegistar: T): T {
    const validInstalacioniDelovi = lcpRegistar.instalacioni_deo.map(deo => {
      const validDatum = dayjs(deo.datum_pocetka_rada).format('YYYY-MM-DD');
      return { ...deo, datum_pocetka_rada: validDatum };
    });
    return { ...lcpRegistar, instalacioni_deo: validInstalacioniDelovi };
  }

  formatResponseDates = (lcpRegistar: ILCPRegistar) => {
    const validInstalacioniDelovi = lcpRegistar.instalacioni_deo.map(deo => {
      const validDatum = dayjs(deo.datum_pocetka_rada);
      return { ...deo, datum_pocetka_rada: validDatum };
    });
    return { ...lcpRegistar, instalacioni_deo: validInstalacioniDelovi };
  };
}

export const lcpService = new LCPService();
