import { Col, Form } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE, checkIfItemExistsInList } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetBilansGve } from 'modules/obrasci/store/gve/gve.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const NewGveBilansForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const nacinMerenjaListMaker = (options: IGetMethodBasisCode[]) => {
    // const nacinMerenje = options.filter(entity => entity.name_sr === 'Merenje');
    return options.map(entity => ({
      label: `${entity.name_sr}`,
      value: entity.id,
    }));
  };

  const defaultZagadjujucaOption: ISifarnikInitial | undefined = gveStore.getBilansForm.naziv_zagadjujuce_materije_id
    ? {
        label: (gveStore.getBilansForm as IGetBilansGve).naziv_zagadjujuce_materije.name_sr,
        value: gveStore.getBilansForm.naziv_zagadjujuce_materije_id,
      }
    : undefined;

  const nacinOdredjivanjaKoncentracijaOption: ISifarnikInitial | undefined = gveStore.getBilansForm
    .nacin_odredjivanja_dimni_gas_id
    ? {
        label: (gveStore.getBilansForm as IGetBilansGve).nacin_odredjivanja_dimni_gas.name_sr,
        value: gveStore.getBilansForm.nacin_odredjivanja_dimni_gas_id,
      }
    : undefined;

  const nacinEmitovaneKolicineOption: ISifarnikInitial | undefined = gveStore.getBilansForm
    .nacin_odredjivanja_normalan_rad_id
    ? {
        label: (gveStore.getBilansForm as IGetBilansGve).nacin_odredjivanja_normalan_rad.name_sr,
        value: gveStore.getBilansForm.nacin_odredjivanja_normalan_rad_id,
      }
    : undefined;

  const defaultMetodaOdredjivanjaOption: ISifarnikInitial | undefined = useMemo(() => {
    return gveStore.getBilansForm.metod_utvrdjivanja_id
      ? {
          label: (gveStore.getBilansForm as IGetBilansGve).metod_utvrdjivanja.name_sr,
          value: gveStore.getBilansForm.metod_utvrdjivanja_id,
        }
      : undefined;
  }, [gveStore.bilans_form]);

  useEffect(() => {
    if (gveStore.initialState.emisije_u_vazduh.length !== 0) {
      gveStore.initialState.emisije_u_vazduh.forEach(item => {
        dayjs().date(Number(item.datum_merenja));
      });
    }
  }, []);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.ZAGADJUJUCA_MATERIJA')}</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_zagadjujuce_materije_id'}
        fetchOptinsList={gveStore.fetchPollutantOptions}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.NAZIV_ZAGADJUJUCE_MATERIJE')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'naziv_zagadjujuce_materije_id'}
        defaultOption={defaultZagadjujucaOption}
        placeholder={t('OBRASCI.GVE.NAZIV_ZAGADJUJUCE_MATERIJE')}
        rules={[
          REQUIRED_FIELD_RULE(true),
          checkIfItemExistsInList({
            previousValue: gveStore.bilans_form.naziv_zagadjujuce_materije_id,
            listItemAccessor: 'naziv_zagadjujuce_materije_id',
            list: gveStore.initialState.bilans,
          }),
          // {
          //   validator(_, value) {
          //     if (
          //       (gveStore.getBilansForm && gveStore.bilans_form?.naziv_zagadjujuce_materije_id === value) ||
          //       !gveStore.getInitialState.bilans.some(materija => {
          //         return materija.naziv_zagadjujuce_materije_id === value;
          //       })
          //     )
          //       return Promise.resolve();

          //     return Promise.reject(new Error(t('OBRASCI.GVE.ISTA_ZAGADJUJUCA_UPOZORENJE')));
          //   },
          // },
        ]}
        readOnly={readOnly}
      />
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.KONCENTRACIJA_ZAGADJUJUCIH_MATERIJA')}</h4>
      <FormInputNumber
        name="srednja_godisnja_izmerena_vrednost"
        label={t('OBRASCI.GVE.SREDNJA_GODISNJA_VREDNOST')}
        placeholder={t('OBRASCI.GVE.SREDNJA_GODISNJA_VREDNOST')}
        inputName={'srednja_godisnja_izmerena_vrednost'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_odredjivanja_dimni_gas_id'}
        fetchOptinsList={gveStore.fetchWayOptions}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.NACIN_ODREDJIVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'nacin_odredjivanja_dimni_gas_id'}
        defaultOption={nacinOdredjivanjaKoncentracijaOption}
        placeholder={t('OBRASCI.GVE.NACIN_ODREDJIVANJA')}
        optionsMaker={nacinMerenjaListMaker}
        readOnly={readOnly}
      />

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.EMITOVANE_KOLICINE_NORMALNI_RAD')}</h4>
      <FormInputNumber
        name="emitovana_kolicina_normalan_rad_g_h"
        label={t('OBRASCI.GVE.EMITOVANE_G_H')}
        placeholder={t('OBRASCI.GVE.EMITOVANE_G_H')}
        inputName={'emitovana_kolicina_normalan_rad_g_h'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="emitovana_kolicina_normalan_rad_kg_god"
        label={t('OBRASCI.GVE.EMITOVANE_KG_GOD')}
        placeholder={t('OBRASCI.GVE.EMITOVANE_KG_GOD')}
        inputName={'emitovana_kolicina_normalan_rad_kg_god'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true), ...MORE_THEN_0]}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_odredjivanja_normalan_rad_id'}
        fetchOptinsList={gveStore.fetchWayOptions}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.NACIN_ODREDJIVANJA')}
        labelCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        wrapperCol={{ span: 12 }}
        inputName={'nacin_odredjivanja_normalan_rad_id'}
        defaultOption={nacinEmitovaneKolicineOption}
        placeholder={t('OBRASCI.GVE.NACIN_ODREDJIVANJA')}
        optionsMaker={nacinMerenjaListMaker}
        onChange={value => {
          form.setFieldValue('metod_utvrdjivanja_id', '');
          gveStore.handleChange('filterNacinOdredjivanjaId', value);
        }}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'metod_utvrdjivanja_id'}
        fetchOptinsList={gveStore.fetchMethodOptionsBilans}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.METODA_UTVRDJIVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'metod_utvrdjivanja_id'}
        defaultOption={defaultMetodaOdredjivanjaOption}
        placeholder={t('OBRASCI.GVE.METODA_UTVRDJIVANJA')}
        disabled={!gveStore.filterNacinOdredjivanjaId}
        resetOption={gveStore.filterNacinOdredjivanjaId}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.EMITOVANE_U_AKCIDENTNIM')}</h4>
      <FormInputNumber
        name="emitovane_kolicine_u_akcidentnim_situacijama"
        label={t('OBRASCI.GVE.EMITOVANE_KG_GOD')}
        placeholder={t('OBRASCI.GVE.EMITOVANE_KG_GOD')}
        inputName={'emitovane_kolicine_u_akcidentnim_situacijama'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
    </Col>
  );
});
