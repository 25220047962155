const isEntityActiveKeys = ['is_active', 'aktivan'];

export const toggleActivateItems = <T>(items: T[], isActive: boolean): T[] => {
  const newArray = items.map((item: T) => {
    const validAccessor = isEntityActiveKeys.find(accessor => {
      //@ts-ignore
      if (accessor in item) return accessor;
    });
    return validAccessor ? { ...item, [validAccessor]: isActive } : item;
  });
  return newArray;
};
