import { UserLanguages } from 'modules/user/user.types';
import { authStore } from 'store';

/**
 * Function that return separator
 * @param { number } decimalNumber
 * @param { string } locale
 * @return { string | undefined }
 */
export function getDecimalSeparator(decimalNumber: number, locale: string): string | undefined {
  return Intl.NumberFormat(locale)
    .formatToParts(decimalNumber)
    .find((part: Intl.NumberFormatPart): boolean => part.type === 'decimal')?.value;
}

/**
 * Method that formats number
 * @param { number | string } value
 * @param { number } numberOfFractionDigits[]
 * @return { string | undefined }
 */
export function formatNumberWithDecimals(value: number | string, numberOfFractionDigits?: number): string | undefined {
  if (!value) return;
  const getLocaleByLanguage = (): string => {
    switch (authStore.getSelectedJezik) {
      case UserLanguages.SRPSKI:
        return 'de';
      case UserLanguages.ENGLESKI:
        return 'en';
      default:
        return 'de';
    }
  };
  if (typeof value === 'string') value = Number(value);

  return value?.toLocaleString(getLocaleByLanguage(), {
    minimumFractionDigits: numberOfFractionDigits ?? 2,
  });
}

/**
 * Method that removes trailing zeros from number
 * @type { string } value
 * @return string
 */
export function removeTrailingZeros(value: string, numberOfFractionDigits?: number) {
  if (!value) return;
  const getSeparator = (): string => {
    switch (authStore.getSelectedJezik) {
      case UserLanguages.SRPSKI:
        return ',';
      case UserLanguages.ENGLESKI:
        return '.';
      default:
        return ',';
    }
  };
  const separator: string = getSeparator();
  if (separator) {
    const decimals: string = value.split(separator)[1];
    for (
      let i = decimals.length - 1;
      i > (numberOfFractionDigits && numberOfFractionDigits <= decimals.length ? numberOfFractionDigits - 1 : 1);
      i--
    ) {
      if (decimals[i] === '0') {
        value = value.slice(0, -1);
      } else {
        break;
      }
    }
  }
  return value;
}
