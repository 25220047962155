import { makeAutoObservable, runInAction } from 'mobx';
import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import to from 'await-to-js';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';

import dayjs from 'dayjs';
import {
  IAAO1Response,
  IPostAAO1,
  IPostKolicinaAmbalaze,
  IPostUpravljanjeAmbalaznimOtpadom,
  IPostOperateri,
  IGetKolicinaAmbalaze,
  IGetUpravljanjeAmbalaznimOtpadom,
  IOperateri,
} from './aao1.types';
import {
  AAO1_COMPANY_OPTIONS,
  AAO1_INITIAL_STATE,
  AAO1_KOLICINA_AMBALAZA_INITIAL_STATE,
  AAO1_OPERATERI_INITIAL_STATE,
  AAO1_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE,
} from './aao1.constants';
import { nrizService } from 'modules/nriz/nriz.service';
import { formStore } from 'store';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { isEmpty } from 'lodash';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { arrayToObject } from 'utils/arrayToObject';
import { IGetTypeOfPacking } from 'modules/sifarnici/store/typeOfPacking/typeOfPacking.types';
import { IGetCompanyWithSelfManagment } from 'modules/sifarnici/store/companyWithSelfManagment/companyWithSelfManagment.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetOperatorsPackageWasteManage } from 'modules/sifarnici/store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';

class Aao1Store implements IBaseObrazacStore<IAAO1Response, IPostAAO1> {
  initialState: IPostAAO1 | IAAO1Response = AAO1_INITIAL_STATE;
  kolicinaAmbalaze: IPostKolicinaAmbalaze | IGetKolicinaAmbalaze = AAO1_KOLICINA_AMBALAZA_INITIAL_STATE;
  upravljanjeAmbalaznimOtpadom: IPostUpravljanjeAmbalaznimOtpadom | IGetUpravljanjeAmbalaznimOtpadom =
    AAO1_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE;
  operater_form: IPostOperateri | IOperateri = AAO1_OPERATERI_INITIAL_STATE;

  companyTypeList: string[] = [];
  isCompanyWithSelfManagment = false;
  showOperateri = false;
  operatorArrayShowError = false;
  typeOfPackingExistsError = false;
  aao1_options = false;
  options_disabled = false;
  disable_upravljanje = true;
  kolicinaWarning = false;
  prenetaKolicina = null;

  constructor() {
    makeAutoObservable(this);
  }

  get getInitialState() {
    return this.initialState;
  }

  get getKolicinaAmbalaze() {
    return this.kolicinaAmbalaze;
  }

  get getUpravljanjeAmbalaznimOtpadom() {
    return this.upravljanjeAmbalaznimOtpadom;
  }

  get getAao1Options() {
    return this.aao1_options;
  }

  get getOperater() {
    return this.operater_form;
  }

  setTypeOfPackingExistsError = (typeOfPackingExistsError: boolean) => {
    this.typeOfPackingExistsError = typeOfPackingExistsError;
  };

  setCompanyWithSelfManagement = (value: boolean) => {
    this.isCompanyWithSelfManagment = value;
  };

  resetOperateri = () => {
    this.kolicinaAmbalaze.operateri = [];
    this.handleChange('prenetaKolicina', null);
  };

  // DOHVATANJE SIFARNIKA

  fetchCompaniesWithSelfManagement = async (id: string, search?: string) => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompanyWithSelfManagment>(
      SIFARNICI_ROUTES.COMPANY_WITH_SELF_MANAGEMENT,
      {
        company_id: id,
        search: search,
      }
    );
    this.setCompanyWithSelfManagement(response.items.some(item => item.company_id === id));
  };

  // POZIV SIFARNIK

  fetchTypeOfPackingOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetTypeOfPacking>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetTypeOfPacking>(SIFARNICI_ROUTES.TYPE_OF_PACKING, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+display_type_sr',
    });
    return response;
  };

  fetchOperateriOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetOperatorsPackageWasteManage>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetOperatorsPackageWasteManage>(
      SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
      {
        page: pagination.page,
        size: pagination.size,
        preduzece__search: search,
        is_active: true,
      }
    );
    return response;
  };

  fetchROptions = async (_: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'R',
      name__not_in: 'R1,R3',
      order_by: '+name',
    });
    return response;
  };

  fetchDOptions = async (_: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'D',
      name__neq: 'D1',
      order_by: '+name',
    });
    return response;
  };

  // HELPERS

  handleChange(key: keyof Aao1Store, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: keyof IPostAAO1, value: any) {
    (this as any).initialState[key] = value;
  }

  handleKolicinaAmbalazeChange(key: keyof IPostKolicinaAmbalaze, value: any) {
    (this as any).kolicinaAmbalaze[key] = value;
  }

  checkSelfManagement(id: string) {
    const list = sifarniciStore.list.COMPANY_WITH_SELF_MANAGEMENT;
    list.some(item => item.company_id === id);
  }

  changeCompanyTypeList = (type: string, value: boolean) => {
    const indexOfType = this.companyTypeList.indexOf(type);

    if (value === true && indexOfType === -1) {
      this.companyTypeList.push(type);
    } else if (value === false && indexOfType !== -1) {
      this.companyTypeList.splice(indexOfType, 1);
    }

    if (this.companyTypeList.length > 0) {
      runInAction(() => {
        this.handleChange('aao1_options', true);
      });
    } else {
      runInAction(() => this.handleChange('aao1_options', false));
    }
  };

  // OPERATORI

  submitOperater = (payload: IOperateri) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: this.operater_form.operators_package_waste_manage_id,
        exObj: (this.operater_form as IOperateri).operators_package_waste_manage,
        list: sifarniciStore.list[SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE],
        name: 'operators_package_waste_manage',
        id: payload.operators_package_waste_manage_id,
      });
    }
    newPayload = { ...newPayload, ...sifarnikRazredEeOtapada, id: (this.operater_form as IOperateri).id };
    const newList = obrasciService.submitMicroServiceForm(
      this.kolicinaAmbalaze.operateri,
      newPayload,
      true,
      'aao1-operateri-modal'
    );
    // handleSuccessNotify();
    this.handleKolicinaAmbalazeChange('operateri', newList);
    this.handleChange('operater_form', AAO1_OPERATERI_INITIAL_STATE);
  };

  // CRUD FUNCTIONS

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IAAO1Response>(TIPOVI_OBRAZACA.AAO1, id));
    if (err) return;

    const newGodina = dayjs().year(Number(res.godina));
    const newIssueDate = res.datum_izdavanja ? dayjs(res.datum_izdavanja) : '';
    AAO1_COMPANY_OPTIONS.forEach(item => {
      if (res[item] === true) this.changeCompanyTypeList(item, true);
    });
    if (this.companyTypeList.length > 0 || Number(res.godina) < 2013) this.handleChange('options_disabled', true);
    const initialState = { ...res, godina: newGodina, datum_izdavanja: newIssueDate };
    this.handleChange('initialState', initialState);
    return res;
  };

  async postData(payload: IPostAAO1) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostAAO1>(TIPOVI_OBRAZACA.AAO1, nrizService.setEmptyValuesToNull(payload))
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async putData(payload: any) {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostAAO1>(
        TIPOVI_OBRAZACA.AAO1,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.AAO1, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  resetStates = () => {
    this.handleChange('initialState', AAO1_INITIAL_STATE);
    this.handleChange('companyTypeList', []);
    this.handleChange('isCompanyWithSelfManagment', false);
    this.handleChange('showOperateri', false);
    this.handleChange('operatorArrayShowError', false);
    this.handleChange('typeOfPackingExistsError', false);
    this.handleChange('aao1_options', false);
    this.handleChange('options_disabled', false);
    this.handleChange('disable_upravljanje', true);
    this.handleChange('prenetaKolicina', null);
  };

  submitKolicinaAmbalaze = (payload: IPostKolicinaAmbalaze) => {
    if (
      this.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera &&
      isEmpty(this.kolicinaAmbalaze.operateri)
    ) {
      this.handleChange('operatorArrayShowError', true);
      return;
    }
    this.handleChange('operatorArrayShowError', false);

    let newPayload = payload;
    let sifarnikTypeOfPacking = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikTypeOfPacking = nrizService.addSifarnikInObject({
        exId: (this.kolicinaAmbalaze as IGetKolicinaAmbalaze).type_of_packing_id,
        exObj: (this.kolicinaAmbalaze as IGetKolicinaAmbalaze).type_of_packing,
        list: sifarniciStore.list.TYPE_OF_PACKING,
        name: 'type_of_packing',
        id: payload.type_of_packing_id,
      });
      if (!this.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera) {
        payload.operateri = [];
      }
      if (
        !this.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera &&
        !this.initialState.upravljanje_ambalaznim_otpadom.some(item => {
          item.type_of_packing_id === payload.type_of_packing_id;
        })
      )
        this.handleChange('kolicinaWarning', true);
    }
    if (
      (formStore.getCrudButtonType === 'delete' ||
        (formStore.getCrudButtonType === 'change' &&
          (this.kolicinaAmbalaze.type_of_packing_id !== payload.type_of_packing_id ||
            payload.da_li_je_preneta_kolicina_ambalaze_na_operatera))) &&
      this.initialState.upravljanje_ambalaznim_otpadom.length !== 0
    ) {
      const newUpravljanjeAmbalaznimOtpadomList = this.initialState.upravljanje_ambalaznim_otpadom.filter(
        item => item.type_of_packing_id !== this.kolicinaAmbalaze.type_of_packing_id
      );
      this.handleInitialStateChange('upravljanje_ambalaznim_otpadom', newUpravljanjeAmbalaznimOtpadomList);
    }
    newPayload = {
      id: (this.kolicinaAmbalaze as IGetKolicinaAmbalaze).id,
      ...newPayload,
      ...sifarnikTypeOfPacking,
      kolicina_preneta_na_operatera: this.prenetaKolicina,
      operateri: this.kolicinaAmbalaze.operateri,
    } as IGetKolicinaAmbalaze;

    const newList = obrasciService.submitMicroServiceForm(
      this.initialState.kolicina_ambalaze,
      nrizService.setEmptyValuesToNull(newPayload)
    );
    this.handleInitialStateChange('kolicina_ambalaze', newList);
    this.handleChange('kolicinaAmbalaze', AAO1_KOLICINA_AMBALAZA_INITIAL_STATE);
    // handleSuccessNotify();
  };

  submitUpravljanjeAmbOtpadom = (payload: IPostUpravljanjeAmbalaznimOtpadom) => {
    let newPayload = payload;
    const kolAmbToObject: {
      [K in IGetKolicinaAmbalaze[][number]['type_of_packing_id']]: Extract<
        IGetKolicinaAmbalaze[][number],
        { type_of_packing_id: K }
      >;
    } = arrayToObject(this.initialState.kolicina_ambalaze, 'type_of_packing_id');
    const sifarnikTypeOfPacking = {
      type_of_packing_id: payload.type_of_packing_id,
      type_of_packing: kolAmbToObject[payload.type_of_packing_id].type_of_packing,
    };
    let sifarnikDrugaOperacijaRSifarnik = {};
    let sifarnikDrugaOperacijaDSifarnik = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikDrugaOperacijaRSifarnik = nrizService.addSifarnikInObject({
        exId: (this.upravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_r_id,
        exObj: (this.upravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_r,
        list: sifarniciStore.list.DR_LIST,
        name: 'druga_operacija_r',
        id: payload.druga_operacija_r_id,
      });
      sifarnikDrugaOperacijaDSifarnik = nrizService.addSifarnikInObject({
        exId: (this.upravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_d_id,
        exObj: (this.upravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_d,
        list: sifarniciStore.list.DR_LIST,
        name: 'druga_operacija_d',
        id: payload.druga_operacija_d_id,
      });
    }

    newPayload = {
      id: (this.upravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).id,
      ...sifarnikTypeOfPacking,
      ...sifarnikDrugaOperacijaRSifarnik,
      ...sifarnikDrugaOperacijaDSifarnik,
      ...newPayload,
    } as IGetUpravljanjeAmbalaznimOtpadom;
    const newList = obrasciService.submitMicroServiceForm(
      this.initialState.upravljanje_ambalaznim_otpadom,
      nrizService.setEmptyValuesToNull(newPayload)
    );
    this.handleInitialStateChange('upravljanje_ambalaznim_otpadom', newList);
    this.handleChange('upravljanjeAmbalaznimOtpadom', AAO1_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE);
    // handleSuccessNotify();
  };

  submitData = (payload: IPostAAO1) => {
    const newInitialState = {
      ...AAO1_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IAAO1Response).id,
      godina: obrasciStore.opste.godina,
      kolicina_ambalaze: this.initialState.kolicina_ambalaze,
      upravljanje_ambalaznim_otpadom: this.initialState.upravljanje_ambalaznim_otpadom,
    } as IAAO1Response;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };
}

export const aao1Store = new Aao1Store();
