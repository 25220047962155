import { Form, FormInstance, Select } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormTable } from 'components/FormTable/FormTable';
import { observer } from 'mobx-react-lite';
import {
  LATITUDE_RULE,
  LONGITUDE_RULE,
  MAX_LENGTH_RULE,
  REQUIRED_AT_LEAST_ONE_LIST_ITEM,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { lcpStore } from 'modules/registri/lcp/lcp.store';
import {
  ILCPIzvorZagadjenja,
  LCPKategorijaPostrojenjaZaSagorevanje,
  LCPStatus,
  LCPVrstaInstalacionogDela,
} from 'modules/registri/lcp/lcp.types';
import { RegistriLists } from 'modules/registri/registri.types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormListType } from 'typescript/NrizTypes';

interface FormListItemProps<T> {
  parentForm: FormInstance<T>;
  formListName: string;
  selectedIndex: number;
}
export const LCPRegistarInstalacioniDeoForm = observer(
  // eslint-disable-next-line
  <T extends object>({ parentForm }: FormListItemProps<T>) => {
    const { t } = useTranslation();

    const form = Form.useFormInstance();

    useEffect(() => {
      if (parentForm.getFieldValue('postrojenje'))
        form.setFieldValue('potvrda_izabranog_postrojenja', parentForm.getFieldValue('postrojenje').naziv_postrojenja);
      if (!form.getFieldValue('id_instalacionog_dela'))
        form.setFieldValue('id_instalacionog_dela', lcpStore.nextInstalacioniDeoId);
    }, []);

    const izvorZagadjenjaColumns: ColumnType<ILCPIzvorZagadjenja>[] = [
      {
        title: 'Naziv izvora',
        dataIndex: ['izvor_zagadjenja_vazduha', 'naziv_izvora'],
        key: 'naziv_izvora',
      },
    ];

    const vrstaInstalacionogDelaOptions = Object.values(LCPVrstaInstalacionogDela).map(key => {
      if (key === LCPVrstaInstalacionogDela.coWI)
        return {
          label: 'co-Wi',
          value: key,
        };
      return {
        label: key,
        value: key,
      };
    });

    const kategorijaPostrojenjaZaSagorevanjeOptions = Object.values(LCPKategorijaPostrojenjaZaSagorevanje).map(key => {
      return {
        label: key,
        value: key,
      };
    });

    const statusOptions = Object.values(LCPStatus).map(key => {
      return {
        label: key,
        value: key,
      };
    });

    return (
      <>
        <FormInput
          name={'potvrda_izabranog_postrojenja'}
          inputName={'potvrda_izabranog_postrojenja'}
          label="Potvrda izabranog postrojenja"
          disabled
        />
        <FormInput
          name={'id_instalacionog_dela'}
          inputName={'id_instalacionog_dela'}
          label="ID instalacionog dela"
          disabled
        />
        <FormInput
          name={'naziv_instalacionog_dela'}
          inputName={'naziv_instalacionog_dela'}
          label="Naziv instalacionog dela"
          rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(1000)]}
        />
        <Form.Item
          name={'vrsta_instalacionog_dela'}
          label="Vrsta instalacionog dela"
          rules={[REQUIRED_FIELD_RULE(true)]}
          colon
        >
          <Select options={vrstaInstalacionogDelaOptions} />
        </Form.Item>
        <Form.Item
          name={'kategorija_postrojenja_za_sagorevanje'}
          label="Kategorija postrojenja za sagorevanje"
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select options={kategorijaPostrojenjaZaSagorevanjeOptions} />
        </Form.Item>
        <FormCheckbox name="u_okviru_rafinerije" label={'U okviru rafinerije'} colon />
        <FormInputNumber
          name="ukupan_toplotni_ulaz_mw"
          inputName="ukupan_toplotni_ulaz_mw"
          rules={[REQUIRED_FIELD_RULE(true)]}
          label={'Ukupan toplotni ulaz (MW)'}
          colon
        />
        <FormInput
          type="number"
          name="geografska_sirina"
          inputName="geografska_sirina"
          rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
          label={t('COMPANY.LATITUDE')}
          colon
        />
        <FormInput
          type="number"
          name="geografska_duzina"
          inputName="geografska_duzina"
          rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
          label={t('COMPANY.LONGITUDE')}
          colon
        />
        <FormDatePicker
          name="datum_pocetka_rada"
          label="Datum početka rada"
          rules={[REQUIRED_FIELD_RULE(true)]}
          format="DD-MM-YYYY"
        />
        <Form.Item name={'status'} label="Status" rules={[REQUIRED_FIELD_RULE(true)]}>
          <Select options={statusOptions} />
        </Form.Item>
        <FormTable<ILCPIzvorZagadjenja>
          listName="izvori_zagadjenja_vazduha"
          tableColumns={izvorZagadjenjaColumns}
          title="Izvori zagađivanja"
          listValidationRules={[REQUIRED_AT_LEAST_ONE_LIST_ITEM(true)]}
          configPath={{ formListType: FormListType.Registri, formListSubType: RegistriLists.LCP_INSTALACIONI_DEO }}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          tableMarginBottom
        />
      </>
    );
  }
);
