import { Checkbox } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { ptp2Service } from 'modules/obrasci/store/ptp2/ptp2.service';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { IGetProizvod } from 'modules/obrasci/store/ptp2/ptp2.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'store';

const EditProizvodButton: React.FC<EditButtonProps<IGetProizvod>> = observer(({ record }) => {
  const dozvoljenaKorekcijaReda = record.dozvoljena_korekcija_proizvoda || !obrasciStore.zakljucanObrazac;

  return (
    <RowEditPreviewButton
      name="ptp2-proizvod"
      modalName="proizvodi-modal"
      onClick={() => ptp2ObrazacStore.handleChange('proizvodi', record)}
      preview={!ptp2ObrazacStore.getDozvoljeneKorekcije || !dozvoljenaKorekcijaReda}
      store={ptp2ObrazacStore}
    />
  );
});

const KorekcijaCheckbox: React.FC<EditButtonProps<IGetProizvod>> = observer(({ record }) => {
  const onCheckboxClick = async () => {
    const korekcijePayload = ptp2Service.formatKorekcijaPayload(ptp2ObrazacStore.dozvoljeneKorekcije, [
      { ...record, dozvoljena_korekcija_proizvoda: !record.dozvoljena_korekcija_proizvoda },
    ]);
    const successfulSubmit = await ptp2ObrazacStore.submitDozvoljenePtp2Korekcije(korekcijePayload);

    if (successfulSubmit) {
      const updatedProizvodiList = ptp2ObrazacStore.initialState.proizvodi.map(proizvod => {
        const { id } = record;
        if (proizvod.id === id)
          return { ...proizvod, dozvoljena_korekcija_proizvoda: !proizvod.dozvoljena_korekcija_proizvoda };
        return proizvod;
      });

      ptp2ObrazacStore.handleInitialStateChange('proizvodi', updatedProizvodiList);
    }
  };

  return (
    <Checkbox
      checked={record.dozvoljena_korekcija_proizvoda}
      disabled={!ptp2ObrazacStore.getDozvoljeneKorekcije}
      onClick={onCheckboxClick}
    />
  );
});

export const TableProizvodi: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetProizvod> = [
    {
      title: `${t('OBRASCI.PTP2.VRSTE_PROIZVODA_PO_PERIODIMA')}`,
      width: '150px',
      dataIndex: ['products_nr', 'display_product_category'],
      key: 'display_product_category',
    },
    {
      title: `${t('OBRASCI.PTP2.NAZIV_PROIZVODA')}`,
      width: '150px',
      dataIndex: ['products_type', 'display_product'],
      key: 'display_product',
      render: (_: any, record: IGetProizvod) => (
        <div>{`${record.products_type.display_product}${
          record.products_type.description ? ` / ${record.products_type.description}` : ''
        }${record.products_type.p_class ? ` - ${record.products_type.p_class}` : ''}`}</div>
      ),
    },
    {
      title: `${t('OBRASCI.PTP2.KOLICINA_PROIZVODA_STAVLJENIH_NA_TRZISTE')}`,
      dataIndex: 'kolicina_proizvoda_stavljenih_na_trziste',
      key: 'kolicina_proizvoda_stavljenih_na_trziste',
      width: '250px',
    },
    {
      title: `${t('OBRASCI.PTP2.KOLICINA_PROIZVODA_KG')}`,
      dataIndex: 'kolicina_proizvoda',
      key: 'kolicina_proizvoda',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.PDV')}`,
      dataIndex: 'pdv',
      key: 'pdv',
      width: '100px',
    },
  ];

  const editButtonColumn: ColumnType<IGetProizvod> = {
    title: ``,
    key: 'operation',
    fixed: 'right',
    width: '50px',
    align: 'center',
    render: (_, record) => <EditProizvodButton record={record} />,
  };

  const korekcijaColumn: ColumnType<IGetProizvod> = {
    title: `Dozvoli korekcije prozivoda`,
    key: 'dozvoljena_korekcija_proizvoda',
    fixed: 'right',
    width: '100px',
    align: 'center',
    render: (_, record) => <KorekcijaCheckbox record={record} />,
  };

  const columns =
    obrasciStore.zakljucanObrazac && authStore.isAdmin
      ? [...baseColumns, korekcijaColumn, editButtonColumn]
      : [...baseColumns, editButtonColumn];

  return (
    <TableData
      scrollConfig={['#ptp2ProizvodiTabelHeader', '#obrazacWizardHeader']}
      name="proizvodi-ptp2"
      columns={columns}
      dataSource={[...ptp2ObrazacStore.getInitialState.proizvodi]}
    />
  );
});
