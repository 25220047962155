import { Dayjs } from 'dayjs';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';

export enum LCPVrstaInstalacije {
  NONIED = 'NONIED',
  IED = 'IED',
}

export enum LCPRelevantnoPoljeDirektive {
  ChapterIII = 'ChapterIII',
  ChapterIV = 'ChapterIV',
  ChapterV = 'ChapterV',
  ChapterVI = 'ChapterVI',
}

export enum LCPStatus {
  functional = 'functional',
  disused = 'disused',
  decomissioned = 'decommissioned',
  notRegulater = 'notRegulated',
}

export enum LCPVrstaInstalacionogDela {
  LCP = 'LCP',
  WI = 'WI',
  coWI = 'coWI',
}

export enum LCPKategorijaPostrojenjaZaSagorevanje {
  Boiler = 'Boiler',
  DieselEngine = 'DieselEngine',
  GasEngine = 'GasEngine',
  GasTurbine = 'GasTurbine',
  Others = 'Others',
}

export interface LCPOpsteInfo {
  preduzece_id: string;
  postrojenje_id: string;
}

export interface ILCPRegistarListResponse {
  preduzece: string;
  nacionalni_id: string;
  istorijski_id: string;
  id_proizvodnog_pogona: string;
  postrojenje: string;
  id_instalacije: string;
  naziv_instalacije: string;
}

export interface ILCPFilters {
  preduzece_id: string;
  postrojenje_id: string;
  postrojenje__nacionalni_id__ilike: string;
  postrojenje__istorijski_id__ilike: string;
  id_proizvodnog_pogona__ilike: string;
  id_instalacije__ilike: string;
  naziv_instalacije__ilike: string;
}

export interface LCPRequest {
  preduzece_id: string;
  postrojenje_id: string;
  naziv_instalacije: string;
  geografska_sirina: number | null;
  geografska_duzina: number | null;
  proizvodni_pogon_id: string;
  id: string;
  id_instalacije: string;
  id_proizvodnog_pogona: string;
  instalacioni_deo: ILCPInstalacioniDeo[];
  vrsta_instalacije: LCPVrstaInstalacije | undefined;
  relevantno_poglavlje_direktive: LCPRelevantnoPoljeDirektive | undefined;
  status: LCPStatus | undefined;
}

export interface ILCPRegistar extends LCPRequest {
  preduzece: IGetCompany;
  postrojenje: IGetFacility;
}

export interface ILCPInstalacioniDeo {
  id_instalacionog_dela: string;
  naziv_instalacionog_dela: string;
  vrsta_instalacionog_dela: LCPVrstaInstalacionogDela | undefined;
  kategorija_postrojenja_za_sagorevanje: LCPKategorijaPostrojenjaZaSagorevanje | undefined;
  u_okviru_rafinerije: boolean;
  ukupan_toplotni_ulaz_mw: number | null;
  geografska_sirina: number | null;
  geografska_duzina: number | null;
  datum_pocetka_rada: string | Dayjs;
  status: LCPStatus | undefined;
  izvori_zagadjenja_vazduha: ILCPIzvorZagadjenja[];
}

export interface ILCPIzvorZagadjenja {
  lcp_instalacioni_deo_id: string;
  izvor_zagadjenja_vazduha_id: string;
  izvor_zagadjenja_vazduha: IGetAir;
}
