import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import {
  MAX_LENGTH_RULE,
  MORE_THEN_0,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { IGetProizvodiObrazac1 } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RuleConfig } from 'typescript';

export const NewProizvodiObrazac1Form: React.FC = observer(() => {
  const { t } = useTranslation();

  const form = useFormInstance();

  const defaultJedinicaMereOption: ISifarnikInitial | undefined = obrazac1Store.getProizvodiForm.jedinica_mere_id
    ? {
        label: (obrazac1Store.getProizvodiForm as IGetProizvodiObrazac1).jedinica_mere.name_sr,
        value: obrazac1Store.getProizvodiForm.jedinica_mere_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  const uniqueProizvodRule = (field: 'sifra' | 'naziv'): RuleConfig => ({
    validator: (_: any, value: string) => {
      const nazivProizvoda = field === 'sifra' ? form.getFieldValue('naziv_proizvoda') : value;
      const sifraProizvoda = field === 'sifra' ? value : form.getFieldValue('sifra_proizvoda');
      const matchingRowExists = obrazac1Store.initialState.proizvodi.find(
        proizvod => proizvod.naziv_proizvoda === nazivProizvoda && proizvod.sifra_proizvoda === sifraProizvoda
      );
      const isRowBeingEdited =
        obrazac1Store.proizvodi_form.naziv_proizvoda === nazivProizvoda &&
        obrazac1Store.proizvodi_form.sifra_proizvoda === sifraProizvoda;
      if (matchingRowExists && !isRowBeingEdited)
        return Promise.reject(new Error('Kombinacija sifre i naziva proizvoda mora biti unikatna'));
      return Promise.resolve();
    },
  });

  return (
    <>
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.PRODUCT_KEY')}`}
        labelCol={{ span: 10 }}
        name={'sifra_proizvoda'}
        inputName={'sifra_proizvoda'}
        placeholder=""
        readOnly={readOnly}
        rules={[MAX_LENGTH_RULE(100), uniqueProizvodRule('sifra')]}
        onInputChange={() => form.validateFields(['naziv_proizvoda'])}
      />
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.PRODUCT_NAME')}`}
        labelCol={{ span: 10 }}
        name={'naziv_proizvoda'}
        inputName={'naziv_proizvoda'}
        placeholder=""
        onInputChange={() => form.validateFields(['sifra_proizvoda'])}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(300), uniqueProizvodRule('naziv')]}
        readOnly={readOnly}
      />
      <FormTextArea
        name={'opis'}
        labelCol={10}
        label={t('OBRASCI.OBRAZAC_1.DESCRIPTION')}
        rows={0}
        rules={[MAX_LENGTH_RULE(320)]}
        readOnly={readOnly}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE')}`}
        labelCol={{ span: 10 }}
        formName={'jedinica_mere_id'}
        inputName={'jedinica_mere_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        fetchOptinsList={obrazac1Store.fetchUnitCode}
        placeholder={''}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultJedinicaMereOption}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.ANNUAL_PRODUCTION')}`}
        labelCol={{ span: 10 }}
        name={'godisnja_proizvodnja'}
        inputName={'godisnja_proizvodnja'}
        placeholder=""
        rules={MORE_THEN_0_IS_REQUIRED}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.INSTALLED_CAPACITIES')}`}
        labelCol={{ span: 10 }}
        name={'instalirani_kapaciteti'}
        inputName={'instalirani_kapaciteti'}
        placeholder=""
        rules={MORE_THEN_0}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.AVERAGE_ENGAGED_CAPACITIES')}`}
        rules={MORE_THEN_0}
        labelCol={{ span: 10 }}
        name={'prosecno_angazovani_kapacitet'}
        inputName={'prosecno_angazovani_kapacitet'}
        placeholder=""
        readOnly={readOnly}
      />
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.METHOD_OF_STORAGE')}`}
        labelCol={{ span: 10 }}
        name={'nacin_lagerovanja'}
        inputName={'nacin_lagerovanja'}
        placeholder=""
        readOnly={readOnly}
        rules={[MAX_LENGTH_RULE(320)]}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.MAXIMUM_STORAGE_CAPACITY')}`}
        rules={MORE_THEN_0}
        labelCol={{ span: 10 }}
        name={'maksimalni_kapacitet_lagera'}
        inputName={'maksimalni_kapacitet_lagera'}
        placeholder=""
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.AVERAGE_AMOUNT_OF_STORAGE')}`}
        rules={MORE_THEN_0}
        labelCol={{ span: 10 }}
        name={'prosecna_kolicina_na_lageru'}
        inputName={'prosecna_kolicina_na_lageru'}
        placeholder=""
        readOnly={readOnly}
      />
    </>
  );
});
