import { Modal } from 'components/Modal/Modal';
import { NewUnsanitaryLandfill } from 'components/NewForms/NewUnsanitaryLandfill/NewUnsanitaryLandfill';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './UnsanitaryLandfillModal.module.scss';
import { unsanitaryLandfillInitialState } from 'modules/unsanitarylandfill/unsanitarylandfill.constants';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import { Row } from 'antd';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { IGetUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { companyStore } from 'modules/company/company.store';

export const UnsanitaryLandfillModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Modal
      name="unsanitary-landfill-modal"
      open={modalStore.getCurrentOpenedModal.includes('unsanitary-landfill-modal')}
      className={`.and-modal-wrap .ant-modal ${styles.unsanitary_landfill}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        unsanitaryLandfillStore.setUnsanitaryLandfill(unsanitaryLandfillInitialState);
        modalStore.removeAllModals();
      }}
      title={
        <Row justify={'space-between'} style={{ marginRight: '10px' }}>
          <h3>{t('UNSANITARY_LANDFILL.TITLE')}</h3>
          {(unsanitaryLandfillStore.unsanitaryLandfill as IGetUnsanitaryLandfill).id && (
            <IsActiveSwitch
              isActive={unsanitaryLandfillStore.unsanitaryLandfill.is_active}
              toggleActivate={unsanitaryLandfillStore.toggleActiveUnsanitaryLandfill}
              disabled={!companyStore.getCompany.is_active}
            />
          )}
        </Row>
      }
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'40%'}
    >
      <NewUnsanitaryLandfill />
    </Modal>
  );
});
