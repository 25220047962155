import { sifarniciRepo } from './sifarnici.repo';
import { formStore } from 'store';
import {
  IDateArrayPropsData,
  ISifarniciFilters,
  ISifarniciFormData,
  ISifarniciTypeOfForm,
  ISubmitFormSifarnik,
  SIFARNICI_ROUTES,
} from './sifarnici.types';
import { SIFARNICI_TYPE_TABLE_COLUMNS, SIFARNIK_MODAL_DATA } from './sifarnici.constants.tsx';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import { IGetDRList } from './store/dRList/dRList.types.ts';
import { IPaginatedApiResponse } from 'typescript/NrizTypes.ts';
import { sifarniciStore } from './sifarnici.store.ts';

class SifarniciService {
  fetchSingleSifarnik<Sifarnik = any>(sifarnik_type: string, id: string): Promise<Sifarnik> {
    return sifarniciRepo.fetchSingleSifarnik(sifarnik_type, id);
  }
  fetchSingleSifarnikList = <SifarnikResponse = any>(
    type: string,
    filters: any
  ): IPaginatedApiResponse<SifarnikResponse> => {
    return sifarniciRepo.fetchSingleSifarnikList<SifarnikResponse>(type, filters);
  };

  postSingleSifarnik = <SifarniciPayload = any>(type: string, payload: SifarniciPayload) => {
    return sifarniciRepo.postSingleSifarnik<SifarniciPayload>(type, payload);
  };

  putSingleSifarnik = (type: string, payload: any) => {
    return sifarniciRepo.putSingleSifarnik(type, payload);
  };

  deleteSingleSifarnik = <SifarniciPayload = any>(type: string, id: string) => {
    return sifarniciRepo.deleteSingleSifarnik<SifarniciPayload>(type, id);
  };

  fetchUnitCodeByPollutant = (payload: any) => {
    return sifarniciRepo.fetchUnitCodeByPollutant(payload);
  };

  submitFormSifarnik = (props: ISubmitFormSifarnik) => {
    if (formStore.getCrudButtonType === 'submit') {
      return props.postForm(props.state);
    }

    if (formStore.getCrudButtonType === 'change') {
      return props.putForm(props.state);
    }
    if (formStore.getCrudButtonType === 'delete') {
      return props.deleteForm(props.state.id);
    }
  };

  tableColumnsSifarniciFinder = (type: string) => {
    return SIFARNICI_TYPE_TABLE_COLUMNS[type];
  };
  filtersSifarniciFinder = (type: string): ISifarniciFormData => {
    return SIFARNIK_MODAL_DATA[type].formData;
  };

  reverseKeyValue = (obj: any) => {
    const reversedObj = {};
    for (const key in obj) {
      const value = obj[key];
      (reversedObj as any)[value] = key;
    }

    return reversedObj;
  };

  fetchMakeDateAndYearObject = (dateArray: IDateArrayPropsData[], obj: any) => {
    let newObj = {};
    dateArray.map((item: IDateArrayPropsData) => {
      newObj = {
        ...newObj,
        [item.name]: !obj[item.name] || obj[item.name] === 'NULL' ? '' : dayjs(obj[item.name]),
      };
    });
    return { ...obj, ...newObj };
  };

  makeDateAndYearString = (dateArray: IDateArrayPropsData[], obj: any) => {
    let newObj = {};
    dateArray.map((item: IDateArrayPropsData) => {
      if (item.type === 'year') {
        newObj = {
          ...newObj,
          [item.name]: !obj[item.name] || obj[item.name] === 'NULL' ? null : dayjs(obj[item.name]).format('YYYY'),
        };
      }
      if (item.type === 'date' && sifarniciStore.sifarnik_type === SIFARNICI_ROUTES.AKTIVNOST_1) {
        newObj = {
          ...newObj,
          [item.name]: !obj[item.name] || obj[item.name] === 'NULL' ? null : dayjs(obj[item.name]).format('YYYY-MM-DD'),
        };
      }
    });

    return { ...obj, ...newObj };
  };

  removeRules = (arrayOptions: ISifarniciTypeOfForm[]) => {
    return arrayOptions.map((item: ISifarniciTypeOfForm) => omit(item, ['rules']));
  };

  sortDRList = <DrList>(items: IGetDRList[]): any[] => {
    const dList: IGetDRList[] = [];
    const rList: IGetDRList[] = [];
    items.map((item: IGetDRList) => {
      if (item.name?.includes('D')) {
        dList.push(item);
      }
      if (item.name?.includes('R')) {
        rList.push(item);
      }
    });

    return [...this.sortSingleDorRList<DrList>(dList), ...this.sortSingleDorRList<DrList>(rList)];
  };

  sortSingleDorRList = <_DrList>(items: IGetDRList[]): IGetDRList[] => {
    return items.sort((a: IGetDRList, b: IGetDRList) => {
      const nameA = parseInt(a.name.slice(1));
      const nameB = parseInt(b.name.slice(1));
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  };

  modifyPayloadSifarniciFilters = (payload: ISifarniciFilters): ISifarniciFilters => {
    return Object.fromEntries(
      Object.entries(payload).map(([key, value]) => {
        if (key === 'validity_from_year__gte' || key === 'validity_to_year__lte' || key === 'period_year__ilike') {
          return [key, dayjs(value).format('YYYY')];
        }
        if (key.includes('__gte') && value) {
          return [key, dayjs(value).format('YYYY-MM-DDT00:00:00.000[Z]')];
        }
        if (key.includes('__lte') && value) {
          return [key, dayjs(value).format('YYYY-MM-DDT23:59:59.999[Z]')];
        }
        return [key, value];
      })
    );
  };
}

export const sifarniciService = new SifarniciService();
