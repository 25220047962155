import { ROUTES } from 'modules/nriz/nriz.constants';
import { RegistriRoutes, RegistriEnum, RegistriTableData } from '../registri.types';
import { ILCPFilters, ILCPInstalacioniDeo, ILCPRegistar, LCPRequest } from './lcp.types';

import {
  InputSifarniciFormProps,
  ISifarniciComponentHandler,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';
import { LCPRegistar } from 'pages/Registri/components/LCPRegistar/LCPRegistar';
import i18n from 'translations/i18n.config';
import { FormInstance } from 'antd';

export const LCP_INITIAL_STATE: Partial<LCPRequest> = {
  preduzece_id: '',
  postrojenje_id: '',
  naziv_instalacije: '',
  geografska_sirina: null,
  geografska_duzina: null,
  proizvodni_pogon_id: '',
  id_instalacije: '',
  id_proizvodnog_pogona: '',
  vrsta_instalacije: undefined,
  relevantno_poglavlje_direktive: undefined,
  status: undefined,
  instalacioni_deo: [],
};

export const LCP_INSTALACIJA_INITIAL: Partial<ILCPInstalacioniDeo> = {
  id_instalacionog_dela: '',
  naziv_instalacionog_dela: '',
  vrsta_instalacionog_dela: undefined,
  kategorija_postrojenja_za_sagorevanje: undefined,
  u_okviru_rafinerije: false,
  ukupan_toplotni_ulaz_mw: null,
  geografska_sirina: null,
  geografska_duzina: null,
  datum_pocetka_rada: '',
  status: undefined,
  izvori_zagadjenja_vazduha: [],
};

// const PIB_FILTER: InputSifarniciFormProps = {
//   label: 'PIB',
//   name: 'pib',
//   type_of_form: 'input',
//   inputName: 'pib',
//   sortName: 'pib',
// };

const PREDUZECE_FILTER = (form: FormInstance<ILCPRegistar>): SearchScrollSifarniciFormProps => {
  return {
    placeholder: '',
    type_of_form: 'searchScroll',
    label: i18n.t('COMPANY.TITLE'),
    formName: 'preduzece_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    readOnly: false,
    sortName: 'naziv_preduzeca',
    onChange: () => form.setFieldValue('postrojenje_id', ''),
  };
};

const POSTROJENJE_FILTER = (form: FormInstance<ILCPRegistar>): SearchScrollSifarniciFormProps => {
  return {
    placeholder: '',
    type_of_form: 'searchScroll',
    filtersForFetch: {
      preduzece_id: form.getFieldValue('preduzece_id'),
    },
    formName: 'postrojenje_id',
    fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
    labelOptionAccessor: ['naziv_postrojenja'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    label: i18n.t('FACILITY.NAME'),
    sortName: 'naziv_postrojenja',
    hideInput: !form.getFieldValue('preduzece_id'),
  };
};

const NACIONALNI_ID_FILTER: InputSifarniciFormProps = {
  label: 'Nacionalni ID',
  name: 'postrojenje__nacionalni_id__ilike',
  type_of_form: 'input',
  inputName: 'postrojenje__nacionalni_id__ilike',
  sortName: 'postrojenje__nacionalni_id',
};

const ISTORIJSKI_ID_FILTER: InputSifarniciFormProps = {
  label: 'Istorijski ID',
  name: 'postrojenje__istorijski_id__ilike',
  type_of_form: 'input',
  inputName: 'postrojenje__istorijski_id__ilike',
  sortName: 'postrojenje__istorijski_id',
};

const ID_PROIZVODNOG_POGONA_FILTER: InputSifarniciFormProps = {
  label: 'ID proizvodnog pogona',
  name: 'id_proizvodnog_pogona__ilike',
  type_of_form: 'input',
  inputName: 'id_proizvodnog_pogona__ilike',
  sortName: 'id_proizvodnog_pogona',
};

const ID_INSTALACIJE_FILTER: InputSifarniciFormProps = {
  label: 'ID instalacije',
  name: 'id_instalacije__ilike',
  type_of_form: 'input',
  inputName: 'id_instalacije__ilike',
  sortName: 'id_instalacije',
};

const NAZIV_INSTALACIJE_FILTER: InputSifarniciFormProps = {
  label: 'Naziv instalacije',
  name: 'naziv_instalacije__ilike',
  type_of_form: 'input',
  inputName: 'naziv_instalacije__ilike',
  sortName: 'naziv_instalacije',
};

const lcpFilterComponents: ISifarniciComponentHandler = (form: FormInstance<ILCPRegistar>) => [
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  // PIB_FILTER,
  NACIONALNI_ID_FILTER,
  ISTORIJSKI_ID_FILTER,
  ID_PROIZVODNOG_POGONA_FILTER,
  ID_INSTALACIJE_FILTER,
  NAZIV_INSTALACIJE_FILTER,
];

const LCP_FILTERS_INITIAL_STATE: ILCPFilters = {
  preduzece_id: '',
  postrojenje_id: '',
  postrojenje__nacionalni_id__ilike: '',
  postrojenje__istorijski_id__ilike: '',
  id_proizvodnog_pogona__ilike: '',
  id_instalacije__ilike: '',
  naziv_instalacije__ilike: '',
};

export const LCP_TABLE_DATA: RegistriTableData<RegistriEnum.Lcp> = {
  columns: [
    { key: 'preduzece', dataIndex: ['preduzece', 'naziv'], title: 'Preduzeće' },
    { key: 'postrojenje', dataIndex: ['postrojenje', 'naziv_postrojenja'], title: 'Postrojenje' },
    {
      key: 'nacionalni_id',
      dataIndex: ['postrojenje', 'nacionalni_id'],
      title: 'Nacionalni ID',
    },
    {
      key: 'istorijski_id',
      dataIndex: ['postrojenje', 'istorijski_id'],
      title: 'Istorijski ID',
    },
    {
      key: 'id_proizvodnog_pogona',
      dataIndex: 'id_proizvodnog_pogona',
      title: 'ID proizvodnog pogona',
    },
    {
      key: 'id_instalacije',
      dataIndex: 'id_instalacije',
      title: 'ID instalacije',
    },
    {
      key: 'naziv_instalacije',
      dataIndex: 'naziv_instalacije',
      title: 'Naziv instalacije',
    },
  ],
  filters: {
    components: (form: FormInstance<LCPRequest>) => {
      return lcpFilterComponents(form);
    },
    initialState: LCP_FILTERS_INITIAL_STATE,
  },
};

export const LCP_ROUTE_DATA: RegistriRoutes = {
  urlRoute: `/${ROUTES.REGISTRI}/${RegistriEnum.Lcp}`,
  component: <LCPRegistar />,
  apiRoute: 'lcp_instalacije/',
};

export const LCP_OPSTE_INITIAL = {
  preduzece_id: '',
  postrojenje_id: '',
};
