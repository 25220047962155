import { Col, Form, Popover, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInput } from 'components/FormInput/FormInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NewUserForm.module.scss';
import { observer } from 'mobx-react-lite';
import { authStore, formStore, modalStore } from 'store';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { IFormProps, RuleConfig } from 'typescript/NrizTypes';
import { EMAIL_FIELD_RULE, MAX_LENGTH_RULE, PHONE_FIELD_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { IGetUser, IPostUser, UserFormType, UserLanguages } from 'modules/user/user.types';
import { userInitialState } from 'modules/user/user.constants.ts';
import { userStore } from 'modules/user/user.store';
import { useLocation } from 'react-router-dom';
import { FormSwitch } from 'components/FormSwitch/FormSwitch';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { Button } from 'components/Button/Button';
import { Switch } from 'components/Switch/Switch';
import { RoutePathEnum } from 'router/router.constants';

export const NewUserForm: React.FC<IFormProps> = observer(props => {
  const [form] = useForm();
  const { t } = useTranslation();

  // skloni kupus iz komponente, napravi logiku za polja forme kroz funkciju u odnosu na to koja se stranica pokazuje i koje su odgovornosti korisnika

  const [showChangeOwner, setShowChangeOwner] = useState(false);

  useEffect(() => {
    if (!props.readOnly && !authStore.isCompanyUser && !authStore.isFacilityUser && userStore.isMainCompanyUser) {
      setShowChangeOwner(true);
    } else {
      setShowChangeOwner(false);
    }
  }, [props.readOnly, userStore.getUser]);

  useEffect(() => {
    return () => userStore.setUserTypeForm(null);
  }, []);

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return userStore.submitUser(newValues as any);
  };
  const { pathname } = useLocation();

  const languageOptions: { label: string; value: UserLanguages }[] = [
    { label: t('SERBIAN'), value: UserLanguages.SRPSKI },
    { label: t('ENGLISH'), value: UserLanguages.ENGLESKI },
  ];

  useEffect(() => {
    if (props.passForm) {
      props.passForm(form);
    }
    form.setFieldsValue({ ...userStore.getUser });
  }, [userStore.getUser]);

  const showAdditionalUserInfo =
    !pathname.includes(RoutePathEnum.REGISTRACIJA) &&
    userStore.userTypeForm !== UserFormType.UserAdministrator &&
    //todo: proveriti ovo
    !userStore.isUserAdmin;

  const showAdminOwnerControl = userStore.isMainCompanyUser && !pathname.includes(RoutePathEnum.REGISTRACIJA);

  const showSuperAdminControl = authStore.isSuperAdmin && userStore.isUserAdmin;

  const showUserRoleAssingmentControl =
    (authStore.isAdmin || authStore.isCompanyOwner) &&
    !userStore.isMainCompanyUser &&
    !userStore.isUserAdmin &&
    !(userStore.userTypeForm === UserFormType.UserCompany || userStore.userTypeForm === UserFormType.UserAdministrator);

  const userAssignmentChecboxRule = (): RuleConfig => {
    const fieldNames = ['facility_user', 'company_user'];
    return {
      validator(_, _value) {
        if (userStore.userTypeForm === UserFormType.UserFacility) return Promise.resolve();
        if (fieldNames.some(fieldName => form.getFieldValue(fieldName))) return Promise.resolve();
        return Promise.reject(new Error('Morate odabrati barem jednu opciju kako biste sačuvali podatke!'));
      },
    };
  };

  return (
    <Form
      form={form}
      initialValues={userStore.getUser ?? userInitialState}
      name={props.formName}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      layout="horizontal"
      onFinish={async (values: IPostUser) => {
        await userStore.submitUser(values);
        if (userStore.userTypeForm === UserFormType.UserFacility) userStore.fetchUserFacilityList();
      }}
      className={styles.user_form}
      labelAlign="left"
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          onFinishFailed();
        }
      }}
    >
      <FormInput
        label={t('USER.USERNAME')}
        name="username"
        inputName={'username'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(100)]}
        readOnly={props.readOnly}
        disabled={!!userStore.user?.username}
      />
      <FormInput
        label={t('USER.SURNAME')}
        name="prezime"
        inputName={'prezime'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(300)]}
        readOnly={props.readOnly}
        disabled={!!userStore.user?.prezime}
      />
      <FormInput
        label={t('USER.NAME')}
        name="ime"
        inputName={'ime'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(40)]}
        readOnly={props.readOnly}
        disabled={!!userStore.user?.ime}
      />
      <FormInput
        label={t('USER.EMAIL')}
        name="email"
        inputName={'email'}
        type={'email'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), EMAIL_FIELD_RULE, MAX_LENGTH_RULE(100)]}
        readOnly={props.readOnly}
        disabled={!!userStore.user?.email && !authStore.isAdmin}
      />
      <FormInput
        label={t('USER.PHONE')}
        name="broj_telefona"
        inputName={'broj_telefona'}
        type={'text'}
        className={`removeArrow`}
        placeholder="+381 60666555"
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), PHONE_FIELD_RULE, MAX_LENGTH_RULE(50)]}
        readOnly={props.readOnly}
      />

      <FormSelect
        allowClear={!props.readOnly}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        name={'jezik'}
        label={t('USER.LANGUAGE')}
        options={languageOptions}
        disabled={props.readOnly}
        className={props.readOnly ? styles.disabledDropdown : ''}
      />

      {showSuperAdminControl && (
        <FormSwitch label="Sistem administrator" name={'sistem_administrator'} disabled={true} />
      )}

      {showAdditionalUserInfo && (
        <>
          <FormInput
            label={`Alternativni telefon`}
            name="alternativni_telefon"
            inputName={'alternativni_telefon'}
            type={'text'}
            className={`removeArrow`}
            placeholder="+381 60666555"
            readOnly={props.readOnly}
            rules={[PHONE_FIELD_RULE]}
          />
          <FormInput
            label={'Funkcija osobe'}
            name="function_of_person"
            inputName={'function_of_person'}
            type={'text'}
            readOnly={props.readOnly}
            rules={[MAX_LENGTH_RULE(200)]}
          />
        </>
      )}
      {showAdminOwnerControl && (
        <Row justify={'space-between'} align={'middle'} style={{ marginBottom: '20px' }}>
          <Col span={9} style={{ fontWeight: 'bold' }}>
            Administrator preduzeća:
          </Col>
          <Col span={15}>
            {!showChangeOwner ? (
              <Switch
                style={{ marginLeft: '5px' }}
                name={'main_company_user'}
                disabled={true}
                checked={userStore.isMainCompanyUser}
              />
            ) : (
              <Button
                style={{ width: '100%' }}
                name={'promeni-vlasnika'}
                onClick={() => {
                  modalStore.changeModalName('change-company-owner');
                }}
              >
                Promeni administratora preduzeća
              </Button>
            )}
          </Col>
        </Row>
      )}
      {showUserRoleAssingmentControl && (
        <div className={styles.roleAssignmentWrapper}>
          <p className={styles.assignmentHeading}>Dozvoliti korisniku pristup obrascima na nivou:</p>

          <FormSwitch
            name="company_user"
            label="Preduzeća"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            disabled={
              props.readOnly ||
              !!(!userStore.user.facility_user && userStore.userTypeForm !== UserFormType.UserFacility)
            }
            rules={[userAssignmentChecboxRule()]}
            onSwitchChange={() => form.validateFields(['facility_user'])}
          />
          {!(userStore.userTypeForm === UserFormType.UserFacility) && userStore.user.facility_user && (
            <Popover>
              <FormSwitch
                name="facility_user"
                label="Postrojenja"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
                disabled={props.readOnly}
                rules={[userAssignmentChecboxRule()]}
                onSwitchChange={() => form.validateFields(['company_user'])}
                tooltip={
                  props.readOnly
                    ? undefined
                    : {
                        title:
                          'Ovom akcijom korisnika uklanjate sa svih postrojenja na koja je dodeljen i ukidate mu obavezu podnošenja obrazaca za postrojenja',
                        placement: 'top',
                        arrow: false,
                      }
                }
              />
            </Popover>
          )}
        </div>
      )}
      {props.showButtons && (
        <CRUDButton showButton={!!(userStore.user as IGetUser).id} form={form} withOutDelete={true} />
      )}
    </Form>
  );
});
