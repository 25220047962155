import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { USER_FILTERS_INITIAL_STATE, USER_FILTERS_OPTIONS } from 'modules/user/user.constants.ts';
import { userStore } from 'modules/user/user.store';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { authStore } from 'store';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

import { IFilterTableProps } from 'typescript/NrizTypes';

interface IUserFiltersProps {
  isFacility: boolean;
}

export const UserListFilters: React.FC<IUserFiltersProps> = observer(props => {
  const location = useLocation();

  const [form] = useForm();

  useEffect(() => {
    return () => {
      if (!location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA)) {
        if (props.isFacility) {
          filtersStore.setFilters(FiltersNameEnum.KORISNIK_POSTROJENJE, USER_FILTERS_INITIAL_STATE);
        }
        filtersStore.setFilters(FiltersNameEnum.KORISNIK, USER_FILTERS_INITIAL_STATE);
      }
    };
  }, []);

  const fetchUserList = () => {
    return props.isFacility
      ? userStore.fetchUserFacilityList(paginationInitialState)
      : location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin
      ? userStore.fetchUserList(paginationInitialState)
      : userStore.fetchUserCompanyList(paginationInitialState);
  };

  const dataFilters = {
    form: form,
    initialState: props.isFacility
      ? filtersStore.getFilters[FiltersNameEnum.KORISNIK_POSTROJENJE]
      : filtersStore.getFilters[FiltersNameEnum.KORISNIK],
    filterStoreName: props.isFacility ? FiltersNameEnum.KORISNIK_POSTROJENJE : FiltersNameEnum.KORISNIK,
    filterOptions: USER_FILTERS_OPTIONS(location.pathname.includes(RoutePathEnum.LISTA_KORISNIKA) && authStore.isAdmin),
    fetchTableList: fetchUserList,
  } as IFilterTableProps;

  return (
    <div id="userFilters">
      <FilterTableForm data={dataFilters} />
    </div>
  );
});
