import { ColumnsType } from 'antd/es/table';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetPonovnaUpotrebaDeo6 } from 'modules/obrasci/store/deo6/deo6.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberWithDecimals } from 'utils/formatNumberWithDecimals';

const EditDeo6PonovnaUpotrebaOtpadaButton: React.FC<EditButtonProps<IGetPonovnaUpotrebaDeo6>> = observer(
  ({ record }) => {
    return (
      <RowEditPreviewButton
        name="deo6-ponovna-upotreba"
        modalName="deo6-ponovna-upotreba-modal"
        onClick={() => {
          deo6ObrazacStore.handleChange('index_id', record.indeksni_broj_otpada_id);
          // deo6ObrazacStore.handleChange('weee_categories', record.indeksni_broj_otpada.weee);
          if (record.indeksni_broj_otpada.weee && Number(deo6ObrazacStore.godina) >= 2020) {
            deo6ObrazacStore.handleChange('weee_categories', true);
          }
          deo6ObrazacStore.handleChange('ponovna_upotreba_otpada_form', record);
        }}
        preview={obrasciStore.zakljucanObrazac}
      />
    );
  }
);

export const Deo6PonovnaUpotrebaOtpadWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetPonovnaUpotrebaDeo6> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 200,
      dataIndex: ['indeksni_broj_otpada', 'index_number'],
      key: 'waste',
      render: (_: any, record: IGetPonovnaUpotrebaDeo6) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.DEO_6.KOLICINA_PONOVNO_UPOTREBLJENOG')}`,
      width: 200,
      render(_, record) {
        if (!record.kolicina) return <></>;
        return <span>{formatNumberWithDecimals(record.kolicina, 4)}</span>;
      },
      key: 'kolicina',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetPonovnaUpotrebaDeo6) => <EditDeo6PonovnaUpotrebaOtpadaButton record={record} />,
    },
  ];

  return (
    <>
      <div id="ponovnaUpotrebaPoruka">
        <h3 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEO_6.PONOVNA_UPOTREBA_ODNOSI_SE_SAMO')}</h3>
        <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEO_6.PONOVNA_UPOTREBA_PRIMER')}</h4>
      </div>
      <InsideContentWrapper
        header={<WizardObrazacListHeader name={'deo6-ponovna-upotreba-form'} modalName="deo6-ponovna-upotreba-modal" />}
      >
        <TableData
          scrollConfig={['#obrazacWizardHeader', '#obrazacTableHeader', '#ponovnaUpotrebaPoruka']}
          name="ponovna-upotreba-deo6"
          columns={baseColumns}
          dataSource={[...deo6ObrazacStore.initialState.ponovna_upotreba_otpada]}
        />
      </InsideContentWrapper>
    </>
  );
});
