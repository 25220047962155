import dayjs from 'dayjs';
import {
  IPostPtp2ReportCustoms,
  IGetPtp2ReportCustoms,
  IPostProductCategories,
  IGetProductCategories,
  IPtp2ReportCustomsFilters,
} from './ptp2ReportCustoms.types';
import i18n from 'translations/i18n.config';
import { Checkbox, FormInstance } from 'antd';
import { MAX_LENGTH_RULE, PIB_FIELD_RULE } from 'modules/nriz/nriz.constants';
import {
  InputSifarniciFormProps,
  DateSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
  SifarniciLists,
  TableSifarniciFormProps,
  ISifarniciTypeOfForm,
} from 'modules/sifarnici/sifarnici.types';
import { ColumnsType } from 'antd/es/table';
import { DESCRIPTION_FORM, IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
import { DESCRIPTION_TABLE, IS_ACTIVE_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormListType } from 'typescript/NrizTypes';

export const PRODUCT_CATEGORIES_INITIAL_STATE: IPostProductCategories = {
  product: '',
  net_mass: '',
  description: '',
  exempted: false,
  remarks: '',
  is_active: true,
};

export const PRODUCT_CATEGORIES_TABLE_DATA: ColumnsType<IGetProductCategories> = [
  IS_ACTIVE_TABLE as any,
  {
    title: i18n.t('SIFARNICI.PROIZVOD'),
    width: '200px',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: `${i18n.t('SIFARNICI.NETO_MASA')}`,
    width: '150px',
    dataIndex: 'net_mass',
    key: 'net_mass',
  },
  DESCRIPTION_TABLE,
  {
    title: `${i18n.t('SIFARNICI.OSLOBODJENI')}`,
    width: '150px',
    dataIndex: 'exempted',
    key: 'exempted',
    render: (_: any, record: IGetProductCategories) => <Checkbox checked={record.exempted} disabled={true} />,
    align: 'center',
  },
  {
    title: `${i18n.t('WIZARD_TABS.NAPOMENA')}`,
    width: '200px',
    dataIndex: 'remarks',
    key: 'remarks',
  },
];

export const PRODUCT_CATEGORIES_FORM_OPTIONS = (_: FormInstance<IGetProductCategories>): ISifarniciTypeOfForm[] => {
  return [
    { ...IS_ACTIVE_SWITCH, labelCol: { span: 8 } } as SwitchSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('SIFARNICI.PROIZVOD'),
      name: 'product',
      filterName: 'product__ilike',
      inputName: 'product',
      rules: [MAX_LENGTH_RULE(200)],
      sortName: 'product',
      labelCol: { span: 8 },
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'net_mass',
      filterName: 'net_mass__ilike',
      label: i18n.t('SIFARNICI.NETO_MASA'),
      rules: [MAX_LENGTH_RULE(50)],
      inputName: 'net_mass',
      sortName: 'net_mass',
      labelCol: { span: 8 },
    } as InputSifarniciFormProps,

    {
      ...DESCRIPTION_FORM(500),
      wrapperCol: 16,
      labelCol: 8,
    } as TextAreaSifarniciFormProps,

    {
      type_of_form: 'switch',
      name: 'exempted',
      label: i18n.t('SIFARNICI.OSLOBODJENI'),
      labelCol: { span: 8 },
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'area',
      name: 'remarks',
      filterName: 'remarks__ilike',
      label: i18n.t('WIZARD_TABS.NAPOMENA'),
      rows: 4,
      wrapperCol: 16,
      labelCol: 8,
      rules: [MAX_LENGTH_RULE(300)],
    } as TextAreaSifarniciFormProps,
  ];
};

export const PTP2_REPORT_CUSTOMS_FILTERS_INITIAL_STATE: IPtp2ReportCustomsFilters = {
  pro_id__ilike: null,
  period_year__ilike: '',
  pib__ilike: '',
  company_name__ilike: '',
  address__ilike: '',
  location__ilike: '',
  is_active: null,
};
export const PTP2_REPORT_CUSTOMS_INITIAL_STATE: IPostPtp2ReportCustoms = {
  pro_id: null,
  period_year: '',
  pib: '',
  company_name: '',
  address: '',
  location: '',
  is_active: true,
  product_categories: [],
};

export const PTP2_REPORT_CUSTOMS_TABLE_DATA = [
  {
    title: 'Pro id',
    width: '150px',
    dataIndex: 'pro_id',
    key: 'pro_id',
  },
  {
    title: `${i18n.t('YEAR')}`,
    width: '100px',
    dataIndex: 'period_year',
    key: 'period_year',
  },
  {
    title: `${i18n.t('COMPANY.PIB')}`,
    width: '100px',
    dataIndex: 'pib',
    key: 'pib',
  },
  {
    title: i18n.t('OBRASCI.OBRAZAC3.NAZIV_PREDUZECA'),
    width: '200px',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: `${i18n.t('ADDRESS')}`,
    width: '100px',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: `${i18n.t('SIFARNICI.LOCATION')}`,
    width: '100px',
    dataIndex: 'location',
    key: 'location',
  },
];

export const PTP2_REPORT_CUSTOMS_FORM_OPTIONS = (_: FormInstance<IGetPtp2ReportCustoms>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      label: 'Pro id',
      name: 'pro_id',
      filterName: 'pro_id__ilike',
      inputName: 'pro_id',
      rules: [MAX_LENGTH_RULE(15)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'period_year',
      filterName: 'period_year__ilike',
      label: i18n.t('YEAR'),
      format: 'YYYY',
      picker: 'year',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('COMPANY.PIB'),
      name: 'pib',
      filterName: 'pib__ilike',
      inputName: 'pib',
      rules: [PIB_FIELD_RULE],
      precision: 0,
      type: 'number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('OBRASCI.OBRAZAC3.NAZIV_PREDUZECA'),
      name: 'company_name',
      filterName: 'company_name__ilike',
      inputName: 'company_name',
      rules: [MAX_LENGTH_RULE(250)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('ADDRESS'),
      name: 'address',
      filterName: 'address__ilike',
      type: 'text',
      inputName: 'address',
      rules: [MAX_LENGTH_RULE(300)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('SIFARNICI.LOCATION'),
      type: 'text',
      name: 'location',
      filterName: 'location__ilike',
      inputName: 'location',
      rules: [MAX_LENGTH_RULE(200)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'table',
      tableColumns: PRODUCT_CATEGORIES_TABLE_DATA,
      listName: 'product_categories',
      configPath: { formListType: FormListType.Sifarnici, formListSubType: SifarniciLists.PRODUCT_CATEGORIES },
      title: <div style={{ textDecoration: 'underline' }}>Kategorije</div>,
      formModalInitialValues: PRODUCT_CATEGORIES_INITIAL_STATE,
    } as TableSifarniciFormProps,
  ];
};

//
