import { ColumnsType } from 'antd/es/table';
import { IGetMethodTypes, IMethodTypesFilters, IPostMethodTypes } from './methodTypes.types';
import { FormInstance } from 'antd';
import {
  DESCRIPTION_TABLE,
  NAME_SR_TABLE,
  NAME_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  DESCRIPTION_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  ISifarniciTypeOfForm,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import i18n from 'translations/i18n.config';

export const METHOD_TYPES_INITIAL_STATE: IPostMethodTypes = {
  validity_from_date: '',
  validity_to_date: '',
  name: '',
  name_sr: '',
  description: '',
  method_type_code_id: '',
  method_designation_id: '',
  medium_id: '',
  method_basis_id: '',
  is_active: true,
};
export const METHOD_TYPES_FILTERS_INITIAL_STATE: IMethodTypesFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  name__ilike: '',
  description__ilike: '',
  name_sr__ilike: '',
  method_type_code_id: '',
  method_designation_id: '',
  medium_id: '',
  method_basis_id: '',
  is_active: null,
};

export const METHOD_TYPES_TABLE_DATA: ColumnsType<IGetMethodTypes> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
  {
    title: i18n.t('SIFARNICI.SIFRA_TIPA_METODE'),
    width: '200px',
    dataIndex: ['method_type_code', 'name_sr'],
    key: 'method_type_code',
  },
  {
    title: i18n.t('SIFARNICI.OZNAKA_METODE'),
    width: '200px',
    dataIndex: ['method_designation', 'name'],
    key: 'method_designation',
  },
  {
    title: i18n.t('SIFARNICI.SREDNJI_KOD'),
    width: '200px',
    dataIndex: ['medium_code', 'name_sr'],
    key: 'medium_code',
  },
  {
    title: i18n.t('SIFARNICI.OSNOVA_METODE'),
    width: '200px',
    dataIndex: ['method_basis_code', 'name_sr'],
    key: 'method_basis_code',
  },
];

export const METHOD_TYPES_FORM_OPTIONS = (form: FormInstance<IGetMethodTypes>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(500),
    NAME_SR_FORM(500),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    DESCRIPTION_FORM(500),
    {
      type_of_form: 'searchScroll',
      label: i18n.t('SIFARNICI.SIFRA_TIPA_METODE'),
      formName: 'method_type_code_id',
      sortName: 'method_type_code_id',
      fetchOptinsList: SIFARNICI_ROUTES.METHOD_TYPE_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: i18n.t('SIFARNICI.OZNAKA_METODE'),
      formName: 'method_designation_id',
      // sortName: 'method_designation_id',
      fetchOptinsList: SIFARNICI_ROUTES.METHOD_DESIGNATION,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: i18n.t('SIFARNICI.SREDNJI_KOD'),
      formName: 'medium_id',
      sortName: 'medium_id',
      fetchOptinsList: SIFARNICI_ROUTES.MEDIUM_CODE,
      filtersForFetch: {},
      objName: 'medium_code',
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: i18n.t('SIFARNICI.OSNOVA_METODE'),
      formName: 'method_basis_id',
      sortName: 'method_basis_id',
      fetchOptinsList: SIFARNICI_ROUTES.METHOD_BASIS_CODE,
      filtersForFetch: {},
      objName: 'method_basis_code',
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
  ];
};
