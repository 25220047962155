import { ptp2Repo } from './ptp2.repo';
import { IGetProizvod, IPtp2KorekcijaReda, IPtp2Korekcije } from './ptp2.types';

class Ptp2Service {
  submitDozvoljeneKorekcije = (payload: IPtp2Korekcije, id: string) => {
    return ptp2Repo.submitDozvoljeneKorekcije(payload, id);
  };

  formatKorekcijaPayload = (dozvoljene_korekcije: boolean, proizvodi: IGetProizvod[] = []): IPtp2Korekcije => {
    if (!proizvodi.length) {
      return {
        dozvoljene_korekcije,
        proizvodi,
      };
    }
    const formattedProizvodi = proizvodi.map((proizvod): IPtp2KorekcijaReda => {
      const { products_type_id, dozvoljena_korekcija_proizvoda } = proizvod;

      return {
        products_type_id,
        dozvoljena_korekcija_proizvoda: dozvoljene_korekcije ? dozvoljena_korekcija_proizvoda : false,
      };
    });

    return {
      dozvoljene_korekcije,
      proizvodi: formattedProizvodi,
    };
  };
}

export const ptp2Service = new Ptp2Service();
