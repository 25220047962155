import i18n from 'translations/i18n.config';
import {
  DateSifarniciFormProps,
  InputSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from './sifarnici.types';
import { MAX_LENGTH_RULE, MORE_THEN_0 } from 'modules/nriz/nriz.constants';
import { FormInstance } from 'antd';

export const THE_ORDER_FORM = {
  type_of_form: 'input_number',
  name: 'the_order',
  filterName: 'the_order__ilike',
  // todo: prevod
  label: 'Redosled',
  precision: 0,
  inputName: 'the_order',
  rules: MORE_THEN_0,
} as InputSifarniciFormProps;

export const TITLE_FORM = (ruleNumber: number) => {
  return {
    type_of_form: 'input',
    name: 'title',
    filterName: 'title__ilike',
    label: 'Naslov',
    inputName: 'title',
    rules: [MAX_LENGTH_RULE(ruleNumber)],
  } as InputSifarniciFormProps;
};

export const DESCRIPTION_FORM = (ruleNumber: number): TextAreaSifarniciFormProps => {
  return {
    type_of_form: 'area',
    name: 'description',
    filterName: 'description__ilike',
    label: i18n.t('SIFARNICI.OPIS'),
    rules: [MAX_LENGTH_RULE(ruleNumber)],
    rows: 3,
    labelCol: 10,
    wrapperCol: 14,
  };
};

export const DESCRIPTION_SR_FORM = (ruleNumber: number) => {
  return {
    type_of_form: 'area',
    name: 'description_sr',
    filterName: 'description_sr__ilike',
    label: `${i18n.t('SIFARNICI.OPIS')} ${i18n.t('NA_SRPSKOM')}`,
    rules: [MAX_LENGTH_RULE(ruleNumber)],
    rows: 3,
    labelCol: 10,
    wrapperCol: 14,
  } as TextAreaSifarniciFormProps;
};

export const VERSION_FORM = {
  type_of_form: 'input_number',
  name: 'version',
  filterName: 'version__ilike',
  precision: 0,
  // todo: prevod
  label: i18n.t('Verzija'),
  inputName: 'version',
} as InputSifarniciFormProps;

export const CODE_FORM = {
  type_of_form: 'input_number',
  name: 'code',
  filterName: 'code__ilike',
  precision: 0,
  // todo: prevod
  label: i18n.t('USER.PASSWORD'),
  inputName: 'code',
} as InputSifarniciFormProps;

export const CODE_2_FORM = {
  type_of_form: 'input',
  name: 'code2',
  filterName: 'code2__ilike',
  // todo: prevod
  label: `${i18n.t('USER.PASSWORD')} 2`,
  inputName: 'code2',
} as InputSifarniciFormProps;

export const NAME_FORM = (ruleNumber: number) => {
  return {
    type_of_form: 'input',
    name: 'name',
    filterName: 'name__ilike',
    // todo: prevod
    label: i18n.t('UNSANITARY_LANDFILL.NAME'),
    inputName: 'name',
    rules: [MAX_LENGTH_RULE(ruleNumber)],
  } as InputSifarniciFormProps;
};

export const NAME_SR_FORM = (ruleNumber: number) => {
  return {
    type_of_form: 'input',
    name: 'name_sr',
    filterName: 'name_sr__ilike',
    rules: [MAX_LENGTH_RULE(ruleNumber)],
    label: `${i18n.t('UNSANITARY_LANDFILL.NAME')} ${i18n.t('NA_SRPSKOM')}`,
    inputName: 'name_sr',
  } as InputSifarniciFormProps;
};

export const APPROVED_FORM = {
  type_of_form: 'switch',
  name: 'approved',
  label: `Odobreno`,
} as SwitchSifarniciFormProps;

export const VALIDITY_FROM_DATE_FORM = (form: FormInstance<any>) => {
  return {
    type_of_form: 'date',
    name: 'validity_from_date',
    filterName: 'validity_from_date__gte',
    label: i18n.t('SIFARNICI.OD_DATUMA'),
    format: 'DD-MM-YYYY',
    picker: 'date',
    onChange: () => {
      form.setFieldValue('validity_to_date', '');
    },
  } as DateSifarniciFormProps;
};

export const VALIDITY_TO_DATE_FORM = (form: FormInstance<any>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return {
    type_of_form: 'date',
    name: 'validity_to_date',
    filterName: 'validity_to_date__lte',
    label: i18n.t('SIFARNICI.DO_DATUMA'),
    format: 'DD-MM-YYYY',
    disabled: !!(sifarnik_type && !form.getFieldValue('validity_from_date')),
    picker: 'date',
    disabledDate: (current: any) => {
      if (form.getFieldValue('validity_from_date')) {
        return current && current.endOf('day') < form.getFieldValue('validity_from_date');
      }
    },
  } as DateSifarniciFormProps;
};

export const INDEX_NUMBER_FORM = (ruleNumber: number) => {
  return {
    type_of_form: 'input',
    name: 'index_number',
    filterName: 'index_number__ilike',
    label: 'Indeksni broj',
    inputName: 'index_number',
    rules: [MAX_LENGTH_RULE(ruleNumber)],
  } as InputSifarniciFormProps;
};

export const IS_ACTIVE_SWITCH = {
  type_of_form: 'switch',
  name: 'is_active',
  label: i18n.t('COMPANY.ACTIVE'),
} as SwitchSifarniciFormProps;
