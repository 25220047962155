export enum ObrazacIzvestajTypeEnum {
  OBRAZAC_1 = 'obrazac1',
  OBRAZAC_2 = 'obrazac2', // GVE
  OBRAZAC_3 = 'obrazac3', // OTV
  OBRAZAC_5 = 'obrazac5', // GIO 1
  GIO_2 = 'gio2',
  GIO_3 = 'gio3',
  GIO_4 = 'gio4',
  GIO_5 = 'gio5',
  DEO_6 = 'deo6',
  KOM_1 = 'kom1',
  PTP2 = 'ptp2',
  AAO1 = 'aao1',
  AAO2 = 'aao2',
  DEP1 = 'dep1',
  DEP2 = 'dep2',
  PZV = 'pzv',
}

// Todo: commented izvestaji enum values are yet to be implemented
// and are of no big relevance right now

export enum Obrazac1IzvestajiEnum {
  GORIVA = 'obrazac1_gorivo',
  PROIZVODI = 'obrazac1_proizvodi',
  SIROVINE = 'obrazac1_sirovine',
  OPSTE = 'obrazac1_opste',
  // PRIKAZ_PROIZVODA_PO_AKTIVNOSTIMA = 'prikaz_proizvoda_po_aktivnostima',
}

export enum Obrazac2IzvestajiEnum {
  ZAGADLJIVE_MATERIJE = 'obrazac2_emisije_u_vazduhu_zag_materije',
  GORIVO = 'obrazac2_gorivo',
  OPSTE = 'obrazac2_opsti',
  PREGLED_PO_GODINAMA = 'obrazac2_pregled_po_godinama',
  IZVORI_PO_GODINAMA = 'obrazac2_emisije_u_vazduhu_izvori_po_godinama',
  PRTR_KATEGORIJE = 'obrazac2_emisije_u_vazduhu_prtr_kategorije',
  KONTROLA_UNOSA = 'obrazac2_emisije_u_vazduhu_kontrola_unosa',
  PRTR_PREGLED_PO_GODINAMA = 'obrazac2_emisije_u_vazduhu_prtr_pregled_po_godinama',
}

export enum Obrazac3IzvestajiEnum {
  ZAGADLJIVE_MATERIJE = 'obrazac3_analiza_otpadne_vode_zag_materije',
  PREGLED_PO_GODINAMA = 'obrazac3_analiza_otpadne_vode_pregled_po_godinama',
  ISPUST_PO_GODINAMA = 'obrazac3_analiza_otpadne_vode_pregled_ispusti_po_godinama',
  OPSTE = 'obrazac3_analiza_otpadne_vode_opste',
  KONTROLA_UNOSA = 'obrazac3_analiza_otpadne_vode_kontrola_unosa',
  PRTR_PREGLED_PO_GODINAMA = 'obrazac3_prtr_pregled_po_godinama',
}

export enum Obrazac5IzvestajiEnum {
  POSTROJENJA = 'obrazac5_otpad_postrojenja',
  POSTROJENJA_INDEKSNI_BROJ = 'obrazac5_otpad_postrojenja_indeksni_broj',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'obrazac5_otpad_grupa_podgrupa_indeksni_broj',
  OTPAD_NE_OPASAN = 'obrazac5_otpad_ne_opasan',
  OTPAD_POSTROJENJA_ZA_PLACANJE = 'obrazac5_otpad_postrojenja_ippc',
  GENERISANJE_OTPADA_PREGLED_PO_GODINAMA = 'obrazac5_pregled_po_godinama',
  GENERISANJE_OTPADA_KONTROLA_UNOSA = 'obrazac5_kontrola_unosa',
  PRTR_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA = 'obrazac5_prtr_pregled_po_godinama',
}

export enum Gio2IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio2_postrojenja',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio2_grupa',
  ODLAGANJE_OTPADA_PREGLED_PO_GODINI = 'gio2_pregled_po_godinama',
  ELEKTRICNI_I_ELEKTRONSKI_OTPAD = 'gio2_elektricni_otpad',
  ODLAGANJE_OTPADA_KONTROLA_UNOSA = 'gio2_kontrola_unosa',
}

export enum Gio3IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio3_postrojenja_indeksni_broj',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio3_grupa_podgrupa_indeksni_broj',
  TRETMAN_OTPADA_PREGLED_PO_GODINI = 'gio3_tretman_otpada_pregled_po_godinama',
  ELEKTRICNI_I_ELEKTRONSKI_OTPAD = 'gio3_elektricni_i_elektronski_otpad',
  TRETMAN_OTPADA_KONTROLA_UNOSA = 'gio3_tretman_otpada_kontrola_unosa',
}

export enum Gio4IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio4_postrojenja_indeksni_broj',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio4_grupa_podgrupa_indeksni_broj',
  IZVOZ_OTPADA_PREGLED_PO_GODINI = 'gio4_izvoz_otpada_pregled_po_godinama',
  ELEKTRICNI_I_ELEKTRONSKI_OTPAD = 'gio4_elektricni_i_elektronski_otpad',
  KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI = 'gio4_kolicina_izvezenog_otpada_po_vrsti_otpada_i_zemlji_izvoza',
  IZVOZ_OTPADA_KONTROLA_UNOSA = 'gio4_izvoz_otpada_kontrola_unosa',
}

export enum Gio5IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio5_postrojenja',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio5_grupa',
  UVOZ_OTPADA_PREGLED_PO_GODINI = 'gio5_pregled_po_godinama',
  KOLICINA_UVEZENOG_OTPADA_PO_VRSTI = 'gio5_kolicina_uvezenog_otpada',
  UVOZ_OTPADA_KONTROLA_UNOSA = 'gio5_kontrola_unosa',
}

export enum Deo6IzvestajiEnum {
  UKUPNO_PREUZET_OTPAD = 'deo6_ukupno_preuzet_otpad',
  PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ = 'deo6_preuzet_otpad_postrojenja_indeksni_broj',
  UKUPNO_PREDAT_OTPAD = 'deo6_ukupno_predat_otpad',
  PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ = 'deo6_predat_otpad_postrojenja_indeksni_broj',
  REKAPITULACIJA = 'deo6_rekapitulacija',
  REKALKULACIJA = 'deo6_rekalkulacija',
  PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA = 'deo6_primopredaja_otpada_izmedju_preduzeca',
  GIO_3_DEO6_PO_PREDUZECU = 'gio3_deo6_po_preduzecu',
}

export enum Kom1IzvestajiEnum {
  SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA = 'kom1_sastav_komunalnog_otpada',
  KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA = 'kom1_kolicina_pregled_po_godinama',
  OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA = 'kom1_prikupljanje_pregled_po_godinama',
  UPRAVLJANJE_KOMUNALNIM_OTPADOM = 'kom1_upravljanje_komunalnim_otpadom',
  // PREGLED_I_STAMPA_PO_SVIM_PREDUZECIMA = 'pregled_i_stampa_po_svim_preduzecima',
}

export enum Ptp2IzvestajiEnum {
  OBRACUN_ZA_NAPLATU = 'ptp2_obracun_za_naplatu',
  VOZILA_UPOREDJIVANJE = 'ptp2_vozila_uporedjivanje',
  NEDOSTAVLJENI_PODACI_O_VOZILIMA = 'ptp2_nedostavljeni_podaci_o_vozilima',
  UVOZ_GUMA_UPOREDJIVANJE = 'ptp2_gume_uporedjivanje',
  NEDOSTAVLJENI_PODACI_O_GUMAMA = 'ptp2_nedostavljeni_podaci_o_gumama',
  KONTROLA_UNOSA = 'ptp2_kontrola_unosa',
}

export enum Aao1IzvestajiEnum {
  PREGLED_PO_GODINAMA = 'aao1_pregled_po_godinama',
  PRIKAZ_1 = 'aao1_prikaz_1',
  SVI_PODACI = 'aao1_svi_podaci',
  NEDOSTAVLJENI_OBRASCI = 'aao1_nedostavljeni_obrasci',
}

export enum Aao2IzvestajiEnum {
  IZVESTAJ_FOR_BOOK = 'aao2_for_book', // is naming good?
}

export enum PzvIzvestajiEnum {
  KOLICINE_PROIZVODA_PO_PREDUZECU = 'pzv_kolicine_proizvoda_po_preduzecu',
  KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU = 'pzv_kolicine_proizvoda_po_grupi_i_preduzecu',
}

// Todo: check if dep1 & dep2 need to be nested under same DEP1_DEP2 parent
export enum Dep1IzvestajiEnum {
  SVI_PODACI = 'dep1_svi_podaci',
}

export enum Dep2IzvestajiEnum {
  SVI_PODACI = 'dep2_svi_podaci',
}

export type IzvestajiSubtypes =
  | Obrazac1IzvestajiEnum
  | Obrazac2IzvestajiEnum
  | Obrazac3IzvestajiEnum
  | Obrazac5IzvestajiEnum
  | Gio2IzvestajiEnum
  | Gio3IzvestajiEnum
  | Gio4IzvestajiEnum
  | Gio5IzvestajiEnum
  | Deo6IzvestajiEnum
  | Kom1IzvestajiEnum
  | Ptp2IzvestajiEnum
  | Aao1IzvestajiEnum
  | Aao2IzvestajiEnum
  | PzvIzvestajiEnum
  | Dep1IzvestajiEnum
  | Dep2IzvestajiEnum;

export enum ReportTypeEnum {
  PDF = 'pdf',
  XLSX = 'xlsx',
}

export interface IObrazacIzvestajFilters {
  type: IzvestajiSubtypes;
  filter_params: ObrazacIzvestajiAllFilters;
  report_type?: ReportTypeEnum;
}

export interface RekalkulacijaIzvestajTableData {
  tabela1: ObrazacIzvestajiAllReports;
  tabela2: ObrazacIzvestajiAllReports;
}

// #region OBRAZAC 1
// Goriva, Proizvodi, Sirovine & Opste

export interface Obrazac1Goriva {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  naziv_goriva: string;
  potrosnja_na_dan: number;
  jed_mere: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac1GorivoIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  gorivo_id?: string;
}

export interface Obrazac1Proizvodi {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  prtr_kod: string;
  naziv_proizvoda: string;
  kolicina_proizvoda: number;
  jed_mere: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac1ProizvodIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  aktivnost_id?: string;
}

export interface Obrazac1Sirovine {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  iupac: string;
  naziv_sirovine: string;
  potrosnja_na_dan: number;
  jed_mere: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac1SirovineIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
}

export interface Obrazac1Opste {
  godina: number;
  pib: string;
  mb: string;
  preduzece: string;
  opstina_preduzeca: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  sifra_pretezne_delatnosti: string;
  prtr_kod: string;
  broj_ispusta_u_vazduh: number;
  broj_ispusta_u_vodu: number;
  broj_ispusta_u_tlo: number;
  broj_vrsta_otpada: number;
  rezim_rada: string;
  pocetak_sezone: string;
  kraj_sezone: string;
  broj_smena: number;
  broj_radnih_dana_nedeljno: number;
  broj_radnih_dana_godisnje: number;
  broj_radnih_dana_sezonski: number;
  broj_stalno_zaposlenih: number;
  broj_privremeno_zaposlenih: number;
  broj_zaposlenih_u_prvoj_smeni: number;
  broj_zaposlenih_u_drugoj_smeni: number;
  broj_zaposlenih_u_trecoj_smeni: number;
}

export interface Obrazac1OpsteIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  aktivnost_id?: string;
  sifra_pretezne_delatnosti_id?: string;
}
// #endregion OBRAZAC 1

// #region OBRAZAC 2
// Zagadljive materije, Gorivo, Opste, Pregled po godinama, Izvori po godinama
export interface Obrazac2ZagadljiveMaterije {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  istorijski_id: string;
  prtr: boolean;
  ippc: boolean;
  aktivnosti: string;
  naziv_izvora: string;
  broj_izvora: string;
  protok: number;
  ukupan_broj_radnih_sati: number;
  zagadjujuca_materija: string;
  srednja_godisnja_koncentracija: number;
  kolicina_u_normalnim_uslovima_g_h: number;
  kolicina_u_normalnim_uslovima_kg_god: number;
  kolicina_u_akcidentnim_situacijama: number;
  obrazac2: boolean;
  gve: boolean;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac2ZagadljiveMaterijeIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  prtr?: string;
  opstina_id?: string;
  izvor?: string;
  zagadjujuca_materija?: string;
  aktivnost?: string;
  // obrazac2?: string;
  tip_obrasca_id?: string;
  gve?: string;
}

export interface Obrazac2Gorivo {
  godina: number;
  pib: string;
  preduzece: string;
  sifra_pretezne_delatnosti: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  prtr: boolean;
  aktivnosti: string;
  naziv_izvora: string;
  broj_izvora: string;
  tip_izvora: string;
  naziv_goriva: string;
  godisnja_potrosnja_goriva: number;
  jed_mere: string;
  donja_toplotna_moc_goriva: number;
  obrazac2: boolean;
  gve: boolean;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac2GorivoIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  prtr?: string;
  opstina_id?: string;
  izvor?: string;
  zagadjujuca_materija?: string;
  aktivnost?: string;
  obrazaac2?: string;
  gve?: string;
}

export interface Obrazac2Opste {
  godina: number;
  pib: string;
  preduzece: string;
  postrojenje: string;
  nacionalni_id: string;
  opstina_postrojenja: string;
  prtr: boolean;
  aktivnosti: string;
  naziv_izvora: string;
  broj_izvora: string;
  vrsta_izvora: string;
  geografska_sirina: number;
  geografska_duzina: number;
  nadmorska_visina: number;
  instalisana_toplotna_snaga_na_ulazu: number;
  godisnja_iskoriscenost_izvora: number;
  visina_izvora: number;
  unutrasnji_precnik_izvora_na_vrhu: number;
  srednja_godisnja_temperatura_izlaznih_gasova: number;
  srednja_godisnja_brzina_izlaznih_gasova: number;
  srednji_godisnji_protok_na_mernom_mestu: number;
  ukupan_broj_radnih_sati_godisnje: number;
  raspodela_emisija_zima: number;
  raspodela_emisija_prolece: number;
  raspodela_emisija_leto: number;
  raspodela_emisija_jesen: number;
  obrazac_2: boolean;
  gve: boolean;
}

export interface Obrazac2OpsteIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  prtr?: string;
  opstina_id?: string;
  izvor?: string;
  aktivnost?: string;
  obrazaac2?: string;
  gve?: string;
}

export interface Obrazac2PregledPoGodinama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  mesto_postrojenja: string;
  opstina_postrojenja: string;
  zagadjujuca_materija: string;
}

export interface Obrazac2PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  prtr?: string;
  opstina_id?: string;
  zagadjujuca_materija?: string;
  obrazaac2?: string;
  gve?: string;
}

export interface Obrazac2IzvoriPoGodinama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  oznaka_izvora: string;
  izvor_zagadjivanja: string;
  zagadjujuca_materija: string;
}

export interface Obrazac2IzvoriPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  prtr?: string;
  opstina_id?: string;
  zagadjujuca_materija?: string;
  obrazaac2?: string;
  gve?: string;
}

export interface Obrazac2PRTRKategorije {
  redni_broj: string;
  kategorija: string;
  nh3: string;
  nox: string;
  pm10: string;
  sox: string;
}

export interface Obrazac2PRTRKategorijeIzvestajFilters {
  godina?: string;
}

export interface Obrazac2KontrolaUnosa extends IIzvestajUporedjivanjeGodina<Obrazac2KontrolaUnosaGrupaKolona> {
  izmena_za_godinu: string;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  prtr: boolean;
  oznaka_izvora: string;
  naziv_izvora: string;
  protok: string;
  broj_radnih_sati_godisnje: number;
  zagadjujuca_materija: string;
  obrazac_2: boolean;
  gve: boolean;
}

export interface Obrazac2KontrolaUnosaGrupaKolona {
  emitovano: string;
  koncentracija: string;
}

export interface Obrazac2KontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}

export interface Obrazac2PrtrPregledPoGodinama extends IIzvestajPoGodinamaTotal {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  istorijski_id: string;
  postrojenje: string;
  mesto_postrojenja: string;
  opstina_postrojenja: string;
  zagadjujuca_materija: string;
}

export interface Obrazac2PRTRPregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

// #endregion OBRAZAC 2

//#region OBRAZAC 3 / OTV

export interface Obrazac3ZagadjujuceMaterije {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  prtr: boolean;
  aktivnosti: string;
  naziv_ispusta: string;
  broj_ispusta: string;
  vrsta_ispusta: string;
  naziv_recipijenta: string;
  sliv: string;
  vremenski_period_ispustanja_vode_dan_god: string;
  ukupna_kolicina_ispustene_vode_m3_god: string;
  srednji_dnevni_protok_otpdane_vode_m3_dan: string;
  zagadjujuca_materija: string;
  srednja_godisnja_izmerena_vrednost_mg_l: string;
  kolicina_pri_redovnom_radu_kg_god: string;
  kolicina_u_akcidentnim_situacijama_kg_god: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac3ZagadjujuceMaterijeIzvestajFilters {
  godina?: string;
  preduzece?: string;
  postrojenje?: string;
  prtr?: boolean;
  opstina_id?: string;
  zagadjujuca_materija_id: string;
  vrsta_recipijenta_id?: string;
  naziv_recipijenta?: string;
  sliv_id?: string;
  obrazac3?: boolean;
  lriz?: boolean;
  otv?: boolean;
}

export interface Obrazac3PregledPoGodinama extends IIzvestajPoGodinama {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  zagadjujuca_materija: string;
}

export interface Obrazac3PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece?: string;
  postrojenje?: string;
  prtr?: boolean;
  opstina_id?: string;
  zagadjujuca_materija_id: string;
  obrazac3?: boolean;
  lriz?: boolean;
  otv?: boolean;
}

export interface Obrazac3IspustPoGodinama extends IIzvestajPoGodinama {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  oznaka_ispusta: number;
  naziv_ispusta: string;
  zagadjujuca_materija: string;
}

export interface Obrazac3IspustPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece?: string;
  postrojenje?: string;
  prtr?: boolean;
  opstina_id?: string;
  zagadjujuca_materija_id: string;
  obrazac3?: boolean;
  lriz?: boolean;
  otv?: boolean;
}

export interface Obrazac3Opste {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  opstina_postrojenja: string;
  godina: number;
  prtr: boolean;
  aktivnosti: string;
  oznaka_ispusta: string;
  naziv_ispusta: string;
  vrsta_recipijenta: string;
  naziv_recipijenta: string;
  geografska_sirina: string;
  geografska_duzina: string;
  rezim_rada: string;
  rashladne: boolean;
  sanitarne: boolean;
  atmosferske: boolean;
  tehnoloske: boolean;
  period_ispustanja: string;
  ukupna_kolicina_ispustene_vode: string;
  kreirano: string;
  azurirano: string;
}
export interface Obrazac3OpsteIzvestajFilters {
  godina?: string;
  preduzece?: string;
  postrojenje?: string;
  prtr?: boolean;
  opstina_id?: string;
  izvor_zagadjenja_vode_id?: string;
  ppov?: boolean;
  obrazac3?: boolean;
  lriz?: boolean;
  otv?: boolean;
}

export interface Obrazac3KontrolaUnosa extends IIzvestajPoGodinama {
  godina: string;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  prtr: boolean;
  oznaka_ispusta: string;
  naziv_ispusta: string;
  zagadjujuca_materija: string;
}

export interface Obrazac3KontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}

export type Obrazac3PrtrPregledPoGodinama = Obrazac3PrtrPregledPoGodinamaData | IIzvestajPoGodinamaTotal;

export interface Obrazac3PrtrPregledPoGodinamaData extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  istorijski_id: string;
  postrojenje: string;
  zagadjujuca_materija: string;
}

export interface Obrazac3PrtrPregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

//#endregion

// #region OBRAZAC 5
// Postrojenja, Postrojenja - Indeksni broj, Grupa-Podgrupa-Indeksni broj, Ne/Opasan, Pregled po godinama, Kontrola unosa, Postrojenja za plaćanje, PRTR Generisanje otpada
export interface Obrazac5Postrojenja {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  aktivnosti: string;
  preneto_iz_prethodnog_perioda: number | null;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  kolicina_otpada_predata_na_skladistenje: number | null;
  kolicina_otpada_predata_na_odlaganje: number | null;
  kolicina_otpada_predata_na_tretman: number | null;
  kolicina_izvezenog_otpada: number | null;
  ostalo_na_lokaciji: number | null;
}

export interface Obrazac5PostrojenjaIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  opasan?: string;
  obrazac?: string;
}

export interface Obrazac5PostrojenjaIndeksniBroj {
  godina: number;
  pib: string;
  preduzece: string;
  sifra_pretezne_delatnosti: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  aktivnosti: string;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  vrsta_otpada: string;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  kolicina_otpada_predata_na_skladistenje: number | null;
  kolicina_otpada_predata_na_odlaganje: number | null;
  kolicina_otpada_predata_na_tretman: number | null;
  kolicina_izvezenog_otpada: number | null;
  y1: string | null;
  y2: string | null;
  y3: string | null;
}

export interface Obrazac5PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  principalbusinessactivity_id?: string;
  indeksni_broj_id?: string;
  opasan?: string;
  obrazac?: string;
  y_mark_id?: string;
}

export interface Obrazac5GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina_t: number;
  children?: Obrazac5GrupaPodgrupaIndeksniBroj[];
}

export interface Obrazac5GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  obrazac?: string;
  y_mark_id?: string;
  indeksni_broj_id?: string;
}

export interface Obrazac5NeOpasan {
  grupa: string;
  kolicina_neopasan_t: number;
  kolicina_opasan_t: number;
}

export interface Obrazac5NeOpasanIzvestajFilters {
  godina?: string;
  obrazac?: string;
}

export interface Obrazac5PregledPoGodinama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
}

export interface Obrazac5PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: string;
  obrazac?: string;
}

// NOTE: low priority
export interface Obrazac5KontrolaUnosa extends IIzvestajPoGodinama {
  izmena_za_godinu: number;
  pib: number;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
}

export interface Obrazac5KontrolaUnosaIzvestajFilters {
  datum?: string;
  godina?: string;
  godina_od?: string;
  godina_do?: string;
  obrazac?: string;
}

export interface Obrazac5PostrojenjaZaPlacanje {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  opstina_postrojenja: string;
  ippc: boolean;
  aktivnosti: string;
  preneto_iz_prethodnog_perioda: number | null;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  kolicina_otpada_predata_na_skladistenje: number | null;
  kolicina_otpada_predata_na_odlaganje: number | null;
  kolicina_otpada_predata_na_tretman: number | null;
  kolicina_izvezenog_otpada: number | null;
  ostalo_na_lokaciji: number | null;
}

export interface Obrazac5PostrojenjaZaPlacanjeIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  opasan?: string;
  obrazac?: string;
}

// NOTE: low priority
export interface Obrazac5PRTRPregledPoGodinama extends IIzvestajPoGodinamaTotal {
  pib: number;
  preduzece: string;
  nacionalni_id: number;
  istorijski_id: string;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
}

export interface Obrazac5PRTRPregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}
// #endregion Obrazac5

// #region GIO2
// PostrojenjaIndeksniBroj, GrupaPodgrupaIndeksniBroj, GodisnjeOdlaganjeOtpada, KontrolaUnosaOdlaganjaOtpada & ElektricniIElektronskiOtpad

export interface Gio2PostrojenjaIndeksniBroj {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  ukupno_preuzeto: number;
  ukupno_odlozeno: number;
  stanje_na_skladistu_1_jan: number;
  stanje_na_skladistu_31_dec: number;
  kolicina_za_d1_postupak: number;
  kolicina_za_d10_postupak: number;
  drugi_d_postupak_1: string;
  kolicina_za_drugi_d_postupak_1: number;
  drugi_d_postupak_2: string;
  kolicina_za_drugi_d_postupak_2: number;
}
export interface Gio2PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  sifra_pretezne_delatnosti?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opasan?: boolean;
  d_oznaka_id?: string;
}

export interface Gio2GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  naziv_otpada: string;
  kolicina: number;
  children?: Gio2GrupaPodgrupaIndeksniBroj[];
}
export interface Gio2GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  nivo?: string;
  indeksni_broj_id?: string;
}

export interface Gio2GodisnjeOdlaganjeOtpada extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
}
export interface Gio2GodisnjeOdlaganjeOtpadaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: boolean;
}

export interface Gio2OdlaganjeOtpadaKontrolaUnosa extends IIzvestajPoGodinama {
  godina: string;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
}

export interface Gio2OdlaganjeOtpadaKontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}

export interface Gio2ElektricniIElektronskiOtpad {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
  ukupno_preuzeto: number;
  ukupno_odlozeno: number;
  stanje_na_deponiji_1_jan: number;
  stanje_na_deponiji_31_dec: number;
  d_oznaka_1: string;
  kolicina_d_oznaka_1: string;
  d_oznaka_2: string;
  kolicina_d_oznaka_2: number;
  razred: string;
  vrsta_ee_otpada: string;
  kolicina: number;
}
export interface Gio2ElektricniIElektronskiOtpadIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  kategorija_ee_id?: string;
}

// #endregion GIO2

// #region GIO3
// Postrojenja-Indeksni broj, Grupa-Podgrupa-Indeksni broj, Pregled po godinama, Elektricni i elektronski otpad

export interface Gio3PostrojenjaIndeksniBroj {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  ukupno_preuzeto: number;
  ukupno_tretirano: number;
  stanje_privremenog_skladista_na_dan_1_januar: number;
  stanje_privremenog_skladista_na_dan_31_decembar: number;
  kolicina_za_r1_tretman: number;
  kolicina_za_r3_tretman: number;
  drugi_r_tretman_1: number;
  kolicina_za_drugi_r_tretman_1: number;
  drugi_r_tretman_2: number;
  kolicina_za_drugi_r_tretman_2: number;
}

export interface Gio3PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  sifra_pretezne_delatnosti?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  indeksni_broj_id?: string;
  opasan?: boolean;
  r_oznaka?: string;
}

export interface Gio3GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina_preuzetog_otpada: number;
  kolicina_tretiranog_otpada: number;
  children?: Gio3GrupaPodgrupaIndeksniBroj[];
}

export interface Gio3GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  nivo?: string;
  indeksni_broj_id?: string;
}

export interface Gio3TretmanOtpadaPregledPoGodinama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
}

export interface Gio3TretmanOtpadaPregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: boolean;
}

export interface Gio3TretmanOtpadaKontrolaUnosa extends IIzvestajPoGodinama {
  godina: string;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
}

export interface Gio3TretmanOtpadaKontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}

export interface Gio3ElektricniIElektronskiOtpad {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
  preuzeto: number;
  ponovo_iskorisceno: number;
  stanje_privremenog_skladista_na_dan_1_januar: number;
  stanje_privremenog_skladista_na_dan_31_decembar: number;
  r_oznaka_1: string;
  kolicina_r_oznaka_1: number;
  r_oznaka_2: string;
  kolicina_r_oznaka_2: number;
  razred: string;
  vrsta_ee_otpada: string;
  kolicina_ee: number;
}

export interface Gio3ElektricniIElektronskiOtpadIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  kategorija_ee_id?: boolean;
}
// #endregion GIO3

//#region GIO 4

export interface Gio4PostrojenjaIndeksniBroj {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  opstina_postrojenja: string;
  godina: string;
  indeksni_broj: string;
  izvezeno: string;
  opasan: boolean;
  naziv_otpada: string;
  broj_dozvole: string;
  zemlja_izvoza: string;
  zemlja_tranzita: string;
  y1: string;
  y2: string;
  y3: string;
  c_oznaka: string;
  h1: string;
  h2: string;
  h3: string;
  ia_oznaka: string;
  iib_oznaka: string;
  r_oznaka: string;
  d_oznaka: string;
}
export interface Gio4PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  sifra_pretezne_delatnosti?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  indeksni_broj_id?: string;
  opasan?: boolean;
}

export interface Gio4GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina: number;
  children?: Gio4GrupaPodgrupaIndeksniBroj[];
}
export interface Gio4GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  nivo?: string;
  indeksni_broj_id?: string;
}

export interface Gio4PregledPoGodinama extends IIzvestajPoGodinama {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  indeksni_broj: string;
  opis_otpada: string;
}
export interface Gio4PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: boolean;
}

export interface Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada {
  indeksni_broj: string;
  otpad: string;
  kolicina_izvezenog_otpada: number;
  zemlja_izvoza: string;
}
export interface Gio4KolicinaIzvezenogOtpadaPoVrstiOtpadaIzvestajFilters {
  godina?: string;
  indeksni_broj_id?: string;
}

export interface Gio4IzvozOtpadaKontrolaUnosa extends IIzvestajPoGodinama {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
}
export interface Gio4IzvozOtpadaKontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}

export interface Gio4ElektricniIElektronskiOtpad {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  godina: string;
  indeksni_broj: string;
  kolicina_izvezenog_otpada: string;
  broj_dozvole: string;
  zemlja_izvoza: string;
  zemlja_tranzita: string;
  naziv_adresa_postrojenja_u_inostranstvu: string;
  y1: string;
  y2: string;
  y3: string;
  c_oznaka: string;
  h1: string;
  h2: string;
  h3: string;
  ia_oznaka: string;
  iib_oznaka: null;
  r_oznaka: null;
  d_oznaka: string;
  razred: string;
  vrsta_ee_otpada: string;
  kolicina_ee: string;
}
export interface Gio4ElektricniIElektronskiOtpadIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  kategorija_ee_id?: boolean;
}

//#endregion GIO 4

// #region GIO 5
// Postrojenja-Indeksni broj, Grupa-Podrgrupa-Indeksni broj, Pregled po godinama, Kolicina uvezenog otpada po vrsti otpada, Kontrola unosa

export interface Gio5PostrojenjaIndeksniBroj {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  opstina_postrojenja: string;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  ukupno_uvezeno: number;
  zemlja_izvoza: string;
  zemlja_tranzita: string;
  y1: string;
  y2: string;
  y3: string;
  c_oznaka: string | null;
  h1: string;
  h2: string;
  h3: string;
  ia_oznaka: string;
  iib_oznaka: string;
}

export interface Gio5PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  sifra_delatnosti_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  indeksni_broj?: string;
  opasan?: string;
}

export interface Gio5GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  naziv_otpada: string;
  kolicina: number;
  children?: Gio5GrupaPodgrupaIndeksniBroj[];
}

export interface Gio5GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  nivo?: string;
  indeksni_broj?: string;
}

export interface Gio5PregledPoGodinama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
}

export interface Gio5PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: string;
}

export interface Gio5KolicinaUvezenogOtpadaPoVrstiOtpada {
  indeksni_broj: string;
  otpad: string;
  ukupno_uvezeno: number;
  zemlja_izvoza: string;
}

export interface Gio5KolicinaUvezenogOtpadaPoVrstiOtpadaIzvestajFilters {
  godina?: string;
  indeksni_broj?: string;
}

export interface Gio5UvozOtpadaKontrolaUnosa extends IIzvestajPoGodinama {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
}

export interface Gio5UvozOtpadaKontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}
// #endregion GIO 5

// #region DEO6
// UkupnoPreuzetOtpad, PreuzetOtpadPostrojenjaIndeksniBroj, PredatOtpadPostrojenjaIndeksniBroj, Rekapitulacija, RekalkulacijaIndeksniBroj, RekalkulacijaRazred, PrimopredajaOtpadaIzmedjuPreduzeca & Gio3Deo6PoPreduzecu
export interface Deo6UkupnoPreuzetOtpad {
  indeksni_broj: string;
  opis: string;
  kolicina_preuzeta_od_pravnog_lica: number;
  kolicina_preuzeta_od_fizickog_lica: number;
  ukupno_preuzeto: number;
}

export interface Deo6UkupnoPreuzetOtpadIzvestajFilters {
  godina?: string;
  indeksni_broj_id?: string;
}

export interface Deo6PreuzetOtpadPostrojenjaIndeksniBroj {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
  otpad: string;
  kolicina_preuzeta_od_pravnog_lica: number;
  kolicina_preuzeta_od_fizickog_lica: number;
  ukupno_preuzeto: number;
}

export interface Deo6PreuzetOtpadPostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
}

export interface Deo6UkupnoPredatOtpad {
  indeksni_broj: string;
  opis: string;
  ukupno_predato: number;
}

export interface Deo6UkupnoPredatOtpadIzvestajFilters {
  godina?: string;
  indeksni_broj?: string;
}

export interface Deo6PredatOtpadPostrojenjaIndeksniBroj {
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  indeksni_broj: string;
  otpad: string;
  ukupno_predato: number;
}

export interface Deo6PredatOtpadPostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece?: string;
  postrojenje?: string;
  indeksni_broj?: string;
}

export interface Deo6Rekapitulacija {
  nacionalni_id: string;
  postrojenje: string;
  datum: string;
  transakcija: string;
  indeksni_broj: string;
  kolicina: number;
  opis_transakcije: string;
  pib: string;
  naziv_preduzece: string;
  naziv_postrojenja: string;
  ee_razred: Record<string, string>;
}

export interface Deo6RekapitulacijaIzvestajFilters {
  preduzece_id?: string;
  datum_od?: string;
  datum_do?: string;
  postrojenje?: string;
  indeksni_broj?: string;
  transakcije: Array<string>;
}

interface Deo6Rekalkulacija {
  kolicina_ponovne_upotrebe: number;
  kolicina_predatog: number;
  kolicina_preuzetog: number;
  kolicina_prvi_januar: number;
  ostalo_na_skladistu: number;
}

export interface Deo6TransakcijePoIndeksnimBrojevima extends Deo6Rekalkulacija {
  indeksni_broj_otpada: string;
}

export interface Deo6TransakcijeZaRazredeElektricnihIElektronskihProizvoda extends Deo6Rekalkulacija {
  razred_ee_otpada: string;
}

export interface Deo6RekalkulacijaIzvestajFilters {
  preduzece?: string;
  datum_od?: string;
  datum_do?: string;
  postrojenje?: string;
}

export interface Deo6PrimopredajaOtpadaIzmedjuPreduzeca {
  godina: number;
  indeksni_broj: string;
  pib: string;
  maticni_broj_preduzeca: string;
  preduzece: string;
  predata_kolicina_otpada: number;
  sakupljac: boolean;
  operator_na_deponiji: boolean;
  operator_za_tretman: boolean;
  skladistar: boolean;
  izvoznik: boolean;
  drugi_vlasnik: boolean;
  pib_preuzeto: string;
  maticni_broj_preduzeca_preuzeto: string;
  preduzece_preuzeto: string;
  predata_kolicina_otpada_preuzeto: number;
  proizvodjac: boolean;
  sakupljac_preuzeto: boolean;
  operator_na_deponiji_preuzeto: boolean;
  operator_za_tretman_preuzeto: boolean;
  skladistar_preuzeto: boolean;
  uvoznik: boolean;
  drugi_vlasnik_preuzeto: boolean;
}

export interface Deo6PrimopredajaOtpadaIzmedjuPreduzecaIzvestajFilters {
  godina?: string;
  pib_preduzeca_koje_je_predalo_otpad_id?: string;
  pmb_preduzeca_koje_je_predalo_otpad_id?: string;
  pib_preduzeca_koje_je_preuzelo_otpad_id?: string;
  mb_preduzeca_koje_je_pruzelo_otpad_id?: string;
  indeksni_broj?: string;
}

export interface Gio3Deo6PoPreduzecu {
  godina: number;
  pib: string;
  maticni_broj: string;
  preduzece: string;
  indeksni_broj: string;
  preuzet_otpad: number;
  tretiran_otpad: number;
  stanje_na_skladistu_1_januara: number;
  preuzet_otpad2: number;
  tretiran_otpad2: number;
}

export interface Gio3Deo6PoPreduzecuIzvestajFilters {
  godina?: string;
  pib_preduzeca?: string;
  indeksni_broj?: string;
}
// #endregion DEO6

// #region KOM1
// Sastav komunalnog otpada - pregled po godinama, Kolicina komunalnog otpada - pregled po godinama, Obuhvat prikupljenog otpda - pregled po godinama, Upravljanje komunalnim otpadom
export interface Kom1SastavKomunalnogOtpadaPregledPoGodinama {
  opstina: string;
  preduzece: string;
  vrsta_otpada: IIzvestajGodinePoKategorijiKategorijaInfo[];
}

export interface Kom1SastavKomunalnogOtpadaPregledPoGodinamaIzvestajFilters {
  godina_od: string;
  godina_do: string;
  opstina_id: string;
}

export interface Kom1KolicinaKomunalnogOtpadaPregledPoGodinama extends IIzvestajPoGodinama {
  opstina: string;
  preduzece: string;
}

export interface Kom1KolicinaKomunalnogOtpadaPregledPoGodinamaIzvestajFilters {
  godina_od: string;
  godina_do: string;
  opstina_id: string;
}

export interface Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinama extends IIzvestajPoGodinama {
  opstina: string;
  preduzece: string;
}

export interface Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinamaIzvestajFilters {
  godina_od: string;
  godina_do: string;
  opstina_id: string;
}

export interface Kom1UpravljanjeKomunalnimOtpadom {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina: string;
  indeksni_broj: string;
  ukupna_kolicina_otpada: number;
  nacin_odredjivanja: string;
  kolicina_iz_domacinstva: number;
  kolicina_iz_kontejnera: number;
  kolicina_iz_reciklaznog_dvorista: number;
  kolicina_iz_drugih_sabirnih_mesta: number;
  vrsta_predaje: string;
  predata_kolicina: number;
  d_ili_r_oznaka: string;
}

export interface Kom1UpravljanjeKomunalnimOtpadomIzvestajFilters {
  godina: string;
  preduzece_id: string;
  postrojenje_id: string;
  opstina_id: string;
  indeksni_broj_id: string;
  d_ili_r_oznaka_id: string;
}
//#endregion KOM1

//#region PTP2

export interface Ptp2ObracunZaNaplatu extends IIzvestajPoGodinama {
  vrsta_proizvoda: string;
}
export interface Ptp2ObracunZaNaplatuIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

export interface Ptp2VozilaUporedjivanje extends IIzvestajUporedjivanjeGodina<Ptp2VozilaUporedjivanjeGrupaKolona> {
  pib: string;
  preduzece: string;
}
export interface Ptp2VozilaUporedjivanjeGrupaKolona {
  prijavljena_kolicina: number;
  kolicina_na_carini: number;
  razlika: number;
}
export interface Ptp2VozilaUporedjivanjeIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

export interface Ptp2NedostavljeniPodaciOVozilima extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
}
export interface Ptp2NedostavljeniPodaciOVozilimaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

export interface Ptp2UvozGumaUporedjivanje extends IIzvestajUporedjivanjeGodina<Ptp2UvozGumaUporedjivanjeGrupaKolona> {
  pib: string;
  preduzece: string;
}
export interface Ptp2UvozGumaUporedjivanjeGrupaKolona {
  prijavljena_kolicina: number;
  kolicina_na_carini: number;
  razlika: number;
}
export interface Ptp2UvozGumaUporedjivanjeIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

export interface Ptp2NedostavljeniPodaciOGumama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
}
export interface Ptp2NedostavljeniPodaciOGumamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
}

export interface Ptp2KontrolaUnosa extends IIzvestajPoGodinama {
  godina: string;
  pib: string;
  preduzece: string;
  vrsta_proizvoda: string;
  naziv_proizvoda: string;
  jedinica: string;
  klasa: string;
}
export interface Ptp2KontrolaUnosaIzvestajFilters {
  datum_od?: string;
  datum_do?: string;
  godina_od?: string;
  godina_do?: string;
}

// #endregion PTP2

// #region AAO1
// Pregled po godinama, Prikaz 1, Svi podaci, Nedostavljeni obrasci

export interface Aao1PregledPoGodinama extends IIzvestajPoGodinama {
  pib: string;
  preduzece: string;
  email: string;
  vrsta_ambalaze: string;
}

export interface Aao1PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
}

export interface Aao1Prikaz1 {
  pib: string;
  preduzece: string;
  mb: string;
  ulica: string;
  kucni_broj: string;
  opstina: string;
  vrsta_ambalaze: string;
  ukupno_nepovratne_ambalaze: number | null;
  ukupno_povratne_ambalaze: number | null;
  operater1: string;
  operater2: string;
  datum_kreiranja: string | null;
  datum_azuriranja: string | null;
}

export interface Aao1Prikaz1IzvestajFilters {
  godina?: string;
  operater?: string;
  pib?: string;
  operater_search_id?: string;
  dostavljeno_od?: string;
  dostavljeno_do?: string;
}

export interface Aao1SviPodaci {
  godina: number;
  pib: string;
  naziv: string;
  proizvodjac_ambalaze: boolean;
  paker_proizvoda_u_ambalazu: boolean;
  isporucilac_ambalaze: boolean;
  uvoznik_ambalaze: boolean;
  izvoznik_ambalaze: boolean;
  krajnji_korisnik_ambalaze: boolean;
  oznaka_grupe: string;
  oznaka_podgrupe: string;
  vrsta_ambalaze: string;
  nepovratna_ambalaza_proizvedena_kolicina: number | null;
  nepovratna_ambalaza_uvezena_kolicina: number | null;
  nepovratna_ambalaza_izvezena_kolicina: number | null;
  nepovratna_ambalaza_ukupna_kolicina: number | null;
  povratna_ambalaza_proizvedena_kolicina: number | null;
  povratna_ambalaza_uvezena_kolicina: number | null;
  povratna_ambalaza_izvezena_kolicina: number | null;
  povratna_ambalaza_ukupna_kolicina: number | null;
  kolicina_ambalaze_preneta_na_operatere: number | null;
  operateri: Aao1SviPodaciOperaterInfo[];
  kolicina_ambalaze_na_samostalnom_upravljanju: number | null;
  procenat_ambalaze_prenet_na_samostalno_upravljanje: number | null;
}

export interface Aao1SviPodaciIzvestajFilters {
  godina?: string;
  pib?: string;
}

export interface Aao1NedostavljeniObrasci {
  godina: number;
  pib: string;
  preduzece: string;
  pib_operatera: number;
  naziv_operatera: string;
  mb: string;
  adresa: string;
  mesto: string;
  postanski_broj: string;
  telefon: string;
  email: string;
}

export interface Aao1NedostavljeniObrasciIzvestajFilters {
  godina?: string;
  pib_operatera?: string;
}
// #endregion AAO1

// #region AAO2
export interface AAO2IzvestajForBook {
  temp_id: string;
  reporting_year: string;
  company: string;
  permit_number: string;
  group_number: string;
  sub_group_number: string;
  display_type_sr: string;
  index_number: string;
  produced_quantity: number;
  imported_quantity: number;
  exported_quantity: number;
  total_quantity: number;
  take_quantity_communal: number;
  taken_quantity_noncommunal: number;
  quantity_operation_r1: number;
  other_r_operation: string;
  quantity_other_r_operation: number;
  quantity_operation_d1: number;
  other_d_operation: string;
  quantity_other_d_operation: number;
  total_reused: number;
  total_disposal: number;
  client_numbers: number;
}

export interface AAO2IzvestajForBookIzvestajFilters {
  godina?: string;
}
// #endregion AAO2

// #region DEP1 i DEP2
// Svi podaci - DEP1, Svi podaci - DEP2
export interface Dep1SviPodaci {
  godina: number;
  preduzece: string;
  pib: string;
  mb: string;
  opstina_preduzeca: string;
  mesto_preduzeca: string;
  sifra_mesta_preduzeca: number;
  postanski_broj: string;
  adresa: string;
  telefon: string;
  telefax: string;
  email: string;
  sifra_pretezne_delatnosti: string;
  olp_ime_prezime: string;
  olp_funkcija: string;
  olp_telefon: string;
  olo_ime_prezime: string;
  olo_funkcija: string;
  olo_telefon: string;
  olo_email: string;
  deponija_lokacija: string;
  deponija_naziv: string;
  geografska_sirina: number;
  geografska_duzina: number;
  zauzete_katastarske_parcele: string;
  godina_pocetka_deponovanja: number;
  godina_zavrsetka_deponovanja: number;
  prosecne_godisnje_kolicine_otpada: number;
  naselja_za_odlaganje_otpada: string;
  ograda_oko_nesanitarne_deponije: boolean;
  kapija_rampa_na_ulazu: boolean;
  cuvarska_sluzba: boolean;
  kolska_vaga: boolean;
  drenazni_sistem: boolean;
  sistem_za_preciscavanje: boolean;
  sistem_za_otplinjavanje: boolean;
  region_za_upravljanje_otpada: string;
  lok_samouprave_upravljanje_otpadom: string;
  nesanitarna_deponija_smetliste_je: string;
  operativni_status_nesanitarne_deponije: string;
  dalje_odlaganje_otpada: string;
  nacin_odlaganja_otpada: string;
  prekrivanje_inertnim_materijalom: string;
  period_trajanje_i_eksploatacije_deponije: string;
  evid_o_kolicinama_vrstama_otpada: boolean;
  dep_u_poplavnom_podrucju: boolean;
  izradjen_projekat_sanacije: boolean;
  godina_projekta_sanacije: number;
  pribavljena_saglasnost_na_projekat: boolean;
  godina_pribavljanja_saglasnosti_na_projekat: number;
  izvodjenje_radova_po_projektu_sanacije: string;
  radovi_koji_se_izvode_po_projektu: string;
  sprovodjenje_mera_zzs: boolean;
  sprovodjenje_monitoringa: string;
  potreba_izrada_azuriranje_projekta: boolean;
}

export interface Dep1SviPodaciIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  nesanitarna_deponija_id?: string;
}

export interface Dep2SviPodaci {
  godina: number;
  preduzece: string;
  pib: string;
  mb: string;
  opstina_preduzeca: string;
  mesto_preduzeca: string;
  sifra_mesta_preduzeca: number;
  postanski_broj: string;
  adresa: string;
  telefon: string;
  telefax: string;
  email: string;
  sifra_pretezne_delatnosti: string;
  olp_ime_prezime: string;
  olp_funkcija: string;
  olp_telefon: string;
  olo_ime_prezime: string;
  olo_funkcija: string;
  olo_telefon: string;
  olo_email: string;
  deponija_opstina: string;
  deponija_naselje: string;
  geografska_sirina: number;
  geografska_duzina: number;
  procenjena_kolicina_otpada: number;
  procenjena_povrsina_smetlista: number;
  broj_ciscenja_prostora_u_okviru_godine: number;
  ponavlja_se_divlje_odlaganje_otpada: boolean;
}

export interface Dep2SviPodaciIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
}
// #endregion DEP1 i DEP2

//#region PZV
export interface PzvKolicineProizvodaPoPreduzecu {
  godina: string;
  pib: string;
  preduzece: string;
  grupa_proizvoda: string;
  naziv_proizvoda: string;
  opis: string;
  kolicina_proizvoda: string;
  sadrzaj: string;
  predefinisani_sadrzaj: string;
  ukupna_kolicina: string;
}
export interface PzvKolicineProizvodaPoPreduzecuIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  deterdzenti?: boolean;
  mineralna_djubruva?: boolean;
  sredstva_za_zastitu_bilja?: boolean;
}

export interface PzvKolicineProizvodaPoGrupiIPreduzecu {
  godina: string;
  pib: string;
  mb: string;
  preduzece: string;
  mesto_preduzeca: string;
  opstina_preduzeca: string;
  ulica: string;
  broj: string;
  grupa_proizvoda: string;
  kolicina_proizvoda: string;
  ukupna_kolicina: string;
  ukupna_kolicina_na_carini: string;
}
export interface PzvKolicineProizvodaPoGrupiIPreduzecuIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  deterdzenti?: boolean;
  mineralna_djubruva?: boolean;
  sredstva_za_zastitu_bilja?: boolean;
}
//#endregion

export type ObrazacIzvestajiAllReports =
  | Obrazac1Goriva[]
  | Obrazac1Proizvodi[]
  | Obrazac1Sirovine[]
  | Obrazac1Opste[]
  | Obrazac2ZagadljiveMaterije[]
  | Obrazac2Gorivo[]
  | Obrazac2Opste[]
  | Obrazac2PregledPoGodinama[]
  | Obrazac2IzvoriPoGodinama[]
  | Obrazac2PRTRKategorije[]
  | Obrazac2KontrolaUnosa[]
  | Obrazac2PrtrPregledPoGodinama[]
  | Obrazac3ZagadjujuceMaterije[]
  | Obrazac3PregledPoGodinama[]
  | Obrazac3IspustPoGodinama[]
  | Obrazac3Opste[]
  | Obrazac3KontrolaUnosa[]
  | Obrazac3PrtrPregledPoGodinama[]
  | Obrazac5Postrojenja[]
  | Obrazac5PostrojenjaIndeksniBroj[]
  | Obrazac5GrupaPodgrupaIndeksniBroj[]
  | Obrazac5NeOpasan[]
  | Obrazac5PregledPoGodinama[]
  | Obrazac5KontrolaUnosa[]
  | Obrazac5PostrojenjaZaPlacanje[]
  | Obrazac5PRTRPregledPoGodinama[]
  | Gio2PostrojenjaIndeksniBroj[]
  | Gio2GrupaPodgrupaIndeksniBroj[]
  | Gio2GodisnjeOdlaganjeOtpada[]
  | Gio2OdlaganjeOtpadaKontrolaUnosa[]
  | Gio2ElektricniIElektronskiOtpad[]
  | Gio3PostrojenjaIndeksniBroj[]
  | Gio3GrupaPodgrupaIndeksniBroj[]
  | Gio3TretmanOtpadaPregledPoGodinama[]
  | Gio3TretmanOtpadaKontrolaUnosa[]
  | Gio3ElektricniIElektronskiOtpad[]
  | Gio4PostrojenjaIndeksniBroj[]
  | Gio4GrupaPodgrupaIndeksniBroj[]
  | Gio4PregledPoGodinama[]
  | Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada[]
  | Gio4IzvozOtpadaKontrolaUnosa[]
  | Gio4ElektricniIElektronskiOtpad[]
  | Gio5PostrojenjaIndeksniBroj[]
  | Gio5GrupaPodgrupaIndeksniBroj[]
  | Gio5PregledPoGodinama[]
  | Gio5KolicinaUvezenogOtpadaPoVrstiOtpada[]
  | Gio5UvozOtpadaKontrolaUnosa[]
  | Deo6UkupnoPreuzetOtpad[]
  | Deo6PreuzetOtpadPostrojenjaIndeksniBroj[]
  | Deo6UkupnoPredatOtpad[]
  | Deo6PredatOtpadPostrojenjaIndeksniBroj[]
  | Deo6Rekapitulacija[]
  | Deo6TransakcijePoIndeksnimBrojevima[]
  | Deo6TransakcijeZaRazredeElektricnihIElektronskihProizvoda[]
  | Deo6PrimopredajaOtpadaIzmedjuPreduzeca[]
  | Gio3Deo6PoPreduzecu[]
  | Kom1SastavKomunalnogOtpadaPregledPoGodinama[]
  | Kom1KolicinaKomunalnogOtpadaPregledPoGodinama[]
  | Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinama[]
  | Kom1UpravljanjeKomunalnimOtpadom[]
  | Ptp2ObracunZaNaplatu[]
  | Ptp2KontrolaUnosa[]
  | Ptp2VozilaUporedjivanje[]
  | Ptp2NedostavljeniPodaciOVozilima[]
  | Ptp2UvozGumaUporedjivanje[]
  | Ptp2NedostavljeniPodaciOGumama[]
  | Aao1PregledPoGodinama[]
  | Aao1Prikaz1[]
  | Aao1SviPodaci[]
  | Aao1NedostavljeniObrasci[]
  | AAO2IzvestajForBook[]
  | Dep1SviPodaci[]
  | Dep2SviPodaci[]
  | PzvKolicineProizvodaPoPreduzecu[]
  | PzvKolicineProizvodaPoGrupiIPreduzecu[];

export type ObrazacIzvestajiAllFilters =
  | Obrazac1GorivoIzvestajFilters
  | Obrazac1ProizvodIzvestajFilters
  | Obrazac1SirovineIzvestajFilters
  | Obrazac1OpsteIzvestajFilters
  | Obrazac2ZagadljiveMaterijeIzvestajFilters
  | Obrazac2GorivoIzvestajFilters
  | Obrazac2OpsteIzvestajFilters
  | Obrazac2PregledPoGodinamaIzvestajFilters
  | Obrazac2IzvoriPoGodinamaIzvestajFilters
  | Obrazac2PRTRKategorijeIzvestajFilters
  | Obrazac2KontrolaUnosaIzvestajFilters
  | Obrazac2PRTRPregledPoGodinamaIzvestajFilters
  | Obrazac3ZagadjujuceMaterijeIzvestajFilters
  | Obrazac3PregledPoGodinamaIzvestajFilters
  | Obrazac3IspustPoGodinamaIzvestajFilters
  | Obrazac3OpsteIzvestajFilters
  | Obrazac3KontrolaUnosaIzvestajFilters
  | Obrazac3PrtrPregledPoGodinamaIzvestajFilters
  | Obrazac5PostrojenjaIzvestajFilters
  | Obrazac5PostrojenjaIndeksniBrojIzvestajFilters
  | Obrazac5GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Obrazac5NeOpasanIzvestajFilters
  | Obrazac5PregledPoGodinamaIzvestajFilters
  | Obrazac5KontrolaUnosaIzvestajFilters
  | Obrazac5PostrojenjaZaPlacanjeIzvestajFilters
  | Obrazac5PRTRPregledPoGodinama
  | Gio2PostrojenjaIndeksniBrojIzvestajFilters
  | Gio2GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Gio2GodisnjeOdlaganjeOtpadaIzvestajFilters
  | Gio2OdlaganjeOtpadaKontrolaUnosaIzvestajFilters
  | Gio2ElektricniIElektronskiOtpadIzvestajFilters
  | Gio3PostrojenjaIndeksniBrojIzvestajFilters
  | Gio3GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Gio3TretmanOtpadaPregledPoGodinamaIzvestajFilters
  | Gio3TretmanOtpadaKontrolaUnosaIzvestajFilters
  | Gio3ElektricniIElektronskiOtpadIzvestajFilters
  | Gio4PostrojenjaIndeksniBrojIzvestajFilters
  | Gio4GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Gio4PregledPoGodinamaIzvestajFilters
  | Gio4KolicinaIzvezenogOtpadaPoVrstiOtpadaIzvestajFilters
  | Gio4IzvozOtpadaKontrolaUnosaIzvestajFilters
  | Gio4ElektricniIElektronskiOtpadIzvestajFilters
  | Gio5PostrojenjaIndeksniBrojIzvestajFilters
  | Gio5GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Gio5PregledPoGodinamaIzvestajFilters
  | Gio5KolicinaUvezenogOtpadaPoVrstiOtpadaIzvestajFilters
  | Gio5UvozOtpadaKontrolaUnosaIzvestajFilters
  | Deo6UkupnoPreuzetOtpadIzvestajFilters
  | Deo6PreuzetOtpadPostrojenjaIndeksniBrojIzvestajFilters
  | Deo6UkupnoPredatOtpadIzvestajFilters
  | Deo6PredatOtpadPostrojenjaIndeksniBrojIzvestajFilters
  | Deo6RekapitulacijaIzvestajFilters
  | Deo6RekalkulacijaIzvestajFilters
  | Deo6PrimopredajaOtpadaIzmedjuPreduzecaIzvestajFilters
  | Gio3Deo6PoPreduzecuIzvestajFilters
  | Kom1SastavKomunalnogOtpadaPregledPoGodinamaIzvestajFilters
  | Kom1KolicinaKomunalnogOtpadaPregledPoGodinamaIzvestajFilters
  | Kom1ObuhvatPrikupljanjaKomunalnogOtpadaPregledPoGodinamaIzvestajFilters
  | Kom1UpravljanjeKomunalnimOtpadomIzvestajFilters
  | Ptp2KontrolaUnosaIzvestajFilters
  | Ptp2VozilaUporedjivanjeIzvestajFilters
  | Ptp2NedostavljeniPodaciOVozilimaIzvestajFilters
  | Ptp2UvozGumaUporedjivanjeIzvestajFilters
  | Ptp2NedostavljeniPodaciOGumamaIzvestajFilters
  | Aao1PregledPoGodinamaIzvestajFilters
  | Aao1Prikaz1IzvestajFilters
  | Aao1SviPodaciIzvestajFilters
  | Aao1NedostavljeniObrasciIzvestajFilters
  | AAO2IzvestajForBookIzvestajFilters
  | Dep1SviPodaciIzvestajFilters
  | Dep2SviPodaciIzvestajFilters
  | PzvKolicineProizvodaPoPreduzecuIzvestajFilters
  | PzvKolicineProizvodaPoGrupiIPreduzecuIzvestajFilters;

export interface IIzvestajUporedjivanjeGodina<T> {
  godine: {
    '1901'?: T;
    '1902'?: T;
    '1903'?: T;
    '1904'?: T;
    '1905'?: T;
    '1906'?: T;
    '1907'?: T;
    '1908'?: T;
    '1909'?: T;
    '1910'?: T;
    '1911'?: T;
    '1912'?: T;
    '1913'?: T;
    '1914'?: T;
    '1915'?: T;
    '1916'?: T;
    '1917'?: T;
    '1918'?: T;
    '1919'?: T;
    '1920'?: T;
    '1921'?: T;
    '1922'?: T;
    '1923'?: T;
    '1924'?: T;
    '1925'?: T;
    '1926'?: T;
    '1927'?: T;
    '1928'?: T;
    '1929'?: T;
    '1930'?: T;
    '1931'?: T;
    '1932'?: T;
    '1933'?: T;
    '1934'?: T;
    '1935'?: T;
    '1936'?: T;
    '1937'?: T;
    '1938'?: T;
    '1939'?: T;
    '1940'?: T;
    '1941'?: T;
    '1942'?: T;
    '1943'?: T;
    '1944'?: T;
    '1945'?: T;
    '1946'?: T;
    '1947'?: T;
    '1948'?: T;
    '1949'?: T;
    '1950'?: T;
    '1951'?: T;
    '1952'?: T;
    '1953'?: T;
    '1954'?: T;
    '1955'?: T;
    '1956'?: T;
    '1957'?: T;
    '1958'?: T;
    '1959'?: T;
    '1960'?: T;
    '1961'?: T;
    '1962'?: T;
    '1963'?: T;
    '1964'?: T;
    '1965'?: T;
    '1966'?: T;
    '1967'?: T;
    '1968'?: T;
    '1969'?: T;
    '1970'?: T;
    '1971'?: T;
    '1972'?: T;
    '1973'?: T;
    '1974'?: T;
    '1975'?: T;
    '1976'?: T;
    '1977'?: T;
    '1978'?: T;
    '1979'?: T;
    '1980'?: T;
    '1981'?: T;
    '1982'?: T;
    '1983'?: T;
    '1984'?: T;
    '1985'?: T;
    '1986'?: T;
    '1987'?: T;
    '1988'?: T;
    '1989'?: T;
    '1990'?: T;
    '1991'?: T;
    '1992'?: T;
    '1993'?: T;
    '1994'?: T;
    '1995'?: T;
    '1996'?: T;
    '1997'?: T;
    '1998'?: T;
    '1999'?: T;
    '2000'?: T;
    '2001'?: T;
    '2002'?: T;
    '2003'?: T;
    '2004'?: T;
    '2005'?: T;
    '2006'?: T;
    '2007'?: T;
    '2008'?: T;
    '2009'?: T;
    '2010'?: T;
    '2011'?: T;
    '2012'?: T;
    '2013'?: T;
    '2014'?: T;
    '2015'?: T;
    '2016'?: T;
    '2017'?: T;
    '2018'?: T;
    '2019'?: T;
    '2020'?: T;
    '2021'?: T;
    '2022'?: T;
    '2023'?: T;
    '2024'?: T;
    '2025'?: T;
    '2026'?: T;
    '2027'?: T;
    '2028'?: T;
    '2029'?: T;
    '2030'?: T;
    '2031'?: T;
    '2032'?: T;
    '2033'?: T;
    '2034'?: T;
    '2035'?: T;
    '2036'?: T;
    '2037'?: T;
    '2038'?: T;
    '2039'?: T;
    '2040'?: T;
    '2041'?: T;
    '2042'?: T;
    '2043'?: T;
    '2044'?: T;
    '2045'?: T;
    '2046'?: T;
    '2047'?: T;
    '2048'?: T;
    '2049'?: T;
    '2050'?: T;
    '2051'?: T;
    '2052'?: T;
    '2053'?: T;
    '2054'?: T;
    '2055'?: T;
    '2056'?: T;
    '2057'?: T;
    '2058'?: T;
    '2059'?: T;
    '2060'?: T;
    '2061'?: T;
    '2062'?: T;
    '2063'?: T;
    '2064'?: T;
    '2065'?: T;
    '2066'?: T;
    '2067'?: T;
    '2068'?: T;
    '2069'?: T;
    '2070'?: T;
    '2071'?: T;
    '2072'?: T;
    '2073'?: T;
    '2074'?: T;
    '2075'?: T;
    '2076'?: T;
    '2077'?: T;
    '2078'?: T;
    '2079'?: T;
    '2080'?: T;
    '2081'?: T;
    '2082'?: T;
    '2083'?: T;
    '2084'?: T;
    '2085'?: T;
    '2086'?: T;
    '2087'?: T;
    '2088'?: T;
    '2089'?: T;
    '2090'?: T;
    '2091'?: T;
    '2092'?: T;
    '2093'?: T;
    '2094'?: T;
    '2095'?: T;
    '2096'?: T;
    '2097'?: T;
    '2098'?: T;
    '2099'?: T;
    '2100'?: T;
  };
}

export interface IIzvestajGodinePoKategorijiKategorijaInfo {
  naziv: string;
  godine: {
    godina: number;
    vrednost: number;
  }[];
}
export interface IIzvestajPoGodinamaTotal extends IIzvestajPoGodinama {
  is_total_record?: boolean;
}

export interface IIzvestajPoGodinama {
  godine: {
    '1901'?: string | number;
    '1902'?: string | number;
    '1903'?: string | number;
    '1904'?: string | number;
    '1905'?: string | number;
    '1906'?: string | number;
    '1907'?: string | number;
    '1908'?: string | number;
    '1909'?: string | number;
    '1910'?: string | number;
    '1911'?: string | number;
    '1912'?: string | number;
    '1913'?: string | number;
    '1914'?: string | number;
    '1915'?: string | number;
    '1916'?: string | number;
    '1917'?: string | number;
    '1918'?: string | number;
    '1919'?: string | number;
    '1920'?: string | number;
    '1921'?: string | number;
    '1922'?: string | number;
    '1923'?: string | number;
    '1924'?: string | number;
    '1925'?: string | number;
    '1926'?: string | number;
    '1927'?: string | number;
    '1928'?: string | number;
    '1929'?: string | number;
    '1930'?: string | number;
    '1931'?: string | number;
    '1932'?: string | number;
    '1933'?: string | number;
    '1934'?: string | number;
    '1935'?: string | number;
    '1936'?: string | number;
    '1937'?: string | number;
    '1938'?: string | number;
    '1939'?: string | number;
    '1940'?: string | number;
    '1941'?: string | number;
    '1942'?: string | number;
    '1943'?: string | number;
    '1944'?: string | number;
    '1945'?: string | number;
    '1946'?: string | number;
    '1947'?: string | number;
    '1948'?: string | number;
    '1949'?: string | number;
    '1950'?: string | number;
    '1951'?: string | number;
    '1952'?: string | number;
    '1953'?: string | number;
    '1954'?: string | number;
    '1955'?: string | number;
    '1956'?: string | number;
    '1957'?: string | number;
    '1958'?: string | number;
    '1959'?: string | number;
    '1960'?: string | number;
    '1961'?: string | number;
    '1962'?: string | number;
    '1963'?: string | number;
    '1964'?: string | number;
    '1965'?: string | number;
    '1966'?: string | number;
    '1967'?: string | number;
    '1968'?: string | number;
    '1969'?: string | number;
    '1970'?: string | number;
    '1971'?: string | number;
    '1972'?: string | number;
    '1973'?: string | number;
    '1974'?: string | number;
    '1975'?: string | number;
    '1976'?: string | number;
    '1977'?: string | number;
    '1978'?: string | number;
    '1979'?: string | number;
    '1980'?: string | number;
    '1981'?: string | number;
    '1982'?: string | number;
    '1983'?: string | number;
    '1984'?: string | number;
    '1985'?: string | number;
    '1986'?: string | number;
    '1987'?: string | number;
    '1988'?: string | number;
    '1989'?: string | number;
    '1990'?: string | number;
    '1991'?: string | number;
    '1992'?: string | number;
    '1993'?: string | number;
    '1994'?: string | number;
    '1995'?: string | number;
    '1996'?: string | number;
    '1997'?: string | number;
    '1998'?: string | number;
    '1999'?: string | number;
    '2000'?: string | number;
    '2001'?: string | number;
    '2002'?: string | number;
    '2003'?: string | number;
    '2004'?: string | number;
    '2005'?: string | number;
    '2006'?: string | number;
    '2007'?: string | number;
    '2008'?: string | number;
    '2009'?: string | number;
    '2010'?: string | number;
    '2011'?: string | number;
    '2012'?: string | number;
    '2013'?: string | number;
    '2014'?: string | number;
    '2015'?: string | number;
    '2016'?: string | number;
    '2017'?: string | number;
    '2018'?: string | number;
    '2019'?: string | number;
    '2020'?: string | number;
    '2021'?: string | number;
    '2022'?: string | number;
    '2023'?: string | number;
    '2024'?: string | number;
    '2025'?: string | number;
    '2026'?: string | number;
    '2027'?: string | number;
    '2028'?: string | number;
    '2029'?: string | number;
    '2030'?: string | number;
    '2031'?: string | number;
    '2032'?: string | number;
    '2033'?: string | number;
    '2034'?: string | number;
    '2035'?: string | number;
    '2036'?: string | number;
    '2037'?: string | number;
    '2038'?: string | number;
    '2039'?: string | number;
    '2040'?: string | number;
    '2041'?: string | number;
    '2042'?: string | number;
    '2043'?: string | number;
    '2044'?: string | number;
    '2045'?: string | number;
    '2046'?: string | number;
    '2047'?: string | number;
    '2048'?: string | number;
    '2049'?: string | number;
    '2050'?: string | number;
    '2051'?: string | number;
    '2052'?: string | number;
    '2053'?: string | number;
    '2054'?: string | number;
    '2055'?: string | number;
    '2056'?: string | number;
    '2057'?: string | number;
    '2058'?: string | number;
    '2059'?: string | number;
    '2060'?: string | number;
    '2061'?: string | number;
    '2062'?: string | number;
    '2063'?: string | number;
    '2064'?: string | number;
    '2065'?: string | number;
    '2066'?: string | number;
    '2067'?: string | number;
    '2068'?: string | number;
    '2069'?: string | number;
    '2070'?: string | number;
    '2071'?: string | number;
    '2072'?: string | number;
    '2073'?: string | number;
    '2074'?: string | number;
    '2075'?: string | number;
    '2076'?: string | number;
    '2077'?: string | number;
    '2078'?: string | number;
    '2079'?: string | number;
    '2080'?: string | number;
    '2081'?: string | number;
    '2082'?: string | number;
    '2083'?: string | number;
    '2084'?: string | number;
    '2085'?: string | number;
    '2086'?: string | number;
    '2087'?: string | number;
    '2088'?: string | number;
    '2089'?: string | number;
    '2090'?: string | number;
    '2091'?: string | number;
    '2092'?: string | number;
    '2093'?: string | number;
    '2094'?: string | number;
    '2095'?: string | number;
    '2096'?: string | number;
    '2097'?: string | number;
    '2098'?: string | number;
    '2099'?: string | number;
    '2100'?: string | number;
  };
}

export interface Aao1SviPodaciOperaterInfo {
  naziv_operatera: string;
  broj_dozvole_operatera: string;
  procenat_ambalaze_prenet_na_operatera: number;
}

export interface IIzvestajOperaterInfo {
  operateri: Aao1SviPodaciOperaterInfo[];
}
