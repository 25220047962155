import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import {
  GVE_BILANS_INITIAL_STATE,
  GVE_GORIVA_INITIAL_STATE,
  GVE_INITIAL_STATE,
  GVE_IZMERENE_VREDNOSTII_INITIAL_STATE,
  GVE_EMISIJE_U_VAZDUH_INITIAL_STATE,
} from './gve.constants';
import {
  IGetBilansGve,
  IGetGorivaGve,
  IGetIzmereneVrednostiGve,
  IGetEmisijeUVazduh,
  IPostBilansGve,
  IPostGorivaGve,
  IPostGve,
  IPostIzmereneVrednostiGve,
  IPostEmisijeUVazduhGve,
  IGetGve,
} from './gve.types';
import { makeAutoObservable } from 'mobx';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { formStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import to from 'await-to-js';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import dayjs from 'dayjs';
import { arrayToObject } from 'utils/arrayToObject';
import { IGetFuelType } from 'modules/sifarnici/store/fuelType/fuelType.types';
import { IGetLaboratoriesAirWater } from 'modules/sifarnici/store/laboratoriesAirWater/laboratoriesAirWater.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetMethodTypes } from 'modules/sifarnici/store/methodTypes/methodTypes.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { IGetWorkingRegime } from 'modules/sifarnici/store/workingRegime/workingRegime.types';
import { gveService } from './gve.service';

class GveStore implements IBaseObrazacStore<IPostGve> {
  initialState: IPostGve | IGetGve = GVE_INITIAL_STATE;
  goriva_form: IPostGorivaGve | IGetGorivaGve = GVE_GORIVA_INITIAL_STATE;
  bilans_form: IPostBilansGve | IGetBilansGve = GVE_BILANS_INITIAL_STATE;
  emisije_u_vazduh_form: IPostEmisijeUVazduhGve | IGetEmisijeUVazduh = GVE_EMISIJE_U_VAZDUH_INITIAL_STATE;
  izmerene_vrednosti_form: IPostIzmereneVrednostiGve | IGetIzmereneVrednostiGve = GVE_IZMERENE_VREDNOSTII_INITIAL_STATE;

  showEmisijeUVazduh = false;
  isIzvorZagadjenjaEnergetski = false;
  // resetFlag = false;
  filterNacinOdredjivanjaId = '';

  izvorZagadjenjaObject: IGetAir = {} as IGetAir;

  constructor() {
    makeAutoObservable(this);
  }

  get getInitialState() {
    return this.initialState;
  }
  get getGorivaForm() {
    return this.goriva_form;
  }
  get getBilansForm() {
    return this.bilans_form;
  }
  get getIzmereneVrednostiForm() {
    return this.izmerene_vrednosti_form;
  }
  get getEmisijeUVazduhForm() {
    return this.emisije_u_vazduh_form;
  }

  get getMapEmisijeUVazduh() {
    return arrayToObject(
      this.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu,
      'naziv_zagadjujuce_materije_id'
    );
  }

  handleGve = (id: string) => {
    const objArr = this.getMapEmisijeUVazduh;
    const obj = objArr[id] as IGetIzmereneVrednostiGve;
    if (!obj) return;
    return obj.gve;
  };

  handleChange(key: keyof GveStore, value: any) {
    (this as any)[key] = value;
  }
  resetStates = () => {
    this.handleChange('initialState', GVE_INITIAL_STATE);
    this.handleChange('isIzvorZagadjenjaEnergetski', false);
    this.handleChange('showEmisijeUVazduh', false);
    this.handleChange('filterNacinOdredjivanjaId', '');
  };
  handleInitialStateChange(key: keyof IGetGve, value: any) {
    (this as any).initialState[key] = value;
  }

  handleEmisijeUVazduhChange(key: string, value: any) {
    (this as any).emisije_u_vazduh_form[key] = value;
  }

  submitGorivaForm = (payload: IPostGorivaGve) => {
    let newPayload = payload;
    let sifarnikGorivo = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.naziv_goriva_id) {
        sifarnikGorivo = nrizService.addSifarnikInObject({
          exId: (this.goriva_form as IGetGorivaGve).naziv_goriva_id,
          exObj: (this.goriva_form as IGetGorivaGve).naziv_goriva,
          list: sifarniciStore.list[SIFARNICI_ROUTES.FUEL_TYPE],
          name: 'naziv_goriva',
          id: payload.naziv_goriva_id,
        });
      }
    }

    newPayload = {
      //  ...this.goriva_form,
      ...newPayload,
      ...sifarnikGorivo,
      id: (this.goriva_form as IGetGorivaGve).id,
    } as IGetGorivaGve;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.goriva, newPayload);
    // handleSuccessNotify();
    this.handleChange('goriva_form', GVE_GORIVA_INITIAL_STATE);
    this.handleInitialStateChange('goriva', newList);
  };

  submitBilansForm = (payload: IPostBilansGve) => {
    let newPayload = payload;
    let sifarnikZagadjujucaMaterija = {};
    let sifarnikNacinOdredjivanjaKoncentracija = {};
    let sifarnikNacinOdredivanjaEmitovane = {};
    let sifarnikMetodaOdredivanja = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.naziv_zagadjujuce_materije_id) {
        sifarnikZagadjujucaMaterija = nrizService.addSifarnikInObject({
          exId: (this.bilans_form as IGetBilansGve).naziv_zagadjujuce_materije_id,
          exObj: (this.bilans_form as IGetBilansGve).naziv_zagadjujuce_materije,
          list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
          name: 'naziv_zagadjujuce_materije',
          id: payload.naziv_zagadjujuce_materije_id,
        });
      }
    }

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.nacin_odredjivanja_dimni_gas_id) {
        sifarnikNacinOdredjivanjaKoncentracija = nrizService.addSifarnikInObject({
          exId: (this.bilans_form as IGetBilansGve).nacin_odredjivanja_dimni_gas_id,
          exObj: (this.bilans_form as IGetBilansGve).nacin_odredjivanja_dimni_gas,
          list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_BASIS_CODE],
          name: 'nacin_odredjivanja_dimni_gas',
          id: payload.nacin_odredjivanja_dimni_gas_id,
        });
      }
    }

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.nacin_odredjivanja_normalan_rad_id) {
        sifarnikNacinOdredivanjaEmitovane = nrizService.addSifarnikInObject({
          exId: (this.bilans_form as IGetBilansGve).nacin_odredjivanja_normalan_rad_id,
          exObj: (this.bilans_form as IGetBilansGve).nacin_odredjivanja_normalan_rad,
          list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_BASIS_CODE],
          name: 'nacin_odredjivanja_normalan_rad',
          id: payload.nacin_odredjivanja_normalan_rad_id,
        });
      }
    }

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.metod_utvrdjivanja_id) {
        sifarnikMetodaOdredivanja = nrizService.addSifarnikInObject({
          exId: (this.bilans_form as IGetBilansGve).metod_utvrdjivanja_id,
          exObj: (this.bilans_form as IGetBilansGve).metod_utvrdjivanja,
          list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
          name: 'metod_utvrdjivanja',
          id: payload.metod_utvrdjivanja_id,
        });
      }
    }

    newPayload = {
      // ...this.bilans_form,
      ...newPayload,
      ...sifarnikZagadjujucaMaterija,
      ...sifarnikNacinOdredjivanjaKoncentracija,
      ...sifarnikNacinOdredivanjaEmitovane,
      ...sifarnikMetodaOdredivanja,
      id: (this.bilans_form as IGetBilansGve).id,
    } as IGetBilansGve;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.bilans, newPayload);
    // handleSuccessNotify();
    this.handleChange('bilans_form', GVE_BILANS_INITIAL_STATE);
    this.handleChange('filterNacinOdredjivanjaId', '');
    this.handleInitialStateChange('bilans', newList);
  };

  submitIzmereneVrednostiForm = (payload: IPostIzmereneVrednostiGve) => {
    let newPayload = payload;
    let sifarnikZagadjujucaMaterija = {};
    let sifarnikMetodaUtvrdjivanja = {};
    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.naziv_zagadjujuce_materije_id) {
        sifarnikZagadjujucaMaterija = nrizService.addSifarnikInObject({
          exId: (this.izmerene_vrednosti_form as IGetIzmereneVrednostiGve).naziv_zagadjujuce_materije_id,
          exObj: (this.izmerene_vrednosti_form as IGetIzmereneVrednostiGve).naziv_zagadjujuce_materije,
          list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
          name: 'naziv_zagadjujuce_materije',
          id: payload.naziv_zagadjujuce_materije_id,
        });
      }
    }
    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.metod_utvrdjivanja_id) {
        sifarnikMetodaUtvrdjivanja = nrizService.addSifarnikInObject({
          exId: (this.izmerene_vrednosti_form as IGetIzmereneVrednostiGve).metod_utvrdjivanja_id,
          exObj: (this.izmerene_vrednosti_form as IGetIzmereneVrednostiGve).metod_utvrdjivanja,
          list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
          name: 'metod_utvrdjivanja',
          id: payload.metod_utvrdjivanja_id,
        });
      }
    }

    newPayload = {
      // ...this.izmerene_vrednosti_form,
      ...newPayload,
      ...sifarnikZagadjujucaMaterija,
      ...sifarnikMetodaUtvrdjivanja,
      id: (this.izmerene_vrednosti_form as IGetIzmereneVrednostiGve).id,
    } as IGetIzmereneVrednostiGve;
    const newList = obrasciService.submitMicroServiceForm(
      this.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu,
      newPayload,
      true,
      'gve-izmerene-vrednosti-modal'
    );
    // handleSuccessNotify();
    this.handleEmisijeUVazduhChange('merenja_izmerene_vrednosti_emisija_u_vazduhu', newList);
    this.handleChange('izmerene_vrednosti_form', GVE_IZMERENE_VREDNOSTII_INITIAL_STATE);
  };

  submitEmisijeUVazduhForm = (payload: IPostEmisijeUVazduhGve) => {
    if (this.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu.length === 0) return;

    let newPayload = {
      ...payload,
      merenja_izmerene_vrednosti_emisija_u_vazduhu:
        this.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu,
    };

    let sifarnikLaboratorije = {};
    const newDatumMerenja = dayjs(payload.datum_merenja).format('YYYY-MM-DD');

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.naziv_strucne_laboratorije_id) {
        sifarnikLaboratorije = nrizService.addSifarnikInObject({
          exId: (this.emisije_u_vazduh_form as IGetEmisijeUVazduh).naziv_strucne_laboratorije_id,
          exObj: (this.emisije_u_vazduh_form as IGetEmisijeUVazduh).naziv_strucne_laboratorije,
          list: sifarniciStore.list[SIFARNICI_ROUTES.LABORATORIES_AIR_WATER],
          name: 'naziv_strucne_laboratorije',
          id: payload.naziv_strucne_laboratorije_id,
        });
      }
    }

    newPayload = {
      // ...this.emisije_u_vazduh_form,
      ...newPayload,
      ...sifarnikLaboratorije,
      datum_merenja: newDatumMerenja,
      id: (this.emisije_u_vazduh_form as IGetEmisijeUVazduh).id,
      merenja_izmerene_vrednosti_emisija_u_vazduhu: (this.emisije_u_vazduh_form as IGetEmisijeUVazduh)
        .merenja_izmerene_vrednosti_emisija_u_vazduhu,
    } as IGetEmisijeUVazduh;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.emisije_u_vazduh, newPayload);
    if (this.initialState.kreiranje_godisnjeg_bilansa) {
      if (newList?.length) {
        this.calculateGodisnjiBilans(
          newList as IPostEmisijeUVazduhGve[],
          Number(this.initialState.ukupan_broj_radnih_sati_godisnje)
        );
      } else {
        this.handleInitialStateChange('kreiranje_godisnjeg_bilansa', null);
        this.handleInitialStateChange('bilans', []);
      }
    }

    this.handleInitialStateChange('emisije_u_vazduh', newList);
    this.handleChange('emisije_u_vazduh_form', GVE_EMISIJE_U_VAZDUH_INITIAL_STATE);
  };

  handleGodisnjiBilansResponse = (bilans: IGetBilansGve[]) => {
    const merenja = this.initialState.emisije_u_vazduh.flatMap(
      merenje => merenje.merenja_izmerene_vrednosti_emisija_u_vazduhu
    );
    const newArray = bilans.map(item => {
      const zagadjujucaMaterijaArray = arrayToObject(merenja, 'naziv_zagadjujuce_materije_id');
      const metodUtvrdjivanjaArray = arrayToObject(merenja, 'metod_utvrdjivanja_id');

      const zagadjujucaMaterija = zagadjujucaMaterijaArray[
        item.naziv_zagadjujuce_materije_id
      ] as IGetIzmereneVrednostiGve;
      const metodUtvrdjivanja = metodUtvrdjivanjaArray[item.metod_utvrdjivanja_id] as IGetIzmereneVrednostiGve;

      return {
        ...item,
        naziv_zagadjujuce_materije: zagadjujucaMaterija.naziv_zagadjujuce_materije,
        metod_utvrdjivanja: metodUtvrdjivanja.metod_utvrdjivanja,
      };
    });
    return newArray;
  };

  calculateGodisnjiBilans = async (merenjaList: IPostEmisijeUVazduhGve[], ukupna_kolicina: number): Promise<any> => {
    const newPayload = { emisije_u_vazduh: merenjaList, ukupan_broj_radnih_sati_godisnje: ukupna_kolicina };
    const response = await obrasciService.calculateGodisnjiBilans(TIPOVI_OBRAZACA.OBRAZAC_2, newPayload);
    const newResponse = this.handleGodisnjiBilansResponse(response);
    this.handleInitialStateChange('bilans', newResponse);

    return newPayload;
  };

  //// SUBMIT OBRASCA

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetGve>(TIPOVI_OBRAZACA.OBRAZAC_2, id));
    if (err) return;
    if (res.obrazac_2 && res.obrazac_gve) {
      obrasciStore.handleChange('real_obrazac_type', TIPOVI_OBRAZACA.OBRAZAC_2_GVE);
    }
    if (res.lriz_obrazac_2 && res.obrazac_gve) {
      obrasciStore.handleChange('real_obrazac_type', TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE);
    }
    if (res.kreiranje_godisnjeg_bilansa === false) res.kreiranje_godisnjeg_bilansa = null;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newResponse = { ...res, godina: newGodina };
    this.handleChange('initialState', newResponse);
    this.handleChange('izvorZagadjenjaObject', res.izvor_zagadjenja_vazduha);
    if (
      Number(res.godina) > 2020 &&
      (res.izvor_zagadjenja_vazduha.obaveza_merenja?.toLowerCase() === 'da' ||
        res.izvor_zagadjenja_vazduha.obaveza_merenja?.toLowerCase() === 'yes')
    ) {
      this.handleChange('showEmisijeUVazduh', true);
    } else {
      this.handleChange('showEmisijeUVazduh', false);
    }

    return res;
  };

  async postData(payload: IPostGve) {
    const validObrazac = obrasciService.filtersSameObrazac(obrasciStore.real_obrazac_type as TIPOVI_OBRAZACA);
    const validPayload = { ...payload, ...validObrazac };
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostGve>(
        TIPOVI_OBRAZACA.OBRAZAC_2,
        nrizService.setEmptyValuesToNull(validPayload)
      )
    );

    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: any) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostGve>(
        TIPOVI_OBRAZACA.OBRAZAC_2,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;

    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.OBRAZAC_2, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostGve) => {
    const newInitialState = {
      ...GVE_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IGetGve).id,
      godina: obrasciStore.opste.godina,
      goriva: this.initialState.goriva,
      emisije_u_vazduh: this.initialState.emisije_u_vazduh,
      bilans: this.initialState.bilans,
    } as IGetGve;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  // DOHVATANJE SIFARNIKA

  fetchFuelType = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetFuelType>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetFuelType>(SIFARNICI_ROUTES.FUEL_TYPE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      approved: true,
      order_by: '+name_sr',
    });
    return response;
  };

  fetchPollutantOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetPollutantCode>> => {
    const filters = gveService.filtersForPollutatCode();

    const response = await sifarniciStore.fetchSifarnikOptions<IGetPollutantCode>(SIFARNICI_ROUTES.POLLUTANT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code: 'AIR',

      ...filters,
      order_by: '+name_sr',
    });
    return response;
  };

  fetchWayOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodBasisCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodBasisCode>(
      SIFARNICI_ROUTES.METHOD_BASIS_CODE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        //////
      }
    );
    return response;
  };

  fetchMethodOptionsIzmereneVrednosti = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodTypes>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodTypes>(SIFARNICI_ROUTES.METHOD_TYPES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      medium_code: 'AIR',
      method_basis_code: 'M',
      order_by: '+name_sr',
    });
    return response;
  };

  fetchMethodOptionsBilans = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodTypes>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodTypes>(SIFARNICI_ROUTES.METHOD_TYPES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      medium_code: 'AIR',
      method_basis_code_id: this.filterNacinOdredjivanjaId,
      order_by: '+name_sr',
    });
    return response;
  };

  fetchWorkingRegime = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWorkingRegime>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWorkingRegime>(SIFARNICI_ROUTES.WORKING_REGIME, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      name__in: 'Continual,Discontinual',
      order_by: '+name_sr',
    });
    return response;
  };

  fetchLaboratoriesAirWater = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetLaboratoriesAirWater>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetLaboratoriesAirWater>(
      SIFARNICI_ROUTES.LABORATORIES_AIR_WATER,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        is_active: true,
        order_by: '+name_of_laboratory',
        air: true,
      }
    );
    return response;
  };

  fetchIzvorZagadjenjaVazduha = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetAir>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetAir>(SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA, {
      page: pagination.page,
      size: pagination.size,
      postrojenje_id: obrasciStore.opste.postrojenje_id,
      search: search,
      order_by: '+naziv_izvora',
    });
    return response;
  };
}

export const gveStore = new GveStore();
