import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import React, { useEffect } from 'react';
import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { WaterListTable } from './WaterListTable/WaterListTable';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';
import { facilityStore } from 'modules/facility/facility.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

export const FacilityWaterListHeader: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t('POLLUTION.WATER.LIST')}</h3>
      {facilityStore.getFacility.is_active &&
        facilityStore.getFacilityTipoviObrazaca.some(
          (item: any) =>
            item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_3 ||
            item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3 ||
            item.skraceni_naziv === TIPOVI_OBRAZACA.OTV
        ) &&
        facilityStore.readOnlyTipoviObrazacaForm && (
          <AddNewButton
            label="Dodaj izvor emisija u vodu"
            name={'water'}
            onButtonClick={() => {
              modalStore.changeModalName('water-modal');
              waterStore.handleChange('waterShowDevices', false);
            }}
          />
        )}
    </Row>
  );
});

export const FacilityWaterList: React.FC = () => {
  useEffect(() => {
    waterStore.fetchWaterList(paginationInitialState);
  }, []);

  return (
    <InsideContentWrapper header={<FacilityWaterListHeader />}>
      <WaterListTable />
    </InsideContentWrapper>
  );
};
