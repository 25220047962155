import { makeAutoObservable } from 'mobx';
import { AddFacilityUsers, IGetUser, IPostUser, IPostUserResponsibility, UserFormType } from 'modules/user/user.types';
import { nrizService } from 'modules/nriz/nriz.service';
import to from 'await-to-js';
import { formStore, modalStore } from 'store';
import { IPagination } from 'typescript';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetCompany } from 'modules/company/company.types';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { userInitialState } from './user.constants.ts';
import { facilityStore } from 'modules/facility/facility.store';
import { companyStore } from 'modules/company/company.store';
import { userService } from './user.service';
import dayjs from 'dayjs';
import { saveBlob } from 'utils/fileDownload';
import { ITablePagination } from 'components/TableData/TableData';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  user: IPostUser | IGetUser = userInitialState;
  userList: IGetUser[] = [];
  userListPagination: IPagination = paginationInitialState;

  facilityUserList: IGetUser[] = [];
  facilityUserListPagination: IPagination = paginationInitialState;
  userTypeForm: UserFormType | null = null;
  addFacilityUsers: number = AddFacilityUsers.Existing;

  get getUser(): IGetUser | IPostUser {
    return this.user;
  }

  get getUserList(): IGetUser[] {
    return this.userList;
  }

  get getFacilityUserList(): IGetUser[] {
    return this.facilityUserList;
  }

  handleChange(key: keyof UserStore, value: any) {
    (this as any)[key] = value;
  }

  get isUserAdmin() {
    return (this.user as IGetUser).sistem_administrator;
  }

  get isMainCompanyUser() {
    return (this.user as IGetUser).main_company_user;
  }

  putUser = async (payload: IGetUser) => {
    const [err, res] = await to<IEmptyResponse>(userService.putUser(payload));
    if (err || !res) return this.setUser(this.user);
    this.setUser(payload);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  putUserResponsible = async (payload: IGetUser) => {
    const [err, res] = await to<IEmptyResponse>(
      userService.putUserResponsible(payload.id, {
        postrojenje_id: (facilityStore.getFacility as IGetFacility).id,
      })
    );
    if (err || !res) return;
    const newObject = { ...payload, odgovorno_lice: true };
    const newList = nrizService.changeItemInList(
      userService.toggleBooleanToFalseInList(this.facilityUserList, 'odgovorno_lice'),
      newObject
    );
    this.setFacilityUserList([...newList]);
    handleSuccessNotify();
  };

  postUser = async (values: IPostUser) => {
    const newObject = { ...values, preduzece_id: (companyStore.getCompany as IGetCompany).id };
    const [err, res] = await to<IPostResponse>(userService.postUser(newObject));
    if (err || !res) return;
    const { id } = res;
    this.setUserList([{ ...newObject, id, is_active: true } as IGetUser, ...this.userList]);
    this.handleChange('userListPagination', nrizService.increaseTotalPagination(this.userListPagination));
    modalStore.removeAllModals();
    handleSuccessNotify();
    return res;
  };

  postUserRegistration = async (values: IPostUser) => {
    const [err, res] = await to<IPostResponse>(userService.postUserRegistration(values));
    if (err || !res) return;
    this.setUser({ ...values, id: res.id, is_active: true } as IGetUser);
    handleSuccessNotify();
  };

  postUserFacility = async (values: IGetUser) => {
    const payload = {
      postrojenje_id: (facilityStore.facility as IGetFacility).id,
      odgovorno_lice: !this.facilityUserList.some(user => user.odgovorno_lice),
    };
    const [err, res] = await to<IEmptyResponse>(userService.postUserFacility(values.id, payload));
    if (err || !res) return;

    this.setFacilityUserList([
      { ...values, odgovorno_lice: !this.facilityUserList.some(user => user.odgovorno_lice) },
      ...this.facilityUserList,
    ]);
    this.handleChange(
      'facilityUserListPagination',
      nrizService.increaseTotalPagination(this.facilityUserListPagination)
    );
    handleSuccessNotify();
  };

  deleteFacilityUser = async (id: string) => {
    const [err, res] = await to<IEmptyResponse>(
      userService.deleteFacilityUser(id, (facilityStore.getFacility as IGetFacility).id)
    );
    if (err || !res) return;
    this.setFacilityUserList(nrizService.removeFromList(this.facilityUserList, id));
    this.handleChange(
      'facilityUserListPagination',
      nrizService.decreaseTotalPagination(this.facilityUserListPagination)
    );
    handleSuccessNotify();
  };

  fetchUserList = async (pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetUser>>(
      userService.fetchUserList({
        page: pagination?.page || this.userListPagination.page,
        size: pagination?.size || this.userListPagination.size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.KORISNIK]),
      })
    );
    if (err || !res) return;
    const { items, total, page, pages, size } = res;
    this.handleChange('userList', items);
    this.handleChange('userListPagination', { total, page, size, pages });
    return res;
  };

  fetchUserCompanyList = async (pagination?: ITablePagination, search?: string) => {
    const [err, res] = await to<IPaginatedResponse<IGetUser>>(
      userService.fetchUserCompanyList({
        page: pagination?.page || this.userListPagination.page,
        size: pagination?.size || this.userListPagination.size,
        search: search,
        preduzece_id: (companyStore.company as IGetCompany).id,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.KORISNIK]),
      })
    );
    if (err || !res) return;
    const { items, total, page, pages, size } = res;
    this.handleChange('userList', items);
    this.handleChange('userListPagination', { total, page, size, pages });
    return res;
  };

  fetchUserFacilityList = async (pagination?: IPagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetUser>>(
      userService.fetchUserFacilityList({
        page: pagination?.page || this.facilityUserListPagination.page,
        size: pagination?.size || this.facilityUserListPagination.size,
        id: (facilityStore.facility as IGetFacility).id,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.KORISNIK_POSTROJENJE]),
      })
    );
    if (err || !res) return;
    const { items, total, page, pages, size } = res;
    this.handleChange('facilityUserList', items);
    this.handleChange('facilityUserListPagination', { total, page, size, pages });
    return res;
  };

  exportUsersListExcel = async () => {
    const [err, res] = await to<Blob>(
      userService.exportUsersListExcel(nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.KORISNIK]))
    );
    if (err || !res) return;
    const downloadName = `${'Korisnici'}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  postUserAdministrator = async (values: IPostUser) => {
    const [err, res] = await to<IPostResponse>(userService.postUserAdministrator(values));
    if (err || !res) return;
    const { id } = res;
    const newList = [{ ...values, id, is_active: true }, ...this.userList] as IGetUser[];
    this.setUserList(newList);
    this.handleChange('userListPagination', nrizService.increaseTotalPagination(this.userListPagination));
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  fetchUser = async (id: string) => {
    const [err, res] = await to<IGetUser>(userService.fetchUser(id));
    if (err || !res) return;
    this.setUser(res as IGetUser);
    companyStore.setCompany(res.preduzece as IGetCompany);
  };

  setUser = (userObj: IPostUser | IGetUser) => (this.user = userObj);
  setUserList = (userList: IGetUser[] | []) => (this.userList = userList);
  setFacilityUserList = (facilityUserList: IGetUser[] | []) => (this.facilityUserList = facilityUserList);

  setAddFacilityUser = (value: AddFacilityUsers) => {
    this.addFacilityUsers = value;
  };

  setUserTypeForm = (value: UserFormType | null) => {
    this.userTypeForm = value;
  };

  toggleActivateUser = async (isActive: boolean) => {
    const [err, res] = await to<IEmptyResponse>(userService.toggleActivateUser((this.user as IGetUser).id, isActive));
    if (err || !res) return Promise.reject();
    this.setUser({ ...this.user, is_active: isActive });
    return handleSuccessNotify();
  };

  putUserCompanyResponsibility = async (payload: IPostUserResponsibility) => {
    const [err, res] = await to<IEmptyResponse>(
      userService.putUserCompanyResponsibility((this.user as IGetUser).id, payload)
    );
    if (err || !res) return this.setUser(this.user);
    const newUser = { ...this.user, ...payload };
    let userList = this.userList;
    if (this.userList.length) {
      if (
        payload.odgovorna_osoba_za_saradnju_sa_agencijom &&
        !(this.user as IGetUser).odgovorna_osoba_za_saradnju_sa_agencijom
      ) {
        userList = userService.toggleBooleanToFalseInList(userList, 'odgovorna_osoba_za_saradnju_sa_agencijom');
      }
      if (payload.odgovorna_osoba_preduzeca && !(this.user as IGetUser).odgovorna_osoba_preduzeca) {
        userList = userService.toggleBooleanToFalseInList(userList, 'odgovorna_osoba_preduzeca');
      }
      if (payload.odgovorna_osoba_za_izvestavanje && !(this.user as IGetUser).odgovorna_osoba_za_izvestavanje) {
        userList = userService.toggleBooleanToFalseInList(userList, 'odgovorna_osoba_za_izvestavanje');
      }
      this.setUserList(nrizService.changeItemInList(userList, newUser));
    }

    this.setUser(newUser);
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  changeCompanyOwner = async (id: { korisnik_id: string }) => {
    const [err, res] = await to<IEmptyResponse>(userService.changeCompanyOwner(id.korisnik_id));
    if (err || !res) return;
    this.setUser({ ...this.user, main_company_user: false });
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  submitUser = (user: IPostUser) => {
    if (formStore.getCrudButtonType === 'submit') {
      switch (this.userTypeForm) {
        case UserFormType.UserRegistration: {
          return this.postUserRegistration(user);
        }
        case UserFormType.UserCompany: {
          return this.postUser({ ...user, company_user: true });
        }
        case UserFormType.UserFacility: {
          const newFacilityUser = {
            ...user,
            facility_user: true,
            odgovorno_lice: !this.facilityUserList.some(user => user.odgovorno_lice),
            postrojenje_id: (facilityStore.facility as IGetFacility).id,
          } as IGetUser;
          return this.postUser(newFacilityUser);
        }
        case UserFormType.UserAdministrator: {
          return this.postUserAdministrator(user);
        }
      }
    }
    const newObject = { id: (this.user as IGetUser).id, ...user } as IGetUser;
    if (formStore.getCrudButtonType === 'change') {
      // TODO: proveri ovaj zbudz, bez usera pre newobjecta gazi sve ono sto je van forme(responsibility itd...)
      return this.putUser({ ...this.user, ...newObject });
    }
  };
}

export const userStore = new UserStore();
