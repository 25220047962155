import { ColumnsType } from 'antd/es/table';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetEmisijeUVazduh } from 'modules/obrasci/store/gve/gve.types';
import { useTranslation } from 'react-i18next';

const EditGveMerenjeButton: React.FC<EditButtonProps<IGetEmisijeUVazduh>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="gve-merenje"
      modalName="gve-merenje-modal"
      onClick={() =>
        gveStore.handleChange('emisije_u_vazduh_form', { ...record, datum_merenja: dayjs(record.datum_merenja) })
      }
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const GveEmisijaMerenjaTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetEmisijeUVazduh> = [
    {
      title: `${t(['OBRASCI.GVE.REDNI_BROJ_MERENJA'])}`,
      width: 100,
      dataIndex: 'redni_broj_merenja',
      key: 'redni_broj_merenja',
      render: (_, _record, i) => i + 1,
    },
    {
      title: `${t(['OBRASCI.GVE.DATUM_MERENJA'])}`,
      width: 100,
      // dataIndex: 'datum_merenja',
      render: (_: any, record: IGetEmisijeUVazduh) => {
        const datum = dayjs(record.datum_merenja).format('DD-MM-YYYY');
        return <span>{datum}</span>;
      },
      key: 'datum_merenja',
    },
    {
      title: `${t(['OBRASCI.GVE.IDENTIFIKACIONI_BROJ'])}`,
      width: 100,
      dataIndex: 'indetifikacioni_broj_izvestaja',
      key: 'indetifikacioni_broj_izvestaja',
    },
    {
      title: `${t(['OBRASCI.GVE.NAZIV_STRUCNE_LABO'])}`,
      width: 100,
      dataIndex: ['naziv_strucne_laboratorije', 'name_of_laboratory'],
      key: 'name_of_laboratory',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',

      render: (_, record: IGetEmisijeUVazduh) => <EditGveMerenjeButton record={record} />,
    },
  ];

  //// storemyyyy TODO
  return (
    <TableData
      name="emisije-merenje-gve"
      columns={baseColumns}
      dataSource={[...gveStore.initialState.emisije_u_vazduh].sort(
        (a, b) => (new Date(a.datum_merenja) as any) - (new Date(b.datum_merenja) as any)
      )}
    />
  );
});
