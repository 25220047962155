import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore, modalStore } from 'store';
import { IErrors } from 'typescript/NrizTypes';
import { errorHandling } from 'utils/errorHandling';
import { companyStore } from './company/company.store';
import { IGetCompany } from './company/company.types';

const getHeaders = (config: AxiosRequestConfig) => {
  if (!config.headers) {
    return new Error('Missing config.headers');
  }

  const companyId = (companyStore.getCompany as IGetCompany)?.id;
  if (companyId && !authStore.isAdmin) {
    config.params = {
      ...config.params,
      preduzece_header_id: companyId,
    };
  }

  const token = authStore.keycloak.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  else config.headers.Authorization = null;

  config.headers.Accept = '*/*';
  // Login call requires xxx/urlencoded content type
  // we set them in repo file, so here we check if we need to set them again
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  config = { ...config, timeout: 1800000 };

  return config;
};

export const axios = Axios.create({
  baseURL: 'https://nriz-api.sepa.gov.rs/api',
});

export type ApiResponse<T> = Promise<T>;

export const WithAxios = observer(({ children }: any) => {
  const resInterceptorId = useRef<number | null>(null);
  const reqInterceptorId = useRef<number | null>(null);
  const requestCounter = useRef<number>(0);

  const handleError = (error: IErrors) => {
    errorHandling(error);
  };

  const startLoader = () => {
    requestCounter.current += 1;
    if (requestCounter.current === 1) {
      modalStore.startLoader();
    }
  };

  const stopLoader = () => {
    requestCounter.current -= 1;
    if (requestCounter.current === 0) {
      modalStore.endLoader();
    }
  };

  const authRequestInterceptor = (config: AxiosRequestConfig) => {
    startLoader();

    return getHeaders(config);
  };

  const getResponseData = (response: { data: string }) => {
    stopLoader();
    return response.data || response;
  };

  const handleResponseErrors = (error: AxiosError) => {
    stopLoader();
    handleError(error as IErrors);
    return Promise.reject(error);
  };

  const setInterceptor = useCallback(() => {
    //@ts-ignore
    if (axios.interceptors.request.handlers.length > 0) return;
    resInterceptorId.current = axios.interceptors.response.use(getResponseData as any, handleResponseErrors);
    reqInterceptorId.current = axios.interceptors.request.use(authRequestInterceptor as any);
  }, []);

  setInterceptor();

  return children;
});
