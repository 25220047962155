import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { CRUDModal, ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import Decimal from 'decimal.js';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { DEO_6_RAZREDI_PREUZET_INITIAL_STATE } from 'modules/obrasci/store/deo6/deo6.constants';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetRazrediEEPreuzet } from 'modules/obrasci/store/deo6/deo6.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewRazredPreuzetOtpadDeo6Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const [kolicinaDomacinstva, setKolicinaDomacinstva] = useState(
    deo6ObrazacStore.razred_preuzet_form.kolicina_domacinstva
  );
  const [kolicinaDruga, setKolicinaDruga] = useState(deo6ObrazacStore.razred_preuzet_form.kolicina_drugi_izvor);

  const defaultWeeeCategory: ISifarnikInitial | undefined = deo6ObrazacStore.razred_preuzet_form.razred_ee_otpada_id
    ? {
        value: deo6ObrazacStore.razred_preuzet_form.razred_ee_otpada_id,
        label: `${(deo6ObrazacStore.razred_preuzet_form as IGetRazrediEEPreuzet).razred_ee_otpada.the_order} ${
          (deo6ObrazacStore.razred_preuzet_form as IGetRazrediEEPreuzet).razred_ee_otpada.category_name_sr
        }`,
      }
    : undefined;

  const weeeCategoriesOptionMaker = (options: IGetWeeeCategories[]) => {
    return options.map(weeeCategory => ({
      label: `${weeeCategory.the_order} ${weeeCategory.category_name_sr}`,
      value: weeeCategory.id,
    }));
  };

  useEffect(() => {
    if (kolicinaDomacinstva !== null || kolicinaDruga !== null) {
      props.form.validateFields(['kolicina_domacinstva', 'kolicina_drugi_izvor']);
    }
    const sum = new Decimal(kolicinaDomacinstva ?? 0).plus(kolicinaDruga ?? 0);
    props.form.setFieldValue('ukupna_kolicina', sum.toNumber());
  }, [kolicinaDomacinstva, kolicinaDruga]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'razred_ee_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWeeeCategoriesList}
        labelOptionAccessor="category_name_sr"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                deo6ObrazacStore.razred_preuzet_form.razred_ee_otpada_id !== value &&
                deo6ObrazacStore.preuzet_form.razred.some(item => {
                  return value === item.razred_ee_otpada_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.DEO_6.RAZREDI_ERROR')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'razred_ee_otpada_id'}
        label={t('OBRASCI.GIO_3.CLASS_EE_WASTE')}
        colon={true}
        defaultOption={defaultWeeeCategory}
        placeholder={''}
        optionsMaker={weeeCategoriesOptionMaker}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina_domacinstva"
        label={t('Kolicina prikupljena iz domacinstava')}
        rules={[
          // REQUIRED_FIELD_RULE(!kolicinaDruga),
          {
            validator: (_, value) => {
              if (props.form.getFieldValue('kolicina_drugi_izvor') === null && value === null) {
                return Promise.reject(t('VALIDATION.SELECT_A_FIELD'));
              }
              return Promise.resolve();
            },
          },
          ...MORE_THEN_0,
        ]}
        placeholder={''}
        onInputChange={(e: any) => {
          setKolicinaDomacinstva(e);
        }}
        inputName={'kolicina_domacinstva'}
        colon={true}
        precision={4}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina_drugi_izvor"
        label={t('Kolicina prikupljena iz drugih izvora')}
        rules={[
          // REQUIRED_FIELD_RULE(!kolicinaDomacinstva),
          {
            validator: (_, value) => {
              if (props.form.getFieldValue('kolicina_domacinstva') === null && value === null) {
                return Promise.reject(t('VALIDATION.SELECT_A_FIELD'));
              }
              return Promise.resolve();
            },
          },
          ...MORE_THEN_0,
        ]}
        placeholder={''}
        onInputChange={(e: any) => {
          setKolicinaDruga(e);
        }}
        inputName={'kolicina_drugi_izvor'}
        colon={true}
        precision={4}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="ukupna_kolicina"
        label={t('Ukupna prikupljena kolicina')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        placeholder={''}
        inputName={'ukupna_kolicina'}
        colon={true}
        disabled={true}
        precision={4}
        readOnly={readOnly}
      />
    </>
  );
});

export const Deo6RazredPreuzetOtpadaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <CRUDModal
      width="40%"
      name="deo6-razred-preuzet-otpad-modal"
      title={`${t('OBRASCI.GIO_3.CLASS_EE_WASTE')} ${t('WIZARD_TABS.PREUZET_OTPAD')}`}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.razred_preuzet_form}
      children={({ form }) => <NewRazredPreuzetOtpadDeo6Form form={form} />}
      submitForm={deo6ObrazacStore.submitRazredPreuzet}
      initialState={DEO_6_RAZREDI_PREUZET_INITIAL_STATE}
      dataToChange={'razred_preuzet_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
