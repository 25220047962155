import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
// import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './NewGio2DeponijeForm.module.scss';
import { LATITUDE_RULE, LONGITUDE_RULE, MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetDeponijeGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const NewGio2DeponijeForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultMestoOption: ISifarnikInitial | undefined = gio2ObrazacStore.getDeponijeForm.mesto_deponije_id
    ? {
        label: (gio2ObrazacStore.getDeponijeForm as IGetDeponijeGio2).mesto_deponije.display_name,
        value: (gio2ObrazacStore.getDeponijeForm as IGetDeponijeGio2).mesto_deponije.id,
      }
    : undefined;

  const defaultVrstaDeponijeOption: ISifarnikInitial | undefined = gio2ObrazacStore.getDeponijeForm.vrsta_deponije_id
    ? {
        label: (gio2ObrazacStore.getDeponijeForm as IGetDeponijeGio2).vrsta_deponije.name_sr,
        value: (gio2ObrazacStore.getDeponijeForm as IGetDeponijeGio2).vrsta_deponije.id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <Col span={24}>
      <div className={styles.titleMargin}>{t('OBRASCI.GIO2.PODACI_O_DEPONIJI')}</div>
      <FormInput
        name="adresa_deponije"
        type="text"
        label={t('OBRASCI.GIO2.ADRESA_DEPONIJE')}
        placeholder={''}
        inputName={'prolecna_analiza'}
        colon={true}
        rules={[MAX_LENGTH_RULE(200)]}
        readOnly={readOnly}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'mesto_deponije_id'}
        fetchOptinsList={gio2ObrazacStore.fetchMesto}
        labelOptionAccessor="display_name"
        valueOptionAccessor="id"
        inputName={'mesto_deponije_id'}
        label={t('OBRASCI.GIO2.MESTO_DEPONIJE')}
        colon={true}
        defaultOption={defaultMestoOption}
        placeholder={t('OBRASCI.GIO2.MESTO_DEPONIJE')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />

      <FormInput
        type="number"
        name="geografska_sirina"
        label={t('COMPANY.LATITUDE')}
        placeholder={t('COMPANY.LATITUDE')}
        inputName={'geografska_sirina'}
        colon={true}
        readOnly={readOnly}
        rules={LATITUDE_RULE()}
      />
      <FormInput
        type="number"
        name="geografska_duzina"
        label={t('COMPANY.LONGITUDE')}
        placeholder={t('COMPANY.LONGITUDE')}
        inputName={'geografska_duzina'}
        colon={true}
        readOnly={readOnly}
        rules={LONGITUDE_RULE()}
      />
      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'vrsta_deponije_id'}
        fetchOptinsList={gio2ObrazacStore.fetchLandfillType}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'vrsta_deponije_id'}
        label={t('OBRASCI.GIO2.VRSTA_DEPONIJE')}
        colon={true}
        defaultOption={defaultVrstaDeponijeOption}
        placeholder={t('OBRASCI.GIO2.VRSTA_DEPONIJE')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />
      {/* <FormTextArea name={'neznase'} rows={3} labelCol={12} wrapperCol={14} label={t('neznase')} /> */}
    </Col>
  );
});
