import { Col, Row } from 'antd';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { useTranslation } from 'react-i18next';
import { disabledTodayAndFutureDates } from 'utils/formatDate';

import styles from './NewGio5DozvoleForm.module.scss';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import dayjs, { Dayjs } from 'dayjs';
import { IRangePickerProps } from 'typescript';

export const NewGio5DozvoleForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const form = useFormInstance();

  useEffect(() => {
    gio5ObrazacStore.handleChange('dozvoleNizValidacija', []);
    if (gio5ObrazacStore.dozvoleInitial.sakupljanje === true) {
      gio5ObrazacStore.promeniDozvoleNizValidacijaListu('sakupljanje', true);
    }
    if (gio5ObrazacStore.dozvoleInitial.odlaganje === true) {
      gio5ObrazacStore.promeniDozvoleNizValidacijaListu('odlaganje', true);
    }
    if (gio5ObrazacStore.dozvoleInitial.skladistenje === true) {
      gio5ObrazacStore.promeniDozvoleNizValidacijaListu('skladistenje', true);
    }
    if (gio5ObrazacStore.dozvoleInitial.transport === true) {
      gio5ObrazacStore.promeniDozvoleNizValidacijaListu('transport', true);
    }
    if (gio5ObrazacStore.dozvoleInitial.tretman === true) {
      gio5ObrazacStore.promeniDozvoleNizValidacijaListu('tretman', true);
    }
  }, []);

  const readOnly = obrasciStore.zakljucanObrazac;

  const handleDatumIzdavanjaChange = (date: Dayjs | null) => {
    if (!date || date > dayjs(form.getFieldValue('datum_isteka'))) form.setFieldValue('datum_isteka', null);
  };

  const getDisabledDatesForDatumIsteka: IRangePickerProps['disabledDate'] = current => {
    const datumIzdavanja = form.getFieldValue('datum_izdavanja') as Dayjs | null;
    if (!datumIzdavanja) return false;
    return current && current < dayjs(datumIzdavanja);
  };

  return (
    <Col span={24}>
      <div className={styles.labelMargin} style={{ fontWeight: 'bold' }}>
        {t('OBRASCI.GIO2.PODACI_O_DOZVOLI_ZA_UPRAVLJANJE')}
      </div>

      <FormInput
        name="broj_dozvole"
        label={t('OBRASCI.GIO2.BROJ_DOZVOLE')}
        placeholder={t('OBRASCI.GIO2.BROJ_DOZVOLE')}
        inputName={'broj_dozvole'}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          MAX_LENGTH_RULE(100),
          {
            validator(_, value) {
              if (
                gio5ObrazacStore.getDozvoleInitial.broj_dozvole !== value &&
                gio5ObrazacStore.initialState.dozvole.some(item => {
                  return value === item.broj_dozvole;
                })
              ) {
                return Promise.reject(new Error('Dozvola sa ovim brojem vec postoji u listi'));
              }
              return Promise.resolve();
            },
          },
        ]}
        readOnly={readOnly}
      />

      <FormDatePicker
        name="datum_izdavanja"
        label={t('OBRASCI.GIO2.DATUM_IZDAVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
        onChange={handleDatumIzdavanjaChange}
      />
      <FormDatePicker
        name="datum_isteka"
        label={t('OBRASCI.GIO2.DATUM_ISTEKA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={getDisabledDatesForDatumIsteka}
        rules={[REQUIRED_FIELD_RULE(true)]}
        readOnly={readOnly}
      />
      <div className={styles.labelMargin} style={{ fontWeight: 'bold' }}>
        <span>*</span> {t('OBRASCI.GIO2.DOZVOLA_VAZI_ZA')}
      </div>
      {!gio5ObrazacStore.dozvoleNizValidacija.length && (
        <div className={styles.validateError}>{t('OBRASCI.GIO2.VALIDACIJA_CHECKBOX')}</div>
      )}

      <Row>
        <Col span={24}>
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'sakupljanje'}
            label={t('OBRASCI.GIO2.SAKUPLJANJE')}
            onCheckBoxChange={e => {
              gio5ObrazacStore.promeniDozvoleNizValidacijaListu('sakupljanje', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'transport'}
            label={t('OBRASCI.GIO2.TRANSPORT')}
            onCheckBoxChange={e => {
              gio5ObrazacStore.promeniDozvoleNizValidacijaListu('transport', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'skladistenje'}
            label={t('OBRASCI.GIO2.SKLADISTENJE')}
            onCheckBoxChange={e => {
              gio5ObrazacStore.promeniDozvoleNizValidacijaListu('skladistenje', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'tretman'}
            label={t('OBRASCI.GIO2.TRETMAN')}
            onCheckBoxChange={e => {
              gio5ObrazacStore.promeniDozvoleNizValidacijaListu('tretman', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'odlaganje'}
            label={t('OBRASCI.GIO2.ODLAGANJE')}
            onCheckBoxChange={e => {
              gio5ObrazacStore.promeniDozvoleNizValidacijaListu('odlaganje', e.target.checked);
            }}
          />
        </Col>
      </Row>
    </Col>
  );
});
