import { Checkbox, FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ISifarniciTypeOfForm, SwitchSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostYList, IGetYList, IYListFilters } from './yList.types';

export const Y_LIST_FILTERS_INITIAL_STATE: IYListFilters = {
  name__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
  v2: null,
  is_active: null,
};
export const Y_LIST_INITIAL_STATE: IPostYList = {
  name: '',
  description: '',
  description_sr: '',
  v2: false,
  is_active: true,
};

export const Y_LIST_TABLE_DATA: ColumnsType<IGetYList> = [
  NAME_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
  {
    title: 'V2',
    width: 100,
    dataIndex: 'v2',
    key: 'v2',
    render: (_: any, record: IGetYList) => <Checkbox checked={!!record.v2} disabled={true} />,
  },
];
export const Y_LIST_FORM_OPTIONS = (_: FormInstance<IGetYList>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(10),
    DESCRIPTION_FORM(1600),
    DESCRIPTION_SR_FORM(1600),
    {
      type_of_form: 'switch',
      name: 'v2',
      label: 'V2',
    } as SwitchSifarniciFormProps,
  ];
};
