import { ColumnsType } from 'antd/es/table';
import { IGetPollutantCode, IPollutantCodeFilters, IPostPollutantCode } from './pollutantCode.types';
import {
  NAME_FORM,
  NAME_SR_FORM,
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { Checkbox, FormInstance } from 'antd';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import {
  InputSifarniciFormProps,
  ISifarniciTypeOfForm,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';

export const POLLUTANT_CODE_FILTERS_INITIAL_STATE: IPollutantCodeFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  group_code__ilike: null,
  parent_group_id: '',
  cas__ilike: '',
  is_active: null,
};
export const POLLUTANT_CODE_INITIAL_STATE: IPostPollutantCode = {
  name: '',
  name_sr: '',
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  group_code: false,
  parent_group_id: '',
  cas: '',
  is_active: true,
};
export const POLLUTANT_CODE_TABLE_DATA: ColumnsType<IGetPollutantCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  {
    title: 'Šifra grupe',
    width: '100px',
    dataIndex: 'group_code',
    key: 'group_code',
    render: (_: any, record: IGetPollutantCode) => <Checkbox checked={!!record.group_code} disabled={true} />,
  },
  {
    title: 'Cas',
    width: '100px',
    dataIndex: 'cas',
    key: 'cas',
  },
];
export const POLLUTANT_CODE_FORM_OPTIONS = (form: FormInstance<IGetPollutantCode>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(300),
    NAME_SR_FORM(300),
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM(100),
    DESCRIPTION_FORM(500),
    { ...VERSION_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(5)] },
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(150)] },
    {
      type_of_form: 'switch',
      name: 'group_code',
      label: 'Šifra grupe',
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'cas',
      filterName: 'cas__ilike',
      // todo: prevod
      label: 'Cas',
      inputName: 'cas',
    } as InputSifarniciFormProps,
  ];
};
