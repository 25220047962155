import React from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import styles from './SidebarAnt.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { UnsanitaryLandfillModal } from 'components/Modals';
import { UserModal } from 'components/Modals/UserModal/UserModal';
import { FacilityModal } from 'components/Modals/FacilityModal/FacilityModal';
import { WaterModal } from 'components/Modals/WaterModal/WaterModal';
import { SoilModal } from 'components/Modals/SoilModal/SoilModal';
import { AirModal } from 'components/Modals/AirModal/AirModal';
import { Logo } from 'components/Logo/Logo';
import { SifarniciCrudModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciCrudModal';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { IRouteConfig, RoutePathEnum, routes } from 'router/router.constants';
import { flattenChildren } from 'utils/flattenChildren';
import { authStore } from 'store';
import { companyStore } from 'modules/company/company.store';
import { IGetCompany } from 'modules/company/company.types';
import { UserCompanyResponsibilityModal } from 'components/Modals/UserCompanyResponsibilityModal/UserCompanyResponsibilityModal';
import { FILTERS_INITIAL_STATE, filtersStore } from 'store/filteri.store';
import { nrizService } from 'modules/nriz/nriz.service';

const { Sider } = Layout;

export interface ISidebar {
  key: string;
  label: string;
  icon: React.ReactNode;
  path: string;
  subpath?: string[];
  children?: ISidebar[];
}

const SidebarAnt: React.FC = observer(() => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const roleArray = authStore.roles;

  if (!roleArray.length) return null;

  const getMenuPath = (route: IRouteConfig) => {
    switch (route.key) {
      case RoutePathEnum.PROFIL:
        return `${RoutePathEnum.PROFIL}/${authStore?.userId}`;
      case RoutePathEnum.PREDUZECE:
        return `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`;
      default:
        return route.path;
    }
  };

  const generateSidebarItems = (routes: IRouteConfig[]): ISidebar[] => {
    return routes.reduce((menuItems: ISidebar[], route) => {
      if (route.menuProps && route.menuProps.roles.some(role => roleArray.includes(role))) {
        const { key } = route;
        const children = route.children ? generateSidebarItems(route.children) : undefined;
        const { label, icon, subpath } = route.menuProps;
        const newMenuItem = {
          key,
          path: getMenuPath(route),
          children,
          label,
          icon,
          subpath,
        };
        return [...menuItems, newMenuItem];
      }
      return menuItems;
    }, []);
  };

  const menuItems = generateSidebarItems(routes);

  const flatMenuItems = flattenChildren(menuItems);

  const findSelectedKey = () => {
    return flatMenuItems.reduce((selectedKeys: string[], item) => {
      if (pathname.includes(item.path) || item.subpath?.some(path => pathname.includes(path)))
        return [...selectedKeys, item.key];
      return selectedKeys;
    }, []);
  };

  const getOpenSubmenu = () => {
    const openSubmenuKeys = menuItems.filter(route => {
      return route.children && pathname.includes(route.path);
    });
    return openSubmenuKeys.length ? [openSubmenuKeys[0].key.toString()] : [];
  };

  const handleMenuClick: MenuProps['onClick'] = e => {
    const menuItem = flatMenuItems.find(sidebarItem => e.key === sidebarItem.key);
    if (menuItem) {
      nrizService.handleSidebarMenuClickPaginationReset();
      filtersStore.handleChange('filters', FILTERS_INITIAL_STATE);
      navigate(menuItem.path);
    }
  };

  return (
    <div>
      {/* TODO: prebaciti modale u posebnu komponentu */}
      <UserModal />
      <FacilityModal />
      <WaterModal />
      <SoilModal />
      <AirModal />
      <SifarniciCrudModal />
      <UnsanitaryLandfillModal />
      <UserCompanyResponsibilityModal />

      <Layout>
        {/* width is hardcoded here, should be in sync with $sidebar-width in _variables.scss file */}
        <Sider theme="dark" width={250}>
          <div>
            <Logo />
            <Menu
              theme="dark"
              mode="inline"
              defaultOpenKeys={getOpenSubmenu()}
              selectedKeys={findSelectedKey()}
              onClick={handleMenuClick}
              items={menuItems}
              className={styles.items}
            />
          </div>

          <LogOutButton />
        </Sider>
      </Layout>
    </div>
  );
});

export default SidebarAnt;
