import { ColumnsType } from 'antd/es/table';
import { IAktivnost2Filters, IPostAktivnost2 } from './aktivnost2.types';
import { IGetActivity2 } from 'modules/activityList2/activityList2.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import i18n from 'translations/i18n.config';
import { CODE_2_TABLE, CODE_TABLE, NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_2_FORM, CODE_FORM, NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
export const AKTIVNOST_2_INITIAL_STATE: IPostAktivnost2 = {
  code: '',
  name: '',
  name_sr: '',
  minimumthresholdofcapacity: '',
  minimumthresholdofcapacity_sr: '',
  a_level: null,
  code2: '',
  codeofgroup: '',
  is_active: true,
};
export const AKTIVNOST_2_FILTERS_INITIAL_STATE: IAktivnost2Filters = {
  code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  minimumthresholdofcapacity__ilike: '',
  minimumthresholdofcapacity_sr__ilike: '',
  a_level__ilike: null,
  code2__ilike: '',
  codeofgroup__ilike: '',
  is_active: null,
};

export const AKTIVNOST_2_TABLE_DATA: ColumnsType<IGetActivity2> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  CODE_TABLE,

  // todo: prevod
  {
    title: 'Minimalna granična vrednost kapaciteta ',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity',
    key: 'minimumthresholdofcapacity',
  },
  {
    title: 'Minimalna granična vrednost kapaciteta (srp.)',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity_sr',
    key: 'minimumthresholdofcapacity_sr',
  },
  {
    title: 'Nivo a',
    width: '100px',
    dataIndex: 'a_level',
    key: 'a_level',
  },
  CODE_2_TABLE,
  {
    title: 'Kod grupe',
    width: '100px',
    dataIndex: 'codeofgroup',
    key: 'codeofgroup',
  },
];

export const AKTIVNOST_2_FORM_OPTIONS = (_: FormInstance<IGetActivity2>): ISifarniciTypeOfForm[] => {
  return [
    NAME_FORM(1000),
    NAME_SR_FORM(1000),
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity',
      filterName: 'minimumthresholdofcapacity__ilike',
      rules: [MAX_LENGTH_RULE(1000)],
      // todo: prevod
      label: i18n.t('Minimalna granična vrednost kapaciteta '),
      inputName: 'minimumthresholdofcapacity',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity_sr',
      filterName: 'minimumthresholdofcapacity_sr__ilike',
      rules: [MAX_LENGTH_RULE(1000)],
      // todo: prevod
      label: i18n.t('Minimalna granična vrednost kapaciteta (srp.)'),
      inputName: 'minimumthresholdofcapacity_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'a_level',
      filterName: 'a_level__ilike',
      precision: 0,
      // todo: prevod
      label: i18n.t('Nivo a'),
      inputName: 'a_level',
    } as InputSifarniciFormProps,
    { ...CODE_2_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },
    {
      type_of_form: 'input',
      name: 'codeofgroup',
      filterName: 'codeofgroup__ilike',
      rules: [MAX_LENGTH_RULE(10)],
      // todo: prevod
      label: i18n.t('Kod grupe'),
      inputName: 'codeofgroup',
    } as InputSifarniciFormProps,
  ];
};
