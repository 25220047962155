import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CODE_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { CODE_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IGetNuts3AreaCode, INuts3AreaCodeFilters, IPostNuts3AreaCode } from './nuts3AreaCode.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const NUTS3_AREA_CODE_INITIAL_STATE: IPostNuts3AreaCode = {
  code: '',
  name: '',
  is_active: true,
};
export const NUTS3_AREA_CODE_FILTERS_INITIAL_STATE: INuts3AreaCodeFilters = {
  code__ilike: '',
  name__ilike: '',
  is_active: null,
};
export const NUTS3_AREA_CODE_TABLE_DATA: ColumnsType<IGetNuts3AreaCode> = [
  CODE_TABLE,
  { ...NAME_TABLE, width: '600px' },
];
export const NUTS3_AREA_CODE_FORM_OPTIONS = (_: FormInstance<IGetNuts3AreaCode>): ISifarniciTypeOfForm[] => {
  return [{ ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(50)] }, NAME_FORM(100)];
};
