import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IGetSoil, IPostSoil, RequestSoilList } from './soil.types';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';

const fetchSoilList = (payload: RequestSoilList): IPaginatedApiResponse<IGetSoil> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.SOIL}?${query}`);
};

const postSoil = (payload: IPostSoil): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.SOIL}`, payload);
};
const putSoil = (payload: IGetSoil): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.SOIL}${payload.id}`, payload);
};

const toggleActivateSoil = (id: string, isActive: boolean): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.SOIL}${id}/${isActive ? 'aktiviraj' : 'deaktiviraj'}`);
};

export const soilRepo = {
  postSoil,
  putSoil,
  fetchSoilList,
  toggleActivateSoil,
};
