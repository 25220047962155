import * as Sentry from '@sentry/react';

import process from 'process';

import { ENVIRONMENT_TYPES } from '../constants/environment-types.const';

/**
 * Method that check environment stage
 * @return boolean
 */
export const checkEnvironmentType = (environmentType: string): boolean =>
  process.env.NODE_ENV && process.env.NODE_ENV === environmentType;

/**
 * Function for initializing Sentry tool
 */
export const initializeSentry = () => {
  if (checkEnvironmentType(ENVIRONMENT_TYPES.DEVELOPMENT)) return;

  Sentry.init({
    environment: ENVIRONMENT_TYPES.PRODUCTION,
    dsn: 'https://96f7482e92fc475fd080dc0798cd853b@o492963.ingest.us.sentry.io/4507406919073792',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [`${document.location.origin + document.location.pathname}#/`],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
