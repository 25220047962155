import { message } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { opsteInitial } from 'modules/obrasci/obrasci.constants.tsx';
import { OpsteProvera } from 'modules/obrasci/obrasci.types';
import { authStore } from 'store';

function removeUndefinedKeys(keys: string[], object: any) {
  return keys.reduce((validKeys: string[], currentKey) => {
    if (object[currentKey] !== undefined) return [...validKeys, currentKey];
    return validKeys;
  }, []);
}

export function useObrazacExistsCheck() {
  const form = useFormInstance();

  const checkForExistingObrazac = async (remainingOpsteFields?: Partial<OpsteProvera>) => {
    if (!form) return true;

    const { godina, postrojenje_id } = obrasciStore.opste;

    const { isAdmin } = authStore;

    const validParams = { godina, postrojenje_id, ...remainingOpsteFields };

    const obrazacExists = await obrasciStore.checkIfObrazacExists(validParams);

    if (obrazacExists) {
      const remainingKeysToReset = remainingOpsteFields ? Object.keys(remainingOpsteFields) : [];

      const formValues = form.getFieldsValue(true);

      const keysToReset = isAdmin
        ? removeUndefinedKeys(['godina', 'preduzece_id', 'postrojenje_id', ...remainingKeysToReset], formValues)
        : removeUndefinedKeys(['godina', ...remainingKeysToReset], formValues);

      isAdmin ? obrasciStore.handleChange('opste', opsteInitial) : obrasciStore.handleOpste('godina', '');

      form.resetFields(keysToReset);
      message.error('Obrazac sa podacima unetim u opštem delu već postoji!', 2);
    }
    return obrazacExists;
  };

  return checkForExistingObrazac;
}
