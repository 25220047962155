import i18n from 'translations/i18n.config';
import { IPostWater } from './water.types';

export const waterInitialState: IPostWater = {
  broj_ispusta: null,
  naziv_ispusta: '',
  aktivan: false,
  zakljucane_koordinate: false,
  nacin_snabdevanja_vodom: undefined,
  koordinate_long: null,
  koordinate_lat: null,
  projektovani_kapacitet_ispusta: null,
  recipient_type_id: '',
  naziv_recipijenta: '',
  sliv_id: '',
  sanitarna: false,
  tehnoloska: false,
  rashladna: false,
  atmosferska: false,
  postojanje_postrojenja_za_preciscavanje: false,
  resetka: false,
  sito_mehanicki_filter: false,
  peskolov: false,
  aerisani_peskolov: false,
  taloznik_uzduzni: false,
  taloznik_laminarni: false,
  taloznik_radijalni: false,
  separator_masti_i_ulja: false,
  flotator: false,
  pescani_filter: false,
  uredjaj_za_neutralizaciju: false,
  uredjaj_za_detoksikaciju: false,
  jonska_izmena: false,
  hlorisanje: false,
  ozonizacija: false,
  laguna: false,
  aeracioni_bazen: false,
  bio_filter: false,
  bio_disk: false,
  nitrifikacija: false,
  denitrifikacija: false,
  fermentacija_mulja: false,
  prirodna_izmena_toplote: false,
  rashladni_toranj_prirodna_cirkulacija_vazduha: false,
  rashladni_toranj_prisilna_cirkulacija_vazduha: false,
  zatvoreni_rashladni_uredjaji: false,
  napomena: '',
  vodno_telo_id: '',
};

export const optionsDevicesWaterTreatment = [
  {
    title: `${i18n.t('POLLUTION.WATER.MECHANICAL_TREATMENT')}:`,

    items: [
      {
        name: 'resetka',
        label: i18n.t('POLLUTION.WATER.GRID'),
      },
      {
        name: 'sito_mehanicki_filter',
        label: i18n.t('POLLUTION.WATER.SIEVE_MECHANICAL_FILTER'),
      },
      { name: 'peskolov', label: i18n.t('POLLUTION.WATER.SAND_CATCHER') },
      {
        name: 'aerisani_peskolov',
        label: i18n.t('POLLUTION.WATER.AERATED_SAND_CATCHER'),
      },
      {
        name: 'taloznik_uzduzni',
        label: i18n.t('POLLUTION.WATER.PRECIPITATOR_LONGITUDINAL'),
      },
      { name: 'taloznik_laminarni', label: i18n.t('POLLUTION.WATER.PRECIPITATOR_LAMINAR') },
      { name: 'taloznik_radijalni', label: i18n.t('POLLUTION.WATER.PRECIPITATOR_RADIAL') },
      { name: 'separator_masti_i_ulja', label: i18n.t('POLLUTION.WATER.GREASE_AND_OIL_SEPARATOR') },
      { name: 'flotator', label: i18n.t('POLLUTION.WATER.FLOTATOR') },
      { name: 'pescani_filter', label: i18n.t('POLLUTION.WATER.SAND_FILTER') },
    ],
  },
  {
    title: `${i18n.t('POLLUTION.WATER.CHEMICAL_TREATMENT')}:`,

    items: [
      {
        name: 'uredjaj_za_neutralizaciju',
        label: i18n.t('POLLUTION.WATER.NEUTRALISATION_DEVICES'),
      },
      {
        name: 'uredjaj_za_detoksikaciju',
        label: i18n.t('POLLUTION.WATER.DETOXIFICATION_DEVICES'),
      },
      {
        name: 'jonska_izmena',
        label: i18n.t('POLLUTION.WATER.ION_EXCHANGE'),
      },
      {
        name: 'hlorisanje',
        label: i18n.t('POLLUTION.WATER.CHLORINATION'),
      },
      {
        name: 'ozonizacija',
        label: i18n.t('POLLUTION.WATER.OZONATION'),
      },
    ],
  },

  {
    title: `${i18n.t('POLLUTION.WATER.BIOLOGICAL_TREATMENT')}:`,

    items: [
      {
        name: 'laguna',
        label: i18n.t('POLLUTION.WATER.LAGOON'),
      },
      { name: 'aeracioni_bazen', label: i18n.t('POLLUTION.WATER.AERATION_POOL') },
      { name: 'bio_filter', label: i18n.t('POLLUTION.WATER.BIO_FILTER') },
      { name: 'bio_disk', label: i18n.t('POLLUTION.WATER.BIO_DISC') },
      { name: 'nitrifikacija', label: i18n.t('POLLUTION.WATER.NITRIFICATION') },
      { name: 'denitrifikacija', label: i18n.t('POLLUTION.WATER.DENITRIFICATION') },
      { name: 'fermentacija_mulja', label: i18n.t('POLLUTION.WATER.SLUDGE_FERMENTATION') },
    ],
  },
  {
    title: `${i18n.t('POLLUTION.WATER.DEVICES_FOR_CHANGING_THE_HEAT')}:`,
    items: [
      {
        name: 'prirodna_izmena_toplote',
        label: i18n.t('POLLUTION.WATER.NATURAL_HEAT_INTERCHANGE_POOL_LAGOON'),
      },
      {
        name: 'rashladni_toranj_prirodna_cirkulacija_vazduha',
        label: i18n.t('POLLUTION.WATER.COOLING_TOWER_NATURAL_AIR_CIRCULATION'),
      },
      {
        name: 'rashladni_toranj_prisilna_cirkulacija_vazduha',
        label: i18n.t('POLLUTION.WATER.COOLING_TOWER_FORCED_AIR_CIRCULATION'),
      },
      {
        name: 'zatvoreni_rashladni_uredjaji',
        label: i18n.t('POLLUTION.WATER.CLOSED_COOLING_DEVICES'),
      },
    ],
  },
];
