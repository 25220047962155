import { observer } from 'mobx-react-lite';
import styles from './RegistriHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import { REGISTRI_CONFIG } from 'modules/registri/registri.constants';
import { RegistarKeyProps, RegistriEnum } from 'modules/registri/registri.types';
import { useNavigate } from 'react-router-dom';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { registriService } from 'modules/registri/registri.service';

export const RegistriHeader: React.FC<RegistarKeyProps> = observer(({ registar }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onRegistriOptionChange = (value: RegistriEnum) => {
    registriService.handleResetPagination();
    filtersStore.setFilters(FiltersNameEnum.REGISTRI, REGISTRI_CONFIG[value].tableData.filters.initialState);
    navigate(REGISTRI_CONFIG[value].routes.urlRoute);
  };

  const registriOptions = Object.values(REGISTRI_CONFIG).map(registar => ({
    label: registar.label,
    value: registar.key,
  }));

  const onAddNewButtonClick = () => navigate(`${REGISTRI_CONFIG[registar].routes.urlRoute}/novi`);

  return (
    <Row id="registriHeader" className={styles.registriHeader}>
      <Col span={8}>
        <h1>{t('REGISTRI.TITLE')}</h1>
      </Col>
      <Col span={8} className={styles.headerOptions}>
        <Select
          showSearch
          defaultValue={registar}
          options={registriOptions}
          optionFilterProp="label"
          filterOption
          notFoundContent={t('NO_OPTIONS_FOUND')}
          placeholder={t('REGISTRI.SELECT_PLACEHOLDER')}
          onChange={onRegistriOptionChange}
        />
        <AddNewButton label="Dodaj" name={`create-${registar}`} onButtonClick={onAddNewButtonClick} />
      </Col>
    </Row>
  );
});
