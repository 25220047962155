import { IBaseObrazacStore, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import {
  IGetObrazac3,
  IGetObrazac3AnalizaOtpadneVode,
  IGetObrazac3AnalizaRecipijenata,
  IGetObrazac3GlavniIndustrijskiPotrosaciVode,
  IGetObrazac3GodisnjiBilans,
  IGetObrazac3IzmereneVrednostiOtpadneVode,
  IGetObrazac3IzmereneVrednostiRecipijenta,
  IGetObrazac3MerenjeOtpadneVode,
  IGetObrazac3MerenjeRecipijenta,
  IPostObrazac3,
  IPostObrazac3AnalizaOtpadneVode,
  IPostObrazac3AnalizaRecipijenata,
  IPostObrazac3GlavniIndustrijskiPotrosaciVode,
  IPostObrazac3IzmereneVrednostiOtpadneVode,
  IPostObrazac3IzmereneVrednostiRecipijenta,
  IPostObrazac3MerenjeOtpadneVode,
  IPostObrazac3MerenjeRecipijenta,
} from './obrazac3.types';
import {
  OBRAZAC_3_ANALIZA_RECIPIJENATA_INITIAL_STATE,
  OBRAZAC_3_GLAVNI_INDUSTRIJSKI_POTROSACI_INITIAL_STATE,
  OBRAZAC_3_INITIAL_STATE,
  OBRAZAC_3_IZMERENE_VREDNOSTI_OTPADNE_VODE_INITIAL_STATE,
  OBRAZAC_3_IZMERENE_VREDNOSTI_RECIPIJENTA_INITIAL_STATE,
  OBRAZAC_3_OTPADNE_VODE_INITIAL_STATE,
  OBRAZAC_3_OTPADNE_VODE_MERENJE_INITIAL_STATE,
  OBRAZAC_3_RECIPIJENT_MERENJE_INITIAL_STATE,
} from './obrazac3.constants';
import { action, makeAutoObservable, observable } from 'mobx';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import to from 'await-to-js';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { nrizService } from 'modules/nriz/nriz.service';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { handleSuccessNotify } from 'utils/succesHandling';
import { formStore } from 'store';
import dayjs from 'dayjs';
import { arrayToObject } from 'utils/arrayToObject';
import { sifarniciService } from 'modules/sifarnici/sifarnici.service';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import { IGetLaboratoriesAirWater } from 'modules/sifarnici/store/laboratoriesAirWater/laboratoriesAirWater.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetMethodTypes } from 'modules/sifarnici/store/methodTypes/methodTypes.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { IGetSifarnikPretezneDelatnosti } from 'modules/sifarnici/store/sifarnikPretezneDelatnosti/sifarnikPretezneDelatnosti.types';
import { IGetUnitCode } from 'modules/sifarnici/store/unitCode/unitCode.types';
import { IGetWorkingRegime } from 'modules/sifarnici/store/workingRegime/workingRegime.types';
import { OtvOpsteInfo } from 'modules/registri/otv/otv.types';
import { obrazac3Service } from './obrazac3.service';

class Obrazac3Store implements IBaseObrazacStore<IGetObrazac3, IPostObrazac3> {
  initialState: IPostObrazac3 | IGetObrazac3 = OBRAZAC_3_INITIAL_STATE;
  otpadne_vode_form: IPostObrazac3AnalizaOtpadneVode | IGetObrazac3AnalizaOtpadneVode =
    OBRAZAC_3_OTPADNE_VODE_INITIAL_STATE;
  otpadne_vode_merenje_form: IPostObrazac3MerenjeOtpadneVode | IGetObrazac3MerenjeOtpadneVode =
    OBRAZAC_3_OTPADNE_VODE_MERENJE_INITIAL_STATE;
  recipijent_merenje_form: IPostObrazac3MerenjeRecipijenta | IGetObrazac3MerenjeRecipijenta =
    OBRAZAC_3_RECIPIJENT_MERENJE_INITIAL_STATE;
  izmerene_vrednosti_otpadne_vode_form:
    | IPostObrazac3IzmereneVrednostiOtpadneVode
    | IGetObrazac3IzmereneVrednostiOtpadneVode = OBRAZAC_3_IZMERENE_VREDNOSTI_OTPADNE_VODE_INITIAL_STATE;
  izmerene_vrednosti_recipijenta_form:
    | IPostObrazac3IzmereneVrednostiRecipijenta
    | IGetObrazac3IzmereneVrednostiRecipijenta = OBRAZAC_3_IZMERENE_VREDNOSTI_RECIPIJENTA_INITIAL_STATE;
  analiza_recipijenata_form: IPostObrazac3AnalizaRecipijenata | IGetObrazac3AnalizaRecipijenata =
    OBRAZAC_3_ANALIZA_RECIPIJENATA_INITIAL_STATE;
  industrijski_potrosaci_form:
    | IPostObrazac3GlavniIndustrijskiPotrosaciVode
    | IGetObrazac3GlavniIndustrijskiPotrosaciVode = OBRAZAC_3_GLAVNI_INDUSTRIJSKI_POTROSACI_INITIAL_STATE;

  izvor_zagadjenja_vode_id = '';
  nacin_odredjivanja_id = '';
  // godisnji = false;
  // resetFlag = false;
  jedinica_mere_option: IGetUnitCode = {} as IGetUnitCode;
  vodovod = false;
  kanalizacioni = false;
  otvObaveza = false;

  // proveriti respons za bilans obrasca i da se prosiri respons izmerenih vrednosti sa objektom za jedinicu mere
  // i da ne rejzuje eror kad se okine poziv za jedinicu mere sa zagadjujucom materijom koja nema jedinicu mere, nek vrati prazno

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      otpadne_vode_form: observable,
      otpadne_vode_merenje_form: observable,
      izmerene_vrednosti_otpadne_vode_form: observable,
      analiza_recipijenata_form: observable,
      recipijent_merenje_form: observable,
      izmerene_vrednosti_recipijenta_form: observable,
      industrijski_potrosaci_form: observable,
      // resetFlag: observable,
      nacin_odredjivanja_id: observable,
      jedinica_mere_option: observable,

      izvor_zagadjenja_vode_id: observable,
      // godisnji: observable,

      submitAnalizaOtpadneVode: action,
      submitAnalizaRecipijenata: action,
      submitData: action,
      submitIndustrijskiPotrosaci: action,
      submitIzmereneVrednostiOtpadneVode: action,
      submitIzmereneVrednostiRecipijenta: action,
      submitMerenjaOtpadneVode: action,
      submitMerenjaRecipijenta: action,
      handleChange: action,
      handleMerenjeOtpadneChange: action,
      handleMerenjeRecipijentChange: action,
    });
  }

  get getInitialState() {
    return this.initialState;
  }

  get getAnalizaOtpadneVode() {
    return this.otpadne_vode_form;
  }

  get getAnalizaMerenjeOtpadneVode() {
    return this.otpadne_vode_merenje_form;
  }

  get getAnalizaMerenjeRecipijenta() {
    return this.recipijent_merenje_form;
  }

  get getIzmereneVrednostiOtpadneVode() {
    return this.izmerene_vrednosti_otpadne_vode_form;
  }

  get getIzmereneVrednostiRecipijenta() {
    return this.izmerene_vrednosti_recipijenta_form;
  }

  get getAnalizaRecipijenata() {
    return this.analiza_recipijenata_form;
  }

  get getIndustrijskiPotrosaci() {
    return this.industrijski_potrosaci_form;
  }
  // uradi getere

  handleChange(key: keyof Obrazac3Store, value: any) {
    (this as any)[key] = value;
  }

  handleOtpadneVodeForm = (key: any, value: any) => {
    (this as any).otpadne_vode_form[key] = value;
  };

  handleInitialStateChange(key: keyof IGetObrazac3, value: any) {
    (this as any).initialState[key] = value;
  }

  handleMerenjeOtpadneChange(key: keyof IPostObrazac3MerenjeOtpadneVode, value: any) {
    (this as any).otpadne_vode_merenje_form[key] = value;
  }

  handleMerenjeRecipijentChange(key: keyof IPostObrazac3MerenjeRecipijenta, value: any) {
    (this as any).recipijent_merenje_form[key] = value;
  }

  checkVodovodniSistemiTab = () => {
    if (this.vodovod) {
      return false;
    }
    return true;
  };

  checkKanalizacioniSistemiTab = () => {
    if (this.kanalizacioni) {
      return false;
    }
    return true;
  };

  get getIsTabHidden() {
    const hiddenTabsArray = [this.vodovod, this.kanalizacioni];
    return hiddenTabsArray;
  }

  resetStates = () => {
    this.handleChange('initialState', '');
    this.handleChange('initialState', OBRAZAC_3_INITIAL_STATE);
    this.handleChange('izvor_zagadjenja_vode_id', '');
    this.handleChange('nacin_odredjivanja_id', '');
    // this.handleChange('godisnji', false);
    this.handleChange('vodovod', false);
    this.handleChange('kanalizacioni', false);
    this.handleChange('otvObaveza', false);
  };

  handleGodisnjiBilansResponse = (bilans: IGetObrazac3GodisnjiBilans[]) => {
    const merenja = this.initialState.merenja_otpadne_vode.flatMap(merenje => merenje.izmerene_vrednosti_otpadne_vode);
    const newArray = bilans.map(item => {
      const zagadjujucaMaterijaArray = arrayToObject(merenja, 'naziv_zagadjujuce_materije_id');
      const metodOdredjivanjaArray = arrayToObject(merenja, 'metod_odredjivanja_id');
      const jedinicaMereArray = arrayToObject(merenja, 'jedinica_mere_id');

      const zagadjujucaMaterija = zagadjujucaMaterijaArray[
        item.naziv_zagadjujuce_materije_id
      ] as IGetObrazac3IzmereneVrednostiOtpadneVode;
      const metodOdredjivanja = metodOdredjivanjaArray[
        item.metod_odredjivanja_id
      ] as IGetObrazac3IzmereneVrednostiOtpadneVode;
      const jedinicaMere = jedinicaMereArray[item.jedinica_mere_id] as IGetObrazac3IzmereneVrednostiOtpadneVode;

      return {
        ...item,
        naziv_zagadjujuce_materije: zagadjujucaMaterija.naziv_zagadjujuce_materije,
        metod_odredjivanja: metodOdredjivanja.metod_odredjivanja,
        jedinica_mere: jedinicaMere.jedinica_mere,
      };
    });
    return newArray;
  };

  calculateGodisnjiBilans = async (
    merenjaList: IPostObrazac3MerenjeOtpadneVode[],
    ukupna_kolicina: number
  ): Promise<any> => {
    const newPayload = {
      merenja_otpadne_vode: merenjaList,
      ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu: ukupna_kolicina,
    };
    const [err, res] = await to(obrasciService.calculateGodisnjiBilans(TIPOVI_OBRAZACA.OBRAZAC_3, newPayload));

    if (err || !res) return;

    const newResponse = this.handleGodisnjiBilansResponse(res);
    this.handleInitialStateChange('godisnji_bilans', newResponse);

    return newPayload;
  };

  checkOtvObaveza = async ({ preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id }: OtvOpsteInfo) => {
    const obaveza = await obrazac3Service.checkOtvObaveza({ preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id });
    this.handleChange('otvObaveza', obaveza);
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetObrazac3>(TIPOVI_OBRAZACA.OBRAZAC_3, id));
    if (err || !res) return;

    if (res.otv && res.obrazac3) {
      obrasciStore.handleChange('real_obrazac_type', TIPOVI_OBRAZACA.OBRAZAC_3_OTV);
    }
    if (res.otv && res.obrazac3_lriz) {
      obrasciStore.handleChange('real_obrazac_type', TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV);
    }

    const { preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id, godina } = res;

    if (Number(godina) >= 2023 && res.otv)
      this.checkOtvObaveza({ preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id });

    const newGodina = dayjs().year(Number(res.godina));
    if (res.kreiranje_godisnjeg_bilansa === false) res.kreiranje_godisnjeg_bilansa = null;
    this.handleChange('initialState', { ...res, godina: newGodina, id: id });
    if (res.vodovodni_sistem) this.handleChange('vodovod', true);
    if (res.kanalizacioni_sistem) this.handleChange('kanalizacioni', true);

    return res;
  };

  setData?: ((res: IGetObrazac3) => void) | undefined;

  async postData(payload: IPostObrazac3) {
    const validObrazac = obrasciService.filtersSameObrazac(obrasciStore.real_obrazac_type as TIPOVI_OBRAZACA);

    const validPayload = { ...payload, ...validObrazac };
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostObrazac3>(
        TIPOVI_OBRAZACA.OBRAZAC_3,
        nrizService.setEmptyValuesToNull(validPayload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: any) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostObrazac3>(
        TIPOVI_OBRAZACA.OBRAZAC_3,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.OBRAZAC_3, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostObrazac3) => {
    const newInitialState = {
      ...OBRAZAC_3_INITIAL_STATE,
      ...payload,
      id: (this.initialState as IGetObrazac3).id,
      godina: obrasciStore.opste.godina,
      analiza_otpadne_vode: this.initialState.analiza_otpadne_vode,
      merenja_otpadne_vode: this.initialState.merenja_otpadne_vode,
      analiza_recipijenta: this.initialState.analiza_recipijenta,
      merenja_recipijenta: this.initialState.merenja_recipijenta,
      glavni_industrijski_potrosaci_vode: this.initialState.glavni_industrijski_potrosaci_vode,
      godisnji_bilans: this.initialState.godisnji_bilans,
    } as IGetObrazac3;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  fetchSourceWater = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetWater>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWater>(SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE, {
      page: pagination.page,
      size: pagination.size,
      postrojenje_id: obrasciStore.opste.postrojenje_id,
      search: search,
      order_by: '+naziv_ispusta',
    });
    return response;
  };

  fetchLaboratoriesAirWater = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetLaboratoriesAirWater>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetLaboratoriesAirWater>(
      SIFARNICI_ROUTES.LABORATORIES_AIR_WATER,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        order_by: '+name_of_laboratory',
        water: true,
      }
    );
    return response;
  };

  fetchPollutantCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetPollutantCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetPollutantCode>(SIFARNICI_ROUTES.POLLUTANT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code: 'WATER',
      order_by: '+name_sr',
    });
    return response;
  };

  fetchSifarnikPretezneDelatnostni = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetSifarnikPretezneDelatnosti>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetSifarnikPretezneDelatnosti>(
      SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        version__neq: 0,
        order_by: '+code',
      }
    );
    return response;
  };

  fetchWorkingRegime = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWorkingRegime>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWorkingRegime>(SIFARNICI_ROUTES.WORKING_REGIME, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      name__in: 'Continual,Discontinual',
      order_by: '+name_sr',
    });
    return response;
  };

  fetchUnitCode = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetUnitCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetUnitCode>(SIFARNICI_ROUTES.UNIT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      version: '1,2',
      order_by: '+name_sr',
      // ne vraca nista, proveriti
    });
    return response;
  };

  fetchUnitCodeByPollutant = async (pollutantID: string): Promise<IGetUnitCode> => {
    const response = await sifarniciService.fetchUnitCodeByPollutant({
      naziv_zagadjujuce_materije_id: pollutantID,
    });
    return response;
  };

  fetchMethodBasisCode = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodBasisCode>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodBasisCode>(
      SIFARNICI_ROUTES.METHOD_BASIS_CODE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        /// nema order ide name_sr
      }
    );
    return response;
  };

  fetchMethodTypes = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMethodTypes>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodTypes>(SIFARNICI_ROUTES.METHOD_TYPES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      medium_code: 'WATER',
      method_basis_code_id: this.nacin_odredjivanja_id,
      order_by: '+name_sr',
    });
    return response;
  };

  fetchMethodTypesMerenja = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetMethodTypes>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMethodTypes>(SIFARNICI_ROUTES.METHOD_TYPES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      medium_code: 'WATER',
      method_basis_code_id: 'DB4F61E6-C7F5-4CDF-8584-D65860B45D4F',
      order_by: '+name_sr',
    });
    return response;
  };

  // MICRO IZMERENE VREDNOSTI OTPADNE VODE

  submitIzmereneVrednostiOtpadneVode = (payload: IPostObrazac3IzmereneVrednostiOtpadneVode) => {
    let newPayload = payload;
    let sifarnikZagadjujucaMaterija = {};
    let sifarnikMetodOdredjivnja = {};
    let sifarnikJedinicaMere = {};

    if (Object.keys(this.jedinica_mere_option).length)
      sifarnikJedinicaMere = { jedinica_mere: this.jedinica_mere_option };
    else {
      sifarnikJedinicaMere = { jedinica_mere: '' };
    }

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikZagadjujucaMaterija = nrizService.addSifarnikInObject({
        exId: (this.izmerene_vrednosti_otpadne_vode_form as IGetObrazac3IzmereneVrednostiOtpadneVode)
          .naziv_zagadjujuce_materije_id,
        exObj: (this.izmerene_vrednosti_otpadne_vode_form as IGetObrazac3IzmereneVrednostiOtpadneVode)
          .naziv_zagadjujuce_materije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
        name: 'naziv_zagadjujuce_materije',
        id: payload.naziv_zagadjujuce_materije_id,
      });

      sifarnikMetodOdredjivnja = nrizService.addSifarnikInObject({
        exId: (this.izmerene_vrednosti_otpadne_vode_form as IGetObrazac3IzmereneVrednostiOtpadneVode)
          .metod_odredjivanja_id,
        exObj: (this.izmerene_vrednosti_otpadne_vode_form as IGetObrazac3IzmereneVrednostiOtpadneVode)
          .metod_odredjivanja,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
        name: 'metod_odredjivanja',
        id: payload.metod_odredjivanja_id,
      });
    }
    newPayload = {
      // ...this.izmerene_vrednosti_otpadne_vode_form,
      ...newPayload,
      ...sifarnikZagadjujucaMaterija,
      ...sifarnikMetodOdredjivnja,
      ...sifarnikJedinicaMere,
      id: (this.izmerene_vrednosti_otpadne_vode_form as IGetObrazac3IzmereneVrednostiOtpadneVode).id,
    } as IGetObrazac3IzmereneVrednostiOtpadneVode;
    const newList = obrasciService.submitMicroServiceForm(
      this.otpadne_vode_merenje_form.izmerene_vrednosti_otpadne_vode,
      newPayload,
      true,
      'obrazac3-analiza-otpadne-vode-izmerene-vrednosti-modal'
    );
    // handleSuccessNotify();
    this.handleChange('jedinica_mere_option', {});
    this.handleMerenjeOtpadneChange('izmerene_vrednosti_otpadne_vode', newList);
    this.handleChange('izmerene_vrednosti_otpadne_vode_form', OBRAZAC_3_IZMERENE_VREDNOSTI_OTPADNE_VODE_INITIAL_STATE);
  };

  // MICRO IZMERENE VREDNOSTI RECIPIJENTA

  submitIzmereneVrednostiRecipijenta = (payload: IPostObrazac3IzmereneVrednostiRecipijenta) => {
    let newPayload = payload;
    let sifarnikZagadjujucaMaterija = {};
    let sifarnikMetodOdredjivnja = {};
    let sifarnikJedinicaMere = {};

    if (Object.keys(this.jedinica_mere_option).length)
      sifarnikJedinicaMere = { jedinica_mere: this.jedinica_mere_option };

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikZagadjujucaMaterija = nrizService.addSifarnikInObject({
        exId: (this.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .naziv_zagadjujuce_materije_id,
        exObj: (this.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .naziv_zagadjujuce_materije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
        name: 'naziv_zagadjujuce_materije',
        id: payload.naziv_zagadjujuce_materije_id,
      });

      sifarnikMetodOdredjivnja = nrizService.addSifarnikInObject({
        exId: (this.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .metod_odredjivanja_id,
        exObj: (this.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta)
          .metod_odredjivanja,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
        name: 'metod_odredjivanja',
        id: payload.metod_odredjivanja_id,
      });
    }

    newPayload = {
      // ...this.izmerene_vrednosti_recipijenta_form,
      ...newPayload,
      ...sifarnikZagadjujucaMaterija,
      ...sifarnikJedinicaMere,
      ...sifarnikMetodOdredjivnja,
      id: (this.izmerene_vrednosti_recipijenta_form as IGetObrazac3IzmereneVrednostiRecipijenta).id,
    } as IGetObrazac3IzmereneVrednostiRecipijenta;
    const newList = obrasciService.submitMicroServiceForm(
      this.recipijent_merenje_form.izmerene_vrednosti_recipijenta,
      newPayload,
      true,
      'obrazac3-analiza-recipijenata-izmerene-vrednosti-modal'
    );
    // handleSuccessNotify();
    this.handleChange('jedinica_mere_option', {});
    this.handleMerenjeRecipijentChange('izmerene_vrednosti_recipijenta', newList);
    this.handleChange('izmerene_vrednosti_recipijenta_form', OBRAZAC_3_IZMERENE_VREDNOSTI_RECIPIJENTA_INITIAL_STATE);
  };

  // MERENJE

  submitMerenjaOtpadneVode = (payload: IPostObrazac3MerenjeOtpadneVode) => {
    if (this.otpadne_vode_merenje_form.izmerene_vrednosti_otpadne_vode.length === 0) return;

    let newPayload = {
      ...payload,
      izmerene_vrednosti_otpadne_vode: this.otpadne_vode_merenje_form.izmerene_vrednosti_otpadne_vode,
    };
    let sifarnikLaboratorija = {};

    const newDate = dayjs(payload.datum_merenja).format('YYYY-MM-DD');

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikLaboratorija = nrizService.addSifarnikInObject({
        exId: (this.otpadne_vode_merenje_form as IGetObrazac3MerenjeOtpadneVode).naziv_strucne_laboratorije_id,
        exObj: (this.otpadne_vode_merenje_form as IGetObrazac3MerenjeOtpadneVode).naziv_strucne_laboratorije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.LABORATORIES_AIR_WATER],
        name: 'naziv_strucne_laboratorije',
        id: payload.naziv_strucne_laboratorije_id,
      });
    }

    newPayload = {
      // ...this.otpadne_vode_merenje_form,
      ...newPayload,
      ...sifarnikLaboratorija,
      datum_merenja: newDate,
      izmerene_vrednosti_otpadne_vode: (this.otpadne_vode_merenje_form as IGetObrazac3MerenjeOtpadneVode)
        .izmerene_vrednosti_otpadne_vode,
      id: (this.otpadne_vode_merenje_form as IGetObrazac3MerenjeOtpadneVode).id,
    } as IGetObrazac3MerenjeOtpadneVode;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.merenja_otpadne_vode, newPayload);
    if (this.initialState.kreiranje_godisnjeg_bilansa) {
      if (
        newList?.some((merenje: IGetObrazac3MerenjeOtpadneVode) =>
          merenje.izmerene_vrednosti_otpadne_vode.some(izmerenaVrednost => izmerenaVrednost.jedinica_mere_id)
        )
      ) {
        this.calculateGodisnjiBilans(
          newList as IPostObrazac3MerenjeOtpadneVode[],
          Number(this.initialState.ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu)
        );
      } else {
        this.handleInitialStateChange('kreiranje_godisnjeg_bilansa', null);
        this.handleInitialStateChange('godisnji_bilans', []);
      }
    }
    // handleSuccessNotify();
    this.handleInitialStateChange('merenja_otpadne_vode', newList);
    this.handleChange('otpadne_vode_merenje_form', OBRAZAC_3_OTPADNE_VODE_MERENJE_INITIAL_STATE);
  };

  submitMerenjaRecipijenta = (payload: IPostObrazac3MerenjeRecipijenta) => {
    if (this.recipijent_merenje_form.izmerene_vrednosti_recipijenta.length === 0) return;

    let newPayload = {
      ...payload,
      izmerene_vrednosti_recipijenta: this.recipijent_merenje_form.izmerene_vrednosti_recipijenta,
    };
    let sifarnikLaboratorija = {};

    const newDate = dayjs(payload.datum_merenja).format('YYYY-MM-DD');

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikLaboratorija = nrizService.addSifarnikInObject({
        exId: (this.recipijent_merenje_form as IGetObrazac3MerenjeRecipijenta).naziv_strucne_laboratorije_id,
        exObj: (this.recipijent_merenje_form as IGetObrazac3MerenjeRecipijenta).naziv_strucne_laboratorije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.LABORATORIES_AIR_WATER],
        name: 'naziv_strucne_laboratorije',
        id: payload.naziv_strucne_laboratorije_id,
      });
    }
    newPayload = {
      // ...this.recipijent_merenje_form,
      ...newPayload,
      ...sifarnikLaboratorija,
      datum_merenja: newDate,
      izmerene_vrednosti_recipijenta: (this.recipijent_merenje_form as IGetObrazac3MerenjeRecipijenta)
        .izmerene_vrednosti_recipijenta,
      id: (this.recipijent_merenje_form as IGetObrazac3MerenjeRecipijenta).id,
    } as IGetObrazac3MerenjeRecipijenta;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.merenja_recipijenta, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('merenja_recipijenta', newList);
    this.handleChange('recipijent_merenje_form', OBRAZAC_3_RECIPIJENT_MERENJE_INITIAL_STATE);
  };

  // GLAVNI INDUSTRIJSKI POTROSACI

  submitIndustrijskiPotrosaci = (payload: IPostObrazac3GlavniIndustrijskiPotrosaciVode) => {
    let newPayload = payload;
    let sifarnikPreteznaDelatnost = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikPreteznaDelatnost = nrizService.addSifarnikInObject({
        exId: (this.industrijski_potrosaci_form as IGetObrazac3GlavniIndustrijskiPotrosaciVode)
          .sifra_pretezne_delatnosti_id,
        exObj: (this.industrijski_potrosaci_form as IGetObrazac3GlavniIndustrijskiPotrosaciVode)
          .sifra_pretezne_delatnosti,
        list: sifarniciStore.list[SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI],
        name: 'sifra_pretezne_delatnosti',
        id: payload.sifra_pretezne_delatnosti_id,
      });
    }
    newPayload = {
      // ...this.industrijski_potrosaci_form,
      ...newPayload,
      ...sifarnikPreteznaDelatnost,
      id: (this.industrijski_potrosaci_form as IGetObrazac3GlavniIndustrijskiPotrosaciVode).id,
    } as IGetObrazac3GlavniIndustrijskiPotrosaciVode;
    const newList = obrasciService.submitMicroServiceForm(
      this.initialState.glavni_industrijski_potrosaci_vode,
      newPayload
    );
    // handleSuccessNotify();
    this.handleInitialStateChange('glavni_industrijski_potrosaci_vode', newList);
    this.handleChange('industrijski_potrosaci_form', OBRAZAC_3_GLAVNI_INDUSTRIJSKI_POTROSACI_INITIAL_STATE);
  };

  // OTPADNE VODE

  submitAnalizaOtpadneVode = (payload: IPostObrazac3AnalizaOtpadneVode) => {
    let newPayload = payload;
    let sifarnikZagadjujucaMaterija = {};
    let sifarnikNacinOdredjivnja = {};
    let sifarnikMetodOdredjivnja = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikZagadjujucaMaterija = nrizService.addSifarnikInObject({
        exId: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).naziv_zagadjujuce_materije_id,
        exObj: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).naziv_zagadjujuce_materije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
        name: 'naziv_zagadjujuce_materije',
        id: payload.naziv_zagadjujuce_materije_id,
      });

      sifarnikNacinOdredjivnja = nrizService.addSifarnikInObject({
        exId: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).nacin_odredjivanja_id,
        exObj: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).nacin_odredjivanja,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_BASIS_CODE],
        name: 'nacin_odredjivanja',
        id: payload.nacin_odredjivanja_id,
      });

      sifarnikMetodOdredjivnja = nrizService.addSifarnikInObject({
        exId: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).metod_odredjivanja_id,
        exObj: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).metod_odredjivanja,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
        name: 'metod_odredjivanja',
        id: payload.metod_odredjivanja_id,
      });
    }

    newPayload = {
      // ...this.otpadne_vode_form,
      ...newPayload,
      ...sifarnikZagadjujucaMaterija,
      ...sifarnikNacinOdredjivnja,
      ...sifarnikMetodOdredjivnja,
      id: (this.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).id,
    } as IGetObrazac3AnalizaOtpadneVode;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.analiza_otpadne_vode, newPayload);
    // handleSuccessNotify();
    this.handleChange('nacin_odredjivanja_id', '');
    this.handleInitialStateChange('analiza_otpadne_vode', newList);
    this.handleChange('otpadne_vode_form', OBRAZAC_3_OTPADNE_VODE_INITIAL_STATE);
  };

  // ANALIZA RECIPIJENATA

  submitAnalizaRecipijenata = (payload: IPostObrazac3AnalizaRecipijenata) => {
    let newPayload = payload;
    let sifarnikZagadjujucaMaterija = {};
    let sifarnikJedinicaMere = {};
    let sifarnikNacinOdredjivnja = {};
    let sifarnikMetodOdredjivnja = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikZagadjujucaMaterija = nrizService.addSifarnikInObject({
        exId: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).naziv_zagadjujuce_materije_id,
        exObj: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).naziv_zagadjujuce_materije,
        list: sifarniciStore.list[SIFARNICI_ROUTES.POLLUTANT_CODE],
        name: 'naziv_zagadjujuce_materije',
        id: payload.naziv_zagadjujuce_materije_id,
      });

      sifarnikJedinicaMere = nrizService.addSifarnikInObject({
        exId: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).jedinica_mere_id,
        exObj: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).jedinica_mere,
        list: sifarniciStore.list[SIFARNICI_ROUTES.UNIT_CODE],
        name: 'jedinica_mere',
        id: payload.jedinica_mere_id,
      });

      sifarnikNacinOdredjivnja = nrizService.addSifarnikInObject({
        exId: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).nacin_odredjivanja_id,
        exObj: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).nacin_odredjivanja,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_BASIS_CODE],
        name: 'nacin_odredjivanja',
        id: payload.nacin_odredjivanja_id,
      });

      sifarnikMetodOdredjivnja = nrizService.addSifarnikInObject({
        exId: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).metod_odredjivanja_id,
        exObj: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).metod_odredjivanja,
        list: sifarniciStore.list[SIFARNICI_ROUTES.METHOD_TYPES],
        name: 'metod_odredjivanja',
        id: payload.metod_odredjivanja_id,
      });
    }
    newPayload = {
      // ...this.analiza_recipijenata_form,
      ...newPayload,
      ...sifarnikZagadjujucaMaterija,
      ...sifarnikJedinicaMere,
      ...sifarnikNacinOdredjivnja,
      ...sifarnikMetodOdredjivnja,
      id: (this.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).id,
    } as IGetObrazac3AnalizaRecipijenata;
    const newList = obrasciService.submitMicroServiceForm(this.initialState.analiza_recipijenta, newPayload);
    // handleSuccessNotify();
    this.handleChange('nacin_odredjivanja_id', '');
    this.handleInitialStateChange('analiza_recipijenta', newList);
    this.handleChange('analiza_recipijenata_form', OBRAZAC_3_ANALIZA_RECIPIJENATA_INITIAL_STATE);
  };
}

export const obrazac3Store = new Obrazac3Store();
