import { ColumnsType } from 'antd/es/table';
import { IGetTypeOfWaste, IPostTypeOfWaste, ITypeOfWasteFilters } from './typeOfWaste.types';
import { NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const TYPE_OF_WASTE_INITIAL_STATE: IPostTypeOfWaste = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const TYPE_OF_WASTE_FILTERS_INITIAL_STATE: ITypeOfWasteFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};

export const TYPE_OF_WASTE_TABLE_DATA: ColumnsType<IGetTypeOfWaste> = [NAME_TABLE, NAME_SR_TABLE];

export const TYPE_OF_WASTE_FORM_OPTIONS = (_: FormInstance<IGetTypeOfWaste>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(100), NAME_SR_FORM(100)];
};
