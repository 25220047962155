import { makeAutoObservable } from 'mobx';
import {
  IGodina,
  IGodinaTipObrasca,
  IObrazacZaZakljucavanje,
  IZakljucavanjeGodinaIzuzeci,
  IZakljucavanjeGodinaIzuzetakPayload,
  IZakljucavanjeObrazacaFilters,
  OtkljucaniZakljucaniTipoviObrazaca,
} from './zakljucavanje.types';
import { zakljucavanjeService } from './zakljucavanje.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IGetCompany } from 'modules/company/company.types';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { ITablePagination } from 'components/TableData/TableData';
import { IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { handleSuccessNotify } from 'utils/succesHandling';

class ZakljucavanjeStore {
  constructor() {
    makeAutoObservable(this);
  }

  godine: IGodina[] = [];

  selectedGodina: IGodina | null = null;

  tipoviObrazacaPoGodini: IGodinaTipObrasca[] = [];

  izuzeciPoGodini: IZakljucavanjeGodinaIzuzeci[] = [];

  tipObrazacaOptions: ITipObrasca[] = [];

  showOtkljucano: OtkljucaniZakljucaniTipoviObrazaca = OtkljucaniZakljucaniTipoviObrazaca.ZAKLJUCANO;

  obrasciZaZakljucavanje: IObrazacZaZakljucavanje[] = [];

  selectedTipObrasca: ITipObrasca | null = null;

  pagination: IPagination = paginationInitialState;

  get postPagination(): ITablePagination {
    const { page, size } = this.pagination;
    return { page, size };
  }

  resetStates = () => {
    this.setObrasciZaZakljucavanje([]);
    this.setPagination(paginationInitialState);
  };

  setGodine = (godine: IGodina[]) => {
    this.godine = godine;
  };

  setSelectedGodina = (godina: IGodina | null) => {
    this.selectedGodina = godina;
  };

  setTipoviObrazacaPoGodini = (tipoviObrazacaPoGodini: IGodinaTipObrasca[]) => {
    this.tipoviObrazacaPoGodini = [...tipoviObrazacaPoGodini];
  };

  setIzuzeciPoGodini = (izuzeci: IZakljucavanjeGodinaIzuzeci[]) => {
    this.izuzeciPoGodini = izuzeci;
  };

  setTipObrazacaOptions = (tipoviObrazaca: ITipObrasca[]) => {
    this.tipObrazacaOptions = tipoviObrazaca;
  };

  setObrasciZaZakljucavanje = (obrasci: IObrazacZaZakljucavanje[]) => {
    this.obrasciZaZakljucavanje = obrasci;
  };

  setSelectedTipObrasca = (tip: ITipObrasca | null) => {
    this.selectedTipObrasca = tip;
  };

  toggleOtkljucanoZakljucanoTipovi = (value: OtkljucaniZakljucaniTipoviObrazaca) => {
    this.setObrasciZaZakljucavanje([]);
    this.showOtkljucano = value;
  };

  setPagination = (pageInfo: IPagination) => {
    this.pagination = pageInfo;
  };

  changeGodina = (tipId: string, isLocked: boolean) => {
    const newTipovi = this.tipoviObrazacaPoGodini.map(tip => {
      if (tip.tip_obrasca_id === tipId) return { ...tip, zakljucana_godina: isLocked };
      return tip;
    }, []);
    this.setTipoviObrazacaPoGodini(newTipovi);
  };

  fetchGodine = async () => {
    const response = await zakljucavanjeService.fetchGodine();
    if (response) this.setGodine(response);
  };

  fetchTipoviObrazacaPoGodini = async () => {
    if (this.selectedGodina) {
      const response = await zakljucavanjeService.fetchTipoviObrazacaPoGodini(this.selectedGodina?.godina);
      if (response) {
        this.setTipoviObrazacaPoGodini(response.tipovi_obrazaca);
        return response.tipovi_obrazaca;
      }
    }
  };

  toggleLockTipObrasca = async (
    isLocked: boolean,
    tipoviObrazaca: IGodinaTipObrasca[] = this.tipoviObrazacaPoGodini
  ) => {
    if (this.selectedGodina) {
      const updatedTipovi = await zakljucavanjeService.toggleLockTipObrascaZaGodinu(
        this.tipoviObrazacaPoGodini,
        this.selectedGodina.godina,
        tipoviObrazaca,
        isLocked
      );
      this.setTipoviObrazacaPoGodini(updatedTipovi);
    }
  };

  otkljucajGodinu = async () => {
    const updatedGodine = await zakljucavanjeService.otkljucajGodinu(this.godine, this.tipoviObrazacaPoGodini);
    if (updatedGodine) this.setGodine(updatedGodine);
  };

  fetchIzuzeciPoGodini = async (pagination: ITablePagination) => {
    if (this.selectedGodina) {
      const response = await zakljucavanjeService.fetchIzuzeciPoGodini({
        ...pagination,
        godina: this.selectedGodina?.godina,
      });
      if (response) {
        this.setIzuzeciPoGodini(response.items);
        const { page, size, pages, total } = response;
        this.setPagination({ page, size, pages, total });
      }
      return response;
    }
  };

  postIzuzetakPoGodini = async (payload: IZakljucavanjeGodinaIzuzetakPayload) => {
    if (this.selectedGodina) {
      const response = await zakljucavanjeService.postIzuzetakPoGodini(this.selectedGodina, payload);
      if (response) this.fetchIzuzeciPoGodini(this.postPagination);
      return response;
    }
  };

  deleteIzuzetakPoGodini = async (id: string) => {
    const response = await zakljucavanjeService.deleteIzuzetakPoGodini(id);
    if (response) this.fetchIzuzeciPoGodini(this.postPagination);
  };

  fetchPreduzece = async (pagination: ITablePagination, search: string): Promise<IPaginatedResponse<IGetCompany>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompany>(SIFARNICI_ROUTES.PREDUZECE, {
      ...pagination,
      search: search,
    });
    return response;
  };

  fetchTipoviObrazacaPoPreduzecu = async (id: string) => {
    const response = await zakljucavanjeService.fetchTipoviObrazacaPoPreduzecu(id, this.tipoviObrazacaPoGodini);
    this.setTipObrazacaOptions(response);
  };

  fetchZakljucavanjeObrazacaList = async (filters: Partial<IZakljucavanjeObrazacaFilters>) => {
    const response = await zakljucavanjeService.fetchZakljucavanjeObrazacaList(
      { ...this.pagination, ...filters },
      zakljucavanjeService.returnIsLocked(this.showOtkljucano)
    );
    this.setObrasciZaZakljucavanje(response.items);
    const { page, size, total, pages } = response;
    this.setPagination({ page, size, total, pages });

    return response;
  };

  zakljucajObrasce = async (payload: Partial<IZakljucavanjeObrazacaFilters>) => {
    const response = await zakljucavanjeService.zakljucajObrasce(
      payload,
      !zakljucavanjeService.returnIsLocked(this.showOtkljucano)
    );
    if (response) {
      handleSuccessNotify();
    }
    return response;
  };
}

export const zakljucavanjeStore = new ZakljucavanjeStore();
