import { ContentType } from './types/content-type.type';
import { CONTENT_TYPE } from './constants/content-type.const';

export const getExtensionFromContentType = (type: string, fileName: string): string =>
  fileName + (CONTENT_TYPE.find((contentType: ContentType): boolean => contentType.type === type)?.extension || '');

export const saveBlob = (file: Blob, downloadName: string) => {
  const fileUrl = window.URL.createObjectURL(file);
  const fileLink = document.createElement('a');
  fileLink.href = fileUrl;
  fileLink.download = getExtensionFromContentType(file.type, downloadName);
  fileLink.click();
};
