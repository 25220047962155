import { NewUserForm } from 'components/NewForms/NewUserForm/NewUserForm';
import styles from './UserRegistration.module.scss';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from 'store';
import { Alert } from 'antd';

import { LogoWithForm } from 'components/LogoWithForm/LogoWithForm';
import { IGetUser, UserFormType } from 'modules/user/user.types';
import { userStore } from 'modules/user/user.store';
import { useTranslation } from 'react-i18next';

const UserRegistration: React.FC = observer(() => {
  const { t } = useTranslation();
  useEffect(() => {
    userStore.setUserTypeForm(UserFormType.UserRegistration);
  }, []);

  return (
    <LogoWithForm
      component={
        !(userStore.getUser as IGetUser)?.id ? (
          <div className={styles.user_registration}>
            <NewUserForm formName="user-registration" showButtons={true} />
            <p onClick={() => authStore.keycloak.login()} className={styles.logInRedirect}>
              Već imate nalog?
            </p>
          </div>
        ) : (
          <div className={styles.alertWrapper}>
            <Alert message={t('USER.ALERTS.REGISTRATION_SUCCESS')} type={'success'} />
            <p onClick={() => authStore.keycloak.login()} className={styles.logInRedirect}>
              Prijava
            </p>
          </div>
        )
      }
      title="Registracija korisnika"
    />
  );
});
export default UserRegistration;
