import { Modal } from 'components/Modal/Modal';
import { NewWaterPollution } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './WaterModal.module.scss';
import { waterInitialState } from 'modules/sourcesOfPollution/water/water.constants';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';
import { Row } from 'antd';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { facilityStore } from 'modules/facility/facility.store';

export const WaterModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="water-modal"
      open={modalStore.getCurrentOpenedModal.includes('water-modal')}
      className={`${styles.water_pollution_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        waterStore.onWaterModalCancel();
        waterStore.setWater(waterInitialState);
        modalStore.removeAllModals();
      }}
      title={
        <Row justify={'space-between'} style={{ marginRight: '10px' }}>
          <h3>{t('POLLUTION.WATER.TITLE')}</h3>
          {(waterStore.water as IGetWater).id && (
            <IsActiveSwitch
              isActive={waterStore.getWater.aktivan}
              toggleActivate={waterStore.toggleActivateWater}
              disabled={!facilityStore.getFacility.is_active}
            />
          )}
        </Row>
      }
      footer={false}
      destroyOnClose={true}
      maskClosable={true}
      width={waterStore.getShowWaterDevices ? '95%' : '60%'}
    >
      <NewWaterPollution />
    </Modal>
  );
});
