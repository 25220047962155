import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IGetNList, INListFilters, IPostNList } from './nList.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const N_LIST_INITIAL_STATE: IPostNList = {
  name: '',
  description: '',
  description_sr: '',
  is_active: true,
};

export const N_LIST_FILTERS_INITIAL_STATE: INListFilters = {
  name__ilike: '',
  description__ilike: '',
  description_sr__ilike: '',
  is_active: null,
};
// todo: proveriti polje v2

export const N_LIST_TABLE_DATA: ColumnsType<IGetNList> = [
  { ...NAME_TABLE, width: '100px' },
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
];
export const N_LIST_FORM_OPTIONS = (_: FormInstance<IGetNList>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(150), DESCRIPTION_FORM(800), DESCRIPTION_SR_FORM(800)];
};
