import { ColumnsType } from 'antd/es/table';
import { IGetMethodDesignation, IMethodDesignationFilters, IPostMethodDesignation } from './methodDesignation.types';
import {
  NAME_TABLE,
  DESCRIPTION_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import {
  DESCRIPTION_FORM,
  NAME_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const METHOD_DESIGNATION_INITIAL_STATE: IPostMethodDesignation = {
  validity_from_date: '',
  validity_to_date: '',
  name: '',
  description: '',
  is_active: true,
};
export const METHOD_DESIGNATION_FILTERS_INITIAL_STATE: IMethodDesignationFilters = {
  validity_from_date__gte: '',
  validity_to_date__lte: '',
  name__ilike: '',
  description__ilike: '',
  is_active: null,
};
export const METHOD_DESIGNATION_TABLE_DATA: ColumnsType<IGetMethodDesignation> = [
  NAME_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
];
export const METHOD_DESIGNATION_FORM_OPTIONS = (form: FormInstance<IGetMethodDesignation>): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(100), VALIDITY_FROM_DATE_FORM(form), VALIDITY_TO_DATE_FORM(form), DESCRIPTION_FORM(300)];
};
