import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetKolicineGio2, IGetRazrediEeOtpadaGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { TableGio2RazrediEe } from './components/TableGio2RazrediEe/TableGio2RazrediEe';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { resetFormFields } from 'utils/resetFormFieldValues';
import { drListOptions } from 'utils/optionMakers';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import Decimal from 'decimal.js';

export const NewGio2KolicineForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultTypeOfWaste: ISifarnikInitial | undefined = gio2ObrazacStore.getKolicineForm.indeksni_broj_otpada_id
    ? {
        label: `${(gio2ObrazacStore.getKolicineForm as IGetKolicineGio2).indeksni_broj_otpada.index_number} ${(
          gio2ObrazacStore.getKolicineForm as IGetKolicineGio2
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
        value: gio2ObrazacStore.getKolicineForm.indeksni_broj_otpada_id,
      }
    : undefined;

  const defaultPostupakOdlaganja: ISifarnikInitial | undefined = gio2ObrazacStore.getKolicineForm
    .postupak_odlaganja_d_oznaka_id
    ? {
        value: gio2ObrazacStore.getKolicineForm.postupak_odlaganja_d_oznaka_id,
        label: `${(gio2ObrazacStore.getKolicineForm as IGetKolicineGio2).postupak_odlaganja_d_oznaka.name} ${
          (gio2ObrazacStore.getKolicineForm as IGetKolicineGio2).postupak_odlaganja_d_oznaka.description_sr
        }`,
      }
    : undefined;

  const defaultDrugiPostupakOdlaganja: ISifarnikInitial | undefined = gio2ObrazacStore.getKolicineForm
    .drugi_postupak_odlaganja_d_oznaka_id
    ? {
        value: gio2ObrazacStore.getKolicineForm.drugi_postupak_odlaganja_d_oznaka_id,
        label: `${(gio2ObrazacStore.getKolicineForm as IGetKolicineGio2).drugi_postupak_odlaganja_d_oznaka.name} ${
          (gio2ObrazacStore.getKolicineForm as IGetKolicineGio2).drugi_postupak_odlaganja_d_oznaka.description_sr
        }`,
      }
    : undefined;

  const onPostupakKolicinaChange = useDebouncedCallback(() => {
    const prvi = new Decimal(form.getFieldValue('postupak_odlaganja_kolicina') || 0);
    const drugi = form.getFieldValue('drugi_postupak_odlaganja_kolicina') || 0;
    const kolicinaOdlaganje = form.getFieldValue('kolicina_otpada_za_odlaganje') || 0;
    const kolicinaSpaljivanje = form.getFieldValue('kolicina_otpada_za_spaljivanje') || 0;

    form.setFieldValue(
      'ukupna_kolicina_ove_vrste_otpada',
      prvi.plus(drugi).plus(kolicinaOdlaganje).plus(kolicinaSpaljivanje).toNumber()
    );
    form.validateFields(['ukupna_kolicina_ove_vrste_otpada']);
  }, 500);

  const [kolicinaReq, setKolicinaReq] = useState(false);

  useEffect(() => {
    setKolicinaReq(!form.getFieldValue('ukupna_kolicina_ove_vrste_otpada'));
  }, [Form.useWatch('ukupna_kolicina_ove_vrste_otpada'), form]);

  const [weee_categories, setWeeeCategories] = useState(false);
  useEffect(() => {
    if (weee_categories) {
      form.validateFields(['ukupna_kolicina_ove_vrste_otpada']);
    }
  }, [gio2ObrazacStore.razredi_ee_form]);

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  useEffect(() => {
    gio2ObrazacStore.handleChange('index_id', form.getFieldValue('indeksni_broj_otpada_id'));
    if (Number(obrasciStore.opste.godina) >= 2019) {
      if (!isEmpty((gio2ObrazacStore.kolicine_form as IGetKolicineGio2).indeksni_broj_otpada)) {
        return setWeeeCategories((gio2ObrazacStore.kolicine_form as IGetKolicineGio2).indeksni_broj_otpada.weee);
      }
      if (form.getFieldValue('indeksni_broj_otpada_id') !== '') {
        const indexObj = gio2ObrazacStore.indexMap(form.getFieldValue('indeksni_broj_otpada_id'));
        setWeeeCategories(indexObj?.weee);
      }
    }
  }, [Form.useWatch('indeksni_broj_otpada_id', form)]);

  useEffect(() => {
    if (!form.getFieldValue('drugi_postupak_odlaganja_kolicina')) {
      resetFormFields(form, ['drugi_postupak_odlaganja_d_oznaka_id']);
    }
  }, [Form.useWatch('drugi_postupak_odlaganja_kolicina', form)]);

  const readOnly = obrasciStore.zakljucanObrazac;

  return (
    <>
      <h3>{t('OBRASCI.GIO2.KOLICINA_PREUZETOG_OTPADA')}</h3>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={gio2ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        optionsMaker={wasteCatalogueOptions}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                gio2ObrazacStore.kolicine_form.indeksni_broj_otpada_id !== value &&
                gio2ObrazacStore.initialState.kolicine.some(item => {
                  return value === item.indeksni_broj_otpada_id;
                })
              ) {
                setWeeeCategories(false);
                gio2ObrazacStore.handleChange('index_id', '');
                return Promise.reject(new Error(t('OBRASCI.DEO_6.INDEX_ERROR')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'indeksni_broj_otpada_id'}
        disabled={!!gio2ObrazacStore.index_id}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="kolicina_preuzetog_otpada"
        label={t('OBRASCI.GIO_3.AMOUNT_OF_COLLECTED_WASTE_IN_REPORTING_YEAR')}
        placeholder={''}
        inputName={'kolicina_preuzetog_otpada'}
        colon={true}
        rules={[...MORE_THEN_0_IS_REQUIRED]}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="ukupna_kolicina_ove_vrste_otpada"
        label={t('OBRASCI.GIO2.UKUPNA_KOLICINA_ODLOZENOG')}
        rules={[
          {
            validator(_, value) {
              if (weee_categories && Number(obrasciStore.opste.godina) >= 2019) {
                let sum = new Decimal(0);
                (gio2ObrazacStore.kolicine_form as IGetKolicineGio2).razredi_ee_otpada.map(
                  (item: IGetRazrediEeOtpadaGio2) => (sum = sum.plus(item.kolicina ? item.kolicina : 0))
                );
                if (sum.toNumber() !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        placeholder={''}
        inputName={'ukupna_kolicina_ove_vrste_otpada'}
        colon={true}
        disabled={true}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="stanje_na_deponiji_na_dan_1_januar"
        label={t('OBRASCI.GIO_3.BALANCE_OF_THE_TEMPORARY_WAREHOUSE_JANUARY')}
        placeholder={''}
        inputName={'stanje_na_deponiji_na_dan_1_januar'}
        colon={true}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="stanje_na_deponiji_na_dan_31_decembar"
        label={t('OBRASCI.GIO_3.BALANCE_OF_THE_TEMPORARY_WAREHOUSE_DECEMBER')}
        placeholder={''}
        inputName={'stanje_na_deponiji_na_dan_31_decembar'}
        colon={true}
        readOnly={readOnly}
      />
      {Number(obrasciStore.opste.godina) < 2019 && (
        <>
          <FormInputNumber
            name="kolicina_otpada_za_odlaganje"
            label={t('OBRASCI.GIO2.KOLICINA_OTPADA_ZA_ODLAGANJE')}
            placeholder={''}
            inputName={'kolicina_otpada_za_odlaganje'}
            colon={true}
            onInputChange={() => onPostupakKolicinaChange()}
            rules={[REQUIRED_FIELD_RULE(kolicinaReq), ...MORE_THEN_0]}
            readOnly={readOnly}
          />
          <FormInputNumber
            name="kolicina_otpada_za_spaljivanje"
            label={t('OBRASCI.GIO2.KOLICINA_OTPADA_ZA_SPALJIVANJE')}
            placeholder={''}
            inputName={'kolicina_otpada_za_spaljivanje'}
            colon={true}
            onInputChange={() => onPostupakKolicinaChange()}
            rules={[REQUIRED_FIELD_RULE(kolicinaReq), ...MORE_THEN_0]}
            readOnly={readOnly}
          />
        </>
      )}

      <>
        <h3>{t('OBRASCI.GIO2.POSTUPAK_ODLAGANJA')}</h3>
        <FormInputNumber
          name="postupak_odlaganja_kolicina"
          label={t('OBRASCI.GIO_3.QUANTITY_T')}
          rules={[REQUIRED_FIELD_RULE(kolicinaReq), ...MORE_THEN_0]}
          placeholder={''}
          onInputChange={value => {
            if (!value || value < 1) {
              form.setFieldValue('postupak_odlaganja_d_oznaka_id', '');
            }
            onPostupakKolicinaChange();
          }}
          inputName={'postupak_odlaganja_kolicina'}
          colon={true}
          readOnly={readOnly}
        />
        {Form.useWatch('postupak_odlaganja_kolicina', form) > 0 && (
          <FormSearchInfiniteScroll
            showSearch
            formName={'postupak_odlaganja_d_oznaka_id'}
            fetchOptinsList={gio2ObrazacStore.fetchDrList}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[REQUIRED_FIELD_RULE(true)]}
            inputName={'postupak_odlaganja_d_oznaka_id'}
            label={t('OBRASCI.GIO_3.D_MARK')}
            colon={true}
            defaultOption={defaultPostupakOdlaganja}
            placeholder={''}
            optionsMaker={drListOptions}
            readOnly={readOnly}
          />
        )}
      </>

      <>
        <h3>{t('OBRASCI.GIO2.DRUGI_POSTUPAK_ODLAGANJA')}</h3>
        <FormInputNumber
          name="drugi_postupak_odlaganja_kolicina"
          label={t('OBRASCI.GIO_3.QUANTITY_T')}
          rules={[REQUIRED_FIELD_RULE(kolicinaReq), ...MORE_THEN_0]}
          placeholder={''}
          onInputChange={value => {
            if (!value || value < 1) {
              form.setFieldValue('drugi_postupak_odlaganja_d_oznaka_id', '');
            }
            onPostupakKolicinaChange();
          }}
          inputName={'drugi_postupak_odlaganja_kolicina'}
          colon={true}
          readOnly={readOnly}
        />

        {Form.useWatch('drugi_postupak_odlaganja_kolicina', form) > 0 && (
          <FormSearchInfiniteScroll
            showSearch
            formName={'drugi_postupak_odlaganja_d_oznaka_id'}
            fetchOptinsList={gio2ObrazacStore.fetchDrList}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[REQUIRED_FIELD_RULE(true)]}
            inputName={'drugi_postupak_odlaganja_d_oznaka_id'}
            label={t('OBRASCI.GIO_3.D_MARK')}
            colon={true}
            defaultOption={defaultDrugiPostupakOdlaganja}
            placeholder={''}
            optionsMaker={drListOptions}
            readOnly={readOnly}
          />
        )}
      </>

      {Number(obrasciStore.opste.godina) >= 2019 && weee_categories && (
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              title={t('OBRASCI.GIO_3.TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE')}
              name={'gio2-razredi-ee-form'}
              modalName="gio2-razredi-ee-modal"
            />
          }
        >
          <TableGio2RazrediEe />
        </InsideContentWrapper>
      )}
    </>
  );
});
