import { ColumnsType } from 'antd/es/table';
import { IGetWaterArea, IPostWaterArea, IWaterAreaFilters } from './waterArea.types';
import { FormInstance } from 'antd';
import i18n from 'translations/i18n.config';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const WATER_AREA_INITIAL_STATE: IPostWaterArea = {
  water_area_name: '',
  is_active: true,
};
export const WATER_AREA_FILTERS_INITIAL_STATE: IWaterAreaFilters = {
  water_area_name__ilike: '',
  is_active: null,
};
export const WATER_AREA_TABLE_DATA: ColumnsType<IGetWaterArea> = [
  { title: i18n.t('UNSANITARY_LANDFILL.NAME'), width: '700px', dataIndex: 'water_area_name', key: 'water_area_name' },
];
export const WATER_AREA_FORM_OPTIONS = (_: FormInstance<IGetWaterArea>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      name: 'water_area_name',
      filterName: 'water_area_name__ilike',
      label: i18n.t('UNSANITARY_LANDFILL.NAME'),
      inputName: 'water_area_name',
      rules: [MAX_LENGTH_RULE(300)],
    } as InputSifarniciFormProps,
  ];
};
