import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  IPostWasteWaterTreatmentMethod,
  IGetWasteWaterTreatmentMethod,
  IWasteWaterTreatmentMethodFilters,
} from './wasteWaterTreatmentMethod.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const WASTE_WATER_TREATMENT_METHOD_FILTERS_INITIAL_STATE: IWasteWaterTreatmentMethodFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  is_active: null,
};
export const WASTE_WATER_TREATMENT_METHOD_INITIAL_STATE: IPostWasteWaterTreatmentMethod = {
  name: '',
  name_sr: '',
  is_active: true,
};

export const WASTE_WATER_TREATMENT_METHOD_TABLE_DATA: ColumnsType<IGetWasteWaterTreatmentMethod> = [
  NAME_TABLE,
  NAME_SR_TABLE,
];

export const WASTE_WATER_TREATMENT_METHOD_FORM_OPTIONS = (
  _: FormInstance<IGetWasteWaterTreatmentMethod>
): ISifarniciTypeOfForm[] => {
  return [NAME_FORM(100), NAME_SR_FORM(100)];
};
