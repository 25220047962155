import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { observer } from 'mobx-react-lite';
import { MORE_THAN_OR_EQUAL_0_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const KolicinaSakupljenogOtpadaIzReciklaze: React.FC = observer(() => {
  const readOnly = obrasciStore.zakljucanObrazac;
  const { t } = useTranslation();
  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>
        {t('OBRASCI.KOM_1.MANAGEMENT.THE_AMOUNT_OF_COLLECTED_WASTE_FROM_PRIMARY_RECICLING')}
      </div>
      <FormInputNumber
        name="iz_kontejnera_za_primarnu_selekciju"
        label={t('OBRASCI.KOM_1.MANAGEMENT.FROM_THE_PRIMARY_SELECTION')}
        placeholder={''}
        inputName={'iz_kontejnera_za_primarnu_selekciju'}
        colon={true}
        rules={[MORE_THAN_OR_EQUAL_0_RULE]}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="iz_reciklaznih_dvorista"
        label={t('OBRASCI.KOM_1.MANAGEMENT.FROM_RECYCLING_YARDS')}
        rules={[MORE_THAN_OR_EQUAL_0_RULE]}
        placeholder={''}
        inputName={'iz_reciklaznih_dvorista'}
        colon={true}
        readOnly={readOnly}
      />
      <FormInputNumber
        name="drugih_sabirnih_mesta"
        label={`${t('OBRASCI.KOM_1.MANAGEMENT.OTHER_COLLECTION_POINTS')} (t)`}
        rules={[MORE_THAN_OR_EQUAL_0_RULE]}
        placeholder={''}
        inputName={'drugih_sabirnih_mesta'}
        colon={true}
        readOnly={readOnly}
      />
    </div>
  );
});
