import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { WithAxios } from 'modules/axios';
import { initializeSentry } from './utils/helpers/sentry.helper';
import App from './App';

import './index.scss';

initializeSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <WithAxios>
    <App />
  </WithAxios>
);

reportWebVitals();
