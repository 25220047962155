import { Col, Row } from 'antd';
import { Button } from 'components/Button/Button';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import {
  MAX_AND_MIN_NUMBER,
  MAX_LENGTH_RULE,
  MORE_THAN_OR_EQUAL_0_RULE,
  MORE_THEN_0,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import styles from './NewObrazac3GodisnjPitanjeForm.module.scss';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { IGetObrazac3 } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWorkingRegime } from 'modules/sifarnici/store/workingRegime/workingRegime.types';
import { FormInput } from 'components/FormInput/FormInput';

export const NewObrazac3GodisnjPitanjeForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const form = props.form;

  const workingRegimeOptionMaker = (options: IGetWorkingRegime[]) => {
    return options.map(regime => ({
      label: regime.name_sr,
      value: regime.id,
      item: regime,
    }));
  };

  const defaultWorkingRegimeOption: ISifarnikInitial | undefined = obrazac3Store.initialState.radni_rezim_ispusta_id
    ? {
        label: (obrazac3Store.initialState as IGetObrazac3).radni_rezim_ispusta.name_sr,
        value: obrazac3Store.initialState.radni_rezim_ispusta_id,
      }
    : undefined;

  const handleGodisnjiSubmit = async () => {
    const ukupna = Number(form.getFieldValue('ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu'));
    try {
      await form.validateFields([
        'radni_rezim_ispusta_id',
        'vremenski_period_ispustanja',
        'ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu',
        'nacin_odredjivanja_kolicine_ispustene_vode',
        'ukupan_kapacitet_proizvodnje_t_god',
      ]);
      obrazac3Store.calculateGodisnjiBilans(obrazac3Store.initialState.merenja_otpadne_vode, ukupna);
      modalStore.removeAllModals();
    } catch {
      return;
    }
  };

  return (
    <Col span={24} className={styles.wrapper}>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'radni_rezim_ispusta_id'}
        fetchOptinsList={obrazac3Store.fetchWorkingRegime}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'radni_rezim_ispusta_id'}
        label={t('OBRASCI.OBRAZAC3.RADNI_REZIM_ISPUSTA')}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultWorkingRegimeOption}
        optionsMaker={workingRegimeOptionMaker}
        onChange={(value, option) => {
          obrazac3Store.handleInitialStateChange('radni_rezim_ispusta_id', value);
          obrazac3Store.handleInitialStateChange('radni_rezim_ispusta', option.item);
        }}
        placeholder={''}
      />
      <FormInputNumber
        name="vremenski_period_ispustanja"
        label={t('OBRASCI.OBRAZAC3.VREMENSKI_PERIOD_ISPUSTANJA')}
        placeholder={''}
        inputName={'vremenski_period_ispustanja'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true), ...MAX_AND_MIN_NUMBER(1, 366)]}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        precision={0}
      />
      <FormInputNumber
        name="ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu"
        label={t('OBRASCI.OBRAZAC3.UKUPNA_KOLICINA_ISPUSTENE')}
        placeholder={''}
        inputName={'ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true), MORE_THAN_OR_EQUAL_0_RULE]}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
      />
      <FormInput
        name="nacin_odredjivanja_kolicine_ispustene_vode"
        label={t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA_ISPUSTENE')}
        placeholder={''}
        inputName={'nacin_odredjivanja_kolicine_ispustene_vode'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(300)]}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
      />
      {obrazac3Store.otvObaveza && (
        <>
          <FormInputNumber
            name={'ukupan_kapacitet_proizvodnje_t_god'}
            label={t('OBRASCI.OBRAZAC3.UKUPAN_KAPACITET_PROIZVODNJE')}
            placeholder={''}
            inputName={'ukupan_kapacitet_proizvodnje_t_god'}
            colon
            rules={[REQUIRED_FIELD_RULE(true), ...MORE_THEN_0]}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
          />
          <h5 className={styles.ukupanKapacitetInfo}>
            {t('OBRASCI.OBRAZAC3.UKUPAN_KAPACITET_PROIZVODNJE_DESCRIPTION')}
          </h5>
        </>
      )}
      <Row justify="end">
        <Button onClick={handleGodisnjiSubmit} disabled={modalStore.isLoading} name={'generate_bilans'}>
          {t('OBRASCI.GVE.BILANS_GENERISI')}
        </Button>
      </Row>
    </Col>
  );
});
