import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { lcpStore } from './lcp/lcp.store';
import { otvStore } from './otv/otv.store';
import { registriRepo } from './registri.repo';

class RegistriService {
  handleResetPagination = () => {
    otvStore.setPagination(paginationInitialState);
    lcpStore.setPagination(paginationInitialState);
  };

  checkIfRegistarExists = (postrojenje_id: string, izvor_zagadjenja_vode_id?: string) => {
    const payload = { postrojenje_id, izvor_zagadjenja_vode_id };
    return registriRepo.checkIfRegistarExists(payload);
  };
}

export const registriService = new RegistriService();
