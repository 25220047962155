import to from 'await-to-js';
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

import { ITablePagination } from 'components/TableData/TableData';
import { nrizService } from 'modules/nriz/nriz.service';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { saveBlob } from 'utils/fileDownload';
import { obrazacIzvestajiService } from './obrazacIzvestaji.service';
import {
  Deo6IzvestajiEnum,
  IzvestajiSubtypes,
  Obrazac3IzvestajiEnum,
  // Obrazac3PrtrPregledPoGodinama,
  Obrazac5IzvestajiEnum,
  // Obrazac5NeOpasan,
  ObrazacIzvestajiAllReports,
  RekalkulacijaIzvestajTableData,
} from './obrazacIzvestaji.types';
import { IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

class ObrazacIzvestajiStore {
  obrazacIzvestaj: ObrazacIzvestajiAllReports = [];
  obrazacDrugiIzvestaj: ObrazacIzvestajiAllReports | null = null;
  obrazacIzvestajPagination: IPagination = paginationInitialState;

  constructor() {
    makeAutoObservable(this);
  }

  setObrazacIzvestaj = (izvestaj: ObrazacIzvestajiAllReports) => {
    this.obrazacIzvestaj = nrizService.addKeyToChildrenData(izvestaj);
  };

  setObrazacIzvestajPagination = (pagination: IPagination) => {
    this.obrazacIzvestajPagination = pagination;
  };

  setObrazacDrugiIzvestaj = (drugiIzvestaj: ObrazacIzvestajiAllReports | null) => {
    this.obrazacDrugiIzvestaj = drugiIzvestaj;
  };

  clearObrazacIzvestaj = (izvestajSubtype: IzvestajiSubtypes) => {
    this.obrazacIzvestaj = [];
    this.obrazacDrugiIzvestaj = izvestajSubtype !== Deo6IzvestajiEnum.REKALKULACIJA ? null : [];
    this.setObrazacIzvestajPagination(paginationInitialState);
  };

  getObrazacIzvestaj = async (izvestajType: IzvestajiSubtypes, pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<ObrazacIzvestajiAllReports | Array<RekalkulacijaIzvestajTableData>>>(
      obrazacIzvestajiService.getObrazacByType(
        {
          type: izvestajType,
          filter_params: obrazacIzvestajiService.parseIzvestajiFilters(
            filtersStore.getFilters[FiltersNameEnum.OBRAZAC_IZVESTAJI]
          ),
        },
        {
          page: pagination?.page || this.obrazacIzvestajPagination.page,
          size: pagination?.size || this.obrazacIzvestajPagination.size,
        }
      )
    );
    if (err) return Promise.reject(err);
    const { items, page, pages, size, total } = res;

    switch (izvestajType) {
      case Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN:
        (items as any).push(obrazacIzvestajiService.createSummaryRow(items as any));
        break;
      case Obrazac3IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA:
        (items as any).push({
          ...obrazacIzvestajiService.createYearlyTotalRow(items as any),
          is_total_record: true,
        });
        break;
    }

    if (izvestajType !== Deo6IzvestajiEnum.REKALKULACIJA) {
      this.setObrazacIzvestaj(items as any);
      this.setObrazacDrugiIzvestaj(null);
    } else {
      this.setObrazacIzvestaj((items as any)[0].tabela1);
      this.setObrazacDrugiIzvestaj((items as any)[0].tabela2);
    }
    // TODO: prepraviti ovaj switch i zameniti ga sa gornjim. Baca error za typescript
    // switch (izvestajType) {
    //   case Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN:
    //     (items as any).push(obrazacIzvestajiService.createSummaryRow(items as Array<Obrazac5NeOpasan>));
    //     break;
    //   case Obrazac3IzvestajiEnum.PRTR_PREGLED_PO_GODINAMA:
    //     (items as Array<Obrazac3PrtrPregledPoGodinama>).push({
    //       ...obrazacIzvestajiService.createYearlyTotalRow(items as Array<Obrazac3PrtrPregledPoGodinama>),
    //       is_total_record: true,
    //     });
    //     break;
    // }

    // if (izvestajType !== Deo6IzvestajiEnum.REKALKULACIJA) {
    //   this.setObrazacIzvestaj(items as ObrazacIzvestajiAllReports);
    //   this.setObrazacDrugiIzvestaj(null);
    // } else {
    //   this.setObrazacIzvestaj((items as Array<RekalkulacijaIzvestajTableData>)[0].tabela1);
    //   this.setObrazacDrugiIzvestaj((items as Array<RekalkulacijaIzvestajTableData>)[0].tabela2);
    // }

    this.setObrazacIzvestajPagination({ page, size, pages, total });
    return Promise.resolve(res);
  };

  exportToExcel = async (izvestajType: IzvestajiSubtypes) => {
    const [err, res] = await to(
      obrazacIzvestajiService.exportToExcel({
        type: izvestajType,
        filter_params: obrazacIzvestajiService.parseIzvestajiFilters(
          filtersStore.getFilters[FiltersNameEnum.OBRAZAC_IZVESTAJI]
        ),
      })
    );
    if (err) return Promise.reject(err);
    const fileName = `${izvestajType}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, fileName);
  };

  exportToPDF = async (izvestajType: IzvestajiSubtypes) => {
    const [err, res] = await to(
      obrazacIzvestajiService.exportToPDF({
        type: izvestajType,
        filter_params: obrazacIzvestajiService.parseIzvestajiFilters(
          filtersStore.getFilters[FiltersNameEnum.OBRAZAC_IZVESTAJI]
        ),
      })
    );
    if (err) return Promise.reject(err);
    const fileName = `${izvestajType}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, fileName);
  };
}

export const obrazacIzvestajiStore = new ObrazacIzvestajiStore();
