import { message } from 'antd';
import styles from './FacilityResponsiblePersonMessage.module.scss';
import { IGetFacility } from 'modules/facility/facility.types';

export const facilityResponsiblePersonMessage = (facilityNames: Pick<IGetFacility, 'naziv_postrojenja'>[]) => {
  const content = (
    <div className={styles.contentWrapper}>
      <h3>Za sledeća postrojenja nije postavljeno odgovorno lice:</h3>
      <ul className={styles.dashed}>
        {facilityNames.map((postrojenje, index) => (
          <li key={index} className={styles.postrojenjeNaziv}>
            {postrojenje.naziv_postrojenja}
          </li>
        ))}
      </ul>
    </div>
  );

  return facilityNames.length ? message.warning(content) : null;
};
