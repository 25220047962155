import { omit } from 'lodash';
import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPagination } from 'typescript';
import {
  ResponseCheckCompany,
  ICompanyTipoviObrasca,
  IGetCompany,
  ResponseLocationsList,
  IPostCompany,
  IPib,
  IPibMaticniCheckResponse,
  ICompaniesListFilters,
} from './company.types';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { IEmptyResponse, IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IGetFacility } from 'modules/facility/facility.types';

const checkPibMaticni = (params: IPib): ApiResponse<IPibMaticniCheckResponse> => {
  const query = queryString.stringify(params);
  return axios.get(`${ROUTES.CONNECTION_APR}?${query}`);
};

const postPib = (payload: any): ApiResponse<any> => {
  return axios.post(`${ROUTES.CONNECTION_APR}`, payload);
};

const companyRegistration = (payload: IPostCompany): ApiResponse<any> => {
  return axios.post(`${ROUTES.COMPANY}`, payload);
};

const fetchCompaniesList = (payload: Partial<IPagination>): IPaginatedApiResponse<IGetCompany> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.COMPANY}?${query}`);
};

const exportCompanyExcel = (filters: ICompaniesListFilters): ApiResponse<Blob> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.COMPANY}export?${query}`, { responseType: 'blob' });
};

const checkCompany = (): ApiResponse<ResponseCheckCompany> => {
  return axios.get(`${ROUTES.USER}${ROUTES.COMPANY}`);
};

const companyApprove = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.COMPANY}${id}/${ROUTES.APPROVE}`);
};
const companyReject = (id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.COMPANY}${id}/${ROUTES.REJECT}`);
};

const fetchCompany = (id: string): ApiResponse<IGetCompany> => {
  return axios.get(`${ROUTES.COMPANY}${id}`);
};

const fetchCompanyTipObrasca = (companyId: string): ApiResponse<ITipObrasca[]> => {
  return axios.get(`${ROUTES.COMPANY}${companyId}/tip-obrasca`);
};

const postCompanyTipObrasca = (companyId: string, tipoviObrazca: ICompanyTipoviObrasca): ApiResponse<any> => {
  return axios.post(`${ROUTES.COMPANY}${companyId}/tip-obrasca`, tipoviObrazca);
};

const putCompany = (company: IGetCompany): ApiResponse<IEmptyResponse> => {
  const patchCompanyObj = omit(company, ['id']);
  return axios.put(`${ROUTES.COMPANY}${company.id}`, patchCompanyObj);
};

const toggleActivateCompany = (id: string, isActive: boolean): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.COMPANY}${id}/${isActive ? 'aktiviraj' : 'deaktiviraj'}`);
};

// todo: sifarnici prepakuj

const fetchLocationList = (payload: Partial<IPagination>): ApiResponse<ResponseLocationsList> => {
  const query = queryString.stringify(payload);
  return axios.get(`/${ROUTES.LOCATION}/?${query}`);
};

const fetchPretezneDelatnostiList = (payload: Partial<IPagination>): ApiResponse<ResponseLocationsList> => {
  const query = queryString.stringify(payload);
  return axios.get(`/${ROUTES.PRETEZNE_DELATNOSTI}/?${query}`);
};

const checkIfFacilitiesHaveResponsiblePersons = (
  id: string
): ApiResponse<Pick<IGetFacility, 'naziv_postrojenja'>[]> => {
  return axios.get(`${ROUTES.COMPANY}${id}/odgovorna-lica-postrojenja`);
};

export const companyRepo = {
  checkPibMaticni,
  postPib,
  checkCompany,
  companyRegistration,
  companyReject,
  companyApprove,
  fetchCompaniesList,
  exportCompanyExcel,
  fetchCompany,
  fetchCompanyTipObrasca,
  postCompanyTipObrasca,
  putCompany,
  toggleActivateCompany,
  fetchLocationList,
  fetchPretezneDelatnostiList,
  checkIfFacilitiesHaveResponsiblePersons,
};
