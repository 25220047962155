import React from 'react';

/**
 * Mapper type
 */
type Mapper = {
  function: (value: any, args?: any) => any;
  args?: any;
};

/**
 * Custom Filter props type
 */
type CustomFilterProps = {
  value: any;
  mappers: Array<Mapper>;
  className?: string;
};

/**
 * Custom Filters as React Function component
 * @param { CustomFilterProps } props
 */
export const CustomFilters: React.FC<CustomFilterProps> = (props: CustomFilterProps) => {
  let { value } = props;
  if (props.mappers.length) {
    props.mappers.forEach((mapper: Mapper) => {
      let inputs: readonly [any, ...any] = [value];
      if (mapper?.args) {
        inputs = [...inputs, ...mapper.args];
      }
      value = mapper.function && mapper.function(...inputs);
    });
  }
  return <span className={props.className}>{value}</span>;
};
