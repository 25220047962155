import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
// import style from './HistoryDiffViewer.module.scss';
import { istorijaPromenaService } from 'modules/istorijaPromena/istorijaPromena.service';

interface IHistoryDiffViewerProps<T> {
  oldValues: T;
  newValues: T;
}

// Function to recursively parse nested JSON strings

export const HistoryDiffViewer: React.FC<IHistoryDiffViewerProps<any>> = props => {
  const oldValuesParsed = istorijaPromenaService.parseNestedJSON(props.oldValues) || 'Nema promena';
  const newValuesParsed = istorijaPromenaService.parseNestedJSON(props.newValues || 'Nema promena');

  const oldObjectString = JSON.stringify(oldValuesParsed, null, 2);
  const newObjectString = JSON.stringify(newValuesParsed, null, 2);

  return (
    // <div className={style.diffWrapper}>
    <ReactDiffViewer
      oldValue={oldObjectString}
      newValue={newObjectString}
      splitView={true}
      disableWordDiff={true}
      showDiffOnly={false}
    />
    // </div>
  );
};
