// pass config object which will contain sidebar items and rendered components
// rendered components should edit certain obrasci store values
// handle submit by type

import { Form, Popconfirm, Row, Tabs, Tooltip } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React, { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';
import style from './ObrazacWizard.module.scss';
import { Button } from 'components/Button/Button';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/es/form/Form';
import { IBaseObrazacStore } from 'typescript/NrizTypes';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { authStore, formStore, modalStore } from 'store';
import { OBRAZAC_TYPE_WIZARD_URL_MAP } from 'modules/obrasci/obrasci.constants';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { CopyOutlined, DownloadOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { ValidationObrazacNotification } from './components/components/ValidationObrazacNotification/ValidationObrazacNotification';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { Ptp2IzjavaOdgovornosti } from './components/components/ptp2IzjavaOdgovornosti/ptp2IzjavaOdgovornosti';
import { Switch } from 'components/Switch/Switch';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { RoutePathEnum } from 'router/router.constants';

interface IObrazacWizardButtonHeader {
  form: FormInstance<any>;
}

const ObrazacWizardButtonHeader: React.FC<IObrazacWizardButtonHeader> = observer(props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [izjava, setIzjava] = useState(false);
  const [title, setTitle] = useState<React.ReactNode>();

  useEffect(() => {
    authStore.isAdmin
      ? setTitle(
          <>
            <span>Da li želite javno dostupne podatke ?</span>
            <span style={{ marginLeft: '10px' }}>
              <Switch
                onChange={e => {
                  obrasciStore.handleChange('javno_dostupno', e);
                }}
                disabled={false}
                name={'javno_dostupno'}
              />
            </span>
          </>
        )
      : setTitle(<div>Da li želite da preuzmete obrazac ?</div>);
  }, []);

  const canSubmitDKO = (): boolean => {
    if (obrasciStore.obrazac_type !== TIPOVI_OBRAZACA.DKO) return false;
    const { admin_odjava_dokumenta, odjava_kretanja, potvrda_dokumenta } = dkoObrazacStore.initialState;
    if (admin_odjava_dokumenta || odjava_kretanja || potvrda_dokumenta) return false;
    return true;
  };

  const copyDKO = () => {
    navigate(`${OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.DKO]}${obrasciStore.tip_obrasca_id}`, {
      replace: true,
    });
    obrasciStore.setZakljucanObrazac(false);
    dkoObrazacStore.copyDKO();
    obrasciStore.setActiveWizardTab('1');
  };

  const removePdf = () => {
    if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.DKO) {
      const { admin_odjava_dokumenta, odjava_kretanja } = dkoObrazacStore.initialState;
      return !(admin_odjava_dokumenta || odjava_kretanja);
    }
    return obrasciStore.real_obrazac_type !== TIPOVI_OBRAZACA.DEO6_GIO6;
  };

  return (
    <div className={style.buttons}>
      <Row justify={'end'}>
        {id && (
          <div style={{ marginRight: '10px' }}>
            {/* todo: prevedi */}
            {removePdf() && (
              <Tooltip title={'Preuzmi'}>
                <Popconfirm
                  title=""
                  description={title}
                  placement="leftTop"
                  okText={t('YES')}
                  okButtonProps={{ disabled: false }}
                  cancelButtonProps={{ disabled: false }}
                  // icon={<span />}
                  cancelText={t('NO')}
                  onConfirm={async () => obrasciStore.exportPdfSingleObrazac(id)}
                >
                  <Button style={{ marginRight: '10px' }} name={'stampaj_dugme'} disabled={false}>
                    <DownloadOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            )}
            {obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DKO && (
              <Tooltip title={'Kopiraj'}>
                <Button name={'kopiraj_dugme'} disabled={false} onClick={copyDKO}>
                  <CopyOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
        {!id && (
          <Popconfirm
            title={
              obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !authStore.isAdmin
                ? t('IZJAVA_ODGOVORNOSTI.TITLE')
                : 'Da li ste sigurni da želite da sačuvate obrazac?'
            }
            description={
              obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 &&
              !authStore.isAdmin && <Ptp2IzjavaOdgovornosti izjava={izjava} setIzjava={setIzjava} />
            }
            okText={t('Da')}
            cancelText={t('Odbaci')}
            onConfirm={() => {
              formStore.setCrudButtonType('submit');
              props.form.submit();
            }}
            placement="bottom"
            okButtonProps={{
              disabled: obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !authStore.isAdmin && !izjava,
            }}
          >
            <Button disabled={modalStore.isLoading} name={'submit'}>
              {t('SUBMIT')}
            </Button>
          </Popconfirm>
        )}
        {id &&
          (!obrasciStore.zakljucanObrazac || authStore.isAdmin) &&
          obrasciStore.obrazac_type !== TIPOVI_OBRAZACA.DKO && (
            <div style={{ marginRight: '10px' }}>
              <Popconfirm
                title={'Da li ste sigurni da želite da izbrišete obrazac?'}
                okText={t('Da')}
                okButtonProps={{ disabled: false }}
                cancelButtonProps={{ disabled: false }}
                cancelText={t('Odbaci')}
                onConfirm={async () => {
                  const response = await obrasciStore.deleteObrazac(id);
                  if (response) navigate(RoutePathEnum.LISTA_OBRAZACA);
                }}
                placement="bottom"
              >
                <Button disabled={modalStore.isLoading} name={'delete'} danger>
                  {t('DELETE')}
                </Button>
              </Popconfirm>
            </div>
          )}
        {id &&
          (!obrasciStore.zakljucanObrazac ||
            canSubmitDKO() ||
            pzv1ObrazacStore.getDozvoljeneKorekcije ||
            (obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && ptp2ObrazacStore.getDozvoljeneKorekcije)) && (
            <Row justify={'end'}>
              <Popconfirm
                title={
                  obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !authStore.isAdmin
                    ? t('IZJAVA_ODGOVORNOSTI.TITLE')
                    : 'Da li ste sigurni da želite da izmenite obrazac?'
                }
                description={
                  obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 &&
                  !authStore.isAdmin && <Ptp2IzjavaOdgovornosti izjava={izjava} setIzjava={setIzjava} />
                }
                okText={t('Da')}
                cancelText={t('Odbaci')}
                onConfirm={() => {
                  formStore.setCrudButtonType('change');
                  props.form.submit();
                }}
                okButtonProps={{
                  disabled: obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !authStore.isAdmin && !izjava,
                }}
                cancelButtonProps={{ disabled: false }}
                placement="bottom"
              >
                <Button disabled={modalStore.isLoading} name="change-button">
                  {t('SUBMIT')}
                </Button>
              </Popconfirm>
            </Row>
          )}
      </Row>
      <Popconfirm
        title={'Da li ste sigurni da želite da izađete sa obrasca?'}
        okText={t('Da')}
        cancelText={t('Odbaci')}
        onConfirm={() => navigate(RoutePathEnum.LISTA_OBRAZACA)}
        placement="bottomRight"
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Button name="wizard-go-back" disabled={false} danger>
          {t('BACK')}
        </Button>
      </Popconfirm>

      {!obrasciStore.zakljucanObrazac && (
        <div>
          <InfoCircleTwoTone
            onClick={() =>
              obrasciStore.handleChange('isValidationNotificationOpen', !obrasciStore.isValidationNotificationOpen)
            }
            style={{ fontSize: '20px' }}
          />
        </div>
      )}
    </div>
  );
});

//////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////
export interface IObrazacHeader {
  form: FormInstance<any> | any;
}

export const WizardHeader: React.FC<IObrazacHeader> = observer(props => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      formStore.handleChange('goBack', false);
    };
  }, []);

  useEffect(() => {
    if (formStore.getGoBack) {
      navigate(RoutePathEnum.LISTA_OBRAZACA);
    }
  }, [formStore.getGoBack]);

  return (
    <Row className={style.wizardHeader} align={'middle'} id="obrazacWizardHeader">
      <h1 color="white" style={{ margin: 0 }}>
        {obrasciStore.real_obrazac_type}
      </h1>
      <ObrazacWizardButtonHeader form={props.form} />
    </Row>
  );
});

export interface IRenderTabConfig {
  wizardConfig: IWizardTabConfig[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export interface IWizardTabConfig {
  label: string;
  key: string;
  component: ({ form }: { form: FormInstance<any> }) => JSX.Element;
  isTabHidden?: () => boolean;
}

//////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////

export const WizardTabs = observer(
  ({
    wizardConfig,
    form,
    store,
  }: {
    wizardConfig: IWizardTabConfig[];
    form: FormInstance<any>;
    store: IBaseObrazacStore;
  }) => {
    const renderTabs = () => {
      return (
        <div className="ant-tabs-nav-list">
          <div className={style.scrollable}>
            {wizardConfig.map((tab: IWizardTabConfig, i) => {
              if (tab.isTabHidden) {
                const isHidden = tab.isTabHidden();
                if (isHidden) return;
              }
              return (
                <div
                  key={i}
                  className={`${style.navTab} ${obrasciStore.activeWizardTab === tab.key ? 'active' : ''}`}
                  onClick={() => {
                    if (obrasciStore.disabled_tab_button) return;
                    obrasciStore.setActiveWizardTab(tab.key);
                  }}
                >
                  {tab.label.toUpperCase()}
                </div>
              );
            })}
          </div>
          <LogOutButton />
        </div>
      );
    };

    useEffect(() => {
      renderTabs();
      return () => obrasciStore.setActiveWizardTab('1');
    }, [store.getIsTabHidden]);

    return (
      <Tabs
        defaultActiveKey="1"
        onChange={(tabKey: string) => obrasciStore.setActiveWizardTab(tabKey)}
        className={style.wizardTabWrapper}
        renderTabBar={() => renderTabs()}
        activeKey={obrasciStore.activeWizardTab}
      >
        {wizardConfig.map(tab => {
          const tabHidden = tab.isTabHidden ? !tab.isTabHidden() : true;
          return (
            <TabPane className={style.wizardTab} key={tab.key} tab={tab?.label} forceRender={true}>
              <Suspense fallback={<div>Loading</div>}>
                <div className={style.wizardContent}>
                  <div className={style.content}>{tabHidden && <tab.component form={form} />}</div>
                </div>
              </Suspense>
            </TabPane>
          );
        })}
      </Tabs>
    );
  }
);

//////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////

export interface IObrazacWizardProps {
  wizardConfig: IWizardTabConfig[];
  store: IBaseObrazacStore;
  modals: JSX.Element;
}

export const ObrazacWizard = observer(<T,>({ wizardConfig, store, modals }: IObrazacWizardProps) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();

  const handleObrazacIdInEffect = async (id: string) => {
    obrasciStore.setDisabledTabButton(false);
    await obrasciStore.loadSingleObrazacData(store.loadData, id);
    // hotfix, should be refactored
    if (obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DKO) return;
    const response =
      obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DEO6_GIO6
        ? await obrasciStore.checkIfObrazacDeo6Locked()
        : await obrasciStore.checkIfObrazacLocked(id);
    if (response) return obrasciStore.setZakljucanObrazac(response);
  };

  useEffect(() => {
    obrasciService.setObrazacTypeWithUrl(location.pathname);

    if (id) {
      handleObrazacIdInEffect(id);
    }

    if (!authStore.isAdmin) {
      obrasciStore.setOpsteObrazacWizardUser(store);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (authStore.isAdmin) {
        obrasciService.resetObrazacWizardAdmin(obrasciStore.real_obrazac_type);
      }
      obrasciStore.setZakljucanObrazac(false);
      obrasciStore.setDisabledTabButton(true);
      obrasciStore.handleChange('javno_dostupno', false);
      store.resetStates();
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue(store.initialState);
  }, [store.initialState]);

  return (
    <div className={style.obrazacWizardPage}>
      {modals}
      <ValidationObrazacNotification form={form} />
      <Form.Provider>
        <Form
          form={form}
          initialValues={store.initialState}
          name="wizard"
          onFinishFailed={() => {
            obrasciStore.handleChange('isValidationNotificationOpen', true);
          }}
          onFinish={(values: T) => {
            const isValid = obrasciService.handleValidation(values);
            if (!isValid) return;

            store.submitData({ izjava_o_odgovornosti: true, ...values });
          }}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          labelAlign="left"
          layout="horizontal"
          disabled={obrasciStore.zakljucanObrazac}
        >
          <WizardHeader form={form} />
          <WizardTabs wizardConfig={wizardConfig} form={form} store={store} />
        </Form>
      </Form.Provider>
    </div>
  );
});
