import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import {
  UNSANITARY_LANDFILL_FILTERS_INITIAL_STATE,
  UNSANITARY_LANDFILL_FILTERS_OPTIONS,
} from 'modules/unsanitarylandfill/unsanitarylandfill.constants';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import React, { useEffect } from 'react';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const FilterUnsanitaryLandfillList: React.FC = observer(() => {
  const [unsanitaryForm] = useForm();

  const fetchUnsanitaryLandfillList = () => {
    return unsanitaryLandfillStore.fetchUnsanitaryLandfillList(paginationInitialState);
  };

  useEffect(() => {
    return () => {
      filtersStore.setFilters(FiltersNameEnum.NESANITARNE_DEPONIJE, UNSANITARY_LANDFILL_FILTERS_INITIAL_STATE);
    };
  }, []);

  const data: IFilterTableProps = {
    form: unsanitaryForm,
    initialState: filtersStore.getFilters[FiltersNameEnum.NESANITARNE_DEPONIJE],
    filterStoreName: FiltersNameEnum.NESANITARNE_DEPONIJE,
    filterOptions: UNSANITARY_LANDFILL_FILTERS_OPTIONS,
    fetchTableList: fetchUnsanitaryLandfillList,
  };

  return <FilterTableForm data={data} />;
});
