import { DefaultOptionType } from 'antd/es/select';
import { omit, trim } from 'lodash';
import { companyStore } from 'modules/company/company.store';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { IPagination, ISifarnikFindProps } from 'typescript/NrizTypes';
import { arrayToObject } from 'utils/arrayToObject';
import { paginationInitialState } from './nriz.constants';
import { userStore } from 'modules/user/user.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { registriService } from 'modules/registri/registri.service';

export const randomString = (id: string) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let rndString = '';
  for (let i = 0; i < 5; i++) rndString += characters[Math.floor(Math.random() * characters.length)];
  return `${rndString}${id}`;
};

class NrizService {
  pickFields = (obj: any) => {
    const removedKeys = [];
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = trim(obj[key]);
      }
      if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === 'Invalid Date') {
        removedKeys.push(key);
      }
    }
    obj = omit(obj, removedKeys);
    return obj;
  };

  setEmptyValuesToNull = <T>(obj: T): T => {
    let newObj = {} as T;
    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === '' || obj[key] === 'Invalid Date') {
        newObj = { ...newObj, [key]: null };
      } else {
        newObj = { ...newObj, [key]: obj[key] };
      }
    }
    return newObj;
  };

  removeBool = (obj: any) => {
    const removedKeys = [];
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = trim(obj[key]);
      }
      if (obj[key] === false) {
        removedKeys.push(key);
      }
    }
    obj = omit(obj, removedKeys);
    return obj;
  };

  addKey = (array: any) => {
    const newArray = array?.map((item: any) => {
      return {
        ...item,
        key: randomString(item.id),
      };
    });
    return newArray;
  };

  /** Map keys to children arrays for ANTD collapsible table row functionality */
  addKeyToChildrenData = (array: any) => {
    const newArray = array?.map((item: any) => {
      if (item.children) {
        item.children = this.addKeyToChildrenData(item.children);
      }
      return {
        ...item,
        key: randomString(item.id),
      };
    });
    return newArray;
  };

  addIdAndKey = (object: any) => {
    const randomNumber = Math.random().toString();
    return {
      ...object,
      id: randomNumber,
      key: randomNumber,
    };
  };

  changeColValue = (value: boolean) => {
    if (value) {
      return 12;
    }
    return 24;
  };

  addToList = (list: any[], item: any) => {
    const updatedList = [this.setEmptyValuesToNull(item), ...list];
    return updatedList;
  };

  removeFromList = (list: any[], id: string) => {
    const filterList = list.filter(item => item.id !== id);
    return filterList;
  };
  removeFromListSifarnik = (list: any[], id: string) => {
    const newList = list.filter(item => item.id !== id);

    return newList ? newList : [];
  };

  isItemInList = (list: any[], id: string) => {
    return list.some(item => item.id === id);
  };

  findItemInList = (list: any[], id: string) => {
    if (Array.isArray(list)) {
      return list.find(item => item.id === id);
    }
  };

  increaseTotalPagination = (pagination: IPagination) => {
    return { ...pagination, total: pagination.total + 1 };
  };

  decreaseTotalPagination = (pagination: IPagination) => {
    if (pagination.total === 0) return;
    return { ...pagination, total: pagination.total - 1 };
  };

  changeItemInList = (list: any[], newObject: any, accessor = 'id') => {
    const findIndex = list.findIndex(item => item[accessor] === newObject[accessor]);
    list[findIndex] = this.setEmptyValuesToNull(newObject);
    return list;
  };

  addItemToList = (list: any[], newObject: any) => {
    const newList = [{ ...this.setEmptyValuesToNull(this.addIdAndKey(newObject)) }, ...list];
    return newList;
  };

  addSifarnikInObject = ({ list, name, id, exId, exObj }: ISifarnikFindProps) => {
    if (exId === id) {
      return { [name]: exObj };
    }
    const objArray: any = arrayToObject(list);
    const object = objArray[id];

    return { [name]: object };
  };

  handleSearchOptions = (input: string, option: DefaultOptionType | undefined) => {
    return ((option?.label as string).toLowerCase() ?? '').includes(input.toLowerCase());
  };

  setBoolToNull = (initialState: ISifarniciTypeOfForm) => {
    return Object.fromEntries(
      Object.entries(initialState).map(([key, value]) => [key, typeof value === 'boolean' ? null : value])
    );
  };

  handleSidebarMenuClickPaginationReset = () => {
    companyStore.handleChange('companyPagination', paginationInitialState);
    userStore.handleChange('userListPagination', paginationInitialState);
    obrasciStore.handleChange('obrasciPagination', paginationInitialState);
    registriService.handleResetPagination();
  };

  removeObjFromPayload = (payload: Record<string, any>): Record<string, any> => {
    return Object.entries(payload).reduce((acc, [key, value]) => {
      if (!(typeof value === 'object' && value !== null)) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);
  };
}

export const nrizService = new NrizService();
