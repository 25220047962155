import { observer } from 'mobx-react-lite';
import styles from './LCPRegistarForm.module.scss';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { useTranslation } from 'react-i18next';
import { lcpStore } from 'modules/registri/lcp/lcp.store';
import {
  LATITUDE_RULE,
  LONGITUDE_RULE,
  MAX_LENGTH_RULE,
  REQUIRED_AT_LEAST_ONE_LIST_ITEM,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { FormTable } from 'components/FormTable/FormTable';
import { FormListType } from 'typescript/NrizTypes';
import { RegistriLists } from 'modules/registri/registri.types';
import {
  ILCPInstalacioniDeo,
  LCPRelevantnoPoljeDirektive,
  LCPStatus,
  LCPVrstaInstalacije,
} from 'modules/registri/lcp/lcp.types';
import { ColumnType } from 'antd/es/table';
import { ISifarnikInitial, SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { FormInput } from 'components/FormInput/FormInput';
import { Form, message } from 'antd';
import { Select } from 'components/Select/Select';
import { lcpService } from 'modules/registri/lcp/lcp.service';
import { IGetFacility } from 'modules/facility/facility.types';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { LCP_INSTALACIJA_INITIAL } from 'modules/registri/lcp/lcp.constants';
import dayjs from 'dayjs';
import { registriStore } from 'modules/registri/registri.store';

export const LCPRegistarForm = observer(() => {
  const { t } = useTranslation();

  const form = useFormInstance();

  const defaultPreduzeceOption: ISifarnikInitial | undefined = lcpStore.lcpRegistar?.preduzece_id
    ? {
        label: lcpStore.lcpRegistar?.preduzece.naziv,
        value: lcpStore.lcpRegistar?.preduzece.id,
      }
    : undefined;

  const defaultPostrojenjeOption: ISifarnikInitial | undefined = lcpStore.lcpRegistar?.postrojenje_id
    ? {
        label: lcpStore.lcpRegistar?.postrojenje.naziv_postrojenja,
        value: lcpStore.lcpRegistar?.postrojenje.id,
      }
    : undefined;

  const vrstaInstalacijeOptions = Object.values(LCPVrstaInstalacije).map(key => {
    return {
      label: key,
      value: key,
    };
  });

  const relevantnoPoljeDirektiveOptions = Object.values(LCPRelevantnoPoljeDirektive).map(key => {
    return {
      label: key,
      value: key,
    };
  });

  const statusOptions = Object.values(LCPStatus).map(key => {
    return {
      label: key,
      value: key,
    };
  });

  const handlePostrojenjeChange = async (postrojenje: IGetFacility) => {
    const resetOpsteStates = () => {
      lcpStore.handleOpsteChange('preduzece_id', '');
      lcpStore.handleOpsteChange('postrojenje_id', '');
      form.setFieldValue('preduzece_id', '');
      form.setFieldValue('postrojenje_id', '');
    };

    const { istorijski_id, id } = postrojenje;
    const registarExists = await registriStore.checkIfRegistarExists(id);
    if (registarExists) {
      resetOpsteStates();
      return message.error('LCP registar za ovo postrojenje već postoji!');
    }
    const idProizvodnogPogona = lcpService.getIdProizvodnogPogona(istorijski_id);
    if (!idProizvodnogPogona) {
      resetOpsteStates();
      return message.error('Postrojenje nema istorijski ID!');
    }
    lcpStore.handleOpsteChange('postrojenje_id', id);
    form.setFieldValue('id_proizvodnog_pogona', idProizvodnogPogona);
  };

  const setIdProizvodnogPogona = (istorijskiId: string | undefined) => {
    const idProizvodnogPogona = lcpService.getIdProizvodnogPogona(istorijskiId);
    form.setFieldValue('id_proizvodnog_pogona', idProizvodnogPogona);
  };

  const handleInstalacioniDeoRemove = (index: number | number[]) => {
    if (typeof index !== 'number') return;
    const initialList = form.getFieldValue('instalacioni_deo') as ILCPInstalacioniDeo[];
    const newList = initialList.filter((_, i) => i !== index);
    if (lcpStore.lcpRegistar?.id) return form.setFieldValue('instalacioni_deo', newList);
    if (newList[index]) {
      if (newList.length === 1)
        newList[0] = { ...newList[0], id_instalacionog_dela: initialList[0].id_instalacionog_dela };
      else {
        for (let i = 1; i < newList.length; i++) {
          newList[i] = {
            ...newList[i],
            id_instalacionog_dela: lcpService.incrementIdInstalacionogDela(newList[i - 1].id_instalacionog_dela),
          };
        }
      }
    }
    newList.length
      ? lcpStore.setNextInstalacioniDeoId(
          lcpService.incrementIdInstalacionogDela(newList[newList.length - 1].id_instalacionog_dela)
        )
      : lcpStore.setNextInstalacioniDeoId(initialList[index].id_instalacionog_dela);

    form.setFieldValue('instalacioni_deo', newList);
  };

  const lcpInstalacioniDeoColumns: ColumnType<ILCPInstalacioniDeo>[] = [
    {
      title: 'ID instalacionog dela',
      dataIndex: 'id_instalacionog_dela',
      key: 'id_instalacionog_dela',
      width: 200,
    },
    {
      title: 'Naziv instalacionog dela',
      dataIndex: 'naziv_instalacionog_dela',
      key: 'naziv_instalacionog_dela',
      width: 500,
    },
    {
      title: 'Vrsta instalacionog dela',
      dataIndex: 'vrsta_instalacionog_dela',
      key: 'vrsta_instalacionog_dela',
      width: 200,
    },
    {
      title: 'Ukupan toplotni ulaz (MW)',
      dataIndex: 'ukupan_toplotni_ulaz_mw',
      key: 'ukupan_toplotni_ulaz_mw',
      width: 200,
    },
    {
      title: 'Geografska širina',
      dataIndex: 'geografska_sirina',
      key: 'geografska_sirina',
      width: 200,
    },
    {
      title: 'Geografska dužina',
      dataIndex: 'geografska_duzina',
      key: 'geografska_duzina',
      width: 200,
    },
    {
      title: 'Datum početka rada',
      render(_, record) {
        return <>{dayjs(record.datum_pocetka_rada).format('DD-MM-YYYY') ?? ''}</>;
      },
      key: 'datum_pocetka_rada',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
    },
  ];

  return (
    <div className={styles.lcpFormWrapper}>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('COMPANY.TITLE')}
        onChange={value => {
          lcpStore.handleOpsteChange('preduzece_id', value);
          if (lcpStore.lcpOpste.postrojenje_id) {
            lcpStore.handleOpsteChange('postrojenje_id', '');
            form.setFieldValue('postrojenje_id', '');
            setIdProizvodnogPogona(undefined);
          }
        }}
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
        formName={'preduzece_id'}
        filtersForFetch={{ is_active: true }}
        fetchOptinsList={lcpStore.fetchPreduzecaLcp}
        labelOptionAccessor="naziv"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultPreduzeceOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={!!lcpStore.lcpOpste.postrojenje_id && !!lcpStore.lcpOpste.preduzece_id}
      />
      <FormScrollWithObjInside
        showSearch={true}
        label={t('FACILITY.TITLE')}
        placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
        formName={'postrojenje_id'}
        filtersForFetch={{ preduzece_id: lcpStore.lcpOpste.preduzece_id, is_active: true }}
        fetchOptinsList={SIFARNICI_ROUTES.VELIKI_ZAGADJIVACI}
        labelOptionAccessor={['naziv_postrojenja']}
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultPostrojenjeOption}
        onChange={(_, option: { item: IGetFacility }) => handlePostrojenjeChange(option.item)}
        rules={[REQUIRED_FIELD_RULE(true)]}
        resetOption={lcpStore.lcpOpste.preduzece_id}
        disabled={!lcpStore.lcpOpste.preduzece_id || !!lcpStore.lcpOpste.postrojenje_id}
      />
      <FormInput
        name="id_proizvodnog_pogona"
        inputName="id_proizvodnog_pogona"
        disabled
        label="ID proizvodnog pogona"
      />
      <FormInput name="id_instalacije" inputName="id_instalacije" disabled label="ID instalacije" />
      <FormInput
        name="naziv_instalacije"
        inputName="naziv_instalacije"
        label="Naziv instalacije"
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(1000)]}
      />
      <FormInput
        type="number"
        name="geografska_sirina"
        inputName="geografska_sirina"
        rules={[REQUIRED_FIELD_RULE(true), ...LATITUDE_RULE()]}
        label={t('COMPANY.LATITUDE')}
        colon={true}
      />
      <FormInput
        type="number"
        name="geografska_duzina"
        inputName="geografska_duzina"
        rules={[REQUIRED_FIELD_RULE(true), ...LONGITUDE_RULE()]}
        label={t('COMPANY.LONGITUDE')}
        colon={true}
      />
      <Form.Item label="Vrsta instalacije" rules={[REQUIRED_FIELD_RULE(true)]} name={'vrsta_instalacije'}>
        <Select name="vrsta_instalacije" options={vrstaInstalacijeOptions} />
      </Form.Item>
      <Form.Item
        label="Relevantno polje direktive"
        rules={[REQUIRED_FIELD_RULE(true)]}
        name={'relevantno_poglavlje_direktive'}
      >
        <Select name="relevantno_poglavlje_direktive" options={relevantnoPoljeDirektiveOptions} />
      </Form.Item>
      <Form.Item label="Status" rules={[REQUIRED_FIELD_RULE(true)]} name={'status'}>
        <Select name="status" options={statusOptions} />
      </Form.Item>
      <FormTable<ILCPInstalacioniDeo>
        listName="instalacioni_deo"
        tableColumns={lcpInstalacioniDeoColumns}
        configPath={{ formListType: FormListType.Registri, formListSubType: RegistriLists.LCP }}
        title="Instalacioni deo"
        listValidationRules={[REQUIRED_AT_LEAST_ONE_LIST_ITEM(true)]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        formModalInitialValues={LCP_INSTALACIJA_INITIAL}
        onRemove={handleInstalacioniDeoRemove}
        onAdd={values =>
          lcpStore.setNextInstalacioniDeoId(lcpService.incrementIdInstalacionogDela(values.id_instalacionog_dela))
        }
      />
    </div>
  );
});
