import { observer } from 'mobx-react-lite';
import User from 'pages/User/User';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { authStore } from 'store';

export const ProfileUser: React.FC = observer(() => {
  const [profile, setProfile] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id === authStore.userId) {
      setProfile(true);
    } else {
      navigate(`${RoutePathEnum.PROFIL}/${authStore.userId}`);
      setProfile(true);
    }
    return () => setProfile(false);
  }, []);

  return <>{profile && <User />}</>;
});
