import { RegistriConfig, RegistriEnum, RegistriOptionType } from './registri.types';
// import i18n from 'translations/i18n.config';
import { otvStore } from './otv/otv.store';
import { OTV_ROUTE_DATA, OTV_TABLE_DATA } from './otv/otv.constants';
import { lcpStore } from './lcp/lcp.store';
import { LCP_ROUTE_DATA, LCP_TABLE_DATA } from './lcp/lcp.constants';

export const registriOptions: RegistriOptionType[] = [
  {
    label: 'OTV',
    value: RegistriEnum.Otv,
  },
  // {
  //   label: i18n.t('REGISTRI.DOZVOLE.TITLE'),
  //   value: RegistriEnum.Dozvole,
  // },
  {
    label: 'LCP',
    value: RegistriEnum.Lcp,
  },
];

export const REGISTRI_CONFIG: {
  [key in RegistriEnum]: RegistriConfig<key>;
} = {
  [RegistriEnum.Otv]: {
    key: RegistriEnum.Otv,
    label: 'OTV Registar',
    routes: OTV_ROUTE_DATA,
    store: otvStore,
    tableData: OTV_TABLE_DATA,
  },
  [RegistriEnum.Lcp]: {
    key: RegistriEnum.Lcp,
    label: 'LCP Registar',
    routes: LCP_ROUTE_DATA,
    store: lcpStore,
    tableData: LCP_TABLE_DATA,
  },
  // [RegistriEnum.Dozvole]: {
  //   key: RegistriEnum.Dozvole,
  //   label: i18n.t('REGISTRI.DOZVOLE.TITLE'),
  //   routes: { ...OTV_ROUTE_DATA, urlRoute: `/${ROUTES.REGISTRI}/${RegistriEnum.Dozvole}` },
  //   store: otvStore,
  //   tableData: OTV_TABLE_DATA,
  // },
};
