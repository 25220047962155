import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  MORE_OR_EQUILE_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
  MAX_LENGTH_RULE,
  MORE_THEN_0,
} from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { IGetSirovine } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RuleConfig } from 'typescript';

export const NewSirovineForm: React.FC = observer(() => {
  const { t } = useTranslation();
  const form = useFormInstance();

  const defaultJedinicaMereOption: ISifarnikInitial | undefined = obrazac1Store.getSirovineForm.jedinica_mere_id
    ? {
        label: (obrazac1Store.getSirovineForm as IGetSirovine).jedinica_mere.name_sr,
        value: obrazac1Store.getSirovineForm.jedinica_mere_id,
      }
    : undefined;

  const readOnly = obrasciStore.zakljucanObrazac;

  const uniqueSirovinaRule = (field: 'hemijski' | 'komercijalni'): RuleConfig => ({
    validator: (_: any, value: string) => {
      const hemijskiNaziv = field === 'hemijski' ? value : form.getFieldValue('hemijski_naziv');
      const komercijalniNaziv = field === 'hemijski' ? form.getFieldValue('komercijalni_naziv') : value;
      const matchingRowExists = obrazac1Store.initialState.sirovine.find(
        sirovina => sirovina.hemijski_naziv === hemijskiNaziv && sirovina.komercijalni_naziv === komercijalniNaziv
      );
      const isRowBeingEdited =
        obrazac1Store.sirovine_form.hemijski_naziv === hemijskiNaziv &&
        obrazac1Store.sirovine_form.komercijalni_naziv === komercijalniNaziv;
      if (matchingRowExists && !isRowBeingEdited)
        return Promise.reject(new Error('Kombinacija hemijskog i komercijalnog naziva sirovine mora biti unikatna'));
      return Promise.resolve();
    },
  });

  return (
    <>
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.CHEMICAL_NAME_IUPAC')}`}
        labelCol={{ span: 10 }}
        name={'hemijski_naziv'}
        inputName={'hemijski_naziv'}
        placeholder=""
        readOnly={readOnly}
        rules={[MAX_LENGTH_RULE(300), uniqueSirovinaRule('hemijski')]}
        onInputChange={() => form.validateFields(['komercijalni_naziv'])}
      />
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.COMMERCIAL_NAME')}`}
        labelCol={{ span: 10 }}
        name={'komercijalni_naziv'}
        inputName={'komercijalni_naziv'}
        placeholder=""
        onInputChange={() => form.validateFields(['hemijski_naziv'])}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(300), uniqueSirovinaRule('komercijalni')]}
        readOnly={readOnly}
      />
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.AGGREGATE_CONDITION_WHEN_IN_STORAGE')}`}
        labelCol={{ span: 10 }}
        name={'agregatno_stanje_kada_je_na_skladistu'}
        inputName={'agregatno_stanje_kada_je_na_skladistu'}
        placeholder=""
        readOnly={readOnly}
        rules={[MAX_LENGTH_RULE(100)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE')}`}
        labelCol={{ span: 10 }}
        formName={'jedinica_mere_id'}
        inputName={'jedinica_mere_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        fetchOptinsList={obrazac1Store.fetchUnitCode}
        placeholder={''}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultJedinicaMereOption}
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.CONSUMPTION_PER_DAY')}`}
        labelCol={{ span: 10 }}
        name={'potrosnja_na_dan'}
        inputName={'potrosnja_na_dan'}
        placeholder=""
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
        readOnly={readOnly}
      />
      <FormInput
        label={`${t('OBRASCI.OBRAZAC_1.METHOD_OF_STORAGE')}`}
        labelCol={{ span: 10 }}
        name={'nacin_lagerovanja'}
        inputName={'nacin_lagerovanja'}
        placeholder=""
        readOnly={readOnly}
        rules={[MAX_LENGTH_RULE(320)]}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.MAXIMUM_STORAGE_CAPACITY')}`}
        labelCol={{ span: 10 }}
        name={'maksimalni_kapacitet_lagera'}
        inputName={'maksimalni_kapacitet_lagera'}
        rules={MORE_THEN_0}
        placeholder=""
        readOnly={readOnly}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_1.AVERAGE_AMOUNT_OF_STORAGE')}`}
        labelCol={{ span: 10 }}
        name={'prosecna_kolicina_na_lageru'}
        inputName={'prosecna_kolicina_na_lageru'}
        rules={MORE_THEN_0}
        placeholder=""
        readOnly={readOnly}
      />
    </>
  );
});
