import dayjs from 'dayjs';
import { IPostUnsanitaryLandfill, IUnsanitaryLandfillFilters } from './unsanitarylandfill.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const unsanitaryLandfillInitialState: IPostUnsanitaryLandfill = {
  oznaka_nesanitarne_deponije: '',
  naziv: '',
  mesto_id: '',
  koordinate_long: '',
  koordinate_lat: '',
  zauzete_katastarske_parcele: '',
  period_deponovanja: [dayjs(), dayjs()],
  napomena: '',
  is_active: false,
};

export const UNSANITARY_LANDFILL_FILTERS_INITIAL_STATE: IUnsanitaryLandfillFilters = {
  naziv: '',
  oznaka_nesanitarne_deponije: '',
  order_by: '',
};

export const UNSANITARY_LANDFILL_FILTERS_OPTIONS: ISifarniciTypeOfForm[] = [
  {
    type_of_form: 'input',
    inputName: 'oznaka_nesanitarne_deponije__ilike',
    name: 'oznaka_nesanitarne_deponije__ilike',
    label: 'Oznaka',
    sortName: 'oznaka_nesanitarne_deponije',
  },
  {
    type_of_form: 'input',
    inputName: 'naziv__ilike',
    name: 'naziv__ilike',
    label: 'Naziv',
    sortName: 'naziv',
  },
];
