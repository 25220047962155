import {
  ISifarniciComponentHandler,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import i18n from 'translations/i18n.config';
import { FormInstance } from 'antd';

import { IOTVRegistar, IOTVRegistarZagadjenje, OtvOpsteInfo, OTVRequest, IOTVFilters } from './otv.types';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { OTVRegistar } from 'pages/Registri/components/OTVRegistar/OTVRegistar';
import { RegistriEnum, RegistriRoutes, RegistriTableData } from '../registri.types';

const formFieldNamesConnectedByPreduzece = ['postrojenje_id', 'izvor_zagadjenja_vode_id'];

const PREDUZECE_FILTER = (form: FormInstance<IOTVRegistar>): SearchScrollSifarniciFormProps => {
  return {
    placeholder: '',
    type_of_form: 'searchScroll',
    label: i18n.t('COMPANY.TITLE'),
    formName: 'preduzece_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    readOnly: false,
    sortName: 'naziv_preduzeca',
    onChange: () => formFieldNamesConnectedByPreduzece.forEach(field => form.setFieldValue(field, '')),
  };
};

const POSTROJENJE_FILTER = (form: FormInstance<IOTVRegistar>): SearchScrollSifarniciFormProps => {
  return {
    placeholder: '',
    type_of_form: 'searchScroll',
    filtersForFetch: {
      preduzece_id: form.getFieldValue('preduzece_id'),
    },
    formName: 'postrojenje_id',
    fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
    labelOptionAccessor: ['naziv_postrojenja'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    label: i18n.t('FACILITY.NAME'),
    sortName: 'naziv_postrojenja',
    onChange: () => form.setFieldValue('izvor_zagadjenja_vode_id', ''),
    hideInput: !form.getFieldValue('preduzece_id'),
  };
};

const IZVOR_VODA_FILTER_DATA = (form: FormInstance<IOTVRegistar>): SearchScrollSifarniciFormProps => {
  return {
    placeholder: '',
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id: form.getFieldValue('postrojenje_id'),
    },
    formName: 'izvor_zagadjenja_vode_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
    labelOptionAccessor: ['naziv_ispusta'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'aktivan',
    label: i18n.t('POLLUTION.WATER.TITLE'),
    sortName: 'izvor_zagadjenja_vode',
    hideInput: !form.getFieldValue('postrojenje_id'),
  };
};

export const otvFilterComponents: ISifarniciComponentHandler = (form: FormInstance<IOTVRegistar>) => [
  PREDUZECE_FILTER(form),
  POSTROJENJE_FILTER(form),
  IZVOR_VODA_FILTER_DATA(form),
];

export const OTV_FILTERS_INITIAL_STATE: IOTVFilters = {
  preduzece_id: '',
  postrojenje_id: '',
  izvor_zagadjenja_vode_id: '',
};

export const OTV_INITIAL_STATE: Partial<OTVRequest> = {
  postrojenje_id: undefined,
  preduzece_id: undefined,
  izvor_zagadjenja_vode_id: undefined,
  posebna_cena: false,
  zona_sanitarne_zastite_izvorista: false,
  vodna_tela_namenjena_rekreaciji: false,
  pov_postrojenje_u_izgradnji_ili_rekonstrukciji: false,
  otv_registar_zagadjenje: [],
};

export const OTV_OPSTE_INITIAL: OtvOpsteInfo = {
  preduzece_id: '',
  postrojenje_id: '',
  izvor_zagadjenja_vode_id: '',
};

export const OTV_REGISTAR_ZAGADJENJE_INITIAL_STATE: Partial<IOTVRegistarZagadjenje> = {
  naziv_zagadjujuce_materije_id: '',
  jedinica_mere: '',
  granicna_vrednost: null,
};

export const OTV_TABLE_DATA: RegistriTableData<RegistriEnum.Otv> = {
  columns: [
    { key: 'preduzece', dataIndex: ['preduzece', 'naziv'], title: 'Preduzeće' },
    { key: 'postrojenje', dataIndex: ['postrojenje', 'naziv_postrojenja'], title: 'Postrojenje' },
    {
      key: 'izvor_zagadjenja_vode',
      dataIndex: ['izvor_zagadjenja_vode', 'naziv_ispusta'],
      title: 'Izvor zagađenja',
    },
  ],
  filters: {
    components: (form: FormInstance<OTVRequest>) => {
      return otvFilterComponents(form);
    },
    initialState: OTV_FILTERS_INITIAL_STATE,
  },
};

export const OTV_ROUTE_DATA: RegistriRoutes = {
  urlRoute: `/${ROUTES.REGISTRI}/${RegistriEnum.Otv}`,
  component: <OTVRegistar />,
  apiRoute: 'otv_registar/',
};
