import { Button, Form, Row } from 'antd';
import { FormTableModal } from './FormTableModal/FormTableModal';
import Table, { ColumnType } from 'antd/es/table';
import { useMemo, useRef, useState } from 'react';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { modalStore } from 'store';
import { EditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import styles from './FormTable.module.scss';
import { FormListConfig } from 'typescript/NrizTypes';
import { nrizService } from 'modules/nriz/nriz.service';
import { localeEmpty } from 'modules/nriz/nriz.constants';
import type { ValidatorRule } from 'rc-field-form/lib/interface';
import { ColProps } from 'antd/lib';

export interface FormTableProps<T> {
  listName: string;
  tableColumns: ColumnType<T>[];
  title?: string | React.ReactNode;
  onEdit?: (record: T) => void;
  onAdd?: (values: T) => void;
  onRemove?: (index: number | number[]) => void;
  configPath: FormListConfig;
  modalWidth?: string | number;
  scrollY?: string;
  formModalInitialValues?: any;
  listValidationRules?: ValidatorRule[];
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  tableMarginBottom?: boolean;
}

export const FormTable = observer(
  // eslint-disable-next-line
  <T extends object>({
    listName,
    tableColumns,
    title,
    onEdit,
    configPath,
    modalWidth = '50%',
    scrollY = '300px',
    formModalInitialValues,
    listValidationRules,
    labelCol,
    wrapperCol,
    tableMarginBottom,
    onAdd,
    onRemove,
  }: FormTableProps<T>) => {
    const form = Form.useFormInstance();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const tableRef = useRef<HTMLDivElement | null>(null);

    const tableDataSource: T[] = useMemo(() => {
      const sourceList = nrizService.addKey(form.getFieldValue(listName)) ?? [];

      setSelectedIndex(sourceList.length);

      return sourceList;
    }, [Form.useWatch(listName), form]);

    const handleAddNewRecord = () => {
      setSelectedIndex(tableDataSource.length);
      modalStore.changeModalName(`${listName}-modal`);
    };

    const handleEditRecord = (_record: T, formListItemIndex: number) => {
      setSelectedIndex(formListItemIndex);
      modalStore.changeModalName(`${listName}-modal`);
    };

    const columns: ColumnType<T>[] = [
      ...tableColumns,
      {
        fixed: 'right',
        align: 'center',
        width: 70,

        render: (_: any, record: T, formListItemIndex: number) => {
          return (
            <Button
              icon={<EditOutlined />}
              name={`edit-${listName}-item`}
              onClick={() => handleEditRecord(record, formListItemIndex)}
            />
          );
        },
      },
    ];

    const TableHeader = () => (
      <Row justify={'space-between'} id="formTableHeader">
        {title && <h3>{title}</h3>}

        <AddNewButton name={`${listName}-table-add-btn`} label="Dodaj" onButtonClick={handleAddNewRecord} />
      </Row>
    );

    return (
      <div className={`${styles.formListWrapper} ${tableMarginBottom ? styles.tableMarginBottom : ''}`}>
        <Form.List name={listName} rules={listValidationRules}>
          {(_items, { add, remove }, { errors }) => {
            const addRow = (values: T) => {
              add(values);
              onAdd?.(values);
              tableRef.current?.scrollIntoView({
                behavior: 'smooth',
              });
            };

            const removeRow = (index: number | number[]) => {
              onRemove ? onRemove(index) : remove(index);
            };

            return tableDataSource ? (
              <>
                <FormTableModal<T>
                  name={listName}
                  removeRow={removeRow}
                  addRow={addRow}
                  editRow={onEdit}
                  configPath={configPath}
                  selectedIndex={selectedIndex}
                  modalWidth={modalWidth}
                  title={title}
                  initialValues={formModalInitialValues}
                  labelCol={labelCol}
                  wrapperCol={wrapperCol}
                />
                <TableHeader />
                <Table
                  ref={tableRef}
                  pagination={false}
                  bordered
                  dataSource={tableDataSource}
                  columns={columns}
                  className={`${styles.formTable} ${errors.length && styles.formListTableError}`}
                  scroll={{ y: scrollY }}
                  locale={localeEmpty}
                  // todo: resi jebeni scrollConfig
                />
                {!!errors.length && <p className={styles.formListError}>{errors[0]}</p>}
              </>
            ) : null;
          }}
        </Form.List>
      </div>
    );
  }
);
