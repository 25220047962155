import { ContentType } from '../types/content-type.type';

/**
 * Content Type constant
 * @type { Array<ContentType> }
 */
export const CONTENT_TYPE: Array<ContentType> = [
  {
    type: 'application/x-7z-compressed',
    extension: '.7z',
  },
];
