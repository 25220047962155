import { Form, FormInstance } from 'antd';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { observer } from 'mobx-react-lite';
import { checkIfItemExistsInList, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { lcpStore } from 'modules/registri/lcp/lcp.store';
import { ISifarnikInitial, SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface FormListItemProps<T> {
  parentForm: FormInstance<T>;
  formListName: string;
  selectedIndex: number;
}

export const LCPRegistarIzvorZagadjenjaForm = observer(
  // eslint-disable-next-line
  <T extends object>({ parentForm, formListName, selectedIndex }: FormListItemProps<T>) => {
    const { t } = useTranslation();

    const accessor = 'izvor_zagadjenja_vazduha_id';
    const formList = parentForm.getFieldValue(formListName);
    const existingIzvor = formList[selectedIndex] ? formList[selectedIndex][accessor] : undefined;

    const izvorExistsRule = useMemo(() => {
      return checkIfItemExistsInList({ previousValue: existingIzvor, listItemAccessor: accessor, list: formList });
    }, [Form.useWatch('izvor_zagadjenja_vazduha_id'), Form.useWatch(formListName, parentForm)]);

    const defaultIzvorOption: ISifarnikInitial | undefined = existingIzvor
      ? {
          label: existingIzvor.naziv_izvora,
          value: existingIzvor.id,
        }
      : undefined;

    return (
      <FormScrollWithObjInside
        showSearch={true}
        label={t('POLLUTION.AIR.TITLE')}
        formName={'izvor_zagadjenja_vazduha_id'}
        filtersForFetch={{ postrojenje_id: lcpStore.lcpOpste.postrojenje_id }}
        fetchOptinsList={SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA}
        labelOptionAccessor={['naziv_izvora']}
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultIzvorOption}
        rules={[REQUIRED_FIELD_RULE(true), izvorExistsRule]}
      />
    );
  }
);
