import i18n from 'translations/i18n.config';
import {
  IGetOperatorsPackageWasteManage,
  IOperatorsPackageWasteManageFilters,
  IPostOperatorsPackageWasteManage,
} from './operatorsPackageWasteManage.types';
import { FormInstance } from 'antd';
import {
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const OPERATORS_PACKAGE_WASTE_MANAGE_INITIAL_STATE: IPostOperatorsPackageWasteManage = {
  company_id: '',
  permit_number: '',
  is_active: true,
};
export const OPERATORS_PACKAGE_WASTE_MANAGE_FILTERS_INITIAL_STATE: IOperatorsPackageWasteManageFilters = {
  company_id: '',
  permit_number__ilike: '',
  is_active: null,
};

export const OPERATORS_PACKAGE_WASTE_MANAGE_TABLE_DATA = [
  {
    title: `${i18n.t('COMPANY.TITLE')}`,
    width: '500px',
    dataIndex: ['preduzece', 'naziv'],
    key: 'company_id',
  },
  {
    title: `${i18n.t('OBRASCI.AAO1.PACKAGING_AMOUNT.PERMIT_NUMBER')}`,
    width: '100px',
    dataIndex: 'permit_number',
    key: 'permit_number',
  },
];

export const OPERATORS_PACKAGE_WASTE_MANAGE_FORM_OPTIONS = (
  _: FormInstance<IGetOperatorsPackageWasteManage>
): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'searchScroll',
      label: i18n.t('COMPANY.TITLE'),
      formName: 'company_id',
      fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
      filtersForFetch: {},
      labelOptionAccessor: ['naziv'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      objName: 'preduzece',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: i18n.t('OBRASCI.AAO1.PACKAGING_AMOUNT.PERMIT_NUMBER'),
      rules: [MAX_LENGTH_RULE(50)],
      name: 'permit_number',
      filterName: 'permit_number__ilike',
      inputName: 'permit_number',
    } as InputSifarniciFormProps,
  ];
};
