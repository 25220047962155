import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';

class GveService {
  filtersForPollutatCode = () => {
    switch (obrasciStore.real_obrazac_type) {
      case TIPOVI_OBRAZACA.OBRAZAC_2:
        return { prtr: 1 };

      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return { prtr: 1, gve1: 1 };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2:
        return { lriz: 1 };

      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return { lriz: 1, gve1: 1 };

      case TIPOVI_OBRAZACA.GVE:
        return { gve1: 1 };

      default:
        break;
    }
  };
}

export const gveService = new GveService();
