import { Table as ANTTable, Pagination, TableProps } from 'antd';
import styles from './TableData.module.scss';
import { observer } from 'mobx-react-lite';
import { IPagination } from 'typescript';
import { nrizService } from 'modules/nriz/nriz.service';
import { localeEmpty } from 'modules/nriz/nriz.constants';
import { cloneDeep } from 'lodash';

import { GetComponentProps } from 'rc-table/lib/interface';
import { getTableScrollHeight } from 'utils/getTableScrollHeight';
import { ColumnProps } from 'antd/es/table';
import { ExpandableConfig } from 'antd/es/table/interface';
import { IPageSizePagination } from 'typescript/NrizTypes';

export interface ITablePagination {
  page: number;
  size: number;
}

interface ITableProps extends TableProps<any> {
  columns: ColumnProps<any>[] | any[];
  dataSource: any[];
  refetchData?: (pagination: IPageSizePagination) => void;
  rowKey?: string | number;
  onChange?: () => void;
  scrollX?: number;
  virtual?: boolean;
  name: string;
  rowClassName?: (e: any) => string;
  onRow?: GetComponentProps<any>;
  scrollConfig?: string[];
  subtractTableHeight?: number;
  expandable?: ExpandableConfig<any>;
  storePagination?: IPagination | undefined;
}

const TableData: React.FC<ITableProps> = ({ ...props }) => {
  const loadData = async (page: number, size: number) => {
    props.refetchData && (await props.refetchData({ page, size }));
  };

  const handlePaginationChange = (page: any, pageSize: any) => {
    loadData(page, pageSize);
  };

  const defaultOnRow = (_: any, index?: number) => ({
    'data-test': `${props.name}-row-${index}`,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <ANTTable
          onChange={() => props.onChange}
          columns={props.columns}
          scroll={{
            y: props.scrollConfig
              ? `calc(100vh - ${getTableScrollHeight(props.scrollConfig)}px - ${props.subtractTableHeight ?? 0}px) `
              : '400px',
          }}
          dataSource={cloneDeep(nrizService.addKey(props.dataSource))}
          virtual={props.virtual}
          bordered={true}
          locale={localeEmpty}
          pagination={false}
          rowClassName={record => (props.rowClassName ? `${styles[props.rowClassName(record)]}` : '')}
          rowKey={props.rowKey}
          expandable={props.expandable}
          //@ts-ignore
          onRow={props.onRow || defaultOnRow}
          data-test={props.name}
        />
        {props.storePagination && props.storePagination.total > 0 && (
          <div className={styles.pagination}>
            <Pagination
              current={props.storePagination?.page}
              pageSize={props.storePagination?.size}
              total={props.storePagination?.total}
              onChange={handlePaginationChange}
              showSizeChanger={true}
              showTotal={(_, range) =>
                `${range[0]}-${range[1]} od ${props.storePagination && props.storePagination?.total}`
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default observer(TableData);

// useEffect(
//   () => {
//     if (isFetchOnInitEnabled) {
//       loadData(1, paginationTable.size, true);
//     } else {
//       setIsFetchOnInitEnabled(true);
//     }
//   },
//   props.forceFetch ? [...props.forceFetch] : []
// );

// useEffect(
//   () => {
//     if (props.refetchSamePage && props.refetchSamePage[0]) {
//       loadData(paginationTable.page, paginationTable.size);
//     }
//   },
//   props.refetchSamePage ? props.refetchSamePage : []
// );

// const setDataProps = () => {
//   return setData(props.dataSource);
// };

// useEffect(() => {
//   setDataProps();
// }, [props.dataSource]);

// // todo: proveriti da za sta smo koristili isEmpty
// // if (isEmpty(data)) {
// //   return setData(props.dataSource);
// // }

// // kada brise a na poslednjoj je strani i nije  posledjni item
// if (
//   props.dataSource.length < data.length &&
//   paginationTable.page === paginationTable.pages &&
//   props.dataSource.length !== 0 &&
//   formStore.crudButtonType === 'delete'
// ) {
//   // console.log(' kada brise a na poslednjoj je strani i nije  posledjni item');

//   setData(props.dataSource);
//   setPaginationTable({ ...paginationTable, total: paginationTable.total - 1 });
// }

// // console.log(kada brisemo a na poslednjoj smo strani i poslednji item a nije prva strana)
// else if (
//   props.dataSource.length < data.length &&
//   paginationTable.page === paginationTable.pages &&
//   props.dataSource.length === 0 &&
//   paginationTable.page !== 1
// ) {
//   // console.log('kada brisemo a na poslednjoj smo strani i poslednji item a nije prva strana');
//   setPaginationTable({
//     ...paginationTable,
//     page: paginationTable.page - 1,
//     pages: paginationTable.pages - 1,
//     total: paginationTable.total - 1,
//   });
//   loadData(paginationTable.page - 1, paginationTable.size);
// }

// // kada brisemo a na prvoj strani i poslednji item
// else if (
//   props.dataSource.length < data.length &&
//   paginationTable.page === paginationTable.pages &&
//   props.dataSource.length === 0 &&
//   paginationTable.page === 1
// ) {
//   setPaginationTable({
//     ...paginationTable,
//     total: paginationTable.total - 1,
//   });
//   setData(props.dataSource);
// }

// // kada brise a nije na poslednjoj strani ni na prvoj
// else if (
//   props.dataSource.length < data.length &&
//   paginationTable.page !== paginationTable.pages &&
//   props.dataSource.length !== 0
// ) {
//   // console.log('kada brise a nije na poslednjoj strani ni na prvoj');

//   loadData(paginationTable.page, paginationTable.size);
// }
// // kada dodaje na prvoj je strani i vise od size
// else if (
//   props.dataSource.length > data.length &&
//   paginationTable.page === 1 &&
//   props.dataSource.length > paginationTable.size
// ) {
//   // console.log('kada dodaje na prvoj je strani i vise od size');

//   props.dataSource.pop();
//   setData(props.dataSource);
//   setPaginationTable({ ...paginationTable, total: paginationTable.total + 1, pages: paginationTable.pages + 1 });
// }

// // kada dodajemo i na prvoj smo strani a manji od size
// else if (
//   props.dataSource.length > data.length &&
//   paginationTable.page === 1 &&
//   props.dataSource.length <= paginationTable.size
// ) {
//   // console.log('kada dodajemo i na prvoj smo strani a manji od size');

//   setData(props.dataSource);
//   setPaginationTable({ ...paginationTable, total: paginationTable.total + 1 });
// }

// // kada dodaje na strani koja nije 1
// else if (
//   props.dataSource.length > data.length &&
//   paginationTable.page !== 1 &&
//   formStore.crudButtonType === 'submit'
// ) {
//   // console.log('kada dodaje na strani koja nije 1');

//   loadData(1, paginationTable.size);
// } else {
//   // console.log('setDataProps');

//   setData(props.dataSource);
// }
