import { Alert, Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NewActivity2Form.module.scss';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { formStore } from 'store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IFormProps } from 'typescript/NrizTypes';
import { IGetActivity2 } from 'modules/activityList2/activityList2.types';
import { activity2InitialState } from 'modules/activityList2/activityList2.constants';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { activityList2Store } from 'modules/activityList2/activityList2.store';
import { IGetPrivrednaDelatnost2 } from 'modules/sifarnici/store/privrednaDelatnost2/privrednaDelatnost2.types';
import { IGetAktivnost2 } from 'modules/sifarnici/store/aktivnost2/aktivnost2.types';
import { facilityStore } from 'modules/facility/facility.store';

export const NewActivity2Form: React.FC<IFormProps> = observer(() => {
  const [form] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return activityList2Store.submitActivity(newValues as any);
  };

  useEffect(() => {
    form.setFieldsValue({ ...activityList2Store.getActivity });
  }, [activityList2Store.getActivity]);

  const defaultPrivrednaDelatnostOption: ISifarnikInitial | undefined = activityList2Store.activity
    .privredna_delatnost_id
    ? {
        label: `${(activityList2Store.activity as IGetActivity2).privredna_delatnost2.code} ${
          (activityList2Store.activity as IGetActivity2).privredna_delatnost2.name
        }`,
        value: activityList2Store.activity.privredna_delatnost_id,
      }
    : undefined;

  const defaultAktivnostOption: ISifarnikInitial | undefined = useMemo(() => {
    return activityList2Store.activity.aktivnost_id
      ? {
          label: `${(activityList2Store.activity as IGetActivity2).aktivnost2.code} ${
            (activityList2Store.activity as IGetActivity2).aktivnost2.name
          }`,
          value: activityList2Store.activity.aktivnost_id,
        }
      : undefined;
  }, [activityList2Store.activity]);

  const handlePrivrednaDelatnostChange = (value: string) => {
    form.setFieldValue('aktivnost_id', '');
    activityList2Store.handleChange('privredna_delatnost_id', value);
  };

  const activityOptionMaker = (options: IGetAktivnost2[]) => {
    return options.map(activity => ({
      label: `${activity.code} ${activity.name}`,
      value: activity.id,
    }));
  };

  const economicActivityOptionMaker = (options: IGetPrivrednaDelatnost2[]) => {
    return options.map(activity => ({
      label: `${activity.code} ${activity.name}`,
      value: activity.id,
    }));
  };

  return (
    <>
      <p className={styles.activityModalInfo}>{t('ACTIVITIES.MODAL_INFO')}</p>
      <Form
        form={form}
        className={styles.activityForm}
        initialValues={activity2InitialState}
        labelAlign="left"
        name={'aktivnost2'}
        onFinish={activityList2Store.submitActivity}
        onFinishFailed={() => {
          if (formStore.crudButtonType === 'delete') {
            onFinishFailed();
          }
        }}
      >
        <FormSearchInfiniteScroll
          showSearch
          label={t('ACTIVITIES.ECONOMIC_ACTIVITY')}
          labelCol={{ span: 8 }}
          formName={'privredna_delatnost_id'}
          inputName={'privredna_delatnost_id'}
          labelOptionAccessor={'name_sr'}
          valueOptionAccessor={'id'}
          defaultOption={defaultPrivrednaDelatnostOption}
          fetchOptinsList={activityList2Store.fetchPrivrednaDelatnost2}
          rules={[REQUIRED_FIELD_RULE(true)]}
          onChange={handlePrivrednaDelatnostChange}
          optionsMaker={economicActivityOptionMaker}
          placeholder={''}
        />
        <FormSearchInfiniteScroll
          showSearch
          label={t('ACTIVITIES.ACTIVITY')}
          labelCol={{ span: 8 }}
          formName={'aktivnost_id'}
          inputName={'aktivnost_id'}
          labelOptionAccessor={'name_sr'}
          valueOptionAccessor={'id'}
          defaultOption={defaultAktivnostOption}
          fetchOptinsList={activityList2Store.fetchAktivnost2}
          resetOption={activityList2Store.privredna_delatnost_id}
          rules={[REQUIRED_FIELD_RULE(true)]}
          optionsMaker={activityOptionMaker}
          disabled={!activityList2Store.privredna_delatnost_id}
          placeholder={''}
        />
        <FormCheckbox
          name="glavna_aktivnost"
          label={t('ACTIVITIES.MAIN')}
          labelCol={{ span: 23 }}
          disabled={activityList2Store.activity.glavna_aktivnost}
          rules={[
            {
              validator(_, value) {
                const privrednaDelatnostId = form.getFieldValue('privredna_delatnost_id');
                if (!privrednaDelatnostId) return Promise.resolve();
                const privrednaDelatnost = sifarniciStore.list.PRIVREDNA_DELATNOST_2.find(delatnost => {
                  return delatnost.id === privrednaDelatnostId;
                });
                if (Number(privrednaDelatnost?.code) === 0 && value)
                  return Promise.reject(new Error('Aktivnost pod šifrom 0 ne može biti glavna'));
                return Promise.resolve();
              },
            },
          ]}
        />
        <Row justify={'space-between'}>
          <Col span={14}>
            <Alert
              style={{ display: 'flex' }}
              message="Nakon menjanja liste aktivnosti, proverite dodeljene obrasce na postrojenju"
              type="info"
              banner
            />
          </Col>
          {(facilityStore.getFacility.is_active && (activityList2Store.getActivity as IGetActivity2).id) ||
          !(activityList2Store.getActivity as IGetActivity2).id ? (
            <CRUDButton
              showButton={!!(activityList2Store.getActivity as IGetActivity2).id}
              form={form}
              withOutDelete={activityList2Store.activity.glavna_aktivnost && activityList2Store.activityList.length > 1}
            />
          ) : undefined}
        </Row>
      </Form>
    </>
  );
});
