import { ApiResponse, axios } from 'modules/axios';
import { REGISTRI_CONFIG } from '../registri.constants';
import { RegistriEnum } from '../registri.types';
import { IOTVRegistar, IOTVRegistarZagadjenje, OTVRequest } from './otv.types';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import queryString from 'query-string';

const fetchOtvList = (filters: any): IPaginatedApiResponse<IOTVRegistar> => {
  const query = queryString.stringify(filters);

  return axios.get(`${REGISTRI_CONFIG[RegistriEnum.Otv].routes.apiRoute}?${query}`);
};

const fetchOtvById = (id: string): ApiResponse<IOTVRegistar> => {
  return axios.get(`${REGISTRI_CONFIG[RegistriEnum.Otv].routes.apiRoute}${id}`);
};

const postOtv = (payload: OTVRequest): ApiResponse<string> => {
  return axios.post(`${REGISTRI_CONFIG[RegistriEnum.Otv].routes.apiRoute}`, payload);
};

const updateOtv = (payload: { otv_registar_zagadjenje: IOTVRegistarZagadjenje[] }, id: string): ApiResponse<any> => {
  return axios.put(`${REGISTRI_CONFIG[RegistriEnum.Otv].routes.apiRoute}${id}`, payload);
};

export const otvRepo = {
  fetchOtvList,
  fetchOtvById,
  postOtv,
  updateOtv,
};
