import { ColumnsType } from 'antd/es/table';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { EditButtonProps, RowEditPreviewButton } from 'components/RowEditPreviewButton/RowEditPreviewButton';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { exportPreuzetOdText } from 'modules/obrasci/store/deo6/deo6.service';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetPreuzetOtpad, PreuzetOdEnum } from 'modules/obrasci/store/deo6/deo6.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberWithDecimals } from 'utils/formatNumberWithDecimals';

const EditDeo6PreuzetOtpadButton: React.FC<EditButtonProps<IGetPreuzetOtpad>> = observer(({ record }) => {
  return (
    <RowEditPreviewButton
      name="deo6-preuzet-otpad"
      modalName="deo6-preuzet-otpad-modal"
      onClick={() => {
        deo6ObrazacStore.handleChange('index_id', record.indeksni_broj_otpada_id);
        if (record.indeksni_broj_otpada.weee && Number(deo6ObrazacStore.godina) >= 2020) {
          deo6ObrazacStore.handleChange('weee_categories', true);
        }
        deo6ObrazacStore.handleChange('preuzet_form', record);
      }}
      preview={obrasciStore.zakljucanObrazac}
    />
  );
});

export const Deo6PreuzetOtpadWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();
  const baseColumns: ColumnsType<IGetPreuzetOtpad> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 200,
      key: 'index_number',
      render: (_: any, record: IGetPreuzetOtpad) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.DEO_6.KOLICINA_PREUZETOG')}`,
      width: 200,
      key: 'kolicina',
      render(_, record) {
        if (!record.kolicina) return <></>;
        return <span>{formatNumberWithDecimals(record.kolicina, 4)}</span>;
      },
    },
    {
      title: `${t('OBRASCI.DEO_6.OTPAD_PREUZET_OD')}`,
      width: 200,
      dataIndex: 'preuzet_od',
      key: 'preuzet_od',
      render: (_: any, record: IGetPreuzetOtpad) => {
        return <div>{exportPreuzetOdText(record.preuzet_od)}</div>;
      },
    },
    {
      title: `${t('COMPANY.TITLE')}`,
      width: 200,
      dataIndex: ['preduzece', 'full_company_name'],
      key: 'naziv',
      render: (_: any, record: IGetPreuzetOtpad) => {
        if (
          !record.preduzece_id &&
          record.preuzet_od === PreuzetOdEnum.PRAVNO_LICE &&
          Number(deo6ObrazacStore.godina) <= 2019
        ) {
          return <>{record?.naziv_preduzeca}</>;
        }
        return <div>{record?.preduzece?.full_company_name}</div>;
      },
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetPreuzetOtpad) => <EditDeo6PreuzetOtpadButton record={record} />,
    },
  ];

  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'deo6-preuzet-otpad-form'} modalName="deo6-preuzet-otpad-modal" />}
    >
      <TableData
        scrollConfig={['#obrazacWizardHeader', '#obrazacTableHeader']}
        name="preuzet-otpad-deo6"
        columns={baseColumns}
        dataSource={[...deo6ObrazacStore.initialState.preuzet_otpad]}
      />
    </InsideContentWrapper>
  );
});
