import { Col, Form, Row } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore, formStore } from 'store';
import styles from './NewFacilityForm.module.scss';
import { useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { FacilityListProps } from 'typescript/NrizTypes';
import {
  REQUIRED_FIELD_RULE,
  MAX_LENGTH_RULE,
  EMAIL_FIELD_RULE,
  POST_NUMBER_FIELD_RULE,
  invalidCharactersForInputNumber,
  MORE_THEN_0,
  LATITUDE_RULE,
  LONGITUDE_RULE,
} from 'modules/nriz/nriz.constants';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';

import { IGetFacility } from 'modules/facility/facility.types';
import { facilityFormInitialState } from 'modules/facility/facility.constants';
import { facilityStore } from 'modules/facility/facility.store';

export const NewFacilityForm: React.FC<FacilityListProps> = observer(props => {
  const [form] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return facilityStore.submitFacility(newValues as any);
  };

  useEffect(() => {
    if (props.passForm) {
      props.passForm(form);
    }
    return form.setFieldsValue({ ...facilityStore.getFacility });
  }, [facilityStore.getFacility]);

  const defaultMestoOption = facilityStore.facility?.mesto_id
    ? { label: (facilityStore.facility as IGetFacility).mesto?.display_name, value: facilityStore.facility?.mesto_id }
    : undefined;

  return (
    <>
      <Form
        form={form}
        initialValues={facilityFormInitialState}
        name={'facility'}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        layout="horizontal"
        onFinish={values => {
          facilityStore.submitFacility(values);
        }}
        labelAlign="left"
        onFinishFailed={() => {
          if (formStore.crudButtonType === 'delete') {
            onFinishFailed();
          }
        }}
      >
        <Row gutter={50}>
          <Col span={12}>
            <FormInput
              name="naziv_postrojenja"
              rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(500)]}
              type="text"
              placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
              label={t('FACILITY.NAME')}
              inputName={'naziv_postrojenja'}
              colon={true}
              readOnly={props.readOnly}
            />

            <FormSearchInfiniteScroll
              showSearch={true}
              formName={'mesto_id'}
              fetchOptinsList={facilityStore.fetchMesto}
              labelOptionAccessor="display_name"
              valueOptionAccessor="id"
              placeholder={t('COMPANY.PLACEHOLDER.ENTER_CITY')}
              label={t('COMPANY.LOCATION')}
              readOnly={props.readOnly}
              inputName={'mesto_id'}
              rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
              colon={true}
              defaultOption={defaultMestoOption}
            />

            <FormInput
              name="postanski_broj"
              rules={[REQUIRED_FIELD_RULE(!props.readOnly), POST_NUMBER_FIELD_RULE, ...MORE_THEN_0]}
              type="number"
              placeholder={t('COMPANY.PLACEHOLDER.ENTER_POST_NUMBER')}
              label={t('COMPANY.POST_NUMBER')}
              inputName={'postanski_broj'}
              colon={true}
              className={styles.removeArrow}
              readOnly={props.readOnly}
              invalidCharacters={invalidCharactersForInputNumber}
            />

            <FormInput
              name="ulica"
              rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(100)]}
              type="text"
              readOnly={props.readOnly}
              placeholder={t('COMPANY.PLACEHOLDER.ENTER_ADDRESS')}
              label={t('COMPANY.ADDRESS')}
              inputName={'ulica'}
              colon={true}
            />

            <FormInput
              name="kucni_broj"
              rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(100)]}
              type="text"
              placeholder={t('COMPANY.PLACEHOLDER.ENTER_ADDRESS_NUMBER')}
              readOnly={props.readOnly}
              label={t('COMPANY.ADDRESS_NUMBER')}
              inputName={'kucni_broj'}
              colon={true}
              className={styles.removeArrow}
            />
            <FormInput
              name="email"
              rules={[EMAIL_FIELD_RULE, MAX_LENGTH_RULE(100)]}
              type="email"
              readOnly={props.readOnly}
              placeholder={t('USER.EMAIL')}
              label={t('USER.EMAIL')}
              inputName={'email'}
              colon={true}
            />
            <FormInput
              rules={[REQUIRED_FIELD_RULE(!props.readOnly), MAX_LENGTH_RULE(100)]}
              label={t('USER.PHONE')}
              readOnly={props.readOnly}
              name={'telefon'}
              inputName={'telefon'}
              colon={true}
              type={'text'}
              placeholder="+381 60666555"
              className={styles.removeArrow}
            />

            <FormInput
              label={t('Telefaks')}
              readOnly={props.readOnly}
              name={'telefax'}
              inputName={'telefax'}
              placeholder=""
              rules={[...MORE_THEN_0, MAX_LENGTH_RULE(100)]}
              type="number"
            />
          </Col>
          <Col span={12}>
            <FormInput
              type="number"
              name="koordinate_lat"
              readOnly={props.readOnly}
              placeholder={t('FACILITY.PLACEHOLDER.ENTER_LATITUDE')}
              label={t('COMPANY.LATITUDE')}
              inputName={'koordinate_lat'}
              colon={true}
              className={styles.removeArrow}
              rules={LATITUDE_RULE()}
            />

            <FormInput
              type="number"
              name="koordinate_long"
              placeholder={t('FACILITY.PLACEHOLDER.ENTER_LONGITUDE')}
              label={t('COMPANY.LONGITUDE')}
              readOnly={props.readOnly}
              inputName={'koordinate_long'}
              colon={true}
              className={styles.removeArrow}
              rules={LONGITUDE_RULE()}
            />
            {!props.readOnly && (
              <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
                {t('KOORDINATE_TEXT')}
                <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
                  http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
                </a>
              </h4>
            )}
            <FormInput
              name="nacionalni_id"
              type="text"
              label={t('FACILITY.NACIONAL_ID')}
              disabled={!props.readOnly}
              readOnly={props.readOnly}
              inputName={'nacionalni_id'}
              rules={[MAX_LENGTH_RULE(50)]}
              colon={true}
              className={styles.removeArrow}
            />
            <FormInput
              name="prethodni_nacionalni_id"
              type="number"
              rules={[MAX_LENGTH_RULE(50)]}
              placeholder={authStore.isAdmin ? t('FACILITY.PLACEHOLDER.ENTER_PREVIOUS_NATIONAL_ID') : ''}
              label={t('FACILITY.PREVIOUS_NATIONAL_ID')}
              disabled={!props.readOnly && !authStore.isAdmin}
              readOnly={props.readOnly}
              inputName={'prethodni_nacionalni_id'}
              colon={true}
              className={styles.removeArrow}
            />
            {authStore.isAdmin && (
              <>
                <FormInput
                  name="istorijski_id"
                  rules={[MAX_LENGTH_RULE(50)]}
                  placeholder={t('FACILITY.PLACEHOLDER.ENTER_HISTORICAL_ID')}
                  label={t('FACILITY.HISTORICAL_ID')}
                  readOnly={props.readOnly}
                  inputName={'istorijski_id'}
                  colon={true}
                  className={styles.removeArrow}
                />
                <FormCheckbox name={'ippc'} label={'IPPC'} disabled={props.readOnly} />
              </>
            )}
            <FormTextArea
              disabled={props.readOnly}
              labelCol={9}
              wrapperCol={15}
              name="napomena"
              rows={2}
              label={t('COMPANY.NOTE')}
              rules={[MAX_LENGTH_RULE(3000)]}
            />
          </Col>

          <Col span={24}>
            {!props.readOnly && !props.passForm && (
              <CRUDButton
                showButton={!!(facilityStore.getFacility as IGetFacility).id}
                form={form}
                withOutDelete={true}
              />
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
});
