import { ColumnsType } from 'antd/es/table';
import { IGetMunicipalityCode, IMunicipalityCodeFilters, IPostMunicipalityCode } from './municipalityCode.types';
import { FormInstance } from 'antd';
import { CODE_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import i18n from 'translations/i18n.config';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const MUNICIPALITY_CODE_FILTERS_INITIAL_STATE: IMunicipalityCodeFilters = {
  code__ilike: null,
  name__ilike: '',
  longitude_min__ilike: '',
  longitude_max__ilike: '',
  latitude_min__ilike: '',
  latitude_max__ilike: '',
  is_active: null,
};
export const MUNICIPALITY_CODE_INITIAL_STATE: IPostMunicipalityCode = {
  code: null,
  name: '',
  longitude_min: '',
  longitude_max: '',
  latitude_min: '',
  latitude_max: '',
  is_active: true,
};
export const MUNICIPALITY_CODE_TABLE_DATA: ColumnsType<IGetMunicipalityCode> = [
  NAME_TABLE,
  CODE_TABLE,
  {
    title: `${i18n.t('COMPANY.LONGITUDE')} min`,
    width: '200px',
    dataIndex: 'longitude_min',
    key: 'longitude_min',
  },
  {
    title: `${i18n.t('COMPANY.LONGITUDE')} max`,
    width: '200px',
    dataIndex: 'longitude_max',
    key: 'longitude_max',
  },
  {
    title: `${i18n.t('COMPANY.LATITUDE')} min`,
    width: '200px',
    dataIndex: 'latitude_min',
    key: 'latitude_min',
  },
  {
    title: `${i18n.t('COMPANY.LATITUDE')} max`,
    width: '200px',
    dataIndex: 'latitude_max',
    key: 'latitude_max',
  },
];
export const MUNICIPALITY_CODE_FORM_OPTIONS = (_: FormInstance<IGetMunicipalityCode>): ISifarniciTypeOfForm[] => {
  return [
    CODE_FORM,
    NAME_FORM(150),
    {
      type_of_form: 'input',
      name: 'longitude_min',
      filterName: 'longitude_min__ilike',
      rules: [MAX_LENGTH_RULE(50)],
      label: `${i18n.t('COMPANY.LONGITUDE')} min`,
      inputName: 'longitude_min',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'longitude_max',
      filterName: 'longitude_max__ilike',
      rules: [MAX_LENGTH_RULE(50)],
      label: `${i18n.t('COMPANY.LONGITUDE')} max`,
      inputName: 'longitude_max',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'latitude_min',
      filterName: 'latitude_min__ilike',
      rules: [MAX_LENGTH_RULE(50)],
      label: `${i18n.t('COMPANY.LATITUDE')} min`,
      inputName: 'latitude_min',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'latitude_max',
      filterName: 'latitude_max__ilike',
      rules: [MAX_LENGTH_RULE(50)],
      label: `${i18n.t('COMPANY.LATITUDE')} max`,
      inputName: 'latitude_max',
    } as InputSifarniciFormProps,
  ];
};
