import { Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormSwitch } from 'components/FormSwitch/FormSwitch';
import { Modal } from 'components/Modal/Modal';
import dayjs from 'dayjs';

import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { opsteInitial } from 'modules/obrasci/obrasci.constants.tsx';
import React, { useEffect } from 'react';
import { authStore, modalStore } from 'store';
import { useTranslation } from 'react-i18next';

export interface IExportDeo6Gio6PdfForm {
  datum: string;
  preduzece_id: string;
  postrojenje_id: string;
  javno_dostupno: boolean;
}

export const ExportDeo6Gio6PdfForm = observer(() => {
  const { t } = useTranslation();
  const initialState: IExportDeo6Gio6PdfForm = {
    datum: '',
    preduzece_id: !authStore.isAdmin ? obrasciStore.opste.preduzece_id : '',
    postrojenje_id: !authStore.isAdmin ? obrasciStore.opste.postrojenje_id : '',
    javno_dostupno: false,
  };

  const [form] = useForm();

  useEffect(() => {
    if (!modalStore.getCurrentOpenedModal.includes('deo6-gio6-generisi-pdf-modal')) {
      if (authStore.isAdmin) {
        obrasciStore.handleChange('opste', opsteInitial);
      }
      form.setFieldsValue(initialState);
    }
  }, [modalStore.getCurrentOpenedModal]);

  return (
    <Form
      form={form}
      initialValues={initialState}
      name={`generisi-${obrasciStore.isDeo6OrGio6}`}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      layout="horizontal"
      onFinish={(values: IExportDeo6Gio6PdfForm) => {
        const datum = dayjs(values.datum).format('YYYY-MM-DD');
        obrasciStore.exportDeo6Gio6Pdf({ ...form.getFieldsValue(true), datum: datum });
      }}
      labelAlign="left"
    >
      <FormDatePicker
        name="datum"
        label="Datum"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        format="DD-MM-YYYY"
        picker={'date'}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />

      {authStore.isAdmin && (
        <>
          <FormSearchInfiniteScroll
            label={t('COMPANY.TITLE')}
            placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
            formName={'preduzece_id'}
            fetchOptinsList={obrasciStore.fetchPreduzecaPoObrascu}
            labelOptionAccessor="naziv"
            valueOptionAccessor="id"
            readOnly={false}
            showSearch
            onChange={(value: string) => {
              form.setFieldValue('postrojenje_id', null);
              obrasciStore.handleOpste('preduzece_id', value);
            }}
            rules={[REQUIRED_FIELD_RULE(true)]}
          />

          <FormSearchInfiniteScroll
            label={t('FACILITY.TITLE')}
            labelCol={{ span: 9 }}
            showSearch
            wrapperCol={{ span: 15 }}
            placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
            formName={'postrojenje_id'}
            fetchOptinsList={obrasciStore.fetchPostrojenjePoObrascu}
            labelOptionAccessor="naziv_postrojenja"
            valueOptionAccessor="id"
            readOnly={false}
            rules={[REQUIRED_FIELD_RULE(true)]}
            disabled={!obrasciStore.opste.preduzece_id}
          />

          <FormSwitch name={'javno_dostupno'} label={'Javno dostupni podaci'} />
        </>
      )}
      <Row justify={'end'}>
        <Button disabled={modalStore.isLoading} htmlType="submit" name="submit-button">
          {t('SUBMIT')}
        </Button>
      </Row>
    </Form>
  );
});

export const ExportDeo6Gio6PdfModal: React.FC = observer(() => {
  return (
    <Modal
      name="deo6-gio6-generisi-pdf-modal"
      open={modalStore.getCurrentOpenedModal.includes('deo6-gio6-generisi-pdf-modal')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        modalStore.removeAllModals();
      }}
      title={`Preuzmi ${obrasciStore.isDeo6OrGio6} PDF`}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'70%'}
      forceRender
    >
      <ExportDeo6Gio6PdfForm />
    </Modal>
  );
});
