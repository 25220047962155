import { ContentProps } from 'typescript/NrizTypes';
import styles from './Content.module.scss';
import { BackButton } from 'components/Buttons/BackButton/BackButton';
import { Row } from 'antd';

export const Content: React.FC<ContentProps> = props => {
  return (
    <div className={styles.contentWrapper}>
      <div id={'contentHeader'}>
        {props.backBtn && (
          <Row className={styles.btn} align={'middle'} justify={'space-between'}>
            <BackButton
              name={props.backBtn.name}
              onClick={props.backBtn.onClick}
              route={props.backBtn.route}
              label={props.backBtn.label}
            />
            {props.backBtn.rightComponent}
          </Row>
        )}
        {props.header && <div className={styles.header}>{props.header}</div>}
      </div>
      <div className={styles.content} id="mainContent">
        {props.children}
      </div>
    </div>
  );
};
