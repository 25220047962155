import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styles from './UserInfo.module.scss';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { NewUserForm } from 'components/NewForms';
import { Col, FormInstance, Row } from 'antd';
import { IGetUser } from 'modules/user/user.types';
import { useTranslation } from 'react-i18next';
import { authStore, formStore } from 'store';
import { EditChangeDiscardButton } from 'components/Buttons/EditChangeDiscardButton/EditChangeDiscardButton';
import { userStore } from 'modules/user/user.store';
// import { cloneDeep } from 'lodash';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { ChangeCompanyOwnerModal } from 'components/Modals/ChangeCompanyOwnerModal/ChangeCompanyOwnerModal';

export interface IPropsHeaderUser {
  changeIsReadOnly: () => any;
  isReadOnly: boolean;
  form: FormInstance<IGetUser> | null;
}

export const UserInfoHeader: React.FC<IPropsHeaderUser> = observer(props => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('COMPANY.GENERAL_INFORMATION')}</h3>
      <Row align={'middle'}>
        {(authStore.isAdmin || authStore.isCompanyOwner) && (
          <>
            <Col>
              {props.isReadOnly && (userStore.getUser as IGetUser).id !== authStore.userId && (
                <IsActiveSwitch
                  roleCondition={authStore.isCompanyOwner}
                  isActive={(userStore.getUser as IGetUser).is_active}
                  toggleActivate={userStore.toggleActivateUser}
                />
              )}
            </Col>
            <EditChangeDiscardButton
              name="company-info"
              editButtonVisible={props.isReadOnly && (userStore.getUser as IGetUser).is_active}
              onEditClick={() => {
                props.changeIsReadOnly();
              }}
              onDiscardClick={() => {
                props.changeIsReadOnly();
                // userStore.setUser(cloneDeep(userStore.getUser));
                props.form?.resetFields();
              }}
              onChangeClick={() => {
                formStore.setCrudButtonType('change');
                // if (props.form) {
                // }

                // todo: Ubacena validacija za submit. Desavalo se da se readonly state menja pre submita forme i da se polja uopste desava validacija polja.
                // Takodje, u samoj komponenti je promenjeno da je REQUIRED_FIELD_RULE uvek true.
                // mozda nije najsrecnije resenje... baci pogled

                props.form?.validateFields().then(
                  res => {
                    props.form?.submit();
                    props.changeIsReadOnly();
                    return res;
                  },
                  err => {
                    return err;
                  }
                );

                // props.changeIsReadOnly();
              }}
              discardChangeButtonVisible={!props.isReadOnly}
            />
          </>
        )}
      </Row>
    </Row>
  );
});

export const UserInfo: React.FC = observer(() => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [form, setForm] = useState<FormInstance<IGetUser> | null>(null);

  const changeIsReadOnly = () => {
    setIsReadOnly(!isReadOnly);
  };

  const passForm = (form: FormInstance<IGetUser>) => {
    setForm(form);
  };

  useEffect(() => {
    return () => setIsReadOnly(true);
  }, []);

  return (
    <InsideContentWrapper
      header={<UserInfoHeader changeIsReadOnly={changeIsReadOnly} isReadOnly={isReadOnly} form={form} />}
    >
      <div className={`${styles.user_info_wrapper} ${isReadOnly && styles.inputReadOnly}`}>
        <NewUserForm showButtons={false} readOnly={isReadOnly} formName="user-read-only" passForm={passForm} />
        <ChangeCompanyOwnerModal />
      </div>
    </InsideContentWrapper>
  );
});
