import { observer } from 'mobx-react-lite';
import React from 'react';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { useForm } from 'antd/es/form/Form';
import { IFilterTableProps } from 'typescript/NrizTypes';
import { companyStore } from 'modules/company/company.store';
import { companiesFilterOptions } from 'modules/company/company.constants';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

export const CompaniesListFilters: React.FC = observer(() => {
  const fetchList = () => {
    companyStore.fetchCompaniesList(paginationInitialState);
  };

  const [companyForm] = useForm();

  const data: IFilterTableProps = {
    form: companyForm,
    initialState: filtersStore.getFilters[FiltersNameEnum.PREDUZECA],
    filterStoreName: FiltersNameEnum.PREDUZECA,
    filterOptions: companiesFilterOptions,
    fetchTableList: fetchList,
  };

  return (
    <div id={'companyFilters'}>
      <FilterTableForm data={data} />
    </div>
  );
});
