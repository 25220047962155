import { Form } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { OBRASCI_FILTERS_DATA } from 'modules/obrasci/filterDataObrasci.constants';
import { IFilterDataObrasci } from 'modules/obrasci/filterDataObrasci.types';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import React, { useMemo } from 'react';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const ObrasciFilters: React.FC = observer(() => {
  const [obrasciFilterForm] = useForm();

  const components = useMemo(() => {
    return OBRASCI_FILTERS_DATA[obrasciStore.real_obrazac_type].components(obrasciFilterForm);
  }, [Form.useWatch([], obrasciFilterForm), obrasciStore.real_obrazac_type]);

  const obrasciFiltersFetch = async () => {
    obrasciStore.fetchListObrazac(paginationInitialState);
  };

  const initialState = obrasciService.changeGodinaAndDatumFilters({
    ...obrasciService.filtersObrasciFinder(obrasciStore.real_obrazac_type).initialState,
    ...filtersStore.getFilters[FiltersNameEnum.OBRASCI],
  });

  const data: IFilterTableProps = {
    initialState: initialState,
    form: obrasciFilterForm as FormInstance<IFilterDataObrasci>,
    filterOptions: components,
    filterStoreName: FiltersNameEnum.OBRASCI,
    resetForm: obrasciStore.real_obrazac_type,
    modifyPayload: obrasciService.obrasciFilterModifyPayload,
    fetchTableList: obrasciFiltersFetch,
  };

  return (
    <div id="obrasciFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
