import to from 'await-to-js';
import { isEmpty } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { IPagination } from 'typescript';
import { companyStore } from '../company/company.store';
import { formStore } from '../../store/FormStore';
import { nrizService } from 'modules/nriz/nriz.service';
import { modalStore } from '../../store/ModalStore';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import {
  IGetFacility,
  IPostFacility,
  IPostFacilityTipObrasca,
  IPostFacilityTipoviObrazaca,
  ISurvey,
} from 'modules/facility/facility.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetCompany } from 'modules/company/company.types';
import { activityList1Store } from '../activityList1/activityList1.store';
import { activityList2Store } from '../activityList2/activityList2.store';
import { airStore } from '../sourcesOfPollution/air/air.store';
import { soilStore } from '../sourcesOfPollution/soil/soil.store';
import { waterStore } from '../sourcesOfPollution/water/water.store';
import { userStore } from '../user/user.store';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { facilityFormInitialState } from './facility.constants';
import { facilityService } from './facility.service';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { ITablePagination } from 'components/TableData/TableData';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import { toggleActivateItems } from 'utils/toggleActivateItems';

const buildSurveyValues = (values: ISurvey) => {
  const newValues = {
    ...values,
    postrojenje: { ...values.postrojenje, preduzece_id: (companyStore.getCompany as IGetCompany).id },
  };
  return newValues;
};

class FacilityStore {
  constructor() {
    makeAutoObservable(this);
  }
  facility: IPostFacility | IGetFacility = facilityFormInitialState;
  facilityList: IGetFacility[] = [];
  facilityTipoviObrazaca: ITipObrasca[] | IPostFacilityTipoviObrazaca = [];
  initial_tipovi_ids: string[] = [];
  survey: any = null;
  facilityFormShow = false;
  surveyValidation = true;
  facilityPagination: IPagination = paginationInitialState;
  readOnlyTipoviObrazacaForm = true;

  get getFacility() {
    return this.facility;
  }

  get getSurvey() {
    return this.survey;
  }

  get getFacilityTipoviObrazaca() {
    return this.facilityTipoviObrazaca;
  }

  get getFacilityTipoviObrazacaIds() {
    return this.facilityTipoviObrazaca?.map(tipObrasca => tipObrasca.id) || [];
  }

  get getFacilityList() {
    return this.facilityList;
  }

  get getFacilityFormShow() {
    return this.facilityFormShow;
  }

  get getSurveyValidation() {
    return this.surveyValidation;
  }

  handleChange(key: keyof FacilityStore, value: any) {
    (this as any)[key] = value;
  }

  changeSurvey = (type: string, value: string) => {
    runInAction(() => (this.survey = { ...this.survey, [type]: value }));
    if (!isEmpty(this.survey)) {
      let numberOfYes = false;
      Object.entries(this.survey).map(([key, values]) => {
        if (key.startsWith('korak_1') || key.startsWith('korak_4')) {
          if (values === 'da') {
            numberOfYes = true;
            runInAction(() => (this.facilityFormShow = true));
          }
        }
      });
      if (!numberOfYes) {
        runInAction(() => (this.facility = facilityFormInitialState));
        runInAction(() => (this.facilityFormShow = false));
      }
      this.changeValidationAllYes();
    }
  };

  checkSurveyValidation = () => {
    if (Object.values(this.survey).includes('da')) {
      return runInAction(() => (this.surveyValidation = true));
    }
    return runInAction(() => (this.surveyValidation = false));
  };

  changeValidationAllYes = () => {
    const yes = Object.values(this.survey).some(value => value === 'da');

    if (!this.surveyValidation) {
      if (yes) {
        runInAction(() => (this.surveyValidation = true));
      }
    }
  };

  setFacilityTipoviObrazaca = (selectedTipovi: ITipObrasca[] | IPostFacilityTipoviObrazaca) => {
    this.facilityTipoviObrazaca = selectedTipovi;
  };

  changeFacility = (type: string, value: string) => {
    if (this.facility) {
      this.facility = { ...this.facility, [type]: value };
    }
  };

  changeFacilityFormShow = () => {
    this.facilityFormShow = !this.changeFacilityFormShow;
  };

  setFacilityList = (facilityList: IGetFacility[]) => {
    this.facilityList = facilityList;
  };

  setFacility = (facility: IPostFacility | IGetFacility) => {
    this.facility = facility;
  };

  fetchFacility = async (id: string) => {
    const [err, res] = await to(facilityService.fetchFacility(id));
    if (err || !res) return;
    companyStore.handleChange('company', { ...res.preduzece, id: res.preduzece_id });
    this.setFacility(res);
  };

  fetchFacilityCompanyList = async (pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<IGetFacility>>(
      facilityService.fetchFacilityList(
        nrizService.pickFields({
          page: pagination?.page || this.facilityPagination.page,
          size: pagination?.size || this.facilityPagination.size,
          preduzece_id: (companyStore.company as IGetCompany).id,
          ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.POSTROJENJA]),
        })
      )
    );
    if (err || !res) return;
    const { items, page, size, total, pages } = res;
    this.setFacilityList(items);
    this.handleChange('facilityPagination', { page, size, pages, total });

    return res;
  };

  fetchFacilityListByUser = async (pagination: ITablePagination) => {
    const [err, res] = await to(
      facilityService.fetchFacilityListByUser({
        page: pagination.page,
        size: pagination.size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.POSTROJENJA]),
      })
    );
    if (err || !res) return;
    this.setFacilityList(res.items);
    return Promise.resolve(res);
  };

  postFacility = async (values: IPostFacility) => {
    const newObject = { ...values, preduzece_id: (companyStore.getCompany as IGetCompany).id, is_active: true };
    const [err, res] = await to<IPostResponse>(
      facilityService.postFacility(nrizService.setEmptyValuesToNull(newObject) as IGetFacility)
    );
    if (err || !res) return;

    this.fetchFacilityCompanyList(this.facilityPagination);
    this.handleChange('facilityPagination', nrizService.increaseTotalPagination(this.facilityPagination));
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  fetchFacilityTipoviObrasca = async (id: string) => {
    const [err, res] = await to(facilityService.fetchFacilityTipObrasca(id));
    if (err || !res) return;
    this.setFacilityTipoviObrazaca(res);
    const ids = res.map((item: ITipObrasca) => item.id);

    this.handleChange('initial_tipovi_ids', ids);
  };

  postFacilityTipoviObrasca = async (selectedTipovi: IPostFacilityTipoviObrazaca) => {
    if (!(this.facility as IGetFacility).id) return;
    const [err, res] = await to(
      facilityService.postFacilityTipObrasca((this.facility as IGetFacility).id, selectedTipovi)
    );
    if (err || !res) return Promise.reject();
    handleSuccessNotify();
    this.setFacilityTipoviObrazaca(selectedTipovi);
    const ids = selectedTipovi.map((item: IPostFacilityTipObrasca) => item.id);
    this.handleChange('initial_tipovi_ids', ids);
  };

  postSurvey = async (values: ISurvey) => {
    this.checkSurveyValidation();
    if (this.surveyValidation === false) return;
    let newValues = values;
    if (this.facilityFormShow) {
      newValues = buildSurveyValues(values);
    }

    const [err, res] = await to(
      facilityService.postSurvey({ ...newValues, preduzece_id: (companyStore.getCompany as IGetCompany).id })
    );
    if (err || !res) return;
    runInAction(() => {
      if (this.facility) {
        this.facility = { ...this.facility, id: res.id };
      }
    });
    handleSuccessNotify();
    companyStore.handleChange('firstPageToShow', 'home');
  };

  setSurvey = (values: ISurvey) => {
    this.survey = values;
  };

  putFacility = async (payload: IGetFacility) => {
    const newObject = { ...this.facility, ...payload } as IGetFacility;
    const [err, res] = await to(
      facilityService.putFacility(nrizService.setEmptyValuesToNull(newObject) as IGetFacility)
    );
    if (err || !res) return;
    let sifarnikMesto = {};
    if (newObject.mesto_id) {
      sifarnikMesto = nrizService.addSifarnikInObject({
        exId: this.facility?.mesto_id,
        exObj: (this.facility as IGetFacility).mesto,
        list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
        name: 'mesto',
        id: newObject.mesto_id,
      });
    }

    this.setFacility({ ...newObject, ...sifarnikMesto });
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  submitFacility = (values: IPostFacility) => {
    if (formStore.getCrudButtonType === 'submit') {
      return this.postFacility(values);
    }
    const newObject = { id: (this.facility as IGetFacility).id, ...values } as IGetFacility;
    if (formStore.getCrudButtonType === 'change') {
      return this.putFacility(newObject);
    }
  };

  toggleActivateFacility = async (isActive: boolean) => {
    const [err, res] = await to(facilityService.toggleActivateFacility((this.facility as IGetFacility).id, isActive));
    if (err || !res) return Promise.reject();
    this.handleChange('facility', { ...this.facility, is_active: isActive });
    airStore.setAirList(toggleActivateItems(airStore.getAirList, isActive));
    waterStore.setWaterList(toggleActivateItems(waterStore.getWaterList, isActive));
    soilStore.setSoilList(toggleActivateItems(soilStore.getSoilList, isActive));
    return handleSuccessNotify();
  };

  resetFacilityPage = () => {
    this.handleChange('facility', facilityFormInitialState);
    this.setFacilityTipoviObrazaca([]);
    this.handleChange('initial_tipovi_ids', []);
    this.handleChange('readOnlyTipoviObrazacaForm', true);
    activityList1Store.handleChange('activityList', []);
    activityList2Store.handleChange('activityList', []);
    waterStore.handleChange('waterList', []);
    airStore.handleChange('airList', []);
    soilStore.handleChange('soilList', []);
    userStore.setFacilityUserList([]);
    userStore.setUserList([]);
  };

  toggleDeo6Validation = (id: string, isValidationDisabled: boolean) => {
    return facilityService.toggleDeo6Validation(id, isValidationDisabled);
  };
}

export const facilityStore = new FacilityStore();
