import { ColumnsType } from 'antd/es/table';
import { ICountryCodeFilters, IGetCountryCode, IPostCountryCode } from './countryCode.types';
import { Checkbox, FormInstance } from 'antd';
import dayjs from 'dayjs';
import i18n from 'translations/i18n.config';

import {
  DateSifarniciFormProps,
  ISifarniciTypeOfForm,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import {
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  NAME_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  CODE_FORM,
  DESCRIPTION_FORM,
  NAME_FORM,
  TITLE_FORM,
  VERSION_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const COUNTRY_CODE_INITIL_STATE: IPostCountryCode = {
  validity_fromdate: '',
  validity_todate: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  granici_se_srbija: false,
  is_active: true,
};

export const COUNTRY_CODE_FILTERS_INITIL_STATE: ICountryCodeFilters = {
  validity_fromdate__gte: '',
  validity_todate__lte: '',
  title__ilike: '',
  description__ilike: '',
  version__ilike: null,
  code__ilike: '',
  name__ilike: '',
  granici_se_srbija: null,
  is_active: null,
};

export const COUNTRY_CODE_TABLE_DATA: ColumnsType<IGetCountryCode> = [
  NAME_TABLE,
  {
    title: i18n.t('SIFARNICI.OD_DATUMA'),
    width: '150px',
    dataIndex: 'validity_fromdate',
    key: 'validity_fromdate',
    render: (_: any, record: IGetCountryCode) => (
      <span>{record.validity_fromdate ? dayjs(record.validity_fromdate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: i18n.t('SIFARNICI.DO_DATUMA'),
    width: '150px',
    dataIndex: 'validity_todate',
    key: 'validity_todate',
    render: (_: any, record: IGetCountryCode) => (
      <span>{record.validity_todate ? dayjs(record.validity_todate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  {
    title: 'Graniči se sa Srbijom',
    width: '200px',
    dataIndex: 'granici_se_srbija',
    key: 'granici_se_srbija',
    align: 'center',
    render: (_: any, record: IGetCountryCode) => <Checkbox checked={!!record.granici_se_srbija} disabled={true} />,
  },
];
export const COUNTRY_CODE_FORM_OPTIONS = (form: FormInstance<IGetCountryCode>): ISifarniciTypeOfForm[] => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    NAME_FORM(300),
    {
      type_of_form: 'date',
      name: 'validity_fromdate',
      filterName: 'validity_fromdate__gte',
      label: i18n.t('SIFARNICI.OD_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        form.setFieldValue('validity_todate', '');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_todate',
      filterName: 'validity_todate__lte',
      label: i18n.t('SIFARNICI.DO_DATUMA'),
      format: 'DD-MM-YYYY',
      disabled: !!(sifarnik_type && !form.getFieldValue('validity_fromdate')),
      picker: 'date',
      disabledDate: (current: any) => {
        return current && current.endOf('day') < form.getFieldValue('validity_fromdate');
      },
    },
    TITLE_FORM(100),
    DESCRIPTION_FORM(200),
    VERSION_FORM,
    { ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(20)] },

    {
      type_of_form: 'switch',
      name: 'granici_se_srbija',
      label: 'Graniči se sa Srbijom?',
    } as SwitchSifarniciFormProps,
  ];
};
