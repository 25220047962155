import { action, makeAutoObservable, observable } from 'mobx';
import {
  IBaseObrazacStore,
  IEmptyResponse,
  IPaginatedResponse,
  IPagination,
  IPostResponse,
} from 'typescript/NrizTypes';
import to from 'await-to-js';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import dayjs from 'dayjs';
import { nrizService } from 'modules/nriz/nriz.service';
import { formStore } from 'store';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { isEmpty } from 'lodash';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import {
  IAAO2Response,
  IAao2KolicinaAmbalaze,
  IAao2UpravljanjeAmbalaznimOtpadom,
  IPostAao2,
  IPostAao2KolicinaAmbalaze,
  IPostAao2UpravljanjeAmbalaznimOtpadom,
  IPostPravnoLiceIliPreduzetnik,
  IPravnoLiceIliPreduzetnik,
} from './aao2.types';
import {
  AAO2_INITIAL_STATE,
  AAO2_KOLICINA_AMBALAZA_INITIAL_STATE,
  AAO2_PRAVNO_LICE,
  AAO2_PRAVNO_LICE_FIELDS,
  AAO2_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE,
  keyMapping,
} from './aao2.constants';
import { sifarniciService } from 'modules/sifarnici/sifarnici.service';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
// import { arrayToObject } from 'utils/arrayToObject';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { IGetCompany } from 'modules/company/company.types';
import { IGetOperatorsPackageWasteManage } from 'modules/sifarnici/store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { IGetSifarnikPretezneDelatnosti } from 'modules/sifarnici/store/sifarnikPretezneDelatnosti/sifarnikPretezneDelatnosti.types';
import { IGetTypeOfPacking } from 'modules/sifarnici/store/typeOfPacking/typeOfPacking.types';
import { arrayToObject } from 'utils/arrayToObject';
import { CompanyStatus } from 'modules/company/company.constants';
import { aao2Service } from './aao2.service';

class Aao2store implements IBaseObrazacStore<IAAO2Response, IPostAao2> {
  initialState: IPostAao2 | IAAO2Response = AAO2_INITIAL_STATE;
  kolicinaAmbalaze: IPostAao2KolicinaAmbalaze | IAao2KolicinaAmbalaze = AAO2_KOLICINA_AMBALAZA_INITIAL_STATE;
  upravljanjeAmbalaznimOtpadom: IPostAao2UpravljanjeAmbalaznimOtpadom | IAao2UpravljanjeAmbalaznimOtpadom =
    AAO2_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE;
  pravnoLiceIliPreduzetnik: IPostPravnoLiceIliPreduzetnik | IGetCompany = AAO2_PRAVNO_LICE;

  typeOfPackingExistsError = false;
  upravljanje_otpadom_options: {
    [K in IAao2KolicinaAmbalaze[][number]['type_of_packing_id']]: Extract<
      IAao2KolicinaAmbalaze[][number],
      { type_of_packing_id: K }
    >;
  } = {};

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      kolicinaAmbalaze: observable,
      upravljanjeAmbalaznimOtpadom: observable,
      typeOfPackingExistsError: observable,

      handleChange: action,
      handleInitialStateChange: action,
    });
  }

  get getInitialState() {
    return this.initialState;
  }

  get getKolicinaAmbalaze() {
    return this.kolicinaAmbalaze;
  }

  get getUpravljanjeAmbalaznimOtpadom() {
    return this.upravljanjeAmbalaznimOtpadom;
  }

  get getPravnoLice() {
    return this.pravnoLiceIliPreduzetnik;
  }

  setTypeOfPackingExistsError = (typeOfPackingExistsError: boolean) => {
    this.typeOfPackingExistsError = typeOfPackingExistsError;
  };

  // DOHVATANJE SIFARNIKA

  // OPSTE

  fetchCompaniesListWithSearch = async (pagination: IPagination, search: string) => {
    const [err, res] = await to(
      sifarniciService.fetchSingleSifarnikList(SIFARNICI_ROUTES.PREDUZECE, {
        page: pagination.page,
        size: pagination.size,
        naziv: search,
        order_by: '+naziv',
        status: CompanyStatus.AKTIVAN,
      })
    );
    if (err || !res) return;
    return res;
  };

  // proveriti da li se ova skarabudzevina ostavlja ovako ili ce bekend da menja pravnolice/preduzetnik iz iGetcompany polja u IGetAprpolja

  transformResponse = (response: IGetApr): Record<string, string> => {
    const transformedObject: Record<string, string> = {};

    AAO2_PRAVNO_LICE_FIELDS.forEach(field => {
      //@ts-ignore
      const mappedField = keyMapping[field] || field;
      //@ts-ignore
      transformedObject[field] = response[mappedField];
    });
    transformedObject.maticni_broj = transformedObject.maticni_broj.toString();
    return transformedObject;
  };

  fetchApr = async (search: string, type: string) => {
    const [err, res] = await to(
      sifarniciService.fetchSingleSifarnikList<IGetApr>(SIFARNICI_ROUTES.APR, {
        [type]: search,
      })
    );
    if (err || !res || isEmpty(res.items)) return Promise.reject();
    const transformedObject = this.transformResponse({ ...res.items[0] });
    this.handleChange('pravnoLiceIliPreduzetnik', transformedObject);
    return transformedObject;
  };

  fetchOldPravnoLiceList = async (godina: number) => {
    const { preduzece_id } = obrasciStore.opste;
    const [err, res] = await to(aao2Service.fetchOldPravnaLicaList({ godina, preduzece_id }));
    if (err || !res) return;
    this.handleInitialStateChange('pravno_lice_ili_preduzetnik', res);
    return res;
  };

  // POZIV SIFARNIK

  fetchTypeOfPackingOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetTypeOfPacking>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetTypeOfPacking>(SIFARNICI_ROUTES.TYPE_OF_PACKING, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+display_type_sr',
    });
    return response;
  };

  fetchOperateriOptions = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetOperatorsPackageWasteManage>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetOperatorsPackageWasteManage>(
      SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
      }
    );
    return response;
  };

  fetchROptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'R',
      name__not_in: ['R1'],
      order_by: '+name',
    });
    return response;
  };

  fetchDOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetDRList>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetDRList>(SIFARNICI_ROUTES.DR_LIST, {
      search: search,
      name__ilike: 'D',
      name__not_in: ['D1'],
      order_by: '+name',
    });
    return response;
  };

  fetchMestoOptions = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  fetchPretezneDelatnosti = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetSifarnikPretezneDelatnosti>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetSifarnikPretezneDelatnosti>(
      SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        version__neq: 0,
        order_by: '+name_sr',
      }
    );
    return response;
  };

  // HELPERS

  handleChange(key: keyof Aao2store, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: keyof IPostAao2, value: any) {
    (this as any).initialState[key] = value;
  }

  handleKolicinaAmbalazeChange(key: keyof IPostAao2KolicinaAmbalaze, value: any) {
    (this as any).kolicinaAmbalaze[key] = value;
  }

  checkSelfManagement(id: string) {
    const list = sifarniciStore.list.COMPANY_WITH_SELF_MANAGEMENT;
    list.some(item => item.company_id === id);
  }

  // CRUD FUNCTIONS

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IAAO2Response>(TIPOVI_OBRAZACA.AAO2, id));
    if (err) return;
    const newGodina = dayjs().year(Number(res.godina));
    const newIssueDate = res.datum_izdavanja ? dayjs(res.datum_izdavanja) : '';
    const initialState = { ...res, godina: newGodina, datum_izdavanja: newIssueDate };
    this.upravljanje_otpadom_options = arrayToObject(res.kolicina_ambalaze, 'type_of_packing_id');
    this.handleChange('initialState', initialState);
    return res;
  };

  async postData(payload: IPostAao2) {
    const [err, res] = await to<IPostResponse>(
      obrasciService.postSingleObrazac<IPostAao2>(TIPOVI_OBRAZACA.AAO2, nrizService.setEmptyValuesToNull(payload))
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async putData(payload: any) {
    const [err, res] = await to<IEmptyResponse>(
      obrasciService.putSingleObrazac<IAAO2Response>(
        TIPOVI_OBRAZACA.AAO2,
        payload.id,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.AAO2, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  resetStates = () => {
    this.handleChange('initialState', AAO2_INITIAL_STATE);
    this.handleChange('typeOfPackingExistsError', false);
    this.handleChange('upravljanje_otpadom_options', {});
  };

  submitPravnoLice = (payload: IPostPravnoLiceIliPreduzetnik) => {
    let newPayload = payload;
    let sifarnikMesto = {};
    let sifarnikPreteznaDelatnost = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikMesto = nrizService.addSifarnikInObject({
        exId: this.pravnoLiceIliPreduzetnik.mesto_id,
        exObj: (this.pravnoLiceIliPreduzetnik as IGetCompany).mesto,
        list: sifarniciStore.list.MESTO,
        name: 'mesto',
        id: payload.mesto_id,
      });
      sifarnikPreteznaDelatnost = nrizService.addSifarnikInObject({
        exId: this.pravnoLiceIliPreduzetnik.sifarnik_pretezne_delatnosti_id,
        exObj: (this.pravnoLiceIliPreduzetnik as IGetCompany).sifarnik_pretezne_delatnosti,
        list: sifarniciStore.list.SIFARNIK_PRETEZNE_DELATNOSTI,
        name: 'sifarnik_pretezne_delatnosti',
        id: payload.sifarnik_pretezne_delatnosti_id,
      });
    }
    newPayload = {
      ...newPayload,
      ...sifarnikMesto,
      ...sifarnikPreteznaDelatnost,
      id: (this.pravnoLiceIliPreduzetnik as IPravnoLiceIliPreduzetnik).id,
    } as IPravnoLiceIliPreduzetnik;
    const newList = obrasciService.submitMicroServiceForm(
      this.initialState.pravno_lice_ili_preduzetnik,
      nrizService.setEmptyValuesToNull(newPayload)
    );
    // handleSuccessNotify();
    this.handleInitialStateChange('pravno_lice_ili_preduzetnik', newList);
    this.handleChange('pravnoLiceIliPreduzetnik', AAO2_PRAVNO_LICE);
  };

  submitKolicinaAmbalaze = (payload: IPostAao2KolicinaAmbalaze) => {
    let newPayload = payload;
    let sifarnikTypeOfPacking = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikTypeOfPacking = nrizService.addSifarnikInObject({
        exId: (this.kolicinaAmbalaze as IAao2KolicinaAmbalaze).type_of_packing_id,
        exObj: (this.kolicinaAmbalaze as IAao2KolicinaAmbalaze).type_of_packing,
        list: sifarniciStore.list.TYPE_OF_PACKING,
        name: 'type_of_packing',
        id: payload.type_of_packing_id,
      });
      this.handleChange('upravljanje_otpadom_options', {
        ...this.upravljanje_otpadom_options,
        [newPayload.type_of_packing_id]: { ...this.kolicinaAmbalaze, ...newPayload, ...sifarnikTypeOfPacking },
      });
    }

    if (
      formStore.getCrudButtonType === 'delete' ||
      (formStore.getCrudButtonType === 'change' &&
        this.kolicinaAmbalaze.type_of_packing_id !== payload.type_of_packing_id)
    ) {
      const newUpravljanjeAmbalaznimOtpadomList = this.initialState.upravljanje_ambalaznim_otpadom.filter(
        item => item.type_of_packing_id !== this.kolicinaAmbalaze.type_of_packing_id
      );
      delete this.upravljanje_otpadom_options[this.kolicinaAmbalaze.type_of_packing_id];
      this.handleInitialStateChange('upravljanje_ambalaznim_otpadom', newUpravljanjeAmbalaznimOtpadomList);
    }
    newPayload = {
      ...this.kolicinaAmbalaze,
      ...newPayload,
      ...sifarnikTypeOfPacking,
    };
    const newList = obrasciService.submitMicroServiceForm(
      this.initialState.kolicina_ambalaze,
      nrizService.setEmptyValuesToNull(newPayload)
    );
    // handleSuccessNotify();
    this.handleInitialStateChange('kolicina_ambalaze', newList);
    this.handleChange('kolicinaAmbalaze', AAO2_KOLICINA_AMBALAZA_INITIAL_STATE);
  };

  submitUpravljanjeAmbOtpadom = (payload: IPostAao2UpravljanjeAmbalaznimOtpadom) => {
    let newPayload = payload;
    let sifarnikTypeOfPacking = {};
    let sifarnikDrugaOperacijaRSifarnik = {};
    let sifarnikDrugaOperacijaDSifarnik = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikTypeOfPacking = nrizService.addSifarnikInObject({
        exId: (this.upravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).type_of_packing_id,
        exObj: (this.upravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).type_of_packing,
        list: sifarniciStore.list.TYPE_OF_PACKING,
        name: 'type_of_packing',
        id: payload.type_of_packing_id,
      });
      sifarnikDrugaOperacijaRSifarnik = nrizService.addSifarnikInObject({
        exId: (this.upravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_r_id,
        exObj: (this.upravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_r,
        list: sifarniciStore.list.DR_LIST,
        name: 'druga_operacija_r',
        id: payload.druga_operacija_r_id,
      });
      sifarnikDrugaOperacijaDSifarnik = nrizService.addSifarnikInObject({
        exId: (this.upravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_d_id,
        exObj: (this.upravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_d,
        list: sifarniciStore.list.DR_LIST,
        name: 'druga_operacija_d',
        id: payload.druga_operacija_d_id,
      });
    }
    newPayload = {
      ...this.upravljanjeAmbalaznimOtpadom,
      ...newPayload,
      ...sifarnikTypeOfPacking,
      ...sifarnikDrugaOperacijaRSifarnik,
      ...sifarnikDrugaOperacijaDSifarnik,
    };
    const newList = obrasciService.submitMicroServiceForm(
      this.initialState.upravljanje_ambalaznim_otpadom,
      nrizService.setEmptyValuesToNull(newPayload)
    );
    // handleSuccessNotify();
    this.handleInitialStateChange('upravljanje_ambalaznim_otpadom', newList);
    this.handleChange('upravljanjeAmbalaznimOtpadom', AAO2_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE);
  };

  submitData = (payload: IPostAao2) => {
    const newInitialState = {
      ...AAO2_INITIAL_STATE,
      id: (this.initialState as IAAO2Response).id,
      ...payload,
      godina: obrasciStore.opste.godina,
      kolicina_ambalaze: this.initialState.kolicina_ambalaze,
      upravljanje_ambalaznim_otpadom: this.initialState.upravljanje_ambalaznim_otpadom,
      pravno_lice_ili_preduzetnik: this.initialState.pravno_lice_ili_preduzetnik,
    } as IAAO2Response;
    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };
}

export const aao2Store = new Aao2store();
