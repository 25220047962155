import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore, modalStore } from 'store';
import { AirListTable } from './AirListTable/AirListTable';
import { facilityStore } from 'modules/facility/facility.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { airStore } from 'modules/sourcesOfPollution/air/air.store';
import { useForm } from 'antd/es/form/Form';
import { IFilterTableProps } from 'typescript/NrizTypes';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { AIR_FILTERS_INITIAL_STATE, AIR_FILTERS_OPTIONS } from 'modules/sourcesOfPollution/air/air.constants';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

const FacilityAirListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t('POLLUTION.AIR.LIST')}</h3>
      {facilityStore.getFacility.is_active &&
        facilityStore.getFacilityTipoviObrazaca.some(
          (item: any) =>
            item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2 ||
            item.skraceni_naziv === TIPOVI_OBRAZACA.GVE ||
            item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
        ) &&
        facilityStore.readOnlyTipoviObrazacaForm && (
          <AddNewButton
            label="Dodaj izvor emisija u vazduh"
            name={'air'}
            onButtonClick={() => {
              modalStore.changeModalName('air-modal');
            }}
          />
        )}
    </Row>
  );
});

const FacilityAirFilters: React.FC = observer(() => {
  const [form] = useForm();

  const fetchAirList = () => {
    return airStore.fetchAirList(paginationInitialState);
  };

  useEffect(() => {
    return () => {
      filtersStore.setFilters(FiltersNameEnum.IZVOR_VAZDUH, AIR_FILTERS_INITIAL_STATE);
    };
  }, []);

  const data: IFilterTableProps = {
    form: form,
    initialState: filtersStore.getFilters[FiltersNameEnum.IZVOR_VAZDUH],
    filterStoreName: FiltersNameEnum.IZVOR_VAZDUH,
    filterOptions: AIR_FILTERS_OPTIONS,
    fetchTableList: fetchAirList,
  };
  return <>{authStore.isAdmin && <FilterTableForm data={data} />}</>;
});

export const FacilityAirList: React.FC = observer(() => {
  useEffect(() => {
    airStore.fetchAirList(paginationInitialState);
  }, []);

  return (
    <InsideContentWrapper header={<FacilityAirListHeader />}>
      {authStore.isAdmin && <FacilityAirFilters />}
      <AirListTable />
    </InsideContentWrapper>
  );
});
